import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function IndependenceDay() {
  return (
    <><title>Celebrating Independence Day with the Visually Impaired</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Celebrating Independence Day with the Visually Impaired</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Celebrating Independence Day with the Visually Impaired</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Independence-Day-Blog_01.png" alt="Celebrating Independence Day" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}><b>Editor’s Note</b><br /><em>Rose Indra, Director – Specially Abled, Kalyani Youth Leadership Forum (KYLF) 2022-23, writes about her experience of celebrating Independence Day 2022 with visually impaired children of Calcutta Blind School. Read on to know how Rose, along with her batchmates, spent an eventful day with the children by putting up cultural performance titled “Azaadi ki Udaan”.</em></p>
                                <p>Globsyn Business School (GBS) celebrated India’s 76th Independence Day with the visually impaired students of Calcutta Blind School. Student volunteers of Kalyani Youth Leadership Forum (KYLF), a Beyond Education initiative of GBS, put up a cultural performance called ‘Azaadi Ki Udaan’ as a part of ‘Care for Society’ project, through which they tried to capture the ethos of independence while building a connection with the indomitable souls.</p>
                                <p>It was the most awaited day for all of us, as a week of preparation and hard work finally showed its results. Although the day began with a clear blue sky and plenty of sunshine, there was sporadic rainfall in bits and spurts. Despite the weather, the KYLF team members and my fellow mates participated enthusiastically with the help and support of several of our faculty.</p>
                                <p>
                                    <img className="img-fluid" src="/assets/images/Independence-Day-Blog_02.png" alt="Celebrating Independence Day" />
                                </p>
                                <p>At first, we decorated the auditorium, regardless of our individual verticals, because it was our top priority due to the short amount of time. Then, at around 10:00 am, our Director and Trustee, Rahul Dasgupta and Principal and Instructor-in-charge of Calcutta Blind School arrived. Then we called the the blind school children organised them in two lines. As we interacted with people who were blind but had an upright and joyful soul, it was an entirely new experience.</p>
                                <p>
                                    <img className="img-fluid" src="/assets/images/Independence-Day-Blog_04_01.png" alt="Independence-Day-Blog" />
                                </p>
                                <p>Around 10.30 am, Mr Dasgupta along with CBS principal jointly hoisted the National Flag of India. This was followed by the National Anthem which was sung by the students and teachers. After that, we moved to the auditorium where the event – “Azaadi ki Udaan” – was kicked off with a speech by the Director and Principal of GBS. The students of CBS also then put up a beautiful performance and showcased their dancing talent. Then, some of our fellow college mates performed songs and recitations which kept everyone spellbound. This was followed by a skit, which was performed on the theme of ‘Azaadi Ki Udaan’. I also took part in a recitation called “Kandari Hunsiyaar” by the poet Kaji Nazrul Islam.</p>
                                <iframe width="796" height="446" src="https://www.youtube.com/embed/sLocCvFm8Ck" title="Independence Day 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                <p>It was a great feeling to watch the smiles on the faces of the children as they enjoyed every moment of our classmates’ performances. At the end, we forged a strong bond with the students of CBS by tying up friendship bands and distributing food packets and gifts among the students. It is needless to say that as management students, with all our management learning and help from our faculties and non-teaching staff, we tried our level best to make the whole event successful and bring a smile on the faces of the children.</p>
                                <p>
                                    <img className="img-fluid" src="/assets/images/Independence-Day-Blog_03.png" alt="Independence-Day-Blog" />
                                </p>
                                <p>I would like to thank Globsyn Business School for giving us this opportunity to learn that visually impaired people can achieve everything that a normal sighted person can – all it takes is the confidence and ambition to pursue it. It was not only a gift for all of us to spend a day with them but it also helped me understand the principles behind KYLF events. I think such “Beyond Education” initiatives give students a chance to improve society while also giving them the chance to become compassionate managers and better human beings.</p>
                                <div className="one-table">
                                    <div className="one-table1"><img className="img-fluid" src="/assets/images/Rose-Indra-150x150.jpg" alt="tt" width="150" height="150" /></div>
                                    <div className="one-table2">
                                        <p><b>Rose Indra</b><br />
                                        Director – Specially Abled<br />
                                        Kalyani Youth Leadership Forum<br />
                                        Globsyn Business School<br />
                                        Batch 2021-2023
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
