import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function Consumerism() {
    return (
        <><title>Consumerism is a shame of Marketing</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Consumerism is a shame of Marketing</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Consumerism is a shame of Marketing</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Consumerism-is-a-shame-of-Marketing.jpg" alt="Consumerism-is-a-shame-of-Marketing" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Dr. M.P. Singh, teaches Marketing at Globsyn Business School – one of the best B-Schools in Kolkata. Dr, Singh is a renowned academician and distinguished professor, who is well known for his academic-administrative excellence and acumen. He has more than 22 years of academic experience, out of which, for the last 13 years he has worked as an Institutional Head at various institutes of repute in NCR. Dr. Singh is an advisory board member and research supervisor of institutes of academic excellence. He is also an authorized keynote speaker at various national and international conferences, and chaired various technical sessions. He has presented and published more than twenty-one research papers in National/International seminars and conferences. Dr. Singh has been awarded the Bolt award (Broad Outlook Learner Teachers Award) at the PHD Chamber of Commerce & Industry, New Delhi.
                                    </em>
                                </p>

                                <p>The consumers of the 21st century have become more critical of the policies and practices of corporate houses in recent years than ever before. All over the world, the consumers are organizing themselves for the protection of their interests. The right to consumers to be protected for their interest in the market place is called ‘Consumerism’. It is a consumer movement against poor and fraudulent practices of marketers.</p>
                                <p>Consumerism can be defined as a universal consumers movement and their movement force designed to protect consumer rights, interests and title in the marketplace by organizing consumer pressures on business. It is a united and organized effort to fight against unfair marketing practices and to achieve consumer protection against such malpractices.</p>
                                <p>The power of balance in marketing and the market rests with the sellers and marketers. This is evident in marketplaces. Consumerism includes all efforts to right this imbalance in exchange of goods and services between the sellers and the buyers legally and ethically.</p>
                                <p>Consumerism connotes that the consumer looks upon the manufacturer as somebody who is interested but who really does not know what the consumers’ realities are. The consumer takes the manufacturer as who has not made the conscious effort to find out, and who does not understand the world in which the consumer lives and who expects the consumer to be able to make rational purchasing and consumption decisions. Consumerism movement questions four important premises of the marketing concept. Firstly, it assumes that consumers know their needs, and secondly it is assumed that business really cares about those needs and knows exactly how to find them.</p>
                                <p>Thirdly, it bases its assumption that business does provide useful information that precisely matches products to need. Fourthly, it is presumed that products and services really fulfill customer expectations as well as business premises.</p>
                                <p>Thereby, consumerism refers to all the efforts organized by various consumer groups to remedy their frustration in realizing their rights and standard of living, caused by products and services not as per their expectations. It is in the long-term interest of both the manufacturer and marketer to protect the interest of the consumer. All over the world, the consumerism movement is gaining extra momentum and the picture from various parts of the world is proving that it is shame on marketing.</p>
                                <h4>Marketing and Consumerism </h4>
                                <p>Marketing is to get the right products at the right time, at the right place, with the right communication to the right person. Marketing is to convert societal needs into profitable opportunities. Sellers and Marketers must assure and ensure proper service with appropriate and right products to customers and consumers. The relationship between marketers-customers rests on mutually beneficial exchanges but when it gets one-sided screwed by malpractices from marketers it leads to consumer movement-consumerism.</p>
                                <p>The global call for a ‘societal marketing concept’ would take into account the long-term ethical, moral and social issues with which marketing should now concern itself. Such a societal driven ethical consumer concept, must recognize certain practicalities which complicate the issue. But the fact is that failure on part of marketers has given birth to consumerism.</p>
                                <h4>Origin and Development of Consumerism (Globally) </h4>
                                <p>The inappropriate, unethical and malpractices from marketers led to consumerism. In the latter half of the 20th century in the 1960s, American business firms found themselves the target of a growing consumer movement. Globally and in the USA the literacy rates and standard of living changed and the Consumers had become better educated; products had become increasingly complex and hazardous; discontent with American marketing and business institutions was widespread; influential writers accused corporate and big business of wasteful and manipulative practices leading to the movement of consumers.</p>
                                <p>The term Consumerism may be defined as a “conscious movement of customer and consumer seeking to augment the rights and power of buyers in relation to seller”. Consumerism means and includes all activities of business, government and independent organizations that are designed to protect consumers/ customers/ individuals from practices that infringe upon consumption and upon their rights.The customers and consumers have basic fundamental right to be informed which includes such things as the right to know the cost and interest of a loan i.e. truth-in-lending, the cost true as per standard unit of competing brands i.e. unit pricing, the true ingredients in a product i.e. ingredient labeling, the true nutritional quality of foods i.e. nutritional labeling, the true freshness of products i.e. truth-in-advertising.</p>
                                <p>Following practices have been termed as unethical by consumers:</p>
                                <ol type="I">
                                    <li>Misleading advertising</li>
                                    <li>Fast product obsolescence</li>
                                    <li>Deceptive and unfair trade practices</li>
                                    <li>Harmful and poorly made products</li>
                                    <li>No willingness on the part of the business houses to stand behind their products</li>
                                    <li>Too many products to choose from, i.e., dumping</li>
                                    <li>Overcharging the poor</li>
                                    <li>Profits with no regard to the humanitarian aspects in general and consumer’s plight in particular</li>
                                </ol>
                                <p>The spread of consumerism to the global world is itself a shame on marketing. Consumerism gains when marketing fails, i.e., the marketing failure has resulted in the birth of consumerism. Therefore, it is worthy to conclude that consumerism is the shame of marketing.</p>


                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Prof-Dr-M-P-Singh.png"
                                            alt="Dr-M-P-Singh"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Dr. M.P. Singh
                                            <br />
                                            Faculty – Marketing
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
