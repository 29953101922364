import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../Sidebar'




export default function Pgdm() {
    return (
        <>
            <title>AICTE Approved PGDM Course in Kolkata | Enroll at Best PGDM College</title>
            <section className="hero-banner-img-pgdm">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">PGDM</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">Pgdm</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                                <h4 className="vc_custom_heading" style={{ textAlign: 'center' }}>Admissions Closed for Batch 2023-25</h4>
                                <h4 className="vc_custom_heading" style={{ textAlign: 'center' }}>Currently accepting applications under Waiting List</h4>
                            </div>
                            <div className="one-table">
                                <div className="one-table2">
                                    <p>Designed for the academically consistent student, the AICTE approved Post Graduate Diploma in Management (PGDM) programme of Globsyn Business School – considered to be one of the best PGDM colleges in Kolkata – provides for strong curricula based learning and an Indian MBA experience coupled with a wide area of study.</p>
                                    <p>Based on Globsyn’s triad of Innovation, Research and Technology, this programme is augmented with access to real-time research driven case studies, journals, articles, etc., from the dedicated Research Cell. It gears students for life-long learning by getting them exposed to self-paced learning aided by eGlobsyn – a Web 2.0 based Learning and Content Management System with social plug-ins.</p>
                                    <p>The PGDM programme has been structured to help students prepare either for fast track corporate management AICTE approved careers, or pursue further academic studies either in India or abroad and go on to either contribute towards research and development of the management sciences, or join the academic community, or pursue their entrepreneurial vision.</p>
                                    <p>Aimed at students who see themselves making a mark as hands-on ‘Indian’ managers – a strong breed of management professionals who are increasingly leading MNC brands into nascent and emerging markets, the PGDM programme is designed to provide career options for students that are virtually limitless with avenues to work in a variety of fields ranging from Government assignments to NGO’s etc.</p>
                                    <p>With its successful track record of providing quality management education to its students, the programme has become one of the most sought after AICTE approved PGDM course in Kolkata and Eastern India attracting MBA aspirants from different parts of the country.</p>
                                    <p><strong>Duration:</strong> 2 years Full Time Programme</p>
                                    <h4>Program Educational Objectives</h4>
                                    <ul className='disc'>
                                        <li>To provide graduates with a sound foundation and appreciation of management as a discipline and a career</li>
                                        <li>To produce management graduates who can critically think to solve business problems in real-world situations</li>
                                        <li>To produce industry ready and responsible managers who have high regard for personal and institutional integrity</li>
                                        <li>To prepare graduates with effective communication skills, teamwork skills, domain skills and values to meet the diversified needs of the industry</li>
                                    </ul>
                                    <h4>Structure</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td><strong>Foundation Programme</strong></td>
                                                <td>Coverage: Academic Sessions, Non-Academic Sessions, Corporate Connects, Employability Enhancement Sessions, Basic English, Basic Maths
                                                    <br />Mode: Blended Mode – Online and Face to Face</td>
                                            </tr>
                                            <tr>
                                                <td><strong>General Management Programme</strong></td>
                                                <td>Coverage: Finance, Marketing, HR, Economics, Quantitative Techniques, Operations, Business Research Methods, Information Technology etc.
                                                    <br />Mode: Mixed Mode – Lectures supplemented by Online Learning, Live on-site projects, Experiential Learning and Case Studies</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Schools of Excellence</strong></td>
                                                <td>Finance, Marketing, HR, Operations Management, Information Systems
                                                    <br />Mode: Mixed Mode – Lectures supplemented by Online Learning and Case Studies</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <strong style={{fontSize:'20px'}}>Curriculum</strong>
                                    <h4>Semester-I</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                            <td style={{backgroundColor:"#d9edf7"}}><strong>Sl. No.</strong></td>
                                            <td style={{backgroundColor:"#d9edf7"}}><strong>Link</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>1</strong></td>
                                                <td>Financial & Management Accounting</td>
                                            </tr>
                                            <tr>
                                                <td><strong>2</strong></td>
                                                <td>Managerial Economics</td>
                                            </tr>
                                            <tr>
                                                <td><strong>3</strong></td>
                                                <td>Legal Aspects of Business</td>
                                            </tr>
                                            <tr>
                                                <td><strong>4</strong></td>
                                                <td>Management Functions and Organizational Behaviour</td>
                                            </tr>
                                            <tr>
                                                <td><strong>5</strong></td>
                                                <td>Marketing Management - I</td>
                                            </tr>
                                            <tr>
                                                <td><strong>6</strong></td>
                                                <td>Business Communication</td>
                                            </tr>
                                            <tr>
                                                <td><strong>7</strong></td>
                                                <td>Professional Skills Development - I</td>
                                            </tr>
                                            <tr>
                                                <td><strong>8</strong></td>
                                                <td>Computer Applications - I</td>
                                            </tr>
                                            <tr>
                                                <td><strong>9</strong></td>
                                                <td>Industry 4.0 </td>
                                            </tr>
                                            <tr>
                                                <td><strong>10</strong></td>
                                                <td>Basic English - I</td>
                                            </tr>
                                            <tr>
                                                <td><strong>11</strong></td>
                                                <td>Basic Maths - I</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h4>Semester-II</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                            <td style={{backgroundColor:"#d9edf7"}}><strong>Sl. No.</strong></td>
                                            <td style={{backgroundColor:"#d9edf7"}}><strong>Link</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>1</strong></td>
                                                <td>Financial Management and Corporate Finance</td>
                                            </tr>
                                            <tr>
                                                <td><strong>2</strong></td>
                                                <td>Managerial Economics & Global Business Environment</td>
                                            </tr>
                                            <tr>
                                                <td><strong>3</strong></td>
                                                <td>Marketing Management - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>4</strong></td>
                                                <td>Human Resource Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>5</strong></td>
                                                <td>Business Process Automation and Advanced Data Analytics</td>
                                            </tr>
                                            <tr>
                                                <td><strong>6</strong></td>
                                                <td>Business Research Methods & Applications</td>
                                            </tr>
                                            <tr>
                                                <td><strong>7</strong></td>
                                                <td>Quantitative Methods - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>8</strong></td>
                                                <td>Global Business Strategy</td>
                                            </tr>
                                            <tr>
                                                <td><strong>9</strong></td>
                                                <td>Operations Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>10</strong></td>
                                                <td>Professional Skills Development - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>11</strong></td>
                                                <td>Computer Applications - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>12</strong></td>
                                                <td>Industry 4.0</td>
                                            </tr>
                                            <tr>
                                                <td><strong>13</strong></td>
                                                <td>Basic English - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>14</strong></td>
                                                <td>Basic Maths - II</td>
                                            </tr>
                                        </tbody>
                                    </table>

<h4>Semester-III</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                            <td style={{backgroundColor:"#d9edf7"}}><strong>Sl. No.</strong></td>
                                            <td style={{backgroundColor:"#d9edf7"}}><strong>Link</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>1</strong></td>
                                                <td>Sales Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>2</strong></td>
                                                <td>Boardroom Simulation Activity</td>
                                            </tr>
                                            <tr>
                                                <td><strong>3</strong></td>
                                                <td>Supply Chain Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>4</strong></td>
                                                <td>Change Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>5</strong></td>
                                                <td>Professional Skills Development - III</td>
                                            </tr>
                                            <tr>
                                                <td><strong>6</strong></td>
                                                <td>Computer Applications - III</td>
                                            </tr>
                                            <tr>
                                                <td><strong>7</strong></td>
                                                <td>Digital Analytics</td>
                                            </tr>
                                            <tr>
                                                <td><strong>8</strong></td>
                                                <td>Basic English - III</td>
                                            </tr>
                                            <tr>
                                                <td><strong>9</strong></td>
                                                <td>Basic Maths - III</td>
                                            </tr>
                                            <tr>
                                                <td><strong>10</strong></td>
                                                <td>Electives from Schools of Excellence</td>
                                            </tr>
                                        </tbody>
                                    </table>
<h4>Semester-IV</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                            <td style={{backgroundColor:"#d9edf7"}}><strong>Sl. No.</strong></td>
                                            <td style={{backgroundColor:"#d9edf7"}}><strong>Link</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>1</strong></td>
                                                <td>Project Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>2</strong></td>
                                                <td>Quality Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>3</strong></td>
                                                <td>Business Ethics & Corporate Governance</td>
                                            </tr>
                                            <tr>
                                                <td><strong>4</strong></td>
                                                <td>Computer Applications – IV</td>
                                            </tr>
                                            <tr>
                                                <td><strong>5</strong></td>
                                                <td>Electives from Schools of Excellence</td>
                                            </tr>
                                            <tr>
                                                <td><strong>6</strong></td>
                                                <td>Dissertation</td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>

                                    <strong>Two Credits is allotted to Beyond Education Activities which is spread across all four semesters. </strong>
                                    <h4>Schools of Excellence</h4>
                                    <ul className='disc'>
                                        <li>School of Finance Excellence</li>
                                        <li>School of Marketing Excellence</li>
                                        <li>School of HR Excellence</li>
                                        <li>School of Information System</li>
                                        <li>School of Operations Management Excellence</li>
                                    </ul>
                                    <p>Please be aware of AICTE’s eligibility criteria, admission policy and refund policy from the AICTE website, before applying for the PGDM programme of Globsyn Business School.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
