import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function UsageOfEmoji() {
  return (
    <><title>Usage of Emojis at Workplace</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">Usage of Emojis at Workplace</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog">Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="">Usage of Emojis at Workplace</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>
                    <div className="one-table">
                        <div className="entry-header">
                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>A picture is worth a thousand words – and an ‘Emoji’ can save you that many letters. It is interesting to see how communication has evolved over time, and how funny and interactive pictures can fluently express human emotions without the use of letters.</p>
                        <p>Emojis are cute. It is easy to think they are just for teenagers whose phones are permanently glued to their hands. But, as ever with the humble emoji, there is so much more beneath the surface  –  in form, function, history, and future. They are based on symbols from Japanese comics and express emotions through digital communication. The idea is to provide a reaction when emailing, texting or messaging someone who is unable to see your body language. It is difficult to imagine a world without the usage of emojis, whether it’s decoding what they really mean, waiting for the new set to arrive, or thinking of ones that should exist.</p>
                        <p>In today’s communication landscape, emojis allow people to visually express themselves and convey emotions that might be difficult to deliver through words alone. Many companies use them in creative ways to boost their brands online and connect with target audiences. But although emojis might be appropriate at a particular moment, they don’t always work as intended. Individuals bring their own personal experiences when interpreting an emoji, so although you might use one with streaming tears after something you found incredibly funny, someone else might wonder why you are upset. Therefore, emojis can get lost in translation causing disconnect and confusion between correspondents.</p>
                        <p><b>Words have consequences. So do the symbols that stand for them.</b></p>
                        <p>Professionalism is not the real issue, since many companies, teams, and industries have varying ideas of professionalism. Communication always evolves, and unless an individual belong to an organisation that still think contractions are going to destroy language and the world as we know it, he is probably already using emojis at his workplace. A person may think that winky faces, heart eyes and blowing kisses are fun way to express his appreciation, but when sent to his team, these seemingly harmless emojis run the risk of being construed as ‘evidence’ of sexual harassment. Emojis allow for open interpretation which is one of the major factors of misrepresentation of one’s behaviour and intentions. Even the skin tone selected could be perceived as racist or ethnic misappropriation. Some past cases and life experiences in an organisation favouring the usage of emojis have faced these tough challenges. Each new emoji carries as much risk of sending unintended messages as rewards. So just remember that each time you sent an emoji, you are potentially playing with danger.</p>
                        <p><b>Give your emotions a right face</b></p>
                        <p>Of course there are some pros and cons to using emojis in an office setting. Therefore picking a right face is the most important aspect for this mode of communication to work. For example, an employee should never respond to his boss’ request with a ‘pile of poop’ icon. But smiley faces and thumbs up are generally good positive ways to communicate quickly.</p>
                        <p>The good part of emojis is that, they are helpful for conveying emotions. So much of our personal and professional communications are by emails or text messages, and it is really hard to interpret tone. With a paucity of words, in clipped conversation or casual shorthand, we can also misunderstand someone’s intent.</p>
                        <p>Emojis can also work to build positive emotional associations among team mates. While, historically, the ‘workplace’ is supposed to be an ’emotion-free’ zone, it is never really been the case. On the contrary, now we know that ’emotion-free’ is actually bad for business. Studies and researches have shown that emotion is an important component of team-building and employee engagement.</p>
                        <p>Beyond that, emojis can be powerful tools for marketing. People mentally respond the same way to an emoji as they do to a human face, and adding a smile on the end of a message can increase positive responses. Some major brands, including Coca Cola, Goldman Sachs, Chevrolet, Starbucks, and Disney have begun using emojis in their social media and advertising, presumably to reach a younger target audience.</p>
                        <p>Even during internal communication within organisations, corporates have started actively using such creative gestures as modes of communication. Businesses have started encouraging its employees to be expressive and creative at their workplaces in order to stay motivated in a challenging environment – during a stressful situation for example, when there is a race to meet a project deadline, a smiley face added to an email injects some much-needed levity.</p>
                        <p><b>Let us adopt Emoji Policies</b></p>
                        <p>Instead of just nuking emojis as unprofessional or dangerous, it’s time we get smart about the way we use them. We do need something called, Emoji Policies for institutions and organisations where certain aspects need to be taken into consideration, like:</p>
                        <ul className='disc'> 
                            <li>Which kinds of emojis are we going to use in business communication and which are off-limits?</li>
                            <li>What forms of communication are good for emojis and which ones should be emoji-free? Contracts, for example, might be an emoji-free zone, while marketing materials remain emoji-friendly</li>
                            <li>Who is responsible for using emojis on social media, which emojis will they use, and for which type of posts?</li>
                            <li>When is an emoji considered to be an acceptable substitute for a word, and when is it not? A glossary or cheat sheet can be created so that everyone is on the same page</li>
                        </ul>
                        <p>Today, emojis are a part of our communication in everyday life, and have also become an integral part of our communication in business. But as with other types of communication – verbal, non-verbal and written – we have to use them carefully and with discretion.</p>
                        <p>However at the same time, we should remember that change is the essence of life and for an organisation to grow globally, it needs to keep on trying and adapting to new challenges and changes. Therefore, adapting to a new form of communication in the right manner is equally important and essential. More so, as we all know that emojis are here to stay.</p>
                        <br/>
                        <p><strong>Parama Majumdar</strong><br/>
                        Globsyn Business School</p>


                    </div>

                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
