import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'

export default function Placement() {
    return (
        <>
            <title>Placement - Globsyn</title>

            <section className="hero-banner-img-placement">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Placement</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation"> Placement</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/Placement-Page-Pic-1.png" alt="logo-panel" width={624} height={320} />
                                    <br />
                                    <strong style={{ fontSize: "12px" }}>Mr. Rahul Dasgupta, Director &amp; Trustee, Globsyn Business School, receiving the ‘Best Private B-School in Eastern India’<br />
                                        recognition by The Times Group, at Times Brand Icons 2022</strong>
                                </div>
                                <br />
                                <br />
                                <p>With 3500+ alumni working at 360+ corporates globally, Globsyn Business School (GBS) has always been a pioneer in providing optimum placement to its students. Considered to be one of the best placement MBA colleges in Kolkata, GBS, with its legacy of continuous innovation in management education for two decades, has been lauded with the ‘Best Private B-School in Eastern India’ by The Times Group, at Times Brand Icons 2022. Supported by a nationwide forum of alumni and faculty, the B-School’s Corporate Relationship Program (CRP) is manned by a dedicated team of highly motivated professionals who seek to construct alliances that resonate with the industry, the institution, and the student community. These alliances expose and prepare the students for the corporate world, make them ‘industry-relevant, and finally help them in finding their place in the job market through Final Placement.</p>
                                <p>Over the years, GBS has successfully built relations with hundreds of corporates, who have not only recruited its students over the years for SIP or Final Placement, but have also been an integral part of the management curriculum through the Corporate Connect sessions, and encouraged the young managers with their presence in Beyond Education activities.
                                </p>
                                <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/Global-Presence-of-GBS-Alumni-1024x576.png" alt="Global-presence" />
                                </div>
                                <br />
                                <p>The infographic above shows the global presence of 3500+ alumni of Globsyn Business School, who have earned rich accolades for their achievements, and today, a large number of them are star performers within their organizations.</p>
                                <p>GBS through various nouveau initiatives like Globsyn Alumni Engage attempts to connect with its alumni at a personal level and involve them in every aspect of its functioning as a B-school, thus making them active participants in the evolution of their alma mater and fellow students. The Globsyn Alumni Mentorship Program is another such initiative where distinct alumni members engage, mentor and groom the students of the B-School, with the endeavour to acquaint the young managers with the essence of corporate outlook and prepare them for final placement interviews. With an objective to bridge the gap between the industry and academia for a life-long learning experience, the GBS has come up with the Globsyn Alumni Academic Programme that offers all alumni of the B-School the opportunity to attend any one paper from the Core/Elective subjects of PGDM course. Over the years, GBS has been able to realize the corporate dreams of its students by getting them placed in industries and securing job roles that they are passionate about, long after their graduation from the B-School. This has only been possible through Globsyn Lifelong Career Support, a lateral placement program for the alumni.</p>
                                <p>GBS, known to be amongst the top MBA colleges in Kolkata with good placements, keeps on engaging with its alumni and corporate partners throughout the year and updating its curriculum structure based on their feedback thereby minimizing the supply-demand gap within the industry.</p>
                                <h3>Final Placement</h3>
                                <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/Placement-Photograph-for-Website-1.jpg" alt="Global-presence" />
                                    <strong>Students of Batch 2021-23 after getting placed during Placement 2023</strong>
                                </div>
                                <br />
                                <p>Final Placements are opportunities given to students to help shape the skills and knowledge gained during their academic journey and implement those in their industry of choice. These opportunities also increase their knowledge of a corporate sector, allowing them to make better informed decisions about future career goals. In line with its mission of transforming young minds into industry relevant managers and prepare them for final placement interviews, GBS has always been focusing on various types of industry engagements. These engagements have helped the students get placed in top companies across India, and made sure that corporates get the right talent for the right job in their organizations.</p>
                                <br />
                                <b>Placement 2023</b>
                                <p>This year’s Placement Season has witnessed 1500+ job opportunities from 90+ corporates being offered to the students, with the highest and second highest CTC offered at 23.34 LPA and 20.14 LPA, respectively, while the third highest CTC offered stands at an impressive 13.58 LPA, and the average CTC was at 7.51 LPA.</p>
                                <p>The various academic delivery practices of the B-School have significantly contributed in a steady growth of CTC over the decades. The infographic below shows the growth of average CTC from Batch 2018-20 to 2021-23:</p>
                                <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/Placemenrt-Statistics-Graph.jpg" alt="Global-presence" />
                                </div>
                                <br />
                                <h4>
                                    <i>Highlights of Placement 2023</i>
                                </h4>
                                <ul>
                                    <li ><strong>Highest CTC Offered:</strong> 23.34 LPA</li>
                                    <li ><strong>Second Highest CTC Offered:</strong> 20.14 LPA</li>
                                    <li ><strong>Third Highest CTC Offered: </strong>13.58 LPA</li>
                                    <li ><strong>Top 10 CTC Offered:</strong> 23.34 LPA, 20.14 LPA, 13.58 LPA, 10.06 LPA, 10 LPA, 10 LPA, 9.20 LPA, 9.03 LPA, 9 LPA, 8.30 LPA</li>
                                    <li ><strong>Top 15 Existing Brands:</strong> Asian Paints, Bandhan Bank, BuyerForesight, Ernst &amp; Young, Federal Bank, IDFC First Bank, Kantar, Pantaloons, PhonePe, PwC, Reliance Jio, Tata AIG GI, UTI Mutual Funds, Vodafone Idea</li>
                                    <li ><strong>Top 15 New Brands:</strong> ABP Network, Alight Solutions, Deloitte, Exide Industries, HLS Asia, IndiaMART, Kimberly-Clark India, Magma HDI GI, Marico, Motilal Oswal Financial Services, MRF, Nihilent, Otis Elevator, Pine Labs, VIP Industries</li>
                                    <li ><strong>Average CTC:</strong> 7.51 LPA</li>
                                    <li ><strong>Number of Opportunities:</strong> 1500+</li>
                                </ul>
                                <br />
                                <a className='btn' href="/gbs-placement-2023">Placement 2023 Details</a>
                                <br />
                                <br />
                                <p>The infographic below explains the sector-wise opportunities, which came during Placement 2023, with the highest percentage of job opportunities coming from BFSI at 28.3%, followed by Consulting & Services, Fintech, Research & Analytics at 21%, 14.8%, and 7.3% respectively. The rest of the infographic is made up of opportunities from sectors like FMCG/FMCD, Telecommunications, IT/ITES, NBFC, Automobile, Retail, E-Commerce, amongst others.</p>
                                <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/Sectors-Covered-Graph.jpg" alt="sectors-cover" />
                                </div>
                                <br />
                                <h3>Students Speak</h3>
                                <p>Students share their heartfelt gratitude towards the B-School for their unique GBS experience that has helped them to develop key managerial skills, improve their inner potentials, and boost their employability skills to secure managerial positions at leading corporate houses.</p>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <h3>Summer Internship Program (SIP)</h3>
                                <p>At Globsyn Business School, Summer Internship Program (SIP) – an integral part of the B-School’s academic curriculum – is one of the key components that help shape the students into younger managers. The 8 – 10 weeks SIP opportunity is extended to all first-year students of the B-School, which allows them to contribute their knowledge, and enhance their cognitive and interpersonal skills, whilst providing the young minds with a first-hand exposure at working with various national and multinational business houses across various sectors in the global corporate world.</p>
                                <h4>SIP Experience of Students</h4>
                                <p>Watch the experiences of some of our students who have done their SIP from Deloitte, one of the Big Four.</p>
                                <div style={{ textAlign: "center" }}>
                                    <iframe width="848" height="477" src="https://www.youtube.com/embed/W684WicI90w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <h4>Award for Excellence in SIP</h4>
                                <p>Globsyn Business School organizes the annual Globsyn Award for Excellence in SIP in order to appreciate the research acumen of the young managers of the B-School, and ascertain the desired level of performance in internships. On this occasion, an eminent panel of judges comprising of renowned corporate professionals, chooses the top 3 most promising projects ideas and confers certificates, citations and awards to the winners amidst encouragement and cheer from their fellow batch mates.</p>
                                <div style={{ textAlign: "center" }}>
                                    <iframe width="848" height="477" src="https://www.youtube.com/embed/7h9UxhxAPSY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <br />
                                <h3>Corporate Partners</h3>
                                <p>With 20 years of innovation in management education, Globsyn Business School treasures all its 360+ corporate relationships. Apart from recruiting the young managers over the years for Summer Internship Program or Final Placement, these corporate partners have been an integral part of the management curriculum through Corporate Connects, Boardroom Simulations and Beyond Education elements that hone the management skills of the students and subject them to real-life corporate exposure. For the last two decades through various industry-academia engagements, these corporates have not only trained and groomed the students, but have always been ready to absorb them once they were ‘industry relevant’.</p>
                                <h4>Corporates Speak</h4>
                                <p>The Corporate Relationship Program (CRP) as an integral part of Globsyn Business School not only takes charge of student placements but also focuses on building long-term relationships with corporates representing 360+ organizations. To extend a vote of thanks to all the corporate partners of the B-School for providing continuous assistance to the students, GBS’ CRP team hosted an event called ‘An Evening with Globsyn’, where new ideas and possibilities of industry-academia partnerships were discussed.</p>
                                <p>Following videos are some of the captures of what the corporates said about GBS:</p>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                        <div className='col-md-4'>
                                            <iframe title="Rushia Goldar Placed in GlobalData | Globsyn Business School" src="https://www.youtube.com/embed/ohtnA6XMagY?feature=oembed" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-12="true" id="_dytid_2453" width="292" height="182" frameborder="0"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <h4>Corporate Feedback</h4>
                                <p>Globsyn Business School shares some of the sentiments the corporate partners voiced about the young managers of the B-School.</p>
                                <div className="accordion" id="myAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Dipak Agarwal Founder & CEO, Onex Solutions</button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>At Onex Solutions, we are happy to associate with Globsyn Business School where the B-School’s CRP Team always provides a win-win situation for students and corporates. The students are presentable, smart and knowledgeable in various management domains. We have worked on Live Projects and hired few students from GBS for internship too. Keep up the good work and help students get practical exposure. We look forward for a long term relation with the B-School.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingtwo">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsetwo">Sayan Bandyopadhyay, AVP (East) – HR, HDFC Life</button>
                                            </h2>
                                            <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Globsyn has always been a professionally managed B-School. I have recruited students from there several times for different organizations, and have found students to be smart, good in communication and well-taught.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingthree">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Sushmita Choudhury, Head – HR (Talent, OD and L&D), Richard Design Services</button>
                                            </h2>
                                            <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Globsyn has a pool of motivated budding talents who are brimming with energy. The Institute with its state-of-the-art technology creates blended learning and encourages the students to be a socially responsible citizen of the society, as they prepare them to be industry ready. I am happy with the coordination process for SIP and mentoring of the students. My best wishes to the leadership team of Globsyn as they inspire the ambition of the ‘future minds’ of tomorrow.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingfour">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefour">Piyali Dhar, Head – Human Resource & Administration, Titagarh Wagons Limited</button>
                                            </h2>
                                            <div id="collapsefour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Globsyn Business School is doing a phenomenal job by educating students through industry connect sessions. The students displayed a sense of keen inquisitiveness, which reflected their intelligence, knowledge and maturity. I wish GBS and its students all the success.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingfive">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefive">Siddhartha Basu, Head – Strategic Business, Payabbhi</button>
                                            </h2>
                                            <div id="collapsefive" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>We have had a student joining us from Globsyn about a couple of years back and he is now anchoring the entire merchant settlement of our platform. More recently, we had taken a summer intern and were very pleased to have extended a full time employment offer to the said intern. So our experience with Globsyn has been pretty good.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingsix">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsesix">Arunav Ghosh, Talent Acquisition Head – East, Flipkart</button>
                                            </h2>
                                            <div id="collapsesix" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Globysn has been one of the best B-Schools in Eastern India, and has been instrumental with their quick GBO Online courses for students & professionals during tough COVID-19 situations across the country. I think students need to have a more practical understanding of the current scenario and should work on enhancing their skills, as well as in getting practical real time workshops for industry exposure. This will help them in the long run. I wish you and your team all the best for the future.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingseven">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseseven">Rillika Sarkar, Senior Manager – HR, Vishva Electrotech (Polar Industries)</button>
                                            </h2>
                                            <div id="collapseseven" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>It was really good to be associated with such an esteemed academic institute of Kolkata. We found the students’ profiles to be quite suitable for the transition from campus to corporate. They seem to be having good knowledge about their subjects and are presentable for joining an organization.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingeight">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseeight">Sayantan Banerjee, Assistant Manager – HR, Spencer’s Retail Ltd.</button>
                                            </h2>
                                            <div id="collapseeight" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Some students of Globsyn Business School did a live project at Spencer’s Retails Limited in our Mani Square and Axis Mall outlets. During this period of interaction, I found them to be hard working, flexible, receptive and ready to accept challenges in their roles and responsibilities. I believe GBS provides a great exposure to the students by organizing different activities which are very much helpful for these students in deciding their future career fields.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingnine">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsenine">Himanshu Shah, Divisional Sales HR Manager, Marico</button>
                                            </h2>
                                            <div id="collapsenine" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>We selected some students for projects with Marico and the output was good, as the students were disciplined. The CRP Team of Globsyn Business School is very supportive and responsive. We look forward to having a synergistic relationship in the future.</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <br/>
                                    <h4>Recent Recruiters from the Campus</h4>
                                    <p>In the recent past, top national and international corporates like Amazon, Nestle India, PwC, Parle, TCS, ITC Ltd. and a host of others have visited the B-School’s campus for recruitment.</p>
                                    <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/Companies-Logo-for-Placement-Page.jpg" alt="sectors-cover" />
                                </div>
                                <h4>Human Capital Conclave</h4>
                                <p>In its continuous efforts to build an industry-academia partnership, Globsyn Business School in collaboration with National HRD Network (Kolkata Chapter), organized Human Capital Conclave on ‘Bridging the Gap between Campus and Corporate’. Some of the best minds from the corporate and academia graced this event to share their insights on topics like: ‘Emerging Edge Skills – in the next 5 years’, ‘Corporate’s Expectations from Campus’, and ‘Business Analytics’.</p>
                                <div style={{ textAlign: "center" }}>
                                    <iframe width="848" height="477" src="https://www.youtube.com/embed/_Lnxkna5E_g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
