import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function HowisFlipped() {
    return (
        <><title>How is Flipped Classroom Flipping the Role of Traditional Classroom Pedagogy</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">How is Flipped Classroom Flipping the Role of Traditional Classroom Pedagogy</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> How is Flipped Classroom Flipping the Role of Traditional Classroom Pedagogy</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/GBS-web-blog-banner.png" alt="How is Flipped Classroom Flipping the Role of Traditional Classroom Pedagogy" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>The purpose of education is to move forward, improve and express. Over the last few years, Flipped Classroom has rapidly gained popularity among faculty and administrators of institutes around the globe. Flipped Classroom pedagogy has become popular in several different courses in Higher Educational Institutions. Information Technology have become an integral part of the teaching/learning process in academic courses at Higher Education Institutions, opening the way for the emergence of different pedagogical models like e-Learning, Blended Learning and the Flipped Classroom.</p>
                                <p><b>What is the Flipped Classroom pedagogy? Why are instructors flipping the classroom?</b></p>
                                <p>Flipped Classroom is a form of blended teaching-learning pedagogy which is focuses on engagement and active learning of the students. Flipping the classroom is a teaching approach in which instead of teachers lecturing for long hours and merely delivering the information/content, the course materials are introduced outside of class, often online, to the students beforehand and in-class activities is re-purposed for diving deeper into the curriculum through knowledge sharing, application-oriented learning, projects, case study analysis, role-play, debates, quizzes group presentations and assessments in order to better meet the needs of individual learners. Course materials might include readings, pre-recorded video lectures or research assignments. In-class activities might involve the faculty helping students to work on exercises, projects, cases individually and in groups, among other active learning strategies for students to gain practice applying knowledge gained prior to class.</p>
                                <p>Thus, a flipped class is one that inverts the typical cycle of content acquisition and application so that content (e.g. materials, pre-recorded video lectures) is assigned as ‘homework’ which is analyzed before coming to class and in-class time is spent working on problems, advancing concepts, and engaging in collaborative learning. Flipped Classroom leads (if properly designed/evaluated) to improved teaching-learning relationships producing a greater involvement, commitment and motivation of the students.</p>
                                <p>In 1956, the cognitive psychologist Benjamin Bloom had introduced his famous Taxonomy of Educational Objectives, commonly known as Bloom’s Taxonomy, developed for classifying learning objectives in levels. Bloom’s classification includes three areas of learning- Cognitive, Affective and Psychomotor. In his framework, he divided cognitive domain into six levels or categories, ranked from the simplest and most concrete to the more complex and abstract: knowledge, comprehension, application, analysis, synthesis and evaluation. Anderson & Krathwohl revised Bloom’s original Taxonomy in 2001 that resulted in the so-called Revised Bloom Taxonomy, where the categories were divided as follows: remember, understand, apply, analyze, evaluate and create. In contrast to the original taxonomy, here the levels are defined by verbs rather than nouns, since these better reflect the active nature of the learning process.</p>
                                <p>In the Flipped Classroom pedagogy, the student’s preliminary study at home would work in the first three areas from the Revised Blooms Taxonomy i.e. remember, understand, apply, while in class more complex levels like analyze, evaluate and create can be approached.</p>
                                <img src='/assets/images/GBS-web-blog-banner_2.png' alt='GBS-web-blog' />
                                <p>In a Flipped Classroom approach instructors plays the important role of being the facilitator and guides their students to deeper thinking and learn higher levels of application. A Flipped Classroom is a student-centric teaching-learning methodology. Flipping the classroom enables educators to enhance the classroom experience in the following ways:</p>
                                <ul className='disc'>
                                    <li><b>Dive deeper into subject</b> – Once students have a basic foundation on a certain subject, they can dive deeper into the learning material. Therefore, instructors can deliver more in-depth learning material to those who are looking for a deeper understanding of the subject.</li>
                                    <li><b>Faculty can work more closely with students inside the classroom</b> – The faculty can spend time in the classroom working more closely with students, ensuring the progress of their learning by answering individual questions as well as helping them to understand concepts taught in the material or video lessons.</li>
                                    <li><b>Students can share their understanding of the concepts with peers</b> – The typical classroom model allows for minimal peer engagement where students typically attend class for lectures and then do their work at home. The Flipped Classroom allows students to study the material at home and then work with their peers on projects during class time. This increases classroom engagement, teamwork skills, and peer understanding. The students work together and apply course concepts under the guidance of the instructor. The increased interaction helps to create a learning environment that encourages students to build knowledge together inside and outside the classroom.</li>
                                    <li><b>Students actively participate in learning</b> – The student’s role shifts from passive recipient to active constructor of knowledge providing them opportunities to practice and apply using the intellectual tools of the discipline.</li>
                                    <li><b>Constructive feedback</b> – With more opportunities for students to apply their knowledge and therefore demonstrate their ability to use it, gaps in their understanding become visible to both themselves and the faculty.</li>
                                </ul>

                                <p>According to Vanderbilt University Center for Teaching, in a Flipped Classroom ‘’Reviewing materials beforehand and turning a lecture into an interactive working session yields statistically significant improvements in engagement, test scores and overall long-term learning.’’</p>
                                <p>Globsyn Business School blends in Flipped Classroom methodology as a part of its academic delivery process through the use of eGlobsyn, our online learning portal. The students are required to go through the lectures/cases uploaded in eGlobsyn portal before the class followed by discussion and analysis of the topics/cases during the class hours. The learning method allows the faculty to devote classroom time to experiential learning activities and encourage higher order thinking and learning. This helps us to make classroom learning more effective and interesting and at the same time in redefining the delivery process to ensure that our students acquire the required skills to become successful working professionals.</p>

                                <p><b>Oindrila Roy</b><br />
                                    Knowledge Cell</p>
                                <p><em>Source:<br />
                                researchgate.net/publication/309890120_Flipped_Classroom_Approach<br />
                                cft.vanderbilt.edu/guides-sub-pages/flipping-the-classroom<br/>
                                washington.edu/teaching/topics/engaging-students-in-learning/flipping-the-classroom
                                </em></p>

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
