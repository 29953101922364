import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function OrganizationalCulture() {
  return (
    <><title>Organizational Culture and Climate</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Organizational Culture and Climate</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Organizational Culture and Climate</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Organizational-Culture-and-Climate.jpg" alt="Organizational Culture and Climate" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                    Dr. Debaprasad Chattopadhyay, teaches Human Resource at Globsyn Business School – one of the best B-Schools in Kolkata. Dr. Chattopadhyay has over 48 years of academic and corporate experience with very successful stints in reputed national and international organizations. In addition to being an academician, Dr. Chattopadhyay is a life member of National HRD Network and ISTD and a member of ISABS, NIPM, BMA, and CMA. He is a life member of his alma mater, Don Bosco School Park Circus Alumni Association. Dr. Chattopadhyay completed a program on Strategic Human Resource Leadership from National HRD Network and has also attended Human Laboratory Process Course from Indian Society of Applied Behavioral Sciences. Dr. Chattopadhyay has conducted Management Development Programs and Consultancy Assignments across organizations and has to his credit several publications in various journals and presented technical papers at different national and international conferences. Recently, he has received the Udai Pareek Memorial Distinguished AHRD Alumni Award for the year 2022, bestowed on him by his alma mater Academy of Human Resource Development (AHRD), Ahmedabad.
                                    </em>
                                </p>
                                
                                <p>Culture consists of a set of values and beliefs which helps to reinforce work groups and communities. It applies to individuals, teams and organisations. The more the culture of one is in sync with the culture of the other, it can result in harmony, smooth sailing and protracted relationships. The reverse is also true, in that, if cultures of the two substantially differ, it can also result in bickering and severance. Therefore, it is essential to study the ingredients of Culture.</p>
                                <p>An apt approach is the OCTAPACE Model enunciated by the renowned Late Dr. (Prof.) Udai Pareekh, ex-IIMA. Dr. (Prof.) Udai Pareekh, along with Dr. (Prof.) T. V. Rao (also ex-IIMA), were the pioneers to implement the concept of HRD in India at Larsen & Toubro in1974, precisely five years after the acronym HRD was pronounced in the annual conference of American Society of Training and Development (ASTD) held in 1969 at Miami, USA, by Dr. (Prof.) Leonard Nadler of University of Washington DC, USA.</p>
                                <p>The OCTAPACE Model of organisational culture states as: ‘O’ stands for ‘Openness’, ‘C’ stands for ‘Confrontation’, ‘T’ stands for ‘Trust’, ‘A’ stands for ‘Authenticity’, ‘P’ stands for ‘Proactivity’, ‘A’ stands for ‘Autonomy’, ‘C’ stands for ‘Collaboration’, and ‘E’ stands for ‘Experimentation’. ‘Openness’ refers to transparency, ‘Confrontation’ implies ability to take problems head-on, ‘Trust’ indicates mutual faith, ‘Authenticity’ refers to bonafideness, ‘Proactivity’ refers to capacity to envision the future, ‘Autonomy’ implies empowerment, ‘Collaboration’ indicates team-spirit, and ‘Experimentation’ implies the ability to take calculated risks. Organisational Culture is the precursor Organisational Climate.</p>
                                <p>So, an organisation can have Sales Climate, Safety Climate, Quality Climate, Ethical Climate, Spiritual Climate. Psychometric instruments are available as exercises to discern one’s Culture and one’s Climate. It behoves on an individual, team, organisation to identify one’s Culture and Climate thereby making it possible to identify the right partner so as to achieve a win-win situation, in the long-run.</p>
                                <p>Globsyn Business School, with top PGDM courses in Kolkata, recognizes that the cutting edge of an organisation ultimately depends upon the quality of its human resource and how effectively it is leveraged by the firm. This is reflected in its HR specialization programme that covers compulsory courses, key concepts, issues, systems and tools relevant HR functions, particularly Organisation Behaviour (OB), which comes under MFOB (Management Functions & Organisation Behaviour). This subject is taught/imparted to the 1st year students in Semester I itself. The underlying premise is, budding managers of the near future need to know functions of management and the nuts and bolts of how people behave in organisations.</p>
                                <p>This blog elaborates how to create a win-win situation between two individuals while they interact with each other in organisations. ‘Enculturalisation’ is the process of imbibing one’s culture from the home-front, whereas, ‘Acculturation’ is the process of acquiring culture from peers and others in the working environment. The write-up dilates models and specifies the nuggets of organisational culture and climate.</p>
                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Dr.-Debaprasad-Chattopadhyay.jpg"
                                            alt="Debaprasad-Chattopadhyay"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                        Dr. Debaprasad Chattopadhyay
                                            <br />
                                            Faculty – HR
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
