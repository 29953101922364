import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'


export default function Teaching() {
    return (
        <>
            <title>Teaching Resource - Globsyn</title>
            <section className="hero-banner-img-teaching">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Teaching Resource</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">Teaching Resource</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>

                            </div>
                            <div className="one-table">
                                <p>Any institution that sets out to meet global standards of Education must start on a powerful foundation of high quality faculty team. GBS, to facilitate high-end management Education has assembled a powerful mix of academicians, industry professionals and luminaries from different walks of life. This coupled with innovative learning models like “Learn & Intern” and “Work & Teach” strives to instill into students an attitude of life-long learning that is critical in this millennium of knowledge capital.</p>
                                <h2>Full-time Faculty</h2>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/rcb.jpg" alt="rcb" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. R.C. Bhattacharya – Vice Chairman</strong></p>
                                    <p><strong>Education:</strong> Strategic Thinking & Management – Wharton Business School, USA; MBA -Cranfield University, UK, DMS – Staffordshire University, UK, AIM – Institution of Metallurgists, London</p>
                                    <p><strong>Core Area:</strong> Strategic Management, Strategic Marketing, Corporate Governance</p>
                                    <p>An alumnus of the Cranfield Business School, UK; Wharton Business School, US; and North Staffordshire, UK; he was Head of Marketing, Indian Oxygen Ltd. (now BOC India Ltd.). He was also the Senior Vice President – Marketing with one of the Aditya Birla Group Companies. He has been teaching Industrial Marketing, Marketing of Services, Strategic Marketing and other topics on Marketing for many years and offers first-hand experience in Strategic Alliances and Brand Building in Industrial Markets, Corporate Marketing, Relationship Marketing, Loyalty Building etc. in his programmes.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Prof-Dr-M-P-Singh.png" alt="M P Singh" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. (Dr.) M.P. Singh – Principal</strong></p>
                                    <p><strong>Education:</strong> Ph.D., UGC – NET, MBA (Marketing)</p>
                                    <p><strong>Core Area:</strong> Marketing Management, Marketing of Services, Consumer Behaviour, Advertising Management, Customer Relationship Management, Global Marketing</p>
                                    <p>A renowned academician, and distinguished professor, Dr. Singh is well known for his academic-administrative excellence and acumen. He has more than 22 years of academic experience, out of which, for the last 13 years he has worked as an Institutional Head at various institutes of repute in NCR.</p>
                                    <p>Dr. Singh is an advisory board member of institutes of academic excellence, and was a former member of RDC and Board of Studies, Amity University, Rajasthan. He is an authorized research supervisor of various universities, a keynote speaker at various national and international conferences, and also chaired various technical sessions. He has guided twenty-eight M. Phil and eight PhDs, and has headed various R&D cells, Entrepreneurship Cells, and Alumni Cells at various institutes.</p>
                                    <p>He has presented and published more than twenty-one research papers in National/International seminars and conferences. He has also conducted various MDPs and FDPs. Dr. Singh has been awarded Bolt award (Broad Outlook Learner Teachers Award) at the PHD Chamber of Commerce & Industry, New Delhi.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/dpc_jpg062517.jpg" alt="(Dr.) Debaprasad Chattopadhyay" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. (Dr.) Debaprasad Chattopadhyay</strong></p>
                                    <p><strong>Education:</strong> Ph.D. – Academy of Human Resource Development; M.Phil.; PGDBIM (Strategic Human Resource Leadership); B.Sc. – University of Calcutta</p>
                                    <p><strong>Core Area:</strong> Performance Management, Strategic HRM, Training & Development</p>
                                    <p>Dr. Debaprasad Chattopadhyay has over 48 years of academic and corporate experience with very successful stints in reputed national and international organizations like Merck & Co, Organon, and Dr. Reddy’s Laboratories Limited. In addition to being an academician, Prof. Chattopadhyay is a life member of National HRD Network and ISTD and a member of ISABS, NIPM, BMA, and CMA. He is a life member of his alma mater, Don Bosco School Park Circus Alumni Association. Prof Chattopadhyay completed a program on Strategic Human Resource Leadership from National HRD Network and has also attended Human Laboratory Process Course from Indian Society of Applied Behavioral Sciences. Prof. Chattopadhyay has conducted Management Development Programs and Consultancy Assignments across organizations and has to his credit several publications in various journals and presented technical papers at different national and international conferences. Prof. Chattopadhyay has also successfully completed a four-week online program on, “Ethical Leadership by Giving Voice to Values” from Darden Business School, University of Virginia, USA. Recently, he has received the Udai Pareek Memorial Distinguished AHRD Alumni Award for the year 2022, bestowed on him by his alma mater Academy of Human Resource Development (AHRD), Ahmedabad.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Tanusree-Chakraborty_jpg121820.png" alt="Tanusree-Chakraborty" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. (Dr.) Tanusree Chakraborty</strong></p>
                                    <p><strong>Education:</strong> Ph.D. – University of Calcutta; M.Phil. – Burdwan University; M.Sc. (Economics) – University of Calcutta</p>
                                    <p><strong>Core Area:</strong> Dr. Tanusree Chakraborty has completed her Masters and Ph.D. in Economics from the esteemed Calcutta University. With over 12 years of experience in teaching and research, she has led several learning sessions for aspirants, in the field of Economics, Operations Research, Research Methodology, Statistics and Gender & Development. Dr. Chakraborty’s repertoire of work includes a number of published books and journals, wherein she has extensively contributed her knowledge about women’s economic empowerment in the present day. She has also been actively involved in events, seminars and conference proceedings of international repute.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Sabyasachi-Dasgupta.jpg" alt="Prof. (Dr.) Sabyasachi Dasgupta" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. (Dr.) Sabyasachi Dasgupta</strong></p>
                                    <p><strong>Education:</strong> FPM – MICA; MDHEA – Symbiosis, Pune; MBM (Marketing) – University of Calcutta</p>
                                    <p><strong>Core Area:</strong> Branding, Advertising, Consumer Behavior, Gender Studies</p>
                                    <p>Dr. Sabyasachi Dasgupta has commanded prominent positions in several corporate and academic giants for more than 14 years. He has taught at the Florida State University, USA and has also been a visiting researcher in the University. Dr. Dasgupta, apart from presenting research papers in national and international conferences, also has a number of publications including Scopus/ABDC/UGC Care, edited books, number of cases, book chapters and a book under his name. He has also received the distinguished Research Excellence Award 2020. Dr. Dasgupta heads the Research and Publications vertical of Globsyn Research Cell. He is also a scientific committee member of TIIKM – Colombo, a member of American Marketing Association and Calcutta Management Association, and a reviewer in Journal of Brand Management, Journal of Creative Communication and journals published by American Marketing Association.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Prof-Sanjoy-Mukerji.png" alt="Prof-Sanjoy-Mukerji" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Sanjoy Mukerji</strong></p>
                                    <p><strong>Education:</strong> BE (Mech) IIT Roorkee; PGDM (IIMB); UGC (NET)</p>
                                    <p><strong>Core Area:</strong> Operations Management, Project Management, Supply Chain, Management, Quantitative Techniques</p>
                                    <p>Prof. Sanjoy Mukerji has over 36 years of experience comprising 31 years in Industry and 5 years in Academics. He has been a Full Time Faculty at XIME Bangalore, Institute of Project Management, Vadodara, and Eastern IILM Kolkata in the areas of Operations, Project Management, and Quantitative Techniques. He has worked with reputed companies like Tata Motors, NTPC, BrahMos Aerospace, Kolkata Port, and Jayprakash Associates among others in the areas of Procurement, Project Management, and Operations. He has been a Project Manager of BrahMos Supersonic Cruise Missile system, a much-acclaimed project in the Indian Defense Sector. Prof Mukerji has conducted MDP and OBP Programs for Tata Electronics and Neyveli Lignite Corporation.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Prof-Dr.-Mahuya.png" alt="Prof-Dr.-Mahuya" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. (Dr.) Mahuya Basu</strong></p>
                                    <p><strong>Education:</strong> Ph.D. – University of Calcutta; MBA (Finance) – Jadavpur University; M.Sc. (Economics) – University of Calcutta</p>
                                    <p><strong>Core Area:</strong> Finance, Micro and Macro Economics, Business Research Methodology</p>
                                    <p>Dr. Mahuya Basu did her graduation in Economics from Lady Brabourne college before completing her Ph.D. and M.Sc. from the University of Calcutta, and MBA in Finance from Jadavpur University. With over 20 years of experience in teaching and academic administration; her last stint, in the capacity of a faculty and Head of the Department was at Footwear Design & Development Institute (FDDI), Kolkata. She was earlier associated as a faculty member in ICFAI National College and led several learning sessions for students in the field of Financial management, Economics and Business Research Methods, etc. She has several conference papers and journal publications to her credit, and has co-authored two books. One of her research paper has been awarded as the Third Best Paper from industry and academia in the International Conference of Nirma University; NICOM.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/RAJIB-BHATTACHARYA.jpg" alt="RAJIB-BHATTACHARYA" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. (Dr.) Rajib Bhattacharya</strong></p>
                                    <p><strong>Education:</strong> Ph.D (Kalinga University), M.Phil (The Global Open University), M.A. – Economics (Mahatma Gandhi University), MBA – Finance (Jadavpur University), ACMA (The Institute of Cost Accountants of India), PGDPMIR & PGDMM (Annamalai University), B.Com (University of Calcutta)</p>
                                    <p><strong>Core Area:</strong> Cost & Management Accounting, Financial Accounting, Quantitative Finance</p>
                                    <p>Dr. Rajib Bhattacharya has over 14 years of experience in middle and senior level positions in various industries including the banking sector. In his academic career extending over 11 years, he has been associated with reputed business schools as faculty member, paper setter and examiner. He had also been a guest faculty at Universitas Pelita Harapan, Jakarta, Indonesia. He has provided corporate training to middle and senior level executives of many MNCs. He has many research papers (including a best-paper award winning paper) to his credit, published in peer-reviewed indexed journals and presented in national & international conferences including some at IITs and IIMs. He is a member of The Institute of Cost Accountants of India, All India Management Association, Operational Research Society of India and Analytics Society of India.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Prof-Dr-Kavita-Shastri.png" alt="Prof-Dr-Kavita-Shastri" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. (Dr.) Kavita Shastri</strong></p>
                                    <p><strong>Education:</strong> Ph.D – Kurukshetra University; UGC (NET) Qualified; MBA (HR) – University of Jammu</p>
                                    <p><strong>Core Area:</strong> Dr. Kavita Shastri has two years of industry experience and around 25 years of teaching experience including academic administration. Ranking second in order of merit in her MBA, Dr. Shastri had the opportunity of teaching at prominent Business Schools of Calcutta. Besides HR specialization papers she also taught soft skills and has written many papers published in peer reviewed journals. Her edited book “Personality at Workplace” was published by ICFAI University Press Hyderabad. Dr. Shastri has written a book on Human Resource Management and another on Organizational Behavior too. In her last stint as Registrar of a College affiliated to University of Calcutta she also mentored students and conducted MDPs/Consultancy assignments in addition to routine teaching and administrative</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Prof-Dr-Swapna-Dutta-Khan.png" alt="Prof-Dr-Swapna-Dutta-Khan" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. (Dr.) Swapna Datta Khan</strong></p>
                                    <p><strong>Education:</strong> PhD (Marketing Management) – University of Pune, MPhil (Management) – Madurai Kamaraj University, MSc (Applied Mathematics) – University of Roorkee (now Indian Institute of Technology Roorkee), MStat (Statistical Quality Control & Operations Research) – Indian Statistical Institute</p>
                                    <p><strong>Core Area:</strong> Marketing Management, Services Marketing, Quantitative Methods, Business Research,                                                    Operations Research</p>
                                    <p>Prof. (Dr.) Swapna Datta Khan has been passionate about teaching and grooming young management professionals since more than 16 years. She has taught various courses related to Decision Sciences and Marketing Management at the Army Institute of Management, Kolkata. Prior to that she has been a Faculty at ICFAI National College, Nashik. She has had a research experience of over 14 years, with publications in international journals and presentations at international conferences, besides having edited a research journal, been resource person at conferences and reviewer of research papers. She has qualified the UGC NET Lecturership in Mathematics. She has also served in the Indian Army as a part of the 1st course of non-medical lady officers.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Darshana-Bhagawati.jpg" alt="Darshana-Bhagawati" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. (Dr.) Darshana Bhagowati</strong></p>
                                    <p><strong>Education:</strong> Ph.D – Burdwan University; MBA (Marketing) – Dibrugarh University, Assam</p>
                                    <p><strong>Core Area:</strong> Marketing Management, Consumer Behaviour, Service Marketing, Rural Marketing</p>
                                    <p>Dr. Darshana Bhagowati did her MBA from Dibrugarh University, Assam before completing her Ph.D from Burdwan University. She has around a decade of years of experience in both industry and academics. Dr. Bhagowati was earlier associated with various leading management institutions in the country. She has also published several conference papers and journal publications. One of her research paper has been awarded the best research paper in marketing track in the national conference organised by J.D Birla Institute (Department of Management), Kolkata.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Manas_jpg124648.jpg" alt="Prof. Manas Chakravarty" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Manas Chakravarty</strong></p>
                                    <p><strong>Education:</strong> MBA – IISWBM, MBA</p>
                                    <p><strong>Core Area:</strong> Human Resource, Industrial Relations, General Management</p>
                                    <p>Prof. Chakravarty moved to academics for over 17 years now following a distinguished career of 25 years in the industry culminating in leadership roles. Prior to joining the faculty team at Globsyn Business School, he has been Associate Dean at ICFAI, Hyderabad and Dean at United World School of Business, Ahmedabad. In addition to administrative and professorial responsibilities, Prof. Chakravarty has delivered executive learning programs at leading corporate houses across the country and has been the program director of national and international conferences. Apart from academic writing, he also has a blog page in the Economic Times. Persuasive and articulate, Prof. Chakravarty enjoys working with individuals and groups engaged in intellectual pursuit.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/kaushik_jpg050251.jpg" alt="Prof. Kaushik Ghosh" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Kaushik Ghosh</strong></p>
                                    <p><strong>Education:</strong> MBA – Sikkim Manipal University; PGDCA</p>
                                    <p><strong>Core Area:</strong> Prof. Kaushik Ghosh is a Microsoft Certified Professional in various Microsoft Technologies and Process Automation. He has more than 10 years of experience in the software industry and 10 years in Academics. Prof. Ghosh has completed his PGDCA from NIIT and received his MBA from Sikkim Manipal University. He is an empanelled trainer at IBM, Tech Mahindra, Wipro Technologies, Infosys and many other Corporate. Prof. Ghosh also worked as a consultant in various universities in Dhaka, Bangladesh under the project of Digital Bangladesh funded by World Bank. Prof. Ghosh is specialist in Robotic Process Automation and is the subject matter expert of the relevant course of PGDM program in Globsyn Business School. His areas of expertise also include Python and Excel as robust data analytics and predictive modeling tool.
                                    </p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/titas_jpg050748.jpg" alt="Prof. Titas RoyChoudhury" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Titas RoyChoudhury</strong></p>
                                    <p><strong>Education:</strong> Executive PGDM – Globsyn Business School; PGDACS</p>
                                    <p><strong>Core Area:</strong> Java, JEE, Android, BigData</p>
                                    <p>Prof. Titas RoyChowdhury has graduated in Mathematics from University of Burdwan and pursued a post-graduate course in Applied Computer Science from CMC Ltd. Prof. RoyChoudhury pursued his management education at Globsyn Business School and has more than 15 years of experience in the software industry. With a vast experience of training in a number of reputed IT training institutes in Kolkata, he has trained nearly 10,000 students from various engineering colleges as well as employees from various IT corporates.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/img.png" alt="Prof. Shaoli Chakravorty" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Shaoli Chakravorty</strong></p>
                                    <p><strong>Education:</strong> Master in Business Administration – IISWBM; PGDM – IISWBM; B.Sc. – University of Calcutta</p>
                                    <p><strong>Core Area:</strong> General Management Functions and Business Strategy</p>
                                    <p>Prof. Chakravorty has a versatile experience across leading academic institutions and corporates in India. She has gained expertise in areas like Program Management, Operations, Marketing & Promotions among many others while working in multifarious positions. Her leadership qualities along with excellent people mangement abilities has witnessed teams ascending to the next level while driving business performance of organizations.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Sayani.png" alt="Prof. Sayani Sen" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Sayani Sen</strong></p>
                                    <p><strong>Education:</strong> UGC JRF & NET (Management), WBSET (Management), MBA (Rank holder) – Uttarakhand Technical University, M. Com – University of Calcutta, CA IPCC (I) ICAI</p>
                                    <p><strong>Core Area:</strong> Finance, Strategic Management, Business Communication, Soft Skills</p>
                                    <p>Prof. Sayani Sen is a rank holder in MBA (Finance and Marketing) from Uttarakhand Technical University and cleared UGC JRF & NET in Management. She has also completed her M. Com in Accounting & Finance from the prestigious University of Calcutta. Before joining Globsyn Business School, she was working as an Assistant Manager at a reputed bank, and has also held academic positions in other private institutes. She possesses distinguished certificates in Account Management & Sales Force Design – West Virginia University, Behavioural Finance – Duke University, NISM – Mutual fund (V-A). She is presently working on conceptualization of various academic initiatives to improve the delivery mechanism, and emphasizes on a knowledge driven approach with the objective of focusing on capability development.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Doyel-Mukherjee-Prodhan.jpg" alt="Prof. Sayani Sen" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Doyel Mukherjee</strong></p>
                                    <p><strong>Education:</strong> Master in Business Administration</p>
                                    <p><strong>Core Area:</strong> Marketing Management, Data Visualization, Data Analytics</p>
                                    <p>Prof. Doyel Mukherjee, an MBA in Marketing Management, possesses distinguished certificates in data analytics using SQL, Advanced Excel, and is an expert visual story teller. Prior to joining the faculty at Globsyn Business School, she has been associated with various esteemed academic institutions in managerial positions. Prof. Mukherjee is presently working on developing program architecture based on the experiential learning model and conceptualizing various innovative methods for the overall improvement of the academic delivery.</p>
                                </div>
                                <h2>Adjunct - Faculty</h2>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/subrata-kar_jpg121833.jpg" alt="Prof. Subrata Kar" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Subrata Kar</strong></p>
                                    <p><strong>Education:</strong> MBA, AIC, B.Sc</p>
                                    <p><strong>Core Area:</strong> Operations Management, Supply Chain Management</p>
                                    <p>Prof. Subrata Kar has close to 40 years of work experience in Operations Management and Total Quality Management field with Eveready Industries Ltd. He has attended a course of ‘Lead Assessor’ for ISO:9000 organised by CII and Batala, UK.</p>
                                </div>
                                <h2>Visiting - Faculty</h2>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/soma-dutta_jpg011126.jpg" alt="Prof. Soma Dutta" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Soma Dutta</strong></p>
                                    <p><strong>Education:</strong> Diploma in Labour Law – Madurai Kamraj University; LLB – University of Calcutta</p>
                                    <p><strong>Core Area:</strong> Law</p>
                                    <p>Prof. Dutta is a renowned name in management education in India, having an experience of more than fifteen years. She has been associated with many leading business schools of the country as a committed academician.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Sanjay_kumar_dutta_jpg035303.jpg" alt="Prof. Soma Dutta" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Sanjay Kumar Datta</strong></p>
                                    <p><strong>Education:</strong> MBA – IISWBM, Executive Programme – IIM Calcutta, B.Sc – Presidency College</p>
                                    <p><strong>Core Area:</strong> Marketing</p>
                                    <p>Prof. Sanjay Kumar Datta has an experience of a decade and a half in the academic domain. Apart from GBS, he is presently associated as a visiting faculty with IIT Kharagpore, and a few other esteemed engineering colleges in Kolkata. He has secured 99.99 percentile in CAT eight times in a row since 2010. He also has a rich gamut of experience in the corporate world and has worked as a Vice President of Ranbaxy Pharmaceuticals.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Chandana_jpg122754.jpg" alt="Prof. Chandana Ghosh" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Chandana Ghosh</strong></p>
                                    <p><strong>Education:</strong> PGDM – Jamnalal Bajaj Institute of Management, Mumbai</p>
                                    <p><strong>Core Area:</strong> Brand Management, Sales & Distribution, Human Resource Management and Competency Development</p>
                                    <p>Apart from being the Founder & CEO of 4Front Talent Solutions, Prof. Chandana Ghosh has 25 years of experience in the FMCG industry. She has started her career in Brand Management with Lakme. Later, she has played leadership roles in Sales & Marketing domain of various leading corporates. She has set up FMCG Sales Structure under ITC , where she worked as Head Competency Development and HR. Prof. Ghosh has also been a visiting faculty in premier institutes like XLRI, IIMC and JBIMS.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/tapas-ghosh_jpg060150.jpg" alt="Prof. Tapas Kumar Ghosh" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Tapas Kumar Ghosh</strong></p>
                                    <p><strong>Education:</strong> M.Com. – University of Calcutta; Masters in Law (L.L.M) – North Orissa University</p>
                                    <p><strong>Core Area:</strong> Business & Industrial Law, Financial Management, Business Economics, Accountancy</p>
                                    <p>Prof. Ghosh has done her M.Com from Calcutta University and completed his Master in Law from North Orissa University. He has been associated with State Bank of India in different supervisory roles for a long time. A long stint as guest faculty at South Indian Bank, IDBI Bank, Allahabad Bank and at various other financial institutions has propelled him as a renowned name in the financial sector. Prof. Ghosh has published several articles and books on different aspects of finance.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Atanu.png" alt="Prof. Atanu Ghosh" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Atanu Ghosh</strong></p>
                                    <p><strong>Education:</strong> Post Graduate Diploma in Management from IIM Bangalore followed by a Bachelor Degree in Electrical Engineering</p>
                                    <p><strong>Core Area:</strong> Digital transformation, digital marketing, analytics, e-commerce, blockchain and ERP</p>
                                    <p>Prof. Ghosh has designed and delivered full courses at leading business schools in India and outside including XLRI, IIM Calcutta and IIM Udaipur. He is a consultant and coach to leading corporate and government departments and assists them in formulating their digital transformation, marketing and analytics strategy. Some of the notable clients consulted, coached and trained by Prof. Ghosh are National Police Academy Hyderabad, ABP Group, Asian Paints, ITC, CESC, National Insurance, Emami Group and Department of Tourism, Govt. of West Bengal. He is the promoter, founder and CEO of Bluebeaks Solutions. Before his entrepreneurial stint, Prof. Ghosh was a Director with IBM and, prior to that, a Principal with PwC where he worked extensively in India, USA, UK, SE Asia and China consulting leading Fortune 100 Companies on their SAP and ERP deployment.
                                    </p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Prof-Indranil-Chakraborty.png" alt="Prof. Indranil Chakraborty" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Indranil Chakraborty</strong></p>
                                    <p><strong>Education:</strong> Post graduation in English Literature and Diplôme Supérieur en Français</p>
                                    <p><strong>Core Area:</strong> French Language and Literature, English Language and Literature, Translation, Interpretation</p>
                                    <p>Prof. Indranil Chakraborty has been primarily associated with the world of academics since 1994. He has taught in IIFT, Loreto College, Jadavpur University, Techno India Colleges, IAM, IIHM, and in a host of other prestigious institutes. Currently he teaches at the Alliance Française du Bengale, IIM Calcutta, and University of Calcutta at the postgraduate level.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Prof-Anshu-Bery.png" alt="Prof. Anshu Bery" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Anshu Bery</strong></p>
                                    <p><strong>Education:</strong> MBA (Gold Medalist) IIPM; B.Sc. (University Topper) – Delhi University</p>
                                    <p><strong>Core Area:</strong> Communicative English, Soft Skills, Employability Skills</p>
                                    <p>Prof. Anshu Bery has completed her MBA in Human Resource Management from IIPM. She has an experience of both academia and industry in the education sector. As a faculty, she has experience to teach Human Resource Management, Soft Skills, Business Communication and Business English at various leading educational institutions in the country. Her rich experience at eminent corporate houses, over the years, has enriched her industry acumen. Her portfolio includes Education Policy Design and Development including Conceptualization, Implementation and Impact of Literacy Programs pan India, as a Deputy Director – Programs, Rotary India Literacy Mission.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Prof-Sangita-Bhattacharya.png" alt="Prof. Sangita Bhattacharya" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Sangita Bhattacharya</strong></p>
                                    <p><strong>Education:</strong> MA in English Literature; PGCTE and PGDTE in English and Foreign Language University, Hyderabad</p>
                                    <p><strong>Core Area:</strong> Business Communication, Softskills, Accent Neutralization and Motivation</p>
                                    <p>Prof. Sangita Bhattacharya is a specialized language trainer, a DiSC certified Leadership Coach and a Soft Skill Expert working extensively with Banking, IT, Hospitality, Telecommunication, and Pharmaceutical Industries for a holistic development of their employees globally. As a Resource Person of Oxford University Press she conducts workshops for school teachers in the Indian Subcontinent. Prof. Bhattacharya is also a transformation coach who works with individuals and groups to help them achieve their professional and personal goals.</p>
                                </div>
                                <hr />
                                <br />
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Prof-Kaustav-Majumder.png" alt="Prof. Kaustav Majumdar" width="167" height="195" /></div>
                                <div className="one-table2">
                                    <p><strong>Prof. Kaustav Majumdar</strong></p>
                                    <p><strong>Education:</strong> Advanced degree in Leadership, Strategy & Social Psychology, London Business School; Computer Science degree from the University of Southampton, UK</p>
                                    <p><strong>Core Area:</strong> Digital Marketing, Customer Service Entrepreneurship including Mindset, Understanding Business Models, Digital Platforms & Networks, Communication & Behavior, and Interview Skills</p>
                                    <p>Prof. Kaustav Majumdar has an advanced degree in Leadership, Strategy & Social Psychology from the London Business School and a Computer Science degree from the University of Southampton, UK. He has 26 years of experience and a long list of professional achievements tagged to his name including one in Psychometrics from the University of Cambridge. Prof. Majumdar has an enriching repertoire of academic experience at the crème de la crème of educational bodies across the country.</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
