import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'


export default function RoleOfSimulaton() {
  return (
    <><title>Role of Simulation in Management Education</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">Role of Simulation in Management Education</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog"> Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to=""> Role of Simulation in Management Education</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>

                    <div className="one-table">
                        <p>
                            <img className="img-fluid" src="/assets/images/Simulation_Blog.jpg" alt="Simulation" />
                        </p>
                        <div className="entry-header">
                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>Simulation is an imitation of certain operations of a system. In the modern era, simulation is widely used in the field of education. Earlier this approach was used in military and aviation industries to maximize safety during the training, while minimising the risk. Later it was adopted in several other fields, like education – especially, management and medical education – to prepare students for real life case studies and scenarios. Simulation-based Learning (SBL) provides simplified practical experience to learners on real-time events and situations. According to Robert Mills Gagné, an educational psychologist, “A simulation represents an actual situation carrying operations, thereby providing the user with certain controls over the situation while omitting certain distracting variables that signifies irrelevancy for the particular instructional goals.”</p>
                        <p>Nowadays, SBL relies mostly on the usage of advanced technologies and computers, which help to provide enhanced learning to users. Especially in management education, SBL provides an opportunity for the students to develop and enhance their knowledge, skills, attitude and understanding of real-life corporate scenarios, as well as hone their management skills through an array of activities, such as the Boardroom Activity – a Globsyn Business School best practice.</p>
                        <p>In a Boardroom simulation activity, students work in small teams to simulate the behaviour of the executive team of a real-world organisation, following the company and monitoring its activities (e.g. regularly checking their website, collecting data from electronic sources, the financial press and annual reports etc.). The activity culminates in a role-play of a Boardroom meeting where experienced company executives chair the session and provide guidance, direction and feedback.</p>
                        <p>Let us see some notable key aspects of Simulation-based Learning:
                        <ul className='disc'>
                            <li>Provides critical feedback of the students</li>
                            <li>Leads to the improvement of skills by repetitive practice</li>
                            <li>Integrates curriculum of simulation-based learning</li>
                            <li>Helps to practice with a range of difficulty levels</li>
                            <li>Teaches not just teacher-centred approach but multiple learning strategies</li>
                            <li>Discusses a variety of management issues</li>
                            <li>Controls the situation from the negative consequences of an error</li>
                            <li>Promotes active individualized learning</li>
                            <li>Measures the actual outcomes</li>
                            <li>Represents actual boardroom</li>
                        </ul>
                        </p>
                        <p>Globsyn Business School (GBS), like any other leading management institutions across the globe, has incorporated a myriad of SBL activities to its ‘learner-centric’ pedagogy including exercises and role-plays ‘beyond traditional education’, which has become an integral part of the GBS curriculum to ensure that the students develop sufficient cognitive, inter-personal and intra-personal skills for their future corporate life.</p>
                        <p>At GBS, in a typical SBL activity, the students are divided in teams of 10-12 members, where each and every member of each team are assigned specific roles of Executive and Non-Executive Directors with a representative from the corporate playing the role of the ‘Chairman’ of the ‘Board’ that is tasked upon to solve simulated business crisis situations within the next 2 months. During this period the students, mentored by a faculty of GBS, conducts extensive researches and finally present a solution to the ‘Chairman’, which the corporate either modifies or implements in their organisation.</p>
                        <p>Globsyn Business School believes that these SBL activities will help the students imbibe management and intrapreneurial skills, become dedicated and compassionate managers and be technologically bent, which will make them truly ‘ready’ and ‘relevant’ for the global corporate industry.</p>

                        
                    </div>

                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
