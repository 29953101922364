import React from 'react'
import Sidebar from '../../Sidebar'
import { Link } from 'react-router-dom'

export default function Embroyn() {
  return (
    <><title>Embryon - Globsyn</title>
    <section className="hero-banner-img-sceren">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">Embryon</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/innovation">Embryon</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>
                    <div className="one-table">
                        <p>Globsyn Business School (GBS) motivates students to use their theoretical knowledge, thoughts and imagination in formulating feasible business plans during their course of study. ‘Embryon’ – the entrepreneurship cell of GBS, incubates these ideas and provides students the platforms to showcase their business plans to an independent expert panel comprising of industry veterans, whose inputs help shape the ideas into successful ventures.</p>
                        <p>This panel of judges, comprising of stalwarts from various fields of academia and corporate world, after various rounds of careful considerations chooses a winning team/s. The teams with best ideas are mentored to convert the ideas into business models with the inputs from successful entrepreneurs, angel investors, incubators and professional experts, as well as get the opportunity to take their ideas forward at various national platforms.</p>
                        <p>Recently ‘Embryon’ – the entrepreneurship cell of GBS has been recognized among 24 business schools, globally, as an AACSB International 2021 Innovation That Inspires. This annual program recognizes institutions from around the world that serve as champions of change in the business education landscape.</p>
                        <button className='btn'><a href='https://www.aacsb.edu/about-us/advocacy/member-spotlight/innovations-that-inspire/2021/globsyn-business-school?fbclid=IwAR2DYevclkGUzzuk8mH8QDYLEGCP3QAaa5-soJHjtCen0kkPYyJLNGj1weI'>AACSB International 2021 Innovation That Inspires</a></button>
                        <br />
                        <br />
                        <br />
                        <p>Embryon holds a number of entrepreneurial events for students over the year such as <strong>EntreArena</strong> and <strong>Funnovation.</strong></p>                             
                        <div className="accordion" id="myAccordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">EntreArena</button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                    <div className="card-body">
                                        <p>This event is aimed at enabling experiential learning for the students by motivating them to setup implementable, financially viable and scale-able business ideas.</p>
                                        <p style={{ textAlign: 'center' }}><iframe className="w-100" height="450" src="https://www.youtube.com/embed/xQSucTVPemM" title="Best Private B-School in Eastern India - Globsyn Business School | Times Brand Icons 2022" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingtwo">
                                    <button type="button" className="accordion-button collapsed " data-bs-toggle="collapse" data-bs-target="#collapsetwo">Funnovation</button>
                                </h2>
                                <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                    <div className="card-body">
                                        <p>This event is aimed at motivating students to come out with implementable, financially viable and scalable business ideas.</p>
                                        <img src="./assets/images/Funnovation-pics.png"  alt="funnovation" decoding="async"  width="640" height="320" />
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingthree">
                                    <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Startup Cafe</button>
                                </h2>
                                <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                    <div className="card-body">
                                        <p>Created with a vision to provide the youth of the state a new-age working environment, Startup Café (SUC) – an extension of Embryon – has been setup as an incubation centre in the heart of Sector V, amidst a 15000 sqft co-working space built by Globsyn.</p>
                                    <p>With names like Arjun Malhotra (Co-founder, HCL; Former Chairman & Founder, TechSpan; Chairman & CEO, Headstrong Corp.), Rajendra S. Pawar (Co-Founder, Chairman & Managing Director, NIIT Group), Pradeep Gupta (Chairman, CyberMedia), Pradeep Kar (Chairman & Managing Director, Microland Limited), Pravin Gandhi (Ex-Chairman & Co-Founder, Hindtron. Ex-Director, Digital Equipment Corp.) and Vasant Subramanyan (Founder & Director of Last Peak Solutions) as part of its advisory body, Startup Café has brought together the best entrepreneurial minds to incubate and mentor its new-gen incubatees, helping them find their feet while enabling them to hit the ground running when they are ready.</p>
                                    <p>Incubatees of Startup Café are provided with academic intervention sessions, which will help them to understand the basic nuances of running a business, technology expertise to ensure their startups are equipped with the most advanced technology platforms and mentorship necessary for them to weave through seemingly inextricable hurdles and leap into the unknown.</p>
                                    <img src="./assets/images/Funnovation-pics_2-1.png"  alt="funnovation" decoding="async"  width="640" height="320" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
