import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'


export default function LiquidityCrunch() {
  return (
    <><title>Liquidity Crunch and its Effect on the Economy</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Liquidity Crunch and its Effect on the Economy</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Liquidity Crunch and its Effect on the Economy</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/2945657-1200x600.jpg" alt="Liquidity Crunch and its Effect on the Economy" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Every family has a compulsion bearing list of daily expenses. Without cash, these daily expenses cannot be borne. The earning member in our family supplies the required cash that keeps steady the daily family expenditure. On the contrary, in the absence of any earner of a family, it is required to spare the savings amount to meet the daily expenditure which is not desirable. Likewise, a firm has two types of earnings. One is capital earnings and the other is revenue earnings. Liquidity deals with revenue earnings. The daily operating needs of the firm are managed by the available cash or cash equivalent. The liquid assets are utilised to meet the dues like regular expenses of the firm and debts. Any interruption in this process for a few days may lead to bringing liquidity crunch. Even, the continuation of such a disruption for more days with regards to the supply of money may close the operation of the firm.</p>
                                <p>In such a severe stage, the large firms can continue the operation for a longer period in comparison to Micro, Small, Medium Enterprises (MSMEs). Alike using the savings balance in the absence of regular earnings, the big firms can borrow the part of their capital internally to mitigate the situation although it is a rare practise exercised by the firm. In the current situation, when the whole world encounters the dreadful and contagious virus called COVID-19 the economy across the world is facing perhaps its greatest emergency. The MSMEs of Indian counterpart are poorly affected by the announcement of lockdown. The liquidity crunch likely to occurs of these institutions due to the burden of daily obligations like fixed payables. Rent, repayment of loans, salary are the major items that are required to be met up by these small and medium scale institutions. Cash shortage threatens the future of these firms. Many small scale firms are stumbling and they are bound to close their business due to shortage of cash. Till date, at least 43% of the MSMEs have disappeared from the market.</p>
                                <p>For the survival of these firms, the Government should undertake feasible measures like, announcement of stimulus packages to the cash crunched firms for their revival. The State and Centre should come up together to figure out the requirements in revamping the shocking conditions of these firms. The participation of the Bank is also necessary in this situation. The bank should disburse loans with low interest to the small and medium firms. However, for quick revival of the worst affected firms, the proactive approach like direct benefit transfer (DBT) should be undertaken where disbursement of cash can be made to replenish the shortfall of cash for those firms where least resources are leftover.</p>
                                
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
