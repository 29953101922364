import React from 'react'
import './Common.css'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar'


export default function IconSpeak() {
    return (
        <>
            <title>icon-speaks</title>
            <section className="hero-banner-img-inno">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Icon Speaks</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">icon-speak</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">

                            <div className="row">
                                <p>Globsyn Business School has always provided its young managers with the perfect platform to embrace research, exchange ideas on cutting-edge breakthroughs in management practices, present papers in front of esteemed academic practitioners and industry professionals, and stay motivated in the pursuit of research in the future.
                                </p>
                                <p >Over the years, a number of accomplished and influential stalwarts across various industry-academia domains have graced the B-School with their presence, and contributed significantly to its development and growth. Together, these celebrated minds have made Globsyn Business School one of the most respected and sought-after management institutes in India.</p>
                                <div className="col-md-6 hover-side margin-top-5"><div className=""><a className="popup-youtube" href="https://www.youtube.com/watch?v=eLNYpVj06cA"><img className="borderr rounded " src="https://www.globsyn.edu.in/wp-content/uploads/2023/03/Dr.-Anil-Sahasrabudhe-023.jpg" alt="1st-img" /></a></div></div>
                                <div className="col-md-6 margin-top-5"><a className="popup-youtube" href="https://www.youtube.com/watch?v=6t4hs5lFKlU"><img className="borderr rounded" src="https://www.globsyn.edu.in/wp-content/uploads/2023/03/Dr.-James-McHann2.jpg" alt="1st-img" /></a></div>
                                <div className="col-md-6 margin-top-5"><a className="popup-youtube" href="https://www.youtube.com/watch?v=chXomM3Hgvs"><img className="borderr rounded" src="https://www.globsyn.edu.in/wp-content/uploads/2023/07/Mr.-Rajendra-S.-Pawar.jpg" alt="1st-img" /></a></div>
                                <div className="col-md-6 margin-top-5"><a className="popup-youtube" href="https://www.youtube.com/watch?v=XtNnkuPgByc"><img className="borderr rounded" src="https://www.globsyn.edu.in/wp-content/uploads/2023/07/Shri-Anoop-Kumar-Agrawal-IAS.jpg" alt="1st-img" /></a></div>
                                <div className="col-md-6 margin-top-5"><a className="popup-youtube" href="https://www.youtube.com/watch?v=Qp41WNoYrhc"><img className="borderr rounded" src="https://www.globsyn.edu.in/wp-content/uploads/2023/07/R-Golpalakrishnan.jpg" alt="1st-img" /></a></div>
                                <div className="col-md-6 margin-top-5"><a className="popup-youtube" href="https://www.youtube.com/watch?v=AO-KWe6zCWQ"><img className="borderr rounded" src="https://www.globsyn.edu.in/wp-content/uploads/2023/07/Mr.-Narayana-Murthy.jpg" alt="1st-img" /></a></div>
                                <div className="col-md-6 margin-top-5"><a className="popup-youtube" href="https://www.youtube.com/watch?v=gUFchIapn0U"><img className="borderr rounded" src="https://www.globsyn.edu.in/wp-content/uploads/2023/07/Mr.-Hemant-Kanoria.jpg" alt="1st-img" /></a></div>
                                <div className="col-md-6 margin-top-5"><a className="popup-youtube" href="https://www.youtube.com/watch?v=e5ZbeVm9je8"><img className="borderr rounded" src="https://www.globsyn.edu.in/wp-content/uploads/2023/04/Dr.-Anil-Sahasrabudhe-01.jpg" alt="1st-img" /></a></div>
                                <div className="col-md-6 margin-top-5"><a className="popup-youtube" href="https://www.youtube.com/watch?v=QIJiz0BHpVE"><img className="borderr rounded" src="https://www.globsyn.edu.in/wp-content/uploads/2023/07/Mr.-Chandra-Shekhar-Ghosh.jpg" alt="1st-img" /></a></div>
                                <div className="col-md-6 margin-top-5"><a className="popup-youtube" href="https://www.youtube.com/watch?v=t6e4pvVSEsc"><img className="borderr rounded" src="https://www.globsyn.edu.in/wp-content/uploads/2023/07/Mr.-Abhijit-Roy.jpg" alt="1st-img" /></a></div>






                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
