import React from 'react'
import './Common.css'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar'

export default function Newsletter() {
    return (
        <>
            <title>GBS Newsletter</title>
            <section className="hero-banner-img-inno">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Newsletter</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">Newsletter</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                                <h1 className="vc_custom_heading">Newsletter</h1>
                            </div>
                            <div className="one-table">
                                <div className="one-table2">
                                    <p>These quarterly Newsletters in the form of a retrospective capture, showcases the various activities ranging from academic delivery to placement drives to promotional events to tech-innovations to HR initiatives to various other activities in the Globsyn Business School ecosystem.</p>

                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th>Newsletter</th>
                                                <th>Link</th>
                                            </tr>
                                            <tr>
                                                <td>GBS Newsletter: Jan-Mar, 2023</td>
                                                <td><a href="https://www.globsyn.edu.in/Digital_Newsletter_Apr.23" target="_blank" rel="noreferrer"><img decoding="async" src="./assets/images/8468586_paper_texture_background_cardboard_pattern_icon.png" width="24" alt='icon' /></a></td>
                                            </tr>
                                            <tr>
                                                <td>GBS Newsletter: Oct-Dec, 2022</td>
                                                <td><a href="https://www.globsyn.edu.in/eNewsletter_Jan.23" target="_blank" rel="noreferrer"><img decoding="async" src="./assets/images/8468586_paper_texture_background_cardboard_pattern_icon.png" width="24" alt='icon' /></a></td>
                                            </tr>
                                            <tr>
                                                <td>GBS Newsletter: Jul-Sep, 2022</td>
                                                <td><a href="https://www.globsyn.edu.in/QuarterlyNewsletter_July-Sept-22" target="_blank" rel="noreferrer"><img decoding="async" src="./assets/images/8468586_paper_texture_background_cardboard_pattern_icon.png" width="24" alt='icon' /></a></td>
                                            </tr>
                                            <tr>
                                                <td>GBS Newsletter: Jan-Mar, 2022</td>
                                                <td><a href="https://www.globsyn.edu.in/QuarterlyNewsletter_Jan-Mar-22" target="_blank" rel="noreferrer"><img decoding="async" src="./assets/images/8468586_paper_texture_background_cardboard_pattern_icon.png" width="24" alt='icon' /></a></td>
                                            </tr>
                                            <tr>
                                                <td>GBS Newsletter: Oct-Dec, 2021</td>
                                                <td><a href="https://www.globsyn.edu.in/QuarterlyNewsletter/" target="_blank" rel="noreferrer"><img decoding="async" src="./assets/images/8468586_paper_texture_background_cardboard_pattern_icon.png" width="24" alt='icon' /></a></td>
                                            </tr>
                                            <tr>
                                                <td>GBS Newsletter: Jul-Sept, 2021</td>
                                                <td><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/03/Newsletter_Final-version_July-Sept2021_Volume-3-Issue-1.pdf" target="_blank" rel="noreferrer"><img decoding="async" src="./assets/images/8468586_paper_texture_background_cardboard_pattern_icon.png" width="24" alt='icon' /><br />
                                                </a></td>
                                            </tr>
                                            <tr>
                                                <td>GBS Newsletter: Jan-Mar, 2021</td>
                                                <td><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/03/Newsletter_Final-version_Jan-March2021_Volume-2-Issue-3.pdf" target="_blank" rel="noreferrer"><img decoding="async" src="./assets/images/8468586_paper_texture_background_cardboard_pattern_icon.png" width="24" alt='icon' /></a></td>
                                            </tr>
                                            <tr>
                                                <td>GBS Newsletter: Oct-Dec, 2020</td>
                                                <td><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/03/Newsletter_Final-version_Oct-Dec-2020_Volume-2-Issue-2.pdf" target="_blank" rel="noreferrer"><img decoding="async" src="./assets/images/8468586_paper_texture_background_cardboard_pattern_icon.png" width="24" alt='icon' /></a></td>
                                            </tr>
                                            <tr>
                                                <td>GBS Newsletter: Jul-Sept, 2020</td>
                                                <td><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/03/Newsletter_Final-version_July-Sept2020_Volume-2-Issue-1.pdf" target="_blank" rel="noreferrer"><img decoding="async" src="./assets/images/8468586_paper_texture_background_cardboard_pattern_icon.png" width="24" alt='icon' /></a></td>
                                            </tr>
                                            <tr>
                                                <td>GBS Newsletter: Apr-Jun, 2020</td>
                                                <td><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/03/Newsletter_Final-version_April-June2020_Volume-1-Issue-1.pdf" target="_blank" rel="noreferrer"><img decoding="async" src="./assets/images/8468586_paper_texture_background_cardboard_pattern_icon.png" width="24" alt='icon' /></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
