import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function RememberingNB() {
    return (
        <><title>Remembering Nandan Bhattacharya</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Remembering Nandan Bhattacharya</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Remembering Nandan Bhattacharya</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Today, as I get out from my bed in the morning and recount many memories, think of the very existence and growth of Globsyn Group in Kolkata, be it Infinity, TechnoCampus, Software Finishing School or Globsyn Crystals – which are the very foundations of existence and well-being in our professional and even personal life – has happened because of one person. He held my hand, advised and guided me, took me to the power echelons in a city, which I did not know at all during my early days, and laid the foundation of Globsyn Group that everyone knows of today. This person is none other than my very own Nandan Da – Nandan Bhattacharya to many – who passed away yesterday, and I have been thinking of him and his contribution to me, my family, Globsyn Group, and society at large…</p>
                                <p>It all started in 1994 when I was invited to talk in a Webel Seminar and spoke on ‘Doing Business in Bengal’. I had just left a very successful career in PCL and was well known in the IT Industry in Delhi-Bangalore-Mumbai, and Nandan Da, as the MD of Webel, wanted me to come to Bengal and start my new innings. Nandan Da simply said, “Tell me what you want.” I told him about my vision of building an Institution & Infrastructure model for the IT Industry in Bengal, which will generate millions of jobs for the young intelligent community.</p>
                                <p>He held my hand and took me straight to the then CM, Late Shri Jyoti Basu, and sought his blessings to work towards building the IT Industry in Bengal. Thus, came Infinity, Globsyn Crystals, Globsyn Finishing School, Globsyn Infotech and Globsyn Business School.</p>
                                <p>Today, when thousands of youngsters walk around in Sector V and huge complexes have come up, I am reminded of the discussions which Nandan Da used to have with us about these 85 acres of land given by the then CM, lying barren those days. Truly, he is the architect of Sector V that everyone sees today. I urge citizens whose sons and daughters work in Sector V to spare some time in the memory of this visionary.</p>
                                <p>It was all his inspiration, guidance and advice, which guided me during those days. Globsyn is as much his creation as it is ours.</p>
                                <p>I pay my deepest respect to this gem of a person, who thought about the tomorrow, yesterday.</p>
                                <p>Love you, Nandan Da.</p>
                                <p>May his soul rest in peace.</p>
                                <br />
                                <p><strong>– Bikram Dasgupta</strong></p>
                                <p>Founder & Executive Chairman, Globsyn Group</p>

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
