import React from 'react'
import Sidebar from '../../Sidebar'
import { Link } from 'react-router-dom'

export default function Alumni() {
    return (
        <><title>Alumni – My Story - Globsyn</title>
            <section className="hero-banner-img-sceren">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Alumni – My Story</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Alumni – My Story</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <div className="accordion" id="myAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Khalid Shamim, Associate Vice President, Universal Asset Management AG - Batch 1</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>I am based in Dubai working as Director for Wondrous Wealth, UAE but my journey has been a roller coaster ride far from a smooth sail. After finishing university I wasn’t sure about my career path when a surprise phone call from an old friend got me introduced to Globsyn Business School and I decided to pursue PGDM. Academics was always the focus however extracurricular activities always interested me. I wanted to be on stage but was scared of public speaking, all that changed during our first Serendipity where I landed an accidental role as a lead of a drama portraying an American Businessman, this one act of courage to face my demons transformed my personae and I became more of an extrovert that helped me a lot in my later life. I graduated from Globsyn in 2004 and got a campus placement to Iffco-Tokio General Insurance and from there I moved onto Standard Chartered Bank working as a Relationship Manager. Then in the winter of 2007 I decided to move to Dubai to join ABN AMRO which was later acquired by Royal Bank of Scotland, I obtained my certification from CISI, UK and I worked there for about 2 years.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingtwo">
                                            <button type="button" className="accordion-button collapsed " data-bs-toggle="collapse" data-bs-target="#collapsetwo">Ranjana Gupta, Director South Asia, Kantar - Batch - 1</button>
                                        </h2>
                                        <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>It’s been more than 10 years since I first stepped into GBS. Being a member of the first batch, GBS was a huge leap of faith but one which I have never regretted. It gave me some new friends for life and cemented some old friendships. It taught me to deal with pressures – all those late nights working on assignments, presentations every week – it was a grind that has given me the stamina to deliver in professional world. And trust me, the pressure here makes a typical GBS day look like a cakewalk. The biggest high for me during the two years was Serendipity – the fest that our batch established. It was a humble start yet I don’t think any of us had more fun than that. And the most satisfying feeling at the end of the fest was the look of pride on the faces of our mentor – Bikram Dasgupta and our entire panel of faculties. Even now, when I feel that I cannot keep up anymore, I just take a leaf out of those days and persevere. GBS has given me that and nothing can ever take that away.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingthree">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Arunanshu Ray, Chief Executive, Encore HR Services India - Batch - 1</button>
                                        </h2>
                                        <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Before doing my Post Graduation from Globsyn, I had the privilege of having the experience of working in an MNC set-up (Tech Pacific India Ltd., erstwhile Godrej Pacific India), but one basic thing which I found missing in me during those formative years was the ‘Structure’ – the way of understanding an issue and jotting down the way forward.</p>
                                                <p>Globsyn as a respectable Corporate Brand was pretty well known. So when the initial Advertisement regarding the starting of the Management faculty came, I opted for it and feel that doing MBA from GBS has instilled the faith in my abilities. The grooming which we got from Mr Bikram Dasgupta in his ‘Learn and Intern sessions’ were LIFE lessons… It has made the person we are today.</p>
                                                <p>Coming to placements, I was selected by Ma Foi Management Consultants Ltd, a market leader in HR Consulting and Recruitments, wherein I looked after East for the Recruitments and Outsourcing business development. The exposure of working there had been phenomenal. The next move was to Bennett Coleman (Times Business Solutions Ltd.) where I was entrusted as the Profit Center Head for their Recruitment wing. Then the entrepreneurial bug which had been subdued for last few years hit me, and I ventured on my own into HR Services starting my firm Encore HR Services India. Its been few years I am on my own and the ride is On…</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingfour">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefour">Sudip Patra, Pursuing PhD and Teaching faculty at Glasgow University - Batch - 5</button>
                                        </h2>
                                        <div id="collapsefour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>I have done my PhD in Finance from the University of Glasgow, one of the top 50 universities of the world having a long legacy of Adam Smith, Lord Kelvin, Carlyle and others, and it’s also among the Russel group in UK. It has been a great journey for me till now from Globsyn days to here. I have always considered that my base in finance and economics had developed at Globsyn  and has been very critical for me in later en-devours.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingfive">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefive">Hemant Kothari, Chief Business Analyst - Stovekraft Pvt. Ltd. - Batch - 3</button>
                                        </h2>
                                        <div id="collapsefive" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>I joined Globsyn to pursue my MBA after my graduation from Goenka College. Days in Globsyn were good academically and I learned a lot during this period. The sessions of Mr. Bikram Dasgupta were good for self realisation.</p>
                                                <p>I got an offer from ICICI Prulife and it was good learning there. Then I joined Emami Ltd. as EA to CEO & MD which was the turning point in my career. It gave me the required ground to make a big jump in my career. I was instrumental in making big decision in the organisation like mergers, product launch, cost rationalisation and bigger projects update. It helped me to make a bigger shift to Sahaj e Village Ltd. (an associate company of SREI Infrastructure Finance Ltd.) as AVP – Planning & Strategic Initiatives.</p>
                                                <p>I am a firm believer of ‘achiever’s achieve’. During difficult time, I learned from my mistakes and worked on my mistake. The important recipes of success are hard work, patience as an approach, quick thinker, and good communicator with pinch of luck. Globsyn has being a finishing school for many of us as it has been for me.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingsix">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsesix">Parantap Dasgupta, Leading Global Business Intelligence at Digital Office, IKEA - Batch - 1</button>
                                        </h2>
                                        <div id="collapsesix" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Looking back at my career there were many inflection points, but none of them rivals walking through the door of Globsyn Business School on the first day. I have grown in many ways after passing out and will always treasure the opportunities provided to me by GBS. I’ve already gone through various obstacles in the corporate career so far, where it takes a lot of patience and effort to figure out how things work, but there’s always been a GBS learning from past to come up with a solution or speed things up. I thank all the staff members of GBS for their support and the cooperation that they have extended to me during my tenure at GBS.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingseven">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseseven">Anirban Roy, Associate Vice President at AICL - Batch - 5</button>
                                        </h2>
                                        <div id="collapseseven" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Those were the days when I had just passed out of college, I was dazed and confused. This was when I stepped into Globsyn. Globsyn catalysed my transformation from just another graduate into someone fit to take on the persistent grind of the corporate world. The blend of practical and the theoretical in the curriculum made it easier to deliver on a daily basis. The courses are updated and relevant to the respective fields and the professors guided us in the right direction. I am especially thankful to our HOD for the valuable inputs which had a significant bearing not just on the professional front but the personal as well.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingeight">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseeight">Zeeshan Furrukh, Head Treasury operations at Bank of Baroda, UK - Batch - 6</button>
                                        </h2>
                                        <div id="collapseeight" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>I graduated with B.Com (Hons) from Umes Chandra College and also completed my M.Com in Accounts & Finance from Calcutta University in the year 2007. On the same year I joined GBS for my MBA. I specialized in Finance as my major and marketing as minor subjects. Though it was my second masters but value addition which I had in GBS in terms of learnings, skills development, communication skills and holistic personality development etc, was tremendous. I started looking at my subjects especially finance with new dimensions and thought process resulting in thinking beyond conventional theories and practices. Finally, I got placed in Bank of Baroda as Assistant Manager from the campus.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingnine">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsenine">Sudipta Ghosh – Head of HR, Flipkart  - Batch - 1</button>
                                        </h2>
                                        <div id="collapsenine" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>I am privileged to be a student of Globsyn Business School (Batch 1, 2002-2004). This journey of two years has been enriching and with a lasting impact on our careers. The sincerity exhibited by the faculty in honing our knowledge and skills is unparallel. The infrastructure of the B-School is perfect to support collaboration, and constructive ideation amongst students and faculties. Thank you, Globsyn Business School for everything, and I wish you everlasting success in building careers of budding corporate citizens.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingten">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseten">Shaila Binyakia Bothra – Senior Brand Manager, Jyothy Laboratories Ltd. India - Batch -2</button>
                                        </h2>
                                        <div id="collapseten" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Globsyn Business School has given me a platform for indentifying and nurturing my strengths, my
competencies, thereby helping me to grow as a professional. It moulded us through projects, live assignments and industry exposure, which eventually became the stepping-stone to our illustrious professional careers. I am proud to be associated with GBS and take this opportunity to thank BDG and all faculty members for everything they have done to help in our journey from being an amateur to a professional.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
