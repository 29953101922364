import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ThinkingSocial() {
  return (
    <><title>‘Thinking Social’ Seminar</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">‘Thinking Social’ Seminar</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">‘Thinking Social’ Seminar</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Hello Readers, I’m Minal Khetan, pursuing Post Graduate Diploma in International Business and Marketing from Globsyn Business School.</p>
                                <p>In this blog, I would like to share my experience at the ‘8th Tata Social Enterprise Challenge Launch Event & Thinking Social Seminar’, an event organized by Tata Social Enterprise Challenge in collaboration with IIM, Calcutta. I am overwhelmed that I, along with 11 other students of Globsyn Business School, received the opportunity to attend the 2.5-hour seminar. The seminar helped us to understand the functionality and nuances of a social enterprise as well as helped us in getting insights into the world of entrepreneurship.</p>
                                <p>The engagement discussed various topics, which was commenced as follows:</p>
                                <ul className='disc'>
                                    <li>Inaugural Address</li>
                                    <li>Address on Social Entrepreneurship and TSEC</li>
                                    <li>TSEC Contest Launch</li>
                                    <li>Address by Chief Guest</li>
                                    <li>Panel Discussion on Social Entrepreneurship</li>
                                </ul>
                                <p>The panel discussion on ‘Social Entrepreneurship’ helped me to understand that the reason for the existence of a business is to serve the community. The eminent panelists explained to us that serving the community is an action that benefits others. Further, I got to learn that with the birth of a social enterprise, an individual becomes responsible to deploy resources in an industry to make it self-sustainable. Moreover, the event also helped me to understand the importance of funding prospects, data recording, good team, the role of an influential leader and the need for planning of backups.</p>
                                <p>While witnessing the manner in which the veterans explained the topics, I felt motivated. I learned that we should not be afraid of failures before starting a venture, instead, we should invest a minimum of 3 years in a Start-Up. Among all the speakers, I liked the way one of the speakers mentioned that in the journey of fulfilling our dreams, we have to take chances, we have to stay determined and should not leave any of the stones unturned. He projected the proposition that an entrepreneur should leave a mark behind in the world and there should be an existence of an idea which one must live for.</p>
                                <p>The Thinking Social Seminar was a great platform and served as an instrument in understanding the key points and challenges one faces before and while setting up a new venture. It was a great experience that addressed important issues concerning ideation, funds, and implementation. The seminar encouraged all the Globsyn students to join a forum that enables individuals to talk about social responsibility towards rising and existing social problems and means to solve them in a sustainable manner.</p>
                                
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
