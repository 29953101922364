import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function LookingBackYou() {
  return (
    <><title>Here’s Looking Back at You, 2021</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Here’s Looking Back at You, 2021</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Here’s Looking Back at You, 2021</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/website-blog-images_-final.png" alt="Here’s Looking Back at You, 2021" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>This year, when the month of December rolled by, it seemed like 2021 went by in the blink of an eye! This was the year that will go into the history books as one of the most intense years so far. If 2020 personified disruption with the pandemic making the world go topsy-turvy, then 2021 was all about sustainability in the age of disruption! And that’s pretty intense!</p>
                                <p>As a two-decade old postgraduate management institution, disruption has always been a part of Globsyn Business School’s (GBS) daily calendar. However, GBS has consistently been successful in overcoming these hurdles, with the pandemic being hardly any exception. As an institution that has worked on integrating technology into its pedagogy since its inception, shifting the program online was relatively a smooth sail. The biggest challenge in 2021, however, was the preservation of this new mode of academic delivery. </p>
                                <h3>INDUCTION OF BATCH 2021-23</h3>
                                <p><img className="img-fluid" src="/assets/images/Induction.jpg" alt="Induction" /></p>
                                <p style={{textAlign:"center"}}><b>Induction Ceremony of Batch 2021-23</b></p>
                                <p>With the COVID restrictions, with physical classes still being a distant dream in the earlier months of 2021, Globsyn Business School conducted a unique month-long digital Onboarding Program for the management aspirants of Batch 2021-23, which was the first of its kind in India. The program inducted the management students into the Globsyn ecosystem, in the presence of veterans from the industry and academia. The program also consisted of an interactive session- ‘Rethinking the MBA’ and an open forum for Q&A conducted by Mr. Rahul Dasgupta, Trustee and Director, GBS, which provided the young managers with a fresh perspective towards their MBA Education. </p>
                            <p>Soon after, the B-School conducted a Foundation Program for its aspirants, in an attempt to bridge the gap between their undergraduate knowledge and the management curriculum and to build a culture that promotes ethical academic practice. Under the umbrella of the Foundation Program, an insightful session with alumnus Mr. Anindya Banerjee, Vice President, Kotak Securities, and a ‘Quiz Time’, themed ‘Play and Learn’ were conducted that sensitized the students towards their ethical obligations, responsibilities, and tested their knowledge acumen respectively. </p>
                            <h3>ACADEMIC EXCELLENCE</h3>
                            <iframe width="762" height="384" src="https://www.youtube.com/embed/FbGDY3YCspg" title="Bridging the gap between knowing and doing - Mr. R. Gopalakrishnan | Globsyn Business School" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            <p style={{textAlign:"center"}}><b>An online webinar with Mr. R. Gopalakrishnan, Author and Corporate Advisor</b></p>
                            <p>The most daunting challenge that GBS overcame in 2021 is, unarguably, the sustenance of its Academic Delivery. The B-School has stayed ahead of the learning curve, even during these uncertain times, by conducting numerous academic activities in the form of Webinars, Corporate Connects, Round Table Discussions, Interactive Sessions, Leadership Talks, and a Student Development Program, simultaneously and seamlessly woven with its regular digital classes. s</p>
                            <p>The Webinar on ‘Bridging the gap between Knowing and Doing’ with eminent author and corporate advisor, Mr. R. Gopalakrishnan, was instrumental in conveying the importance of practical knowledge, and industry-relevant skills to the young managers. Another webinar, themed ‘NEP 2020 – The Future Roadmap for Higher Education’, witnessed industry-academia veterans, policymakers, and regulators, deliberate on the implementation of the new Education Policy and its effect on academia. ‘Business Education Innovations in the VUCA world’, was another significant webinar where AACSB member schools came together to discuss and deliberate on the topic.</p>
                            <p>The B-School’s mission to integrate technology into its structured pedagogy, coupled with its aim of providing its young managers with ample industry exposure to groom them into industry-relevant managers, has birthed unparalleled, digitally held Corporate Connect Sessions. Throughout 2021, numerous such sessions were conducted, which gave the students the chance to interact with industry veterans and to learn, as well as acquire skills from their experiences. </p>
                            <p>A Roundtable Discussion on the necessary, contemporary topic ‘How B-Schools are Changing their Curriculum to Adapt to Business Challenges of Post-COVID Era’ was conducted in the presence of Prof. R.C. Bhattacharya, Vice Chairman, Globsyn Business School. The discussion witnessed panelists from various global regions deliberate, discuss, and debate, on the various aspects that ensure relevant, in-demand, and innovative business education delivery.</p>
                            <p>An interactive session on the topic ‘Entrepreneurship Essentials’ with Mr. Kaustav Majumdar, Head of Start-ups & Incubation, SPJIMR and Mentor & Advisor, IIM Calcutta Innovation Park, was conducted which introduced the young managers to prospective entrepreneurial ventures, and the art of starting new businesses. </p>
                            <p>GBS organized a ‘Leadership Talk’ on ‘Your India Management Mission’, which was graced by the presence of the prestigious Padma Bhushan award recipient, Dr. Mrityunjay Athreya, Management Mentor, who shared his recommendations to solve problems in the management field and chart future goals for the aspiring managers of GBS.</p>
                            <p>Another significant academic best practice at GBS is the Student Development Program, which provided students with access to 18 highly engaging courses under its Management, Vocational and Foreign Language verticals, on the successful completion of which they received e-certificates from the B-School. </p>
                            <h3>HYBRID LEARNING CLASSROOM</h3>
                            <p>
                            <img className="img-fluid" src="/assets/images/HSC-1024x461.jpg" alt="HSC" />
                            </p>
                            <p style={{textAlign:"center"}}><b>Rahul Dasgupta, Director, GBS, engaging with audience in a Hybrid Learning Classroom</b></p>
                            <p>Recognizing the trends of the upcoming paradigm shift in pedagogy, this year, GBS has taken a step to become a trend-setter in designing Hybrid Learning Classrooms for its young managers – where the students, regardless of their chosen post-graduate management programmes, can continue their courses remotely and/or re-live the physical classroom experience, once the pandemic is over. Globsyn Business School has become one of the first institutions in India to seamlessly blend in physical and online modes of education to enable students to make that choice through its learner-centric mode of education, coupled with a robust use of technology. These spacious classrooms, with all modern teaching, aid the students to focus on dialogues rather than monologues, and help them to ‘learn and unlearn’. With the Hybrid Learning Classrooms, the B-School has embarked upon a new era of learning.</p>
                            <h3>LEARNING BEYOND EDUCATION</h3>
                            <p>
                            <img className="img-fluid" src="/assets/images/WhatsApp-Image-2021-12-30-at-14.18.35-1-1024x341.jpeg" alt="HSC" />
                            </p>
                            <p style={{textAlign:"center"}}><b>A few Beyond Education activities of Globsyn Business School</b></p>
                            <p>At GBS, while ‘knowing’ skills are imparted through a structured pedagogy, ‘doing’ and ‘being’ skills are propagated through its ‘Beyond Education’ activities. In the last year, especially, all these were carried on both in the virtual and physical environments. Some of the activities like the leadership program, which is imparted through the B-School’s ‘Care for Society’ initiative, required students to be physically engaged. Whereas, certain other activities that were earlier done in the physical world as part of Learning Beyond Education, have all migrated virtually.</p>
                            <p>The six verticals – Globsyn Annual Lecture Series, Globsyn Management Conference, Globsyn Alumni Engage, Embryon, Kalyani Youth Leadership Forum, and Serendipity – designed to impact every important stakeholder of the B-School, have upheld its vision of ‘Beyond Education’ even during the difficult period of the pandemic.</p>
                            <p>Under the aegis of Globsyn Management Conference, GBS digitally conducted its International Vision Seminar 2021, and International Research Seminar 2021. This year’s International Vision Seminar themed ‘Sustainability in the Age of Disruption’ witnessed a digital footfall of over 1200 international participants, as 30 eminent speakers from 10 countries deliberated on the topic. The Seminar spanned over multiple days, including a pre-seminar workshop, was divided into four main segments namely Human Sustainability, Social Sustainability, Economic Sustainability, and Environmental Sustainability. </p>
                            <p>Later in the year, the International Research Seminar 2021 themed ‘Emerging Trends across sectors in a Post-pandemic Globalized World’, created an inclusive platform for esteemed veterans of the industry-academia domain and meritorious students to engage in discussion and provide research-oriented findings on the trends that have emerged across sectors in a post-pandemic globalized world. Mainly aimed at meritorious students with a knack for research, the seminar that spanned over multiple days saw eminent academicians, industry stalwarts, in important positions. It was on the last day of this Seminar that the Globsyn Management Journal Vol. XV on ‘The Next Normal: Visualizing Emerging Trends Across Sectors in a Post-disruptive Economy’ was unveiled by Dr. Juilie Guidry Moulard, Faculty, Louisiana Tech University and President, Academy of Marketing Science, USA.</p>
                            <p>This year’s August 15 witnessed students of the B-School organize a cultural event ‘Salaam India’ through Kalyani Youth Leadership Forum (KYLF), a Beyond Education Vertical of GBS, to salute the undying spirit in our Frontline Heroes. On India’s 75th Independence Day, the young managers went live on Facebook to showcase the spirit of unity and patriotism through various soulful performances, invoking unadulterated joy and love for the motherland in the hearts of the viewers. Apart from this, numerous other ‘Care for Society’ activities were carried out by the students all through the year that helped them to imbibe the practice of selfless social work as an integral part of their lives, while internalizing humane skills necessary to become complete managers. </p>
                            <p>This year also witnessed ‘Embryon – The Entrepreneurship Cell of GBS’ being recognized as an AACSB International 2021 Innovation That Inspires. Aimed at encouraging entrepreneurship among its students, incubating their ideas to formulate feasible business plans, and providing them with the platforms to showcase their business plans to industry veterans whose inputs help shape their ideas into successful ventures, Globsyn Business School is a change-agent, creating environments where out-of-the-box thinking thrives.</p>
                            <p>These young managers also organized Serendipity 2021 themed ‘A Tribute to the Frontline Warriors’, as a part of the Beyond Education initiatives, where they paid tribute to the frontline COVID-19 warriors, and also raised funds through the proceeds of the event for Kalyani Ananda Utsav 2021 – a flagship event of KYLF. Conceptualized as a ‘live project’ for students, Serendipity not only replicates the core ethos of GBS – grooming future entrepreneurs and intrapreneurs to make them ‘industry relevant’ but also a platform to have ‘serious fun’ and discover new talents.</p>
                            <h3>SERIOUS FUN AT GBS</h3>
                            <iframe width="763" height="384" src="https://www.youtube.com/embed/wna_yQgIuPs" title="GBS Nostalgia - Alumni Engage | Globsyn Business School" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <p style={{textAlign:"center"}}><b>One of the several ‘Serious Fun’ activities that happened in 2021</b></p>
                            <p>Life, at the GBS, is ‘Serious Fun’ – from academics to sports. This is a place that encourages our students to know life beyond the realms of a textbook. A place that promises compassion, camaraderie, and bonds for life as they connect over sports, games, and other activities. Like every other year, this year too, GBS organized various events and sessions encompassing a wide gamut of domains like health, dance, sports, and music, in an endeavour to encourage ‘Serious Fun’ activities among the students. These activities not only help them to de-stress, but also enlighten the young minds about subjects that go beyond their management education.</p>
                        <p>For the occasion of Diwali, the students organized ‘Umeed’, which translates to hope, to express gratitude to the support staff and salute them for the hope that they have brought in our lives through their constant and tireless service to society. With a vision to go beyond the mundane everyday classroom life focused solely on academia, GBS has promoted various activities like Yoga, Dance Movement Therapy, GBS Nostalgia – a colourful event to connect with its 3000+ alumni, among others, through digital sessions encouraging the students to maintain a healthy work-life balance. These initiatives have de-stressed and enlightened the young managers about various subjects that go beyond their curriculum. </p>
                        <p>Keeping with the ‘new normal’, this year students of the B-School organized e-Titanium – a four-day long online Annual Sports extravaganza comprising of individual and team online games that tested the skills of the students, and provided a platform to put forward their strategies and whet their managerial acumen.</p>
                        <h3>RANKINGS AND RECOGNITIONS</h3>
                        <p>
                            <img className="img-fluid" src="/assets/images/WhatsApp-Image-2021-12-30-at-14.18.35-1024x341.jpeg" alt="HSC" />
                        </p>
                        <p style={{textAlign:"center"}}><b>Acknowledgements received from the Industry-Academia fraternity in 2021</b></p>
<p>Over the years, Globsyn Business School has received many accolades from the education fraternity from time to time, which has contributed in building up GBS as the coveted choice in management education. This year, too, the B-School has been ranked No. 1 Private B-School in Kolkata as per MBA Universe 2021, as well getting recognized as the ‘Leading B-School in Eastern India’ by The Times Group in the edition of Times Brand Icons 2021. This was also a good year because apart from getting acknowledged as the ‘Best B-School with Academic Excellence’ in Eastern India at the Asia Education Summit & Awards 2021, the B-School’s ‘Embryon – The Entrepreneurship Cell’ also got global recognition as AACSB International 2021 Innovations That Inspire, which showcases business schools creating positive societal impact.</p>
<h3>PLACEMENT</h3>
                        <iframe width="763" height="480" src="https://www.youtube.com/embed/rePArWtQ-Y4" title="Placements 2022 - Phase 1 | Globsyn Business School" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <p style={{textAlign:"center"}}><b>Students placed in the recently concluded Placements 2022 (Phase 1)</b></p>
                        <p>Even during these uncertain times, with a volatile economy, the young managers of GBS scaled the heights of success and managed to secure managerial positions at top corporations. A special mention goes to the uniquely designed Alumni Mentorship Program – where every semester, distinct members from its 3000+ alumni club will be mentoring the students so that they can become industry-relevant managers. Through this Program, the notable alumni will engage with the students, mentor them on areas of self-improvement, boost their employability skills, and prepare them for final placement interviews.</p>
                        <p>The Final Placements of Batch 2019-21 witnessed 1500+ job opportunities from 90+ corporates across 25 sectors, with the highest and second highest CTCs being capped at 12 LPA and 10 LPA respectively, while the top 10 average was at 8.47 LPA. The industry engagements of GBS can be credited to have helped the students get placed in top companies across India, and the corporates to acquire the right talent for the right job in their organizations. </p>
                        <p> For GBS, the year also ends on a very high note as the closure of Campus Placement (Phase I) of Batch 2020-22 witnesses 450+ job opportunities from 35+ corporates being offered to the students, with two international offers capping the highest and second highest CTC offered spots at 22 LPA and 17.55 LPA, respectively, while the third highest CTC offered standing at an impressive 15.30 LPA.</p>
                        <p>The success of the students can also be largely credited to the 360+ corporate relationships of GBS, who trained and groomed the students through their SIPS, as well through various corporate and industry connect sessions, organized all over the year. </p>
                        <h3>CONVOCATION OF BATCH 2018-20</h3>
                        <p>
                            <img className="img-fluid" src="/assets/images/GBS-Convocation.jpeg" alt="HSC" />
                        </p>
                        <p style={{textAlign:"center"}}><b>Convocation Ceremony of Batch 2018 – 20</b></p>
                        <p>The two-decade-wise B-School held its 17th Convocation ceremony for students of postgraduate management programs of the Batch 2018-20. The beautiful event, laced with joy and bitter-sweet nostalgia, witnessed students receive their post-graduate diplomas, and embark upon a new journey. The Convocation ceremony might mark the end of their journey as students, but as alumni, their relationship with GBS will remain forever.</p>
                        <p>In 2021’s globally dynamic society, with the constant emergence of new innovative technologies that are paving ways towards a smarter education system, Globsyn Business School has successfully created a niche for itself on the global industry-academia map. With a new year dawning, the B-School is all ready to welcome the next batch of Globsynites into its learning ecosystem, while continuing with its 20 years’ legacy in postgraduate management education.</p>
                        <p style={{textAlign:"center"}}>
                            <img className="img-fluid" src="/assets/images/GBS20_clour.png" alt="HSC" />
                        </p>
                        
                        </div>
                                                <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
