import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../Sidebar'

export default function Marketing() {
  return (
    <>
            <title>School of Marketing Excellence | Globsyn Business School</title>
            <section className="hero-banner-img-pgdm">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">School of Marketing Excellence</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">School of Marketing Excellence</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                               
                            </div>
                            <div className="one-table">
                                <div className="one-table1"></div>
                                <div className="one-table2">
                                    <p>As the world moves from being a sellers’ market to a buyers’ market, consumers have assumed a pivotal role in the structure of an organisation. Marketing specialization recognises the consumers as the fulcrum around which the plans and programs of an organisation revolves. Today’s market fashion’s itself according to the need of the hour. For example, marketing of ideas and services has become more important than marketing of products. The School of Marketing Excellence programme at Globsyn Business School, armed with an unbeaten course structure, live and industry vertical specific case studies, live projects, workshops, advertisement clubs and other creative pedagogic tools, represents an ideal gurukul to learn the intricacies of the today’s marketplace.</p>
                                    <p>The School of Marketing Excellence offers the following areas of study:</p>
                                    <ul className='disc'>
                                        <li>Industrial Marketing</li>
                                        <li>International Marketing</li>
                                        <li>Marketing for Non-profit organizations</li>
                                        <li>Relationship Marketing</li>
                                        <li>Rural Marketing</li>
                                        <li>Social and Environmental Marketing</li>
                                        <li>Strategic Product & Project Pricing</li>
                                        <li>Consumer Behaviour</li>
                                        <li>Cross-cultural Marketing & Ethnographic Orientation</li>
                                        <li>Logistics & Sales Force Management</li>
                                        <li>Product Innovation & Management</li>
                                        <li>Sales & Distribution Management</li>
                                        <li>Services Marketing</li>
                                        <li>Strategic Brand Management</li>
                                        <li>Digital Sales</li>
                                        <li>Marketing Research</li>
                                        <li>Sports and Tourism Marketing in India</li>
                                        <li>Innovation & Entrepreneurship</li>
                                        <li>Brand Valuation</li>
                                        <li>Marketing Policies and Operations</li>
                                        <li>Marketing and Public Policy</li>
                                        <li>Marketing & Business Ethics</li>
                                        <li>Social and Digital Marketing & Analytics</li>
                                        <li>Digital Marketing</li>
                                        <li>Integrated Marketing Communication</li>
                                        <li>Retail Marketing</li>
                                        <li>Dissertation for Marketing School of Excellence</li>
                                    </ul>
                                    <p><em>* Not all papers are offered in all courses</em></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
