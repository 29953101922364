import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ManegmentFunction() {
    return (
        <><title>Management Function & Organizational Behaviour – Corporate Connect</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Management Function & Organizational Behaviour – Corporate Connect</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Management Function & Organizational Behaviour – Corporate Connect</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>As India’s first ‘Corporate B-School’, Globsyn Business School (GBS) designed ‘Corporate Connect’ as one of the cornerstones of its academic delivery system, which exposes the students to the nuances of the corporate world and makes them industry-relevant managers. With this vision to build a globally networked, corporate savvy, research driven management education system, GBS over the years has been inviting industry stalwarts to share their knowledge and experiences with the young managers, which will help them in their future corporate journey.<br />
                                    Following is an account on the experience of one our students, who have been a part of a Corporate Connect session.
                                </p>
                                <p>Mr. Nadim Kazim, Chief Human Resource Officer, Tractors India Limited, conducted a Corporate Connect session on ‘Management Function & Organizational Behaviour’ for the GBS students, which helped the young managers get an idea on how the corporate world really functions and how they can prepare themselves for the industry. Mr. Kazim shared some of his valuable lifetime experiences and also gave them some valuable advices on how they can be good future managers.</p>
                                <p>He told the students that their careers took off the day they attended their first class at Globsyn Business School. Mr. Kazim began the session explaining to the students that most vital part for any organisation to work well is to maintain ‘discipline’. An academic institute might tolerate late attendance, but a corporate house will not show that leniency. Then he briefed the students about how to build their character, where the main qualities will not just be intelligence and intellect but also ethics and values. Mr. Kazim said that it might get difficult for some people to keep pace with the ever-changing dynamic values of today’s world that can turn them into cultural misfits, so in order to blend in with their workplaces, they need to upgrade and update not just their resumes but also their personalities with the culture of our organisation.</p>
                                <p>He also informed the students that academic success does not necessarily give them professional success. They need to be more conscious about the latest industry trends and demand, learn beyond education and be more ‘industry relevant’ in order to hit the ground running from the day they enter the corporate rat-race. Keeping that in mind, academic excellence is also of primary importance as the expectation levels of an organization from a management student will be much more than from a non-management student.</p>
                                <p>Mr. Kazim stressed on the importance of ideation and communication for succeeding in an organisation. According to him, a lot of people might come up with good ideas but they won’t be considered potent enough to influence the stakeholders and senior management people unless they are expressed well and heard by them. Today geographical boundaries are becoming redundant with corporate houses of one country working with others, Mr. Kazim advised the students to learn atleast one foreign language as it will add extra points to their skill sets as global managers.</p>
                                <p><b>Kajal Das<br />
                                    Batch —–, Globsyn Business School</b></p>



                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
