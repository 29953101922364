import React from 'react'
import Sidebar from '../../Sidebar'
import { Link } from 'react-router-dom'

export default function Ivs() {
    return (
        <><title>International Vision Seminar 2023 - Globsyn</title>
            <section className="hero-banner-img-media">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">International Vision Seminar 2023</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">International Vision Seminar 2023</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/GMC-Post-1024x1024.jpg" alt="campus" width="678px" height="490px" />
                                </div>
                                <br /><br />
                                <p>The 7th International Vision Seminar 2023 was dedicated to the theme: Innovation and Entrepreneurship in Management Education.</p>
                                <p>Innovation and entrepreneurship have been defined variously, which include suggestions of broad overlap as well contentions of nuanced differences. There is however no denying that innovative entrepreneurship and entrepreneurial innovation have been the vanguard of economic development and social progress from time immemorial. The development of entrepreneurial as well as intrapreneurial mind-set among the younger generations of management learners would be extremely beneficial in terms of being equipped with the appropriate mind-set for the corporate and the academia. This seminar focused on some aspects of this vast subject, which are of immediate relevance to Academicians, Corporate, Research Scholars, Government Officials, NGOs, Students, and Public in general so that appropriate deliberations may lead to creation of human resource with an entrepreneurial mind-set.</p>
                            </div>
                            <div className="accordion" id="myAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Vision Seminar 2023: Research Seminar</button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div className="card-body">
                                            <p>The theme for this year’s Research Seminar was ‘Innovation and Entrepreneurship’. This seminar was conceptualized to create an inclusive platform with corporate veterans, academicians, entrepreneurs and students, who deliberated and provided research-oriented findings. The seminar aimed at developing a platform for technocrats, management practitioners, policy makers, entrepreneurs, researchers, and academicians to deliberate on various issues & challenges for entrepreneurship development. The seminar came out with innovative models, which can be used as guiding principle for policy makers, financers, business organizations & budding entrepreneurs operating in national & international level.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingtwo">
                                        <button type="button" className="accordion-button collapsed " data-bs-toggle="collapse" data-bs-target="#collapsetwo">Vision Seminar 2023: Workshop</button>
                                    </h2>
                                    <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div className="card-body">
                                            <p>The workshop for Vision Seminar 2023 was on ‘Design Thinking Framework for Sustainable Entrepreneurship and Innovation’.
                                                <br />
                                                The scope for innovation and entrepreneurship abounds in areas where problems are unknown or ill-defined. In those scenarios, the approach of design thinking enables discovery of hidden needs and solutions that factor in user convenience. The workshop exposed participants to the principles and phases of design thinking and its application for solving complex problems. The key take-away  catered around how design thinking can be used as a technique for disrupting a market or innovating within a larger organization so that there can be sustainable entrepreneurship and innovation in the future.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingthree">
                                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Globsyn Management Journal</button>
                                    </h2>
                                    <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div className="card-body">
                                            <p>Apart from the Inaugural and Valedictory sessions, the Vision Seminar proceedings flowed through the following session-wise sub-themes:</p>
                                            <p><strong>(i) The role of Government and Start-ups in fostering Innovation and Entrepreneurship: Collaborations, Partnership and Initiatives</strong></p>
                                            <p>Successful innovation and entrepreneurship could often be serendipitous but to turn towards ubiquity, it is important to foster a milieu where experimentation and risk taking are encouraged. MSME would play a significant role to foster the start-up environment and thereby promote entrepreneurship through collaborations, partnerships and initiatives. This panel looked forward for an engaging discussion on how Governmental and start-up innovative initiatives are revolutionizing the entrepreneurial landscape and thereby creating a nexus between Government and start-ups to enhance value for both the stakeholders.</p>
                                            <p><strong>(ii) Educators and Social Entrepreneurs: Developing knowledge ecosystems and role of social organizations in building an entrepreneurial environment</strong></p>
                                            <p>Though the need for innovation and entrepreneurship is universal, academic institutions primarily aims for the development of a knowledge society while trying to create resources for the corporate. Creating of opportunity for practice of key entrepreneurial behaviour is one of the crucial determinants of entrepreneurship intentions among students. Many educators are resorting to social initiatives and teaming up with social entrepreneurs including philanthropic foundations which could facilitate in the running of the business. This panel looked forward to a discussion on how educators and social entrepreneurs can work together to develop an entrepreneurial environment.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingfour">
                                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefour">Vision Seminar 2023: Speakers and Tracks</button>
                                    </h2>
                                    <div id="collapsefour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div className="card-body">
                                            <p><strong>RESEARCH SEMINAR (February 24, 2023)&nbsp;</strong></p>
                                            <p><strong>Innovation and Entrepreneurship</strong></p>
                                            <p><strong>Welcome Address:</strong> Dr. Tanusree Chakraborty, Faculty, Globsyn Business School, India<br />
                                                <strong>Inaugural Address:</strong> Prof. R. C. Bhattacharya, Vice-Chairman, Globsyn Business School, India<br />
                                                <strong>Theme Address:</strong> Dr. M.P. Singh, Principal, Globsyn Business School, India<br />
                                                <strong>Chief Guest Address:</strong> Dr. Mary Conway Dato-on, Professor of Social Entrepreneurship, Rollins College, Crummer Graduate School of Business, USA<br />
                                                <strong>Special Guest Address:</strong> Dr. Sharmistha Banerjee, Professor – Department of Business Management, and Project Principal Investigator Entrepreneurship Hub, University of&nbsp;Calcutta,&nbsp;India<br />
                                                <strong>Judges:</strong> Dr. Mary Conway &amp; Dr. Sharmistha Banerjee<br />
                                                <em><strong>Research Paper Presentations</strong></em><br />
                                                <strong>Vote of Thanks:</strong> Dr. Sabyasachi Dasgupta, Faculty, Globsyn Business School, India<br />
                                                <strong>Session Moderator: </strong>Dr. Tanusree Chakraborty, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Recess</strong></p>
                                            <p><strong>SEMINAR WORKSHOP (February 24, 2023)&nbsp; </strong></p>
                                            <p><strong>Design thinking framework for sustainable Entrepreneurship and Innovation</strong></p>
                                            <p><strong>Welcome Address: </strong>Dr. M.P. Singh, Principal, Globsyn Business School, India<br />
                                                <strong>Inaugural Address</strong>: Prof. R. C. Bhattacharya, Vice-Chairman, Globsyn Business School, India<br />
                                                <strong>Keynote Speaker &amp; Chief Guest: </strong>Mr. Tirthankar Banerjee, Director, Oztron Energy, Australia<br />
                                                <strong>Workshop Chairman:</strong> Dr. R.K. Patra, Regional Chairperson (EAST), AIMS, India<br />
                                                <strong>Workshop Guest Leader: </strong>Mr. Devasis Gupta, Mentor, IIMC Innovation Park, India<br />
                                                <strong>Vote of Thanks: </strong>Dr. Sabyasachi Dasgupta, Faculty, Globsyn Business School, India<br />
                                                <strong>Session Moderator: </strong>Prof. Kaushik Ghosh, Faculty, Globsyn Business School, India</p>
                                            <p>&nbsp;</p>
                                            <p><strong>SEMINAR DAY – Panel Discussions (February 25, 2023)</strong></p>
                                            <p><strong>Inauguration </strong><br />
                                                <strong>Welcome Address: </strong>Dr. M.P. Singh, Principal, Globsyn Business School, India<br />
                                                <strong><em>Inauguration and Lamp Lighting, Globsyn Mantra</em></strong><br />
                                                <strong>Inaugural Address: </strong>Mr. Bikram Dasgupta, Founder &amp; Executive Chairman, Globsyn Group, India<br />
                                                <strong>Theme Address: </strong>Prof. R.C. Bhattacharya, Vice-Chairman, Globsyn Business School, India<br />
                                                <strong>Chief Guest: </strong>Shri Anoop Kumar Agrawal, IAS, Principal Secretary, Technical Education, Training and Skill Development, Govt. of West Bengal, India<br />
                                                <strong>Guest-of-Honour:</strong> Dr. Parag Kalkar, Dean, Faculty of Commerce and Management at Savitribai Phule Pune University &amp; Treasurer, AIMS, India<br />
                                                <strong>Special Guest: </strong>Prof. Anil Sahasrabudhe, Ex-Chairman, AICTE; Chairman, NBA; Chairman, NETF<br />
                                                <strong>Special Guest: </strong>Dr. Geoff Perry, Executive Vice President and Chief Officer, Asia Pacific, AACSB<br />
                                                <strong>Vote of Thanks:</strong>&nbsp; Dr. D.P. Chattopadhyay, Faculty, Globsyn Business School, India<br />
                                                <strong>Session Moderator:</strong> Dr. Mahuya Basu, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Emcee:</strong> Dr. D.P. Chattopadhyay, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Recess</strong></p>
                                            <p><strong>Panel Session I: The role of Government and Start-ups in fostering Innovation and Entrepreneurship: Collaborations, Partnership and Initiatives<br />
                                            </strong></p>
                                            <p ><strong>Panelists:</strong></p>
                                            <ul className='disc'>
                                                <li>Ms. Sumita Bhattacharya, Managing Director, NE Innovation Lead, Accenture, USA</li>
                                                <li>Mr. Debabrata Mitra, Joint Director, MSME-DFO, Ministry of MSME, Govt. of India, India</li>
                                                <li>Mr. R.K. Jaiswal, Honorary Consul, Maldives</li>
                                                <li>Mr. Prashant Kumar, Founder and Senior Partner, Entropia (Accenture Song), Malaysia</li>
                                                <li>Mr. Aloke Mookherjea, Former Senior Advisor, Ernst &amp; Young LLP, and Former Chairman – Flakt (India) Limited; Governing Council Member, Globsyn Business School, India</li>
                                            </ul>
                                            <p><strong>Session Chairperson: </strong>Dr. Ajitava Raychaudhuri, Emeritus Professor and HoD – Economics, Adamas University, India; Former Dean, Faculty of Arts (Economics), Jadavpur University, India; Ex-Academic Council Chairman, Globsyn Business&nbsp;School,&nbsp;India<br />
                                                <strong>Session Moderator: </strong>Dr. Rajib Bhattacharya, Faculty, Globsyn Business</p>
                                            <p><strong>Lunch<br />
                                            </strong></p>
                                            <p><strong>Panel Session II: Educators and Social Entrepreneurs: Developing knowledge ecosystems and role of social organizations in building an Entrepreneurial Environment<br />
                                            </strong></p>
                                            <p><strong>Panelists:</strong></p>
                                            <ul className='disc'>
                                                <li>Prof. Manas Chatterji, Bartle Professor, School of Management, Binghamton University, USA</li>
                                                <li>Mr. Debabrata Samaddar, Ex-Senior General Manager, Tata Steel Downstream Products Limited, and COO Wisdom Overseas, India</li>
                                                <li>Dr. Arijit Banerjee, Founder and Executive Director, Ramaesis RPL, India</li>
                                                <li>Dr. Vinitha Guptan, Vice Chancellor, SAITO University, Malaysia</li>
                                                <li>Mr. Saunak Saha, Partner, Ernst &amp; Young, India</li>
                                            </ul>
                                            <p><strong>Session Chairperson: </strong>Dr. Subhrangshu Sanyal, CEO, IIM Calcutta Innovation Park, India; Academic Council Member, Globsyn Business School,&nbsp;India<br />
                                                <strong>Session Moderator: </strong>Dr. Kavita Shastri, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Valedictory Session<br />
                                            </strong></p>
                                            <p><strong>Special Address</strong>: Dr. Upinder Dhar, Vice Chancellor, Vaishnav Vidyapeeth Vishwavidyalaya, Indore, India<br />
                                                <strong>Valedictory Address: </strong>Dr. Damodar Acharya, Ex-Chairman, AICTE, India; Governing Council Member, Globsyn Business School,&nbsp;India<br />
                                                <strong>Conclusion &amp; Vote of Thanks:</strong>&nbsp;Dr. Sabyasachi Dasgupta, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Session Moderator: </strong>Dr. Mahuya Basu, Emcee and Faculty, Globsyn Business School, India</p>
                                            <p><strong>Emcee of the Ceremony:&nbsp;</strong>Dr. D.P. Chattopadhyay, Faculty, Globsyn Business School, India</p>
                                            <p>&nbsp;</p>

                                        </div>
                                        </div>
                                    </div>
                               

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
