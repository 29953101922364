import React from 'react'
import { Link } from 'react-router-dom'
import '../Common.css'
import Sidebar from '../../Sidebar'

export default function DirectorMessage() {
  return (
    <>
    <title>Director’s Message</title>
            <section className="hero-banner-img-trustee">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Director’s Message</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">Director’s Message</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            
                            </div>
                            <div className="one-table">
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/Rahul-Sir.png" alt="" sizes="(max-width: 358px) 100vw, 358px" width="203" height="203" /></div>
                                <div className="one-table2">
                                    <p>With a legacy of continuous innovation in management education for nearly two decades, Globsyn Business School (GBS) has uniquely positioned itself as India’s only ‘Corporate B-School’, earning itself a place among the best in management education in Eastern India, if not nationally. Over the years we have partnered with some of the best Ivy League institutions and brands, integrating various high-end technologies into its academic delivery through these collaborations. This amalgamation of technology with education has placed GBS way ahead of the learning curve.</p>
                                    <p>Keeping this backdrop in mind, we at GBS, have created an ideal learner centric environment that attempts to ignite a spark in the minds of budding managers of the future, who are going to lead this change. To cater to their aspirations, we have incorporated a curriculum that draws from the best practices of traditional and modern management, from East and West, focusing extensively on experiential learning. The concepts are delivered by an eminent faculty team that combines a rich blend of experience from industry and academics.</p>
                                    <p>As an institution, we have always been able to identify areas to harness technology, improve communications, and streamline processes, to create a sustainable, technology-driven education framework for future learners. In these past couple of years, we have taken relevant steps to create a well-planned out mechanism of knowledge dissemination that included synchronous or virtual classes; face-to-face or traditional physical classroom models; asynchronous or pre-recorded classes, self-learning techniques that the students can imbibe on their own, and now hybrid learning –empowering students with an opportunity to choose their own learning path. In the next few years, we plan to transform its pedagogy by integrating AR and 3D videos with traditional techniques to make the Hybrid Learning Model more immersive and self-paced.</p>
                                    <p>The B-School’s vision and mission are aligned to build responsible and relevant managers for the industry. Our education edifice, constructed on a foundation of Innovation, Research, and Technology, coupled with our blended learning pedagogy, inspired and enhanced by the global minds we work with; allow our students to develop their ‘knowing’, ‘doing’ and ‘being’ skills required to thrive in the knowledge-driven global economy. GBS believes in learning ‘Beyond Education’ and our experiential learning initiatives ensure that our students also develop cognitive, inter-personal and intra-personal skills desired in the 21st century corporate world. It also has a well networked Corporate Relationship Programme (CRP) cell that facilitates students’ transition from academics to industry.</p>
                                    <p>We not only aspire to create managers of the future, but also ethical, caring, and responsible corporate citizens who hold the potential to transform businesses and influence society as a whole. If you wish to be a part of this exciting journey, we welcome you to come aboard to transform yourself and your career.</p>
                                </div>
                                <br/>
                            <img src="./assets/images/Rahul-sir_v01-102x58.jpg" alt=""  width="88" height="40"></img>
                            <figcaption className="vc_figure-caption">Rahul Dasgupta</figcaption>
                            </div>                          
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
            </>
  )
}
