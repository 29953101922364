import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function HealthyWaysStrengthen() {
    return (
        <><title>COVID- 19: Healthy Ways to Strengthen Your Immune System</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">COVID- 19: Healthy Ways to Strengthen Your Immune System</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">COVID- 19: Healthy Ways to Strengthen Your Immune System</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/Website-Blog-img_25_06_2021.png" alt="COVID- 19: Healthy Ways to Strengthen Your Immune System" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Coronavirus disease is still raging in the world, but we need to protect ourselves by boosting our immune system to fight against this viral infection. In the last few blogs, we have clarified how to take precautionary measures, and what are the trending terms related to this disease. Here, in this blog, we will explain the balanced diet and healthy lifestyle practise we can undertake to develop a defence mechanism and reduce our chances of getting a severe infection.</p>
                                <p><b>Diet to Boost Immune System Naturally</b></p>
                                <p>Daily meals must be nutritiously balanced with adequate proteins, vitamins and minerals, especially Zinc and Vitamin C that can support and boost our immunity system. Add Mustard Spinach, Broccoli, Sprouts, Chickpeas, Lentils and Beans along with the seasonal vegetables. Consume proteins like fish, chicken eggs, lentils, soybean, mushrooms, etc. and healthy fats like almonds, walnuts, cashews, olive and mustard oil to increase your energy levels.</p>
                                <p>Drink enough water, if possible warm water throughout the day to keep the body hydrated. To ease a sore throat or dry cough, a common symptom of Covid-19 infection, you can drink herbal tea or decoction (kadha) made from the tulsi, dalchini (cinnamon), kalimirch (black pepper), dry ginger, with honey or jaggery or lemon juice.</p>
                                <p>The Government of India and other authorities who are concerned with public health have already issued several awareness campaigns on a healthy diet and active lifestyle where it has been recommended to consume whole grains like ragi, oats, amaranth (rajgira) in breakfast and to use beneficial spices like Haldi, Jeera, Dhaniya and lahsun (garlic) while cooking.</p>
                                <p>Turmeric or haldi consists of the active ingredient curcumin, which is a very strong antioxidant and has powerful anti-inflammatory effects on our body.</p>
                                <p>So, our government has advised drinking ‘Golden Milk’ made with half a teaspoon of haldi in 150 ml of warm milk once or twice a day.</p>
                                <p>A small amount of dark chocolate with 70 % cocoa will help to ease stress and reduce cardiovascular risk, and it is always better to avoid smoking, alcohol and other addictive substances that can have a direct influence on respiratory illnesses and weaken body defence.</p>
                                <p><b>Physical and Breathing Exercises</b></p>
                                <p>During the lockdown and stay-at-home phase, International bodies like the United Nations and World Health Organization, have advised us to include light physical exercises and breathing exercises in our daily routine for us to stay active, relax our muscles and improve blood circulation while lowering our susceptibility towards COVID-19 infection.</p>
                                <p>We can avoid lifts and climb stairs whenever possible, need to take periodic breaks from our long hours of sitting positions during work or study from home, and do some stretching in between. We also can take up household chores to keep our surroundings clean and germ-free.</p>
                                <p>Ministry of Ayush has recommended daily practise of Yogasana (light physical exercise), Pranayama (Breathing exercise) and meditation for at least 30 minutes a day.</p>
                                <p>Additionally, you can practise steam inhalation and oil pulling therapy to enhance the body’s natural immunity system.</p>
                                <p>All these authorities recommended healthy habits are for our benefits and immunity development. Otherwise, if you develop any COVID-19 symptoms, or test positive for coronavirus infection, seek immediate medical help from a registered practitioner and follow up.</p>
                                <p>Share your thoughts in the comment section on what measures you are taking to keep yourself and your family safe during this pandemic time. We will bring more COVID-19 related information in our upcoming blogs and till then keep your community safe.</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
