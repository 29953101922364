import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function PrioritiseMental() {
  return (
    <><title>Why We Need to Prioritise Mental Well-being of Young Minds</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Why We Need to Prioritise Mental Well-being of Young Minds</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Why We Need to Prioritise Mental Well-being of Young Minds</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/Prioritise-Mental.png" alt="Why We Need to Prioritise Mental Well-being of Young Minds" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Dale Carnegie, the notable author of the pioneer book on self-development “How to Stop Worrying and Start Living” explains how most of us fret over useless things in life and lose our precious time overthinking about the future. Although we can relish yesterday’s happiness and have a vision for a hopeful tomorrow, however, we should always keep in mind that all that matters is ‘Today’.</p>
                                <p><b>Mental Health Conditions of Young Minds Are at Risk</b></p>
                                <p>Worries lead to behavioural and mental health conditions like depression, anxiety, disruptive behavioural disorders, and suicidal tendencies which are common among today’s youths. COVID-19 has exaggerated the conditions with family losses and mandatory self-isolation, no one has been left unaffected, so it has become crucial to prioritise and address the mental health condition like never before.</p>
                                <p>In a report presented by the United Nations titled “Mental Health Matters” (2014), it has been observed that mental health conditions are quite common among the young people aged between 14-24 years old, with a majority living in low-income countries, and they are at a greater risk at the transition period reaching adulthood. The condition can impact negatively on the development, quality of life and social and emotional functioning and even work performance later in life.</p>
                                <p><b>Approaches to Keep Your Mind Healthy</b></p>
                                <p>Good mental health condition involves the way we think, feel and act, so just like we eat sleep and exercise to keep our physical body healthy, the four dimensions to keep a check on your mental health condition are:</p>
                                <ul className='disc'>
                                    <li><b>Thoughts</b> – Good thoughts with a positive outlook can bring balance and equilibrium to our lifestyle, so we can see our life more constructively, negative thoughts on the contrary can cause a hindrance and we tend to overlook the positive side of things.</li>
                                <li>Emotions – Thinking positive can make us happy, content, excited, calm, loved, and affectionate whereas jealousy, anger, frustration, anxiety, embarrassment, disgust and irritation are results of having a negative mind.</li>
                                <li>Body Reactions – Pounding negative thoughts can bring adverse effects on bodily functions like heart rate, breathing, digestion, cause upset bowels, dry mouth, unnecessary sweating, feeling dizzy or lightheaded, aggravation of existing health problems like acne, migraines, lack of energy and appetite etc.</li>
                                <li>Behaviours – Bad behaviours towards others can be harmful thus it becomes difficult to enjoy life and has adverse effects on our relationship with family and friends.</li>
                                </ul>
                                <p>Since 1949, Mental Health Awareness Month has been observed in May every year to reduce the stigma associated with mental health conditions; it serves as a reminder that mental illness is common, treatable, so when we see someone struggling, we can intervene and provide assistance.</p>
                                <p><b>Globsyn’s take on Mental Health issues</b></p>
                                <p>Globsyn Business School, since its inception, has shown a prominent effort on uplifting the mental health conditions of its budding managers, faculty members and other Globsynites who painstakingly work towards the unified goal of the company. Amidst all the physical and mental health concerns around the world, GBS has taken various initiatives to enhance awareness on mental health and always encouraged Globsynites to lead a healthy lifestyle. Through various ‘Beyond Education’ activities, aspiring managers learn to tackle critical real-life situations in their two-year stint and learn how to take out their best under a constrained environment. Various sessions on Yoga, inspirational talks are held on a regular basis where attendees can discuss their mental health conditions freely among themselves without the fear of being stigmatised.</p>
                                <p>Resonating with the popular idiom ‘an idle brain is devil’s workshop’, we should stop worrying about the unknown future or ponder upon our past mistakes, and keep ourselves busy in activities that we feel cherish to do. Better we start following Carnegie’s words – “Inaction breeds doubt and fear. Action breeds confidence and courage. If you want to conquer fear, do not sit home and think about it. Go out and get busy.”</p>
                                
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
