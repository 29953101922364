import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function EcommerceTrends() {
  return (
    <><title>E-commerce Trends in the Post-pandemic World</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">E-commerce Trends in the Post-pandemic World</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog">Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="">E-commerce Trends in the Post-pandemic World</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>
                    <div className="one-table">

                        <p>
                            <img className="img-fluid" src="/assets/images/E-commerce-Trends.png" alt="E-commerce Trends" />
                        </p>
                        <div className="entry-header">
                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>With a beginning around four decades back, E-commerce has dramatically redefined the way of carrying out shopping in the day-to-day consumer world. With the access to internet being more available to the public during 1991, the activities concerning online shopping gained needed impetus. The factors like convenient shopping, safety and enjoyable retailing experience have helped e-commerce become one of the most liked internet activity amongst consumers.</p>
                        <p><b>Astronomical Growth Rate </b></p>
                        <p>Analysts reflect that e-commerce has achieved an astronomical growth rate during the Post Pandemic period. E-commerce related searches alone reflect a growth by around 56 percent along the periods ranging from 2016 to 2020. The emergence of the COVID-19 pandemic has indeed influenced the shopping habits and styles of the global consumers. It was seen that from March 2020, e-commerce that was already on demand by the consumers gained an accelerated growth rate at an astronomical level. Experts in Shopify reflected that within a three-month period starting from March 2020 a 10 year’s growth rate was experienced by e-commerce. E-commerce is also observed to have countered a year-on-year growth of around 9.5 percent along the periods 2019 to 2020. The experts further stated that during 2021 around 19.5 percent of sales associated to the global retail segment will emerge from online purchases. Another estimate rendered by eMarketer reflects that growth in retail e-commerce will take a leap by around 14 percent during 2021. Likewise, they also reflected by the end of 2024 e-commerce will tend to account for around 22 percent of total retail purchases.</p>
                        <p><b>Reasoning the Astronomical Growth </b></p>
                        <p>One of the main factors prompting such astronomical growth rate in e-commerce owes to the global e-commerce giant, Amazon. It is estimated that Amazon accounts to potentially gain from the huge investments of $1 million that are expended online in every minute. Sales of Amazon gained a growth by around 44.1 percent during 2020. Amazon as a market leader in the e-commerce sector accounts for around 83.2 percent of purchases of diverse merchandises, like book, music and videos, made by US consumers.</p>
                        <p>Further, the revenues generated, based on online sales accounting from the grocery sector, were almost double during November 2020 as compared to that incurred during January 2020. The food and personal care segment accounted for the significant amount of e-commerce spending. Further, the sales of mobile phones carried out based on the use of the e-commerce platform accounted for a growth of around 31.5 percent during 2020. The same is expected to amount to around $432 billion by the close of 2022.</p>
                        <p>The employment of technological applications like Artificial Intelligence and Machine Learning with that of Virtual and Augmented Reality in the e-commerce sector further contributed in the growth of e-commerce at such astronomical rates. The use of such applications in e-commerce accounted for greater interactivity and transparency, regards to e-commerce consumers, which in turn spurred such potential sales. </p>
                        <p>The integration of social media applications like Facebook and Instagram by e-commerce companies for attracting consumer attention through advertising their product offerings is also accounted to be a significant factor triggering such e-commerce growth. </p>
                        <p>The happening of the COVID-19 pandemic accounted for altering many ground rules. The businesses of the small and medium sized retailers were observed to be potentially affected owing to the emergence of the pandemic. Thus, to compete with e-commerce giants like Amazon the small and medium sized retail firms happened to gain the support of other e-commerce platforms like Spotify, Wix, Etsy among others for selling their products. The same also accounted for such huge e-commerce growth in the post pandemic world. </p>
                        <p><b>E-Commerce and B-Schools</b></p>
                        <p>The study of E-Commerce as a key management subject is undertaken by different B-Schools along both India and abroad. The growing significance of Online Commerce with also the growing advent of Information Technology (IT) has in turn required the generation of a well acknowledged and talented global workforce to service the needs of global consumers. Some institutes are offering a MBA in E-Commerce in that it provides an effective gateway to both professionals and entrepreneurs for enhancing their consumer reach, via the use of internet, over the global marketplace. </p>
                        <p>Globsyn Business School (GBS) is one such B-School that renders focus on integrating the study of e-commerce in the ambit of management studies. Over the last two decades, GBS has uniquely positioned itself as India’s only ‘Corporate B-School’. The School of Information System Excellence in GBS offers Internet Commerce or Fundamentals of E-Business as an integral part of management studies. The B-School prioritises on the studies of Information Systems in management learning and thus incorporates studies along varied areas of Information Technology.</p>
                        <p>The COVID-19 pandemic that has affected the world on a massive scale is also seen to have emerged as a boon to some. E-commerce that has already gained prominence prior to the happening of the pandemic has earned due acceleration, owing to the pandemic, thereby shifting the consumer mind-set to a new retail shopping exposure.</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
