import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function TrustMustTriumph() {
    return (
        <><title>Trust Must Triumph Employment Blues</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Trust Must Triumph Employment Blues</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Trust Must Triumph Employment Blues</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Trust-Must-Triumph-Employment-Blues.jpg" alt="Trust Must Triumph Employment Blues" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Prof. Manas Chakravarty, teaches Human Resource Management at Globsyn Business School – one of the top B Schools in Kolkata. Prof. Chakravarty moved to academics following a distinguished career in the industry culminating in leadership roles. In addition to administrative and professorial responsibilities, Prof. Chakravarty has delivered executive learning programs at leading corporate houses across the country and has been the program director of national and international conferences. Apart from academic writing, he also has a blog page in the Economic Times.
                                    </em>
                                </p>
                                <p>When the year 2022 began it was hoped that putting pandemic blues behind, the employment scenario will turn stable. This was however not to be. The vagaries of uncertainty set out to hunt and haunt employers and employees with great vigor.</p>
                                <p>The Great Resignation that plagued the employment market in 2021 did abate at the turn of the year but elements of its causative factors continue to linger in many organizations. Though certain geographies and sectors were more impacted than others, the phenomenon leaves important lessons for employee retention all across. According to a study published by MIT Sloan Management Review early in 2022, toxic corporate culture, which includes employees feeling disrespected, has been the top factor driving the Great Resignation. Job insecurity and reorganization, the usual suspect, occupies the second slot. Following that is a surprise with the third spot going to high levels of innovation. In an organization pursuing relentless innovation, work may be exciting but also tiring and many employees could opt for exit rather than burn out. The fourth position has gone to organization’s failure to recognize performance, which again is substantiation of common sense. The last of the five major causes of the phenomenon identified by the study is poor response to COVID-19. Employees’ expectation on organizational response to the pandemic situation indicates that organizations have to rise beyond simply being a good pay master.</p>
                                <p>Another phenomenon that came under focus during and following the lockdown due to the pandemic is “moonlighting”. Now, moonlighting has been going on since ages and usually involved a different work outside the time engaged for the “day job” of regular employment. Employers had little objection to it as long as the additional pursuit by the employee did not create a conflict of interest. Working from home during the lockdown, it became possible to pursue two jobs doing similar work at the same time without being easily detected even if it amounted to serving competing organizations. Obviously, employers were fully justified in cracking down on such moonlighting.</p>
                                <p>As the year 2022 progressed, a phenomenon counter to the Great Resignation was on display. Several organizations that were considered to be highly admirable employers, set on a wave of mass termination albeit compelled by business considerations. While employers aggressively assert the right to fire, it is naïve to wish away a backlash. Indeed, the term “career cushioning” gained traction during the year. Career cushioning, which alludes to efforts that individuals make in keeping alternate options open to cushion the impact of being suddenly fired from the current job, finds legitimacy in environments where employees feel continuously insecure in their jobs.</p>
                                <p>Separation, whether initiated by the employer or the employee, is however at times inevitable under the force of circumstances. Both, employer and employees, must remember that employment blues have space for conflict but no place for betrayal. Those responsible for managing human resources in the organization must be able to foster a culture which ensures that break in a relationship is not a breach of trust – neither as the reason, nor as the result.</p>
                                <p>A couplet from the ghazal ‘<em>Wo Hamsafar Tha’</em> by Naseer Turabi suggests a situation where even when the relationship sours, there is no betrayal by either side:
                                    <em>adavaten thi, taghaful tha, ranjishen thi magar</em><br />
                                    <em>bichhardne vale mein sab kuchh thaa, bevafayi na thi </em><br />
                                    (Resentment, indifference, rancour was there;<br />
                                    Separatants indulged in everything but not betrayal.)</p>
                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Manas_jpg124648.jpg"
                                            alt="tt"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Prof. Manas Chakravarty
                                            <br />
                                            Faculty – Human Resource Management
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
