import React from 'react'
import { Link } from 'react-router-dom'
import '../Common.css'
import Sidebar from '../../Sidebar'

export default function Research() {
    return (
        <><title>Innovative system of management study</title>
            <section className="hero-banner-img-inno">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Research</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Research</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                                <h3 className="vc_custom_heading">GBS aims at uniting faculty</h3>
                            </div>
                            <div className="one-table">
                                <p>At Globsyn Business School (GBS), research forms the foundation of the academic delivery system from which emanates all other artefacts. The research initiatives undertaken by the B-School has deep rooted focus on Knowledge Dissemination and Knowledge Creation, an area which is woefully under serviced by management institutions. GBS has its dedicated Research Cell, which synthesises teaching and research, bringing to the student community the latest advancements in management thoughts and practices and sharing with the academic community scholarly work and articles. The Cell also ensures that the young learners are exposed to the most contemporary curriculum and also develops a range of academic artefacts, not only limited to case studies or classroom learning but encompassing Seminars, Conferences etc., keeping in mind the emergent role of Indian and other fast growing economies.</p>
                                <p>Case Studies developed by the school has found acceptance by leading international case houses like ECCH, SSRN etc. – a testimony to the academic excellence of the school’s publication. In addition, Globsyn Business School has its dedicated Globsyn Management Journal (GMJ) – an endeavour to contribute to a better understanding of organisations and their functioning. GMJ is enlisted in the world’s top databases such as EBSCO, J-Gate, Google Scholar, WorldCat, ProQuest, and ICI, for its conceptually sound and methodologically rigorous articles that provide insights and advance knowledge on managerial, business and organization issues.</p>
                                <p>The Research Cell at GBS aims at bringing together faculty, academic staff, and external experts to share research ideas involving various management fields of interest and expertise. Such events provide the faculty/staff of the B-School with ample opportunities to be exposed to a wide range of management faculty and invited guest speakers.</p>
                            <h4>The Cell’s objectives include:</h4>
                            <ul className='disc'>
                                <li>Sharing Research Areas of Interest</li>
                                <li>Facilitating research outreach (including funding)</li>
                                <li>Promoting research collaboration within GBS and also, externally</li>
                                <li>Conducting regular seminars and publication of journals, as a part of B-School’s Beyond Education initiative</li>
                            </ul>
                            <h4>The Cell’s activities include, among others:</h4>
                            <ul className='disc'>
                                <li>Research Paper Presentation</li>
                                <li>Faculty Research Presentation</li>
                                <li>Papers from renowned management/academic journals</li>
                                <li>Case Study Development & Presentation</li>
                                <li>Paper Presentations by External Researchers/fellows</li>
                            </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
