import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function EmergenceHybridLearning() {
  return (
    <><title>Emergence of Hybrid Learning</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Emergence of Hybrid Learning</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Emergence of Hybrid Learning</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Emergence-of-Hybrid-Learning.png" alt="Emergence of Hybrid Learning" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>With the onset of the global pandemic our lives have been quite stuck inside our houses. In every alternative six months, we are witnessing waves of pandemic hitting the mass. This has left a deep effect in the education sector. The Indian education sector has been an offline system of our country. We have traditionally seen students and teachers being physically present in class room set up, and we have believed that education is impossible without a class room set up. The global pandemic has challenged the traditional approach to education. The advent of internet and increased access to digital sphere has restructured the education system. In the recent times of uncertainty, the educational sector has remodelled itself and adapted new models of education, that could strike a balance for the learners both online and offline. HYBRID LEARNING in this context derives a mention, a recent development in the education sector, and definitely an outcome of technology.</p>
                                <h4>Definition of Hybrid Learning</h4>
                                <p>Hybrid Learningis an educational approach that combines face to face classes and online learning or the hybrid approach to education can also be defined as educators simultaneously taking online and offline classes simultaneously. Hybrid Learning includes use of online exercises, pre-recorded class videos, asynchronous sources, alongside live demonstration of classes in the classroom and through broadcast mediums. Hybrid learning model has enabled a student to choose his mode of accessing education, which he is most comfortable with. This in turn makes education more accessible.</p>
                                <h4>Hybrid Approach to Education</h4>
                                <p>Hybrid learning has aptly helped educational institutions to maintain a proper social distancing norm at schools. With hybrid learning system students can come to schools two to three days in a week, or the entire class population can be rotationally divided into groups, where each group gets to visit classes two to three days while the rest can attend classes virtually. Hybrid learning enables an educator melding of traditional classroom experiences, experiential learning objectives and digital course digital course delivery, an educator has the opportunity to utilise the best option for each learning objective.</p>
                                <h4>Hybrid Classroom – How to prepare for Hybrid Learning </h4>
                                <p>In order to adopt the hybrid learning model, educators have started to implement combined form of in-person and online learning together. Here are a few tips to prepare for a Hybrid Classroom:</p>
                                <ul className='disc'> 
                                    <li>The presence of a device is essentially necessary for the educator to simultaneously teach those that are present in person and those that present virtually. This also includes communication with both of them during the process of teaching.</li>
                                    <li>Educators need to be well adapted with the use of core set of applications, that would be also used by the students irrespective of being physically present or being remote. It becomes a primordial responsibility for both for teachers and students to be comfortable with the use of these technical applications, in order to fit into the model of hybrid learning.</li>
                                    <li>Educators should make it point to record each of class videos, because technical issues can often come in the way of any student. However, recording of each class videos serves as an asynchronous source. A student can access it any point of time from the learning management system to go through the missed-out parts.</li>
                                    <li>Another important part of hybrid learning is communication. It becomes a mandate for an educator to allow students discuss on topics taught. Those present in class have the opportunity to discuss, however technological applications have supported in this process. Those attending classes online too get to communicate and participate equally. The educator assigns ample amount of time for both to come up with their queries.</li>
                                </ul>
                                <h4>Advantages of Hybrid Learning </h4>
                                <p>Hybrid learning combines the best aspects of in-person learning and online learning. Here are the benefits of hybrid learning:</p>
                                <ul className='disc'>
                                    <li>It is a flexible learning experience because it allows flexibility in learning schedule, teaching modes, in engagement with students, variation in the use of learning materials.</li>
                                    <li>It provides Synchronous communication opportunities. Few learning experiences match the immediacy and intimacy of in-person academic discussions. The face to face learning opportunity in hybrid systems benefits in real time engagement between peers.</li>
                                    <li>The process allows freedom of independent academic exploration. The freedom to learn from locations of one’s choice, freedom to access study materials any number of times.</li>
                                </ul>
                                <h4>Hybrid Learning at Globsyn Business School</h4>
                                <p>Over the last 20 years, one of the things that Globsyn Business School (GBS) have heavily focussed on is its emphasis on technologyacross every vertical of the organization to enhance the academic experience amongst its students, and be at par with institutes of global repute. Recognizing the trends of the upcoming paradigm shift in pedagogy, the B-School has taken a step to become a trend-setter in designing Hybrid Learning Classrooms for its young managers. The Hybrid Delivery Model at GBS provides students with an academic environment where they can choose to learn the way they want to, and helps them to become more involved, engaged, and experienced. This will also allow themmake better career choices, and help corporate houses to get skilled resources who are trained to operate in the ‘New Normal’.</p>  
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
