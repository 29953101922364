import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function PracticingResearch() {
  return (
    <><title>Practicing Research – A Way of Life with the Spice of Passion and Joy</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">Practicing Research – A Way of Life with the Spice of Passion and Joy</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog">Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="">Practicing Research – A Way of Life with the Spice of Passion and Joy</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>
                    <div className="one-table">

                        <p>
                    <img className="img-fluid" src="/assets/images/website-blog-images_13_10_2020-1.png" alt="Practicing Research – A Way of Life with the Spice of Passion and Joy" />
                    </p>
                        <div className="entry-header">
                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>The brand called Research has always been put into high esteem, enhancing the self-image of the person conducting the research. While research has been put into a halo light or in a hall of fame, I have always found low rate of interest in research among students and even some Faculty. There are multiple alibis for that, ranging from no time to no worth to no relevance and sometimes to the extent of saying, ‘I am not that intellectual’. When I take the first research class of students and I ask how many students are interested in research, very few feeble hands are raised with a lot of hesitancy. When I finish the sessions, there are many confident hands which are raised when I ask the same question to the class. Don’t mistake me thinking I am praising myself for being a splendid teacher, but all that I feel I have been able to do in my sessions were to demystify the heroic brand called research and change the attitude of my listeners from a perception of ‘it is only for a few people’ to ‘it can be practiced by all’.</p>
                        <p>All that I have been able to remove from the conception of research as being elitist to being a commoner is the cleansing of its casteism with the spice of passion and joy. From faculty to staff and of course to students, the endeavor in Globsyn Business School has been to create a culture of research that breeds throughout the ecosystem and forms a way of life for taking decisions and being a part of every individual. Some lack confidence, some lack interest and some cannot go ahead due to lack of knowledge and bottlenecks that they perceive to be present in the ecosystem. When we developed the publication vertical of Globsyn Research Cell, our endeavor was to remove all these hindrances coming in the way of an individual’s research and enabling employees to lead the research way of life. No research can breed in an environment which is done by a few, and saluted by the rest. Getting everybody involved in research and publishing creates an ecosystem where looking at the other, one would say, ‘If he/ she can, why can’t I”? So, we created a tracker and traced the bottlenecks in research and provided the ecosystem with varied and enriched knowledge as food for thought, discussions, debates and deliberations. As the influence of any positive culture has on individual attitude, creation of a research culture in an organizational ecosystem influences every person in the system to develop a desire for practicing research. It is the combination of culture, attitude and knowledge that inculcates passion, skillset and ability to conduct research.</p>
                        <p>Intrinsic motivation is the fuel to be sustainable in research. If at any point, the motivation goes down, the entire ecosystem suffers. Research is a differentiator in every individual’s life and the acumen of inquisitiveness and critical questioning leads to unraveling of issues hitherto remaining in the shadow in broad daylight. The uncovering of truth under the veneer of probability and perception is the ultimate joy one self-actualizes when one reaches its conclusion. Working under the joy and passion of a collective consciousness and redefining the discourses therefore, practicing research in the Globsyn ecosystem would, I am sure, enable every budding and established researcher to find their respect and satisfaction in their career, society and fraternity.</p>
                       <p>
                        <b>Prof. (Dr.) Sabyasachi Dasgupta</b>
                        <br/>Faculty – Marketing<br/>
                        Globsyn Business School
                       </p>


                    </div>

                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
