import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function UnicornsDecacorns() {
    return (
        <><title>Unicorns to Decacorns – The Startup Story of India</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Unicorns to Decacorns – The Startup Story of India</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Unicorns to Decacorns – The Startup Story of India</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Unicorns-to-Decacorns-–-the-Startup-Story-of-India.png" alt="Unicorns to Decacorns" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Prof. Manas Chakravarty, teaches Human Resource Management at Globsyn Business School – one of the best B Schools in Kolkata. Prof. Chakravarty moved to academics following a distinguished career in the industry culminating in leadership roles. Prior to joining the faculty team at Globsyn Business School, he was the Associate Dean at ICFAI, Hyderabad and Dean at Unitedworld School of Business, Karnavati University, Gandhinagar. In addition to administrative and professional responsibilities, Prof. Chakravarty has delivered executive learning programs at leading corporate houses across the country and has been the program director of national and international conferences. Apart from academic writing, he also has a blog page in the Economic Times.
                                    </em>
                                </p>

                                <p>Any organization established anywhere for any purpose goes through an initial phase, which is crucial for its survival and growth. The criticality of those early days is being increasingly recognized and the label “startup” describes business organizations in that phase. The label has also acquired statutory connotation. For the purpose of availing tax benefits in India, it has to meet certain conditions including the following:</p>
                                <ol type='I'>
                                    <li>it has not crossed ten years from the date of incorporation as a private limited company or registration as partnership firm or limited liability partnership</li>
                                    <li>its annual turnover in any year has not exceeded INR 100 Cr.</li>
                                    <li>it is working towards innovation or has high potential for employment generation or wealth creation.</li>
                                </ol>
                                <p>These and some other conditions have been prescribed by notification from the Ministry of Commerce and Industry and the Income Tax Act, 1961 as amended from time to time.</p>
                                <p>Though any organization in its early days is literally a “startup” and many may meet the statutory requirements to draw tax benefits, the excitement over the term is about their potential as an investment proposition. Businesses operating in traditional lines grow organically, linearly and incrementally and therefore investor returns are marginal but begin almost immediately and have less uncertainty. On the other hand, the buzz around the startup phenomenon is about a business idea being able to attract massive investment from external sources even at the ideation or prototype stage so that it can get into a hyper growth trajectory and pay back the investors handsomely in about four to ten years’ time.</p>
                                <p>Such external sources are individuals or institutions who have no prior connection with the organization. They could be in the form of Angel Investment, Venture Capital, Private Equity or Venture Debt. Angel Investors come in at the ideation stage itself and use their own money, therefore having a very high risk-reward ratio and may exit when institutional capital is raised. The exit event for Venture Capital and Private Equity could be acquisition by another company or the startup taking the IPO route.</p>
                                <p>The startup buzz has also led to the coining of esoteric nomenclature to indicate their value and potential. A “unicorn” is a startup with valuation more than USD 1 Bn; a “gazelle” is one whose valuation is between USD 500 Mn and USD 1 Bn; a “cheetah” has a valuation in the range of USD 200-500 Mn. Gazelle may become a unicorn in two years, which a cheetah may in four years. Beyond unicorns are those with the label of “decacorn” valued at more than USD 10 Bn. The first two decacorns were Facebook and Alibaba.</p>
                                <p>Success of a startup depends essentially on the enterprise of its founders and the resources committed by investors but the state has a responsibility to provide a hospitable environment. With this in mind an initiative named Startup India was launched by the Indian government in 2016. The initiative rests on three key pillars: Simplification & Hand-holding, Funding & Incentives, and Incubation & Industry-Academia Partnership. Simplification is targeted at easier compliance, fast tracking of patents, reducing information asymmetry and also easier exit process for failed organizations. The funding pillar is aimed at providing tax benefits, and a fund of funds to infuse capital into the startup ecosystem and credit guarantee scheme. The incubation pillar is for creating incubators and innovation labs and organizing events and competitions.</p>
                                <p>The combined effect of the governmental facilitations and private initiative is that India has emerged as the third largest startup ecosystem in the world. According to data published by Invest India (National Investment Promotion & Facilitation Agency), there were 107 unicorns in India as of September 2022 with a total valuation of USD 340.79 Bn. India also broke into the decacorn list with BYJU’S, Swiggy, Flipcart and Nykaa.</p>
                                <p>Unicorns in India are concentrated in Bengaluru followed by Delhi (NCR) and Mumbai, but startups in general are widely dispersed with presence in 656 districts of the country as indicated in the Invest India report. Startups are spread also across several sectors notably including Animal Husbandry, Construction, Education & Skill Development, Energy, Enterprise Technology, Environment, Fintech, Health &amp; Wellness, Industry 4.0, Media & Entertainment, Security, Space, and Transport.</p>
                                <p>To foster innovative mindset in management students and motivate them to pursue viable business ideas, Globsyn Business School has an entrepreneurship cell named Embryon. This cell organizes several activities that includes panel discussions, workshops, business plan competition and an exhibition-cum-sale event where students are exposed to experiential learning out of real business activity. The panel discussions have startup founders and angel investors sharing live cases. The expert workshops run as a series covering idea generation, business plan and fund raising. In 2021, AACSB – the foremost international accreditation body for business schools – recognized Embryon as “Innovation That Inspires” placing it in a global list of 24 institutions.</p>
                                <p>While there is much to be celebrated about the thriving startup ecosystem of India, the Economic Survey 2022-23 has rightly flagged the very pertinent issue of Flipping and Reverse Flipping. Many Indian startups prefer to be headquartered away from home to avail the benefits of favorable tax and regulatory policies of that country. The exercise is called “flipping” because the process involves an Indian company becoming 100% subsidiary of a foreign company while the founders and investors retain ownership by swapping all shares. Recent changes in regulatory mechanisms and maturity of capital markets in India has slowed down flipping and requires further developments on similar lines to accelerate reverse flipping. When that happens, the country can truly echo the positive note sounded in the survey that startups will continue to be messengers of India’s entrepreneurial dynamism.</p>
                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Manas_jpg124648.jpg"
                                            alt="Prof. Manas Chakravarty"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Prof. Manas Chakravarty
                                            <br />
                                            Faculty – Human Resource Management
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
