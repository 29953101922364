import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ExperientialLearning() {
    return (
        <><title>Experiential Learning in Management Education</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Experiential Learning in Management Education</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Experiential Learning in Management Education</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/sharing-ideas-with-groupmate-1200x600.jpg" alt="Experiential Learning in Management Education" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <blockquote style={{ backgroundColor: "#f5f5f5", padding: "30px 45px;", margin: "0 0 20px" }}>
                                    <p style={{ textAlign: "center" }}>“Tell me and I’ll forget, show me and I’ll remember, involve me and I’ll understand.” – Carter</p>
                                </blockquote>
                                <p>It is difficult to claim that experience will not be crucial for learning under the right circumstances. However, it will be reasoned that consequential learning can be in error unless care is taken to assure that those conditions occur. Experiential Learning is an interdisciplinary method based on management, education and psychology, and involving a holistic process of action/replication based on experience/construct. It is specifically an influential factor in connection with management education, because, it is alleged to be effective in support of the training and education in diverse fields/sectors as talent management, leadership performance, competence development, change management, community involvement, volunteering, cross-cultural training and entrepreneurship.</p>
                                <p>Experiential Learning paves the way to explore how this may contribute in enhancing the field of management development thinking and practice. According to my understanding, appropriate teaching and innovative pedagogical strategies such as Experiential Learning will help the management education system to realise the potential of their students and generate long-term tangible and intangible benefits.</p>
                                <p>At Globsyn Business School (GBS) we follow this Experiential Learning strategy in our curriculum for enhancing understanding of students and accomplishing their career goals. Let’s describe this Experiential Learning technique in a simple manner which we rigorously follow in our teaching method at GBS.</p>
                                <p>In economics, there are different types of markets, like perfect competition, monopoly, monopolistic competition, oligopoly, etc. It is sometimes difficult for a student to understand intrinsic differences and distinguishing factors of each market. We coin examples of the vegetable market, Indian Railway, FMCG market and Cold Drink market to make them understand the basic characteristics of different types of markets. We elaborate advertisement and selling cost as one of the major weapons of Monopolistic Competition and oligopoly market. But independent strategy to achieve the goal and interdependent strategy to maximize profit is absolutely different approaches that have been taken by firms under monopolistic competition and oligopoly respectively. Without Experiential Learning techniques, it is really difficult to find out fine line differences in those markets. From interdependence strategy, with the help of real market examples, we can substantiate the origin of Cartel and Merger and acquisition.</p>
                                <p>I would like to coin another example which I follow in my class. Price Discrimination is one of the most important aspects of economics where the theoretical definitions of 1st Degree, 2nd Degree and 3rd Degree Price Discrimination create complexities in understanding. But when I coin examples of different Airlines and Railway charges – depending on the seasons or days of the week, for electricity, consumers get charged different tariffs depending on the quantity consumed, firms often give coupons to selected consumers, students and senior citizen often get discounts in Indian Railway – help them to understand the theoretical concept of price discrimination.</p>
                                <p>The implementation of Experiential Learning techniques in a behavioural, managerial and economic class plays the most pivotal role to deepen students’ understanding of the experimental approach. We believe that the process of designing and realising the experiments will foster a better understanding of the material, than simply participating in classroom experiments. Thus, the complete process promoted by Globsyn Business School generates active learning through student engagements, both individually and collectively.</p>
  
                                <p>
                                    <b>Prof. (Dr.) Tanusree Chakraborty</b>
                                    <br />Faculty, Economics<br />
                                    Globsyn Business School
                                </p>


                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
