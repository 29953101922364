import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'


export default function JourneyCampus() {
    return (
        <><title>Importance of Diversity in Business Schools</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Importance of Diversity in Business Schools</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Importance of Diversity in Business Schools</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Journey-from-Campus-to-Corporate-through-SIP_Blog.jpg" alt="" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Internships are career building opportunities that a student gets while studying management education. And it becomes even more valuable if we do it from a start-up. Start-ups give opportunity to be yourself and learn from scratch. My name is  Jayasree Rakshit, doing MBA from Globsyn Business School, Kolkata, and I went through a life changing experience in one such start-up.</p>
                                <p>My SIP experience at GetMyUni Education Services Pvt. Ltd. began as an intern in the inside sales team, at the end of April, 2019. GetMyUni is the first social college search platform which helps young aspirants make right education choices via student reviews and insights. They also enable them to get valuable industry experience via student challenges on skill-based hiring platform. Over the past weeks I have met many amazing individuals and have learned about the nuances of sales. These two factors have been the highlights of my summer internship.</p>
                                <p>As a member of the inside sales team, I relish the camaraderie I shared with my seniors and peers. I would have never imagined how tight-knit my team has become with all our inside-jokes and hilarious stories. Working with my team has made work fun and productive. Some of the best memories are created when we share meals together, attend meetings, call prospects, as well as laugh at each others’ mistakes and quirks. My GetMyUni internship has been full of fun, yet I have had learning opportunities every single day.
                                    conversation with seniors and peers</p>
                                <p>
                                    <img className="img-fluid" src="/assets/images/conversation-with-seniors-and-peers.png" alt="conversation-with-seniors" />
                                </p>
                                <p>In the last two months, I have worked on numerous sales campaigns. As an intern we make verbal interaction and call students to counsel them in regards to college so as to generate leads. Counselling involves helping the young students take the right direction towards their dream career by providing them with details of different colleges, sources, scholarship opportunities, course structure and fees. These details give them the right direction towards their prospective career. I also attended various training classes on digital marketing, Google ads, Google Sheet, Excel, Push and Pull Marketing strategies, and the correct way to pitch for services.</p>
                                <p>My summer internship at GetMyUni has been an experiment of sorts as my job responsibilities included running various job searches for career interests and consulting the best career choices. Being a sales intern was enlightening because it is rewarding to learn about various offerings and developing a deeper understanding of an industry of which I previously had very little knowledge. This internship has helped me develop my soft skills and helped diversify my experience. It is very fulfilling knowing that I, being a student myself, have spent my summer doing something that has a positive impact on students’ academic successes.</p>
                                <p>Everyone at GetMyUni were extremely helpful and supportive regarding any confusions I had and have always helped me whenever I needed their support throughout my internship, especially the Operation Team. I learned how to work with a team in a corporate environment, and the lessons learnt shall benefit me tremendously in the future, both in college and professionally. Overall, I had a great experience as a Globsynite intern to do my summer internship with GetMyUni, and am very thankful to Globsyn Business School for my this opportunity that they gave.</p>
                                <p><strong>Jayasree Rakshit</strong></p>
                                <p style={{ fontSize: "11pt" }}>PGDM – Batch 08,<Link to="/"> Globsyn Business School</Link></p>

                            
                            
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
