import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ZomatoIpo() {
    return (
        <><title>Zomato IPO – A Success Story or an Over-optimistic Market Response?</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Zomato IPO – A Success Story or an Over-optimistic Market Response?</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Zomato IPO – A Success Story or an Over-optimistic Market Response?</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/bloge-images.png" alt="Zomato IPO" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Zomato was founded in 2008 by Deepinder Goel & Pankaj Chaddah as Foodiebay, a tech start-up, originally started as an advertising company with an app-based restaurant discovery service. From that day, Zomato has traveled a long way to become one of the success stories among Indian startups in 2021, with almost 50% share in the food delivery market.</p>
                                <p>The current business model of Zomato includes transaction fee from food ordering and delivery, fees from listing and advertisement of the restaurants on Zomato platform, Zomato pro subscription service and a new venture called Hyperpure, a service directed at restaurants offering quality groceries and meats. Its revenue has grown from a decent 446 crores in 2018 to an impressing 2604 crores in 2020, which however has taken a hit of COVID-19 and has reported Rs. 1993 crores in March, 2021. As Zomato continued to grow, it raised funds from several venture capitalists including Info-edge, which remains its biggest single investor till date. This money, Zomato has primarily used to acquire few other companies with Uber-eats being its largest acquisition with 15,250 million INR.</p>
                                <p>Eleven years after its foundation, Zomato took a big step towards public offerings. The long-awaited IPO announcement in July, 2021 is crucial for the company as well as for Indian primary market for several reasons. It is the first tech start-up from a truly digital IPO in India. It is also the first in a series of public listings to come within a few months including Nykaa, PolicyBazaar and Paytm, and hence, its price discovery will act as a benchmark for itself in the coming few years. With an aggressive offer to raise an amount of Rs. 9375 crores and a well criticized price bracket of Rs. 72-76 per share having a face value of Re. 1, it is considered by many as grossly overpriced. But the IPO has received a warm welcome from the investors. The issue is oversubscribed by more than 38 times in total and by 7.5 times by retail investors with an issue price of Rs. 76. The success is believed to be based on a global bull run along with a perfect timing of the issue.</p>
                                <p>Being a pioneer, it has added a massive advantage to Zomato along with the successful listing of its global peer DoorDarsh Inc. in the US market. Although the company continues to record loss over the period and has in fact never reported positive earnings per share (EPS) in the last five years, it could cut down its losses in the last fiscal from Rs. 23,850 million in 2020 to Rs. 8164 million in 2021. Zomato has also reported that while in 2020 the company’s loss was Rs. 30.5 per order; in 2021 they have successfully turned it over and has earned a positive profit per order of Rs. 20.5. This paints a rosy future for the company along with the huge prospective market size and the fact that food delivery is still in its nascent stage in India.</p>
                                <p>But the rally for Zomato does not end here. The issue opened for secondary market trading on July 23, with an impressive listing of Rs. 116 on the National Stock Exchange (NSE), a 53% premium over the IPO price of Rs. 76, and closed at Rs. 125.8 per share on its very first day. Its market capitalization has remained above Rs. 1 lakh crore mark, which it has crossed soon after listing, making it one of the top 50 most valuable publicly traded firms in India.</p>
                                <p>Although the market is optimistic about the future backed by the global bull run, whether the stock can sustain its present valuation is debatable. With the presence of Swiggy as its main competitor and the entry of Amazon food, the market competition is going to be intense. Many market experts are of the opinion that the share is overpriced and not sustainable even in the medium run and may soon experience a price correction.</p>
                                <br />
                                <p><b>Prof. (Dr.) Mahuya Basu</b><br />
                                    Faculty – Finance<br />
                                    Globsyn Business School</p>
                                </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
