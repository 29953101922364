import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function TechnologyAdoption() {
  return (
    <><title>COVID-19 and Increased Technology Adoption</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">COVID-19 and Increased Technology Adoption</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog">Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to=""> COVID-19 and Increased Technology Adoption</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>
                    <div className="one-table">

                        <p>
                            <img className="img-fluid" src="/assets/images/FB-post_800x400.jpg" alt="COVID-19 and Increased Technology Adoption" />
                        </p>
                        <div className="entry-header">
                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>The emergence of COVID-19 has required us to go into a situation of home quarantine. The normal life of school going children and office going people are starkly affected owing to the growth of infected cases in every city and town around the world. Though the global pandemic continues to sustain its invasion into our day-to-day lives yet it has drastically failed to halt the activities associated to learning, working and socialising. Rather the growth of COVID-19 pandemic has required people of different age groups to become more technologically savvy.</p>
                        <p>The new norm generated during the COVID-19 era requires the different activities like studying, interviewing, working and even socialising to be carried out virtually through the use of different types of meeting apps enabled for smartphones, tablets and desktops. Even activities concerning carrying out of conferences, seminars, workshops and other type of important meetings are being carried out based on the use of internet technology. The use of video calling apps like Zoom, Microsoft Teams, Cisco Webex and Skype is on the rise that enables the carrying out of face-to-face meetings and videoconferencing activities on the virtual platform. Children are being required to sit for their day-to-day classes and interact with their teachers through the use of online learning apps. Likewise, the children and also the elderly population, being totally stuck in their homes, find the meeting apps and also the video callings options, linked to social networking platforms like Facebook and WhatsApp, to effectively interact with friends and relatives residing at distant places.</p>
                        <p>The participants of the web based meetings and conferences are also required to get acquainted with some ground rules for helping in the effective conducting of such virtual meets. The participants are required to follow the ground rules like not unmuting until required, not putting off the video, raising the hand to speak and other such rules that are laid down by the one holding the meeting. The following of the ground rules especially by children during the carrying out of virtual learning sessions and the adult population while involved in an official meeting ideally helps in accomplishing the learning and team meeting objectives.</p>
                        <p>To this end, Globsyn Business School (GBS) based in Kolkata, West Bengal has also taken a number of relevant measures in terms of incorporating different types of digital platforms for promoting value both in the education and occupational sphere. Digital platforms like Google Classroom and Google Meet are being extensively used by the management education institution for carrying out of regular classes. Likewise, different webinars are being held from time to time by GBS for helping in the enhancing the efficiencies and knowledge of the faculty members and also the internal staffs. The webinars are designed to encourage knowledge flow from learned minds and also for the sharing of potential ideas that would contribute in value enhancement in a subsequent manner. The above measures undertaken by the business school ideally contributes in enhancement of the learning curve even during these challenging times.</p>
                    </div>

                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
