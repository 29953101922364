import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function Coronavirus() {
    return (
        <><title>Coronavirus: The Impact on Chinese & Global Economy</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Coronavirus: The Impact on Chinese & Global Economy</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog"> Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Coronavirus: The Impact on Chinese & Global Economy</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/2020-03-05-copy.png" alt="Leadership vs. Bullying" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Xiulan Chen, the London-based fashion designer, is going to miss this year’s Fashion Week in Europe as they do not have any ready product to sell because of the recent Novel Coronavirus (nCoV) outbreak. In 2017, the brand shifted all of its production to China. He is also worried about the uncertainty around Shanghai Fashion Week in March, 2020.</p>
                                <p>The Ganges India guesthouse is situated just a stone’s throw from the Great Wall of China and is an ideal destination for a weekend getaway. At present, Amber, the owner and his family are the only occupants of that guesthouse. Iqbal & his brothers, who have a shop in the Supermarkets of China, are struggling to sell their food as families have already stocked up enough for the holiday season. In Wumart Chinese Supermarket, boxes of Lunar New Year goods, including food items such as persimmons, remain stacked and unsold.</p>
                                <p>Yan Li, a 40-year-old man from Singapore now spends his days disinfecting his empty restaurant located near the Ming Dynasty tombs, as millions of people across the country stay home, either under government orders or out of caution.</p>
                                <p>All of the above cases have only one reason and that is the deadly Coronavirus, a pneumonia-causing illness that infects the respiratory tract! The World Health Organization (WHO) has announced this crisis a global health emergency, which has been officially named as COVID-19. The virus epidemic, which may peak in February, is now seen as a high risk to the East Asian region and globally.</p>
                                <p>The death toll from the Novel Coronavirus (nCoV), outbreak crossed 3,000 in China and confirmed cases have climbed to 80,400. This outbreak is giving India Inc. the anxieties, especially sectors dependent on Chinese imports such as pharmaceuticals, consumer electronics, and automobiles. As on 4th March, 2020 India also identifies 29 positive coronavirus cases.</p>
                                <p>Chinese and multinational companies have started facing disruption in their supply chains as the Coronavirus continues to spread; the Chinese government prolonged the shutdown of factories and businesses, and airlines negated air routes in and out of the nation. These kinds of production interruptions will largely shake hi-tech goods, household goods, and textile industries where China plays an essential role in the global supply chain. Production curtailment in these industries will have an adverse effect on the global supply chain as companies would struggle to find alternative suppliers.</p>
                                <p>Every year the global companies are accustomed in facing the slowdown in Chinese factories due usual Chinese New Year closure, which was scheduled to end this year on January 31. The extension of the Lunar New Year shutdown is creating a backlog in the world’s supply chain. Fundamentally, the extended factory shut down will cause businesses to run low on inventory. Constant cancelation of cargo aircraft, lack of commercial flights is worsening the situation day by day.</p>
                                <p>Wuhan – the epicenter of the coronavirus outbreak – has a population of around 11 million people. This capital of Hubei province (Central China) is considered to be a major hub for industry and transportation, powered by the investments from Fortune 500 companies. The Coronavirus crisis, which is thought to have originated in the food market of the central China metropolis, has spread to about two dozen countries and can soon cause disaster in the all-important memory chip markets. French automaker Peugeot Citroen, German conglomerate Siemens and PepsiCo – all have bases in Wuhan and the wider Hubei province.</p>
                                <p>The Coronavirus is hitting electronics manufacturers particularly hard. Producers of smartphones and other consumer electronics fear loss of productivity and revenue due to workers told to stay home and factories shut by government order. The representatives of electronic giants like Godrej Appliances, Hitachi, Haier etc. have expressed their concerns about the shortage of raw materials used for production as the Chinese factories (suppliers) may not open their shuttered plants as per the schedule. As an outcome, it would delay the launch of new products. The scale of the effect would be contingent on how long the interruption continues. Foxconn, the major supplier to Apple, has yet to reopen its largest Chinese factory at Shenzhen’s Longhua district. The production would restart soon after the official inspection at the facility. Apple does the majority of its manufacturing in China and considers it as an essential market. Now Apple is also looking for some substitute suppliers to make up their supply chain disruption as the company shuts all its stores and offices in mainland China due to the growing Coronavirus crisis.</p>
                                <p>With China reporting more cases of Novel Coronavirus, the companies are asking people to refrain from travelling to China till the situation is resolved. Due to this ongoing supply scarcity, intensified by factory shutdowns and cross-border travel, Chinese vendors have raised the key components pricing by 2-3 %. The hike in component prices, in turn, may result in an increase in product prices in India, according to BPL India. BPL’s buffer inventory is also getting exhausted. Lava, HP and Dell are already planning to move production out of China for the longer term. Google has closed all its offices in mainland China.</p>
                                <p>World Mobile Congress, the world’s top mobile trade fair, has been cancelled due to worries stemming from the coronavirus that ignited an evacuation of industry heavyweights. Every year this show used to pull more than 100,000 people, including 5,000 to 6,000 participants from China.</p>
                                <p>Automobile firms are on alert over possible disruption to Chinese factories and suppliers. Renault, General Motors Nissan, Honda, and Peugeot-owner PSA are among the companies which have been in lockdown since January. Italian automaker Fiat also cautioned that they may have to suspend Europe’s production as it struggles to import enough parts and components from China. Suzuki is planning to source parts from outside China since the outbreak could disrupt the production in India, its biggest market. Tata Motors’ Jaguar Land Rover subsidiary has extended the shutdown of its manufacturing operations in China till February 17, 2020.</p>
                                <p>Another biggest concern from the China lockdown is that it could lead to the shortage of medicines supply and therefore, a spike in its prices. It is the high time that the pharmaceutical industry starts preparing for alternative means.</p>
                                <p>The recent trade war between Xi Jinping and Trump administration also prompted a decoupling between the US and Chinese economics. This war forced many multinational companies to shift their supply chain activity out of China. Now because of the Coronavirus outbreak, the global companies again might find themselves locked out of China. The world also witnessed a similar scare during the outbreak of SARS in 2002 and 2003. During that time the virus emerged in Guangdong, coastal province on the north shore of the South China Sea, before spreading across China and around the globe. SARS had killed nearly 800 people in at least 17 countries.</p>
                                <p>Cargo is expected to stand still until late February or possibly early March, so some delay in shipment is expected. Air travel and air cargo will be most affected, as travel bans and canceled flights will reduce available air cargo capacity.  It would be costly and time consuming if the companies at this stage start looking for some alternatives.</p>
                                <p>No one knows how long the Coronavirus outbreak will last, how many lives it will claim or how far it will spread. It is not possible to calculate the degree to which it will interrupt China’s economy. It is difficult to anticipate the effects of the virus on supply chains, which is growing notorious day by day. After the SARS outbreak, China suffered several months of economic contraction and then rebounded dramatically. That might happen this time, too.</p>
                                <br/>
                                <br/>
                                <p><b>Aparajita Sanyal</b><br/>
                                    Knowledge Cell</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
