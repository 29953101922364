import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ZoomforTeaching() {
    return (
        <><title>How to use Zoom for Effective Online Teaching?</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">How to use Zoom for Effective Online Teaching?</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">How to use Zoom for Effective Online Teaching?</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/e-learning-online-business-presentation-meeting-concept-1200x600.jpg" alt="How to use Zoom for Effective Online Teaching?" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p><b>Zoom Features to Secure Your Online Classroom</b></p>
                                <p>We had fun playing Zip Zap Zoom as children, now we mostly meet each other over Zoom meetings. Zoom has taken the traditional face-to-face classroom interaction into an online format and has converted the teachers into virtual hosts for conducting their online classes. With the growing demand, Zoom has introduced many features to make it a reliable and secure online video conferencing service, so that teachers can guarantee a safe and private virtual classroom environment to their students.</p>
                                <p>Here are a few of the prominent Zoom features to ensure safety over the internet:</p>
                                <p><b>1. Lock the Virtual Classroom</b></p>
                                <p>Just like closing the classroom door before starting the lecture, Zoom allows the host to lock a session after all the students have logged in. In the security button pop-up, click ‘Lock Meeting’, which disables any new participant to enter the ongoing classroom and disturb the class.</p>
                                <p><b>2. Create a Virtual Seating Chart</b></p>
                                <p>Zoom has its industry-leading gallery view where a computer screen can display up to 49 participants at a time. Host, here the teacher can rearrange and fix the video tiles in a specific pattern so that it remains fixed even if someone joins or enters the virtual classroom. This feature helps an online instructor to keep a watch on those specific students who need special attention, and everybody can see this arrangement, so they can follow the pattern if they are asked to do so for any discussion.</p>
                                <p><b>3. Multi Pinning Feature</b></p>
                                <p>This multi-pinning view enables teachers to disable the active speaker’s view and pin videos of any other participants, in their custom personal view. Students or participants can also do the same with prior permission. This feature helps participant when he or she uses sign language, and may not want to become the active speaker in the meeting.</p>
                                <p><b>4. Manage Screen Sharing</b></p>
                                <p>Zoom has the default screen-sharing feature given only to the host to bar other participants from sharing unnecessary content and disrupt the meeting. The host can enable the screen-sharing feature for all participants from the advanced sharing options.</p>
                                <p><b>5. Multi Spotlighting Feature for Group Presentation</b></p>
                                <p>The feature allows the teacher to spotlight up to nine participants to create a focussed group, so the other participants can pay attention to what the group discusses in the meeting.</p>
                                <p><b>6. Lock Chat mode</b></p>
                                <p>The chat locking feature disables the private chat among participants, but they can interact with the host or their virtual class teacher.</p>
                                <p><b>7. Waiting Room Feature</b></p>
                                <p>When enabled, the participants stay in the Waiting Room until the host allows the authentic users into the live meeting. This two-fold security feature can be enabled from the security icon, and a host can always send any unauthorised participant back into the virtual waiting room area from the live ongoing meeting.</p>
                                <p><b>8. Remove a Participant or Report a User</b></p>
                                <p>The host or the teacher can kick out any unwanted participant from the Zoom live meeting; also can report against any user to the Zoom’s trust and safety team. Both the features are available from the security icon option.</p>
                                <p>Zoom has an inbuilt security feature named ‘suspend participant activities’ to temporarily halt all kinds of chats, video, audio, annotation, screen sharing if the class disruption goes out of control.</p>
                                <p><b>9. Security Option while scheduling a Class</b></p>
                                <p>Zoom has introduced a few privacy options while scheduling a class are as follows:</p>
                                <ul className='disc'>
                                    <li>Create a passcode for accessing the virtual classroom</li>
                                    <li>Mandatory registration shows every email addresses of all participants that been used to sign in</li>
                                    <li>Allow only authenticated users to join the meeting</li>
                                    <li>Disable joining before the host</li>
                                </ul>
                                <p>Launched in 2013 to connect remote office workers, Zoom has now become one of the most preferred online learning video conferencing platforms among online teachers for its easy set-up, the free version allowing up to 100 participants and the updated security features in the live meeting.</p>
                                <p>Globsyn Business School (GBS), ranked among the Top 50 B-Schools in India, has conducted all of its management classes through the Zoom App during this pandemic. This has led not only to an effective online teaching-learning process but also has developed a mutual trustworthy classroom environment over the internet. Recognizing the importance of hustle free communication through digital medium, GBS has partnered with Zoom to conduct uninterrupted classes, webinars, and other knowledge dissemination activities for its young managers.</p>
                                <p>To this end, before this pandemic stay at home phase, it was never a reality to conduct regular classes over the internet, but at present teachers have become tech savvy to use video conferencing apps like Zoom, Google Meet, amongst others. Cheers to our better future where nothing can stop learning.</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
