import React from 'react'
import { Link } from 'react-router-dom'

export default function RecentPost(props) {
  return (
    <>
    {props.users.map(user=> (
        <div className='row' style={{padding:"2px"}} key={user.id}>
        <ul>
            <li><Link to={user.url} >{user.btitle}</Link></li>
        </ul>
        </div>
    ))}
</>
  )
}
