import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function LearningBeyondEducation() {
  return (
    <><title>Learning Beyond Education</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Learning Beyond Education</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Learning Beyond Education</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Learning-Beyond-Education.png" alt="Learning-Beyond-Education" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                    Prof. Kaushik Ghosh, teaches Information System at Globsyn Business School – one of the best MBA colleges in Kolkata. Prof. Ghosh, who is a Microsoft Certified Professional in various Microsoft Technologies and Process Automation, has more than two decades industry-academia experience, having served in various capacities in MNCs and leading corporate houses. Prof. Ghosh is specialist in Robotic Process Automation and is the subject matter expert of the relevant course of the PGDM program in Globsyn Business School. His areas of expertise also include Python and Excel as robust data analytics and predictive modeling tools.
                                    </em>
                                </p>
                                <p style={{textAlign:"center"}}>
                                    <img className="img-fluid" src="/assets/images/Pic-01-KYLF-Joy-of-Giving-768x384.png" alt="KYLF-Joy-of-Giving" />
                                    <br/>
                                    <em >Winter Magic by KYLF volunteers as part of Joy of Giving</em>
                                </p>
                                <p>The purpose of any educational institution is to develop its students holistically as there is a high demand of complete and responsible individuals in society these days. An institute educating students follows a traditional model of teaching-learning process that undoubtedly tries to create a good academician but not necessarily a complete and responsible individual. The ‘giving’ mindset of an individual is supposedly having higher value-contribution to the society that can come from a responsible individual only.</p>
                                <p style={{textAlign:"center"}}>
                                    <img className="img-fluid" src="/assets/images/Pic-02-Serendipity-768x384.png" alt="KYLF-Joy-of-Giving" />
                                    <br/>
                                    <em >A moment from Serendipity, which provides an ideal platform to experience handling and managing a multi-dimensional large format event successfully            </em>
                                </p>
                                <p>One of the ways to nurture students to become a complete and responsible individual is to educate them through ‘Beyond Education’, where the learning happens outside the classroom. This platform of learning facilitates the students to apply and experience what they have learnt through theories inside the classroom be it subjects like Human Resource Management Or Marketing Management, a few names to mention. The student’s application of in-class learning through Beyond Education platform can be activities that ‘care’ or ‘give’ to the society, organizing events dedicated to their parents, developing Entrepreneur mindset, involving in research that contributes to the society by solving pertinent problems etc. In Globsyn Business School, the students pursuing their MBA, through Beyond Education platform, develop themselves holistically through:</p>
                                <ul className='disc'>
                                    <li>Activities of Kalyani Youth Leadership Forum viz. Elderly Care, Care for differently-abled, Channelizing Youth</li>
                                    <li>The event named Serendipity dedicated for their parents</li>
                                    <li>Embryon to develop Entrepreneur mindset</li>
                                    <li>Globsyn Management Conference to develop Research acumen</li>
                                </ul>
                                <p style={{textAlign:"center"}}>
                                    <img className="img-fluid" src="/assets/images/Pic-03-Embryon-with-BDG-and-Rahul-Sir-768x384.png" alt="KYLF-Joy-of-Giving" />
                                    <br/>
                                    <em >GBS Trustees Bikram Dasgupta and Rahul Dasgupta at Entre-arena, organized by the entrepreneurship cell – Embryon</em>
                                </p>
                                <p>The objective of Beyond Education is to establish a Framework for teaching-learning process outside the classroom. It is about taking the learners out of the classroom to the real world where they experience different exciting and challenging situations. This helps them to learn and innovate and subsequently propose solutions. The sole purpose of Beyond Education is to provide students a real world learning experience that will set them up for success in life. Learning outside the classroom encourages a student to engage in a broader range of work skills like teamwork, leadership, developing a sense of responsibility and gratitude, and understanding the need of giving back to the society.</p>
                                <p style={{textAlign:"center"}}>
                                    <img className="img-fluid" src="/assets/images/Pic-04-Other-KYLF-event-768x384.png" alt="KYLF-Joy-of-Giving" />
                                    <br/>
                                    <em >Independence day celebration by KYLF with the children of Calcutta Blind School</em>
                                </p>
                                <p>The Beyond Education platform benefits students through increased self-esteem and becoming more engaged in learning. Globsyn Business School has many pieces of evidence that suggest that learning outside the classroom helps students to raise their achievements, improve their classroom behavior and become a responsible social being. A report published by Ofsted found learning outside the classroom has significantly raised the standard of students and improved pupil’s social, personal and emotional development.</p>
                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/kaushik_jpg050251.jpg"
                                            alt="Prof. Kaushik Ghosh"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                        Prof. Kaushik Ghosh
                                            <br />
                                            Faculty – Information System
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
