import {Routes, Route } from 'react-router-dom';
import './App.css';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import Home from './pages/Home';
import Navbar from './pages/Navbar';
import Footer from './pages/Footer';
import Innovation from './pages/about gbs/edifince/Innovation'
import Research from './pages/about gbs/edifince/Research'
import Technology from './pages/about gbs/edifince/Technology';
import History from './pages/about gbs/History'
import Ranking from './pages/about gbs/Ranking';
import Trustees from './pages/about gbs/leadership/Trustees';
import ManagingTrustee from './pages/about gbs/leadership/ManagingTrustee';
import DirectorMessage from './pages/about gbs/leadership/DirectorMessage';
import GoverningCouncil from './pages/about gbs/governance/GoverningCouncil';
import AcademicCouncil from './pages/about gbs/governance/AcademicCouncil';
import Campus from './pages/about gbs/vistinggbs/Campus';
import AddmissionOffice from './pages/about gbs/vistinggbs/AddmissionOffice';
import MediaCoverage from './pages/about gbs/MediaCoverage';
import Newsletter from './pages/about gbs/Newsletter';
import Pgdm from './pages/academics/programmes/Pgdm';
import Mba from './pages/academics/programmes/Mba';
import PgdmBusiness from './pages/academics/programmes/PgdmBusiness';
import Customstudy from './pages/academics/Customstudy';
import Finance from './pages/academics/Schools of Excelance/Finance';
import Marketing from './pages/academics/Schools of Excelance/Marketing';
import HumanResource from './pages/academics/Schools of Excelance/HumanResource';
import InformationSystem from './pages/academics/Schools of Excelance/InformationSystem';
import OperationManegment from './pages/academics/Schools of Excelance/OperationManegment';
import ManegementDevelopment from './pages/academics/ManegementDevelopment';
import Corporate from './pages/academics/Experimental learning/Corporate';
import Klyf from './pages/academics/Beyond Education/Klyf';
import Embroyn from './pages/academics/Beyond Education/Embroyn';
import Serendipity from './pages/academics/Beyond Education/Serendipity';
import Teaching from './pages/faculty & staff/Teaching';
import Administrative from './pages/faculty & staff/Administrative';
import FacultyPublication from './pages/reasearch cell/FacultyPublication';
import Conferance from './pages/reasearch cell/Conferance';
import CaseStudy from './pages/reasearch cell/CaseStudy';
import KnowledgeCell from './pages/reasearch cell/KnowledgeCell';
import CampusFacilities from './pages/students/CampusFacilities';
import Awards from './pages/students/Awards';
import StudentDevelopment from './pages/students/StudentDevelopment';
import Apprenticeship from './pages/students/Apprenticeship';
import NationalScholarship from './pages/students/NationalScholarship';
import FinancialAid from './pages/students/FinancialAid';
import AlumniEngage from './pages/students/alumni/AlumniEngage';
import AlumniAcademic from './pages/students/alumni/AlumniAcademic';
import LifelongCareer from './pages/students/alumni/LifelongCareer';
import Alumni from './pages/students/alumni/Alumni';
import Placement from './pages/Placement';
import About from './pages/about gbs/About';
import Ivs from './pages/reasearch cell/Vision Seminar/Ivs';
import ESGtoSDG from './pages/reasearch cell/Vision Seminar/ESGtoSDG';
import IconSpeak from './pages/about gbs/IconSpeak';



import Blog from './pages/blogs/Blog';
import PursingMba from './pages/blogs/PursingMba';
import Significancenet from './pages/blogs/Significancenet';
import SipAtAsian from './pages/blogs/SipAtAsian';
import HigherEducationSystem from './pages/blogs/HigherEducationSystem';
import SipAtKelloogs from './pages/blogs/SipAtKelloogs';
import RememberingNB from './pages/blogs/RememberingNB';
import SocialImpactCyber from './pages/blogs/SocialImpactCyber';
import SipCarvyStock from './pages/blogs/SipCarvyStock';
import ImportanceOfDivercity from './pages/blogs/ImportanceOfDivercity';
import JourneyCampus from './pages/blogs/JourneyCampus';
import BridgingTheGap from './pages/blogs/BridgingTheGap';
import ThinkingSocial from './pages/blogs/ThinkingSocial';
import FifteenthAugust from './pages/blogs/FifteenthAugust';
import UsageOfEmoji from './pages/blogs/UsageOfEmoji';
import RoleOfField from './pages/blogs/RoleOfField';
import RevampStartup from './pages/blogs/RevampStartup';
import OttService from './pages/blogs/OttService';
import TipsToAce from './pages/blogs/TipsToAce';
import ImposingABan from './pages/blogs/ImposingABan';
import ItForManagers from './pages/blogs/ItForManagers';
import DurgaPuja from './pages/blogs/DurgaPuja';
import ManegmentFunction from './pages/blogs/ManegmentFunction';
import TheBlueOcean from './pages/blogs/TheBlueOcean';
import CostOfCommuting from './pages/blogs/CostOfCommuting';
import RoleOfSimulaton from './pages/blogs/RoleOfSimulaton';
import TipsToChase from './pages/blogs/TipsToChase';
import GipPartOne from './pages/blogs/GipPartOne';
import TopManegmentDna from './pages/blogs/TopManegmentDna';
import GreenEntrepreneurship from './pages/blogs/GreenEntrepreneurship';
import GipPartTwo from './pages/blogs/GipPartTwo';
import GipPartThree from './pages/blogs/GipPartThree';
import TheDefinitiveGuide from './pages/blogs/TheDefinitiveGuide';
import PgdmVsMba from './pages/blogs/PgdmVsMba';
import HumanizeLeadership from './pages/blogs/HumanizeLeadership';
import TheFineLine from './pages/blogs/TheFineLine';
import Coronavirus from './pages/blogs/Coronavirus';
import ImportanceofDataPrivacy from './pages/blogs/ImportanceofDataPrivacy';
import WhatEmployersLook from './pages/blogs/WhatEmployersLook';
import WhyImplementingOutcome from './pages/blogs/WhyImplementingOutcome';
import JobYouCanOne from './pages/blogs/JobYouCanOne';
import JobsYouCanTwo from './pages/blogs/JobsYouCanTwo';
import JobsYouCanthree from './pages/blogs/JobsYouCanthree';
import HowisFlipped from './pages/blogs/HowisFlipped';
import MarchingOnline from './pages/blogs/MarchingOnline';
import BrandingAtTheTimeofCOVID from './pages/blogs/BrandingAtTheTimeofCOVID';
import TeachingThroughTechnology from './pages/blogs/TeachingThroughTechnology';
import LiquidityCrunch from './pages/blogs/LiquidityCrunch';
import OnlineTeaching from './pages/blogs/OnlineTeaching';
import ParadigmShift from './pages/blogs/ParadigmShift';
import TechnologyAdoption from './pages/blogs/TechnologyAdoption';
import TipsTricksSailing from './pages/blogs/TipsTricksSailing';
import StimulusPackage from './pages/blogs/StimulusPackage';
import ForeignLanguages from './pages/blogs/ForeignLanguages';
import PracticingResearch from './pages/blogs/PracticingResearch';
import DigitalMarketing from './pages/blogs/DigitalMarketing';
import CommunicationSkill from './pages/blogs/CommunicationSkill';
import BlendedLearning from './pages/blogs/BlendedLearning';
import FinancialManagement from './pages/blogs/FinancialManagement';
import StrategiesDrive from './pages/blogs/StrategiesDrive';
import FutureMBAEducation from './pages/blogs/FutureMBAEducation';
import ExperientialLearning from './pages/blogs/ExperientialLearning';
import ArtificialIntelligence from './pages/blogs/ArtificialIntelligence';
import DecisionMaking from './pages/blogs/DecisionMaking';
import FifthVisionSeminar from './pages/blogs/FifthVisionSeminar';
import ZoomforTeaching from './pages/blogs/ZoomforTeaching';
import EmbryonEntrepreneurshipCell from './pages/blogs/EmbryonEntrepreneurshipCell';
import SerendipityIndustryRelevant from './pages/blogs/SerendipityIndustryRelevant';
import ClimbUpTheCorporate from './pages/blogs/ClimbUpTheCorporate';
import MBAInternationalBusiness from './pages/blogs/MBAInternationalBusiness';
import COVIDBewareAware from './pages/blogs/COVIDBewareAware';
import COVIDPartOne from './pages/blogs/COVIDPartOne';
import COVIDPartTwo from './pages/blogs/COVIDPartTwo';
import MyJourneyInCovid from './pages/blogs/MyJourneyInCovid';
import HealthyWaysStrengthen from './pages/blogs/HealthyWaysStrengthen';
import GlobsynManagementJournal from './pages/blogs/GlobsynManagementJournal';
import ZomatoIpo from './pages/blogs/ZomatoIpo';
import CodeRedHumanity from './pages/blogs/CodeRedHumanity';
import PrioritiseMental from './pages/blogs/PrioritiseMental';
import EffectiveZoomGuide from './pages/blogs/EffectiveZoomGuide';
import RisingDemandEntrepreneurship from './pages/blogs/RisingDemandEntrepreneurship';
import EvolutionEducation from './pages/blogs/EvolutionEducation';
import IndustryRelevantManager from './pages/blogs/IndustryRelevantManager';
import EcommerceTrends from './pages/blogs/EcommerceTrends';
import LookingBackYou from './pages/blogs/LookingBackYou';
import BuildingBusiness from './pages/blogs/BuildingBusiness';
import BigDataAnalytics from './pages/blogs/BigDataAnalytics';
import SociallyResponsibleInvestments from './pages/blogs/SociallyResponsibleInvestments';
import InnovationCatalyst from './pages/blogs/InnovationCatalyst';
import GigEconomy from './pages/blogs/GigEconomy';
import DiversityInclusivity from './pages/blogs/DiversityInclusivity';
import InternationalExpansion from './pages/blogs/InternationalExpansion';
import KnowledgeSharing from './pages/blogs/KnowledgeSharing';
import EmergenceHybridLearning from './pages/blogs/EmergenceHybridLearning';
import SignificanceExperientialLearning from './pages/blogs/SignificanceExperientialLearning';
import AreasConsider from './pages/blogs/AreasConsider';
import MBAMarketing from './pages/blogs/MBAMarketing';
import MBAFinance from './pages/blogs/MBAFinance';
import MBAHR from './pages/blogs/MBAHR';
import ExperientialLearningScale from './pages/blogs/ExperientialLearningScale';
import SpaceForMindfulness from './pages/blogs/SpaceForMindfulness';
import GamificationEducation from './pages/blogs/GamificationEducation';
import SuccessfulBusinessBuilding from './pages/blogs/SuccessfulBusinessBuilding';
import ManagementStudies from './pages/blogs/ManagementStudies';
import CloserLookGroupProjects from './pages/blogs/CloserLookGroupProjects';
import SummerInternshipNecessity from './pages/blogs/SummerInternshipNecessity';
import ReallifeExposure from './pages/blogs/ReallifeExposure';
import MomentCherish from './pages/blogs/MomentCherish';
import IndependenceDay from './pages/blogs/IndependenceDay';
import BloodDonationCamp from './pages/blogs/BloodDonationCamp';
import AnalyzeWaySuccess from './pages/blogs/AnalyzeWaySuccess';
import FestivalLights from './pages/blogs/FestivalLights';
import BrandBattlefield from './pages/blogs/BrandBattlefield';
import TrustMustTriumph from './pages/blogs/TrustMustTriumph';
import BusinessAnalytics from './pages/blogs/BusinessAnalytics';
import StatisticsTheGrammar from './pages/blogs/StatisticsTheGrammar';
import OrganizationalCulture from './pages/blogs/OrganizationalCulture';
import Consumerism from './pages/blogs/Consumerism';
import UnicornsDecacorns from './pages/blogs/UnicornsDecacorns';
import ImportanceOfResearch from './pages/blogs/ImportanceOfResearch';
import LearningBeyondEducation from './pages/blogs/LearningBeyondEducation';
import MasteringMindfulness from './pages/blogs/MasteringMindfulness';
import RevisitingStrategic from './pages/blogs/RevisitingStrategic';
import ResearchOnlyReSearch from './pages/blogs/ResearchOnlyReSearch';
import ERMEnterprises from './pages/blogs/ERMEnterprises';
import GreenInitiatives from './pages/blogs/GreenInitiatives';
import InternshipITCDairy from './pages/blogs/InternshipITCDairy';
import DeFi from './pages/blogs/DeFi';
import InternshipJubilantFoodWorks from './pages/blogs/InternshipJubilantFoodWorks';
import PracticeMantra from './pages/blogs/PracticeMantra';
import InternshipPantaloons from './pages/blogs/InternshipPantaloons';
import ExtremeValueTheory from './pages/blogs/ExtremeValueTheory';
import LiberalArt from './pages/blogs/LiberalArt';
import UnderstandingHappiness from './pages/blogs/UnderstandingHappiness';
import PlacementList from './pages/reasearch cell/PlacementList';

function App() {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);



  return (
    <>
    <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/history' element={<History/>} />
        <Route path='/innovation' element={<Innovation/>} />
        <Route path='/research' element={<Research/>} />
        <Route path='/technology' element={<Technology/>} />
        <Route path='/gbs-recognitions' element={<Ranking/>} />
        <Route path='/board-of-trustees' element={<Trustees/>} />
        <Route path='/message-from-managing-trustee' element={<ManagingTrustee/>} />
        <Route path='/directors-message' element={<DirectorMessage/>} />
        <Route path='/governing-council' element={<GoverningCouncil/>} />
        <Route path='/academic-council' element={<AcademicCouncil/>} />
        <Route path='/gbs-campus' element={<Campus/>} />
        <Route path='/admissions-office' element={<AddmissionOffice/>} />
        <Route path='/media-coverage' element={<MediaCoverage/>} />
        <Route path='/newsletter' element={<Newsletter/>} />
        <Route path='/pgdm' element={<Pgdm/>} />
        <Route path='/mba' element={<Mba/>} />
        <Route path='/pgdm-business-analytics' element={<PgdmBusiness/>} />
        <Route path='/custom-study-path' element={<Customstudy/>} />
        <Route path='/globsyn-finance-specialization' element={<Finance/>} />
        <Route path='/globsyn-marketing-specialization' element={<Marketing/>} />
        <Route path='/globsyn-hr-specialization' element={<HumanResource/>} />
        <Route path='/globsyn-information-system-it-specialization' element={<InformationSystem/>} />
        <Route path='/globsyn-operations-management' element={<OperationManegment/>} />
        <Route path='/management-development-program' element={<ManegementDevelopment/>} />
        <Route path='/corporate-connect' element={<Corporate/>} />
        <Route path='/kalyani-youth-leadership-forum' element={<Klyf/>} />
        <Route path='/gbs-embryon' element={<Embroyn/>} />
        <Route path='/serendipity' element={<Serendipity/>} />
        <Route path='/teaching-resource' element={<Teaching/>} />
        <Route path='/administrative-departments' element={<Administrative/>} />
        <Route path='/faculty-publications' element={<FacultyPublication/>} />
        <Route path='/globsyn-management-conference' element={<Conferance/>} />
        <Route path='/case-study' element={<CaseStudy/>} />
        <Route path='/knowledge-cell' element={<KnowledgeCell/>} />
        <Route path='/campus-facilities' element={<CampusFacilities/>} />
        <Route path='/awards' element={<Awards/>} />
        <Route path='/student-development-program' element={<StudentDevelopment/>} />
        <Route path='/globsyn-apprenticeship-program' element={<Apprenticeship/>} />
        <Route path='/national-scholarship-program' element={<NationalScholarship/>} />
        <Route path='/financial-aid' element={<FinancialAid/>} />
        <Route path='/globsyn-alumni-engage' element={<AlumniEngage/>} />
        <Route path='/globsyn-alumni-academic-programme-gaap' element={<AlumniAcademic/>} />
        <Route path='/globsyn-lifelong-career-support' element={<LifelongCareer/>} />
        <Route path='/alumni-my-story' element={<Alumni/>} />
        <Route path='/placement' element={<Placement/>} />
        <Route path='/about-gbs' element={<About/>} />
        <Route path='/ivs2023' element={<Ivs/>} />
        <Route path='/vs2022' element={<ESGtoSDG/>} />
        <Route path='/gbs-placement-2023' element={<PlacementList/>} />
        <Route path='/icon-speaks' element={<IconSpeak/>} />
       
        




        
        <Route path='/blog' element={<Blog/>} />
        <Route path='/blog/why-pursuing-an-mba-is-the-right-option-for-you' element={<PursingMba/>} />
        <Route path='/blog/significance-of-net-neutrality' element={<Significancenet/>} />
        <Route path='/blog/my-sip-experience-at-asian-paints' element={<SipAtAsian/>} />
        <Route path='/blog/indian-higher-education-system-globally-relevant-and-competitive' element={<HigherEducationSystem/>} />
        <Route path='/blog/summer-internship-program-at-kelloggs' element={<SipAtKelloogs/>} />
        <Route path='/blog/remembering-nandan-bhattacharya' element={<RememberingNB/>} />
        <Route path='/blog/the-business-and-social-impact-of-malpractices-in-cyber-universe' element={<SocialImpactCyber/>} />
        <Route path='/blog/internship-with-karvy-stock-broking-limited' element={<SipCarvyStock/>} />
        <Route path='/blog/importance-of-diversity-at-business-schools' element={<ImportanceOfDivercity/>} />
        <Route path='/blog/journey-from-campus-to-corporate-through-sip' element={<JourneyCampus/>} />
        <Route path='/blog/why-pursuing-an-mba-is-the-right-option-for-you' element={<PursingMba/>} />
        <Route path='/blog/bridging-the-gap-between-campus-and-corporate' element={<BridgingTheGap/>} />
        <Route path='/blog/thinking-social-seminar' element={<ThinkingSocial/>} />
        <Route path='/blog/15th-august-hows-the-josh' element={<FifteenthAugust/>} />
        <Route path='/blog/usage-of-emojis-at-workplace' element={<UsageOfEmoji/>} />
        <Route path='/blog/role-of-field-trips-in-the-life-of-management-students' element={<RoleOfField/>} />
        <Route path='/blog/revamp-your-start-up-idea-with-innovation' element={<RevampStartup/>} />
        <Route path='/blog/oot-services-a-game-changer-for-young-managers' element={<OttService/>} />
        <Route path='/blog/7-tips-to-ace-campus-placement' element={<TipsToAce/>} />
        <Route path='/blog/imposing-a-ban-on-sups-an-only-solution' element={<ImposingABan/>} />
        <Route path='/blog/it-for-managers-corporate-connect' element={<ItForManagers/>} />
        <Route path='/blog/durga-puja-a-management-case-study' element={<DurgaPuja/>} />
        <Route path='/blog/management-function-organizational-behaviour-corporate-connect' element={<ManegmentFunction/>} />
        <Route path='/blog/the-blue-ocean-strategy-venturing-into-virgin-markets' element={<TheBlueOcean/>} />
        <Route path='/blog/the-cost-of-commuting-to-work' element={<CostOfCommuting/>} />
        <Route path='/blog/role-of-simulation-in-management-education' element={<RoleOfSimulaton/>} />
        <Route path='/blog/tips-to-chase-away-those-pre-cat-blues' element={<TipsToChase/>} />
        <Route path='/blog/a-gbs-experience-through-global-immersion-program-part-1' element={<GipPartOne/>} />
        <Route path='/blog/top-management-dna' element={<TopManegmentDna/>} />
        <Route path='/blog/green-entrepreneurship-a-path-towards-sustainable-development' element={<GreenEntrepreneurship/>} />
        <Route path='/blog/a-gbs-experience-through-global-immersion-program-part-2' element={<GipPartTwo/>} />
        <Route path='/blog/a-gbs-experience-through-global-immersion-program-part-3' element={<GipPartThree/>} />
        <Route path='/blog/the-definitive-guide-to-project-management' element={<TheDefinitiveGuide/>} />
        <Route path='/blog/pgdm-vs-mba-what-is-the-wise-choice' element={<PgdmVsMba/>} />
        <Route path='/blog/how-to-humanize-leadership' element={<HumanizeLeadership/>} />
        <Route path='/blog/leadership-vs-bullying-the-fine-line' element={<TheFineLine/>} />
        <Route path='/blog/coronavirus-the-impact-on-chinese-global-economy' element={<Coronavirus/>} />
        <Route path='/blog/importance-of-data-privacy' element={<ImportanceofDataPrivacy/>} />
        <Route path='/blog/what-employers-look-for-when-hiring-recent-graduates' element={<WhatEmployersLook/>} />
        <Route path='/blog/why-implementing-outcome-based-education-the-call-of-the-hour' element={<WhyImplementingOutcome/>} />
        <Route path='/blog/jobs-you-can-apply-after-mba-part-1-marketing' element={<JobYouCanOne/>} />
        <Route path='/blog/jobs-you-can-apply-for-after-mba-part-2-human-resources' element={<JobsYouCanTwo/>} />
        <Route path='/blog/jobs-you-can-apply-for-after-mba-part-3-operations-management' element={<JobsYouCanthree/>} />
        <Route path='/blog/how-is-flipped-classroom-flipping-the-role-of-traditional-classroom-pedagogy' element={<HowisFlipped/>} />
        <Route path='/blog/marching-online' element={<MarchingOnline/>} />
        <Route path='/blog/branding-at-the-time-of-covid-19' element={<BrandingAtTheTimeofCOVID/>} />
        <Route path='/blog/teaching-through-technology-a-new-way-of-life-gbs' element={<TeachingThroughTechnology/>} />
        <Route path='/blog/liquidity-crunch-and-its-effect-on-the-economy' element={<LiquidityCrunch/>} />
        <Route path='/blog/online-teaching-the-new-reality-post-covid-19' element={<OnlineTeaching/>} />
        <Route path='/blog/paradigm-shift-but-seamless-transition' element={<ParadigmShift/>} />
        <Route path='/blog/covid-19-and-increased-technology-adoption' element={<TechnologyAdoption/>} />
        <Route path='/blog/tips-and-tricks-for-sailing-through-online-interviews' element={<TipsTricksSailing/>} />
        <Route path='/blog/announcement-of-the-stimulus-package-by-the-indian-government' element={<StimulusPackage/>} />
        <Route path='/blog/importance-of-foreign-languages-in-global-business-scenario' element={<ForeignLanguages/>} />
        <Route path='/blog/practicing-research-a-way-of-life-with-the-spice-of-passion-and-joy' element={<PracticingResearch/>} />
        <Route path='/blog/digital-marketing-in-emerging-economies' element={<DigitalMarketing/>} />
        <Route path='/blog/communication-a-key-skill-to-enhance-the-employability' element={<CommunicationSkill/>} />
        <Route path='/blog/blended-learning-grooming-students-for-new-normal-corporate' element={<BlendedLearning/>} />
        <Route path='/blog/financial-management-accounting-the-road-ahead' element={<FinancialManagement/>} />
        <Route path='/blog/strategies-to-drive-employee-engagement-to-boost-team-performance' element={<StrategiesDrive/>} />
        <Route path='/blog/the-future-of-mba-education-in-post-covid-19-world' element={<FutureMBAEducation/>} />
        <Route path='/blog/experiential-learning-in-management-education' element={<ExperientialLearning/>} />
        <Route path='/blog/the-roles-and-benefits-of-artificial-intelligence-in-education-industry' element={<ArtificialIntelligence/>} />
        <Route path='/blog/decision-making-a-major-skill-in-defining-leadership' element={<DecisionMaking/>} />
        <Route path='/blog/5th-international-vision-seminar-sustainability-in-the-age-of-disruption' element={<FifthVisionSeminar/>} />
        <Route path='/blog/how-to-use-zoom-for-effective-online-teaching' element={<ZoomforTeaching/>} />
        <Route path='/blog/embryon-the-entrepreneurship-cell-of-gbs-recognized-as-an-aascb-international-2021-innovation-that-inspires' element={<EmbryonEntrepreneurshipCell/>} />
        <Route path='/blog/serendipity-a-journey-to-become-industry-relevant' element={<SerendipityIndustryRelevant/>} />
        <Route path='/blog/climb-up-the-corporate-ladder-with-professional-mba-programme' element={<ClimbUpTheCorporate/>} />
        <Route path='/blog/mba-in-international-business-a-growing-trend-in-management-education-sector' element={<MBAInternationalBusiness/>} />
        <Route path='/blog/covid-19-from-beware-to-be-aware' element={<COVIDBewareAware/>} />
        <Route path='/blog/covid-19-trending-terminologies-part-1' element={<COVIDPartOne/>} />
        <Route path='/blog/covid-19-trending-terminologies-part-2' element={<COVIDPartTwo/>} />
        <Route path='/blog/my-journey-as-a-management-student-in-the-time-of-covid-19-pandemic' element={<MyJourneyInCovid/>} />
        <Route path='/blog/covid-19-healthy-ways-to-strengthen-your-immune-system' element={<HealthyWaysStrengthen/>} />
        <Route path='/blog/globsyn-management-journal-a-research-repository-of-the-management-realm' element={<GlobsynManagementJournal/>} />
        <Route path='/blog/zomato-ipo-a-success-story-or-an-over-optimistic-market-response' element={<ZomatoIpo/>} />
        <Route path='/blog/code-red-for-humanity-as-climate-change-is-real' element={<CodeRedHumanity/>} />
        <Route path='/blog/why-we-need-to-prioritise-mental-wellbeing-of-young-minds' element={<PrioritiseMental/>} />
        <Route path='/blog/an-effective-zoom-guide-features-and-etiquettes-you-should-know' element={<EffectiveZoomGuide/>} />
        <Route path='/blog/rising-demand-of-entrepreneurship-in-the-post-pandemic-world' element={<RisingDemandEntrepreneurship/>} />
        <Route path='/blog/the-evolution-of-education-framework-from-classroom-1-0-to-classroom-4-0' element={<EvolutionEducation/>} />
        <Route path='/blog/how-to-be-an-industry-relevant-manager-in-the-post-pandemic-era' element={<IndustryRelevantManager/>} />
        <Route path='/blog/e-commerce-trends-in-the-post-pandemic-world' element={<EcommerceTrends/>} />
        <Route path='/blog/heres-looking-back-at-you-2021' element={<LookingBackYou/>} />
        <Route path='/blog/building-business-resiliency-to-gain-needed-sustainability-in-the-post-pandemic-world' element={<BuildingBusiness/>} />
        <Route path='/blog/growing-significance-of-big-data-analytics-in-business' element={<BigDataAnalytics/>} />
        <Route path='/blog/socially-responsible-investments-the-way-to-earn-ethically' element={<SociallyResponsibleInvestments/>} />
        <Route path='/blog/innovation-as-a-catalyst-for-successful-entrepreneurship' element={<InnovationCatalyst/>} />
        <Route path='/blog/gig-economy-growing-significance-of-skill-based-education' element={<GigEconomy/>} />
        <Route path='/blog/diversity-inclusivity-and-the-role-of-middle-managers' element={<DiversityInclusivity/>} />
        <Route path='/blog/international-expansion-of-small-business' element={<InternationalExpansion/>} />
        <Route path='/blog/knowledge-sharing-as-the-key-to-success' element={<KnowledgeSharing/>} />
        <Route path='/blog/emergence-of-hybrid-learning' element={<EmergenceHybridLearning/>} />
        <Route path='/blog/The-Significance-of-Experiential-Learning-in-enhancing-Management-Education' element={<SignificanceExperientialLearning/>} />
        <Route path='/blog/areas-you-should-consider-before-applying-for-an-mba' element={<AreasConsider/>} />
        <Route path='/blog/all-about-mba-in-marketing' element={<MBAMarketing/>} />
        <Route path='/blog/all-about-mba-in-finance' element={<MBAFinance/>} />
        <Route path='/blog/all-about-mba-in-human-resource' element={<MBAHR/>} />
        <Route path='/blog/experiential-learning-to-scale-up-your-corporate-life' element={<ExperientialLearningScale/>} />
        <Route path='/blog/making-space-for-mindfulness' element={<SpaceForMindfulness/>} />
        <Route path='/blog/gamification-in-education' element={<GamificationEducation/>} />
        <Route path='/blog/successful-business-building-roles-and-importance-of-being-agile' element={<SuccessfulBusinessBuilding/>} />
        <Route path='/blog/management-studies-are-a-handholding-towards-entrepreneurship' element={<ManagementStudies/>} />
        <Route path='/blog/a-closer-look-at-group-projects-and-their-significance-in-management-education' element={<CloserLookGroupProjects/>} />
        <Route path='/blog/summer-internship-program-and-its-necessity' element={<SummerInternshipNecessity/>} />
        <Route path='/blog/real-life-exposure-redefining-educational-norms' element={<ReallifeExposure/>} />
        <Route path='/blog/a-moment-to-cherish-mango-magic-2022' element={<MomentCherish/>} />
        <Route path='/blog/celebrating-independence-day-with-the-visually-impaired' element={<IndependenceDay/>} />
        <Route path='/blog/blood-donation-camp-at-globsyn-business-school' element={<BloodDonationCamp/>} />
        <Route path='/blog/analyze-your-way-to-success' element={<AnalyzeWaySuccess/>} />
        <Route path='/blog/ushering-in-festival-of-lights-with-ummeed-2022' element={<FestivalLights/>} />
        <Route path='/blog/understanding-the-brand-battlefield' element={<BrandBattlefield/>} />
        <Route path='/blog/trust-must-triumph-employment-blues' element={<TrustMustTriumph/>} />
        <Route path='/blog/business-analytics-applications-across-all-verticals-of-business' element={<BusinessAnalytics/>} />
        <Route path='/blog/statistics-the-grammar-of-science' element={<StatisticsTheGrammar/>} />
        <Route path='/blog/organizational-culture-and-climate' element={<OrganizationalCulture/>} />
        <Route path='/blog/consumerism-is-a-shame-of-marketing' element={<Consumerism/>} />
        <Route path='/blog/unicorns-to-decacorns-startup-story-india' element={<UnicornsDecacorns/>} />
        <Route path='/blog/importance-of-research-in-management-education' element={<ImportanceOfResearch/>} />
        <Route path='/blog/learning-beyond-education' element={<LearningBeyondEducation/>} />
        <Route path='/blog/mastering-mindfulness-for-success-and-happiness' element={<MasteringMindfulness/>} />
        <Route path='/blog/revisiting-strategic-human-resource-management' element={<RevisitingStrategic/>} />
        <Route path='/blog/is-research-only-re-search' element={<ResearchOnlyReSearch/>} />
        <Route path='/blog/erm-in-msme' element={<ERMEnterprises/>} />
        <Route path='/blog/green-initiatives-way-to-brand-building' element={<GreenInitiatives/>} />
        <Route path='/blog/summer-internship-experience-at-itc-dairy' element={<InternshipITCDairy/>} />
        <Route path='/blog/peek-into-decentralized-finance' element={<DeFi/>} />
        <Route path='/blog/summer-internship-experience-at-jubilant-foodworks' element={<InternshipJubilantFoodWorks/>} />
        <Route path='/blog/best-practice-mantra' element={<PracticeMantra/>} />
        <Route path='/blog/summer-internship-experience-at-pantaloons' element={<InternshipPantaloons/>} />
        <Route path='/blog/extreme-value-theory-for-proactive-risk-management' element={<ExtremeValueTheory/>} />
        <Route path='/blog/management-as-liberal-art' element={<LiberalArt/>} />
        <Route path='/blog/understanding-happiness' element={<UnderstandingHappiness/>} />




      
      </Routes>

      <Footer />
    </>
  );
}

export default App;
