import React from "react";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";

export default function FestivalLights() {
  return (
    <>
      <title>Ushering in Festival of Lights with Ummeed 2022</title>
      <section className="hero-banner-img-blogs">
        <div className="container">
          <div className="entry-banner-content">
            <h1 className="entry-title">
              Ushering in Festival of Lights with Ummeed 2022
            </h1>
            <div className="d-flex">
              <div>
                <Link className="text-yellow" to="/">
                  Globsyn
                </Link>
              </div>
              <div>
                <span className="text-light">-</span>
              </div>
              <div>
                <Link className="text-light" to="/blog">
                  Blogs
                </Link>
              </div>
              <div>
                <span className="text-light">-</span>
              </div>
              <div>
                <Link className="text-light" to="">
                  Ushering in Festival of Lights with Ummeed 2022
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-8">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-9 col-12">
              <div></div>
              <div className="one-table">
                <p>
                  <img
                    className="img-fluid"
                    src="/assets/images/ummeed-celebration-at-globsyn-business-school.jpg"
                    alt="Ushering in Festival of Lights with Ummeed 2022"
                  />
                </p>
                <div className="entry-header">
                  <div className="entry-meta bar1 clearfix">
                    <ul>
                      <li>
                        <i className="fa fa-user me-2" aria-hidden="true"></i>
                        <span>By</span>Jonathan
                      </li>
                      <li>
                        <i className="fa fa-tags me-2" aria-hidden="true"></i>
                        Blog
                      </li>
                      <li>
                        <i
                          className="fa fa-comments-o me-2"
                          aria-hidden="true"
                        ></i>
                        <span>(0)</span> Comment
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-clock-o"></i> 3 min read
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                  <b>Editor’s Note</b>
                  <br />
                  <em>
                    Rohini Majumdar, Director – Content Writing, Kalyani Youth
                    Leadership Forum (KYLF) 2022-23, writes about her experience
                    of celebrating Diwali with the frontline support staff of
                    Globsyn Business School, where she along with fellow KYLF
                    members organized a specially themed initiative named
                    Ummeed, as a part of their ‘Care for Society’ activities
                    under the Beyond Education, that ringed in the hope of
                    better times through an engaging programme filled with fun,
                    games, gifts, and scrumptious food.
                  </em>
                </p>
                <p>
                  The members of Kalyani Youth Leadership Forum as a part of
                  their ‘Care for Society’ activities celebrated the
                  contribution of the blue collared staff of the B-school. It
                  was celebrated under the Beyond Education vertical of Globsyn
                  Business School.
                </p>
                <p>
                  The themed initiative named ‘Ummeed’ personifies the oncoming
                  of better times overcoming all obstacles. The event started in
                  the evening and the board members of KYLF shared great
                  enthusiasm and with high spirit the team invited the
                  front-line support staff to commence the event.
                </p>
                <p>
                  <img
                    className="img-fluid"
                    src="/assets/images/hit-the-pot-activities-at-GBS-ummeed-2022.jpg"
                    alt="Ushering in Festival of Lights with Ummeed 2022"
                  />
                </p>
                <p>
                  It gave me immense joy to discuss and plan the event with my
                  fellow board members. I along with the team took great
                  pleasure in executing an event of this grandeur.
                </p>
                <p>
                  The event had two different activities which included ‘Hit the
                  Pot’ & ‘Hit the Stick’. There was an excitement present among
                  everyone and enjoyed every bit of the event. The event
                  concluded with a closing speech by our Principal Mr. Mahendra
                  Pratap Singh, followed by the felicitation ceremony of the
                  staff members by each of the faculties.
                </p>
                <iframe
                  width="796"
                  height="446"
                  src="https://www.youtube.com/embed/rXFcdEk77Bk"
                  title="UMMEED 2022"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p>
                  We took this as an opportunity to express our heartfelt
                  gratitude towards the frontline support staff for their
                  tireless service all-round the year. As budding managers of
                  Globsyn Business School – the best PGDM college in Kolkata –
                  this gave an example of how important ‘Employee Engagement’ is
                  in an organization. It also gave us an opportunity to
                  empathize with the noble workers who take care of our daily
                  health and sanity.
                </p>
                <p>
                  I look forward to planning and executing more such events in
                  the future which not only broadens my knowledge but also gives
                  me an opportunity to extend a helping hand for the bigger
                  cause.
                </p>
                <div className="one-table">
                  <div className="one-table1">
                    <img
                      className="img-fluid"
                      src="/assets/images/camp4_profile-1-150x150.jpg"
                      alt="tt"
                      width="150"
                      height="150"
                    />
                  </div>
                  <div className="one-table2">
                    <p>
                      Rohini Majumdar
                      <br />
                      Director – Content Writing
                      <br />
                      Kalyani Youth Leadership Forum
                      <br />
                      Batch 2021-23
                      <br />
                      Globsyn Business School
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
