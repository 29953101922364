import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'


export default function CaseStudy() {
    return (
        <><title>Case Study - Globsyn</title>
            <section className="hero-banner-img-research">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Case Study</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Case Study</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table container">
                                <div id="exTab2" class="row  justify-content-center ">
                                    <div class="col-md-5 p-0">
                                        <ul class="nav nav-tabs d-flex flex-column">
                                            <li class="active bc-db text-center">
                                                <a href="#1" data-toggle="tab">Vodafone - Branding Challenges in India</a>
                                            </li>
                                            <li className="bc-db text-center"><a href="#2" data-toggle="tab">Tata Nano: The Low Cost Aspirational Car</a>
                                            </li>
                                            <li className="bc-db text-center"><a href="#3" data-toggle="tab">Reliance Fresh: The Turbulent Journey</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tab-content col-md-7">
                                        <div class="tab-pane active" id="1">
                                            <p>Vodafone, one of the largest mobile telecommunications service providers in the world was facing severe pressure from its matured European markets when it entered the rapidly growing mobile market of India in 2007 by acquiring Hutchison Essar. Hutch, with all its offerings and innovative advertisement had become one of the strongest brands of the subcontinent. The dilemma faced by Vodafone after acquiring such a strong brand, having different values and an entirely different brand persona, was how to position itself in the minds of the consumers by replacing Hutch. The challenge lied in the fact that Hutch had been hugely successful in their advertising campaigns where their ‘Boy and Pug’ advertisements had created waves around the nation winning thousands of hearts. The case explores the strategy Vodafone used to face these challenges and how they used advertising innovation as a tool to make people forget Hutch and recognise Vodafone.</p>
                                        </div>
                                        <div class="tab-pane" id="2">
                                            <p>The Tata Group of Companies is one of the most respected companies of India that is known across the globe for its strong business values. With a total revenue of USD 70.8 billion in 2008-09, the group’s strongest presence has been in the automobiles segment wherein it had added up to excellence through incessant innovation. The latest product innovation from Tata Motors, termed Tata Nano was conceptualized by the Chairman of the Tata Group of Companies Mr. Ratan Tata, and had hit the roads in 2009.</p>
                                            <p>The current case takes the reader through the pre launching phase of the product, right from the conceptualization by the Chairman of Tata Motors, till the implementation phase. The case analyzes the product from each and every aspect covering design, technology, price and promotion in order to justify the process of innovation. The study takes into consideration, a journey on the ‘Dream Car ‘, of Ratan Tata, which is uniquely low priced, to cater to the millions of ‘middle class’, who had never thought of using such a product. The case ends with a dilemma, where the readers are left to think, about the product, in terms of usage, existence, affordability, substitutability and technicality, and whether the product will actually revolutionize the way a large number of Indians travel.</p>
                                        </div>
                                        <div class="tab-pane" id="3">
                                            <p>Reliance Fresh was an initiative to bring about inclusive growth in India and build a robust supply chain to corporatize the agri-business. In the last 3 years they experienced lots of turmoil and still it is passing through difficult times. It reported a net loss of Rs.249.30 crores on revenue of Rs.1778.06 crores. Currently, it operates in over 900 stores in 80 cities spanning across 14 states, with around 4.2 million sq. ft. of trading space. The future will witness whether Reliance Fresh will be able to wither the difficult times faced from all sectors of the society and become successful like its parent company or they will need to adopt different strategies for success.</p>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
