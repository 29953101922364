import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function Awards() {
    return (
        <><title>Awards - Globsyn</title>
            <section className="hero-banner-img-sceren">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Awards</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Awards</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <ul className='disc'>
                                    <li>Debanjan Sasmal secured 1st position in Battle of Thrones – Chess Competition, Ritiman Thakur and Sourjodipta Sinha secured 1st position in Bid Wars – The Finance Game and Debanjani Pramanick, Nehal Verma, Kirti Bhattacharya, Rohini Saha, Aratrika Sarkar and Bipasha Choudhary secured 1st position in Pull Me If You Can – Tug Of War (Women) sections of ‘XAVIESTA’ 2019.</li>
                                    <li>Students of Globsyn Business School topped the ‘Conscious Scorecard’ event and wins the silver by coming 2nd from the combined regions of Eastern and Northern India, with an overall 4th position nationwide in the finals of the AIMS Conscious Capitalism Simulation Competition, 2018</li>
                                    <li>Students of Globsyn Business School won the silver at the prestigious KIIT Competition in 2018</li>
                                    <li>Ayush Kumar Bose, Debayan Bhattacharya, Loknath Dey, Mayank Shahi won the Amiphoria (Brandbiz-Business Plan) competition, 2017, organised by Amity University</li>
                                    <li>Ayush Kumar Bose, Debayan Bhattacharya, Loknath Dey, Mayank Shahi won award at Innovacion 2017, Institute of Engineering  Management</li>
                                    <li>2016: Sayak Roy of Globsyn Business School won ‘Connect The Dots’ competition, an All India E-cell meet organised by E-cell of IIT Kharagpur in 2016</li>
                                    <li>Rachna Kumari, Subhadarshan Nayak and Kalpak Kar of Globsyn Business School won awards at Xeron 2014, organised by Calcutta Business School</li>
                                    <li>Ankana Banerjee, Garima Sharma, Biswarup Bose and Ankit Jajodia won awards at IISWBM’s Inferno 2014</li>
                                    <li>Students of Globsyn Business School has emerged as the winner at ‘Mandi to Malls’ – a Marketing skill competition among students of different business schools, organised by IIM Calcutta</li>
                                    <li>Manbir Singh Batra of Globsyn Business School wins 3rd Prize at CMA Extempore Competition</li>
                                    <li>Zujar Kutub Bakkalla of GBS wins Runner Up award in The Debate and Ultimate Managers Game events at Annual Management Fest organized by IISWBM</li>
                                    <li>Neena Jha and Zujar Kutub Bakalla wins Extempore at Marine Engineering and Research Institute</li>
                                    <li>Priya Jaiswal, Probal Guha Thakurata, Neelakshi Saha, Dipannita Bhattacherya, Om Shankar Sharma win awards at IISWBMs Impressario</li>
                                    <li>Sharmistha Ghosh  Zeeshan Furrukh win award at Debate Competition organized by CMA  AIMA</li>
                                    <li>Zujar Bakkalla, Neena Jha, Manisha Hansia, Rahul Das, Vikash Rajdev, and Hussain Khandwala win award at Drucker Fest organized by ISBM</li>
                                    <li>Zujar Kutub Bakkalla, Vikash Prakash Rajdev, and Hussain Kikabhai Khandwala win ROCK-E – a marketing game in IISWBM</li>
                                    <li>Swapno Kumar Dash and Rohit Saharan win Tata NEN Business Idea Award</li>
                                </ul>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
