import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

export default function Testimonial() {

    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        cssEase: "linear",
        arrows: true,
        responsive: [{

            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                infinite: false
            }

        }, {

            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                dots: false,
                arrows: true,
            }

        }, {

            breakpoint: 300,
            settings: { // destroys slick
                slidesToShow: 1,
                dots: false
            }
        }]
    };

    return (
        <Slider {...settings}>
            <div className='d-flex flex-column justify-content-center align-items-center p-0'>
                <img className="lswssp-logo-img dot" src="https://www.globsyn.edu.in/wp-content/uploads/2023/03/Mr-Anil-Sahasrabudhe.jpg" alt="Deloitte_Logo-copy-2" />
                <p className='pt-3 speak' style={{ textAlign: "center" }}><em>"I receive a journal from your organization... It is one of the finest journals in management education, having a lot of case studies
                    and various issues connected with them... Congratulations for all of that!"</em>
                </p>
                <h4 className='pt-2'>Dr. Anil Sahasrabudhe</h4>
                <p style={{ fontSize: "16px" }}>Chairman - NBA and NETF, Ex-Chairman - AICTE</p>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center p-0'>
                <img className="lswssp-logo-img dot" src="https://www.globsyn.edu.in/wp-content/uploads/2023/03/fvbdfh.png" alt="Deloitte_Logo-copy-2" />
                <p className='pt-3 speak' style={{ textAlign: "center" }}><em>"I've been impressed by the unique nature of Globsyn Business School. I think this is the place where students not only learn good theory,
                    but also begin to learn how to practice that theory in the real world corporate situation."</em>
                </p>
                <h4 className='pt-2'>Dr. James McHann</h4>
                <p style={{ fontSize: "16px" }}>Clinical Professor of Management, Doermer School of Business, Purdue University Fort Wayne</p>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center p-0'>
                <img className="lswssp-logo-img dot" src="https://www.globsyn.edu.in/wp-content/uploads/2023/03/Mr-Narayana-Murthy.jpg" alt="Deloitte_Logo-copy-2" />
                <p className='pt-3 speak' style={{ textAlign: "center" }}><em>“Institutions like Globsyn are on a crusade to improve the readiness of professionals to add value to the industry… to take people to the next level.
                    We need more and more such organizations in India in this extraordinary period of growth.”</em>
                </p>
                <h4 className='pt-2'>Mr. Narayana Murthy</h4>
                <p >Founder, Infosys</p>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center p-0'>
                <img className="lswssp-logo-img dot" src="https://www.globsyn.edu.in/wp-content/uploads/2023/03/Mr-Rajendra-S-Pawar.jpg" alt="Deloitte_Logo-copy-2" />
                <p className='pt-3 speak' style={{ textAlign: "center" }}><em>"My expectation from the youth is that they are idea creators and idea merchants... and this is the new way forward."</em>
                </p>
                <h4 className='pt-2'>Mr. Rajendra S. Pawar</h4>
                <p>Founder, NIIT University Chairman & Co-Founder, NIIT Group </p>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center p-0'>
                <img className="lswssp-logo-img dot" src="https://www.globsyn.edu.in/wp-content/uploads/2023/03/Mr-R-Gopalakrishnan.jpg" alt="Deloitte_Logo-copy-2" />
                <p className='pt-3 speak' style={{ textAlign: "center" }}><em>"God couldn't have put you into a more fortunate position... Go out there... smell the roses.. enjoy your life.
                    And God be with you as you graduate from this wonderful institution."</em>
                </p>
                <h4 className='pt-2'>Mr. R. Gopalakrishnan</h4>
                <p>Former Executive Director, Tata Sons | Former Vice Chairman, Hindustan Lever | CEO, The Mindworks</p>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center p-0'>
                <img className="lswssp-logo-img dot" src="https://www.globsyn.edu.in/wp-content/uploads/2023/03/Mr-Anil-Sahasrabudhe.jpg" alt="Deloitte_Logo-copy-2" />
                <p className='pt-3 speak' style={{ textAlign: "center" }}><em>"I congratulate Globsyn Business School for taking an excellent step forward, and AICTE's support is there with the institute."</em>
                </p>
                <h4 className='pt-2'>Dr. Anil Sahasrabudhe</h4>
                <p>Chairman - NBA and NETF, Ex-Chairman - AICTE</p>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center p-0'>
                <img className="lswssp-logo-img dot" src="https://www.globsyn.edu.in/wp-content/uploads/2023/03/Mr-Hemant-Kanoria.jpg" alt="Deloitte_Logo-copy-2" />
                <p className='pt-3 speak' style={{ textAlign: "center" }}><em>"What Bikram said, it resonates very well – Innovation is something that has helped us to grow."</em>
                </p>
                <h4 className='pt-2'>Mr. Hemant Kanoria</h4>
                <p>Founder, Srei Infrastructure Finance Ltd.</p>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center p-0'>
                <img className="lswssp-logo-img dot" src="https://www.globsyn.edu.in/wp-content/uploads/2023/03/Mr-Anoop-Kumar-Agarwal.jpg" alt="Deloitte_Logo-copy-2" />
                <p className='pt-3 speak' style={{ textAlign: "center" }}><em>"India is a land of innovation. The word we use in the rustic sense is called 'jugar'... we have 'jugar' for everything.
                    If something is not working, we will put something else... I think the B-School will be imbibing these ideas and using their own network,
                    and the network across to give their students better education."</em>
                </p>
                <h4 className='pt-2'>Shri Anoop Kumar Agrawal, IAS</h4>
                <p>Principal Secretary, Technical Education, Training and Skill Development, Govt. of West Bengal</p>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center p-0'>
                <img className="lswssp-logo-img dot" src="https://www.globsyn.edu.in/wp-content/uploads/2023/03/Mr.-Abhijit-Roy-Picture-0132-dfgd.png" alt="Deloitte_Logo-copy-2" />
                <p className='pt-3 speak' style={{ textAlign: "center" }}><em>"Hard skills or academic skills hardly matter. It matter only to the extent of 10-15%... probably you're lucky if you're utilising that. What matters the most are the soft skills – power of learning, observation, empathy, being compassionate, being decisive, communication – these are the skills that will come in most handy. Globsyn Business School has tried to impart these soft skills through various programs."</em>
                </p>
                <h4 className='pt-2'>Mr. Abhijit Roy</h4>
                <p>MD and CEO Berger Paints India Ltd.</p>
            </div>

        </Slider>
    )
}
