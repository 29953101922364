import React from 'react'
import { Link } from 'react-router-dom'
import '../Common.css'
import Sidebar from '../../Sidebar'

export default function Trustees() {
    return (
        <><title>GBS Board of Trustees</title>
            <section className="hero-banner-img-trustee">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Board of Trustees</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/technology">Board of Trustees</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                                <h3 className="vc_custom_heading">GBS focuses on technology awareness</h3>
                            </div>
                            <div className="one-table">
                                <p><img className='w-100' src='../assets/images/GBS-Trustee-Page-Pic.png' alt='Leadership-and-Governance' /></p>
                                <strong style={{ fontSize: '12px' }}>Seated in front: Bikram Dasgupta, Founder & Executive Chairman, Globsyn Group, and Managing Trustee, Globsyn Knowledge Foundation
                                    Standing L to R: Rahul Dasgupta, Director & Trustee, Globsyn Business School; Ranjana Dasgupta, Trustee, Globsyn Knowledge Foundation; Romit Dasgupta, Trustee, Globsyn Knowledge Foundation</strong>
                                <div className="m-4">
                                    <div className="accordion" id="myAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">BIKRAM DASGUPTA, Managing Trustee</button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <div className="one-table">
                                                        <div className="one-table1"><img className="img-fluid" src="./assets/images/Bikram-Dasgupta-300x300.jpg" alt="" sizes="(max-width: 358px) 100vw, 358px" width="203" height="203" /></div>
                                                        <div className="one-table2">
                                                            <p>Bikram Dasgupta is the Founder and Executive Chairman, Globsyn Group, with interests in Information Technology, Education and Technology Training. An alumnus of Harvard Business School and Indian Institute of Technology, Kharagpur, Bikram Dasgupta is among the early pioneers of the Indian Information Technology Industry and has witnessed the emergence of the Indian IT Industry from garage start-ups to billion-dollar behemoths.</p>
                                                            <p>A technologist, innovator, visionary and a passionate speaker, Bikram was among the core group of professionals who joined HCL in its infancy and his contributions, as one of the key Market Development Managers, have gone a long way in establishing the market presence that HCL commands today. An out-of-the-box thinker, who strongly believes in ‘ordinary people can do extraordinary things…’whetted his formative entrepreneurial zeal by helping build Pertech Computers Limited (PCL).</p>
                                                            <p>As the Promoter-Director, he steered the company through the initial difficult years and took a leading role in managing a diverse set of functions ranging from Sales & Marketing, to International Business Development and new strategic projects, elevating the company to the position of No.1 PC manufacturer in India. He single-handedly won the single largest (US$ 50 million) export manufacturing contract from Dell Computer, USA, for computer motherboards, which is still rated as the largest single contract in the computer hardware industry. He also set up India’s first motherboard manufacturing unit and ramped it up to 10,000 motherboards/month production in 1994.</p>
                                                            <p>With the fast-changing IT landscape Bikram foresaw the need for a business model built around the fundamental concepts of Infrastructure and Institution to service the knowledge economy and in 1997 conceptualised Globsyn as a group of companies, which embodied that vision. To realise his vision of building an IT infrastructure of global standards in India, Bikram conceptualized and implemented, India’s first truly intelligent ‘Plug n Play’ infrastructure – ‘Infinity’, the model around which Software Parks have been developed in the country. On the institution building front, it has been Bikram’s lifelong zeal to develop young minds to enable them to face the challenges of the modern workplace and be productive from day one. It is in this mission that he conceptualised and pioneered the Knowledge Finishing SchoolTM (KFS) System which bridges the growing gap between academics and industry through industry relevant programs in a world className infrastructural setting. This, early innovator approach and concept, found expression through Software Finishing School programs at TECHNOCAMPUS in 1997, the then IBM Centre for Excellence. Over the years, 180000+ engineers have been trained in high-demand technology domains through the Software Finishing School programs.</p>
                                                            <p>Today under Bikram’s tutelage Globsyn 3rd.Life has grown to be one of the largest IT companies set up in Bengal, with over 1000+ executives working in the domains of FinTech and Artificial Intelligence. Bikram’s venture into management education saw him setup Globsyn Business School (GBS) as India’s First Corporate B-School. Driven by Bikram’s vision of continuous innovation in management education for nearly two decades, the 3500+ alumni of GBS are currently working in mid to senior management roles in organizations across the globe.</p>
                                                            <p>As Bikram Dasgupta transits from a momentous and frenzied phase and embarks upon a new journey in his life, he is driven by an aspiration to build a more equitable social fabric through his foundation, which works in two distinct verticals – philanthropic activities for the underprivileged through Kalyani and benefactor to the people working behind the scenes in the areas of music, theatre and dance under the brand Calcutta Broadway.</p>
                                                            <strong>LinkedIn: <a href="https://www.linkedin.com/in/bikram-dasgupta-26b15a" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/in/bikram-dasgupta-26b15a</a></strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo">RANJANA DASGUPTA, Trustee</button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <div className="one-table">
                                                        <div className="one-table1"><img className="img-fluid" src="./assets/images/Ranjana-Dasgupta-300x300.jpg" alt="" sizes="(max-width: 358px) 100vw, 358px" width="203" height="203" /></div>
                                                        <div className="one-table2">
                                                            <p>Ranjana Dasgupta, Managing Trustee, Bikram Dasgupta Foundation, brings to the Board her sharp insights on the people front. An avid contributor to the growth story of Globsyn she has been associated as a Director with several companies, since the last 10 years, and has played a vital role in their management.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">RAHUL DASGUPTA, Trustee</button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <div className="one-table">
                                                        <div className="one-table1"><img className="img-fluid" src="./assets/images/rdg-e1655708299970.jpg" alt="" sizes="(max-width: 358px) 100vw, 358px" width="203" height="203" /></div>
                                                        <div className="one-table2">
                                                            <p>An alumnus of Durham Business School, UK and Wharton Business School, USA, Rahul Dasgupta, Director, Globsyn Business School, leads the Group’s forays in management education and technology training. He is the chief architect behind the pedagogical change in the academic delivery system of Globsyn Business School (GBS), the education vertical of the Group, which merges industry requirements with academic curriculum to build industry-relevant managers through its Knowledge Finishing SchoolTM System and eGlobsyn – a web 2.0 platform dedicated to the students. Rahul also spearheads the technology training vertical of Globsyn, which has trained over 1,80,000 engineering students through the years in various technology tracks like .NET, JEE, PHP, Oracle, Android and Azure among others, and has recently included subjects like Machine Learning, Data Analytics, Internet of Things and Blockchain. Globsyn Finishing School annually trains nearly 5,000 engineering graduates from 150+ colleges across India to equip them with necessary skills to join the Indian IT industry.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">ROMIT DASGUPTA, Trustee</button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <div className="one-table">
                                                        <div className="one-table1"><img className="img-fluid" src="./assets/images/Romit-Dasgupta-300x300.jpg" alt="" sizes="(max-width: 358px) 100vw, 358px" width="203" height="203" /></div>
                                                        <div className="one-table2">
                                                            <p>Romit Dasgupta is a serial entrepreneur who at the age of 23 (2002), did his first tech start-up in New York and built it into a multi-million-dollar enterprise with Fortune 500 clients. In 2007, he joined his family business, Globsyn Group having business interests in Education, Technology, Vocational Training and Real Estate. He brings a global outlook, strategic vision and a profound passion for institution building to all his ventures. In his recent role as the MD of Globsyn’s technology business (Artificial Intelligence & Fintech), he was instrumental in scaling its operations from 50 to over 2000 people in India & abroad. He is an Angel Investor & plays an active role in mentoring start-ups. Romit moved to UAE in 2019, to expand his business interest in the area of Artificial Intelligence, Machine Learning and Fintech. Romit is an authority in the area of Artificial Intelligence & Machine Learning and has been a speaker at various seminars and forums including TiE, Indo-American Chamber of Commerce, the House of Commons (UK) and GITEX (UAE). His team building and people skills have seen him taking up leadership positions in several youth organisations including CII-Young Indians, the Youth wing of Confederation of Indian Industries, which he led as its Chairman (Kolkata, India).</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>Trustee Details <a href="./assets/pdf/Trustee-Details.pdf" target="_blank" rel="noopener noreferrer">[Download]</a></p>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
