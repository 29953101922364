import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function BridgingTheGap() {
    return (
        <><title>Bridging the Gap between Campus and Corporate</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Bridging the Gap between Campus and Corporate</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Bridging the Gap between Campus and Corporate</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Bridgethegap_Blog.jpg" alt="Bridgethegap" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Campus and Corporate are two different realms, which operate on different platforms with different purposes and ideologies. However, due to these dynamic times, there is a need that these two different worlds come together under one umbrella to address and tackle some real-world issues and challenges. In India and in most of the countries, a large category of the population are not getting jobs and this is indeed a grave problem as it directly affects the economy of a particular country on social conditions. Although millions of youngsters are getting trained by educational institutions, yet corporate often complains that there are fewer students who are industry-ready or job-ready or they are not getting the exact skill or talent that is required in a candidate for a job. In order to increase manpower resource, corporates have started to lower their recruiting standards and they have also started to train the candidates to make them productive. However, the problem arises when these arrangements cannot last for long, as it affects the organisation’s productivity.</p>
                                <p>So, to answer the question ‘where are we going wrong and what are the measures to tackle this challenge’, Globsyn Business School organized <b>Human Capital Conclave</b> in collaboration with <b>National HRD Network (Kolkata Chapter)</b>, with the theme,<b> ‘Bridging the Gap between Campus and Corporate’</b>.</p>
                                <p>Some of the views and ideas shared at this event were:</p>
                                <ul className='disc'>
                                    <li><b>Emerging Edge Skills – In the next 5 years</b></li>
                                </ul>
                                <p>The motive of this session was to inform students about the type of skills they should learn, and the type of skills that will help make them scale the corporate world. According to Sushmita Chowdhury, HR Specialist,  Richard Design Services India Pvt. Ltd., “Students should focus on their personal traits and make themselves ‘culturally fit’ because, in this fast-moving economy, not only should we take care of technical requirements, but taking care of ‘cultural reliability’ is also necessary, and this requires some attributes like Self-Management, Communication and Collaboration.”</p>
                                <p>Let us delve into these emerging skills and discuss them briefly.</p>
                                <ol>
                                    <li><b>Self-Management</b>: Students should know how to manage themselves because the first thing corporates notice, is to look into an applicant’s personality.</li>
                                    <li><b>Communication</b>: Students should work on their communication skills as it plays a very important role in developing interpersonal as well as leadership skills.</li>
                                    <li><b>Collaboration</b>: Students should learn how to be a team player. Wherever required, they should share their ideas and should help when someone is stuck in a difficult situation.</li>
                                </ol>
                                <p>Moreover, students should also be innovative, they should be a critical thinker, active learner and should have the ability to build relationships. In the words of Ananda Dutta, Asst. Manager – HR, Shrachi Group, “There is a dearth of creativity in people nowadays. We are developing various technologies around us but we need to brush up our creative skills as well. We should focus on originality because, in a competitive market, a lot of things come and go, but we should maintain originality and creativity. Moreover, students should have the clarity of thought – they should know who they are and what they want in their life.”</p>
                                <ul className='disc'>
                                    <li><b>Corporate Expectations from Campus</b></li>
                                </ul>
                                <p>This session stressed on the expectations of Corporate from Campus – factors the Campus needs to focus on and also what the students need to focus on. Mr. Shrutorsi Mukherjee, Associate VP-HR, SREI Financial Services says, “Campus needs to focus on 4 aspects; Placement Cell, Placement Data, Networking and Training.”</p>
                                <p>Each of these terms were further explained as mentioned below:</p>
                                <ol>
                                    <li><b>Placement Cell</b>: A Placement Cell or Placement Officer of the organization should be good – because it depends on them what type of companies are recruiting their students.</li>
                                    <li><b>Placement Data</b>: The campus should maintain the data of their past students who were hired along with the companies who hired them. They should know what type of students were hired and what type of companies recruited. This type of data helps the campus to maintain the standard of students and corporates both.</li>
                                    <li><b>Networking</b>: Campus should take care about the reliability and authenticity of corporates they are networking with.</li>
                                    <li><b>Training</b>: The most important aspect is the training of students – because proper training leads to success in the corporate world.</li>
                                </ol>
                                <p>Mr. Dhrubajyoti Majumder, the cluster HR Manager,  Larsen & Toubro Ltd. said, “During campus recruitments, recruiters judge the depth of knowledge of the students. So, students need to know their subject matter, they need to highlight their extra-curricular activities, strengthen their interpersonal and leadership skills and should definitely attend their Summer Internship as it is their only credential of work. And along with all that, students need to invest in themselves by learning new skills from time to time.”</p>
                                <ul className='disc'>
                                    <li><b>Business Analytics</b></li>
                                </ul>
                                <p>This session focussed on the motive of the growth of businesses using Business Analytics and Big Data. In the session, the esteemed panelists explained what Business Analytics is and how can we use it to make our business successful. Business Analytics is the iterative exploration of an organization’s data with prominence on statistical analysis. According to Abhijeet Dey, Sr. VP, Fullerton India Credit Company Ltd., “Big corporations use Business Analytics to project the right kind of perspective for their organization.” And the right kind of perspective depends on skilled analysts who understand the business as well as technologies involved, and an organisational allegiance for using data to gain insights into informed business decisions.</p>
                                <p>At the end of this event, students, faculty and the corporates understood that to survive in this VUCA (Volatility, Uncertainty, Complexity and Ambiguity) world, the academic institutions should train the students to become corporate-relevant from Day-1. They should become quick-learners, critical-thinkers, innovators who should be able to quickly adapt to the corporate world from the day they are recruited.</p>

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
