import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function COVIDPartOne() {
  return (
    <><title>COVID-19: Trending Terminologies – Part 1</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">COVID-19: Trending Terminologies – Part 1</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">COVID-19: Trending Terminologies – Part 1</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/stop-coronavirus.jpg" alt="COVID-19: Trending Terminologies – Part 1" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>It’s been more than a year since the news first broke of novel coronavirus spreading in some parts of the world. Since its emergence, we have acquired a whole new way of life along with the development of a new vocabulary. Who would have thought that we would be discussing the basic concepts of virology and epidemiology amongst us? Or having a conversation about the dangers of disease transmission or the benefits of wearing a mask? Here’s a quick rundown of some of the most common terms that are now part of our everyday COVID-19 vocabulary.</p>
                            <p><b>COVID-19</b></p>
                            <p>It is a coronavirus disease 2019, formerly referred to as “2019 novel coronavirus” or “2019-nCoV”. It is caused by the SARS-CoV-2 and spreads from one person to another through respiratory droplets. COVID-19 illness can range from mild to severe with symptoms as mentioned by World Health Organization (WHO).</p>
                            <p><b>Incubation Period</b></p>
                            <p>The incubation period is identified as the period from when an individual is first exposed to the virus to the time when the symptoms start developing on his/her body. For COVID-19, the incubation period is 2-14 days.</p>
                            <p><b>Community Spread</b></p>
                            <p>Community spread indicates people in a particular area or zone that have been infected by the disease. The best way to prevent community spread is by avoiding crowds, staying at home as much as possible, and by maintaining six feet distance from others.</p>
                            <p><b>Pandemic</b></p>
                            <p>A pandemic is a prevalence of a disease that has spread across the globe and created havoc. COVID-19 has been declared a pandemic due to its rapid spread over 100 different nations around the globe.</p>
                            <p><b>Lockdown</b></p>
                            <p>Lockdown is known to an effective emergency measure taken by governments for restricting the visiting of individuals to certain specific areas and within stated periods. The same is carried out for curbing the transmission of a viral disease. During lockdown, people are rather encouraged to stay at home and not to venture outside.</p>
                            <p><b>Containment Zones</b></p>
                            <p>As per the Ministry of Health and Family Welfare, containment zones are the specific geographical regions where positive COVID-19 cases are found in large numbers. In containment zones, government authorities restrict internal movement and interaction among people. The health professionals monitor infected persons and provide necessary supplies to them.</p>
                            <p><b>Quarantine</b></p>
                            <p>Quarantine or Self-quarantine refers to the mode based on which an individual refrains from maintaining any type of contact with others for a specific period like a fortnight in the case of COVID-19.</p>
                            <p><b>Self-Isolation</b></p>
                            <p>The term Self-Isolation indicates the activity based on which an individual tends to separate or isolate oneself from other individuals, within a community or social network, to help in reducing the transmission of the virus.</p>
                            <p><b>Social Distancing</b></p>
                            <p>Social Distancing involves a conscious effort of staying physically separate from others to reduce the chances of transmission of diseases like COVID-19. Social Distancing activities also include developing remote working spheres, cancellation of events involving social gathering and staying apart from each other at a distance of 6 feet or 2 meters.</p>
                            <p>As novel coronavirus rages on despite the taking of potential curbing measures and third wave is approaching, understanding the terminologies associated with the pandemic will help us in building acquaintance with emerging challenges and situations. Stay tuned for yet another discussion on COVID-19 terminologies in our next blog, where we will come up with a few more popular COVID-19 terms and details.</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
