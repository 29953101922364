import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function Significancenet() {
  return (
    <><title>Significance of Net Neutrality</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">Significance of Net Neutrality</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog">Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="">Significance of Net Neutrality</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>

                    <div className="one-table">
                        <div className="entry-header">

                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>Internet has become part of our daily life. We use it for number of reasons like searching for particular information, financial transactions, filling up online applications, gaming, social networking etc. The Internet/Telecom service providers charge their subscribers based on the data consumed/data plan subscribed. It’s known to all that the amount of data consumed during a particular surfing period/session is highly dependent on type of content accessed. For e.g. if anyone watching online video for an hour, his/her data consumed is much higher than that of a person who is browsing only text documents/web pages.</p>
                        <p>Now let’s assume a hypothetical situation. The ISPs start charging to their subscribers based on websites/app visits. Say for example, ISP ‘A’ charges to their subscribers on browsing websites ‘X’, ‘Y’ and ‘Z’ but they provide free service on browsing websites ‘D’, ‘E’ and ‘F’. On the other hand ISP ‘B’ throttles its network speed on browsing some specific websites/apps and provides full network speed for all other websites/apps.</p>
                        <p>In these two scenarios, the ISPs are biased towards some specific apps/websites on providing their service. This implies that the ISPs are not neutral towards the content their subscribers browsed. Here comes the question of Network Neutrality/Net Neutrality.</p>
                        <p>Network neutrality or Net neutrality refers to the idea that Internet Service Providers (ISPs) should treat all data that travels over their data networks freely, without inappropriate discrimination in favor of some specific apps, sites or services. It can also be defined as the principle that individuals should be free to access all web content and applications equally, regardless of the source, without ISPs discriminating against some particular online services or applications. In other words, it is the principle that the ISP that connects you to the internet does not get to control what you access on the internet. This principle is directly related to the protection of the future of the open Internet.</p>
                        <p>If we consider the power supply sector for better understanding of the ‘neutrality’ concept then the scenario looks like-the power supply companies charge their consumers based on units of electricity consumed for various purposes like for running television, refrigerators, mixers, bulbs, air coolers etc. The electricity company does not specify the end usage of the units consumer purchase, does not discriminate between brands or type of appliances on costs, quality of electricity etc. The same logic applies to the internet. Net neutrality has to be defined as no discrimination to the users on the basis of access, speed, and content. While the first two aspects are in the purview of internet service providers, the last one – content – is under the platform providers.</p>
                        <p>Now have a look at two incidents, which happened in India, related to this issue. Few years ago, Facebook launched Internet.org in India with Reliance Communications. It aimed to provide free access to some websites through an app. Under this project, Bing was offered as the only search engine. Sunil Mittal, CEO of Bharti Airtel, criticized the concept. But in the same year, Airtel announced the “Airtel Zero” scheme. In this case, app firms come under a contract and the telecom service provider allows its subscribers to use the apps for free of cost.</p>
                        <p>The groups/individuals who are in favor of zero-rating schemes or against the idea of net neutrality argue that it will help the financially poor section of the society, and this will be the boost new customers will need to get on to the world of World Wide Web (WWW). But this is a one sided view. While their zero rating plans might reduce the cost of surfing a few websites, accessing a few applications or might even make them free but this will create limited choice for the end users. It will result in creation of a discriminatory environment, which is especially more dangerous to new digital natives, since it denies them the same type of access which digital incumbents have. These Zero-rated services not only violate the principles of net neutrality but also throttle innovation and entrepreneurship by excluding new websites, home-grown apps, local language content providers which may not be available on these schemes.</p>
                        <p>The architecture of WWW is designed to be neutral. In a developing country like India where there is a huge potential for internet services to grow, the ISPs have discovered the power to discriminate on access, speed and costs using advanced technology for short-term corporate gains but ignoring long-term consumer benefits. Strong internet access is the backbone of Central Government’s ambitious project of Digital India and is extremely crucial for job creation in this digital era.</p>
                        <p>From Netizen’s perspective, as long as a consumer bought the right to consume a certain amount of data, he/she should be completely free to do what he/she wish to do with it, whether watch movies, browse websites, communicate via application based text/call. The individuals/groups who are believers in providing free internet access as philanthropy or CSR activity should do it without any pre-specified criteria. The policy makers should clearly define the subject of net neutrality and take informed decisions to promote the growth of open internet which will ultimately benefit entrepreneur, content creators and end users.</p>
                        <p><strong>Prof. Debmalya Banerjee</strong></p>
                        <p><Link to="/knowledge-cell">Knowledge Cell</Link></p>
                    
                                       
                    </div>

                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
