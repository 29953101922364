import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function EmbryonEntrepreneurshipCell() {
    return (
        <><title>‘Embryon – The Entrepreneurship Cell’ of GBS: Recognized as an AASCB International 2021 Innovation That Inspires</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">‘Embryon – The Entrepreneurship Cell’ of GBS: Recognized as an AASCB International 2021 Innovation That Inspires</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">‘Embryon – The Entrepreneurship Cell’ of GBS: Recognized as an AASCB International 2021 Innovation That Inspires</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/Embryon_800x400.jpg" alt="Embryon" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>In order to infuse global standards into its pedagogy, Globsyn Business School (GBS) has joined AACSB International – the Association to Advance Collegiate Schools of Business (AACSB), the world’s largest business education alliance. With this coveted membership, GBS is now one of the few academic institutes in Eastern India and one of only two B-Schools in West Bengal to belong to a part of the world’s largest network of business schools and organizations, focused on advancing business education.</p>
                                <p>Recently ‘Embryon – The Entrepreneurship Cell’, a Beyond Education vertical of GBS has been recognized among 24 business schools, globally, as an AACSB International 2021 Innovation That Inspires.This annual program recognizes institutions from around the world that serve as champions of change in the business education landscape. In concert with its ‘Connected for Better’ campaign, emphasizing business education’s positive contributions to society, AACSB’s 2021 Innovations That Inspire initiative showcases business schools creating positive societal impact.</p>
                                <p>Now in its sixth year, the Innovations That Inspire initiative has highlighted more than 160 business school efforts that exemplify forward-looking approaches to education, research, community engagement or outreach, entrepreneurship, and leadership. ‘Embryon – the Entrepreneurship Cell’ of GBS resonates this approach by conducting a number of entrepreneurial events at the institution’s campus every year, with EntreArena being the signature event.</p>
                                <p>In this event, students showcase and sell products, which include artwork, homemade food, and beverage items. Students develop and implement effective and efficient delivery mechanisms on their own to procure all the raw materials and semi-finished products from people in adjoining rural areas. The event usually lasts for five hours, starting at noon. Students get two hours to arrange everything before the event starts. They also create an integrated marketing communication plan using both online and offline media vehicles to generate buzz for their products before the event. The team size is capped at five, and all teams get stalls of the same size, which they decorate on their own. In order to maintain proper control of financial transactions, paper coupons are issued by the EntreArena organizing committee to the interested buyers in lieu of paper money. During the purchase, buyers provide the coupons to student entrepreneur teams in exchange for products. After the event, these coupons are redeemed, and each team gets their share. Embryon’s entrepreneurship cell sets aside a certain percentage of the entire profit as a reserve for future events.</p>
                                <p>Through EntreArena students can learn how to forecast customer demand and implement subsequent strategies for fulfilling the demand through proportional supply of their products. The event not only enables student to understand how to handle inventory, logistics, and supply chain, as well how to keep financial records and do financial analysis but also develop critical managerial skills such as leadership, planning, organizing, coordinating, strategic thinking, teamwork, and decision-making. EntreArena helps students develop skills to become better managers, and enables them to gain a hands-on understanding of innovation as a means for differentiation and a defining principle of entrepreneurship.</p>
                                <p>Over the years, GBS has received many laurels from industry-academia fraternity for its academic excellences. Staying true to its legacy for over twenty years, the B-School continues its journey of amalgamating technology with innovation and creating milestones in the education sector. Globsyn firmly believes that educational progress can be made by employing project-based group work to bring together diverse skills and disciplines; and with Embryon, GBS provides an innovative entrepreneurial ecosystem where students get the opportunity to apply their knowledge and skills to create a prototype and implement innovative, sustainable, and value-enhancing solutions for identified areas of concern in a socially responsible manner.</p>

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
