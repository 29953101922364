import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { EnquiryForm } from './schemas/EnquiryForm'

export default function Navbar() {

  const initialValues = {
    name : "",
    email : "",
    phone : "",
    city : "",
    state : "",   
  }


const {values,errors,handleBlur,handleChange,handleSubmit,touched} = useFormik({
  initialValues : initialValues,
  validationSchema : EnquiryForm,
  onSubmit : (values,action) =>{
    console.log(values);
    action.resetForm();
  }
});

  return (
    <>
      <button className="trigger_popup" id="myBtn" data-bs-toggle="modal" data-bs-target="#body-sidebar-button">Admission
        Enquiry</button>
      <div className="modal fade" id="body-sidebar-button" tabIndex="-1" aria-labelledby="exampleModalCenterTitle">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="head_text">Enquire Now</div>
            <span className="close" data-bs-dismiss="modal" aria-label="Close">×</span>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="col-md-12 pb-3" >
                    <input type="text" name="name" id="name" className="form-control widget_input" placeholder="Enter Name *" value={values.name} onChange={handleChange} onBlur={handleBlur} />
                {errors.name && touched.name ? (<em>{errors.name}</em>): null}
                </div>
                <div className="col-md-12 pb-3" >
                    <input type="text" name="email" id="email" className="form-control widget_input" placeholder="Enter Email *" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                {errors.email && touched.email ? (<em>{errors.email}</em>): null}
                </div>
               
                <div className="col-md-12 pb-3" >
                    <input type="text" name="phone" id="phone" className="form-control widget_input" placeholder="Enter Phone Number *" value={values.phone} onChange={handleChange} onBlur={handleBlur} />
                {errors.phone && touched.phone ? (<em>{errors.phone}</em>): null}
                </div>
                <div style={{display:"flex"}}>
                <div className="col-md-6 pb-3" >
                    <input type="text" name="state" id="state" className="form-control widget_input" placeholder="Enter State *" value={values.state} onChange={handleChange} onBlur={handleBlur} />
                {errors.state && touched.state ? (<em>{errors.state}</em>): null}
                </div>
                <div className="col-md-6 pb-3" >
                    <input type="text" name="city" id="city" className="form-control widget_input" placeholder="Enter City *" value={values.city} onChange={handleChange} onBlur={handleBlur} />
                {errors.city && touched.city ? (<em>{errors.city}</em>): null}
                </div>
                </div>
                <div className="col-md-12 pb-3" >
                <button type="submit"  className="btn btn-default btn-npf btn-register margin-right-15">Submit</button>
                </div>
              
              </form>

            </div>
          </div>
        </div>
      </div>

      <header>
        <div id="header2" className="header2-area">
          <div className="header-top-area">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="header-top-left">
                    <ul>
                      <li><i className="fa fa-phone" aria-hidden="true"></i><a href="Tel:+918274004556">
                        +91 82740 04556 </a></li>
                      <li><i className="fa fa-envelope" aria-hidden="true"></i><a
                        href="mailto:gbsdirect@globsyn.edu.in">gbsdirect@globsyn.edu.in</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div className="header-top-right">
                    <ul>
                      <li>
                        <div className="apply-btn-area">
                          <Link to="#" className="apply-now-btn">Apply Now</Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <nav className="navbar navbar-expand-lg navbar-light bg-light ">
        <div className="container">
          <Link className="navbar-brand" to="/"><img className="img-fluid" src="https://www.globsyn.edu.in/wp-content/uploads/2022/12/gbs-re-up-logo-1.png" alt="" width="250" /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">

              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle con nav-hover text-secondary nav-all-test" to="/about-gbs"  >
                  ABOUT GBS
                </Link>
                <ul className="dropdown-menu">
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/history">HISTORY</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link nav-hover text-secondary dropdown-toggle nav-all-test" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">EDIFICIE</Link>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/innovation">INNOVATION</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/research">RESEARCH</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/technology">TECHNOLOGY</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/gbs-recognitions">RANKING AND RECONAGNITION</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link nav-hover text-secondary dropdown-toggle nav-all-test" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">LEADERSHIP</Link>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/board-of-trustees">BOARD OF TRUSTEES</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/message-from-managing-trustee">MANAGING TRUSTEE MESSAGE</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/directors-message">DIRECTORS MESSAGE</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link nav-hover text-secondary dropdown-toggle nav-all-test" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">GOVERNANCE</Link>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/governing-council">GOVERNING COUNCIL</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/academic-council">ACADEMIC COUNCIL</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link nav-hover text-secondary dropdown-toggle nav-all-test" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">VISIT GBS</Link>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/gbs-campus">CAMPUS</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/admissions-office">ADDMISSION OFFICE</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/media-coverage">MEDIA COVERAGE</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/blog">BLOGS</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="https://www.youtube.com/user/globsynbschool" target="_blank" rel="noreferrer">VIDEOS</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/newsletter">NEWSLETTER</Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle con nav-hover text-secondary nav-all-test" to="/" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  ACADEMICS
                </Link>
                <ul className="dropdown-menu">
                  <li className="nav-item dropend">
                    <Link className="nav-link nav-hover text-secondary dropdown-toggle nav-all-test" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">PROGRAMMES</Link>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/pgdm">PGDM</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/mba">MBA</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/pgdm-business-analytics">PGDM-BUSINESS ANALYTICS</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/custom-study-path">CUSTOM STUDY PATH</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link nav-hover text-secondary dropdown-toggle nav-all-test" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">SCHOOL OF EXCELANCE</Link>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/globsyn-finance-specialization">FINANCE</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/globsyn-marketing-specialization">MARKETING</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/globsyn-hr-specialization">HUMAN RESOURCES</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/globsyn-information-system-it-specialization">INFORMATION SYSTEM</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/globsyn-operations-management">OPERATION MANEGMENT</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/management-development-program">MANEGMENT DEVELOPMENT PROGRAMME</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link nav-hover text-secondary dropdown-toggle nav-all-test" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">EXPERIMENTAL LEARINING</Link>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/corporate-connect">CORPORATE CONNECT</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link nav-hover text-secondary dropdown-toggle nav-all-test" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">BEYOND EDUCATION</Link>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/kalyani-youth-leadership-forum">KLYF</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/gbs-embryon">EMBRYON</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/serendipity">SERENDIPITY</Link></li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle con nav-hover text-secondary nav-all-test" to="/" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  FACULTY & STAFF
                </Link>
                <ul className="dropdown-menu">
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/teaching-resource">TEACHING RESOURCE</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link nav-hover text-secondary dropdown-toggle nav-all-test" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">ACADEMIC BOARDS</Link>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/governing-council">GOVERNING COUNCIL</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/academic-council">ACADEMIC COUNCIL</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/administrative-departments">ADMINISTRATIVE DEPARTMENT</Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle con nav-hover text-secondary nav-all-test" to="/" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  RESEARCH CELL
                </Link>
                <ul className="dropdown-menu">
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/faculty-publications">FACULTY PUBLICATION</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/globsyn-management-conference">GLOBSYN MANEGMENT CONFARANCE</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/case-study">CASE STUDY</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/knowledge-cell">KNOWLEDGE CELL</Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle con nav-hover text-secondary nav-all-test" to="/" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  STUDENTS
                </Link>
                <ul className="dropdown-menu">
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/campus-facilities">CAMPUS FACILITIES</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/awards">AWARDS</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link nav-hover text-secondary dropdown-toggle nav-all-test" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">STUDENT ACTIVITIES</Link>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/kalyani-youth-leadership-forum">KLYF</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/gbs-embryon">EMBRYON</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/serendipity">SERENDIPITY</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/student-development-program">STUDENTS DEVELOPMENT PROGRAMME</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/globsyn-apprenticeship-program">GLOBSYN APPRENTICESHIP PROGRAMME</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/national-scholarship-program">NATIONAL SCHOLARSHIP PROGRAMME</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/financial-aid">FINANCIAL AID</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="#">CERTIFICATE VERIFICATION</Link>
                  </li>
                  <li className="nav-item dropend">
                    <Link className="nav-link nav-hover text-secondary dropdown-toggle nav-all-test" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">ALUMNI</Link>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/globsyn-alumni-engage">GLOBSYN ALUMNI ENGAGE</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/globsyn-alumni-academic-programme-gaap">GLOBSYN ALUMNI ACADEMIC PROGRAMME</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/globsyn-lifelong-career-support">GLOBSYN LIFELONG CAREER SUPPORT</Link></li>
                      <li><Link className="dropdown-item nav-hover nav-all-test"
                        to="/alumni-my-story">ALUMNI - MY STORY</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item dropend">
                    <a className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" href="http://eglobsyn.org/" target='_blank' rel="noreferrer">E-GLOBSYN</a>
                  </li>
                  <li className="nav-item dropend">
                    <a className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" href="https://globsyn.campusmall.in/" target='_blank' rel="noreferrer">MARCHENDISE</a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link active nav-hover text-secondary dropdown-toggle nav-all-test" aria-current="page" to="/placement">PLACEMENT</Link>
              </li>



            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
