import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function SipAtKelloogs() {
    return (
        <><title>Summer Internship Program at Kellogg’s</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Summer Internship Program at Kellogg’s</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Summer Internship Program at Kellogg’s</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/Summer-Internship-Program-at-Kelloggs.png" alt="" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>My name is Shagufta Farheen and I’m pursuing PGDM from Globsyn Business School in Kolkata. It has been more than 5 weeks now that I am pursuing my summer internship at Kellogg’s and yet I feel that I started just yesterday! The journey has been rewarding beyond my imagination. It has been an awesome learning experience so far and I would like to take this opportunity and platform to share some of my experiences with you.</p>
                                <p><h3>The Selection</h3></p>
                                <p>I feel lucky to be selected for internship at Kellogg’s as there is much demand for getting placed in this highly esteemed international giant. I remember feeling very excited to be able to procure this offer and could not wait to start my journey with Kellogg’s so that I could work and learn from the industry veterans and experts.</p>
                                <p><h3>The Beginning</h3></p>
                                <p>It was at the end of April that many of us from Globsyn B-School have started our internships at various organizations like Aditya Birla Capital, HDFC Bank, etc., and for me it was Kellogg’s. My first task began with visits to various stores of modern day trade in Kolkata, primarily Big Bazaar and Spencer’s, where Kellogg’s sells its products. I had to visit these stores and check for stock availability, stock presentation in the stores and whether there is any requirement of new stock.</p>
                                <p><h3>Work at Stores</h3></p>
                                <p>My task was to visit the stores and check the planogram of the products of Kellogg’s, and try to increasing facing of fast-selling and/or premium products. During my internship, I have learned that checking the planogram includes many sub-tasks, such as:</p>
                                <ol style={{ listStyle: "decimal;" }}>
                                    <li>Checking whether the innovation products are arranged in <strong>Diamond Shape</strong> and at eye-level. The customers for such products mostly comprise of women, and hence it is necessary that the products are placed at such a height that it becomes easy for them to notice.</li>
                                    <li>Ensuring whether the products are placed in a <strong>Descending Order</strong>, meaning that the higher quantity products are at the top of the rack and lowest quantity of the same product is placed lowest on the rack. This arrangement encourages people to buy the higher quantity products.</li>
                                    <li>Ensuring that the products are located in the <strong>Power Aisle</strong> – the place where customers are bound to go, places like the area around the cash counter, near the entrance, etc. This ensures that every customer notices the products and the more they notice, they more they are willing to buy.</li>
                                    <li>Seeing to it that the products have the <strong>Right Adjacency</strong>. For example, breakfast cereals need to be placed near Malted Food Drinks (MFD), or near the placement of milk, snacks, chips, etc. This way people notice the alternatives or add-ons to the products they are buying and consider buying our products.</li>
                                    <li>Convincing the Store Manager to <strong>allot More Facings</strong> to products that are fast selling and are in-demand and it was my responsibility to explain to him how to reach target sales figures. I will try to give you an easy example, if a product is high priced and is selling fast, we can give more facing to it as it will boost sales at much higher rate compared to what lower priced options would have resulted in.</li>
                                </ol>
<p><h3>Other work</h3></p>
                                <p>Recently, I have been tasked with profiling the Sales Report while working with other documents such as the Product Orders of the company for sales in all of eastern India. This is my first industry experience and I learnt how product orders are recorded and matched in the sales report with various other documents. This type of work mostly dealt with working in spreadsheets and using various formulae that I learnt in college, like making pivot tables, using V-lookups, etc. At times, I had to take help from my supervisor for this project to get things done the right way. There had been some technical difficulties as well, but I could resolve them eventually. Nevertheless, this part of the project has been really fun!</p>
                                <p><h3>Onwards… into the future!</h3></p>
                                <p>With another month or so remaining in the internship, I feel I am already in the right place! My seniors and supervisors who have guided me so far are amazing people to work with. They have shown what it means to handle a large and reputable company like Kellogg’s from the ground level and how things work in big MNCs.</p>
                                <p>I would like to extend heartfelt gratitude to my college Globsyn Business School for providing me the right platform and guidance that made it possible to land an internship offer with an international cereal legend like Kellogg’s. With such constant guidance and support, who knows I may land up with a permanent placement offer with them as well! On a serious note, my summer internship at Kellogg’s is my first full-fledged industry experience and I must say, it has lived up beyond my expectations!</p>
                                <br />
                                <p><strong>Shagufta Farheen</strong></p>
                                <p style={{ fontSize: "8pt" }}>PGDM Batch – 08, <Link to="/pgdm">Globsyn Business School</Link></p>
<br/>
<p>If you have enjoyed reading this blog then you will like reading Globsyn Business School’s (PGDM Batch – 8) student, Budhaditya Mondal’s blog: <Link to="/my-sip-experience-at-asian-paints">My SIP Experience at Asian Paints</Link></p>

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
