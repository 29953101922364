import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ImportanceOfResearch() {
    return (
        <><title>Importance of Research in Management Education</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Importance of Research in Management Education</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Importance of Research in Management Education</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Importance-of-Research-in-Management-Education.png" alt="Importance of Research in Management" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Dr. Tanusree Chakraborty teaches Economics and Statistics at Globsyn Business School – top B Schools in India. Dr. Chakraborty has completed her Masters and Ph.D. in Economics from the esteemed Calcutta University. With over 15 years of experience in teaching and research, she has led several learning sessions for aspirants, in the field of Economics, Operations Research, Research Methodology, Statistics and Gender & Development. Dr. Chakraborty’s repertoire of work includes a number of published books and journals, wherein she has extensively contributed her knowledge about women’s economic empowerment in the present day. She has also been actively involved in events, seminars and conference proceedings of international repute.
                                    </em>
                                </p>

                                <p>Research in simple words refers to a quest for knowledge. In other words, research is a perfect blend of a scientific and systematic search for relevant data on a specific issue. In economics three words play the most pivotal role to understand and to provide any probable solution to any problem. These three words are “WHAT”, “HOW”, and “WHOM”. Similarly in research we are trying to address these three words. “What” is the problem… in other words, we define the objectives of the research. The second most important word is “How” to do the research. Here we are identifying Research Methodology. Research methodology is the technique in which research problems are solved systematically.</p>
                                <p>In this process, the researcher familiarizes himself/herself with the various steps generally adopted to study a research problem, along with the underlying logic behind them. It helps them to develop articulated thinking or a ‘bent of mind’ to objectively spot the field; Research is a fine bending of “knowing” and “doing” which inculcates the capability to evaluate and develop the research findings with confidence. This confidence helps researchers to make rational decisions.</p>
                                <p>In Globsyn Business School, we have Globsyn Research Cell, which tries to nurture research culture in the institute by fostering research-driven activities on emerging trends, keeping in view interdisciplinary and multidisciplinary facets. The basic objective of Globsyn Research Cell is to generate cognizance and opportunities in research-driven activities among the students and faculty members. The third vertical of Globsyn Research Cell consists of Globsyn Management Conference (GMC) and Globsyn Management Journal. GMC is a discussion forum for the academia and the industry, where relevant and theme-based topics related to the industry or society are critically evaluated and debated, research papers related to the theme are presented by students, Faculty and industry and the proceedings of the same are published in ISBN ranked Conference Proceedings.</p>
                                <p>The Globsyn Management Journal (GMJ), is enlisted in databases of EBSCO, ProQuest and OCLC,ICI, J-Gate and peer-reviewed journal published annually by Globsyn Business School where research papers with advanced empirical and theoretical understanding are published and circulated to more than 100 B-schools and institutes across the country. In Globsyn Management Journal, students get the opportunity to publish their research papers.</p>
                                <p>At Globsyn Business School we follow this strategy of holistic development of a student. Research opportunity in Globsyn Business School paves the way to explore how this may contribute to enhancing the field of management development thinking and practice. According to the research culture in Globsyn Business School will help management students to realize their true potential, and generate long-term tangible and intangible benefits.</p>
                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Tanusree-Chakraborty_jpg121820.png"
                                            alt="Dr. Tanusree Chakraborty"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Dr. Tanusree Chakraborty
                                            <br />
                                            Faculty – Economics and Statistics
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
