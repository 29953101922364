import _ from "lodash"

export default function appUtils(page, totalPage, limit, siblings) {

    let totalPageNoInarray = 7 + siblings;
    if (totalPageNoInarray >= totalPage) {
        return _.range(1, totalPage + 1);
    }
    let leftSiblingsIndex = Math.max(page - siblings, 1);
    let rightSiblingsIndex = Math.min (page + siblings, totalPage);

    let showLeftDots = leftSiblingsIndex > 2;
    let showRighttDots = rightSiblingsIndex < totalPage-2;

    if(!showLeftDots && showRighttDots){
        let leftItemsCount = 3 +  2 * siblings;
        let leftRange=_.range(1, leftItemsCount + 1);
        return [...leftRange,"...",totalPage];
    }
    else if(showLeftDots && !showRighttDots){
       let rightItemsCount = 3+2 * siblings;
       let rightRange=_.range(totalPage-rightItemsCount+1,totalPage+1);
       return [1,"...",...rightRange];
    }
    else{
        let middleRange=_.range(leftSiblingsIndex,rightSiblingsIndex+1);
        return [1,"...",...middleRange,"...",totalPage];
    }
}
