import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function StudentDevelopment() {
    return (
        <><title>Student Development Program - Globsyn</title>
            <section className="hero-banner-img-media">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Student Development Program</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Student Development Program</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/GBS-GBSO-POST-DESIGN_1200x1200_img-1-1024x741.png" alt="campus" width="678px" height="490px" />
                                </div>
                                <br></br>
                                <p>Student Development Program (SDP), a unique endeavour of Globsyn Research Cell, is offered to every student joining Globsyn Business School.</p>
                                <p>These young managers will get the opportunity to learn 6 online courses as a part of their GBS pedagogy, beyond the post graduate management curriculum. These courses are – How to Learn Online (45 hours), Digital Marketing (30 hours), Industry 4.0 (36 hours), Performance Management & Competency Mapping (60 hours), Financial Markets & Investments (60 hours) and Product & Brand Management (60 hours) – culminating to a staggering nearly 300 hours of dynamic and pertinent coursework. Apart from these 6 courses, this student development plan will provide students with the privileged access to several other Management, Vocational, and Foreign Language courses that will not only enhance their knowledge bank, but also boost their employability quotient.</p>
                                <p>The students will need to complete these courses within the course of their study at GBS; at the end of which, apart from getting e-certificates for completing each of these online courses, they will also receive a Certificate through Blockchain technology for completing the Student Development Program. The rollout of certificates on Blockchain will allow the students and their prospective employers to access the diploma credentials from any geographical location, without any need to send or present physical certificates. All students completing these 6 online courses will be able to share the credential of their SDP certificates with future employers around the world without any hassle.</p>
                                


                            </div>
                            <div style={{ textAlign: "center" }}>
                                < button class="btn"><a href="https://admissions.globsyn.edu.in/" target='_blank' rel="noreferrer">Interested? Apply Now!</a></button>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
