import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function SerendipityIndustryRelevant() {
    return (
        <><title>Serendipity: A Journey to become ‘Industry Relevant’</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Serendipity: A Journey to become ‘Industry Relevant’</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Serendipity: A Journey to become ‘Industry Relevant’</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/blog-image-3.png" alt="Serendipity" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Serendipity means an occurrence by chance. It is an event of the students, for the students and by the students of Globsyn Business School.</p>
                                <p>Conceptualized as a ‘live project’ for students, ‘Serendipity’ always represents a genuine association with committed groups for finance, marketing, operations, and so forth modeled on reality, who are given the errand of a fruitful planning and organizing a huge occasion beginning from gathering reserve to advertising, overseeing activities, overseeing individuals and connections, and exhibiting their innovative abilities. It has consistently been a student driven program, oversaw wholeheartedly by the students of Globsyn Business School (GBS) who manage the entire fest successfully.</p>
                                <p>The theme for this year’s Serendipity was “A Tribute to the Frontline Warriors”. The students of GBS have paid tribute to the frontline warriors who have unconditionally provided services to keep people safe and healthy and away from disease. Needless to say, doctors, nurses and people working in health-care sector who are particularly vulnerable to the highly infectious disease, continued their jobs in protecting others. In response to the global pandemic, under-resourced doctors are facing unprecedented challenges. The list of the sleep-deprived heroes includes doctors, nurses, medical cleaners, pathologists, paramedics, ambulance drivers, health-care administrators, social workers, amongst others. In the fight against coronavirus, the brave medical army stands strong with thermometers, stethoscopes, and ventilators as their weapons. Not to forget, medical researchers have been working day in and night out against all odds, hoping to find the antidote to the disease. Since the COVID-19 outbreak, healthcare professionals have not only experienced the gratification of healing patients and saving their lives but have also lost many battles along the way. On top of that, many doctors have even sacrificed their own lives in the line of duty.</p>
                                <p>While on the one hand, people across India were largely confined to their homes, educational institutions all shut down in an attempt to contain the virus, and on the other hand, these warriors have been leading the battle against COVID-19 from the front. They truly are our heroes in these challenging times. We must reassess the value these frontline workers hold in our lives and the kind of treatment they get from us. Among the several lessons, this pandemic has taught us to find ways to sufficiently invest in the better and more efficient medical fraternity and give medical professionals the respect, compensation and infrastructure that they truly deserve.</p>
                                <p>While they are putting their own families, health and importantly their own lives at risk, the least we can do is appreciate their efforts and honor their struggle. Through Serendipity 2021, we, the students of GBS have portrayed the journey of these frontline warriors through their dazzling performances. Like every year, this year too, students have represented various art forms to showcase appreciation towards their parents and teachers, who imbibe values within them.</p>
                                <p>Through various philanthropic activities, we have also raised a fund from the proceeds of the Serendipity 2021 for Kalyani Ananda Utsav – a flagship event of Kalyani Youth Leadership Forum – to help the needy and economically weaker sections of the society.</p>
                                <p>
                                    <b>Sreya Dutta</b>
                                    <br />Batch 2020-22<br />
                                    Globsyn Business School
                                </p>


                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

