import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function UnderstandingHappiness() {
    return (
        <><title>Understanding Happiness</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Understanding Happiness</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Understanding Happiness</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Understanding-Happiness.png" alt="Understanding-Happiness" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Dr. Debaprasad Chattopadhyay, teaches Human Resource at Globsyn Business School – one of the top MBA colleges in Kolkata. Dr. Chattopadhyay has over 48 years of academic and corporate experience with very successful stints in reputed national and international organizations. In addition to being an academician, Dr. Chattopadhyay is a life member of National HRD Network and ISTD and a member of ISABS, NIPM, BMA, and CMA. He is a life member of his alma mater, Don Bosco School Park Circus Alumni Association. Dr. Chattopadhyay completed a program on Strategic Human Resource Leadership from National HRD Network and has also attended Human Laboratory Process Course from Indian Society of Applied Behavioral Sciences. Dr. Chattopadhyay has conducted Management Development Programs and Consultancy Assignments across organizations and has to his credit several publications in various journals and presented technical papers at different national and international conferences. Recently, he has received the Udai Pareek Memorial Distinguished AHRD Alumni Award for the year 2022, bestowed on him by his alma mater Academy of Human Resource Development (AHRD), Ahmedabad.
                                    </em>
                                </p>
                                <p>Happiness, as we all know, is a state of mind. Roller-Coaster swings of moods are inevitable ranging from being chirpy on one-hand and being crest-fallen on the other. Having said this, what is then the ground principle of being happy? Swami Sarvapriyanandaji Maharaj, in-charge of Ramakrishna Mission, New York Centre of Vedanta has a simple formula which he once shared while addressing IIT Students, who virtue of their brilliance in Mathematics, always put on a “Numeracy Spectacle” to whatever they are subjected to, before they can convince themselves about the utility of any hypothetical proposition.</p>
                                <p>As Maharaj puts it, H=P*E*M. In this equation, ‘H’ stands for Happiness, ‘P’ stands for Pleasure, ‘E’ stands for Engagement, and ‘M’ stands for Meaningfulness. Maharaj further says that an avid cricket-lover would derive happiness if he/she watches the game cricket being played because that provides him/her pleasure. However, this is only one part of being happy. He/she would be further happy if he/she engages himself/herself in the act of actually playing cricket. Nevertheless, his/her happiness will assume comprehensive proportions if he/she plays cricket for an altruistic cause, by way of meaningfully contributing the proceeds/accruals of the game cricket to a productive cause, for e.g., selflessly donating the revenue generated from the game cricket to serve the underprivileged members of the society. It is then and only then, that his/her happiness level will be wholesome instead of being piece-meal. Foregoing therefore elucidates the comprehensive nature of total happiness. Truly, an ideal food for thought and something of worth to ponder upon. The concept is not limited only to the confines of athletics. It permeates into every walk of life. Here is how it works during one’s bereavement. Life is uncertain and unpredictable. We do not know what will happen in the next moment. An impending catastrophe snatches away happiness in entirety no matter how short or long it prevails.</p>
                                <p>Still, in such a situation, Maharaj’s formula stands. A deeply aggrieved person, following a grave crisis, will seek avenues to give vent to pent-up emotions. This will necessitate identifying a prominent source likely to usher in ‘happiness’. One such outlet could be social interactions with friends, close relatives, which can bring in pleasure no matter how insignificant it might be. However, this needs to be augmented with the aggrieved person’s getting immersed in social activities voluntarily, which will provide engagement. Eventually, the extent of happiness can be intensified if some amount of yeoman’s service can be brought into the situation. This can take the form of the aggrieved person hosting a spiritual event for the masses, for e.g., a counseling or meditation session for all like-minded people who can allay their worries through such remedial events, thereby exploring the ‘meaningfulness’ component. Thus the ‘happiness’ equation stands valid irrespective of contingencies.</p>
                                <p>Happiness, therefore, has to be experienced both internally and externally. Internally, partly through ‘pleasure’ and ‘engagement’, while externally partly through ‘engagement’ and ‘meaningfulness’.</p>

                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Dr.-Debaprasad-Chattopadhyay.jpg"
                                            alt="Manas"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Dr. Debaprasad Chattopadhyay
                                            <br />
                                            Faculty – HR
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
