import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function FifthVisionSeminar() {
  return (
    <><title>5th International Vision Seminar: Sustainability in the Age of Disruption</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">5th International Vision Seminar: Sustainability in the Age of Disruption</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog">Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="">5th International Vision Seminar: Sustainability in the Age of Disruption</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>
                    <div className="one-table">

                        <p>
                            <img className="img-fluid" src="/assets/images/website-blog-image-1.png" alt="5th International Vision Seminar" />
                        </p>
                        <div className="entry-header">
                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>Globsyn Business School conceptualized the notion of the International Vision Seminar in the year 2017. Hitherto, four such Vision Seminars have been organized and it is slated to have the 5th International Vision Seminar on February 26 & 27, 2021. As it stands, the scheme of such an event is preceded by a Pre-Vision Seminar Workshop followed by the two days Vision Seminar. The Pre-Vision Seminar Workshop will provide a hands-on indoctrination to the participants-cum-attendees.</p>
                        <p>Customary to practice, a contemporary topic is chosen as the Vision Workshop theme. The event brings together a galaxy of speakers who are cherry-picked icons in their respective fields. Such pre-eminent luminaries will deliberate on a plethora of topics and sub-topics which transcends from academia to industry. The beneficiaries include practicing managers, executives, working professionals from across a diverse range of industries, academicians comprising of faculty members, researchers and students. Thought-leaders from inland and industries will be together not only to ‘disseminate knowledge’ but also to ‘create knowledge’. This process results in a confluence of ‘Adaptive Learning’ and ‘Generative Learning’. All the ‘information’ which gets converted into ‘knowledge’, eventually culled out as ‘wisdom’ are captured and manifested in the form of Vision Seminar Proceedings. Being a form of seminal ideas in an armamentarium of the archival repository, the Proceedings will be used eventually as guideposts for future research and effective work practices.</p>
                        <p>Pursuant to the foregoing, in 2017, the Vision Seminar was titled as “Spirituality in Management Education”, in 2018 as “Women Empowerment”, in 2019 as “Corporate Ethics and Responsible Leadership”, in 2020 as “Technology in the Age of Industry 4.0”. On February 26 & 27, 2021, the 5th Vision Seminar is coming up with the topic “Sustainability in the Age of Disruption” along with the Pre-Vision Seminar Workshop on February 25, named “The Role of Ethics and Spirituality for Business Sustainability in the Age of Disruption”, conducted by International corporate trainer. The focus of this Vision Seminar is aptly segregated into four parts, namely, ‘Human Sustainability in the Age of Disruption’, ‘Social Sustainability in the Age of Disruption’, ‘Economic Sustainability in the Age of Disruption’ and ‘Environmental Sustainability in the Age of Disruption’.</p>
                        <p>Vision Seminar in Globsyn Business School has therefore come to stay as an Annual Signature Event of the Institute with its defining purpose of sensitizing the Society. Hence, the care, compassion, and unconditioned love in the first Vision Seminar was steadily moved to Emancipation and Empowerment in Women in the second Vision Seminar, with Rectitude and Sensitive Leadership in the third Vision Seminar, Paradigm Change in Industry 4.0 with Technology in the driver’s seat in the fourth Vision Seminar, all conducted successfully in physical mode. With literally ‘earth under repair’ since the COVID-19 induced Pandemic broke out from March, 2020, the forthcoming Vision Seminar is thus planned in digital mode to adeptly identify the New Normal in the path ahead with ensuing times and advocating prophecies for life and livelihood. Twenty Five erudite speakers this year, from across the globe will deliberate, discuss and debate on issues related to sustainability in the age of disruption, creating an international level knowledge society and taking knowledge of leadership to the next level.</p>

                        <p>
                            <b>Prof. (Dr.) Debaprasad Chattopadhyay</b>
                            <br />HOD & Faculty – Human Resource<br />
                            Globsyn Business School
                        </p>


                    </div>

                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>  
  )
}
