import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

export default function BrandSlider() {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        arrows: false,
        cssEase: "linear" ,
        responsive: [{
 
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              infinite: false
            }
       
          }, {
       
            breakpoint: 600,
            settings: {
              slidesToShow:1,
              dots: false
            }
       
          }, {
       
            breakpoint: 300,
            settings:{ // destroys slick
            slidesToShow: 1,
            dots: false
            }
          }]           
    };
    return (
        <Slider {...settings}>
            <div>
                <img className="lswssp-logo-img" src="https://www.globsyn.edu.in/wp-content/uploads/2022/10/KPMG-copy-1.jpg" alt="Deloitte_Logo-copy-2" />
            </div>
            <div>
                <img className="lswssp-logo-img" src="https://www.globsyn.edu.in/wp-content/uploads/2022/10/Ernst-Young-copy-1.jpg" alt="Deloitte_Logo-copy-2" />
            </div>
            <div>
                <img className="lswssp-logo-img" src="https://www.globsyn.edu.in/wp-content/uploads/2022/10/Asian-Paints-Logo-copy1.jpg" alt="Deloitte_Logo-copy-2" />
            </div>
            <div>
                <img className="lswssp-logo-img" src="https://www.globsyn.edu.in/wp-content/uploads/2022/10/Ferrero-Rocher-copy-2.jpg" alt="Deloitte_Logo-copy-2" />
            </div>
            <div>
                <img className="lswssp-logo-img" src="https://www.globsyn.edu.in/wp-content/uploads/2022/10/Pepsico_logo-copy-1.jpg" alt="Deloitte_Logo-copy-2" />
            </div>
            <div>
                <img className="lswssp-logo-img" src="https://www.globsyn.edu.in/wp-content/uploads/2022/10/Vodafone-Idea-Limited-copy-1.jpg" alt="Deloitte_Logo-copy-2" />
            </div>
        </Slider>
    )
}
