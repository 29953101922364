import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function RisingDemandEntrepreneurship() {
  return (
    <><title>Rising Demand of Entrepreneurship in the Post Pandemic World</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Rising Demand of Entrepreneurship in the Post Pandemic World</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Rising Demand of Entrepreneurship in the Post Pandemic World</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/Rising-Demand-of-Entrepreneurship-in-the-Post-Pandemic-World.png" alt="Rising-Demand-of-Entrepreneurship" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>The COVID-19 pandemic has transformed the global work culture. Once greatly reliant on employment, workers have now shifted their focus to adopt business ventures- big or small, to counter the influx of economic and employment instability. </p>
                                <p><b>Upsurge in Start-Up Firms</b></p>
                                <p>The emergence of the COVID-19 pandemic has triggered an upsurge in the number of start-up firms in comparison to previous years. The sudden rise in this entrepreneurial attitude can be attributed to the mass layoffs that were initiated by the companies. The employees who were let go started their own businesses which consequently resulted in a surge in entrepreneurship. </p>
                                <p>Reports provided by the Financial Times during 2020, based on official statistics, reflect the growth of entrepreneurship figures against the backdrop of the COVID-19 pandemic. </p>
                                <ul className='disc'>
                                    <li>According to an estimate provided by the Census Bureau in July 2020, in the United States, the total number of applications for commencing a business was at an all-time high of 55165700. The percentage of applications rose by 95% in comparison to 2019. </li>
                                    <li>In a report by McKinsey, around 84,000 emerging businesses were recorded during October 2020. The percentage of startups increased by 20% in comparison to the same month of 2019.</li>
                                    <li>Likewise, in September 2020, 10,000 new businesses were registered in Japan. The sector saw a growth of 14% since the same period of 2019.</li>
                                    <li>Estimates provided by the National Statistical Office reflect that the United Kingdom recorded a 30 percent increase in the months of November and December 2020 compared to that of the previous year. </li>
                                </ul>
                                <p><b>Start-Ups mainly in the Technology and Technology Enabled Sector </b></p>
                                <p>Based on an estimate, it has been observed that in 2020 the total number of start-ups in the Information Technology sector amplified by around 20 percent compared to 2019. Evan Luthra, CEO, Startup Studio Online, shared his insight that a significant number of start-ups cropped up in the diverse technology sectors- telecommunications, decentralized technological sectors, and biomedicine. Similarly, the real estate industry spotted increasing entrepreneurship because of the surge in digitalization. Digitalization has also promoted the growth of start-up firms in the retail and warehouse industries. Information Technology (IT) enabled industries providing counseling and training services also reported similar growth, by an estimated 50 percent. </p>
                                <p><b>Rising Demand for Entrepreneurship among youngsters </b></p>
                                <p>Surprisingly, data collected from a survey reflects that around 62 percent of Gen Z and 50 percent of Millennials have an inclination towards starting businesses. Saniyah Henderson, a 15-year-old resident of Ohio, developed a business plan during the pandemic and started a beauty store in October 2020.  In a similar case, a college student contributed to commencing a tie-dyed clothing firm, ‘Tied by Lien’, during the pandemic. Six-year-old Nicholas Bubeck initiated a business based on the building of toy airplanes. </p>
                                <p>The rising demand for entrepreneurship amongst youngsters can be credited to the following factors:</p>
                                <ul className='disc'>
                                    <li>The emergence of economic uncertainty coupled with lay-offs in diverse industries </li>
                                    <li>Growth of electronic commerce, diverse consumer product categories, and the growing involvement of medium and small-scale firms </li>
                                    <li>Online learning, that has allowed the students to meet their learning objectives from the convenience of their homes in a flexible manner </li>
                                </ul>
                                <p><b>Entrepreneurship and B-Schools </b></p>
                                <p>Presently, the B-Schools based in India and abroad are focused on producing management graduates with entrepreneurial mindsets. Consequently, they are nurturing incubators and start-up cells to help students in acquiring the seed funding required for starting new business ventures. The alumni and placement cells of these institutions are also encouraging former students in starting their own business ventures. </p>
                                <p>Globsyn Business School (GBS) located in Kolkata is one such B-School that renders focuses on supporting budding entrepreneurs. Over the last two decades, GBS has uniquely positioned itself as India’s only ‘Corporate B-School’. The Entrepreneurship Cell of GBS, Embryon, aims to provide a  platform wherein students can effectively showcase their business ideas and plans before a panel of industry veterans and other academicians coming from different global regions. Recently ‘Embryon’- the entrepreneurship cell of GBS has been recognized among 24 business schools, globally, as an AACSB international 2021 Innovation That Inspires. This annual program recognizes institutions from around the world that serve as champions of change in the business education landscape. </p>
                                <p>The demands for time and financial freedom along with a motive to survive in such uncertain times have prompted individuals to undertake entrepreneurial ventures in the post-COVID-19 world. Thus, B-Schools are required to rightly respond and design their pedagogies to help produce quality personnel who can demonstrate their business acumen thereby helping in reviving the affected economies.</p>
                                
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
