import React from 'react'
import Sidebar from '../../Sidebar'
import { Link } from 'react-router-dom'

export default function AlumniAcademic() {
    return (
        <><title>Globsyn Alumni Academic Programme (GAAP) - Globsyn</title>
            <section className="hero-banner-img-sceren">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Globsyn Alumni Academic Programme (GAAP)</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Globsyn Alumni Academic Programme (GAAP)</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/GAAP_2-1024x682.png" alt="campus" width="678px" height="490px" />
                                </div>
                                <br />
                                <br />
                                <p>With an objective to bridge the gap between the industry and academia for a life-long learning experience, the Globsyn Alumni Academic Programme (GAAP) offers all alumni of the B-School the opportunity to attend any one paper from the Core/Elective subjects of PGDM course. The alumni attending the course are not going to be charged any course fees, and the entire Programme is going to be complimentary. On completion of the course, the alumni will be awarded with a completion certificate.</p>
                                <div className="accordion" id="myAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Guidelines</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <ul className='disc'>
                                                    <li>Alumni registering for the Programme must attend the classes as per the schedule.</li>
                                                    <li>The classes will be held alongside the present Batch of 2021-23.</li>
                                                    <li>The alumni registered for the Programme must have minimum 70% attendance in the class to qualify for the exams.</li>
                                                    <li>The alumni must pass the exams with 50% marks to get the certificate.</li>
                                                    <li>The mode of learning is going to be virtual mode for the time being, and later may be transformed to physical classroom learning mode at Globsyn Knowledge Cell Campus, Amtala, depending upon the pandemic situation and Government guidelines.</li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingtwo">
                                            <button type="button" className="accordion-button collapsed " data-bs-toggle="collapse" data-bs-target="#collapsetwo">Subjects – Core Courses</button>
                                        </h2>
                                        <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <strong>Semester I (August 31, 2021 – December 10, 2021)</strong>
                                                <br />
                                                <br />
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <table style={{ width: "65%", border: "solid 1px #cccccc" }} className="table table-striped table-hover">
                                                        <tbody>
                                                            <tr style={{ backgroundColor: "#d9edf7", }}>
                                                                <th style={{ textAlign: "left" }}>Sl. No.</th>
                                                                <th style={{ textAlign: "left" }}>Courses</th>
                                                            </tr>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>Financial & Management Accounting</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>Managerial Economics</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>Quantitative Methods (Introductory)</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>Legal Aspects of Business</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>Management Functions and Organizational Behaviour</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>Marketing Management (Introductory)</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7</td>
                                                                <td>Business Communication</td>
                                                            </tr>
                                                            <tr>
                                                                <td>8</td>
                                                                <td>Professional Skills Development (Introductory)</td>
                                                            </tr>
                                                            <tr>
                                                                <td>9</td>
                                                                <td>Computer Applications (Introductory)</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <strong>Semester II (December 27, 2021 – April 27, 2022)</strong>
                                                <br />
                                                <br />
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <table style={{ width: "65%", border: "solid 1px #cccccc" }} className="table table-striped table-hover">
                                                        <tbody>
                                                            <tr style={{ backgroundColor: "#d9edf7", }}>
                                                                <th style={{ textAlign: "left" }}>Sl. No.</th>
                                                                <th style={{ textAlign: "left" }}>Courses</th>
                                                            </tr>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>Financial Management and Corporate Finance</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>Managerial Economics & Global Business Environment</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>Marketing Management (Intermediate)</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>Human Resource Management</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>Business Process Automation and Advanced Data Analytics</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>Business Research Methods & Applications</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7</td>
                                                                <td>Quantitative Methods (Intermediate)</td>
                                                            </tr>
                                                            <tr>
                                                                <td>8</td>
                                                                <td>Global Business Strategy</td>
                                                            </tr>
                                                            <tr>
                                                                <td>9</td>
                                                                <td>Operations Management</td>
                                                            </tr>
                                                            <tr>
                                                                <td>10</td>
                                                                <td>Professional Skills Development (Intermediate)</td>
                                                            </tr>
                                                            <tr>
                                                                <td>11</td>
                                                                <td>Computer Applications (Intermediate)</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <strong>Semester III (July 30, 2021 – December 3, 2021)</strong>
                                                <br />
                                                <br />
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <table style={{ width: "65%", border: "solid 1px #cccccc" }} className="table table-striped table-hover">
                                                        <tbody>
                                                            <tr style={{ backgroundColor: "#d9edf7", }}>
                                                                <th style={{ textAlign: "left" }}>Sl. No.</th>
                                                                <th style={{ textAlign: "left" }}>Courses</th>
                                                            </tr>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>Sales Management</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>Supply Chain Management</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>Change Management</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>Professional Skills Development (Advance)</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>Computer Applications (Advance)</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <strong>Semester IV (December 27, 2021 – April 15, 2022)</strong>
                                                <br />
                                                <br />
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <table style={{ width: "65%", border: "solid 1px #cccccc" }} className="table table-striped table-hover">
                                                        <tbody>
                                                            <tr style={{ backgroundColor: "#d9edf7", }}>
                                                                <th style={{ textAlign: "left" }}>Sl. No.</th>
                                                                <th style={{ textAlign: "left" }}>Courses</th>
                                                            </tr>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>Project Management</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>Quality Management</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>Business Ethics & Corporate Governance</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>Computer Applications (Extensive)</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingthree">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Subjects – Elective Courses</button>
                                        </h2>
                                        <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <br/>
                                                <h4>Semester III (July 30, 2021 – December 3, 2021)</h4>
                                                <strong>Finance School of Excellence</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingfour">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefour">Registration Form</button>
                                        </h2>
                                        <div id="collapsefour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingfive">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefive">FAQs</button>
                                        </h2>
                                        <div id="collapsefive" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p><strong>1. How do I register for this course?</strong><br />
                                                    A form will be circulated by the CRP Team of Globsyn Business School. The alumni need to fill up the form mentioning the necessary data. Once the alumni have registered, the forms will be circulated.</p>
                                                <p><strong>2. What is the cost for the course?</strong><br />
                                                    The course is free of cost for our alumni of Globsyn Business School.</p>
                                                <p><strong>3. What kind of resources will I need for this class?</strong><br/>
                                                    All the necessary study materials will be provided to the alumni registering themselves for the course.</p>
                                                <p><strong>4. Where will the classes take place?</strong><br />
                                                    The classes will be held over Zoom Meetings, alongside the present batch of students. The link for the classes will be sent to the alumni before the commencement of the respective classes. Classes can be transformed from virtual to physical mode of learning, as per Government protocols.</p>
                                                <p><strong>5. Do I need to give an exam to get the course completion certificate?</strong><br />
                                                    Yes, you have to clear the exam to get the completion certificate for the course.</p>
                                                <p><strong>6. Where will I get my certificate?</strong><br />
                                                    Certificate will be issued by Globsyn Business School</p>
                                                <p><strong>7. How will the examination be conducted?</strong><br />
                                                    The examination will be conducted in the virtual mode for the time being, and later may be transformed to physical classroom learning mode at Globsyn Knowledge Cell Campus, Amtala, as per Government guidelines.</p>
                                                <p><strong>8. Will I get any placement support after completing this course?</strong><br />
                                                    Yes, you will get 100% support with Team CRP’s GBS Lateral Placement Program.</p>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
