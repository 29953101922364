const users = [




{"id":'132', "thumb": '/assets/images/Understanding-Happiness.png', "btitle": 'Understanding Happiness',"url":'/blog/understanding-happiness',"des":'Happiness decoded: Swami Sarvapriyanandaji’s formula—H=P*E*M. Pleasure, engagement, and meaningfulness intertwine for holistic well-being, applicable even in adversity, offering food...',"pdate":'Aug 18, 2023' },                          
{"id":'131', "thumb": '/assets/images/Management-as-Liberal-Art.png', "btitle": 'Management as Liberal Art',"url":'/blog/management-as-liberal-art',"des":'Reviving globally, liberal arts offer interdisciplinary wisdom-focused education, fostering knowledge, self-knowledge, and leadership skills across diverse fields for modern learners...',"pdate":'Aug 10, 2023' },                          
{"id":'130', "thumb": '/assets/images/Extreme-Value-Theory-for-Proactive-Risk-Management.png', "btitle": 'Extreme Value Theory: A Tool for Proactive Risk Management',"url":'/blog/extreme-value-theory-for-proactive-risk-management',"des":'Explore Extreme Value Theory (EVT) – an essential statistical branch for analyzing rare events with significant consequences. Discover its applications in finance, insurance, engineering, and more...',"pdate":'Jul 10, 2023' },                          
{"id":'129', "thumb": '/assets/images/Summer-Internship-Experience-at-Pantaloons.png', "btitle": 'My Summer Internship Experience at Pantaloons',"url":'/blog/summer-internship-experience-at-pantaloons',"des":'Discover the transformative journey of Shourya’s finance internship at Pantaloons, gaining practical experience and professional growth...',"pdate":'Jul 03, 2023' },                          
{"id":'128', "thumb": '/assets/images/Best-Practice-Mantra.png', "btitle": 'Best Practice Mantra',"url":'/blog/best-practice-mantra',"des":'Discover the power of Best Practices in driving progress and making a meaningful difference. Learn the eight-step framework and success stories. Good, better, best – never let it rest!...',"pdate":'Jun 27, 2023' },                          
{"id":'127', "thumb": '/assets/images/Internship-Journey-at-Jubilant-FoodWorks-Ltd.png', "btitle": 'My Internship Journey at Jubilant FoodWorks Ltd: Embracing the Corporate World',"url":'/blog/summer-internship-experience-at-jubilant-foodworks',"des":'Arkadeep Ganguly, a PGPM-IB student at Globsyn Business School, shares his enriching Summer Internship experience at Jubilant FoodWorks. Discover his transformative journey as an intern, i.e., gaining skills...',"pdate":'Jun 20, 2023' },                          
{"id":'126', "thumb": '/assets/images/A-Peek-into-Decentralized-Finance-DeFi.png', "btitle": 'A Peek into Decentralized Finance (DeFi) – The Next-Gen Financial System',"url":'/blog/peek-into-decentralized-finance',"des":'Explore the Future of Finance with DeFi: A Revolutionary Blockchain-Based System. Discover decentralized lending, smart contracts, and global accessibility. Learn more at A Peek into Decentralized Finance (DeFi)...',"pdate":'Jun 14, 2023' },                          
{"id":'125', "thumb": '/assets/images/Summer-Internship-Experience-at-ITC-Dairy.png', "btitle": 'My Summer Internship at ITC Dairy',"url":'/blog/summer-internship-experience-at-itc-dairy',"des":'Krishna Sankar Bhattacharyya, a PGPM-IB student at Globsyn Business School, shares his enriching Summer Internship experience at ITC Dairy, part of ITC Limited. From selection triumphs to on-field ...',"pdate":'Jun 08, 2023' },                          
{"id":'124', "thumb": '/assets/images/Green-Initiatives-–-A-Way-to-Brand-Building.png', "btitle": 'Green Initiatives – A Way to Brand Building',"url":'/blog/green-initiatives-way-to-brand-building',"des":'Discover the transformative power of Green Initiatives in modern marketing. Go green for sustainable brand building. Join the eco-friendly revolution!...',"pdate":'Jun 01, 2023' },                          
{"id":'123', "thumb": '/assets/images/ERM-in-Micro-Small-and-Medium-Enterprises-in-India.png', "btitle": 'ERM in Micro, Small, and Medium Enterprises in India',"url":'/blog/erm-in-msme',"des":'MSMEs in India: Crucial Role, Risk Management, ERM Implementation, Challenges & Globsyn Business School’s Initiative...',"pdate":'May 25, 2023' },                          
{"id":'122', "thumb": '/assets/images/Is-Research-only-Re-search-Decoding-the-Myth-of-Valued-Output.png', "btitle": 'Is Research Only Re-Search? Decoding the Myth of Valued Output',"url":'/blog/is-research-only-re-search',"des":'Discover the essence of research and its importance in today’s world. Globsyn Business School’s research wing prepares students for the corporate world....',"pdate":'May 19, 2023' },                          
{"id":'121', "thumb": '/assets/images/Revisiting-Strategic-Human-Resource-Management.png', "btitle": 'Revisiting Strategic Human Resource Management',"url":'/blog/revisiting-strategic-human-resource-management',"des":'Explore Strategic Human Resource Management and its impact on business goals. Learn how HR functions can align with organizational needs for success....',"pdate":'May 10, 2023' },                          
{"id":'120', "thumb": '/assets/images/Mastering-Mindfulness-for-Success-and-Happiness.png', "btitle": 'Mastering Mindfulness for Success and Happiness',"url":'/blog/mastering-mindfulness-for-success-and-happiness',"des":'Learn about the benefits of mindfulness and spirituality from Harry Poddar and Hermione Ganguli’s imaginary conversation and how they can lead to a fulfilling life in this blog post...',"pdate":'May 03, 2023' },                          
{"id":'119', "thumb": '/assets/images/Learning-Beyond-Education.png', "btitle": 'Importance of Research in Management Education',"url":'/blog/learning-beyond-education',"des":'Discover the importance of research in management education and how Globsyn Business School’s Research Cell fosters a research-driven culture for students and faculty....',"pdate":'Apr 19, 2023' },                          
{"id":'118', "thumb": '/assets/images/Importance-of-Research-in-Management-Education.png', "btitle": 'Importance of Research in Management Education',"url":'/blog/importance-of-research-in-management-education',"des":'Discover the importance of research in management education and how Globsyn Business School’s Research Cell fosters a research-driven culture for students and faculty....',"pdate":'Apr 19, 2023' },                          
{"id":'117', "thumb": '/assets/images/Unicorns-to-Decacorns-–-the-Startup-Story-of-India.png', "btitle": 'Unicorns to Decacorns – The Startup Story of India',"url":'/blog/unicorns-to-decacorns-startup-story-india',"des":'Learn about Unicorns and Decacorns. Know about Embryon – the entrepreneurship cell of Globsyn Business School....',"pdate":'Apr 12, 2023' },                          
{"id":'116', "thumb": '/assets/images/Consumerism-is-a-shame-of-Marketing.jpg', "btitle": 'Consumerism is a shame of Marketing',"url":'/blog/consumerism-is-a-shame-of-marketing',"des":'The consumers of the 21st century have become more critical of the policies and practices of corporate houses in recent years than ever before. All over the world, the consumers are organizing themselves...',"pdate":'Jan 31, 2023' },                          
{"id":'115', "thumb": '/assets/images/Organizational-Culture-and-Climate.jpg', "btitle": 'Organizational Culture and Climate',"url":'/blog/organizational-culture-and-climate',"des":'Culture consists of a set of values and beliefs which helps to reinforce work groups and communities. It applies to individuals, teams and organisations. The more the culture of one is in sync with the culture...',"pdate":'Jan 23, 2023' },                          
{"id":'114', "thumb": '/assets/images/Statistics-The-Grammar-of-Science.jpg', "btitle": 'Statistics: The Grammar of Science',"url":'/blog/statistics-the-grammar-of-science',"des":'Statistics is, basically, the study of data. It is to decision-making what pathology is to medicine; it provides the body of content that may drive a workable choice. The statistician assimilates, organizes, and analyses...',"pdate":'Jan 17, 2023' },                          
{"id":'113', "thumb": '/assets/images/Business-Analytics-Applications-across-all-Verticals-of-Business.jpg', "btitle": 'Business Analytics: Applications across All Verticals of Business',"url":'/blog/business-analytics-applications-across-all-verticals-of-business',"des":'Discover the wide-ranging applications of business analytics. Analyze data, make informed decisions, and optimize performance in finance, HR, and marketing. Learn more now!...',"pdate":'Jan 09, 2023' },                          
{"id":'112', "thumb": '/assets/images/Trust-Must-Triumph-Employment-Blues.jpg', "btitle": 'Trust Must Triumph Employment Blues',"url":'/blog/trust-must-triumph-employment-blues',"des":'When the year 2022 began it was hoped that putting pandemic blues behind, the employment scenario will turn stable. This was however not to be. The vagaries of uncertainty set out to hunt...',"pdate":'Jan 02, 2023' },                          
{"id":'111', "thumb": '/assets/images/Understanding-the-Brand-Battlefield-Website-blog-cover.jpg', "btitle": 'Understanding the Brand Battlefield',"url":'/blog/understanding-the-brand-battlefield',"des":'Brand-fight is never a new phenomenon in the civilization of branding. From the time multiple brands existed in a single category, there have been tensions and palpitations as to who will take over the other...',"pdate":'Dec 26, 2022' },                          
{"id":'110', "thumb": '/assets/images/ummeed-celebration-at-globsyn-business-school.jpg', "btitle": 'Ushering in Festival of Lights with Ummeed 2022',"url":'/blog/ushering-in-festival-of-lights-with-ummeed-2022',"des":'The members of Kalyani Youth Leadership Forum as a part of their ‘Care for Society’ activities celebrated the contribution of the blue collared staff of the B-school. It was celebrated under the Beyond Education vertical of Globsyn Business School...',"pdate":'Dec 20, 2022' },                          
{"id":'109', "thumb": '/assets/images/analyze-your-way-to-success.jpg', "btitle": 'Analyze Your Way to Success',"url":'/blog/analyze-your-way-to-success',"des":'‘Business Analytics’ is the process of using statistical tools and technologies to analyze historical data and gain important insights which eventually aid in strategic decision making....',"pdate":'Dec 14, 2022' },                          
{"id":'108', "thumb": '/assets/images/Blood Donation Camp at Globsyn Business School.jpg', "btitle": 'Blood Donation Camp at Globsyn Business School',"url":'/blog/blood-donation-camp-at-globsyn-business-school',"des":'With the ethos of ‘Care for Society’ firmly embedded in its philosophy, student volunteers of Kalyani Youth Leadership Forum (KYLF), a Beyond Education initiative of Globsyn Business School (GBS), organized...',"pdate":'Oct 07, 2022' },                          
{"id":'107', "thumb": '/assets/images/Independence-Day-Blog_01.png', "btitle": 'Celebrating Independence Day with the Visually Impaired',"url":'/blog/celebrating-independence-day-with-the-visually-impaired',"des":'Globsyn Business School (GBS) celebrated India’s 76th Independence Day with the visually impaired students of Calcutta Blind School. Student volunteers of Kalyani Youth Leadership Forum (KYLF), a Beyond...',"pdate":'Sep 08, 2022' },                       
{"id":'106', "thumb": '/assets/images/collage-1.png', "btitle": 'A Moment to Cherish – Mango Magic 2022',"url":'/blog/a-moment-to-cherish-mango-magic-2022',"des":'The day started with a clear blue sky and bright sunshine, but since it is the Monsoon season, stray rainfall in bits and spurts are a given. However, through the rain and sun, my classmates and I with the support...',"pdate":'Aug 02, 2022' },                       
{"id":'105', "thumb": '/assets/images/Real-life-_-Blog-iamges.png', "btitle": 'Real-life Exposure redefining Educational Norms',"url":'/blog/real-life-exposure-redefining-educational-norms',"des":'Teaching and learning experiences that take place outside the confines of classroom walls have been gaining importance over time. The academic world has required students to put into practice their classroom....',"pdate":'Jul 08, 2022' },                       
{"id":'104', "thumb": '/assets/images/Summer-Internship-Program-and-Its-Necessity.png', "btitle": 'Summer Internship Program and Its Necessity',"url":'/blog/summer-internship-program-and-its-necessity',"des":'Learn how a summer internship program can benefit both students and companies. Discover why they are important, how they can kick-start your career, and give you a competitive edge in the job market....',"pdate":'Jun 22, 2022' },                       
{"id":'103', "thumb": '/assets/images/A-Closer-Look-at-Group-Projects-and-their-Significance-in-Management-Education-Blog-Post_01.jpg', "btitle": 'A Closer Look at Group Projects and their Significance in Management Education',"url":'/blog/a-closer-look-at-group-projects-and-their-significance-in-management-education',"des":'Group Work is the most significant way by which one can learn to collaborate with others. Collaboration is nothing but a group of people being involved and sharing their ideas and skills to achieve a common goal...',"pdate":'Jun 15, 2022' },                       
{"id":'102', "thumb": '/assets/images/IMG-20220609-WA0006.jpg', "btitle": 'Management Studies are a handholding towards Entrepreneurship',"url":'/blog/management-studies-are-a-handholding-towards-entrepreneurship',"des":'India is a developing nation, and the growth of businesses is extremely important for the country. However, the processes involved in the growth of the business are complex and the knowledge...',"pdate":'Jun 09, 2022' },                       
{"id":'101', "thumb": '/assets/images/Successful-Business_2.png', "btitle": 'Successful Business Building – Roles and Importance of being Agile',"url":'/blog/successful-business-building-roles-and-importance-of-being-agile',"des":'Successful businesses today are breaking barriers and traditional norms. However, the approach to successful businesses in the post-pandemic period is standing on the pillar of an agile approach...',"pdate":'Jun 01, 2022' },                       
{"id":'100', "thumb": '/assets/images/Gamification-in-Education.png', "btitle": 'Gamification in Education',"url":'/blog/gamification-in-education',"des":'The present generation is digital natives; they grow up tech-savvy using digital technologies. The education sector presently has been witnessing a massive change, the outlook on education is diversifying...',"pdate":'May 26, 2022' },                       
{"id":'99', "thumb": '/assets/images/Making-space-for-Mindfulness_2.jpg', "btitle": 'Making Space for Mindfulness',"url":'/blog/making-space-for-mindfulness',"des":'What is the meaning of the word ‘Mindfulness’? The meaning is pretty straightforward; it suggests mindfully attending to what one is doing. On the other hand, the definition of ‘Mindfulness’ in psychology...',"pdate":'May 18, 2022' },                       
{"id":'98', "thumb": '/assets/images/blog-images_05.10.jpg', "btitle": 'Experiential Learning to Scale Up Your Corporate Life',"url":'/blog/experiential-learning-to-scale-up-your-corporate-life',"des":'Experiential learning may be defined as learning by doing, learning through experience, or learning through taking part in an action. It can be also referred to as an educational orientation that aims...',"pdate":'May 11, 2022' },                       
{"id":'97', "thumb": '/assets/images/All-about-MBA-in-Human-Resource.jpg', "btitle": 'All about MBA in Human Resource',"url":'/blog/all-about-mba-in-human-resource',"des":'Human Resource Management, or HRM, as the name suggests contributes to forming that essential component of the organization concerned with the recruitment and management of people...',"pdate":'May 04, 2022' },                       
{"id":'96', "thumb": '/assets/images/All-about-MBA-in-Finance.png', "btitle": 'All about MBA in Finance',"url":'/blog/all-about-mba-in-finance',"des":'From the morning tea to the commercials on TV at the end of the night, when it comes to the most influential paradigm controlling all of it, Finance has to be at the top of the list. Finance may be defined...',"pdate":'Apr 26, 2022' },                       
{"id":'95', "thumb": '/assets/images/ALL-about-MBA-in-Marketing_3.jpg', "btitle": 'All about MBA in Marketing',"url":'/blog/all-about-mba-in-marketing',"des":'“Marketing”, a term we hear in our everyday lives, is not just a term. Marketing may be defined as the process to be fundamentally associated with understanding customers extremely well...',"pdate":'Apr 18, 2022' },                       
{"id":'94', "thumb": '/assets/images/Areas-you-should-consider-before-applying-for-an-MBA_website.png', "btitle": 'Areas You Should Consider before Applying for an MBA',"url":'/blog/areas-you-should-consider-before-applying-for-an-mba',"des":'MBA is one of the prominent career choice options for students from multiple disciplines. It has been a prestigious program for anyone looking to enter into an ocean of opportunities. MBA is not just a management...',"pdate":'Apr 7, 2022' },                       
{"id":'93', "thumb": '/assets/images/The-Significance-of-Experiential-Learning-in-enhancing-Management-Education.png', "btitle": 'The Significance of Experiential Learning in enhancing Management Education',"url":'/blog/The-Significance-of-Experiential-Learning-in-enhancing-Management-Education',"des":' Management Education and Development Management education accounts to be the most sought post-graduate educational program by graduates emerging from diverse streams. A developing nation requires...',"pdate":'Apr 1, 2022' },                       
{"id":'92', "thumb": '/assets/images/Emergence-of-Hybrid-Learning.png', "btitle": 'Emergence of Hybrid Learning',"url":'/blog/emergence-of-hybrid-learning',"des":'With the onset of the global pandemic our lives have been quite stuck inside our houses. In every alternative six months, we are witnessing waves of pandemic hitting the mass. This has left a deep effect...',"pdate":'Mar 24, 2022' },                       
{"id":'91', "thumb": '', "btitle": 'Knowledge Sharing as the Key to Success',"url":'/blog/knowledge-sharing-as-the-key-to-success',"des":'  Knowledge sharing paves the road to success wherein effective collaboration is needed between different organizational facets for meeting some common objectives. The benefits of knowledge sharing can be rightly ...',"pdate":'Mar 17, 2022' },                       
{"id":'90', "thumb": '/assets/images/International-Expansion-of-Small-Business_-blog_-website.png', "btitle": 'International Expansion of Small Business',"url":'/blog/international-expansion-of-small-business',"des":'International expansion activities of small business institutions potentially help them in both being increasingly competitive and also in gaining quality access to untapped and new markets. Moreover, the focus on international...',"pdate":'Mar 10, 2022' },                       
{"id":'89', "thumb": '/assets/images/website-blog_08_02_2022.png', "btitle": 'Diversity, Inclusivity, and the Role of Middle Managers',"url":'/blog/diversity-inclusivity-and-the-role-of-middle-managers',"des":'A diverse and inclusive workplace culture can be defined as one that makes employees feel involved and supported in every aspect of the workplace irrespective of their position in the hierarchy...',"pdate":'Feb 8, 2022' },                       
{"id":'88', "thumb": '/assets/images/Gig Economy.png', "btitle": 'Gig Economy: Growing Significance of Skill-based Education',"url":'/blog/gig-economy-growing-significance-of-skill-based-education',"des":'I am text block. Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. The rise of use of technological devices...',"pdate":'Feb 1, 2022' },                       
{"id":'87', "thumb": '/assets/images/blog-image_25_01_2022-1.png', "btitle": 'Innovation as a Catalyst for Successful Entrepreneurship',"url":'/blog/innovation-as-a-catalyst-for-successful-entrepreneurship',"des":'The combined aspects of both innovation and entrepreneurship are gaining increasing popularity amidst the emerging and growing challenges in the business sphere. The growth of entrepreneurial activities increased...',"pdate":'Jan 25, 2022' },                       
{"id":'86', "thumb": '/assets/images/website-blog_19_1_2022_2.png', "btitle": 'Socially Responsible Investments: The Way to Earn Ethically',"url":'/blog/socially-responsible-investments-the-way-to-earn-ethically',"des":'“Greed is good. The point is, ladies and gentleman, that greed, for lack of a better word, is good. Greed is right, greed works. Greed clarifies, cuts through, and captures the essence of the evolutionary spirit...',"pdate":'Jan 19, 2022' },                       
{"id":'85', "thumb": '/assets/images/new-blog-post-design_website.png', "btitle": 'Growing Significance of Big Data Analytics in Business',"url":'/blog/growing-significance-of-big-data-analytics-in-business',"des":'Ballooning of Big Data   The need for understanding and evaluating the emerging trends associated to demand, preference patterns, shopping trends and behavioural aspects of consumers in the day-to-day world...',"pdate":'Jan 11, 2022' },                       
{"id":'84', "thumb": '/assets/images/bolg800x400_05012022.jpg', "btitle": 'Building Business Resiliency to gain Needed Sustainability in the Post-pandemic World',"url":'/blog/building-business-resiliency-to-gain-needed-sustainability-in-the-post-pandemic-world',"des":'he outbreak of the COVID-19 pandemic exposed a number of vulnerabilities in the supply chain framework on a global scale. Coupled with that the issues associated with imposing restrictions on travelling...',"pdate":'Jan 5, 2022' },                    
{"id":'83', "thumb": '/assets/images/website-blog-images_-final.png', "btitle": 'Here’s Looking Back at You, 2021',"url":'/blog/e-commerce-trends-in-the-post-pandemic-world',"des":'This year, when the month of December rolled by, it seemed like 2021 went by in the blink of an eye! This was the year that will go into the history books as one of the most intense years so far. If 2020 personified...',"pdate":'Dec 30, 2021' },                    
{"id":'82', "thumb": '/assets/images/E-commerce-Trends.png', "btitle": 'E-commerce Trends in the Post-pandemic World’ in the Post-pandemic Era?',"url":'/blog/e-commerce-trends-in-the-post-pandemic-world',"des":'With a beginning around four decades back, E-commerce has dramatically redefined the way of carrying out shopping in the day-to-day consumer world. With the access to internet being more available...',"pdate":'Dec 20, 2021' },                    
{"id":'81', "thumb": '/assets/images/Industry-Relevant.png', "btitle": 'How to be an ‘Industry Relevant Manager’ in the Post-pandemic Era?',"url":'/blog/how-to-be-an-industry-relevant-manager-in-the-post-pandemic-era',"des":'An ‘Industry Relevant Manager’ is considered to be an agile professional equipped by B-Schools to effectively function in a technology-driven work environment. With a focus on recruiting industry-relevant personnel...',"pdate":'Dec 14, 2021' },                    
{"id":'80', "thumb": '/assets/images/website-blog-image-1.png', "btitle": 'The Evolution of Education Framework from Classroom 1.0 to Classroom 4.0',"url":'/blog/the-evolution-of-education-framework-from-classroom-1-0-to-classroom-4-0',"des":'In order to understand the aspect of education framework, one needs to first understand the meaning of the word, ‘Framework’. The concept of Framework highlighted by the Canadian Oxford Dictionary happens...',"pdate":'Dec 09, 2021' },                    
{"id":'79', "thumb": '/assets/images/Rising-Demand-of-Entrepreneurship-in-the-Post-Pandemic-World.png', "btitle": 'Rising Demand of Entrepreneurship in the Post Pandemic World',"url":'/blog/rising-demand-of-entrepreneurship-in-the-post-pandemic-world',"des":'The COVID-19 pandemic has transformed the global work culture. Once greatly reliant on employment, workers have now shifted their focus to adopt business ventures- big or small, to counter the influx...',"pdate":'Nov 23, 2021' },                    
{"id":'78', "thumb": '/assets/images/website-blog-image.png', "btitle": 'An Effective Zoom Guide: Features and Etiquettes you Should Know',"url":'/blog/an-effective-zoom-guide-features-and-etiquettes-you-should-know',"des":'Humans have the in-built ability to adapt themselves to changing natural and societal environments. The ‘New Normal’ setting in the post-pandemic educational environment reflects the amalgamation of two pedagogic...',"pdate":'Sep 24, 2021' },                    
{"id":'77', "thumb": '/assets/images/Prioritise-Mental.png', "btitle": 'Why We Need to Prioritise Mental Well-being of Young Minds',"url":'/blog/why-we-need-to-prioritise-mental-wellbeing-of-young-minds',"des":'Dale Carnegie, the notable author of the pioneer book on self-development “How to Stop Worrying and Start Living” explains how most of us fret over useless things in life and lose our precious time overthinking about the future...',"pdate":'Sep 10, 2021' },                    
{"id":'76', "thumb": '/assets/images/Website-blog-iamge_17_08_2021.png', "btitle": '‘Code Red for Humanity’ as Climate Change is Real',"url":'/blog/code-red-for-humanity-as-climate-change-is-real',"des":'More than three decades ago, few scientists sanctioned by the United Nations (UN) first warned that humans were fueling a dangerous greenhouse effect, and that if the world didn’t act collectively and deliberately...',"pdate":'Aug 17, 2021' },                    
{"id":'75', "thumb": '/assets/images/bloge-images.png', "btitle": 'Zomato IPO – A Success Story or an Over-optimistic Market Response?',"url":'/blog/zomato-ipo-a-success-story-or-an-over-optimistic-market-response',"des":'Zomato was founded in 2008 by Deepinder Goel & Pankaj Chaddah as Foodiebay, a tech start-up, originally started as an advertising company with an app-based restaurant discovery service...',"pdate":'Aug 11, 2021' },                    
{"id":'74', "thumb": '/assets/images/website-blog-images_16_07_2021.png', "btitle": 'Globsyn Management Journal: A Research Repository of the Management Realm',"url":'/blog/globsyn-management-journal-a-research-repository-of-the-management-realm',"des":'Globsyn Management Journal (GMJ) has been a classy compendium of articles over the years. GMJ is a peer-reviewed scholarly journal that includes: research papers, perspectives, interfaces, case studies...',"pdate":'Jul 16, 2021' },                    
{"id":'73', "thumb": '/assets/images/Website-Blog-img_25_06_2021.png', "btitle": 'COVID- 19: Healthy Ways to Strengthen Your Immune System',"url":'/blog/covid-19-healthy-ways-to-strengthen-your-immune-system',"des":'Coronavirus disease is still raging in the world, but we need to protect ourselves by boosting our immune system to fight against this viral infection. In the last few blogs, we have clarified how to take precautionary...',"pdate":'Jun 25, 2021' },                    
{"id":'72', "thumb": '/assets/images/Website-Blog-img-2.png', "btitle": 'My Journey as a Management Student in the Time of COVID-19 Pandemic',"url":'/blog/my-journey-as-a-management-student-in-the-time-of-covid-19-pandemic',"des":'COVID-19 has given rise to a global catastrophic situation that has affected every sphere of human life. Amidst this pandemic, the only thing we can do is to stay positive and carry on with our lives...',"pdate":'Jun 18, 2021' },                    
{"id":'71', "thumb": '/assets/images/STOP-CORONAVIRUS_800x400.jpg', "btitle": 'COVID-19: Trending Terminologies – Part 2',"url":'/blog/covid-19-trending-terminologies-part-2',"des":'Welcome back! Or, if you’re a new reader, you might want to read the first part of this series here! Keeping in tune with the first part, this second part will highlight several new terms that have emerged...',"pdate":'Jun 12, 2021' },                    
{"id":'70', "thumb": '/assets/images/stop-coronavirus.jpg', "btitle": 'COVID-19: Trending Terminologies – Part 1',"url":'/blog/covid-19-trending-terminologies-part-1',"des":'It’s been more than a year since the news first broke of novel coronavirus spreading in some parts of the world. Since its emergence, we have acquired a whole new way of life along with the development of a new vocabulary...',"pdate":'Jun 04, 2021' },                    
{"id":'69', "thumb": '/assets/images/Precautions-For-COVID-19_V04_blog-img-1.png', "btitle": 'MBA in International Business: A Growing Trend in Management Education Sector',"url":'/blog/covid-19-from-beware-to-be-aware',"des":'We are presently living through one of the most challenging periods in the last 100 years or so. While the country is in the midst of a grave Second Wave of COVID-19 pandemic, with numbers of cases rising higher...',"pdate":'May 28, 2021' },                    
{"id":'68', "thumb": '/assets/images/IB-blog-image-1.png', "btitle": 'MBA in International Business: A Growing Trend in Management Education Sector',"url":'/blog/mba-in-international-business-a-growing-trend-in-management-education-sector',"des":'In a span of only one year, the COVID-19 pandemic has profoundly changed the lifestyles, stories, and choices of billions of people around the globe, including students. Aside from the devastating health...',"pdate":'May 18, 2021' },                    
{"id":'67', "thumb": '/assets/images/business-teamwork-cooperation-conceptual-image-1200x600.jpg', "btitle": 'Climb up the Corporate Ladder with Professional MBA Programme',"url":'/blog/climb-up-the-corporate-ladder-with-professional-mba-programme',"des":'Why Professional MBA? In the present time, when the global business is experiencing frequent ups and downs in the socio-economic scenario, business sectors are eagerly looking for a skilled...',"pdate":'May 13, 2021' },                    
{"id":'66', "thumb": '/assets/images/blog-image-3.png', "btitle": 'Serendipity: A Journey to become ‘Industry Relevant’',"url":'/blog/serendipity-a-journey-to-become-industry-relevant',"des":'Serendipity means an occurrence by chance. It is an event of the students, for the students and by the students of Globsyn Business School. Conceptualized as a ‘live project’ for students, ...',"pdate":'Apr 09, 2021' },                    
{"id":'65', "thumb": '/assets/images/Embryon_800x400.jpg', "btitle": '‘Embryon – The Entrepreneurship Cell’ of GBS: Recognized as an AASCB International 2021 Innovation That Inspires',"url":'/blog/embryon-the-entrepreneurship-cell-of-gbs-recognized-as-an-aascb-international-2021-innovation-that-inspires',"des":'In order to infuse global standards into its pedagogy, Globsyn Business School (GBS) has joined AACSB International – the Association to Advance Collegiate Schools of Business (AACSB), the world’s largest...',"pdate":'Apr 05, 2021' },                    
{"id":'64', "thumb": '/assets/images/e-learning-online-business-presentation-meeting-concept-1200x600.jpg', "btitle": 'How to use Zoom for Effective Online Teaching?',"url":'/blog/how-to-use-zoom-for-effective-online-teaching',"des":'Zoom Features to Secure Your Online Classroom We had fun playing Zip Zap Zoom as children, now we mostly meet each other over Zoom meetings. Zoom has taken the traditional face-to-face classroom...',"pdate":'Mar 04, 2021' },                    
{"id":'63', "thumb": '/assets/images/website-blog-image-1.png', "btitle": '5th International Vision Seminar: Sustainability in the Age of Disruption',"url":'/blog/5th-international-vision-seminar-sustainability-in-the-age-of-disruption',"des":'Globsyn Business School conceptualized the notion of the International Vision Seminar in the year 2017. Hitherto, four such Vision Seminars have been organized and it is slated to have the 5th International Vision Seminar...',"pdate":'Feb 19, 2021' },                   
{"id":'62', "thumb": '/assets/images/11083-1200x600.jpg', "btitle": 'Decision-Making – A Major Skill in Defining Leadership',"url":'/blog/decision-making-a-major-skill-in-defining-leadership',"des":'An essential deciding factor for any leaders and management is the way they make their decisions, and the people who lack this singular skill are simply not suitable for the leadership roles in this cut-throat...',"pdate":'Feb 02, 2021' },                   
{"id":'61', "thumb": '/assets/images/double-exposure-image-financial-graph-virtual-human-1200x600.jpg', "btitle": 'The Roles and Benefits of Artificial Intelligence in Education Industry',"url":'/blog/the-roles-and-benefits-of-artificial-intelligence-in-education-industry',"des":'Artificial Intelligence (AI) is the computer’s ability or a robot controlled by a computer to do tasks usually done by humans. You may describe it as a branch of computer science which deals with building smart machines...',"pdate":'Jan 13, 2021' },                   
{"id":'60', "thumb": '/assets/images/sharing-ideas-with-groupmate-1200x600.jpg', "btitle": 'Experiential Learning in Management Education',"url":'/blog/the-future-of-mba-education-in-post-covid-19-world',"des":'“Tell me and I’ll forget, show me and I’ll remember, involve me and I’ll understand.” – Carter It is difficult to claim that experience will not be crucial for learning under the right circumstances...',"pdate":'Jan 05, 2021' },                   
{"id":'59', "thumb": '/assets/images/website-blog-img-1.png', "btitle": 'The Future of MBA Education in Post COVID-19 World',"url":'/blog/the-future-of-mba-education-in-post-covid-19-world',"des":'As a Faculty of MBA education for sometimes, I was always of the opinion that knowledge is the primary driver of getting a good placement. The more a student is knowledgeable in class, has the ability...',"pdate":'Dec 18, 2020' },                   
{"id":'58', "thumb": '/assets/images/blog-img_800x400_26_11_2020.png', "btitle": 'Strategies to Drive Employee Engagement to Boost Team Performance',"url":'/blog/strategies-to-drive-employee-engagement-to-boost-team-performance',"des":'Employee Categorisation X-model of Employee Engagement pinpoints four distinct types – the engaged ones are the first and the most coveted group, which can again be classified into fully and almost engaged ones...',"pdate":'Nov 26, 2020' },                   
{"id":'57', "thumb": '/assets/images/blog-image_17_11_2020.png', "btitle": 'Financial & Management Accounting: The Road Ahead',"url":'/blog/financial-management-accounting-the-road-ahead',"des":'In Globsyn Business School (GBS), ‘Financial & Management Accounting’ is a very important foundation course taught in Semester I in the 2 years MBA programme. It is usually followed by a course on...',"pdate":'Nov 17, 2020' },                   
{"id":'56', "thumb": '/assets/images/blog-img_12_11_2020.png', "btitle": 'Blended Learning: Grooming Students for “New Normal” corporate',"url":'/blog/communication-a-key-skill-to-enhance-the-employability',"des":'The pandemic storm has disrupted the world like never before. It has created a new chapter in the historical pages of human civilization. Creating history means a change in the human era and the pandemic...',"pdate":'Nov 12, 2020' },                   
{"id":'55', "thumb": '/assets/images/blog-image_02_11_2020.png', "btitle": 'Communication- A Key Skill to Enhance the Employability',"url":'/blog/communication-a-key-skill-to-enhance-the-employability',"des":'In today’s world, the young generation who possess certain skills along with their excellence in education is acknowledged. The whole world is standing under a pause button due to the pandemic situation...',"pdate":'Nov 02, 2020' },                   
{"id":'54', "thumb": '/assets/images/blog-image-2.png', "btitle": 'Digital Marketing in Emerging Economies',"url":'/blog/digital-marketing-in-emerging-economies',"des":'The term Digital Marketing is often coined with other terms as ‘Online Marketing’, ‘Internet Marketing’ and ‘Web Marketing’ by people residing along diverse regions. In United States, it is most commonly...',"pdate":'Oct 28, 2020' },                   
{"id":'53', "thumb": '/assets/images/website-blog-images_13_10_2020-1.png', "btitle": 'Practicing Research – A Way of Life with the Spice of Passion and Joy',"url":'/blog/practicing-research-a-way-of-life-with-the-spice-of-passion-and-joy',"des":'The brand called Research has always been put into high esteem, enhancing the self-image of the person conducting the research. While research has been put into a halo light or in a hall of fame...',"pdate":'Oct 13, 2020' },                   
{"id":'52', "thumb": '/assets/images/website-blog-img.png', "btitle": 'Importance of Foreign Languages in Global Business Scenario',"url":'/blog/importance-of-foreign-languages-in-global-business-scenario',"des":'Language plays an important role in business administration and customer satisfaction in all kinds of businesses, it aims to achieve customer satisfaction from an interaction with an organization..',"pdate":'Sep 09, 2020' },                   
{"id":'51', "thumb": '/assets/images/website-blog-image_2.png', "btitle": 'Announcement of the ‘Stimulus’ Package by the Indian Government',"url":'/blog/announcement-of-the-stimulus-package-by-the-indian-government',"des":'During the lockdown, Indian Prime Minister Shri Narendra Modi has addressed the nation to be self-reliant, for which he has declared a stimulus package of Rs. 20 Lakh Crore with the aim to nurse...',"pdate":'Sep 02, 2020' },                   
{"id":'50', "thumb": '/assets/images/img_11.png', "btitle": 'Tips and Tricks for Sailing Through Online Interviews',"url":'/blog/tips-and-tricks-for-sailing-through-online-interviews',"des":'Amid the Covid-19 pandemic and lockdown, the industrial machinery has been brought to a standstill in its entirety – and the recruitment industry is no exception. Consistent with the current situation...',"pdate":'Aug 14, 2020' },                   
{"id":'49', "thumb": '/assets/images/FB-post_800x400.jpg', "btitle": 'COVID-19 and Increased Technology Adoption',"url":'/blog/paradigm-shift-but-seamless-transition',"des":'The emergence of COVID-19 has required us to go into a situation of home quarantine. The normal life of school going children and office going people are starkly affected owing to the growth of infected cases ...',"pdate":'Jul 24, 2020' },                   
{"id":'48', "thumb": '/assets/images/website-blog-image.png', "btitle": 'Paradigm Shift but Seamless Transition',"url":'/blog/paradigm-shift-but-seamless-transition',"des":'Lockdown due to the COVID-19 pandemic has brought in several opportunities for the teachers as well as for the students, in terms of exploring more novel avenues of pedagogy delivery. In the initial phase...',"pdate":'Jul 19, 2020' },                   
{"id":'47', "thumb": '/assets/images/web-site-blog-images_2.png', "btitle": 'Online Teaching – The New Reality post COVID-19',"url":'/blog/online-teaching-the-new-reality-post-covid-19',"des":'At times when India is under lockdown due to COVID-19, the online teaching-learning process has become a new reality. It came as a boon to the students who otherwise would have lost a year of studies...',"pdate":'Jun 30, 2020' },                   
{"id":'46', "thumb": '/assets/images/2945657-1200x600.jpg', "btitle": 'Liquidity Crunch and its Effect on the Economy',"url":'/blog/liquidity-crunch-and-its-effect-on-the-economy',"des":'Every family has a compulsion bearing list of daily expenses. Without cash, these daily expenses cannot be borne. The earning member in our family supplies the required cash that keeps steady the daily family expenditure...',"pdate":'Jun 25, 2020' },                   
{"id":'45', "thumb": '/assets/images/BLOG-IMAGES-1.png', "btitle": 'Teaching Through Technology – A New Way of Life @ GBS',"url":'/blog/branding-at-the-time-of-covid-19',"des":'Technology, Research and Innovation have always been a way of life for every faculty in Globsyn Business School (GBS) since the last two decades. We have always thrived on doing things in a different way...',"pdate":'Jun 17, 2020' },                   
{"id":'44', "thumb": '/assets/images/2283-1200x600.jpg', "btitle": 'Branding at the Time of COVID-19',"url":'/blog/branding-at-the-time-of-covid-19',"des":'The emergence of the lockdowns broadly affected the different brands on a global scale. It thus threw novel challenges to the brand managers who in turn were required to strategise their branding activities and designs...',"pdate":'Jun 12, 2020' },                   
{"id":'43', "thumb": '/assets/images/blog-images.png', "btitle": 'Marching Online',"url":'/blog/marching-online',"des":'“Nothing can be taught but everything can be learnt.” This is most true for online education because in this, students have greater responsibility for learning. But that does not diminish the role of the teacher...',"pdate":'Jun 09, 2020' },                   
{"id":'42', "thumb": '/assets/images/GBS-web-blog-banner.png', "btitle": 'How is Flipped Classroom Flipping the Role of Traditional Classroom Pedagogy',"url":'/blog/how-is-flipped-classroom-flipping-the-role-of-traditional-classroom-pedagogy',"des":'The purpose of education is to move forward, improve and express. Over the last few years, Flipped Classroom has rapidly gained popularity among faculty and administrators of institutes around the globe...',"pdate":'Jun 03, 2020' },                   
{"id":'41', "thumb": '/assets/images/348-1200x600.jpg', "btitle": 'Jobs You Can Apply For After MBA (Part 3: Operations Management)',"url":'/blog/jobs-you-can-apply-for-after-mba-part-3-operations-management',"des":'Among the many specializations offered by MBA programs, Operations Management stands out one of the most important academic disciplines, which is of prime importance to any organization and economy as a whole...',"pdate":'May 22, 2020' },                   
{"id":'40', "thumb": '/assets/images/219-1200x600.jpg', "btitle": 'Jobs You Can Apply For After MBA (Part 2: Human Resources)',"url":'/blog/jobs-you-can-apply-for-after-mba-part-2-human-resources',"des":'In every organization, the Human Resource department plays a crucial role in determining that the right talent is given the right responsibility. For this, it is considered to be one of the most important departments in a company...',"pdate":'May 08, 2020' },                   
{"id":'39', "thumb": '/assets/images/1633-1200x600.jpg', "btitle": 'Jobs You Can Apply For After MBA (Part 1: Marketing)',"url":'/blog/jobs-you-can-apply-after-mba-part-1-marketing',"des":'Masters of Business Administration (MBA) as a qualification of higher studies has always been in demand in the education industry. Various universities and institutions aim to educate graduates in the latest practices...',"pdate":'May 01, 2020' },                   
{"id":'38', "thumb": '/assets/images/blog-image.png', "btitle": 'Why implementing Outcome Based Education is the call of the hour?',"url":'/blog/why-implementing-outcome-based-education-the-call-of-the-hour',"des":'At present, the Outcome Based Education or the OBE system is being widely used by leading institutions across the world which has developed a curiosity to understand the reason behind the ongoing learning transformation. ...',"pdate":'Apr 24, 2020' },                   
{"id":'37', "thumb": '/assets/images/Copy-of-How-to-Humanize-Leadership.png', "btitle": 'What Employers Look For When Hiring Recent Graduates',"url":'/blog/what-employers-look-for-when-hiring-recent-graduates',"des":'College students are fresh minds that have the power to alter the economy for the better or worse. It depends on how well they are trained and educated in their respective fields of specializations...',"pdate":'Mar 15, 2020' },                   
{"id":'36', "thumb": '/assets/images/2020-03-17-1.png', "btitle": 'Importance of Data Privacy',"url":'/blog/importance-of-data-privacy',"des":'Amit and his wife recently went to a restaurant for dinner to celebrate their marriage anniversary. At the time of billing, a feedback form was provided. Amit filled up the form happily and handed it over to the waiter....',"pdate":'Mar 17, 2020' },                   
{"id":'35', "thumb": '/assets/images/2020-03-05-copy.png', "btitle": 'Coronavirus: The Impact on Chinese & Global Economy',"url":'/blog/coronavirus-the-impact-on-chinese-global-economy',"des":'Xiulan Chen, the London-based fashion designer, is going to miss this year’s Fashion Week in Europe as they do not have any ready product to sell because of the recent Novel Coronavirus (nCoV) outbreak....',"pdate":'Mar 05, 2020' },                   
{"id":'34', "thumb": '/assets/images/2020-02-27.png', "btitle": 'Leadership vs. Bullying: The Fine Line',"url":'/blog/leadership-vs-bullying-the-fine-line',"des":'An organisation thrives on the vision and guidance of a leader. Effective leadership not only drives success, but also constructs and nourishes the very foundation of sustainable growth...',"pdate":'Feb 27, 2020' },                   
{"id":'33', "thumb": '/assets/images/How-to-Humanize-Leadership.png', "btitle": 'PGDM vs. MBA – What is the Wise Choice?',"url":'/blog/how-to-humanize-leadership',"des":'With the growth of corporate culture and entrepreneurship in India, the number of students opting for management studies has elevated and so has the institutes offering postgraduate courses in management....',"pdate":'Jan 22, 2020' },                   
{"id":'32', "thumb": '/assets/images/PGDM-vs-MBA.png', "btitle": 'PGDM vs. MBA – What is the Wise Choice?',"url":'/blog/pgdm-vs-mba-what-is-the-wise-choice',"des":'With the growth of corporate culture and entrepreneurship in India, the number of students opting for management studies has elevated and so has the institutes offering postgraduate courses in management....',"pdate":'Jan 22, 2020' },                   
{"id":'31', "thumb": '/assets/images/Copy-of-Copy-of-Copy-of-Untitled-Design.png', "btitle": 'The Definitive Guide to Project Management',"url":'/blog/the-definitive-guide-to-project-management',"des":'Most industries function based on projects that are planned and executed to achieve goals on the level of individual corporate firms. Whether you are a project manager, entrepreneur or operations manager, ...',"pdate":'Jan 14, 2020' },                   
{"id":'30', "thumb": '/assets/images/Copy-of-Untitled-Design-2.png', "btitle": 'A GBS Experience through Global Immersion Program (Part 3)',"url":'/blog/a-gbs-experience-through-global-immersion-program-part-3',"des":'This blog is the third and final part of the ‘A GBS Experience through Global Immersion Program’ series, where a group of GBS students, pursuing Global MBA – International Business, visited Malaysia ...',"pdate":'Dec 30, 2019' },                   
{"id":'29', "thumb": '/assets/images/Copy-of-Untitled-Design-1.png', "btitle": 'A GBS Experience through Global Immersion Program (Part 2)',"url":'/blog/a-gbs-experience-through-global-immersion-program-part-2',"des":'This blog is the second part of the ‘A GBS Experience through Global Immersion Program’ series, where a group of GBS students, pursuing Global MBA – International Business, visited Malaysia as a part of their Global...',"pdate":'Dec 19, 2019' },                   
{"id":'28', "thumb": '/assets/images/Copy-of-Untitled-Design.png', "btitle": 'Green Entrepreneurship: A Path towards Sustainable Development',"url":'/blog/green-entrepreneurship-a-path-towards-sustainable-development',"des":'Small and medium-sized enterprises (SMEs) are the backbone of most of the country’s economies by contributing extensively to GDP growth and providing high levels of employment...',"pdate":'Dec 09, 2019' },                   
{"id":'27', "thumb": '/assets/images/Untitled-design.png', "btitle": 'Top Management DNA',"url":'top-management-dna',"des":'Only seven of the first 50 Indian business families of 1947 were in business by the turn of this century, and 32 of the country’s largest business families of 1969 are no longer among the top 50 today....',"pdate":'Dec 04, 2019' },                   
{"id":'26', "thumb": '/assets/images/Malaysia.jpg', "btitle": 'A GBS Experience through Global Immersion Program (Part 1)',"url":'a-gbs-experience-through-global-immersion-program-part-1',"des":'Recently a group of GBS students, pursuing Global MBA – International Business, visited Malaysia as a part of their Global Immersion Program. This will be a series about their various learning experiences,...',"pdate":'Nov 28, 2019' },                   
{"id":'25', "thumb": '/assets/images/Blog-Banner_800x400-1.jpg', "btitle": 'Tips to chase-away those Pre-CAT Blues',"url":'tips-to-chase-away-those-pre-cat-blues',"des":'CAT (Common Admission Test) is one of the toughest competitive exams in India, which is going to be conducted this year on November 24. This exam not just tests your intellect but your ability to manage your time...',"pdate":'Nov 19, 2019' },                   
{"id":'24', "thumb": '/assets/images/Simulation_Blog.jpg', "btitle": 'Role of Simulation in Management Education',"url":'role-of-simulation-in-management-education',"des":'Simulation is an imitation of certain operations of a system. In the modern era, simulation is widely used in the field of education. Earlier this approach was used in military and aviation industries to maximize safety ...',"pdate":'Oct 31, 2019' },                   
{"id":'23', "thumb": '/assets/images/Commuting_Blog.jpg', "btitle": 'The Cost of Commuting to Work',"url":'/blog/the-cost-of-commuting-to-work',"des":'In today’s high paced world, the aspect of commuting plays a very important role in job searches and career selections. The time taken and the money involved in commuting to work, ...',"pdate":'Oct 28, 2019' },                   
{"id":'22', "thumb": '/assets/images/Blue_ocain_Blog.jpg', "btitle": 'The Blue Ocean Strategy – Venturing into Virgin Markets',"url":'/blog/the-blue-ocean-strategy-venturing-into-virgin-markets',"des":'Over years companies have taken bold strategic moves to dwell into the market, create uncontested market space and make the competition irrelevant. How do they do it? What strategy do they adopt?...',"pdate":'Oct 24, 2019' },                   
{"id":'21', "thumb": '/assets/images/Durgapuja_Blog.jpg', "btitle": 'Durga Puja – A Management Case Study',"url":'/blog/durga-puja-a-management-case-study',"des":'Durga Puja, the mother of all socio-cultural festivals, commemorates the homecoming of goddess Durga. Closest to the hearts of every Bengali household...',"pdate":'Oct 17, 2019' },                   
{"id":'20', "thumb": '', "btitle": 'IT for Managers – Corporate Connect',"url":'/blog/it-for-managers-corporate-connect',"des":'As India’s first ‘Corporate B-School’, Globsyn Business School (GBS) designed ‘Corporate Connect’ as one of the cornerstones of its academic delivery system, which exposes the students to the nuances of the corporate world ...',"pdate":'Oct 10, 2019' },                   
{"id":'19', "thumb": '/assets/images/SUP_BLOG.jpg', "btitle": 'Imposing a Ban on SUPs: An only solution?',"url":'/blog/imposing-a-ban-on-sups-an-only-solution',"des":'On the 73rd Independence Day of India, the Prime Minister of the country, Shri Narendra Modi appealed to all his countrymen to put their effort wholeheartedly towards the mission of making the country free from SUPs...',"pdate":'Sep 26, 2019' },                   
{"id":'18', "thumb": '/assets/images/campus_placement_Blog.jpg', "btitle": '7 Tips to ace Campus Placement',"url":'/blog/7-tips-to-ace-campus-placement',"des":'Campus Placements are the corridors through which students walk into the corporate world. Securing the first job is every student’s dream, and campus placements are the platforms which make that dream come true...',"pdate":'Sep 26, 2019' },                
{"id":'17', "thumb": '', "btitle": 'OTT Services – A Game Changer for Young Managers',"url":'/blog/oot-services-a-game-changer-for-young-managers',"des":'Scenario 1: Mr. Jignesh was extremely frustrated with the local cable TV operator because of their labyrinth of rules and regulation regarding channel selections. Whenever he wanted to watch a cricket match...',"pdate":'Sep 24, 2019' },                
{"id":'16', "thumb": '/assets/images/Start-up_Blog.jpg', "btitle": 'Revamp your Start-up Idea with Innovation',"url":'/blog/revamp-your-start-up-idea-with-innovation',"des":'“Without change there is no innovation, creativity or incentive for improvement. Those who initiate change will have a better opportunity to manage the change that is inevitable.” - William G. Pollard...',"pdate":'Sep 12, 2019' },                
{"id":'15', "thumb": '', "btitle": 'Role of Field Trips in the life of Management Students',"url":'/blog/role-of-field-trips-in-the-life-of-management-students',"des":'When we hear about Field Trips, nostalgia hits us and makes us remember our school days when all of us were taken on trips to museums or parks. Field trips were just like another picnic for school kids....',"pdate":'Sep 12, 2019' },                
{"id":'14', "thumb": '', "btitle": 'Usage of Emojis at Workplace',"url":'/blog/usage-of-emojis-at-workplace',"des":'A picture is worth a thousand words – and an ‘Emoji’ can save you that many letters. It is interesting to see how communication has evolved over time, and how funny and interactive pictures can fluently express human...',"pdate":'Sep 4, 2019' },                
{"id":'13', "thumb": '/assets/images/15-Augest_Blog.jpg', "btitle": '15th August – “How’s the Josh?”',"url":'/blog/15th-august-hows-the-josh',"des":'“One individual may die for an idea; but that idea will, after his death, incarnate itself in a thousand lives. That is how the wheel of evolution moves on and the ideas and dreams of one nation are bequeathed to the next...',"pdate":'Aug 14, 2019' },                
{"id":'12', "thumb": '', "btitle": 'Thinking Social’ Seminar',"url":'/blog/thinking-social-seminar',"des":'Hello Readers, I’m Minal Khetan, pursuing Post Graduate Diploma in International Business and Marketing from Globsyn Business School. In this blog, I would like to share my experience at the ‘8th Tata Social Enterprise Challenge Launch Event..',"pdate":'Aug 12, 2019' },                
{"id":'11', "thumb": '/assets/images/Bridgethegap_Blog.jpg', "btitle": 'Bridging the Gap between Campus and Corporate',"url":'/blog/bridging-the-gap-between-campus-and-corporate',"des":'Campus and Corporate are two different realms, which operate on different platforms with different purposes and ideologies. However, due to these dynamic times, there is a need that these two different worlds come together..',"pdate":'Jul 25, 2019' },                
{"id":'10', "thumb": '', "btitle": 'Why Pursuing an MBA is the Right Option for You?',"url":'/blog/why-pursuing-an-mba-is-the-right-option-for-you',"des":'In today’s world, students have a plethora of career options to choose from after their graduation and it is nowhere easy to decide which career field is best for them. Pursuing an MBA can be the right choice for students..',"pdate":'Jul 25, 2019' },                
{"id":'9', "thumb": '/assets/images/Journey-from-Campus-to-Corporate-through-SIP_Blog.jpg', "btitle": 'Journey from Campus to Corporate through SIP',"url":'/blog/journey-from-campus-to-corporate-through-sip',"des":'Internships are career building opportunities that a student gets while studying management education. And it becomes even more valuable if we do it from a start-up. Start-ups give opportunity to be yourself and learn from scratch..',"pdate":'Jul 4, 2019' },             
{"id":'8', "thumb": '', "btitle": 'Importance of Diversity in Business Schools',"url":'/blog/importance-of-diversity-at-business-schools',"des":'Young minds from multiple states will soon be thronging the corridors of Globsyn Business School (GBS) with dreams in their eyes in search of a corporate career. Coming from diverse backgrounds, these students will enter the gates of the School..',"pdate":'Jun 27, 2019' },         
{"id":'7', "thumb": '', "btitle": 'Internship with Karvy Stock Broking Limited',"url":'/blog/internship-with-karvy-stock-broking-limited',"des":'My name is Chandni Sharma and I’m pursuing my PGDM from Globsyn Business School (GBS). As an integral part of our curriculum, a 10-12 weeks SIP opportunity is extended to all first-year students of the school, which allows us to apply our knowledge..',"pdate":'Jun 20, 2019' },     
{"id":'6', "thumb": '', "btitle": 'The Business and Social Impact of Malpractices in Cyber Universe',"url":'/blog/the-business-and-social-impact-of-malpractices-in-cyber-universe',"des":'Calcutta Management Association (CMA) introduced a new programme entitled “Knowledge Exchange Series” (KES) in the form of evening lecture sessions, with the basic objective to provide a platform where invited guest speakers..',"pdate":'Jun 17, 2019' }, 
{"id":'5', "thumb": '', "btitle": 'Remembering Nandan Bhattacharya',"url":'/blog/remembering-nandan-bhattacharya',"des":'Today, as I get out from my bed in the morning and recount many memories, think of the very existence and growth of Globsyn Group in Kolkata, be it Infinity, TechnoCampus, Software Finishing School or Globsyn Crystals –',"pdate":'Jun 10, 2019' },   
{"id":'4', "thumb": '/assets/images/Summer-Internship-Program-at-Kelloggs.png', "btitle": 'Summer Internship Program at Kellogg’s',"url":'/blog/summer-internship-program-at-kelloggs',"des":'My name is Shagufta Farheen and I’m pursuing PGDM from Globsyn Business School in Kolkata. It has been more than 5 weeks now that I am pursuing my summer internship at Kelloggs',"pdate":'Jun 3, 2019' },
{"id":'3', "thumb": '', "btitle": 'Is the Indian Higher Education System Globally Relevant and Competitive?',"url":'/blog/indian-higher-education-system-globally-relevant-and-competitive',"des":'We are living in an age of rapid technological changes. By the year 2020, there will arise an increasing demand for about 3.3 billion workforce in the services and intensive-manufacturing sectors.',"pdate":'May 30, 2019' },
{"id":'2', "thumb": '/assets/images/Extreme-Value-Theory-for-Proactive-Risk-Management.png', "btitle": 'My SIP Experience at Asian Paints',"url":'/blog/my-sip-experience-at-asian-paints',"des":'Hello, my name is Budhaditya Mondal and I am from Kolkata, ‘The City of Joy’. I would like to take this opportunity to share my Summer Internship Program (SIP) from one of India’s largest paint company – Asian Paints.',"pdate":'May 21, 2019' },
{"id":'1', "thumb": '', "btitle": 'Significance of Net Neutrality',"url":'/blog/significance-of-net-neutrality',"des":'Internet has become part of our daily life. We use it for number of reasons like searching for particular information ...',"pdate":'May 16, 2019' }, 
 ]

export const getUsers = function (page, limit) {
    let array = [];
    for (let i = (page - 1) * limit; i < (page * limit) && users[i]; i++) {
        array.push(users[i]);
    }
    return array;
}
export const getLength = function () {
    return users.length;
}