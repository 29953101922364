import React from 'react'
import { Link } from 'react-router-dom'
import './Home.css'
import BrandSlider from './HomeComponents/BrandSlider'
import Numcounter from './HomeComponents/Numcounter'
import Testimonial from './HomeComponents/Testimonial'

export default function Home() {
  return (
    <>
      <title>Best B School in Kolkata, India - Globsyn Business School</title>
      <div id="wrapper">
        <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
          <div className="carousel-item active">
              <img src="https://www.globsyn.edu.in/wp-content/uploads/2023/06/GBS_Campus_Banner-11.png" className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src="https://www.globsyn.edu.in/wp-content/uploads/2023/06/Home-Page-Banner-24.png" className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src="https://www.globsyn.edu.in/wp-content/uploads/2023/06/GBS-website-banner-laurel-31.png" className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src="https://www.globsyn.edu.in/wp-content/uploads/2023/06/Home-Page-Banner-45.png" className="d-block w-100" alt="..." />
            </div>
          </div>
        </div>


        <div className="d-flex justify-content-center service1-area">
          <div className="service1-inner-area">
            <div className="container">
              <div className="row service1-wrapper">
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 service-box1">
                  <div className="service-box-content">
                    <h3><Link to="https://admissions.globsyn.edu.in/">Apply Now</Link></h3>
                  </div>
                  <div className="service-box-icon">
                    <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 service-box1">
                  <div className="service-box-content">
                    <h3><Link to="programmes.php">Programmes</Link></h3>
                  </div>
                  <div className="service-box-icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 service-box1">
                  <div className="service-box-content">
                    <h3><Link to="/placement">Placement</Link></h3>
                  </div>
                  <div className="service-box-icon">
                    <i className="fa fa-book" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 service-box1">
                  <div className="service-box-content">
                    <h3><Link to="/gbs-campus">Visit Campus</Link></h3>
                  </div>
                  <div className="service-box-icon">
                    <i className="fa fa-home" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="about-page1-area home-about-bg">
          <div className="container">
            <div className="row about-page1-inner">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="about-page-content-holder">
                  <div className="content-box">
                    <h2 className="f-45">
                      Welcome to Globsyn Business School</h2>
                    <p>Over the last two decades, Globsyn Business School (GBS) has uniquely positioned
                      itself as India’s only ‘Corporate B-School’, with its two years full-time Post
                      Graduate Diploma in Management (PGDM) being approved by AICTE. We have received many
                      laurels from the education fraternity from time to time and have been ranked among
                      the Top 50 B-Schools in India, as per Business India’s Best B-Schools Survey 2020.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="video-area overlay-video ">
          <div className="container">
            <div className="video-content">
              <h2 className="video-title f-25">Explore the Campus</h2>
              <Link className="play-btn popup-youtube wow bounceInUp" data-wow-duration="2s" data-wow-delay=".1s"
                to="https://www.youtube.com/watch?v=EhhRBPQSxYg"><i className="fa fa-play"
                  aria-hidden="true"></i></Link>
            </div>
          </div>
        </div>


        <div className="courses-area ">
          <div className="container">
            <h2 className="title-default-center f-30">Programmes</h2>
            <div className="row">
            <div className="col-md-4">
              <div className="card h-100">
                <img src="https://www.globsyn.edu.in/wp-content/uploads/2021/11/PGDM_Boy-1.png" className="card-img-top"
                  alt="Skyscrapers" />
                <div className="card-body">
                  <h5 className="card-title"><Link style={{color:"#002147"}} to="/pgdm">PGDM</Link></h5>
                  <p className="card-text" style={{overflow:"hidden",textOverflow:"ellipsis",maxHeight:'55px'}}> 
                  Designed for the academically consistent student, the Post Graduate Diploma in Management (PGDM) programme provides for strong curricula based learning and an Indian MBA experience coupled with a wide area of study. Based on Globsyn’s triad of Innovation, Research and Technology, this programme is augmented with access to real-time research…
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card h-100">
                <img src="https://www.globsyn.edu.in/wp-content/uploads/2021/11/Global-Business-410x260.jpg" className="card-img-top"
                  alt="Los Angeles Skyscrapers" />
                <div className="card-body">
                  <h5 className="card-title"><Link style={{color:"#002147"}} to="/mba">MBA (Global)</Link></h5>
                  <p className="card-text" style={{overflow:"hidden",textOverflow:"ellipsis",maxHeight:'55px'}}>Innovation lies at the heart of a truly global company. Global managers today have to look beyond business geography as a conglomeration of national marketplaces, but as an invaluable source of knowledge, information, skills and expertise – the key requirements in the development and diffusion of innovation worldwide. Business corporations…
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card h-100">
                <img src="https://www.globsyn.edu.in/wp-content/uploads/2022/09/PGDM-BA-1-1.png" className="card-img-top"
                  alt="Palm Springs Road" />
                <div className="card-body">
                  <h5 className="card-title"><Link style={{color:"#002147"}} to="/mba">PGDM – Business Analytics</Link></h5>
                  <p className="card-text" style={{overflow:"hidden",textOverflow:"ellipsis",maxHeight:'55px'}}>
                  Designed for students looking to pursue a global Business Analytics career.
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
          

        </div>


        <Numcounter />


        <div className="brand-area">
          <div className="container">
            <h2 className="f-30 fw-500">Current Recruiters</h2>
            <div className='pt-5'>
              <BrandSlider />
            </div>
          </div>
        </div>


        <div className="students-join2-area">
          <div className="container">
            <div className="students-join2-wrapper">
              <div className="students-join2-left">
                <div id="ri-grid" className="author-banner-bg ri-grid header text-center">
                  {/* <img src="https://www.globsyn.edu.in/wp-content/uploads/2019/11/students.jpg" alt=""/> */}
                  <img src="https://www.globsyn.edu.in/wp-content/uploads/2019/11/students.jpg" className="d-block w-100" alt="globsyn-alumni"></img>
                </div>
              </div>
              <div className="students-join2-right">
                <div>
                  <h2>Join 3,500+ Alumni</h2>
                  <a href="https://admissions.globsyn.edu.in/" className="join-now-primary-btn">Apply Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="news-event-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 news-inner-area">
                <h2 className="d-flex flex-column justify-content-center align-items-center">Icon Speaks</h2>
                <Testimonial />
                <div className="news-btn-holder">
                  <a href="/icon-speaks" className="view-all-accent-btn">View All</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}
