import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function FacultyPublication() {
    return (
        <><title>Faculty Publications - Globsyn</title>
            <section className="hero-banner-img-research">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Faculty Publications</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/technology">Faculty Publications</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>The Academic Council of Globsyn Business School, is one of the four pillars of the academic ecosystem of the B-School.</p>
                                <p>Globsyn Business School is fortunate to have renowned academicians and industry veterans as its Academic Council members, whose able guidance and mentoring ensures that the B-School’s curriculum architecture and overall academic delivery are periodically updated, industry-aligned and in-line with the global trends.</p>

                                <div className="accordion" id="myAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Prof. (Dr.) Debaprasad Chattopadhyay</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <strong>Journals / Articles / Case Studies / Working Papers:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Chattopadhyay, D., Ganguly, A., Ghosh, A.  Sarkar N. (2022) A Study of School Teachers’ Mental Wellness Using Virtual Pedagogy; Globsyn Management Journal (Innovation  Entrepreneurship in Management) XIV (1  2) pgs. 6; Publisher Globsyn Business School</li>
                                                    <li>Chattopadhyay, D.  Chakraborty, I. (2022) Innovation and Entrepreneurship in Management Education; Globsyn Management Journal (Innovation  Entrepreneurship in Management) XIV (1  2) pgs. 4; Publisher Globsyn Business School</li>
                                                    <li>Chattopadhyay, D. (2022) Innovation and Entrepreneurship in Management Education; Globsyn Management Journal (Innovation  Entrepreneurship in Management) XIV (1  2) pgs. 2; Publisher Globsyn Business School</li>
                                                    <li>Chattopadhyay, D. (2023) ESG and Corporate Sustainability: Align And Grow; Bombay Management Association Journal, eAmbit; 1 (1) pgs. 2; Bombay Management Association Journal, eAmbit</li>
                                                    <li>The New Normal For Healthcare Professionals (Hcps) And Allied Personnel In The Pharmaceutical Industry In The Post-pandemic ERA. Globsyn Management Journal, Vol. XV (2021).</li>
                                                    <li>Chakraborty. I.,  Chattopadhyay. D., Post Pandemic Resilience, Entrepreneurial Ecosystem And The Millennial Entrepreneurs In India. Globsyn Management Journal, Vol. XV (2021).</li>
                                                    <li>Chattopadhyay D. Case study, accepted in ‘Dr. Gaur Hari Singhania Institute of Management  Research, Kanpur (India)’, ISSN -2231-1467 (paper to be published).</li>
                                                    <li>Chattopadhyay D. Article for GMJ, ‘The Landscape and Role of HR in Creating Resilience and Agility in Post-COVID-19 era for Business Sustainability’</li>
                                                    <li>Chattopadhyay D. Research Paper Published in BIMS Journal of Management on ‘Spirituality in Managing Organisations’in July 2020 by Debaprasad Chattopadhyay</li>
                                                    <li>Chattopadhyay D. Self-Research entitled ‘A New Look at Careers across Cultures: Eastern vs. Western Approaches’. Published in Globsyn Management Journal, 2020 issue</li>
                                                    <li>Chattopadhyay D. Self-Research entitled ‘Spirituality in Management’Accepted for publication’ in ARISCOM Journal of Management (ISSN: 2581-9631)</li>
                                                    <li>Chattopadhyay D. article ‘CSR  Sustainability’in July 2019, Published in Business Manager.</li>
                                                    <li>Chattopadhyay D. ‘Impact of Spiritual Leadership on Spiritual Climate of Teams’paper Published in IJCRT, June, 2018</li>
                                                    <li>Chattopadhyay D. et al., ‘A New Look at HR Analytics’for GMC, Amtola in March, 2018, Published in GMJ</li>
                                                    <li>Chattopadhyay D, ‘Positive Psychology’, in ISABS’ publication here and Now, January, 2016.</li>
                                                    <li>Chattopadhyay D, ‘Enigma in Talent Acquisition’, Business Manager, November, 2015.</li>
                                                    <li>Chattopadhyay D et al., ‘The impact of leaders’ spirituality at work and their reputation on teams’ spiritual climate’, International Journal of Indian Culture and Business Management, Vol.11, No.4, 2015.</li>
                                                    <li>Chattopadhyay D, ‘What is your Learning Agenda’, Perfect Professional, January, 2015.</li>
                                                    <li>Chattopadhyay D, ‘Spirituality in Management’, Perfect Professional, January, 2015.</li>
                                                    <li>Chattopadhyay D, ‘Manage your Attitude, not your Boss’, Perfect Professional, September, 2014.</li>
                                                    <li>Chattopadhyay D, Case-Study, ‘Managing Change without HR’, Business Manager, June, 2014.</li>
                                                    <li>Chattopadhyay D, ‘Workplace Sexual Harassment’, Business Manager, January, 2014.</li>
                                                    <li>Chattopadhyay D, ‘International Website publications: Tyson Center for Faith and Spirituality in the Workplace’, http://tfsw.uark.edu my dissertation is now in the ‘Research’ ‘Dissertation’ section of the website under the ‘Leadership’ tab., in January, 2013</li>
                                                </ul>
                                                <br />
                                                <br />
                                                <strong>Publication of Books/ Edited Books</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Chattopadhyay D, Review of the book entitled ‘The Polymath: Unlocking the Power of Human Versatility’by Waqas Ahmed, John Wiley  Sons Ltd., The Atrium, Southern Gate, Chichester, West Sussex. Published in Globsyn Management Journal, 2020 issue</li>
                                                    <li>Chattopadhyay D,‘Learning versus Training in Reinventing HRM’,in the book ‘Reinventing HRM :The Picture of Tomorrow’ Published by Excel India Publishers in September,2017 in a Conference organized by Dr. Gaur Hari Singhania Institute of Management and Research,Kanpur</li>
                                                    <li>Chattopadhyay D, ‘Book Review on Nimble Focused Feisty Organizational Cultures That Win in the New Era and How to Create Them’,Globsyn Management Journal, Vol-X, Issue 1  2, January-December,2016,Published in March,2017</li>
                                                    <li>Chattopadhyay D,‘Book Review on Primed to Perform: How to build the highest performing cultures through the science of total motivation’,Globsyn Management Journal,Vol-IX, Issue 1  2, January-December, 2015, published in February,2016</li>
                                                    <li>Chattopadhyay D,‘Impact of Spiritual Leadership on Spiritual Climate of the Team’, COMPENDIUM ON INTEGRATING SPIRITUALITY  ORGANIZATIONAL LEADERSHIP, Vol-8, Page-14, ISOL Publications (New Delhi / Chicago) published in 2016.</li>
                                                    <li>Chattopadhyay D, ‘Employee Wellness and Organizational Success through Workplace Spirituality’, In IMT-Ghaziabad in 2011 on International Conference on Organizational Development, published in book form titled, ‘Readings in Organizational Development’ by Excel Publishers.</li>
                                                    <li>Chattopadhyay D, Contributed Book-Reviews on several occasions for Globsyn Management Journal</li>
                                                    <li>Chattopadhyay D, Book Reviewed ‘Organization Behaviour’by Robbins et al., published by Pearsons</li>
                                                </ul>
                                                <br />
                                                <br />
                                                <strong>Participation/Presentations – Conferences / Seminars / Workshop:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Attended ALL webinars of AIMS, CMA, Shree Vaishnav Vidyapith Mahavidalaya (Indore).</li>
                                                    <li>Attended Globsyn’s 7th International Vision Seminar on 24th and 25th February,2023 on Innovation and Entrepreneurship in Management Education at BCCi.</li>
                                                    <li>Chattopadhyay D. Article, ‘Changing Role of Leadership during the Crisis Time’for Birla Global University Conference Presentation on 5th December,2020 Abstract accepted</li>
                                                    <li>Chattopadhyay D. ‘Employee health and happiness as key drivers for employeeship under the new people strategy’ Published in September, 2018 at Dr. Gaur Hari Singhania Institute of Management  Research,Kanpur,on 5th International HR Summit ‘HumanOurce- 2018’</li>
                                                    <li>Chattopadhyay D.’HR Perspectives in Marketing’for International Conference of ICFAI, Mumbai Published in April, 2018</li>
                                                    <li>Chattopadhyay D. et al., ‘A New Look at HR Analytics’for GMC, Amtola in March,2018</li>
                                                    <li>Delivered a talk on ‘Current Job Market Scenario and Career Path in Management, Relationship between Psychology  Human Resource Management and How Globsyn will help to reach the Goal’in National Conference on Applied Psychology at Moulali Yuva Kendra organized by Dept. of Applied Psychology, University of Kolkata in March, 2017</li>
                                                    <li>Delivered a talk on’PGDM (HR) as a Career aspect in Management , Current Job market scenario and its scope to the students after their Post-Graduation’to Applied Psychology Post-Graduate Students of Rajabazar Science College,University of Calcutta in January,2017</li>
                                                    <li>Chaired HR Sessions on ‘Skillings for tomorrow’at Army Institute of Management in November, 2015 in National Conference.</li>
                                                    <li>Chattopadhyay D, ‘Spirituality in Management’Seminar on Knowledge Sharing by ISTD, Kolkata Chapter, November, 2014.</li>
                                                    <li>Chattopadhyay D, ‘Impact of Self Perceived Spirituality of Leaders at Work and Leaders’ Reputation on Teams’ Spiritual Climate’ defended doctoral-thesis successfully at Academy of Human Resource Development, Ahmedabad, in 2013</li>
                                                    <li>Chattopadhyay D,’Impact of Spiritual aspect of leadership on spiritual climate of the teams and its plausible linkages with corporate governance’at IIM, Bangalore on International Conference in Spirituality in Management in 2012</li>
                                                    <li>Chattopadhyay D, ‘Employee Wellness and Organizational Success through Workplace Spirituality’at IMT, Ghaziabad on International Conference in Organizational Development in 2011</li>
                                                    <li>Chattopadhyay D,’A comparative study of Global HRM and Domestic HRM’ at Jyotirmoy School of Business, Kolkata on International Conference on OB  HR in 2011 (in absentia)</li>
                                                    <li>Chattopadhyay D,’Contemporary Recruitment and Selection Practices’at Sir Padampat Singhania University, Udaipur, Conference in 2010 (in absentia)</li>
                                                    <li>Chattopadhyay D, ‘Spirituality in Management’at National HRD Network,Kolkata Chapter’s evening knowledge-sharing program held at Globsyn Business School, Globsyn Crystals Campus in 2009</li>
                                                    <li>Chattopadhyay D, ‘A Study of Ethics, Human Values and Spirituality in Corporate Governance’at XLRI, Jamshedpur, International Conference on Organizational Leadership in 2009</li>
                                                    <li>Chattopadhyay D, ‘A new look at HR’at Indian Institute of Social Welfare and Business Management (IISWBM), Kolkata, International Conference on Sustainable Development in 2009</li>
                                                    <li>Conducted on 22/07/09 workshop at NIPM, Kolkata Chapter on Teamwork-working together towards communal goals, Self-Development-A key to Success and Developing Interpersonal Skill and to resolve the conflicts for positive results</li>
                                                    <li>Chattopadhyay D, ‘The influence of leadership and organizational culture on performance management in post-merger and acquisition’at Institute of Business Management, Jadavpur University in 2009</li>
                                                    <li>Chattopadhyay D, ‘A study of Organizational Culture in the Pharmaceutical Industry’at IMT (Institute of Management Technology), Nagpur in 2008</li>
                                                    <li>Chattopadhyay D,’Strategic Human Resource Management’at Burdwan University Conference in 2007</li>
                                                    <li>Chattopadhyay D,’Emerging Paradigms in HR’ at Bharatiya Vidya Bhawan, Kolkata Conference in 2007</li>
                                                    <li>Chattopadhyay D,’Management Practices and Leadership styles’ at Kolkata Science City and Ispat Bhawan, Kolkata on Seminar organized by Spark Steel  Research Pvt.Ltd.-Steel Scenario in 2006</li>
                                                    <li>Chattopadhyay D, ‘Management Practices and Leadership styles The Indian Perspective of Management’ at Ramakrishna Mission, Kolkata Conference in 2006</li>
                                                    <li>Conducted seminar on ‘Communication Skills’ at SIPARD, Agartala for Senior Government Officers in 2005</li>
                                                    <li>Conducted classes at ISTD-Kolkata Chapter on Human Resource Management and Training  Development in 2004</li>
                                                    <li>Conducted seminars at various Rotary Club meetings between 1998 and 2003</li>
                                                </ul>
                                                <br />
                                                <br />
                                                <strong>Visiting assignments </strong>
                                                <br />
                                                <br />
                                                <p>Visiting assignments in: AIMA, Bharatiya Vidya Bhavan, ICFAI, ISB&M,Campus Technocrats, Ashutosh College, Deshbandhu College for Girls, Aptech, etc.</p>
                                                <br />
                                                <br />
                                                <strong>FDP / MDP Attended:</strong>
                                                <ul className='disc'>
                                                    <li>Good to Great Managers-A Roadmap to Learning, Leading and Winning, attended on 10<sup>th</sup> December 2022</li>
                                                    <li>National Faculty Development Programme (FDP) on ‘Publishing in High Impact Journals’10.2020  10.10.2020 at Globsyn Business School.</li>
                                                    <li>FDP on ‘Creating a Cutting-Edge Research Environment’on 04.09.2020 at Globsyn Business School.</li>
                                                    <li>FDP on ‘National Level FDP on case writing and case teaching’on 07.08.2020  08.08.2020 at Globsyn Business School.</li>
                                                    <li>FDP on ‘The Role of Culture in Management Education in COVID-19’Period 31.07.2020 at Globsyn Business School.</li>
                                                    <li>MDP on ‘Challenges and Latest Trends in the Banking Sector due to COVID-19 Impact’06.2020  07.06.2020 at Globsyn Business School.</li>
                                                    <li>FDP on ‘Building a Sustainable Scholarly Career: Connecting The Dots!,’Speaker Prof. Mahendhiran Sanggaran Nair from Monash University Malaysia on 03.10.2019 at Globsyn Business school.</li>
                                                    <li>FDP on ‘Learning with cases’, Speaker : Dr. Sabyasachi Dasgupta from O. P. Jindal Global University on 9.01.2020 at Globsyn Business School</li>
                                                    <li>FDP on ‘Outcome Based Learning’, Speaker : Dr. Sabyasachi Dasgupta from O. P. Jindal Global University on 10.01.2020 at Globsyn Business School</li>
                                                    <li>Faculty Development Program (FDP) on ‘NAAC – the road’at Globsyn Business School, Amtala, from 8th May 2019.</li>
                                                    <li>Faculty Development Program on ‘Mordern Teaching and Learning Pedagogies for Management Education (MUST)’on 13th March, 2019</li>
                                                    <li>Faculty Development Workshop on ‘Innovation in Business Education’on 5th  6th February, 2018 at Globsyn Knowledge Campus, Kolkata.</li>
                                                    <li>Faculty Development Workshop on ‘Effective Teaching Learning Methodologies’on 19th January, 2018 at the Globsyn Knowledge Campus, Kolkata.</li>
                                                    <li>Faculty Development Programme on ‘Enhance Your Research Acumen’ on 12th  13th June, 2018 at Globsyn Knowledge Campus, Kolkata.</li>
                                                    <li>Faculty Development Workshop on ‘Structural Equation Modeling’on 4th October, 2017 at Globsyn Knowledge Campus, Kolkata.</li>
                                                    <li>Faculty Development Workshop on ‘Data Analysis Using SPSS’on 3rd October, 2017 at Globsyn Knowledge Campus, Kolkata.</li>
                                                    <li>Faculty Development program on ‘Modern Teaching – Learning Methods- Rway Ahead’on 31st May 2016 at Globsyn Knowledge Campus, Kolkata.</li>
                                                    <li>Faculty Development programme on ‘Spirituality in Management’, organized by Globsyn Business School-AIMS, for corporate and academia in 2013</li>
                                                    <li>Interpersonal Skills – OB, HSBC</li>
                                                    <li>Leadership, Emotional Intelligence, Time Management, New Role of HR, etc.Consultancy Work on Competency Mapping done for AMRI Group of Hospitals during July-August,2017. – HR, India Steamship, TMILL, ITC, ArcVac, Adhunik Group of Companies, etc.</li>
                                                    <li>Competency Mapping, AMRI Group of Hospitals</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingthree">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Prof. (Dr.) Tanusree Chakraborty</button>
                                        </h2>
                                        <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <strong>Journals/ Articles / Case Studies</strong>
                                                <ul className='disc'>
                                                    <li>Chakraborty, T., Dasgupta, S., MICROFINANCE AND WOMEN’S EMPOWERMENT: A CROSS-CASTE ANALYSIS IN WEST BENGAL. Psychology and Education. <strong>(Scopus Indexed Journal)</strong></li>
                                                    <li>Chakraborty. S., Bose. S., Kabiraj. S., Das. I., Majumder. C.,  Chakraborty. T., Impact Of Covid-19 On Education Sector. Globsyn Management Journal, Vol. XV (2021).</li>
                                                    <li>Rohit Kymar, Dr. Chakraborty T  Dasgupta S (2021), Role of Microfinance in the Development of Rural Indian Economy with special Reference to Champaran District in Bihar. Article published in AIMS Journal of Management, Vol-6, No.2, January 2021, ISSN 23956852.</li>
                                                    <li>Dr. Tanusree Chakraborty1 , Dr. Sabyasachi Dasgupta2 ,Articles in Psychology and Education are listed in PsycINFO, American Psychological Association (APA), Scopus, Microfinance and women’s empowerment: A Cross-Caste Analysis in West Bengal, ISSN: 00333077</li>
                                                    <li>Dr. Chakraborty T  Dasgupta S (2020) EXPLORING THE RELATIONSHIP BETWEEN MICROFINANCE AND WOMEN’S EMPOWERMENT WITH REFERENCE TO SCHEDULED CASTE WOMEN IN COOCH BEHAR DISTRICT, WEST BENGAL, ISSN: 1462-6063, Vol. II, issue VI in June 2020 (UGC CARE LIST, Group II)</li>
                                                    <li>Globsyn Management Journal, 2020 issue (as a part of GBS Research  Consulting Cell), collecting research papers, perspectives and case study. Trying to get it enlisted to UGC-CARE, under process. Expected to be completed.</li>
                                                    <li>Self-Research, To be communicated to a ranked Journal in December, 2020.</li>
                                                    <li>Research Article with Debmalya Banerjee (Globsyn Business School) on internet shutdown. Under process.To be completed in December, 2020.</li>
                                                    <li>Self-Research, to a ranked Journal in December, 2020.</li>
                                                    <li>Self-Research, under process. Expected to be completed in March, 2021.</li>
                                                    <li>Collaborative research with students of Globsyn Business School, under process. Expected to be completed in December, 2020.</li>
                                                    <li>Globsyn Management Journal, 2020 issue (as a part of GBS Research  Consulting Cell), collecting research papers, perspectives and case study. Trying to get it enlisted to UGC-CARE,Under process.Expected to be completed in December, 2020.</li>
                                                    <li>Collaborative research with students of Globsyn Business School, Under process. Expected to be completed in December, 2020.</li>
                                                    <li>Collaborative research with students of Globsyn Business School, Under process.</li>
                                                    <li><a href="https://www.indianjournals.com/ijor.aspx?target=ijor:jpgvolume=3issue=4article=002">TanusreeChakraborty ,“Proxy Women’ or Empowered Politicians: Democratic Decentralization Panchayati Raj Institutions in Rural India”, Published in Journal of Politics  Governance, Vol. 3, No. 4, October-December 2014</a></li>
                                                    <li>TanusreeChakraborty , “Women’s Economic Empowerment: Self Help Group and Microfinance”, Published inJournal Women’s Studies: Various Aspect (Book)<strong><u><b>,</b></u></strong>edited by Dr.BasabiChakraborty, UrbeePrakashan, Kolkata, 2014</li>
                                                    <li><a href="http://birdlucknow.in/wp-content/uploads/2015/10/VolJan-June-2012.pdf">TanusreeChakraborty , “Self Help Groups, Microfinance and Women’s Empowerment: Case Study of Purulia”, Published inJournal The micro FINANCE REVIEW, Vol-IV, No-1, Jan – June 2012</a></li>
                                                    <li>TanusreeChakraborty , “SthanioSwayatyaShasaneNari”, Published in <strong><b>E</b></strong>mpowerment and her Story: an Agamic Vision 2010<u>, </u>edited by Dr.AmitaBagchi, Centre for Women’s Studies, North Bengal University, 2010</li>
                                                    <li>Self-HelpGroups and Microfinance – Issues in Gender Empowerment”Empowerment and her Story: an Agamic Vision 2010, edited by Dr.AmitaBagchi, Centre for Women’s Studies, North Bengal University ISBN:978-81-906600-1-3 2010</li>
                                                    <li>“Women Empowerment: National Policy for the empowerment of Women 2001, Mainstreaming gender in development process, UNDP guidelines of mainstreaming gender, Gender Development Index, Gender Empowerment Measure” and “ Economic Liberalization and impact of women microcredit and women’s self-help groups” Text Book Writing on Women’s Studies General Course of Calcutta University at Women’s Studies Research Centre, University of Calcutta Forthcoming.</li>
                                                    <li>Women Studies Research Centre, University of Calcutta as (teaching post, Fulltime contractual post of UGC) 2013 to 2018</li>
                                                    <li>Research Coordinator for the project “Social Discrimination, Access to Credit and Women’s Empowerment” funded by National Commission for Women, New Delhi 2015-2017</li>
                                                    <li>Research associate for the project “Vulnerability of Tribal Women in terms of Social Discrimination and Livelihood with respect to State Policies: Study of three States in Eastern India”, funded by Indian Council of Social Science Research, New Delhi 2015-2017</li>
                                                    <li>Programme Coordinator for the project “Institutionalization of SODIS with Focus on Community Health Communication and Green Architecture as a Solution of Bottle Exposure Space” funded by the SOLAQUA Foundation, Switzerland and guided by The Swiss Federal Institute of Aquatic Science and Technology(Eawag), Switzerland, Women Studies Research Centre, University of Calcutta 02.07.2012 to 30.04.2013</li>
                                                    <li>Research Assistant for the UGC UPE project titled “Women, Work and Empowerment in Post Independent Bengal: Two Case Studies” Department of History, University of Calcutta 02.04.2012 to 30.06.2012</li>
                                                    <li>Project Coordinator for the DelPHE project “Exploring the Relationship between Women’s Political Empowerment and Poverty Reduction” funded by the Development Partnerships in Higher EducationWomen Studies Research Centre, University of Calcutta 03.12.2008 to 30.12.2011</li>
                                                    <li>Project Associate ,Women Studies Research Centre, University of Calcutta 02.02.2006 to 02.12.2008</li>
                                                </ul >
                                                <p><strong><b>Conferences / Seminars / Event / Presentation: (International)</b></strong></p>
                                                <ul className='disc'>
                                                    <li>Joint Coordinator for the project “Gender Dimensions of the Urban Labour Market: Investigating the terms of Women’s Inclusion and Exclusion” with Prof. Ishita Mukhopadhyay, Ms.IndraniMazumdar, Dr.Neetha. N. Pillai. funded by Centre for Women’s Development Studies, New Delhi 2015-2016</li>
                                                    <li>Coordinator for the Project “STEP AGAINST TRAFFICKING”. Statistical Data Analysis and compilation of report by Women’s Studies Research Centre, C.U.It is being initiated by SANLAAP in collaboration with Women’s Studies Research Centre, University of Calcutta, sponsored by FADV 2015-2016</li>
                                                    <li>International Conference On Social Sciences Colombo, Sri Lanka ““Proxy Women” or Empowered Politicians: A qualitative study of effectiveness of seat reservation and elected women’s participation in Indian local governance” March 25-26, 2014</li>
                                                    <li>Individual Grants Program, Sir Ratan Tata Trust Seventh Annual Poverty and Social Protection international Conference Tomorrow People Organizations at Bangkok, Thailand “Microfinance: Rescuing or Betraying gender from the poverty trap? Case Studies of three Districts in West Bengal, India”March 9-11,2014 UGC</li>
                                                    <li>Scotland Malawi Partners hip’s event Edinburgh ‘Gender Equality and Female Empowerment’. The focus of the event was to explore the contribution of Scotland- Malawi community links towards Millennium Development Goal 3-to promote gender equality November 1, 2011 DFID,British Council</li>
                                                    <li>Visit the Department of Social Sciences at Oxford Brookes University, Oxford, UK to participate in a two day research meeting on ‘Gender,Politics and Poverty’ and to participate in a series of meeting and academic exchanges Oxford Brookes University, Oxford, UK “Women empowerment in rural India”.November 2–4,2011DFID, British Council.</li>
                                                </ul>
                                                <p><strong><b>Conferences / Seminars / Event / Presentation: (National)</b></strong></p>
                                                <ul className='disc'>
                                                    <li>Three-day conference on Eco-friendly Women Entrepreneurship: Panacea or Myth in Development on a project “Promoting Eco-friendly Entrepreneurship Development For Women in West Bengal under the Indo-US 21st Century Knowledge Initiative Programme” The project was developed by the University of Calcutta as the lead institution and Claflin University, SC, U.S.A, Kolkata, University of Calcutta, paper titled “Entrepreneurial Development: Myth or Panacea for Women’s Empowerment from the lens of Sen’s Capability Approach” March , 2019.</li>
                                                    <li>Panel Discussion on a project “Promoting Eco-friendly Entrepreneurship Development For Women in West Bengal under the Indo-US 21st Century Knowledge Initiative Programme” The project was developed by the University of Calcutta as the lead institution and Claflin University, SC, U.S.A, Department of Economics, University of Calcutta, Viability of Entrepreneurship as a move towards women’s empowerment under social vulnerabilities in India, November 2017.</li>
                                                    <li>Three-day conference on Eco-friendly Women Entrepreneurship: Panacea or Myth in Development on a project “Promoting Eco-friendly Entrepreneurship Development For Women in West Bengal under the Indo-US 21st Century Knowledge Initiative Programme” The project was developed by the University of Calcutta as the lead institution and Claflin University, SC, Kolkata, University of Calcuttapaper titled “Entrepreneurial Development: Myth or Panacea for Women’s Empowerment from the lens of Sen’s Capability Approach”March 14-16, 2019 U.S.A.</li>
                                                    <li>Panel Discussion on a project “Promoting Eco- friendly Entrepreneurship Development For Women in West Bengal under the Indo-US 21st Century Knowledge Initiative Programme” The project was developed by the University of Calcutta as the lead institution and Claflin University, SC, Department of Economics, University of Calcutta Viability of Entrepreneurship as a move towards women’s empowerment under social vulnerabilities in India November 23rd, 2017 U.S.A.</li>
                                                    <li>“Dissemination Workshop on Baseline Survey of Trafficking”Women’s Studies Research Centre, University of Calcutta Draft report on Baseline Survey of Trafficking , in 34 villages of 4 blocks, Basirhat-I, Swarupnagar, Hingalgunj Hasnabad of North 24 Parganas District 15.12.2015</li>
                                                    <li>56th Annual Conference of the Indian Society of Labour Economics at the Department of Management, Birla Institute of Technology (BIT), Mesra,“Involuntary Employment! An Exploration in Railway December 18‐20, 2014. Ranchi and the Eastern Regional Centre of the Institute for Human Development (IHD‐ERC), Ranchi Hawking in West Bengal, India”</li>
                                                    <li>Urban Women in Contemporary India by the Centre For Urban Economic Studies, Calcutta University “Self-Help Groups and Microfinance— Issues in Gender Empowerment”February15, 2014</li>
                                                    <li>XIV National Conference on Women’s Studies on Equality, Pluralism and the State: Perspectives from the Women’s Movement Gauhati University “Microfinance: Rescuing or Betraying gender from the poverty trap? Case Studies of Three District in West Bengal” February 4-7, 2014</li>
                                                    <li>Dynamics of Development and Discontent UGC Sponsored National Seminar on Organised byTufanganjMahavidyalayaTufanganj, Cooch Behar “Microfinance: Rescuing or Betraying gender from the poverty trap? Case Studies of Coochbehar District in West Bengal” September27-28, 2013</li>
                                                    <li>“Tribal Women in India” Women’s Studies Research Centre, University of Calcutta “Micro-credit Vs Self- Help Groups among Tribal Women in West Bengal” March 23, 2013</li>
                                                    <li>One Day International Conference on “Women’s Political Empowerment and poverty Reduction Women’s Studies Research centre, University of Calcutta“Women’s Political Empowerment and poverty Reduction in rural India” August 30, 2011.</li>
                                                    <li>Reservation Debate in Indian Politics Women’s Studies Research Centre, Calcutta University in collaboration with Women’s Studies and Development Centre, Delhi University “Women In Politics: Reservation Debate” August 03, 2011</li>
                                                    <li>One Day Workshop “Exploring the Relationship between Women’s Political Empowerment and Poverty Reduction” involved women urban municipal councilors of Ten Urban Municipalities of West Bengal Women’s Studies Research Centre and Supported by Institute of Local Government and Urban Studies (ILGUS) “Definition and Measurement of Poverty” December 10, 2010</li>
                                                    <li>Regional Workshop on Women and Work centre for Women’s studies and Department of Economics, North Bengal University “Microfinance, SHG and Women’s Empowerment” March 26, 2008.</li>
                                                    <li>Regional Workshop on Women and Work centre for Women’s studies and Department of Economics, North Bengal University “Unequal Pay for Men and Women: Wage Differences” March 26, 2008</li>
                                                    <li>Researchers’ meeting held at Ford Foundation Delhi, organised by IFMR, Centre for Microfinance “Microfinance, SHG and Women’s Empowerment” March 28, 2008.</li>
                                                </ul>
                                                <strong>Conference/Seminar (International)</strong>
                                                <ul className='disc'>
                                                    <li>“10th International Language and Development Conference” in partnership with the PRAESA, Language and development Conferences, United Nations Educational, Scientific and Cultural Organization, AFRICAN UNION, ACALAM,UNION AFRICAINE Cape Town, South Africa at the Lagoon Beach Hotel:“Language of Development and Women’s Empowerment” October15-17, 2013.</li>
                                                    <li>RC32 Interim Conference for Session E: gender empowerment and microfinance: Theoretical and empirical perspectives Buenos Aires, Argentina :”Microfinance and Women’s Empowerment: A Study Across Social Groups in West Bengal” August 1—4, 2012.</li>
                                                </ul>
                                                <strong>FDP:</strong>
                                                <ul className='disc'>
                                                    <li>FDP on Mordern Teaching and Learning Pedagogies for Management Education(MUST), Speaker Dr.Prem Kumar Rajagopal from Malaysia University of Science  Technology on 11.03.2019 at Globsyn Business school.</li>
                                                    <li>FDP on NAAC – The Road, Speaker Dr.ParasarBandopadhayayDr. B BPaira, form MCKV Institute of Engineering and Heritage Institute of Technology on 08.05.2019 at Globsyn Business School.</li>
                                                    <li>FDP on Building a Sustainable Scholarly Career: Connecting The Dots!, MahendhiranSanggaran Nair from Monash University Malaysia on 03.10.2019 at Globsyn Business school.</li>
                                                    <li>FDP on Learning with cases, Speaker <strong><b>: </b></strong>SabyasachiDasgupta from O. P. Jindal Global University on 9.01.2020 at Globsyn Business School</li>
                                                    <li>FDP on Outcome Based Learning, Speaker <strong><b>: </b></strong>SabyasachiDasgupta from O. P. Jindal Global University on 10.01.2020 at Globsyn Business School</li>
                                                    <li>FDP on National Level FDP on case writing and case teaching. Speakers Prof. Rajendra v Nargudkar Prof. Sandip Anand Prof. Kumer Satyaki Ray, from IIM Indore, XIMB-Bhubaneswar, ICFAI Business School, on 07.08.2020- 08.08.2020 at Globsyn Business School.</li>
                                                    <li>FDP on the Role of Culture in Management Education in COVID-19 Period. Speaker Mr. Jawhar Sircar- Prasar Bharati  Ex-Cultural Secretary, Govt. of India and also a member of our Governing Council and IAS (Retd), Ex- CEO on 31.07.2020 at Globsyn Business School</li>
                                                    <li>FDP on Digital teaching  learning organized by AIMS 25.07.2020.</li>
                                                    <li>International FDP on How to teach online. Speaker Dr. David Bobker from Malaysia University of Science and Technology (Malaysia/United Kingdom) on 27.07.2020 at Globsyn Business School</li>
                                                </ul>

                                                <strong>MDP:</strong>
                                                <ul className='disc'>
                                                    <li>MDP on Challenges and Latest Trends in Banking Sectors due to COVID 19 Impact,Organized by Globsyn Business School on 6.06.2020  07.06.2020 at Globsyn Business School</li>
                                                </ul>
                                                <strong>Training </strong>
                                                <ul className='disc'>
                                                    <li>SPSS Version 14, Micro Pro, Kolkata, year 2017</li>
                                                    <li>Statistical Methods for Research Workers, Department of Business Management, University of Calcutta and Indian Association for Productivity, Quality and Reliability (IAPQR), year 2017</li>
                                                    <li>Computer Application in Operations Research, TECHNO CAMPUS (The Software Finishing School, Kolkata) Year 2002</li>
                                                    <li>Diploma in Information Technology Application, Computer Training Institute State Youth Centre, Govt. of West Bengal. Year 2000-2001</li>
                                                </ul>
                                                <strong>Book / Edited Book</strong>
                                                <ul className='disc'>
                                                    <li>Edited book (co-edited with Ishita Mukherjee of the University of Calcutta) under process.Expected to be published in February, 2021.</li>
                                                    <li>“Women Empowerment: National Policy for the empowerment of Women 2001,Mainstreaming gender in development process, UNDP guidelines of mainstreaming gender, Gender Development Index, Gender Empowerment Measure” and “ Economic Liberalization and impact of women microcredit and women’s self-help groups”, Text Book Writing on Women’s Studies General Course of Calcutta University at Women’s Studies Research Centre, University of Calcutta, Forthcoming.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingtwo">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsetwo">Prof. (Dr.) Sabyasachi Dasgupta</button>
                                        </h2>
                                        <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p><strong><b>News Articles</b></strong></p>
                                                <ul className='disc'>
                                                    <li><a href="https://www.deccanherald.com/author/sabyasachi-dasgupta">Dasgupta, S  Grover, P. (2018). Publicise the Private, article in Deccan Herald on 8<sup>th</sup>June, 2018.</a></li>
                                                </ul>
                                                <strong>Journals / Articles / Case Studies / Working Papers:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Dasgupta, S.  Sanyal, A. (2022) Impact of Age in technology adoption in education industry, ed. by Dr. Sujata Mangaraj  Dr. P. Narayan Reddy,in Rediscovering management education for the pandemic world, Vol.1, edition 1, pp. 119-120, INSC International Publishers, ISBN: 978-1-68576-258-2</li>
                                                    <li>Grover, P.  Dasgupta, S. (2020) A jerky ride without destination: case study of Tourism Industry, Globsyn Management Journal, Vol. XIV (1 2), pp. 113-133</li>
                                                    <li>Wadhwa, B., Grover, P., Dasgupta, S.,  Uppal, A. (2022). Role Of Power Distance Phenomena In Blended Learning In Higher Education Post Covid. Journal of Industrial Integration and Management Innovation and Entrepreneurship. <strong>(Scopus indexed)</strong></li>
                                                    <li>Impact Of Age On Technological Adoption In Education Industry, Rediscovering Management Education For The Pandemic World: The Road Map Ahead, AIMS Convention Book.</li>
                                                    <li>Chakraborty, T., Dasgupta, S., Microfinance And Women’s Empowerment: Across-caste Analysis In West Bengal. Psychology and Education. <strong>(Scopus Indexed Journal)</strong></li>
                                                    <li>Paul. S., Dasgupta. S., Conceptualizing Business Potential Of Strategic Advergames For Future Marketing Campaign In A Post Pandemic World. Globsyn Management Journal, Vol. XV (2021).</li>
                                                    <li>Gupta. A., Halder. A., Dhar. D., Mondal. R., Roshan. P., Singh. P.,  Dasgupta. S., Are Hotels Now Holidaying? Case Study On Itc Hotels And Post Pandemic Impacts. Globsyn Management Journal, Vol. XV (2021).</li>
                                                    <li>Rohit Kymar, Dr. Chakraborty T  Dasgupta S (2021), Role of Microfinance in the Development of Rural Indian Economy with special Reference to Champaran District in Bihar. Article published in AIMS Journal of Management, Vol-6, No.2, January 2021, ISSN 23956852.</li>
                                                    <li>Dr. Tanusree Chakraborty1 , Dr. Sabyasachi Dasgupta2 ,Articles in Psychology and Education are listed in PsycINFO, American Psychological Association (APA), Scopus, Microfinance and women’s empowerment: A Cross-Caste Analysis in West Bengal, ISSN: 00333077</li>
                                                    <li>Dr. Chakraborty T  Dasgupta S (2020) EXPLORING THE RELATIONSHIP BETWEEN MICROFINANCE AND WOMEN’S EMPOWERMENT WITH REFERENCE TO SCHEDULED CASTE WOMEN IN COOCH BEHAR DISTRICT, WEST BENGAL, ISSN: 1462-6063, Vol. II, issue VI in June 2020 (UGC CARE LIST, Group II)</li>
                                                    <li>Majumdar, S. Dasgupta, S (2020) Understanding the inheritance influence of “Cultural Capital”: Challenges faced by first generation Engineering students, <em><i>Studies in Indian Place Names</i></em>, Vol. 40 (50), pp. 1278 – 1297 (UGC CARE LIST, Group 1)</li>
                                                    <li>Dasgupta, S. (2019) Responsibility on Brand Equity in the telecom sector in India, <em><i>International Journal of Innovative Technology and Exploring Engineering (IJITEE), </i></em> 9 (2S3), pp. 356-363 <strong><b>(Scopus Indexed)</b></strong></li>
                                                    <li>Dasgupta, S.  Grover, P. (2019) Critically evaluating SWIFT’S strategy as a monopoly in the FINTECH business, <em><i>International Journal of Innovative Technology and Exploring Engineering (IJITEE), </i></em> 8 (12), pp. 3839-3844 <strong><b>(Scopus Indexed)</b></strong></li>
                                                    <li>Dasgupta, S.  Grover, P. (2019) Understanding the role of Corporate Social Responsibility in Consumer Psychology: A case study of Keen Footwear, Rothy’s Shoes, Supra Footwear and Toms Shoes, <em><i>THINK INDIA JOURNAL, </i></em> 22 (14), pp. 3437-3444. <strong><b>(UGC CARE List)</b></strong></li>
                                                    <li>Dasgupta, S.  Grover, P. (2019) Critically Evaluating SWIFT’s Strategy as a Monopoly in the Fintech Business, <em><i>International Journal of Innovative Technology and Exploring Engineering (IJITEE), </i></em> 8 (12), ISSN: 2278-3075, DOI: 10.35940/ijitee.L3355.1081219 <strong><b>(Scopus Indexed)</b></strong></li>
                                                    <li>Dasgupta, S.  Jain, P. (2019) Exploring Communication Metaphors in Preventive Products: a Critical Discourse Analysis on the portrayal trends of Condom Advertisements, <em><i>International Journal of Recent Technology and Engineering (IJRTE),</i></em>Volume-8(2); pp. 3423-3430; ISSN: 2277-3878, DOI: 10.35940/ijrte.B2525.078219 <strong><b>(Scopus Indexed)</b></strong></li>
                                                    <li><a href="https://www.abacademies.org/journals/month-march-year-2019-vol-23-issue-1-journal-amsj-past-issue.html" target="_blank" rel="noreferrer">Dasgupta, S.  Grover, P. (2019) Impact of digital strategies on consumer decision journey, <em><i>Academy of Marketing Studies Journal,</i></em>Vol 23 (1), pp. 1-14. <strong><b>(ABDC indexed)</b></strong></a></li>
                                                    <li><a href="http://www.iaeme.com/ijcet/issues.asp? JType= IJCETVType=10IType=1" target="_blank" rel="noreferrer">Dasgupta, S.  Grover, P (2019) Understanding adoption factors of over-the-top video services among Millennial consumers, <em><i>International Journal of Computer Engineering  Technology (IJCET)</i></em>, Vol. 10 (1), pp. 61-71</a></li>
                                                    <li>Grover, P Dasgupta, S (2017) Impact of brand equity on the top-of-mind awareness (TOMA) in FMCG sector with special reference to toothpaste category, International Journal of Research in IT and Management (IJRIM), Available online at:<a href="http://euroasiapub.org/">http://euroasiapub.org</a>, Vol. 7, Issue 3, March – 2017, pp. 20~30, ISSN(o): 2231-4334 | ISSN(p): 2349-6517 | Impact Factor: 6.505 | <strong><b>Thomson Reuters </b></strong>Researcher ID: L-5236-2015</li>
                                                    <li>Grover, P Dasgupta, S (2017) Gender and Online Purchase Behavior: The Case of Millennial, <em><i>Media Watch Journal of Communication</i></em>, Vol. 8 (2) pp. 94-101, ISSN 0976-0911 e-ISSN 2249-8818; DOI: 10.15655/mw/2017/v8i1A/48923 <strong><b>(Scopus indexed)</b></strong></li>
                                                    <li>Ghatge, A Dasgupta, S (2017) Exploring the Competitive Advantage of Cross-Cultural Communication Training: A Conceptual Semantic Study, <em><i> Journal of Management and Development Studies</i></em>, Vol. 6(3), ISSN (Online): 2320-0685. ISSN (Print): 2321-1423, pp. 30-40</li>
                                                    <li>Dasgupta, S.  Dutta, D. (2017) Understanding online consumer purchase behaviour for varied consumer clusters for Electronics Brands in India, <em><i>International Journal of Humanitis and Social Science Invention,</i></em>Vol 6 (1), pp.1-15, New Delhi, ISSN: 2319-7714</li>
                                                    <li>Chatterjee, C., Patra, R., Das, P., Dasgupta, S.  Ghosh M. (2017) Holistic Industry Interactions, Enhancing B-School Competitiveness, ed. By S R Singhvi Amit Agnihotri, Pearson India Education Services Pvt. Ltd. Noida, pp.43-48 ISBN: 978-93-325-7820-3</li>
                                                    <li>Dasgupta, S., Dhote, T., Sahasrabuddhe, K. (2016). Heutagogy in teaching, presented a poster in an international conference on innovations in teaching, learning and evaluation in higher education, at Model College of Arts, Science and Commerce, Pune.</li>
                                                    <li>Patra, R Dasgupta, S (2014) Understanding the Synergy of Carroll‟s Model with the Global and Indian Corporate Social Responsibility Guidelines: A Conceptual Study, IJLTEMAS, Volume III, Issue X, October 2014, ISSN 2278 – 2540, pp.80-87</li>
                                                    <li>Dasgupta, S Ghatge, A (2014) “Understanding the stickiness of Corporate Social Responsibility Reporting as a post globalization digital marketing strategy: A study of multinational automobile companies in India” International Journal of Management Sciences and Business Research (ISSN-2226-8235), pp. 283-292</li>
                                                    <li>Dasgupta, S Ghatge, A (2014) Understanding the theoretical convergence of Corporate Social Responsibility reporting in practice: A thematic analysis of automobile websites, International Journal of Business and Social Sciences (ISSN 2219-1933), pp.175-183</li>
                                                    <li>Dasgupta, SDasgupta , A (2014), “Intellectual Property Right (IPR) from a meta-practical approach of information flow”, in the conference proceedings journal (ISSN no. 09723668) of Indian Association of Special Libraries and Information Centres (IASLIC), pg 34-39</li>
                                                    <li>Dasgupta, S (2013). ‘Responsibility in-focus’: Deconstructing the Corporate Social Responsibility concept”, in International Journal of Asian Business and Information Management (IJABIM), Volume 4, Issue 3, DOI: 10.4018/jabim.2013070103 (ISSN: 1947-9638)</li>
                                                    <li>Dasgupta, Sabyasachi (2012). Issues of digital divide and the impact of globalization on the knowledge societies of the future, IASLIC publication, JKU volume, pg 381-386</li>
                                                    <li>Dasgupta, Arjun DasguptaSabyasachi (2012). Change Management: Its impact on Libraries and Information Centres, IASLIC publication, JKU volume, pg 513-518</li>
                                                    <li>Dasgupta, Sabyasachi (2012) “Language, Identity and Culture: the Issue of Multi-culturalism, E-diaspora  Global Culture Networks with special reference to call centres in India”, IPEDR vol. 33 (2012), IACSIT Press, Singapore.</li>
                                                    <li>Dasgupta, Sabyasachi (2011) “Media, Gender and Beauty: the hegemonic role of media in the creation of idealised beauty with special reference to Men’s Health magazine”, IMS Manthan: The Journal of Innovation” in <em><i>“Journalism – Section”</i></em>, Volume-VI, Issue-II, Dec-2011</li>
                                                    <li>Dasgupta, Sabyasachi (2011), “The issue of Ayodhya: A mediated magnification to society”, Mica Communication Review (MCR), Issue V.</li>
                                                </ul>
                                                <strong>Books/ book chapters/ proceedings</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Dasgupta, S. (2021), Book, ‘Brand Trigger-points: How humble start-ups become brand barons’ Thomson Reuters Publisher, April 2021.</li>
                                                    <li><a href="https://doi.org/10.1108/978-1-78756-755-920191002" target="_blank" rel="noreferrer">Dasgupta, S. (2019), “Who Will Wait: The Client or the Employees?”, Ramesh, M., Grover, P. and Dasgupta, S. (Ed.) <em><i>Start-up Marketing Strategies in India</i></em>, Emerald Publishing Limited, Ch. 1, pp. 1-19.</a></li>
                                                    <li><a href="https://doi.org/10.1108/978-1-78756-755-920191003" target="_blank" rel="noreferrer">Dasgupta, S. and Jain, P. (2019), “Building Towers of Happy Clients: A Case of FinMen Advisors Private Ltd”, Ramesh, M., Grover, P. and Dasgupta, S. (Ed.) <em><i>Start-up Marketing Strategies in India</i></em>, Emerald Publishing Limited, Ch. 2, pp. 21-37.</a></li>
                                                    <li><a href="https://doi.org/10.1108/978-1-78756-755-920191004" target="_blank" rel="noreferrer">Dasgupta, S. and Grover, P. (2019), “A Road to Adventure with Happiness: From Students to Senior Citizens”, Ramesh, M., Grover, P. and Dasgupta, S. (Ed.) <em><i>Start-up Marketing Strategies in India</i></em>, Emerald Publishing Limited, Ch. 3, pp. 39-55.</a></li>
                                                    <li><a href="https://doi.org/10.1108/978-1-78756-755-920191005" target="_blank" rel="noreferrer">Dasgupta, S. and Ganguly, A. (2019), “Miles to Go But Which Road to Take: An Entrepreneurial Dilemma”, Ramesh, M., Grover, P. and Dasgupta, S. (Ed.) <em><i>Start-up Marketing Strategies in India</i></em>, Emerald Publishing Limited, Ch. 4, pp. 57-73.</a></li>
                                                    <li><a href="https://doi.org/10.1108/978-1-78756-755-920191006" target="_blank" rel="noreferrer">Grover, P. and Dasgupta, S. (2019), “Loyalty Comes with a Price: A Strategic Up-scaling from USP Loyalty Cards in India”, Ramesh, M., Grover, P. and Dasgupta, S. (Ed.) <em><i>Start-up Marketing Strategies in India</i></em>, Emerald Publishing Limited, Ch. 5, pp. 75-95.</a></li>
                                                    <li><a href="https://www.emerald.com/insight/publication/doi/10.1108/9781787567559">Ramesh, M., Grover, P. Dasgupta, S. (2019) Start-up Marketing strategies in India, edited book. Emerald Publication, USA, Publication Date:2019-07-23</a></li>
                                                    <li>Grover, P. Dasgupta, S. (2019) the impact of brand variance on gender in FMCG sector: with special reference to toothpaste category, In Dasguptaet. al. (ed.), <em><i>Holistic approaches to Brand culture and communication across industries</i></em>, pp. 24-35, Chapter 2, Hershey: IGI Global, DOI: 10.4018/978-1-5225-3150-0.ch002 <strong><b>(Scopus indexed)</b></strong></li>
                                                    <li><a href="https://www.igi-global.com/book/optimizing-millennial-consumer-engagement-mood/191127">Dasgupta, S.  Grover, P. (2018) Optimizing Millennial Consumer Engagement With Mood Analysis, a volume in the <em><i>Advances in Marketing, Customer Relationship Management, and E-Services, (AMCRMES) Book Series</i></em>, IGI Global., pp. 351, ISBN13: 9781522556909|ISBN10: 1522556907|EISBN13: 9781522556916|DOI: 10.4018/978-1- 5225-5690-9</a></li>
                                                    <li>Dasgupta, S., Biswal, S.  Ramesh, A. (2018) Holistic approaches to Brand culture and communication across industries, a volume in the <em><i>Advances in Marketing, Customer Relationship Management, and E-Services, (AMCRMES) Book Series</i></em>, IGI Global., pp. 281, ISBN13: 9781522531500 |ISBN10: 1522531505 |EISBN13: 9781522531517| DOI: 10.4018/978-1-5225-3150-0</li>
                                                    <li><a href="https://www.igi-global.com/book/holistic-approaches-brand-culture-communication/179831" target="_blank" rel="noreferrer">Thind, S. Dasgupta, S. (2018) Exploring gratification factors in visual food communication: with special reference to “South-Indian Cuisine”, In Dasguptaet. al. (ed.),<em><i>Holistic approaches to Brand culture and communication across industries</i></em>, pp. 53-70, Chapter 4, Hershey: IGI Global,DOI: 10.4018/978-1-5225-3150-0.ch004</a></li>
                                                    <li>Dasgupta, S.  Kothari, R. (2018) The impact of digital word-of-mouth communication on consumer decision making processes: with special reference to the fashion apparel industry, In Dasguptaet. al. (ed.),<em><i>Holistic approaches to Brand culture and communication across industries</i></em>, pp. 176-198, Chapter 10, Hershey: IGI Global, DOI: 10.4018/978-1-5225-3150-0.ch010</li>
                                                    <li>Dasgupta, S.  Grover, P. (2017) Understanding the Effectiveness of Social Media Marketing Mix (SMMM) on Consumer Purchase Influencer Stimulants with Special Reference to Apparel Sector, in <em><i>Book of Abstracts: 2017 IIM Indore-NASMEI Summer Marketing Conference, </i></em> 44, New Delhi: Emerald</li>
                                                    <li>Dasgupta, S (2014) Strengthening sustainability through the lenses of Corporate Social Responsibility concept: A conceptual study, <em><i>Technological Solutions for Sustainable</i></em><i></i><em><i>Business Practice in Asia</i></em>, IGI Global Chapter 4, DOI: 10.4018/978-1-4666- 8462-1.ch004, pp.60-82, <strong><b>ISBN13:</b></strong>9781466684621, <strong><b>ISBN10:</b></strong>1466684623, <strong><b>EISBN13:</b></strong>9781466684638 <strong><b>(Scopus indexed).</b></strong></li>
                                                </ul>
                                                <strong>Conference/Seminar/</strong><strong>Paper presentation</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Presented in an e-conference -International Conference on Advancement in Interdisciplinary Research (INCAIR-2020) to be held on July 22, 2020 in the Department of Applied Science, Sagar Institute of Research  Technology (SIRT) Bhopal, M.P., India.</li>
                                                    <li>2020 AMA Virtual Conference: Marketing in the New Normal held virtually from July 21-22, 2020</li>
                                                    <li>International Conference on Advancement in Interdisciplinary Research (INCAIR-2020) hosted at Sagar Institute of Research and Technology, Bhopal, India, online on July 22, 2020.</li>
                                                </ul>
                                                <br />
                                                <br />
                                                <strong>FDP:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>FDP on National Level FDP on case writing and case teaching. Speakers Prof. Rajendra v Nargudkar Prof. Sandip Anand Prof. Kumer Satyaki Ray, from IIM Indore, XIMB-Bhubaneswar, ICFAI Business School, on 07.08.2020- 08.08.2020 at Globsyn Business School.</li>
                                                    <li>FDP on the Role of Culture in Management Education in COVID-19 Period. Speaker Mr. Jawhar Sircar- Prasar Bharati  Ex-Cultural Secretary, Govt. of India and also a member of our Governing Council and IAS (Retd), Ex- CEO on 31.07.2020 at Globsyn Business School</li>
                                                    <li>FDP on Digital teaching  learning organized by AIMS 25.07.2020.</li>
                                                    <li>International FDP on How to teach online. Speaker Dr. David Bobker from Malaysia University of Science and Technology (Malaysia/United Kingdom) on 27.07.2020 at Globsyn Business School</li>
                                                </ul>
                                                <strong>MDP:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>MDP on Challenges and Latest Trends in Banking Sectors due to COVID 19 Impact, Organized by Globsyn Business School on 6.06.2020  07.06.2020 at Globsyn Business School.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingten">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseten">Prof. Sanjoy Mukerji</button>
                                        </h2>
                                        <div id="collapseten" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <strong>Conferences / Seminars / Event / Presentation: (International)</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>International Vision Seminar on Innovation and Entrepreneurship in Management Education organized by GBS on 24/25.02.2023 at BCCI Kolkata</li>
                                                </ul>
                                                <strong>Conferences / Seminars / Event / Presentation: (National)</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>CII Summit on Multimodal Logistics at ITC Sonar Kolkata on 17/18<sup>th</sup> Sept 2022</li>
                                                    <li>Meeting of MCCI Facilitation Cell on 21.09.2022 at Lalit Great Eastern, Kolkata</li>
                                                </ul>
                                                <strong>FDP:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>FDP on Achieving Operational Excellence by Digital Transformation of Supply Chain from 21.6.21 to 25.6.21 at RV College of Engineering, Bangaluru</li>
                                                </ul>
                                                <strong>MDP:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>MDP Program ‘Good to Great Managers: a Roadmap for Learning, Leading and Winning on 10.12.2022</li>
                                                    <li>MDP on Lean Construction for Project Professionals organized by Constask Management Solutions on 25.6.2021</li>
                                                </ul>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingfour">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefour">Prof. (Dr.) Mahuya Basu</button>
                                        </h2>
                                        <div id="collapsefour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <strong>Journals / Articles / Case Studies / Working Papers:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Mahuya Basu and Tanupa Chakraborty. ‘Risk Management through Weather Derivatives in Indian Power Sector: Perspective of an Indian Power Firm’ published in ‘IUP Journal of Applied Economics’ <strong>(ABDC indexed journal)</strong>. Vol 20 Issue 3 (2021).</li>
                                                    <li>Mahuya Basu and Tanupa Chakraborty. ‘Application of Monsoon Derivatives in Indian Hydroelectricity Sector: A Case Based Analysis’ – selected for publication ‘IUP Journal of Applied Economics’ <strong>(ABDC indexed journal)</strong>. Vol 21 Issue 4 Dec 2022</li>
                                                    <li>Mahuya Basu  Partha Sarthi Roy. ‘Innovation Funding: Role of Venture Capital and Stock Market Performance’ published in ‘Globsyn Management Journal’. Vol XVI (2022).</li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingfive">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefive">Prof. (Dr.) Rajib Bhattacharya</button>
                                        </h2>
                                        <div id="collapsefive" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <b>Journals / Articles / Case Studies / Working Papers:</b>
                                                <ul className='disc'>
                                                    <li>Bhattacharya, R., Das, A.,  Roy, S. (2022). Asymmetric Volatility And Volatility Spillover: A Study Of Major Cryptocurrencies. <i>Journal of </i><i>Commerce  Accounting Research</i>, <i>11</i>(1). (ISSN 2277-2146, <b>Indexed with: ABDCC</b>, Business Source Complete, Ulrich’s Web, Cabell’s Directory, i-scholar, Proquest)</li>
                                                </ul>
                                                <strong>Patents (As Co-Author):</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Patent Titled “FINANCIAL FRAUD APPS DETECTION BY USING DATA MINING OPTIMIZATION TECHNIQUES WITH MACHINE LEARNING”, Application No. 202231063461 A; The Patent Office Journal No. 45/2022 dated 11 November 2022;</li>
                                                    <li>Patent Titled “ARTIFICIAL INTELLIGENCE BASED APPROACH TO PREDICT THE STATISTICAL IMPACT OF INFLUENCERS FOR EXPANDING CLIENT BASE THROUGH INFLUENCER MARKETING TACTICS”, Application No. 202211067245; Since published on 28 November 2022;</li>
                                                    <li>Patent Titled “THE INDIAN STOCK MARKET: AN ANALYSIS OF INVESTORS’ FEAR, HOPES AND CHOICES”, Application No. 202241070144; Since published on 05 December 2022;</li>
                                                    <li>Patent Titled “IMPLEMENTING MACHINE LEARNING AND DEEP NEURAL NETWORKS TO ANALYZE STOCK MARKET TRENDS AND ASSESS THE ACCURACY OF MARKET PREDICTIONS”, Application No. 202341001183; Since published on 13 January 2023;</li>
                                                    <li>Patent Titled “AN IMPROVISED MODEL THAT USES MACHINE LEARNING FOR THE PURPOSE OF FORECASTING THE PRICE OF BITCOIN”, Application No. 202341001183; Since published on 13 January 2023</li>
                                                </ul>
                                                <strong>Research Papers (as Co-Author):</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Paper titled “ROBOTIC PROCESS AUTOMATION (RPA) IN ACCOUNTING AND AUDITING OF BUSINESS AND FINANCIAL INFORMATION” published in Manager – The British Journal of Administrative Management (ISSN1746-1278), Vol. 58, Issue: 157, Indexed with: ABDC-C</li>
                                                    <li>Paper titled “MODIFIED GREY WOLF OPTIMIZER WITH SPARSE AUTOENCODER FOR FINANCIAL CRISIS PREDICTION IN SMALL MARGINAL FIRMS”, published in ICEARS-2023 proceedings (IEEE, Indexed with Scopus)</li>
                                                </ul>
                                                <strong>Awards:</strong>
                                                <ul className='disc'>
                                                    <li>RESEARCH EXCELLENCE AWARD 2022 (awarded in February 2023) by Institute of Scholars (InSc), India</li>
                                                    <li>AWARD FOR CONTRIBUTION TO STUDENT DEVELOPMENT by Education Sensations, Indian Shiksha Awards (ISA) 2023</li>
                                                </ul>
                                                <strong>Reviewer  Membership:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Accepted as a reviewer of InSc International Publication Platforms and also for Technical Events under Basic and Medical Sciences</li>
                                                    <li>Professional Membership of InSc International Publishers</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingsix">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsesix">Prof. (Dr.) Kavita Shashtri</button>
                                        </h2>
                                        <div id="collapsesix" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <strong>Journals / Articles / Case Studies / Working Papers:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Shastri K., Hishaa R. Case Study of ITC-A Name Synonymous with Sustainability Kavita Shastri  Rajendra Misha. Globsyn Management Journal, Vol-XV (2021)</li>
                                                </ul>
                                                <strong>Participation/Presentations – Conferences / Seminars / Workshop:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>“Co-creating Sustainability and triple bottom line at ITC- A case study of a humane organization”, presented at International Conference. Sustainability – Collaboration, Compassion and Co-Creation-5th Nov 2012 at IIM Shillong.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingseven">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseseven">Prof. (Dr.) Swapna Datta Khan</button>
                                        </h2>
                                        <div id="collapseseven" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <b>Participation/Presentations – Conferences / Seminars / Workshop:</b>
                                                <ul className='disc'>
                                                    <li>Datta Khan, S. (2021). A Study of The Shift in Methodology of The Prediction of Demand at Traveler Destinations Post the Pandemic. <em>XXIVth Annual International Conference of the Society of Operations Management (SOM-2021) (Conference Presentation).</em> Jamshedpur: XLRI Jamshedpur.</li>
                                                    <li>Datta Khan, S. (2022). A Review of the Business Model Canvas in an Uncertain Environment. <em>International Conference on Role of ICT in Management and Business: Practices, Trends and Prospects (ICRIMB-2022)</em> (pp. 14 – 15). New Delhi: Apple Academic Press with Institute of Information Technology and Management, New Delhi. <strong><em>Cited.</em></strong></li>
                                                    <li>“A review of the Business Model Canvas in an Uncertain Environment” – International Conference Proceedings on ICT in Management and Business Practices, March 25-26, 2022, IIM Janakpuri, New Delhi.</li>
                                                </ul>
                                                <strong>Patent</strong> <strong>Publication</strong>
                                                <ul className='disc'>
                                                    <li>S. Datta Khan, R. Sarkar, R. Bhattacharya, S. M, S. Gaddam, S. R. Kar, S. P. Karmadkar, S. Sarkar, G. Singh and J. Chakraborti, “Financial Fraud Apps Detection by using data mining optimization techniques with Machine learning”. India Patent 202231063461 A, 11 Nov 2022.</li>
                                                    <li>N. M, S. Datta Khan, R. Bhat, D. MAHESH, S. Nath, S. P, M. K. Mishra, M. Prashant, F. Ahmad, H. Pallathadka and S. K, “SOCIAL NETWORKING SERVICE PLATFORM INFLUENCER MARKETING”. India Patent 202241064061, 25 Nov 2022.</li>
                                                    <li>S. Datta Khan, P. Chawla, A. Saxena, M. Arif, S. Dalal, V. K. Thota, A. Singh, S. O. Manjare, S. Nath, M. Prashant, K. Sivaperumal and H. Pallathadka, “Online marketing as a brand-building strategy for the banking industry”. India Patent 202231070168, 05 Dec 2022.</li>
                                                </ul>
                                                <strong>Journals / Articles / Case Studies / Working Papers:</strong>
                                                <ul className='disc'>
                                                    <li>V. Vijayabhaskar, A. Prithiviraj, D. Balamurugan, R. C. Narayanan, K. H. Prasad, S. Datta Khan, G. Purushothaman and B. Kannadasan, “Current Research and Future Potential of Blockchain Search Engines for IOT Networks,” NEUROQUANTOLOGY <strong>(Scopus – Q3)</strong>, vol. 20, no. 10, pp. 120 – 150, 11 Oct 2022.</li>
                                                    <li>S. Datta Khan, “Gauging the service quality of App Cabs, post COVID-19: Associating sets of underlying constructs,” in <em>Advances in Management Research: Emerging Challenges  Trends</em>, D. Chanda, A. Sengupta and D. Mohanti, Eds., <strong>Routledge, Taylor  Francis Group</strong>, 2023, pp. 18 – 28.</li>
                                                    <li>S. Datta Khan, S. Sekar, S. Kowsalya, N. Rajesh and Khushboo, “Brand Equity Dimensions on Brand Trust and Brand Satisfaction: A Study in a Private Hospital,” <em>International Journal of Food and Nutritional Sciences (<strong>UGC CARE Listed (Group -I) Journal</strong>), </em>vol. 11, no. S Iss3, pp. 1989 – 2000, Dec 2022.</li>
                                                    <li>S. Datta Khan, “Identifying what drove Consumer Satisfaction at Domestic Air Terminals in India during the COVID Era: An Exploratory Study,” <em>Kindler, </em>vol. XXII, no. 1  2, pp. 22 – 37, 2022. <strong><em>SJIF 8.247; I2OR 6.002</em></strong></li>
                                                    <li>S. Datta Khan, S. Wankhede, C. L. Nath, B. Haralayya, B. Khan and P. V. Krishna, “A Study on Service Quality Dimensions and Factors Affecting Customer Satisfaction in E-Banking Functionality,” <em>Korea Review of International Studies, </em>vol. 15, no. 41, pp. 119 – 133, Dec 2022. <strong><em>ABDC C</em></strong></li>
                                                    <li>S. Datta Khan, M. H. Behin, K. K. K. Varma, R. R, S. R and A. Chauhan, “Recent Trends in Green Supply Chain Management Practices – A Review,” <em>Korea Review of International Studies, </em>vol. 16, no. 42, pp. 121 – 138, Jan 2023. <strong><em>ABDC C</em></strong></li>
                                                    <li>S. Datta Khan and R. Bhattacharya, “SIMO Research: Where Excellence Enables the Proliferation of Engineering Expertise,” <em>Globsyn Management Journal, </em>vol. XVI, no. 12, pp. 92 – 106, Jan – Dec 2022 <strong><em>EBSCO Listed</em></strong></li>
                                                    <li>S. Datta Khan, R. A. Qureshi, C. R. Matawale, P. Gupta, A. Namde and D. R, “An Investigation of Identifying Risk Factors Affecting Consumer Purchase Behavior In Online Shopping,” <em>Korea Review of International Studies, </em>vol. 16, no. 42, pp. 107 – 120, Jan 2023. <strong><em>ABDC C</em></strong></li>
                                                </ul>
                                                <strong>Book Review:</strong>
                                                <ul className='disc'>
                                                    <li>A Review of the book “<strong>Made in Future: A Story of Marketing, Media  Content for Our Times” by Prashant Kumar (ISBN 9780670096244, published by Penguin Business, Penguin Random House India 2022) published in Globsyn Management Journal Vol XVI, Issue 12 Jan – Dec 2022</strong></li>
                                                </ul>
                                                <strong>Panelist at a Panel Discussion</strong>
                                                <ul className='disc'>
                                                    <li>Panellist at Panel “Diversity and Inclusion – Embrace the differences and celebrate the similarities” at UDAAN 2023: Innovation Carnival of Tata Metaliks at Kharagpur, India on 02 Mar 23</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingeight">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseeight">Dr. Darshana Bhagowati</button>
                                        </h2>
                                        <div id="collapseeight" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <strong>Journal article:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Bhagowati, D.  Dutta, D.M. (2018). A Study on Literature Review for Identifying the Factors Impacting Digital Marketing. International Journal of Marketing Management Research and Development. ISSN: 2249-8044, Vol.8,Issue 3, Impact Factor 7.08.</li>
                                                    <li>Bhagowati, D. (2020). A qualitative study on the factors affecting the pre purchase decision of the management students in Kolkata. Juni Khyat. ISSN: 2278-4632, Vol.10, Issue 7 No. 9, Impact Factor 6.625.</li>
                                                    <li>Bhagowati, D.  Dutta, D.M. (2022). A Study on Prioritizing the Digital Sources which Impacts the Pre Purchase Selection of the Universities and the Institutes offering Management and Related Courses in Kolkata. International Journal of Sales and Marketing Management. ISSN: 2319-4901,Vol.11, Issue 1, Impact Factor 6.72.</li>
                                                </ul>
                                                <strong>Conference presentation:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>International conference on Management Research on the theme Chaos, complexity and Resilience: Business and Society in Pandemic context hosted by KIIT School of Management Bhubaneshwar on 26<sup>th</sup> February 2022.</li>
                                                    <li>National conference on Paradigm shift towards Sustainable Management Practices organized by Department of Management, J.D.Birla Institute, Kolkata on 11<sup>th</sup> March 2022</li>
                                                    <li>Awarded the Best Paper in Marketing Track in National conference on Paradigm shift towards Sustainable Management Practices organized by Department of Management, J.D.Birla Institute, Kolkata on 11<sup>th</sup> March 2022.</li>
                                                </ul>
                                                <strong>FDP:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>FDP on Application of Software Tools in Research Methodology organized by Amity Business School, Amity university Kolkata on 27<sup>th</sup> June to 1<sup>st</sup> July 2020.</li>
                                                    <li>FDP on Contemporary Trends in Business Management  Research – Part I organized by Amity Business School, Amity university Kolkata on 25<sup>th</sup> July to 1<sup>st</sup> August 2022.</li>
                                                    <li>FDP on Future of Management Education in Developing Countries organized by Globsyn Business School on 10<sup>th</sup> March, 2023.</li>
                                                </ul>
                                                <strong>MDP:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>MDP on Good to Great Managers: A road Map for Learning, Leading and Winning organized by Globsyn Business School on 10<sup>th</sup> December 2022.</li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingnine">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsenine">Prof. Manas Chakravarty</button>
                                        </h2>
                                        <div id="collapsenine" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <strong>Papers</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>‘Managing Career Blues: Succour and Solace from Urdu Couplets’ published on SSRN on 28 January 2020.</li>
                                                    <li>‘Chief Justice of India – Appointments and Disappointments Threatening Judicial Independence’&nbsp;submitted in July 2020.</li>
                                                    <li>Self-Research entitled “Managing Career Blues: Succour and Solace from Urdu Couplets”. Published at SSRN on January 2020.</li>
                                                    <li>Review of the book entitled “The Future is Asian: Commerce, Conflict, and Culture in the 21<sup>st</sup>Century”&nbsp;by Parag Khanna, Simon &amp; Schuster, New York, NY.To be published in Globsyn Management Journal, 2020 issue.</li>
                                                    <li><b></b>Self-Research in the domain of selection and appointment.Under process. To be completed in June, 2020.</li>
                                                    <li>“Fault Lines in the Legal Framework of Industrial Relations,”Economic &amp; Political Weekly, December 23, 2017, 14-19.</li>
                                                    <li>“Taking an Expansive View on What Constitutes Political Instability for Global Operations,” International Conference on Globalization – The Way Ahead, XIMR, St. Xavier’s College, Mumbai, and March 2013 (co-authored; won the best paper award).</li>
                                                </ul>
                                                <strong>Book Review:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Dancing With the Devil: A Review of Nicole Perlroth This Is How They Tell Me the World Ends by Manas Chakravarty,&nbsp;<em>Globsyn Management&nbsp;Journal</em>&nbsp;Vol. XVI, December 2022</li>
                                                    <li>FROM “INSTAGRAM” TO “INSTAGRAM FROM FACEBOOK”. Book review of Sarah Frier’s No Filter, Globsyn Management Journal, Vol. XV (2021).</li>
                                                    <li>Review of the book ‘The Future is Asian: Commerce, Conflict, and Culture in the 21st Century’ by Parag Khanna, Simon &amp; Schuster, New York, NY published in Globsyn Management Journal, 2020 issue.</li>
                                                    <li>Chakravarty M, “The Future is Asian”, Globsyn Management Journal, Vol. XIII, Issue 1 &amp; 2, January – December 2019.</li>
                                                </ul>
                                                <strong>FDP / MDP:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>FDP on Mordern Teaching and Learning Pedagogies for Management Education(MUST), Speaker Dr.Prem Kumar Rajagopal from Malaysia University of Science &amp; Technology on 11.03.2019 at Globsyn Business school.</li>
                                                    <li>FDP on Building a Sustainable Scholarly Career: Connecting The Dots!<strong>,</strong>Speaker Prof.MahendhiranSanggaran Nair from Monash University Malaysia on 03.10.2019 at Globsyn Business school.</li>
                                                    <li>FDP on Learning with cases, Speaker <strong><b>: </b></strong>SabyasachiDasgupta from O. P. Jindal Global University on 9.01.2020 at Globsyn Business School</li>
                                                    <li>FDP on Outcome Based Learning, Speaker <strong><b>: </b></strong>SabyasachiDasgupta from O. P. Jindal Global University on 10.01.2020 at Globsyn Business School</li>
                                                    <li>‘Case Writing and Case Teaching’ conducted by Prof. Rajendra V Nargudkar (IIM Indore), Prof. Sandip Anand (XIMB) and Prof. Kumar Satyaki Ray (IBS Kolkata), on 7 &amp; 8 August 2020 at Globsyn Business School.</li>
                                                    <li>‘Role of Culture in Management Education in COVID-19 Period’ conducted by Mr. Jawhar Sircar (IAS Retd) (Formerly CEO, Prasar Bharati and Cultural Secretary, Govt. of India) on 31 July 2020 at Globsyn Business School</li>
                                                    <li>‘How to Teach Online’ conducted by Dr. David Bobker MUST, Malaysia/United Kingdom) on 27 July 2020 at Globsyn Business School</li>
                                                    <li>‘Digital Teaching &amp; Learning’ organized by AIMS on 25July 2020.</li>
                                                </ul>
                                                <strong>Professional Associations</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>All India Management Association (AIMA): &nbsp;Member</li>
                                                    <li>Society for Human Resource Management (SHRM India): &nbsp;Member</li>
                                                    <li>National HRD Network (NHRDN): &nbsp;Member</li>
                                                    <li>International Labour and Employment Relations Association (ILO, Geneva): &nbsp;Associate Member</li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingeleven">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseeleven">Prof. Kaushik Ghosh</button>
                                        </h2>
                                        <div id="collapseeleven" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <strong>Book Review</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>‘Good Karma’, Book review of Jaya Row’s ‘Good Karma: Make the Right Choices for Tomorrow’, Globsyn Management Journal, Vol. XV (2021).</li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingtwelve">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsetwelve">Prof. (Dr.) Ranita Basu</button>
                                        </h2>
                                        <div id="collapsetwelve" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <strong>Journals / Articles / Case Studies / Working Papers:</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>A research paper entitled ‘Impact Of Digital Detox On Individual Performance Of The Employees’ was published in International Journal of Research and Analytical Reviews (IJRAR) [E-ISSN: 2348-1269, Vol 6, Issue 2, May 2019].</li>
                                                    <li>A research paper entitled ‘Employee engagement and work satisfaction: a study on employees engaged in various sectors’ was published in International Journal of Management, Technology And Engineering [ISSN: 2249-7455, Vol 9, Issue 5, May 2019].</li>
                                                </ul>
                                                <br />
                                                <ul className='disc'>
                                                    <li>Ranita Basu, “How Organizational Stress is Affecting the Family Satisfaction of IT Workers”, International Journal of Creative Research Thoughts, Vol. 6, Issue-2, Seen: 2320-2882, April, 2018</li>
                                                    <li>Ranita Basu, “Emotional Intelligence and Work Satisfaction – A study on the employees working in various organizations”, International Journal Research Publications (Research Journal of Commerce &amp; Behavioural Science-RJCBS) [Vol. 6, No. 2 (2016): 01 December 2016; ISSN 2251-1547]</li>
                                                    <li>Ranita Basu, a research paper entitled, “How Work-place Anxiety Affects the Work and Family Satisfaction of the Employees working in Private Sectors”, International Education and Research Journal [E ISSN No.: 2454-9916 | Vol. : 2 | Issue : 2 | Feb. 2016]</li>
                                                    <li>Ranita Basu, “Perceived Differences in Work-Family Conflict and Self-efficacy of Employees working in Private and Public Sector Enterprises”, Indian Institute of Psychometry on January-June session, 2010</li>
                                                </ul>
                                                <strong>GOOGLE SCHOLAR CITATION [ scholar.google.co.in]</strong>
                                                <br />
                                                <br />
                                                <p>Web URL: <a href="http://ierj.in/journal/index.php/ierj/article/view/151" target="_blank" rel="noopener noreferrer">http://ierj.in/journal/index.php/ierj/article/view/151</a></p>
                                                <strong>Workshop, Conference, Webinar and Seminar Attended</strong>
                                                <br />
                                                <br />
                                                <ul className='disc'>
                                                    <li>Attended one day workshop on ‘child and adolescent development on 11January2007.</li>
                                                    <li>Attended the 12 international and 43 national conferences of the Indian academy of applied psychology, from 7-9 February, 2008.</li>
                                                    <li>Attended one day summer training on small sample data analysis by Microsoft Excel, on 01st August, 2009.</li>
                                                    <li>Attended workshop on music in light of ‘150 year birth anniversary celebration of Rabindranath Tagore on 26th November, 2010.</li>
                                                    <li>Attended workshop on practical syllabus of 1st, 2nd and 3rd year of undergraduate students on 31st January, 2011.</li>
                                                    <li>Attended a workshop on faculty development program on 19/01/18.</li>
                                                    <li>Attended a faculty development workshop on 6th and 7th February 2018.</li>
                                                    <li>Attended Globsyn management conference on 9th March, 2018.</li>
                                                    <li>Attended and Coordinated a Seminar on ‘Women Empowerment’ in June,2018.</li>
                                                    <li>Attended a webinar on ‘Building Economic and Business Resilience, Agility and Sustainability in the post COVID – 19 era’, organised by Globsyn Business School, on 20th of June, 2020.</li>
                                                    <li>Participated in one day webinar on ‘Dealing with post Covid challenges through biopsychosocial lens – Managing our thoughts, emotions and behaviour’ organised by Department of Psychology and IQAC of Seth Anandram Jaipuria College on 29.06.2020.</li>
                                                    <li>Participated in the Webinar titled ‘Leadership Skills for Crisis Management’, organized by the NAMAH Foundation, on July 13, 2020.</li>
                                                    <li>Attended a ‘Vice Chancellor’s Roundtable’ webinar on’ The Future of Higher Education in the New Era’, organised by Globsyn Business School, on August 01, 2020.</li>
                                                </ul>


                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
