import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../Sidebar'

export default function Corporate() {
    return (
        <><title>Corporate Connect</title>
            <section className="hero-banner-img-pgdm">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Corporate Connect</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/technology">Corporate Connect</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>

                            </div>
                            <div className="one-table">
                                <p>The Corporate Connect program is a strategic initiative undertaken by Globsyn Business School to integrate various facets of real-life corporate experiences into the curriculum. As a part of this uniquely designed initiative corporate managers share their real-life corporate and business experiences with students not as standalone ‘experience’ sharing sessions but as integral part of the curriculum.</p>
                                <p>The Corporate Connect program invites senior level executives, entrepreneurs and eminent industry experts from various fields and industry sectors to share their practical experiences in the context of theoretical discourses the students have undergone.</p>
                                <p>Listed below are some of the recent sessions that took place:</p>
                                <div className="accordion" id="myAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Financial Management & Corporate Finance</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <div className="one-table">
                                                    <div className="one-table1"><img className="img-fluid" src="./assets/images/Mr.-Sanjay-Kumar-Shrivastav.jpg" alt="sanjay-kumar" width="150" height="169" /></div>
                                                    <div className="one-table2">
                                                        <p><strong>Date:</strong> April 20, 2022
                                                            <br /><strong>Course:</strong> Financial Management & Corporate Finance
                                                            <br /><strong>Theme/Topic:</strong> Techniques of Strategic Cost Management
                                                            <br /><strong>Name of Guest Speaker:</strong> Mr. Sanjay Kumar Shrivastav, Joint CFO, Tata Steel Long Products Ltd.
                                                            <br /><strong>Designation/Short Profile:</strong>Before taking over as Joint CFO, Tata Steel Long Products Ltd., Mr. Shrivastav had been the CFO of Tinplate Company of India Ltd. Prior to that he held prestigious positions like Head – Business Analysis, Raw Materials Division, Chief – Cost Research and Standard Costing and Chief – Business Analysis, Steel Manufacturing of Tata Steel Ltd. He is qualified both as Company Secretary and Cost & Management Accountant. He secured the All India 5th Position in the final examination of The Institute of Cost Accountants of India. He is also a TOCICO Certified Practitioner (Supply Chain – Logistics). He is also skilled in Six-Sigma, Business Planning & Management.</p>
                                                        <p><strong>Event  Description:</strong> The event was conducted on an online mode. It started at 3:00 PM. The speaker explained the concept, importance and applied aspects of the topic in a lucid manner to the students. He cited numerous examples of the applied aspects as practiced in his organization. He also cited some benchmark practices on a global basis. He explained how the topic relates to the vision and mission statements of corporate organizations. His deliberation contained valuable inputs for management graduates. He elucidated that mere cost-cutting may yield short-term benefits but is distinctly separate from strategic Cost Management which is aimed at sustainable development of the organization vis-à-vis that of the stakeholders and the environment. His deliberation was followed numerous questions and queries by the students. Each and every of the queries were patiently answered by Mr. Shrivastav. The session was so engaging and interesting that it continued for half an hour more than the scheduled time limit of one hour.</p>
                                                        <p><strong>Summary of the subject and few main points of the speech:</strong><br />In today’s highly volatile global business and economic environment, enterprises worldwide are facing increased challenges, both externally and internally, to sustain profitable growth. Under rapidly changing market conditions, the ability to reduce operating costs and allocate budgets correctly is paramount for organizations to survive and grow. Strategic Cost Management enables leadership teams to reduce expenses in categories that yield little impact, and optimize spend in areas that drive sustainable growth. Strategic Cost Management programs can help identify and eliminate such expenses which do not add value. Thus it assists in freeing up cash and other resources that can be invested to drive organizational growth as well as to enhance shareholder ROI and corporate value.</p>
                                                        <p>The key takeaways from the session for the students are as below:</p>
                                                        <ol>
                                                            <li>Stakeholders’ expectations and increasing competition and dynamism in business, both in domestic and global business environment, exert constant pressure on the profit margins;</li>
                                                            <li>Margins are the only source of meeting the diverse expectations of the stakeholders;</li>
                                                            <li>The only way out is to apply suitable Cost Management Techniques which aim to eliminate wasteful expenses which do not create any value, but to maintain or if possible to enhance quality of product and service;</li>
                                                            <li>These set of suitable Cost Management Techniques are called Strategic Cost Management Techniques as it is aligned with the strategy of the organization defined by its vision and mission statements;</li>
                                                            <li>Most commonly used Strategic Cost Management techniques include Life Cycle Costing, Target Costing, Activity Based Costing, Total Quality Management, Six Sigma, Balanced Score Card, Kaizen Costing, JIT Inventory Control System, Theory of Constraints, Business Process Reengineering, Benchmarking etc.</li>
                                                            <li>Strategic Cost Management is a continuous and cyclical process involving planning, executing, controlling and re-planning;</li>
                                                            <li>Retrenchment is not an advisable Strategic Cost management Techniques as it deprives the organization of valuable resources; up-skilling and proper deployment is the key in the matter;</li>
                                                            <li>Excessive focus on short-term objectives i.e. managerial myopia is a serious deterrent for applying Strategic Cost Management techniques as Strategic Cost Management primarily addresses long-term objectives of the organization</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingtwo">
                                            <button type="button" className="accordion-button collapsed " data-bs-toggle="collapse" data-bs-target="#collapsetwo">Marketing</button>
                                        </h2>
                                        <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <div className="one-table">
                                                    <div className="one-table1"><img className="img-fluid" src="./assets/images/Mr.-Rahul-Mishra.jpg" alt="sanjay-kumar" width="150" height="169" /></div>
                                                    <div className="one-table2">
                                                        <p><strong>Date:</strong> March 21, 2022
                                                            <br /><strong>Course:</strong> Marketing
                                                            <br /><strong>Theme/Topic:</strong> ‘B2B Commerce from a Global Perspective’
                                                            <br /><strong>Name of Guest Speaker:</strong> Mr. Rahul Mishra (Chief of Marketing and Sales of Long Products at TATA Steel).
                                                            <br /><strong>Designation/Short Profile:</strong>Before taking over as Joint CFO, Tata Steel Long Products Ltd., Mr. Shrivastav had been the CFO of Tinplate Company of India Ltd. Prior to that he held prestigious positions like Head – Business Analysis, Raw Materials Division, Chief – Cost Research and Standard Costing and Chief – Business Analysis, Steel Manufacturing of Tata Steel Ltd. He is qualified both as Company Secretary and Cost & Management Accountant. He secured the All India 5th Position in the final examination of The Institute of Cost Accountants of India. He is also a TOCICO Certified Practitioner (Supply Chain – Logistics). He is also skilled in Six-Sigma, Business Planning & Management.</p>
                                                        <p><strong>Event  Description:</strong> The event was conducted on an online mode. It started at 3:00 PM. The speaker explained the concept, importance and applied aspects of the topic in a lucid manner to the students. He cited numerous examples of the applied aspects as practiced in his organization. He also cited some benchmark practices on a global basis. He explained how the topic relates to the vision and mission statements of corporate organizations. His deliberation contained valuable inputs for management graduates. He elucidated that mere cost-cutting may yield short-term benefits but is distinctly separate from strategic Cost Management which is aimed at sustainable development of the organization vis-à-vis that of the stakeholders and the environment. His deliberation was followed numerous questions and queries by the students. Each and every of the queries were patiently answered by Mr. Shrivastav. The session was so engaging and interesting that it continued for half an hour more than the scheduled time limit of one hour.</p>
                                                        <p><strong>Summary of the subject and few main points of the speech:</strong><br />In today’s highly volatile global business and economic environment, enterprises worldwide are facing increased challenges, both externally and internally, to sustain profitable growth. Under rapidly changing market conditions, the ability to reduce operating costs and allocate budgets correctly is paramount for organizations to survive and grow. Strategic Cost Management enables leadership teams to reduce expenses in categories that yield little impact, and optimize spend in areas that drive sustainable growth. Strategic Cost Management programs can help identify and eliminate such expenses which do not add value. Thus it assists in freeing up cash and other resources that can be invested to drive organizational growth as well as to enhance shareholder ROI and corporate value.</p>
                                                        <p>The key takeaways from the session for the students are as below:</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingthree">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Managerial Economics & Global Business Environment</button>
                                        </h2>
                                        <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <div className="one-table">
                                                    <div className="one-table1"><img className="img-fluid" src="./assets/images/Mr.-Anindo-Majumdar.jpg" alt="sanjay-kumar" width="150" height="169" /></div>
                                                    <div className="one-table2">
                                                        <p><strong>Date:</strong> January 10, 2022
                                                            <br /><strong>Course:</strong> Managerial Economics & Global Business Environment
                                                            <br /><strong>Theme/Topic:</strong> Present Economic Scenario In The Country
                                                            <br /><strong>Name of Guest Speaker:</strong> Present Economic Scenario In The Country
                                                            <br /><strong>Designation/Short Profile:</strong>IAS(Retd), Government Of India</p>
                                                        <p><strong>Profile Of The Guest Speaker:</strong> A retired officer of the Indian Administrative Service (IAS), Mr Anindo Majumdar has nearly thirty five years of rich and varied experience in different areas of public administration such as planning and policy formulation, strategic management, programme implementation, rural development, education, financial management, human resource management etc.</p>
                                                        <p>Mr. Anindo Majumdar has also worked with International Organizations such as UNDP and UNOPS. He has worked in the United Nations Development Programme (UNDP) in Afghanistan as a Capacity Development Advisor in the National Institution Building Project (NIBP) during the period of July 2011 to May 2013 and also as a member of a supervision mission fielded by UNOPS in Bhutan in June 2002.</p>
                                                        <p>He has held several leadership positions in the Government such as the Chief Secretary, Andaman& Nicobar Administration, Secretary, Central Vigilance Commission, Principal Secretary, Joint Secretary, Department of Agriculture etc.</p>
                                                        <p>He holds an MBA degree from the University of Hull, UK and a MA degree in Economics from the University of Delhi. In addition, to these he has attended several executive development programmes at leading institutions in India and abroad.</p>
                                                        <p>He has delivered lectures in service training programmes conducted by leading training institutions such as LalBahadurShastri National Academy of Administration (LBSNAA), Mussorie, Sardar Vallabhai Patel National Police Academy (SVPNPA), Hyderabad, Indian Institute of Public Administration, New Delhi, International Centre for Environment Audit and Sustainable Development, Jaipur, Indian Railway Signals Training Centre, Hyderabad, National Academy of Defence Production (NADP), Nagpur, UCO Bank officers’ training centre etc. Recently he delivered a lecture on “Ethics in everyday life” in Ambedkar University, New Delhi.</p>
                                                        <p>Mr Anindo Majumdar retired from Government of India on 31st August 2020.</p>
                                                        <p><strong>Brief Discussion Of The Speech:<br /></strong>The speech majorly focused on the journey of Indian economy through colonial era till the present date and how it affected the various strata of the Indian Society.With a degraded economy low,capital formation because of poor infrastructure with large sections of people living in abject poverty was of major concern and how it transcended to the onset of Centralized planning in India was discussed in details. Heavy Engineering plants being set up and lot of investments in technology, green revolution and further more white revolution was as per its nomenclature revolutionary. The negative aspects were not missed as well which included Heavy bureaucratization, licence permit control, making difficult for people to make business in India making the then India as place for regulated economy.</p>
                                                        <p>With the embarking of liberalisation acting as a catalyst positive developments in poverty and employment came up making it to be a historical period. With attractive Foreign Direct Investmentscoming up India was in the process of dismantling the regulated economy</p>
                                                        <p>Coming into the current stages, GDP (purchasing power parity and nominal terms), food security, have gotten better but of course the population growth rate being a major concern. Nonetheless as per recent data Indian Economy has been ranked 6th in terms of nominal terms and size, whereas 3rd in terms of Purchasing power which is a fairly well scenario. Stepping on to the problematic areas that are unemployment, gender inequality, increment of poverty, Human Development Index, Hunger Index,Inequality in income and distribution unequal distribution of wealth and how middle, micro and small enterprises being the backbone of industries had almost stopped operating and how the situation aggravated furthermore due to the COVID-19 pandemic</p>
                                                        <p>With Not only Structural problemsfacing a downfall nation’s stability is and might be breaking down to threat leading to a moral and ethical issue. At the very end he went over the sustainable development goals with great precision.</p>
                                                        <p>With a positive note he ended the enlightening session by stating that how economy is all about partnerships in society. As and as partnerships should more to be done with institutions, organisations, civil society and not just with government.  Today man can’t live in isolation today and so should economy work hand in hand.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingfive">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefive">Management Function & Organizational Behavior</button>
                                        </h2>
                                        <div id="collapsefive" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <div className="one-table">
                                                    <div className="one-table1"><img className="img-fluid" src="./assets/images/Mr.-Sujoy-Banerjee.jpg" alt="sanjay-kumar" width="150" height="169" /></div>
                                                    <div className="one-table2">
                                                        <p><strong>Date:</strong> December 7, 2021
                                                            <br /><strong>Course:</strong> Management Function & Organizational Behavior
                                                            <br /><strong>Theme/Topic:</strong> Group Dynamics & Conflict
                                                            <br /><strong>Name of Guest Speaker:</strong> Mr. Sujoy Banerjee
                                                            <br /><strong>Designation: </strong>Group Chief People Officer & Group Head – Marketing
                                                            <br /><strong>Short Profile:</strong>He is an HR professional with 31 years of work experience across various industries like: Automobiles, IT etc. He is currently the group chief people Officer and Group Head Marketing at Ganiwell Common sales Private ltd. He is also a member of CII (Eastern Region) Sub committee</p>
                                                        <p><strong>Event Description: </strong> The session was started by a brief introduction done by Shaoli madam. Then Dr. Debprasad (DPC sir) welcomed our guest speaker Mr. Sujoy Banerjee, one of my colleagues Namrata Dutta (section A) introduced him and gave us a detail description about his profile. At last, there was an interaction session. Finally, the whole session ended with a kind vote of Thanks given by Ohik (section C).</p>
                                                        <p><strong>Summary of the subject and few main points of the speech:<br /></strong>Such an interactive and insightful session it was to have such a great personality Infront of us Mr. Sujoy Banerjee, He is an HR professional and currently he is the group “Chief People Officer & Group Head Marketing “at Ganiwell Common sales PVT Ltd. His admiring personality and his interactive nature were very pleasant. He delivered his thoughts on one of the most interesting topics entitled “Group Dynamics & Conflicts”. He gave us the history about group dynamics which refers to the forces operating in groups. He gave us an idea about group dynamics and also its importance in every day’s life, whether it comes to decision making or tracking social diseases like racism, sexism etc. groups dynamics plays a pivotal role He taught us the importance of 3 step model also i.e., Unfreeze (come out of your comfort zone), Change (Adoption to new environment), Refreeze. The idea of inter and intra group dynamics was also explained by him. He made us understand the importance of groups, Different types of groups the group conflicts and how to resolve those conflicts. He also gave a detailed understanding about the stages involved in group formation and the concept of group cohesiveness too. I personally got to learn a lot from him.</p>
                                                        <p>It was a very interactive and informative session not only for students but for the faculties too. We would like to have such more sessions that would not only enhance our knowledge rather it would shape our personality too.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingsix">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsesix">Computer Application</button>
                                        </h2>
                                        <div id="collapsesix" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <div className="one-table">
                                                    <div className="one-table1"><img className="img-fluid" src="./assets/images/Mr.-Sandeep-Sengupta.jpg" alt="sanjay-kumar" width="150" height="169" /></div>
                                                    <div className="one-table2">
                                                        <p><strong>Date:</strong> December 1, 2021
                                                            <br /><strong>Course:</strong> Computer Application
                                                            <br /><strong>Theme/Topic:</strong> Cyber Security
                                                            <br /><strong>Name of Guest Speaker:</strong> Mr. Sandeep Sengupta
                                                            <br /><strong>Designation:</strong>Founder and Director of ISOAH
                                                            <br /><strong>Short Profile:</strong>Mr. Sandeep Sengupta is the Founder and Director of ISOAH Data Securities Pvt. Ltd. Indian School of Anti Hacking has more than 15 years of experience in IT Security Industry working with Governments, law enforcement agencies and corporate clients. He is a lead auditor for Information Security and a Certified Ethical Hacker and has an experience of more than 20 yrs. in helping MNCs, SME, and startups on information security best practices, in India and abroad. Companies Mr. Sandeep Sengupta has audited are Bangladesh National Data Center, Robi Telecom, PWC, KPMG, TATA Steel, Airtel, Vodafone, National Power Grid, Bandhan Bank, PayU and many more. His domain of expertise is in Vulnerability Assessment & Penetration Testing, ISMS Audit, Privacy Audit, GDPR Gap Assessment, Risk Assessment and mitigation, Disaster Recovery, Information Security Training, etc.. He has also appeared on news channels like India Today, NDTV, ABP Ananda, Door darshan and News18.</p>
                                                        <p><strong>Event Description: </strong> The corporate connect was held by Globsyn Business School on Cyber Security by Mr. Sandeep Sengupta. Cyber security is a way to monitor and detect security breaches in a computer or a computer network. Mr. Sengupta enlightened all the attendees with several cyber-crimes, data privacy and cookies. He also mentioned the impact of cyber security on organizational operations as well as on individuals. Mr. Sengupta provided the audience many website links through which one can learn and have knowledge regarding information security management systems and various other courses.</p>
                                                        <p><strong>Summary of the subject and few main points of the speech:</strong></p>
                                                        <ol>
                                                            <li>Sengupta elaborated and enlightened various cyber-crimes cases, something related to data privacy and the most important jobs requirement in this cyber security world.</li>
                                                            <li>The speaker started the session by giving a demonstration on how one can protect their Facebook and Gmail account from security breach</li>
                                                            <li>The speaker also mentioned how individuals are getting trapped through various apps and their data are getting stolen.</li>
                                                            <li>Sengupta suggested 2 things in his address related to cyber security:
                                                                <ul className='disc'>
                                                                    <li>Type of cyber frauds</li>
                                                                    <li>How one can be protected from cyber frauds</li>
                                                                </ul>
                                                            </li>
                                                            <li>Sengupta addressed Globsynites by influencing them not to get trapped in any of the cyber fraud or crimes and in case if anyone is suffering from any frauds, he shares various common data privacy steps through which one can get rid of.</li>
                                                            <li>Sengupta’s main point of discussion was regarding the jobs in this cyber security world. We have now only 90,000 expertises in this field but till 2030 the requirement of expertise will be one million.</li>
                                                            <li>Sengupta emphasized that cyber security plays an important role for both, the organizations and the individuals.</li>
                                                            <li>At the end the speaker mentioned about the technologies one should have skill on to get a job like Information security management system, Python, C++, Ethical hacking etc.</li>
                                                            <li>Also the speaker provided the following links one can go through to augment knowledge on cyber security and data privacy: </li>
                                                            <ul className='disc'>
                                                                <li>https://www.isoeh.com/tutorials.html</li>
                                                                <li>https://www.youtube.com/c/ISOEHIndianSchoolofEthicalHacking/videos</li>
                                                                <li>https://www.splunk.com/en_us/training/courses/splunk-fundamentals-1.html</li>
                                                                <li>https://www.facebook.com/Sandy.sengupta/</li>
                                                                <li>https://www.isoeh.com/handbook.pdf</li>
                                                            </ul>
                                                        </ol>
                                                        <p>It was indeed a great session in which we all learnt how the environment around is changing. Many jobs will be lost in the coming years if we don’t learn the new technology and adapt to the changes. We are grateful to Mr. Sandeep Sengupta for taking time out and coming to our campus and sharing his views and knowledge with us in regards to Cyber Security. We look forward to have Sir among with us in the future.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingseven">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseseven">Financial, Cost & Management Accounting</button>
                                        </h2>
                                        <div id="collapseseven" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <div className="one-table">
                                                    <div className="one-table1"><img className="img-fluid" src="./assets/images/Mr.-Aditya-Sharma.jpg" alt="sanjay-kumar" width="150" height="169" /></div>
                                                    <div className="one-table2">
                                                        <p><strong>Date:</strong> Novenmber 19, 2021
                                                            <br /><strong>Course:</strong> Financial, Cost & Management Accounting
                                                            <br /><strong>Theme/Topic:</strong> Accounting Made Easy
                                                            <br /><strong>Name of Guest Speaker:</strong> Mr. Aditya Sharma
                                                            <br /><strong>Designation:</strong>Director of Strategy, Senior Vice President – Asia, Boson Energy, Luxembourg
                                                            <br /><strong>Brief Description:</strong>Mr. Sharma develops Asia market for producing Hydrogen from local solid fuels (non-recyclable waste, biomass) through gasification, delivered in dispense-ready mode, suitable for mobility sector or industrial applications. He actively contributes to forums on energy and water at local or international levels. He is a Sherpa Member of the Roundtable on Sustainable and Low-Carbon Hydrogen Production set up by European Clean Hydrogen Alliance to develop roadmap for implementation of Europe’s Hydrogen Strategy, 2020. In early March 2021, he led the efforts to get projects using ‘waste’ as feedstock, classified as ‘New Renewable Hydrogen Projects’. Earlier, in India, his efforts earned recognition by Namami Ganga (Clean Ganga). He has worked in Asia for 18 years and in Europe for 11 years, covering technology-fit, regulatory policy, financial framework and appropriate SDG measures. He has been at FT Roundtables, Asia 3R Summit, EU Circular Mission and India Water Summits for 18 years in the Indian sub-continent and for 11 years in Europe gathering experience in energy, metals & mining and financial markets. He has effectively been in parallel roles of Honorary Adviser, European Affairs for Clean Ganga, Centre for Ganga River Basin Management And Studies; Supervisory Board Member of WaterExpo 2021, India; an accredited Energy Expert empaneled under EU Horizon 2020 program; a member of CII National Task Force on Waste-to-Worth in India and a member of Energy and Environment Committee of Bengal Chamber of Commerce, India. He is a certified Energy Risk Professional from Global Association of Risk Professionals (GARP) USA and has an MBA in Finance. He is a qualified Cost and Management Accountant and a fellow Member of Institute of Company Secretaries of India. He is also the President of Indian Business Chamber of Luxembourg.</p>
                                                        <p><strong>Summary of the subject and few main points of the speech:</strong></p>
                                                        <p>The speaker focused on how to remove the seeming difficulties in grasping the methodology, cause, motivation and utilities of the subject of accounting which inter-alia includes, Financial Accounting, Management Accounting & Cost Accounting. He suggested the following procedures to the students who are new to the subject so that they become accustomed to the subject and its applications in the corporate world.</p>
                                                        <ol>
                                                            <li>To know the difference in format but the uniformity in content between the horizontal formats of financial accounts and statement as prevalent in academics and the vertical format found in real annual reports;</li>
                                                            <li>To get acquainted with the Accounting Equation and the Golden Rules for making entries in the books of accounts;</li>
                                                            <li>To understand the logic behind disclosure in financial statements and ethics;</li>
                                                            <li>To understand the meaning of Source and Application of Funds;</li>
                                                            <li>To understand the difference between the terms Fund and Cash;</li>
                                                            <li>To understand the concept of depreciation and its implication in reducing profits but enhancing tax shield;</li>
                                                            <li>To understand the difference of interests of the management and the shareholders;</li>
                                                            <li>To understand the difference of interest between the auditors and the management;</li>
                                                            <li>To understand the concept of treating capital and accumulated profits as a Liability from the viewpoint of the organization;</li>
                                                            <li>To the understand the similarity and difference between IFRS, other GAAPs and Indian Accounting Standards; and</li>
                                                            <li>To understand the basics of cost accounting like Cost Accounting Standards and other important concepts i.e. Accounting for foreign exchanges, Accounting for contracts and Accounting for cryptocurrencies.</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingeight">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseeight">Marketing Management</button>
                                        </h2>
                                        <div id="collapseeight" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <div className="one-table">
                                                    <div className="one-table1"><img className="img-fluid" src="./assets/images/Mr.-Indraneel-Ganguli.jpg" alt="sanjay-kumar" width="150" height="169" /></div>
                                                    <div className="one-table2">
                                                        <p><strong>Date:</strong> November 17, 2021
                                                            <br /><strong>Course:</strong> Marketing Management 1
                                                            <br /><strong>Sem:</strong> 1
                                                            <br /><strong>Theme/Topic:</strong> Marketing post COVID – Myths and Realities
                                                            <br /><strong>Name of Guest Speaker:</strong> Indraneel Ganguly
                                                            <br /><strong>Designation:</strong>Indraneel Ganguli (IG) is a Brand Builder and a Marketing Professional, actively involved in supporting enterprises globally, with their Awareness, Narrative, and Revenue generation objectives. Recently, IG was leading the marketing function of Tech Mahindra, the digital</p>
                                                        <p><strong>Event Description: </strong>
                                                            On 17th of November, 2021 at 12 pm, we had a corporate connect session, where our honorable guest Mr. Indraneel Ganguly had made that day a memorable day for Globsynites with his presence. He had delivered a very brief and valuable presentation on “Marketing post COVID – Myths and Realities”.</p>
                                                        <p><strong>Summary of the subject and few main points of the speech:</strong><br />
                                                            The event has started with a very interesting example of “a black dot on a white paper” and he correlates the story with our physiological behavior.  From the beginning of the event, everyone present in the session was really connected with his speech. IG has taught us that, whatever we were thinking as negative is not at all so, he relates the thought with the covid-19 circumstances. Then He had shared his mesmerizing corporate experience throughout his 25 years of journey.  He emphasized the power of artificial intelligence, which will be going to connect the whole world in the future. Five myths about these transforming times highlighted by him were-
                                                        </p>
                                                        <ol>
                                                            <li>Economy won’t grow as predicted</li>
                                                            <li>Truth will Prevail</li>
                                                            <li>Consumerism is going to evaporate</li>
                                                            <li>The conventional media will come back</li>
                                                            <li>Our jobs will be secure.</li>
                                                        </ol>
                                                        <p>Moreover, he used very admissible reasons and real-time examples to explain to us the essence of his thoughts. He also brought up the reducing trend of print media for the innovation of smartphones or electronic gadgets. By virtue of the fact that people nowadays are more comfortable staying connected digitally. He also enlightened on the topic of world economy pre and post covid. Mr. Ganguli has also highlighted the most required top 10 skills that will be required in 2025 to sustain the corporate world. After the presentation from his end, he opened the ground to the students for resolving their queries. At the end of the session, he talked about the power of AI In the medical sector, agriculture, etc. He suggested to increase reading skills. It was really an overwhelming session by Mr. Indraneel Ganguly, an eminent corporate personality, and made the day very special to us.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingnine">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsenine">Cost & Management Accounting</button>
                                        </h2>
                                        <div id="collapsenine" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <div className="one-table">
                                                    <div className="one-table1"><img className="img-fluid" src="./assets/images/Ms.-Sumita-Bhattacharya.jpg" alt="sanjay-kumar" width="150" height="169" /></div>
                                                    <div className="one-table2">
                                                        <p><strong>Date:</strong> November 16, 2021
                                                            <br /><strong>Course:</strong> Cost & Management Accounting
                                                            <br /><strong>Theme/Topic:</strong> Bigger, Bolder, Faster: Mindsets & Behaviors to Thrive in Disruption
                                                            <br /><strong>Name of Guest Speaker:</strong> Ms. Sumita Bhattacharya
                                                            <br /><strong>Designation:</strong>Managing Director, Accenture, Whatif Innovation, USA
                                                            <strong>Brief Description: </strong>
                                                            Ms. Sumita Bhattacharya has been with Accenture as a Managing Director for over ten years. Her core expertise is in building innovation, culture and capability and driving transformational change through new operating models, disruptive innovation and embedding new mindsets across leading organizations. Sumita has a long history of launching businesses. Prior to Accenture, Ms. Bhattacharya created her own consulting practice which was acquired by Accenture. She also worked with Disney, where she focused on launching many mobile and digital businesses. In addition, she worked for several years with the media and entertainment practice for Booz Allen & Hamilton (now part of PwC) a leading strategy consulting firm. She holds an MBA from Columbia Business School and a BS in Economics from the Wharton School at the University of Pennsylvania.
                                                        </p>
                                                        <p><strong>Summary of the subject and few main points of the speech:</strong>
                                                            The speaker focused on the following strategies to realign our mindsets in order to thrive in the current era of disruption:</p>
                                                        <ol>
                                                            <li>To lead, experiment, adapt and learn</li>
                                                            <li>To seek new experiences and perspectives for the purpose of  enabling us in making original creative connections</li>
                                                            <li>To protect and nurture young ideas so that they get built up and grow freely by suspending judgement and understanding them</li>
                                                            <li>To be explicit in letting others know what exactly is expected out of them</li>
                                                            <li>To be brave in order to move away from the comfort zone and do something new</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingten">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseten">Legal Aspects of Business</button>
                                        </h2>
                                        <div id="collapseten" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <div className="one-table">
                                                    <div className="one-table1"><img className="img-fluid" src="./assets/images/Mr.-T-B-Chatterjee.jpg" alt="sanjay-kumar" width="150" height="169" /></div>
                                                    <div className="one-table2">
                                                        <p><strong>Date:</strong> November 9, 2021
                                                            <br /><strong>Course:</strong> Legal Aspects of Business
                                                            <br /><strong>Sem:</strong> 1
                                                            <br /><strong>Theme/Topic:</strong> Global Economy and Advisory Services
                                                            <br /><strong>Name of Guest Speaker:</strong> Prof. Timir Baran Chatterjee
                                                            <br /><strong>Designation:</strong>Mentor and Partner of TCN GLOBAL AND ECONOMIC ADVISORY SERVICES LLP</p>
                                                        <p><strong>Brief Description: </strong>
                                                            Prof. Chatterjee’s major exposures are mainly in the areas of Corporate Laws, Indirect and Direct Tax Management, Fund Management, Insurance and Risks Management. Prof Chatterjee retired as the Chief Corporate Officers (Legal and Corporate Affairs) from DIC India Limited (Formerly Coates of India). Prior to above he was a Senior Executive Vice President (Corporate Affairs and Legal) and Company Secretary of the same Company for a period of 22 years. He was also a Director of DIC Fine Chemicals Private Limited. He also worked in various capacities with Voltas Ltd., EMC, B&A Group and Asiatic Oxygen.</p>
                                                        <p>He is presently the Mentor and Partner of TCN GLOBAL AND ECONOMIC ADVISORY SERVICES LLP (Formerly Tax connect Advisory Services LLP) and handling Fund Management, Direct and Indirect Management, Corporate Laws and Foreign Trade Policies.  He has experience of more than 45 years in the areas of finance, tax, legal and corporate affairs in a MNCs.</p>
                                                        <p>Major jobs handled in the Corporates including</p>
                                                        <ol>
                                                            <li>Setting up SEZ Unit in Dahej, Gujarat as a Director of DIC Fine Chemicals (P) Ltd;</li>
                                                            <li>Setting up of India office of DIC Group, Japan</li>
                                                            <li>Technical Collaboration Agreement</li>
                                                            <li>R&D Approval of DIC India Limited</li>
                                                            <li>Right Issue Management</li>
                                                            <li>Managing of open offer declared by Sun Corporation, USA</li>
                                                            <li>Setting up of unit in Bangalore;</li>
                                                            <li>Restructuring of coatings business</li>
                                                            <li>Restructuring of Adhesives Business</li>
                                                            <li>Slump Sale of Coatings and Adhesives Business</li>
                                                        </ol>
                                                        <p>Major jobs done on behalf of Chamber of Commerce</p>
                                                        <ol>
                                                            <li>Advising Government of West Bengal for Export development in the state of Bangladesh</li>
                                                            <li>Report on Destination West Bengal;</li>
                                                            <li>Financial Restructuring issues with regard to Power Sector</li>
                                                        </ol>
                                                        <p>He has authored several books some of which are:</p>
                                                        <ol>
                                                            <li>Hand Book on Value Added Tax in West Bengal</li>
                                                            <li>Report on Destination West Bengal;</li>
                                                            <li>GST- Conception, Issues and Roadmap.</li>
                                                        </ol>
                                                        <p><strong>Brief discussion of the Speech:</strong></p>
                                                        <p>Corporates are regulated and administered by several legal issues that is incorporation of the company, to the winding up of the functioned in a structured manner.</p>
                                                        <p>Laws are not made in isolation but to protect the interest of stake holders including customers, banks, employees etc. It is one artificial individual possessing several rights, and one of the first rights include the right to do business. But there lie several obligations like the payment of the taxes (as he rightly mentioned it to be “the cost of civilisation”) without which the countries cannot run, If such regulations are not met there will be a huge impact on society.</p>
                                                        <p>Important laws drafted by him including GST, VAT , and one of the most important law ie The Company’s Act ( Incorporation to dissolution) were discussed  as well.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingeleven">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseeleven">Business Communication</button>
                                        </h2>
                                        <div id="collapseeleven" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <div className="one-table">
                                                    <div className="one-table1"><img className="img-fluid" src="./assets/images/Mr.-Rahul-Bose.jpg" alt="sanjay-kumar" width="150" height="169" /></div>
                                                    <div className="one-table2">
                                                        <p><strong>Date:</strong> March 10, 2021
                                                            <br /><strong>Course:</strong> Business Communication
                                                            <br /><strong>Theme/Topic:</strong> Learning & Knowledge
                                                            <br /><strong>Name of Guest Speaker:</strong> Rahul Bose
                                                            <br /><strong>Designation:</strong>Former Business Consultant, IBM Corporation
                                                            <br /><strong>Brief Description: </strong>
                                                            Rahul Bose, is a Mechanical Engineer from Jadavpur University and MBA from XLRI Jamshedpur – 2000 with a career spanning over 30 years.<br />
                                                            Rahul Bose is an IBM Certified learning professional, 4 Star Learning Knowledge facilitator and Certified DISC Coach and trainer. Certified virtual facilitator and thought leader. Areas that he has been consulted for consulting / training: Motivation, Leadership, Client Centricity, Critical Thinking, Emotional Intelligence, Delivery Management. Corporate Social Responsibility, Organization Design and Change, Learning and Development, Personality and Behavior, Organization Behaviors, Design Thinking, Strategic HRM. He had worked in organizations like BHEL (1984-92), TATA Steel (1992-2001), PWC as a Consultant (2001-2002) and IBM (2002-2019). He is the Co-Chairperson of Bengal Chamber of Commerce People Management Committee, Executive member of NIPM and ISTD. Course counselor and regional coordinator for IGNOU, Industry mentor to many and visiting faculty at IIMC, Saint Xavier’s University. He fondly remembers his time working under Late Mr. Rajarshi Sengupta whose ‘care’ helped him to succeed at PwC. He was inducted at PwC on 1 June 2001. Three months prior to his joining, Mr. Rajarshi Sengupta, his would- be boss sent a note that said that he should come prepared to roll out a series of ‘Introduction to Consulting’ training programmes immediately upon joining. He was welcomed at PwC with a personal touch and given access to everything he needed to perform his role. He was new to the Technology and IT consulting space. Mr. Sengupta encouraged him at every step and assured him that it was his experience and insights in industry that mattered.</p>
                                                        <p><strong>Summary of the subject and few main points of the speech:</strong></p>
                                                        <ol>
                                                            <li>Knowledge is static. Learning is a skill. IBM Services deliver sustained value for clients that make a difference in the real world and help IT business to accelerate their journey to cloud and become cognitive enterprises</li>
                                                            <li>Planning, budgeting and forecasting is typically a three-step process for determining and mapping out an organization’s short- and long-term financial goals. Planning provides a framework for a business’ financial objectives — typically for the next three to five years.</li>
                                                            <li>Budgeting details how the plan will be carried out month to month and covers items such as revenue, expenses, potential cash flow and debt reduction. Traditionally, a company will designate a fiscal year and create a budget for the year. It may adjust the budget depending on actual revenues or compare actual financial statements to determine how close they are to meeting or exceeding the budget.</li>
                                                            <li>Forecasting takes historical data and current market conditions and then makes predictions as to how much revenue an organization can expect to bring in over the next few months or years. Forecasts are usually adjusted as new information becomes available.</li>
                                                            <li>Forecasting takes historical data and current market conditions and then makes predictions as to how much revenue an organization can expect to bring in over the next few months or years. Forecasts are usually adjusted as new information becomes available.</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
