import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function TheFineLine() {
    return (
        <><title>Leadership vs. Bullying: The Fine Line</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Leadership vs. Bullying: The Fine Line</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog"> Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Leadership vs. Bullying: The Fine Line</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/2020-02-27.png" alt="Leadership vs. Bullying" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>An organisation thrives on the vision and guidance of a leader. Effective leadership not only drives success, but also constructs and nourishes the very foundation of sustainable growth. In the last few decades, various companies around the world have incorporated some of the best leadership practices, which have guided them to explore new heights.</p>
                                <p>But unfortunately, many individuals in the executive level have incorrectly perceived the true meaning of leadership. This has resulted into the emergence of corporate bullying that ends up inflicting more damage than good.</p>
                                <p>While many would argue that corporate bullying has some degree of positive impact on workflow efficiency, there is no doubt about it being a form of aggressive conduct that although can churn out short term results, can also inflict severe damage on workforce health, company culture and overall efficiency in the long run.</p>
                                <p><b>What Is Leadership?</b></p>
                                <p>Leadership is defined as the ability to guide a team or an organization through varying levels of challenges and inspire workers to bring out their true potential. A leader is someone who not only sheds light on unexplored opportunities but also strengthens people to synergize and channelize their potential as a team.</p>
                                <p><b>What Is Bullying?</b></p>
                                <p>Bullying (in this context Corporate Bullying) is the act of aggressively commanding people to perform tasks under pressure. A corporate bully resorts to negative body language and instils fear in workers. They justify their actions by pointing out how bullying ensures all workers get their tasks delivered, regardless of their mental or emotional state.</p>
                                <p><b>What Separates the Two?</b></p>
                                <p>There’s a fine line between leadership and bullying that often goes unnoticed. A bully is often mistaken as a leader and vice-versa. It is not that leaders never issue commands. There are circumstances where they have to compromise on their leniency, but that in no way can be labelled as ‘aggressive’, in comparison to the phrases used by a typical bully. A bully tries to benefit from the efforts of their workers, while a leader tries to understand people and bring out their latent potential. There are times when a good leader turns into a bully. This happens due to the position of authority they have gained with time. But what they fail to realize, is that it is not the power or position, but the respect from workers and the contribution towards an organization’s success that makes an individual a true leader.</p>
                                <p><b>Comprehending the Fine Line of Difference</b></p>
                                <table style={{ height: "192px", width: "100%", border: "dashed", backgroundColor: "#f2f2f2", borderCollapse: "collapse" }}>
                                    <tbody >
                                        <tr >
                                            <td>No.</td>
                                            <td><strong>LEADER</strong></td>
                                            <td><strong>BULLY</strong></td>
                                        </tr>
                                        <tr>
                                            <td>1.</td>
                                            <td>Has a firm vision and is decisive in approach</td>
                                            <td>Lacks vision and is usually impulsive</td>
                                        </tr>
                                        <tr>
                                            <td>2.</td>
                                            <td>Addresses and works towards both short- and long-term goals</td>
                                            <td>Cares for only short-term outcomes</td>
                                        </tr>
                                        <tr>
                                            <td>3.</td>
                                            <td>Admits mistake, accepts responsibility</td>
                                            <td>Plays the typical blame game</td>
                                        </tr>
                                        <tr>
                                            <td>4.</td>
                                            <td>Shares credits of success</td>
                                            <td>Claims all credits to themselves</td>
                                        </tr>
                                        <tr>
                                            <td>5.</td>
                                            <td>Consistent and diligent in effort</td>
                                            <td>Random and unpredictable</td>
                                        </tr>
                                        <tr>
                                            <td>6.</td>
                                            <td>Inspires and leads by example</td>
                                            <td>Dominates and governs by fear</td>
                                        </tr>
                                        <tr>
                                            <td>7.</td>
                                            <td>Learns from experiences</td>
                                            <td>Gives a blind eye to failures</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <p>It is to be remembered that leadership is an art that can only be incorporated when an organization fosters the environment of cohesiveness, trust and above all, transparent communication. That is why, Globsyn Business School (GBS) always strives to bring out the true essence of leadership and educate aspirants in the art of effective management. With the mission to transform young minds through integrity, knowledge, research and technology, GBS imbibes human values in the young managers through its various ‘Care for Society’ activities, thereby shaping the next generation professionals into becoming true leaders of tomorrow.</p>
                                <p>With the rise in agile organizational outlook across the world, this is perhaps the best time when leadership can be redefined and restored to its former glory.
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
