import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function GreenEntrepreneurship() {
    return (
        <><title>Green Entrepreneurship: A Path towards Sustainable Development</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Green Entrepreneurship: A Path towards Sustainable Development</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog"> Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Green Entrepreneurship: A Path towards Sustainable Development</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Copy-of-Untitled-Design.png" alt="" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Small and medium-sized enterprises (SMEs) are the backbone of most of the country’s economies by contributing extensively to GDP growth and providing high levels of employment. Successful enterprises are often driven by dedicated and passionate entrepreneurs who identify, construct and seize chances to capitalize them through business. Hence, entrepreneurship effects in innovation along with social and sustained economic development. Green entrepreneurs deliver the source for beginning and sustaining a green economy by serving green products and services, presenting greener production techniques, enhancing demand for them, and building green jobs.</p>
                                <p>The concept of green entrepreneur rises from environmental concerns such as global warming, pollutions, scarcity of natural resources, ozone layer depletion, climate change and other disaster caused by the disruption in the ecosystem. Due to this growing awareness of environmental knowledge, consumers are becoming more receptive towards an eco-friendly or green product. So this inclination of the consumers leads towards the development of the green market. The green market is an emerging market, which fetches ample chances in every field such as green design, green supply chain, green production, and many more.</p>
                                <p>Change is a very common occurrence which sometimes occurs in a cyclic manner or maybe sometimes in random order. In the same method, the customers’ needs, wants, demands, tests & preferences are also varying. This change is not for a specific product category but it exists in every product segmentation. There are various reasons for these behavioural changes like an increase in consumers’ household income, environmental awareness or maybe because of change in VALS (values, attitude and Lifestyles). This change in consumer behaviour generates a new market opportunity. So to grab this market opportunity, the firms also need to implement some changes like new product development, product redesign or changes in the marketing policies etc. The companies, implementing green practices are also getting profitability in these business operations. Among all these expansions certain models are coming in the field of management such as green marketing, green supply chain management (GSCM), waste management, reverse logistics, etc. which not only achieves the requirements of the consumers but also contribute promise about the green and clean environment.</p>
                                <p>Natural environmental concerns are gradually becoming a fundamental part of the business in every passing day. So, the business units are adopting different business strategies due to this environmental consciousness. This is the reason behind the concept of green marketing, which is taking place slowly but steadily in the market. This can be considered as an opportunity for those entrepreneurs, who rely on ecological redesigning and innovation of their product. It is the perfect phase for those entrepreneurs who want to be a part of this developing green market.</p>
                                <p>An entrepreneur is one who reforms and transforms the pattern of production by developing innovation or, more generally, an untested technological process of producing a new commodity or producing the one in a new way, originating a new source of supply of materials or new outlet for products, by establishing a new industry. The inclination of the customers’ buying behaviour towards eco-friendly product or services creates a space for the green entrepreneur. The term “Green entrepreneur” is the combination of entrepreneurship and the environment. The green entrepreneur is the one, who cultivates green business with the help of green practices. A green entrepreneur consciously addresses an environmental or social problem/need through the understanding of entrepreneurial ideas. They penetrate the market by swapping conventional or traditional products. The objective of the green entrepreneur is not only serving the customer with their green products or services but also makes people aware of the green ecosystem. So the thought for the “green entrepreneurship” originates from the combination of the main features of the entrepreneurship itself – innovation, risk, a brand new business idea, and the social and ecological engagement of those who do business.</p>
                                <p>In recent days, green entrepreneurs play a very important role in the economic development of the country and also treated as the driving force towards the change of consumer behaviour and also esteemed for launching, innovating, implementing and emerging new thoughts and for their rapid response to these changes. Indeed, there is a crucial variance between the way of looking at green entrepreneurship in developed countries and developing countries. Developed countries and international organizations like the World Bank, UNCTAD, OECD, UNIDO, WTO and FAO – incline to put maximum prominence on market opportunities and on the term ‘green’, while developing countries tend to emphasis more on market needs and on the term ‘entrepreneurship’.</p>
                                <p>From a development perspective “Green entrepreneurship” is a progressively appropriate phenomenon, which is still largely under-researched. While global inequality and growing unemployment pose major challenges to policymakers, the emerging effects of climate change and the rapid loss of biodiversity together with the widespread destruction of wildlife and natural habitats, composite the susceptibility of already burdened social groups and ecosystems. Green entrepreneurship also plays a significant role in job creation. Moreover, technological advancement and variation are extensively influenced by the nature of innovation and its determinants. The recent advance of the globalization of economic procedures has drastically altered the outdated approaches used by enterprises to innovate. Though remarkable advances have been made with environmental accounting and reporting as well as with technological research and implementation, the gap towards sustainability is still significant.</p>
                                <p>So the understanding of pre-condition for the formation of “green innovation” is very crucial along with its triggers and barriers. Systemic alteration is desirable to substitute progress in accounting, economics, and legal frameworks. According to a survey of Accenture (UN Global Compact-Accenture, 2010), sustainability has become a principal worldwide component of corporate business strategies and highly relevant for the future of their businesses. But the global evolution to a green economy is only at the beginning, which is very challenging for the earth to wait. In the next decades, there will be rapid growth in industrialization global population and economic development. Resources are inadequate and we must meet the requirements of people. Green entrepreneurship is the only path to deliver on their commitments to provide efficient and safe operations in an environmentally and socially responsible way.</p>
                                <p><b>Aparajita Sanyal</b><br />
                                    Knowledge Cell</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
