import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
<footer>
            <div className="footer-area-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-box">
                                <h3 className="f-20">Globsyn Business School</h3>
                                <div className="footer-about">
                                    <p >Globsyn Business School, India's only 'Corporate B-School' and a global brand of Globsyn Knowledge Foundation, is situated in the city of Kolkata.</p>
                                </div>
                                <ul className="footer-social">
                                    <li><Link to=""><i className="fa fa-facebook" ></i></Link></li>
                                    <li><Link><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                    <li><Link><i className="fa fa-youtube" aria-hidden="true"></i></Link></li>
                                    <li><Link><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                    <li><Link><i className="fa fa-instagram" aria-hidden="true"></i></Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-box">
                                <h3>Quick Links</h3>
                                <ul className="featured-links" style={{display:"flex"}}>
                                    <li>
                                        <ul>
                                            <h4 style={{color:"white"}}>Programms</h4>
                                            <li style={{fontSize:'12px'}}><Link to="/pgdm">PGDM</Link></li>
                                            <li style={{fontSize:'12px'}}><Link to="/mba" >MBA (Global)</Link></li>
                                            <li style={{fontSize:'12px'}}><Link to="/pgdm-business-analytics" >PGDM - Business Analytics</Link></li>
                                            
                                            <h4 style={{color:"white"}}>Admission</h4>
                                            <li style={{fontSize:'12px'}}><Link to="/pgdm-business-analytics" >GBS Admission</Link></li>
                                            <li style={{fontSize:'12px'}}><Link to="/placement" >Placement</Link></li>
                                        </ul>
                                        
                                    </li>
                                    <li>
                                        <ul> 
                                            <h4 style={{color:"white"}}>Other Links</h4>
                                            <li style={{fontSize:'12px'}}><Link to="/alumni-my-story" >Alumni My Story</Link></li>
                                            <li style={{fontSize:'12px'}}><Link to="/blog" >Blog</Link></li>
                                            <li style={{fontSize:'12px'}}><Link to="/kalyani-youth-leadership-forum" >KYLF</Link></li>
                                            <li style={{fontSize:'12px'}}><Link to="/gbs-embryon" >Embroyn</Link></li>
                                            <li style={{fontSize:'12px'}}><Link to="/serendipity" >Serendipity</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-box">
                                <h3>Contact Us</h3>
                                <ul className="corporate-address">
                                    <li><i className="fa fa-paper-plane" aria-hidden="true"></i><a href="https://goo.gl/maps/DJPAHWn33eYK7Fgn8"> Mouza Chandi, PS Bishnupur JL No. 101,, Kolkata, West Bengal 743503</a></li>
                                    <li><i className="fa fa-phone" aria-hidden="true"></i><a href="tel:+918274004556"> +91 82740 04556 </a></li>
                                    <li><i className="fa fa-envelope" aria-hidden="true"></i><a href="mailto:gbsdirect@globsyn.edu.in">gbsdirect@globsyn.edu.in</a></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="footer-area-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                            <p className="f-15">&copy; Copyright: Globsyn Business School</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>        
    </>
  )
}
