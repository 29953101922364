import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function DiversityInclusivity() {
    return (
        <><title>Diversity, Inclusivity, and the Role of Middle Managers</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Diversity, Inclusivity, and the Role of Middle Managers</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Diversity, Inclusivity, and the Role of Middle Managers</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/website-blog_08_02_2022.png" alt="Gig Economy: Growing Significance of Skill-based Education" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>A diverse and inclusive workplace culture can be defined as one that makes employees feel involved and supported in every aspect of the workplace irrespective of their position in the hierarchy or their contribution to the business. Several studies conducted in this area have shown diversity and inclusivity in the workplace to have various advantages like a higher growth in revenue, an increased eagerness for innovation, higher rates of retention, and the enhancement in the ability to recruit fresh talents. Thus, in the 21st century, promoting an inclusive, diverse culture has become one of the top priorities for corporations as it has become synonymous with growth.</p>
                                <p>With corporations joining the bandwagon of diversifying the culture of the workplace, the question of how to promote this culture and make it reach the crevices of the organization has arisen. The answer to this question is through the middle managers, as they are the ones responsible for conducting the daily operations and enforcing policies that have a direct impact on employee lives. Moreover, they also enjoy proximity with the employees which gives them an added advantage in influencing the workplace culture. Senior managers are the ones who initiate the DEI policies whereas the middle managers enforce them. </p>
                                <p>However, a common mistake made by many organizations is using similar tools of engagement for the senior and middle management, even when they have vastly different roles. Senior managers are guided by factors like competition and position in the market, whereas the middle managers are directly in touch with the employees and focus on their performance. Thus it is pivotal for organizations to come up with methods to help the middle managers to carry forward the DEI work of a company. It is the responsibility of an organization to convince the middle managers that the DEI work is of equal importance to their other job roles.</p>
                                <p>The study “Improving Workplace Culture Through Evidence-based Diversity, Equity, and Inclusion Practices”, authored by Wharton management professors Stephanie Creary and Nancy Rothbard and based on interviews and surveys conducted over hundreds of workers across 15 different U.S industries has offered various recommendations to motivate and engage middle managers to not only support but also believe in the DEI work of a company. Early on, the authors realized that establishing diversity, equity, and inclusion as the main pillars of the culture of a company is too unspecific for middle managers, and thus they came up with related, more specific terms and phrases, for example, ‘making the mix work well’ for inclusion. The actions recommended by the report are:</p>
                                <ul className='disc'>
                                    <li>Diversity and Inclusion practices should be connected to business goals</li>
                                    <li>Transparent accountability measures for employee evaluation should be established for middle managers</li>
                                    <li>Middle managers should be provided with online resources for handling issues related to diversity</li>
                                    <li>Middle managers should be invited to attend diversity recruitment fairs </li>
                                    <li>Middle managers should be named to sponsorship roles of resource groups for employees </li>
                                </ul>

                                <p>The authors have spoken for the collaboration between the middle managers and DEI strategists, where the middle managers can discuss freely the various challenges they face during operations, and how diversity and inclusion can help them to deal with these challenges. They can also offer suggestions as to what they would do differently to mould the efforts of diversifying the workplace according to the needs of the business. </p>
                                <p>The study has suggested the introduction of a reward system to motivate middle managers to undertake DEI policies more actively. On the basis of performance markers that support diversity, for example, behaviors and positive actions aligning with DEI initiatives, companies should provide rewards to middle managers in the form of salary hikes, bonuses, and awards of recognition. </p>
                                <p>As a part of the sensitization of the middle managers, organizations should roll out mandatory training in the spheres of diversity, equity, and inclusion. The program should focus on eliminating biases against employees for their ethnicity, race, religion, nationality, gender, or sexual preference. The trainees should receive the opportunity of discussing real-life scenarios that managers have to face, for example supervising an employee with disability, or a single parent. </p>
                                <p>The study has also offered various recommendations for middle managers to develop diversity, equity, and inclusion in areas like recruiting, training, and mentoring. Some of these recommendations are:</p>
                                <ul className='disc'>
                                    <li>Advocating for safe spaces for employees to discuss topics unrelated to work</li>
                                    <li>Familiarising oneself with programs and policies even if they do not impact him personally</li>
                                    <li>Creating a culture of mentorship that ensures access to resources to mentors and sponsors </li>
                                    <li>Using language that is inclusive </li>
                                    <li>Being transparent regarding hiring policies</li>
                                    <li>Reinforcing a zero-tolerance policy for unacceptable behavior </li>
                                </ul>
                                <p>Rothbard has pointed out that the modern workplace is in need of a culture that consists of good management along with an equal focus on inclusion, and diversity. Like the organization, the employees too need to understand the concept of diversity, and what it brings to the table. This is where the role of the middle manager is crucial. The middle managers bring authenticity to the vision and portray it as a way of creating a favorable work environment for everyone. </p>
                                <p>Globsyn Business School throughout its 20 years of existence has focused on developing industry-relevant managers, possessing expertise in the various sectors of management and this area has been no exception. The B-School has created a unique pedagogy accommodating Beyond Education student-oriented verticals like Kalyani Youth Leadership Forum, Serendipity, and Embryon that have provided the young managers with the opportunity to interact with people from various walks of life, ranging from successful entrepreneurs to people from the marginalized sections of the society, and have transformed them into humane, far-sighted managers upholding the principles of diversity and inclusion in their workplaces.</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
