import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../Sidebar'

export default function InformationSystem() {
  return (
    <>
    <title>School of Human Resources Excellence | Globsyn Business School</title>
    <section className="hero-banner-img-pgdm">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">School of Human Resources Excellence</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="#">School of Human Resources Excellence</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                       
                    </div>
                    <div className="one-table">
                        <div className="one-table1"></div>
                        <div className="one-table2">
                            <p>The cutting edge of an organisation ultimately depends upon the quality of its human resource and how effectively it is leveraged by the firm. To lead a successful organisation, Human Resource policies and practices must drive its strategic goals and help in their effective realisation. The HR specialization programme at Globsyn Business School covers compulsory courses, key concepts, issues, systems and tools relevant to the industry in the first year. In the second year, wider areas are taught in depth with industry specific ‘live’ case studies, GDs, management games, industry projects and other innovative pedagogic tools.</p>
                            <p>The School of HR Excellence offers the following areas of study:</p>
                            <ul className='disc'>
                                <li>Organizational Development & Change Management</li>
                                <li>Performance Management & Competency Mapping</li>
                                <li>Talent Acquisition & Retention</li>
                                <li>Compensation Structuring</li>
                                <li>Training & Development</li>
                                <li>Corporate Governance: HR Perspective</li>
                                <li>Cross-Cultural Management</li>
                                <li>Management of IR</li>
                                <li>Strategic HRM</li>
                                <li>Stress Management</li>
                                <li>Transactional Analysis</li>
                                <li>Dissertation for HR School of Excellence</li>
                            </ul>
                            <p><em>* Not all papers are offered in all courses</em></p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
