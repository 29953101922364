import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ReallifeExposure() {
    return (
        <><title>Real-life Exposure redefining Educational Norms</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Real-life Exposure redefining Educational Norms</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Real-life Exposure redefining Educational Norms</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Real-life-_-Blog-iamges.png" alt="Real-life Exposure redefining Educational Norms" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Teaching and learning experiences that take place outside the confines of classroom walls have been gaining importance over time. The academic world has required students to put into practice their classroom learning in the real world, and theorize knowledge beyond education activities. This student-centric learning experience has enhanced learning and fostered personal and social development (Larsen, Walsh, Almond, & Myers, 2017)</p>
                                <p>Traditionally there are two settings of education, classroom setting and real-life exposure. The traditional approach to education involves knowledge circulation at schools and colleges. However, the traditional approach to education is not self-sufficient. Real-life exposure enhances a set of skills or breadth of knowledge that one obtains outside the traditional classroom setting. This approach has led to redefining the Educational Standards for a Better Future. </p>
                                <p><b>What is Education through Real-Life Exposure?</b></p>
                                <p>In a traditional educational setting, one gets to inculcate knowledge in a predictable manner. One is subjected to organized tests and curated discussions. On the other hand, real-life exposure is the reverse, one obtains knowledge in an unpredictable setting, and learners are placed in an unfamiliar setting. This enables a learner to focus on multiple things at one time together and simultaneously imbibe the courage in a learner to know more. </p>
                                <p><b>Forms of Real-life Exposure</b></p>
                                <p>Real-life exposures have been redefining educational norms, and they can be specifically of two different categories:</p>
                                <ul className='disc'>
                                    <li><b>Learner to the Partner</b> – According to Dave Merry, Associate Director for Experiential Integration at Northeastern University, this relationship occurs in a loop. He says, “A student learns something in the classroom, maybe they go out on an internship or they go into the real world and engage in it. The critical component in this loop is reconnecting with an educator as they can help students process what they have learned and prepare them for further partner engagement.” The faculty member acts as a bridge in both ways, connecting real-life exposures and the student so that it can become more empowering. </li>
                                    <li><b>Learners to the Experience</b> – It is important to connect learners to real-life situations. This allows the students to experience real-world problems and to solve them as they unfold. As O’Grady, Country Manager of LeWagon explained, “What we want is for someone to be able to think like a developer; someone who can solve problems by themselves. Students should not just be able to solve the problems that they know, but also be able to solve the problems that they don’t know.” </li>
                                </ul>
                                <p><b>Rethinking and Reshaping Education by Globsyn in the Post-Pandemic Digital Age</b></p>
                                <p>Redefining the educational goals and teacher’s role in the digital era, Globsyn Business School has been working toward preparing learners to be industry-perfect and industry-relevant individuals. The B-Schools various important initiatives in education both before and after the pandemic is exemplary to many out there. The B-School believes in adequately training each student with a curriculum that emphasizes beyond education practices. </p>
                                <p>Globsyn Business School through elements like Globsyn Annual Lecture Series, Globsyn Management Conference, Globsyn Alumni Engage, Embryon, Kalyani Youth Leadership Forum, and Serendipity spreads the concept of ‘Beyond Education’ among its stakeholders. GBS seamlessly blends in the Experiential Learning methodology in its curriculum to enable the students to engage in application-oriented learning. Boardroom Simulation, case-based teaching, students’ participation in live projects, using dance as a pedagogical tool in Business Communication course, industry visits, etc., all form a part of experiential learning activities. The B-School believes that activities, exercises, and role-plays ‘beyond traditional education’ need to be an integral part of the curriculum to ensure the students develop cognitive, interpersonal, and intrapersonal skills to be truly ‘ready’ and ‘relevant’ for the industry.  </p>
                                <p>Education has been witnessing a culminating change since the onset of the pandemic. We have been the audience to some globally dynamic changes, of which the primary changes have been surely in the field of education. However, in the face of all the challenges there has been one certain positive change – education has taken a bent toward training learners to be self-sufficient, teachers now insist students find out new things, learn new things, and unlearn those that have been traditionally been taught. The benefit of learning from real-life exposures has shown a positive impact on personality development. Things we learn and incur from books are theoretical and are important to know what can be done, what had been done, and what needs to be done is possible once learners can utilize their theoretical knowledge when in a real situation that requires a prompt solution or action to resolve it. </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
