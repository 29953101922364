import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function MasteringMindfulness() {
    return (
        <><title>Mastering Mindfulness for Success and Happiness</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Mastering Mindfulness for Success and Happiness</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Mastering Mindfulness for Success and Happiness</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Mastering-Mindfulness-for-Success-and-Happiness.png" alt="Mastering Mindfulness" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Dr. Swapna Datta Khan, teaches Marketing at Globsyn Business School – one of the best B-Schools in Kolkata. Dr. Khan has been passionate about teaching and grooming young management professionals for more than 16 years. She has taught various courses related to Decision Sciences and Marketing Management at various leading institutions across the country. She has had a research experience of over 14 years, with publications in international journals and presentations at international conferences, besides having edited a research journal, been resource person at conferences and reviewer of research papers. She has qualified the UGC NET Lectureship in Mathematics. She has also served in the Indian Army as a part of the 1st course of non-medical lady officers.
                                    </em>
                                </p>
                                <p><strong>Below is a light-hearted attempt at stimulating an imaginary conversation between Harry ‘Poddar’ and Hermoine ‘Ganguli’ about mastering mindfulness for success and happiness.</strong></p>
                                <p>Harry Poddar (HP) and Hermione Ganguly (HG) both in their final year of graduation, were serenading near the sultry bank of the Hooghly River as HG satiated her taste buds with the tangy ‘Phuchkas’ sold by the evergreen Babin-da.</p>
                                <p>HP seemed strangely withdrawn – this was so unlike his bubbly self. HG noticed.</p>
                                <p><strong>HG:</strong> What is up friend? Is something troubling you?</p>
                                <p><strong>HP:</strong> Actually, I appeared for a placement interview. I hoped to breeze through. They asked some questions that were discussed in class but I could not answer them.</p>
                                <p><strong>HG:</strong> Oh! Well!! How is that possible? I would not call you unintelligent!</p>
                                <p><strong>HP (hesitating):</strong> Ricky, Som and I were discussing last evenings IPL match. We were not noticing what the faculty taught.</p>
                                <p><strong>HG:</strong> Hmmm. Lack of focus and mindfulness is often so costly. Never mind, be a little mindful in the future and you will surely ace the next interview.</p>
                                <p><strong>HP:</strong> Mindfulness? What is that?</p>
                                <p><strong>HG:</strong> Mindfulness is the ability to focus yourself on the task at hand, to be in the moment, absorbing the situation. If you practice mindfulness on a regular basis, you will always be attentive in class or elsewhere, developing into a formidable and challenging personality.</p>
                                <p><strong>HP:</strong> You almost sound like a guru. Are you OK, girl?</p>
                                <p><strong>HG:</strong> Of course. I do practice mindfulness and I feel so calm and composed in every situation. I organize my thoughts and articulate better. I also am more focused towards my tasks and execute them with no deterrents.</p>
                                <p><strong>HP:</strong> That sounds great. What do you have in mind for a sound future?</p>
                                <p><strong>HG:</strong> I wish to be an entrepreneur and add value to the lives of rural India in some ways in which I have thought of. I am in the process of applying to business schools to be adequately trained. You know, Globsyn Business School is on my radar.</p>
                                <p><strong>HP:</strong> Oh, yes?</p>
                                <p><strong>HG:</strong> They have a “Spirituality Club”. Spirituality enables you to realize what is beyond yourself, understand that there is more to life than materialism and sensorized perceptions. I would certainly be a part of this club.</p>
                                <p><strong>HP:</strong> Well, I am trying to understand. First you spoke about mindfulness and then spirituality. At times, HG, I do feel that you are so beyond me!</p>
                                <p><strong>HG:</strong> Spiritual well being will bring a sense of calm to your mind and will enable you to concentrate on what is at hand, prioritize your duties and needs and focus yourself, thus granting you the ability to realize your potential and reach higher. Spirituality can develop mindfulness and reduce unease.</p>
                                <p><strong>HP:</strong> You do sound so cool. Maybe I too will join the Spirituality Club and climb a mysterious but better echelon.</p>
                                <p><strong>HG:</strong> Right now, concentrate on enjoying the ‘Phuchkas’, the likes of which are available only in Kolkata. Maybe the entrepreneur in you will globalize the offering of these tangy little spheres of happiness!</p>
                                <br/>
                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Prof-Dr-Swapna-Dutta-Khan.png"
                                            alt="Prof. Kaushik Ghosh"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Dr. Swapna Datta Khan
                                            <br />
                                            Faculty – Human Resource Management
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
