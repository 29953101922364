import React from 'react'
import Sidebar from '../../Sidebar'
import { Link } from 'react-router-dom'

export default function AlumniEngage() {
  return (
    <><title>Globsyn Alumni Engage - Globsyn</title>
            <section className="hero-banner-img-sceren">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Globsyn Alumni Engage</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Globsyn Alumni Engage</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                            <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/14.jpg" alt="campus" width="678px" height="490px" />
                                </div>
                                <br/>
                                <br/>
                                <p>Today, Globsyn Business School (GBS) has uniquely positioned itself as India’s only ‘Corporate B-School’, where it is all about the transformational power of differential thinking with the entire focus being devoted to creating ‘Industry Relevant Managers’, who can hit the ground running, contributing to an organization’s success from day one. The School, which started its journey in the last two decades with just 50 students, has grown to 3500+ alumni who are successfully working with different corporates across the world.</p>
                                <p>The Globsyn Alumni Engage (GAE) initiative attempts to connect with the GBS alumni at a personal level and involve them in every aspect of its functioning as a B-School, thus making them active participants in the evolution of their alma mater and fellow students. These alumni have earned rich accolades for their achievements, and a large number of them are star performers within their organizations and are proudly carrying the GBS flag to all corners of the globe.</p>
                                <strong>RETRACE</strong>
                                <br/>
                                <br/>
                                <p>Multiple large and medium format events are organized over the year at Globsyn Business School that help the alumni to trace back their roots to their alma mater. These events which are attended by the alumni, are clubbed under the aegis of GAE – RETRACE.</p>
                               <p>At this event the alumni not only reminiscent in fond retrospection about the times they have spent as students, but they also exchange thoughts and ideas about how they can make a positive change in the prevailing ecosystem of the country.</p> 
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
