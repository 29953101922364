import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../Sidebar'
import '../Common.css'

export default function Technology() {
    return (
        <><title>GBS focuses on technology awareness</title>
            <section className="hero-banner-img-inno">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Technology</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/technology">Technology</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                                <h3 className="vc_custom_heading">GBS focuses on technology awareness</h3>
                            </div>
                            <div className="one-table">
                                <p>With its core rooted in technology, Globsyn Business School (GBS), uses technology to not only supplement the knowledge dissemination system but also to make its students more ‘technology’ aware – a must-have condition for today’s corporate environment. Having forayed into education two decades ago, GBS stands on a strong scaffolding of technology that encompasses from getting enrolled in the MBA curriculum weaving a robust academic delivery mechanism and helping students track their own learning using a variety of assessment tools.</p>

                                <div className="m-4">
                                    <div className="accordion" id="myAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">GBS Direct</button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p >GBS Direct has been developed to provide the students with an easy process to help them gain admission to Globsyn Business School without the hassle of moving out from the comfort of their homes!</p>
                                                    <div style={{ textAlign: "center" }}>
                                                        <a target='_blank' rel="noreferrer" href='https://admissions.globsyn.edu.in/'><button className='bg-btn'>Gbs Direct</button></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">eGlobsyn</button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>All students and faculty at Globsyn Business School have access to ‘eGlobsyn’ – an extremely robust, Online Learning Management System with web 2.0 applications and cloud computing. Study materials, presentations, faculty notes, pre and post study material and other knowledge driven components are available online. Students are also expected to use the system to appear for online quizzes, tests, and prepare for the pre-study assignments. The system also facilitates students to track their own learning using a variety of assessment tools. ‘e-Globsyn’ is a unique initiative that would integrate knowledge management, social networking and active learning tools under a single login system.</p>
                                                    <div style={{ textAlign: "center" }}>
                                                        <a target='_blank' rel="noreferrer" href='http://eglobsyn.org/'><button className='bg-btn'>eGlobsyn</button></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">Certificate verification</button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Students of Globsyn Business School are granted private and encrypted access to their diplomas on GBS Secure Certification Platform powered by Amazon Web Services (AWS). This platform effectively reduces the stress and hassle to go through a traditional process of documentation and verification of academic credentials. The technology ensures the issued diploma to be tamper-proof and eradicates the chances of falsification. Thanks to the GBS Secure Certification Platform, GBS students can now share the credential of their diplomas with future employers, higher education institutions, and any other body that requires genuine legitimacy of their academic documentations.</p>
                                                    <div style={{ textAlign: "center" }}>
                                                        <a target='_blank' rel="noreferrer" href='https://www.globsyn.edu.in/certificate_verification'><button className='bg-btn'>Certificate Verification</button></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
