import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function Administrative() {
  return (
    <>
    <title>Administrative Departments - Globsyn</title>
    <section className="hero-banner-img-teaching">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">Administrative Departments</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="#">Administrative Departments</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>
                    <div className="one-table">
                        <div className="one-table2">
                            <p><strong>Registrar’s Office</strong></p>
                            <p>The Registrar’s office at Globsyn Business School is the nodal point for all student administration services. Manned by a dedicated team of professionals with years of experience, the Registrar’s office apart from being the single point of contact for all students is also responsible for the entire gamut of activities to ensure the smooth function of the school including class scheduling, conducting examinations and evaluations, maintaining discipline, etc.</p>
                            <p><strong>Enrolments and Admissions</strong></p>
                            <p>The admissions and enrolment team, at GBS®, is made up of highly experienced individuals who help our prospective students choose their career paths and counsel and advise them to make informed decisions. As veteran professionals from diverse fields, the team not only addresses a student’s career related queries but also facilitate in their education funding drives. As the ‘first-point-of-contact’ the team ensures that prospective students and parents have a pleasant experience while visiting GBS®, besides helping and guiding them to complete the admission and enrolment processes.</p>
                            <p><strong>Corporate Relationship Program (CRP)</strong></p>
                            <p>The Corporate Relationship Program (CRP), manned by a dedicated team of highly motivated professionals is supported by a nationwide forum of ex-students and faculty and student representatives. The CRP cell handles and ensures the smooth transition of the graduates from the campus to the professional world. This is the team that maps the industry resource needs and works towards translating the requirements into deliverable resources. Over the years the Corporate Relationship Program has developed excellent corporate relationships, with our professionals being absorbed in leading organisations across the country.</p>
                            <p><strong>Systems Engineering Group (SEG)</strong></p>
                            <p>The System Engineering Group is responsible for all Information Technology and Communication services provided in the school including maintaining the student intranet, mediating all technology enabled security services, ensuring network and bandwidth availability etc.</p>
                        
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
)
}
