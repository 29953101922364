import React from 'react'
import './about gbs/Common.css'
import { Link } from 'react-router-dom'
import RecentPost from './BlogsComponents/RecentPost'
import { useState } from 'react'
import { getUsers } from './BlogsApi/Users'


export default function Sidebar() {

  const [page] = useState(1)
  const [limit] = useState(5)
  return (
    <>
      <div className="sidebar-widget-area">
        <form className="search-form">
          <label> <span className="screen-reader-text">Search for:</span> <input type="search"
            className="search-field" placeholder="Search …" name="s" /> </label> <input
            type="submit" className="search-submit" value="Search" />
        </form>
      </div>

      <div className="sidebar-widget-area">
        <div className="textwidget custom-html-widget">
          <h3 className="widget-title-top">Programmes</h3>
          <h3 className="widget-title"> <Link to="/pgdm" className='sdb' style={{ textDecoration: "none" }}>PGDM</Link></h3>
          <div className="course-description"> Designed for the academically consistent student, the AICTE approved Post...</div>
          <h3 className="widget-title"> <Link to="/mba" className='sdb' style={{ textDecoration: "none" }}>MBA (Global)</Link></h3>
          <div className="course-description"> Innovation lies at the heart of a truly global company. Global managers...</div>
          <h3 className="widget-title"> <Link to="/pgdm-business-analytics" className='sdb' style={{ textDecoration: "none" }}>PGDM – Business Analytics</Link></h3>
          <div className="course-description"> PGDM – Business Analytics is designed for students looking...</div>
        </div>
      </div>


      <div className="sidebar-widget-area">
        <div className="textwidget custom-html-widget">
          <h3 className="widget-title-top">Latest Posts</h3>
          <RecentPost users={getUsers(page, limit)}/>
        </div>
      </div>
    </>
  )
}
