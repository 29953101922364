import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function GipPartOne() {
    return (
        <><title>Tips to chase-away those Pre-CAT Blues</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Tips to chase-away those Pre-CAT Blues</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog"> Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Tips to chase-away those Pre-CAT Blues</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Malaysia.jpg" alt="Malaysia" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Recently a group of GBS students, pursuing Global MBA – International Business, visited Malaysia as a part of their Global Immersion Program. This will be a series about their various learning experiences, industry visits and exposure to a global corporate environment, maybe for the first time. The following is an account in the words of a student, after he came back from this educational trip.</p>
                                <p>We reached Kuala Lumpur and checked in to our hostels straight from the airport. After some rest and refreshments, we went out for local sightseeing. The next day, too, we were busy shopping and doing all the touristy things, as it was public holiday in the country, and we had the entire day to ourselves.</p>
                                <p>Our day started with a surprise as we received mementoes from the officials of the Malaysia University of Science and Technology (MUST). After reaching the MUST Campus, we were asked to assemble in the student lounge, where we received more gifts such as T-shirts, chocolate boxes, pens and coffee mugs engraved with the MUST logo. A while later, we were guided to our classroom where we were addressed by the President of MUST, Dr. Premkumar Rajagopal, who gave us insights into the University from its very foundation.</p>
                                <p>After Dr. Rajagopal’s speech, we were addressed by the Principal of Globsyn Business School, Dr. Subir Sen. He spoke about the Chairman of Globsyn Group, Mr. Bikram Dasgupta and his journey towards building this entrusted company. Further, he spoke about how Globsyn stands apart from the other business schools through its unique experiential learning methodologies that includes Live Projects, Corporate Connect programs, Care for Society activities etc.</p>
                                <p>After their welcome speeches, we got a 15-minute refreshment break. After the break, we were then addressed by the Dean of MUST, Mr. Harwindar Singh. He, along with other faculty of MUST, Ms. Gomathi Sasidaran and Ms. Dona Aini, introduced us to the University’s online portal. He gave us access to the e-library also and informed us about the evaluation process followed by a Q&A session.</p>
                                <p>Post lunch, we were again addressed by Dr. Rajagopal. This time he discussed with us the different traits of leadership and the qualities of the top leaders in the corporate world. The session was followed by a group discussion on a case study ‘Leadership and SME(s)’ where we were told to discuss different dimensions of leadership with real-life examples. This session was followed by another short break. Post break, he informed us about the assignments that we had to perform individually as well as in groups which was followed by a short Q&A session.</p>
                                <p>Our first day at MUST ended at 5:30 pm on a good note. We learnt a lot of new lessons from Dr. Rajagopal. At the end of the day, we were informed about our next day’s Industrial visit to Bank Negara Malaysia Museum, whose experience we will discuss in our next blog. Keep reading!</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
