import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function RevisitingStrategic() {
    return (
        <><title>Revisiting Strategic Human Resource Management</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Revisiting Strategic Human Resource Management</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Revisiting Strategic Human Resource Management</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Revisiting-Strategic-Human-Resource-Management.png" alt="Revisiting-Strategic-Human-Resource-Management" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Dr. Debaprasad Chattopadhyay, teaches Human Resource at Globsyn Business School – one of the top MBA colleges in Kolkata. Dr. Chattopadhyay has over 48 years of academic and corporate experience with very successful stints in reputed national and international organizations. In addition to being an academician, Dr. Chattopadhyay is a life member of National HRD Network and ISTD and a member of ISABS, NIPM, BMA, and CMA. He is a life member of his alma mater, Don Bosco School Park Circus Alumni Association. Dr. Chattopadhyay completed a program on Strategic Human Resource Leadership from National HRD Network and has also attended Human Laboratory Process Course from Indian Society of Applied Behavioral Sciences. Dr. Chattopadhyay has conducted Management Development Programs and Consultancy Assignments across organizations and has to his credit several publications in various journals and presented technical papers at different national and international conferences. Recently, he has received the Udai Pareek Memorial Distinguished AHRD Alumni Award for the year 2022, bestowed on him by his alma mater Academy of Human Resource Development (AHRD), Ahmedabad.
                                    </em>
                                </p>
                                <p>Strategic Human Resource Management (SHRM), conceptually speaking, aligns HR Policies and Goals with Business Policies and Goals. It is a relatively new concept compared with Human Resource Management (HRM).</p>
                                <p>Generally, the professional career of an individual in an organization can be segregated into three parts, namely, the individual JOINS the organization, the individual WORKS in the organization, and finally, the individual EXITS from the organization. Correspondingly, from the organization’s point of view, it has to PROCURE people, DEVELOP people, and finally MAINTAIN people.</p>
                                <p>Human Resource Management refers to managing people, where ‘people’ represent intellectual capital. Thus, functions of Human Resource Management are three-fold: PROCUREMENT, DEVELOPMENT, and MAINTENANCE. In an alternate parlance, Procurement is tantamount to Talent Acquisition, while Development and Maintenance can be termed as Talent Retention. So far, there is nothing nebulous.</p>
                                <p>However, the problem arises when these functions of HRM are discharged in silos, in utter disregard of mainstream business requirements. What’s imperative is the process of managing people should be integrated with specific needs of the business. When these processes are dovetailed with requirements of the business, the phenomenon is nomenclated as ‘Strategic Human Resource Management’. This will become clearer once examples are cited to illustrate this point.</p>
                                <p>For instance, let us assume that an organization is in need of people but financially it has not been doing well, in such a scenario if the organization meets its manpower needs through ‘organization restructuring’ or ‘employee re-development’, then the approach can be termed as ‘Strategic Human Resource Management’ approach in hiring process.</p>
                                <p>Let us take another example. Training is a cardinal function of HR. Organizations conduct training sessions periodically round the year. Let us assume that the margins are under pressure and the organization is not making profits. In such a situation, if the organization, instead of conducting perennial training, resorts to Just In Time Training (JIT) as and when needed, then it is an SHRM approach to the training function.</p>
                                <p>Yet another example. Performance appraisal is crucial in HRM. Several methods prevail, which fall under conventional Human Resource Management approaches. However, if the organization utilizes Balanced Scorecard, where financial perspectives are integrated with learning and growth perspectives, internal business process perspectives, and customer relationship perspective (which are incidentally the four components of Balanced Scorecard), the performance appraisal approach is said to be in conformity with SHRM.</p>
                                <p>One last example. Compensation Management is inherent in Human Resource Management function. ‘Time-rate Method’ and ‘Piece-rate Method’ are traditional methods of Compensation Management. As against this, if the ‘Payment By Results’ (PBR) approach is adopted, where there is a component of variable pay, the approach is SHRM in nature.</p>
                                <p>Therefore, the Strategic Human Resource Management approach is an apt choice as it helps an organization to make its Human Resource Management Policies fall in line with its Business Policies. Only then, Human Resource Management Roles can transcend from ‘Strategic Partner’ to ‘Administrative Expert’ to ‘Employee Champion’ and eventually to ‘Change Agent’.</p>
                                <br />
                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Dr.-Debaprasad-Chattopadhyay.jpg"
                                            alt="Dr.-Debaprasad-Chattopadhyay"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                        Dr. Debaprasad Chattopadhyay
                                            <br />
                                            Faculty – HR
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
