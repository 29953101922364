import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function BrandBattlefield() {
    return (
        <><title>Understanding the Brand Battlefield</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Understanding the Brand Battlefield</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Understanding the Brand Battlefield</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Understanding-the-Brand-Battlefield-Website-blog-cover.jpg" alt="Understanding the Brand Battlefield" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Dr. Sabyasachi Dasgupta teaches Marketing at Globsyn Business School – one of the best business management colleges in Kolkata. Dr. Dasgupta has commanded prominent positions in several corporate and academic giants for more than 14 years. He has taught at the Florida State University, USA and has also been a visiting researcher in the University. Dr. Dasgupta, apart from presenting research papers in national and international conferences, also has a number of publications including Scopus/ABDC/UGC Care, edited books, number of cases, book chapters and a book under his name. He has also received the distinguished Research Excellence Award 2020. Dr. Dasgupta heads the Research and Publications vertical of Globsyn Research Cell. He is also a scientific committee member of TIIKM – Colombo, a member of American Marketing Association and Calcutta Management Association, and a reviewer in Journal of Brand Management, Journal of Creative Communication and journals published by American Marketing Association.
                                    </em>
                                </p>
                                <p>Brand-fight is never a new phenomenon in the civilization of branding. From the time multiple brands existed in a single category, there have been tensions and palpitations as to who will take over the other or at least edge over the other. It is simply analogous to a car race. The fight for which car will speed over the other and by how much distance is, is akin to in the branding context, how brands will edge over the other brands in the same category and by what percentage of market share.</p>
                                <p>In order to chalk out a large market share, brands need to entice the customer to purchase from them instead of their competitive brands. However the question remains, if a competitive Brand Y duplicates the strategies, promotions, advertisements of Brand X, then how will Brand X respond? Let’s say, Brand X fills up a whole space in the retail store, and Brand Y does the same. Brand X puts twenty hoardings in the city, and in response, Brand Y puts up twenty more hoardings. Brand X incentivizes the retailer, and Brand Y mirrors the action. Or, Brand X decides to spend heavily on marketing communication, and its competitor Brand Y also decides to do the same.</p>
                                <p>This is not fictitious, but the present scenario of a cluttered marketplace where every brand in a single category is trying to outplay the other by simply doing more than the other is doing in the marketplace. Hence, in these challenging times of hyper-accessibility, brands are trying to find a solution to the problem of how to outwit the competitor by jamming the market with their communication and their narcissistic attitude of ‘I am the best for you’. Those brands who are only focusing on the market, are taking a wrong path. The brand battlefield is not in the retail store alone but in the consumer’s mind; it is not in the hoardings, but in the tastebuds; it is not in the number of marketing communications, but in the sensory organs. Thus, the brand’s fighting ground will be the consumer more than the market in today’s modern marketing techniques.</p>
                                <p>Understanding the consumers’ intelligence and personality, the brand needs to take into consideration which sensory organ of the body needs to be sensitized so that they can take advantage of the vulnerable consumer mindset and thereby edge over the other competitors who would still be thinking about their presence in the market. The direct and indirect competition takes place in the battlefield of sensory organs present in the human body. If the brand is a coffee chain and you smell the strong coffee while entering the store, if the brand is an apparel brand and you feel the softness of the garment, if the brand has bright colors in its packaging and you love to see the colors, if a brand has a catchy jingle that you would love to listen to, consumers are bound to have more preference for these brands than the others – thereby whatever happens in the market becomes irrelevant to the brand. They may still fight in the market, but their control over multiple human senses will have a far-reaching long term sustainable impact among the consumers.</p>
                                <p>Competitive brands may not even get the slightest hint of why their market share is sliding down when they have done everything to win the market. The clever brands may have played a masterstroke in dominating the senses of the consumers, thereby edging over the competition brand in the category. The undercurrent strategy of brands may take its competitors completely off the hook and have a competitive advantage over them beyond repair and imagination.
                                    <br />As the world moves from being a sellers’ market to a buyers’ market, consumers have assumed a pivotal role in the structure of an organization. And this has given rise to an increasing demand amongst business houses all over the world to hire young management aspirants who have specialized in Marketing Management with an understanding of brand values, strategies, behavior, amongst others.</p>
                                <p>Over the last 20 years, students have chosen Globsyn Business School to pursue their dreams of management education. The School of Marketing Excellence programme at GBS, armed with an unbeaten course structure, live and industry vertical specific case studies, live projects, workshops, advertisement clubs and other creative pedagogic tools, represents an ideal gurukul to learn the intricacies of today’s marketplace. Considered to be the best Business School in Kolkata, the entire focus of Globsyn Business School is devoted to creating ‘Industry Relevant Managers’, who can hit the ground running, contributing to an organization’s success from day one.</p>

                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Sabyasachi-Dasgupta.jpg"
                                            alt="tt"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Dr. Sabyasachi Dasgupta
                                            <br />
                                            Faculty – Marketing
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
