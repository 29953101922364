import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function TheBlueOcean() {
    return (
        <><title>The Blue Ocean Strategy – Venturing into Virgin Markets</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">The Blue Ocean Strategy – Venturing into Virgin Markets</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">The Blue Ocean Strategy – Venturing into Virgin Markets</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Blue_ocain_Blog.jpg" alt="Blue_ocain" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Over years companies have taken bold strategic moves to dwell into the market, create uncontested market space and make the competition irrelevant.</p>
                                <p>How do they do it? What strategy do they adopt?</p>
                                <p>Consider the remarkable strategic moves made by two corporate giants:</p>
                                <p><b>1) Ford Motor Co.</b></p>
                                <p>In the 18th Century, the automobile industry was in its infancy with around 500 automakers in USA producing custom-made cars that were more expensive and less reliable. In 1908, Ford Motor Co. made a blue ocean strategic move and created a new manufacturing process by producing standardized cars at a fraction of the price as that of its competitors and made it available for the mass. Ford introduced the Model T which came only in one color and one model, but it was affordable, durable and reliable. The Model T’s market share jumped from 9% in 1908 to 61% in 1921, also officially replacing the horse-drawn carriage as the principal mode of transportation.</p>
                                <p><b>2) Apple Inc.</b></p>
                                <p>Apple Inc. unlocked a blue ocean of new market space with the launch of its music download service – iTunes. In 2003, Apple observed that billions of music files were being downloaded each month illegally, Apple created the first legal format for downloading music in 2003. iTunes was easy to use and provided users with the ability to buy individual songs at a reasonable price. Apple won over millions of music listeners who had been pirating music by offering higher-quality sound along with search and navigation functions. Apple made iTunes a win-win-win situation for the music producers, music listeners and Apple itself by implementing the Blue Ocean Strategy to create a new revenue stream from a new market while providing more convenient and legal access to music.</p>
                                <p><b>What is this Blue Ocean Strategy?</b></p>
                                <p>In an established industry, companies compete with each other for every unit of available market share. The competition is often so severe that some firms cannot sustain themselves. In such situations, traditional competition-based strategies aren’t enough to sustain high performances as prospects in most established markets are shrinking steadily. This type of industry describes a ‘red ocean’, representing a saturated market share and cut-throat competition.</p>
                                <p>In the two cases mentioned above, Ford Motor Co. and Apple Inc. succeeded because they realized that to win in the future and sustain, companies must stop competing with each other. The only way to beat the competition is to stop trying to beat the competition, and venturing into virgin markets.</p>
                                <p>
                                    To move beyond competition and towards new growth, companies must tap into the ‘blue ocean’ of opportunities. Firms and entrepreneurs choose to innovate or expand in the hope of finding a blue ocean market with uncontested competition.
                                </p>
                                <p>A Blue Ocean Strategy is based on creating demand that is not currently in existence, rather than competing with other companies to position themselves in the existing market space. This strategy is the simultaneous pursuit of differentiation and low cost to open up a new market spaces and create new demands. It is based on the view that boundaries of the market and structures of the industry are not a given, and can be reconstructed by the actions and beliefs of players in the industry. In ‘blue oceans’ competition is not relevant, as the rules of the game are waiting to be set.</p>
                                <p>Thus, the key to a successful Blue Ocean Strategy is finding the right market opportunity and making the competition irrelevant.</p>
                                <p>What steps did Ford Motor Co. and Apple Inc. adopt to move into a Blue Ocean?</p>
                                <p>The steps taken by organizations while planning the move from the ‘red oceans’ and move into the ‘blue ocean’ are as follows:</p>
                                <ol>
                                    <li>Determining the business, product, or service on which the Blue Ocean Strategy is to be implemented.</li>
                                    <li>Understanding the current market situation, customer sentiment and have a clear picture of the existing strategic landscape.</li>
                                    <li>Exploring the non-customers of a target industry, analyzing their areas of grievances to understand the boundaries that limit the appeal of the product and size of the industry, while planning accordingly and opening up opportunities to create leap in value for buyers.</li>
                                    <li>Designing a roadmap to the goal by applying systematic paths to reconstruct market boundaries and develop strategic options.</li>
                                    <li>Launching a ‘blue ocean move’ by rapidly testing and refining in accordance to the designed business models (iTunes for Apple and Model T for Ford), and thereby maximizing the potential.</li>
                                </ol>
                                <p><b>What makes the Blue Ocean Strategy work?</b></p>
                                <p>The creators of ‘blue oceans’ do not use the competition as benchmark, and instead they follow a different strategic logic – Value Innovation. Value Innovation is a concept developed by W. Chan Kim and Renée Mauborgne and it is the cornerstone of the market-creating strategy.</p>
                                <p>Why Value Innovation? Value without innovation will give only incremental gains easily duplicated by the competition. Innovation without meaningful value creation is typically technology oriented and will not be a commercial success. The idea of Blue Ocean Strategy is to create a huge leap in value never seen before. That leap attracts buyers who have never before considered buying or chose not to buy the previously existing product or service.</p>
                                <p>The Blue Ocean Strategy is created through a series of strategic moves resulting in a product or service substantially different from any other offering. These strategic moves aim to lower costs to the producer of the product or service, resulting in the capability to offer a high value product or service that has not been seen before, at a very reasonable price to the buyer.</p>
                                <p>Ford Motor Co. brought a fresh outlook to the automobile industry by introducing its Model T which was affordable, durable, reliable and accessible to the common people, making it the most convenient mode of commute available in the year 1908. Apple Inc. responded to the heightened music piracy scenario prevailing in the market in the year 2003, and made a significant value addition to the music industry by legalizing music download through iTunes. Both Ford Motor Co. and Apple Inc. tried to analyze the current market scenario, understand the customer sentiments and are recognized for planning their dive into the Blue Ocean with their Valuable Innovations.</p>
                                <p>However, it is important to understand that despite its effectiveness, a ‘blue ocean’ initiative does not guarantee permanent success. There will be other organizations that will imitate and compete in the new market space over time. Businesses therefore need to keep repeating the ‘blue ocean’ shift process to continue to grow, be recognized and sustain themselves in their respective industries.</p>
                                <p><b>Oindrila Roy</b><br />
                                    Knowledge Cell</p>
                                <p>
                                    References<br />
                                    https://www.blueoceansys.com/blogs/blue-ocean-strategy-examples/<br />
                                    https://pdfs.semanticscholar.org/d99d/f3d8f6797910548a1a568d43a3ad2e5e6bee.pdf
                                </p>



                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
