import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function InternshipITCDairy() {
    return (
        <><title>My Summer Internship at ITC Dairy</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">My Summer Internship at ITC Dairy</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">My Summer Internship at ITC Dairy</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Summer-Internship-Experience-at-ITC-Dairy.png" alt="Summer-Internship-Experience-at-IT-Dairy" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Krishna Sankar Bhattacharyya, PGPM – IB, Batch 2022-24, Globsyn Business School, writes about his Summer Internship experience at ITC Dairy where he got the opportunity to work with retailers, understand consumer’s needs, launch a new product in the market and learn how to create a Sales Report, among other key tasks.
                                    </em>
                                </p>
                                <p>My name is Krishna Sankar Bhattacharyya and I am a student of the PGPM-IB programme of Globsyn Business School. I am currently doing my Summer Internship from ITC Dairy, the dairy business of the diversified conglomerate – ITC Limited.</p>
                                <p>It has been more than four weeks since I started my summer internship. The journey has been rewarding beyond my imagination. It has been a wonderful learning experience so far and I would like to take this opportunity to share some of my experiences with you.</p>
                                <h3>The Selection</h3>
                                <p>I felt very lucky when I got selected for my Summer Internship Program (SIP) at one of the largest FMCG companies in India – ITC Limited. For me, it was a dream come true. There was a huge competition among my classmates for securing an internship there and I remember feeling overjoyed when I received the internship offer from ITC Dairy.</p>
                                <h3>The Beginning</h3>
                                <p>On May 2, 2023, many of us from Globsyn Business School started our internships at various renowned organizations like Aditya Birla Fashion, Balaji Solutions Limited, Woodlands Hospital, Dabur, HDFC Bank, etc. and for me, it was ITC Dairy. My first task was visiting various stores to check stock availability, stock presentation in the stores, and whether there was any requirement for new stock.</p>
                                <p style={{ textAlign: "center" }}>
                                    <img className="img-fluid" src="/assets/images/With-a-retailer-at-Bardhamman-1536x688.png" alt="Summer-Internship-Experience-at-IT-Dairy" />
                                    <br />
                                    <em>With a retailer at Bardhamman, West Bengal</em>
                                </p>
                                <h3>On Field Experience</h3>
                                <p>After I joined ITC Dairy, I got a lot of opportunities to learn on the job and also got to know the corporate world, which awaited me.</p>
                                <p>I started by visiting existing retailers, along with distributors, at the stores where our products are already placed. I spoke to the retail owners if they were facing any kind of problem or if they received any feedback from the customer after they consumed our product. Within a few days of doing such rounds, I started receiving some complaints. Among them, one was the demand for making straw along with Lassi. After receiving the complaint, I spoke to my superior and he assured me that he will resolve the issue. Within 2 days, the Lassi packets were provided with a straw. I was complimented by the retailer for speeding up this process.</p>
                                <p>After this, I was given a new task – gap analysis. It has been the best task for me so far. I travelled out of station with my superior to find new distributors. During this time, we stayed at many new places, met new people, and tried to convince them about our company. After four days of staying and working together, we returned with a successful analysis of the problem.</p>
                                <p style={{ textAlign: "center" }}>
                                    <img className="img-fluid" src="/assets/images/New-Product-Launch-Ashirvad-Svasti-1536x688.png" alt="Summer-Internship-Experience-at-IT-Dairy" />
                                    <br />
                                    <em>New Product Launch – Ashirvad Svasti</em>
                                </p>
                                <p>After completing this task, I found that there was one more surprise waiting for me – launching a new product. For this, we had to go to the market as a team to know the demand for the product. We spoke to retailers and customers as part of our market research. Following the market research, we launched our promotion campaign. We visited many marketplaces and camps to make the people aware about our new product and distributed samples of the upcoming product. The next phase of work comprised placing banners and posters for the new product in all existing retail stores and distribution of t-shirts for our retail staff.</p>
                                <h3>Other Works</h3>
                                <p>I was also tasked with profiling the Sales Report, while working with other documents, such as the Product Orders of the company. I learnt how product orders are recorded and matched in the sales report with various other documents. This type of work mostly dealt with working in spreadsheets using various formulae, which I had learnt during my classes at Globsyn Business School. During this task, I got to learn from many veterans of the company, especially the Territory Sales Officers and Regional Sales Officer.</p>
                                <p style={{ textAlign: "center" }}>
                                    <img className="img-fluid" src="/assets/images/With-Sales-Officer-and-Area-Distributor-Agent.png" alt="Summer-Internship-Experience-at-IT-Dairy" />
                                    <br />
                                    <em>With Mr Supriyo Sen, Sales Officer (extreme right) and Mr Suman Roy, Area Distributor Agent</em>
                                </p>
                                <h3>Onwards…</h3>
                                <p>I feel I am already in the right place, my seniors and supervisors who have guided me have been amazing to work with. They have shown what it means to handle a large and reputable company like ITC Dairy from the ground level. I am thankful to them for giving me all the support I needed and treating me as an employee, instead of just an intern. I would also like to thank my alma mater – Globsyn Business School, our CRP team, and my faculty mentor for their continuous support and contribution in my SIP journey.</p>
                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Krishna-Sankar-Bhattacharyya.png"
                                            alt="Krishna-Sankar-Bhattacharyya"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Krishna Sankar Bhattacharyya
                                            <br />
                                            PGPM-IB
                                            <br />
                                            Batch 2022-24
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
