import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function CodeRedHumanity() {
    return (
        <><title>‘Code Red for Humanity’ as Climate Change is Real</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">‘Code Red for Humanity’ as Climate Change is Real</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">‘Code Red for Humanity’ as Climate Change is Real</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/Website-blog-iamge_17_08_2021.png" alt="Code Red for Humanity" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>More than three decades ago, few scientists sanctioned by the United Nations (UN) first warned that humans were fueling a dangerous greenhouse effect, and that if the world didn’t act collectively and deliberately to slow Earth’s warming, there could be “profound consequences” for people and nature alike.</p>
                                <p>The scientists were right, but the world didn’t listen.</p>
                                <p>And today, our planet is burning, literally, with catastrophic outcomes! There are no ‘if’s, ‘but’s, or ‘maybe’s anymore. Climate change is here, as some of the facts given below:</p>
                                <ul className='disc'>
                                    <li>We have lost 65% of arctic ice since 1975*</li>
                                    <li>Our world’s carbon dioxide (CO2) concentration is the highest in two million years.</li>
                                    <li>Between 2011 and 2020, the global temperature was 1.09 degrees Celsius higher than the pre-industrial average.</li>
                                    <li>Natural calamities have increased more than ever before.</li>
                                    <li>There is not a single corner of the earth that is not touched by climate change.</li>
                                </ul>

                                <p>The sixth assessment report, considered as the most robust accounting of the climate crisis, by the UN’s Intergovernmental Panel on Climate Change (IPCC)** was published on August 9, 2021, and the stark assessment is sure to cause hiccups. Titled ‘Climate Change 2021 – The Physical Science Basis’, the sixth assessment report was authored by 234 scientists from 66 countries, and approved by 195 countries.</p>
                                <p>The report confirms the impending apocalypse on earth, from devastating floods because of extreme rain events to wildfires because of extreme heat and moisture loss to tropical cyclones because of the changing temperatures between the sea and land surface. The real version of the climate change is more intensifying, widespread, and rapid than the existing speculative version of it.</p>
                                <p>The most comprehensive assessment also says that there is “unequivocal” evidence that humans have pushed the climate into unprecedented territory, and are to be blamed for the rapidly rising land and ocean temperatures.</p>
                                <p>The IPCC report predicts the global surface temperature to elevate until at least the mid-century, considering all the current emission scenarios. Scientists have found enough data to confirm that global warming will likely exceed 1.5 degrees Celsius over the pre-industrial average in the next decade. And the United Nations is calling the consequences of that level of warming a “code red for humanity”.</p>
                                <p>A quick look at how the future might look like:</p>
                                <ul className='disc'>
                                    <li>The global water cycle will be more intensified due to global warming, including global monsoon precipitation, and the severity of dry and wet events.</li>
                                    <li>Under scenarios with increasing CO2 emissions, the ocean and land carbon sinks will be less effective at slowing the accumulation of CO2 in the atmosphere.</li>
                                    <li>The effect of global warming on the climate system will be higher than ever before, including the frequency and intensity of hot extremes, agricultural and ecological droughts, marine heatwaves, heavy levels of precipitations, intensification of tropical cyclones, as well as reductions in Arctic sea ice, snow cover and permafrost.</li>
                                    <li>Some of the impacts of the climate crisis, such as global sea-level rise are irreversible for centuries to millennia. Sea levels have risen by 20 cm already and will remain elevated for thousands of years as a result of ocean warming and melting ice sheets.</li>
                                    <li>The Indian Ocean is warming faster than the global average, and India will see massive floods in low-lying coastal areas, more frequent disasters in the Himalayan region and heavy rainfall events in the southern parts of the country.</li>
                                </ul>
                                <p>In the last 30-40 days, the results of the climate change should worry every citizen of this world.</p>
                                <ul className='disc'>
                                    <li>The heatwaves in the United States of America took many lives</li>
                                    <li>California is battling with the second-largest wildfire in the State’s history</li>
                                    <li>Canada has recently experienced the highest ever temperature as the mercury scale touched 50 degrees Celsius</li>
                                    <li>In British Columbia, more than a million shellfish were boiled alive in the ocean</li>
                                    <li>The bird’s eye view of Turkey has shown even deadly images of wildfires and their effects</li>
                                    <li>Venice was flooded by untimely rains</li>
                                </ul>
                                <p><b>Is there any hope?</b></p>
                                <p>Well, the truth is too bitter and perhaps too big to engulf. Climate change will affect all of us, irrespective of our geographic, social, cultural, and economic differences.</p>
                                <p>The scientists and environmentalists have been crying hoarse for decades about the looming catastrophe, but we never cared to listen. As a result, today the reality is – we, individually, locally, regionally can do nothing to stop climate change. Yet, the scientists who authored the report stresses that humanity’s actions can still determine the future of our planet. The need of the hour is the collaborative, global efforts of every individual on this planet to come together and take steps that will hopefully lessen the speed of this climatic catastrophe.</p>
                                <p>We must step out of our comfort zones, change our fundamental habits, speak out louder, educate our future generations to appreciate the natural world, fight for the earth rather than for individual need, take actions rather discuss on the roundtable, and be united!</p>
                                <p>Science has spoken. This is our last chance to act. Let’s not forget that Earth is the only home we have, yet. It’s time to save our planet.</p>
                                <br />
                                <p><b>Prof. (Dr.) Debaprasad Chattopadhyay</b><br />
                                    HOD & Faculty – Human Resource<br />
                                    Globsyn Business School</p>
                                    <br/>
                                    <p><em>*source: worldwildlife.org<br/>
                                   source: IPCC: Summary for Policymakers</em></p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
