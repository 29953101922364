import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function MarchingOnline() {
  return (
    <><title>Marching Online</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Marching Online</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Marching Online</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/blog-images.png" alt="Marching Online" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>“Nothing can be taught but everything can be learnt.”</p>
                                <p>This is most true for online education because in this, students have greater responsibility for learning. But that does not diminish the role of the teacher. Rather, it has expanded and includes dimensions that were never imagined earlier.</p>
                                <p>When the lockdown was clamped, I had some classes pending in the semester. The curriculum schedule has no buffer time to halt delivery and restart when convenient. We, therefore, had to migrate immediately from traditional format to digital.</p>
                                <p>Compared to many other institutions, Globsyn Business School has a certain advantage. We have been delivering online courses for executive learning for some time now, and I am the faculty resource for one of them. So we already had experiences of teaching online, but doing that during lockdown had additional challenges. This is where our Systems Engineering Group provided critical support working remotely via AnyDesk.</p>
                                <p>At the individual level also, I had to overcome challenges. My writing desk at home had been carefully placed to avoid light falling on my face. But now I needed just the opposite in order to be visible in the lecture videos. I scoured my house and tried various combinations of lamps and lampshades, and could get the right fix after first few attempts failed quality control tests.</p>
                                <p>Migration to online teaching enabled me to put into practice the Flip Classroom pedagogy. The traditional session is now split into halves, a lecture video followed by live online discussion. Students go through the lecture video at their own pace & space, and only then come for a live interaction. This improves the absorption of the lecture as well as the quality of discussion.</p>
                                <p>I am enjoying this teaching experience and also learning from it. I look forward to engage with more and more students in the new environment.</p>
                                <p><b>Prof. Manas Chakravarty</b><br />
                                Faculty – HR<br/>
                                Globsyn Business School
                                </p>
                                

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
