import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'


export default function KnowledgeCell() {
    return (
        <><title>Knowledge Cell - Globsyn</title>
            <section className="hero-banner-img-research">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Knowledge Cell</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Knowledge Cell</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table container">
                                <p>Post Graduate Management education is at a juncture where aspirants with different academic and socio economic background assemble and after a short duration disband towards multiple career streams. Coupled with this there is a need to constantly engage the students and provide them an intellectually challenging learning environment that moves beyond textbook based teaching. Thus the need to create knowledge and research has become the fulcrum of any institution that aspires to influence young minds and prepare them to face the constantly evolving business environment and bring into play new insights and ideas.</p>
                                <p>Globsyn Business School since its inception has a dedicated Knowledge Cell, manned by senior academicians, committed to continuously research and work in association with the faculty on emerging trends and best practices in management education. The Knowledge Cell ensures that students are exposed to the most contemporary program structure and course curricula based on inputs from the industry and academia. The team does continuous research to create Knowledge IPs in terms of live industry driven cases, research papers, articles and blogs and other knowledge components based on emerging trends and practices.</p>
                                <p>The team also is in charge of eGlobsyn – which is a comprehensive technology, learning management, socio business community enmeshment framework for students and faculty under one platform. The students access course material, appear for assessments and keeps themselves abreast with latest happenings in the institute through the portal.</p>




                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
