import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'


export default function TopManegmentDna() {
  return (
    <><title>Top Management DNA</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">Top Management DNA</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog"> Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to=""> Top Management DNA</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>

                    <div className="one-table">
                        <p>
                            <img className="img-fluid" src="/assets/images/Untitled-design.png" alt="" />
                        </p>
                        <div className="entry-header">
                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>Only seven of the first 50 Indian business families of 1947 were in business by the turn of this century, and 32 of the country’s largest business families of 1969 are no longer among the top 50 today. Why do some companies succeed while most atrophy, especially when market conditions change? The lesson is quite clear. Companies have high mortality rates.</p>
                        <p><b>Survival Challenges</b></p>
                        <p>Even globally, less than 10 per cent of the Fortune 500 companies as first established in 1955 still existed as on 2005. Therefore, what we are witnessing in India is not entirely a new phenomenon. In this context, management gurus like C.K. Prahlad advised companies to focus on their core-competencies; Sumantra Ghoshal stressed on ‘stick to the knitting’ as the road to successful internationalisation; Porter exemplified the concept of value chain to become world-class companies. While these mantras are talked about globally, they have left many top managers baffled and disillusioned when it comes to real-life applications. For instance, many Indian company heads glorify in the press that they have identified their ‘core competence’; when unknowingly they are talking about is their ‘core businesses’. Others talk about recreating independent SBUs (strategic business units) and giving considerable latitude to their business heads; at the same time following the map most companies follow, at best, appears very nascent and fuzzy. We offer some interesting insights based on a concept we call ‘dominant logic’ proposed by Prof. C K Prahlad. It is grounded in reality and can supplement existing practices of Indian companies to enable them to move on to the next level.</p>
                        <p><b>About Dominant Logic</b></p>
                        <p>Top managers most often decide on the basis of past experience or ‘what worked before’ and not on the basis of some ‘best’ strategy or optimising procedure. We observed that repeated reinforcements about how the environment acts and reacts leads to the development of various ‘thumb rules’. While positive reinforcements are rewarded, negative reinforcements are punished. Top managers of diversified families operate on multiple such thumb rules which act and interact among themselves to form the top management dominant logic’.</p>
                        <p>This concept is somewhat akin to the DNA that forms the human genome. While the DNA is largely responsible for human attitude and behaviour (among other things); on a similar scale the top management DNA guides how they take key decisions and untangle complex business situations. When faced with crises, they often rely on their DNA which greatly simplifies their task and decision-making process. Obviously, if managers were to systematically scan the entire environment for every decision, they would face severe time constraints. This is neither desirable nor feasible. The DNA automatically filters out irrelevant information. Therefore, for all practical purposes, it facilitates managers to selectively scan the environment and take appropriate decisions. In most cases the DNA is primarily influenced by the family’s core business, which was the driving force behind its evolution and existence. However, unlike human DNA which is predetermined at birth, top management DNA slowly evolves over time.</p>
                        <p><b>Conclusion</b></p>
                        <p>We would like to point out that just like the human genome; the influence of top management DNA is all-pervasive. In most cases, successors in family managed companies groomed by the older generation subconsciously adopt the same DNA (unlike in professionally managed companies). It influences the entire group, yet it remains invisible. It predisposes a group to certain kinds of problems but also offers a route to come out of those problems. In this transformation, three categories of business families come to our view:</p>
                        <p>The families that fail to see changes in the environment;<br/>
The families that see the changes in the environment, but are unable to act; and<br/>
The families that adapt on the face of a changing environment and successfully transform themselves.</p>
                        <p>The future of companies depends on how we restructure and adapt to our DNA. Their sustainability will depend on how they take strategic decisions aligned and compatible with heir DNA, preferably swaying away from opportunistic behaviour. Are we then suggesting then that emerging opportunities be left aside: here again the 80:20 rule comes to our mind. The top management should preferably devote 80% of their commitment and resources on existing businesses and 20% on emerging businesses.</p>
                    <p><b>Subir Sen</b><br/>
Principal<br/>
Globsyn Business School</p>
                    </div>

                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
