import React from 'react'
import { Link } from 'react-router-dom'
import '../Common.css'
import Sidebar from '../../Sidebar'

export default function ManagingTrustee() {
  return (
    <>
    <title>Managing Trustee’s Message</title>
            <section className="hero-banner-img-trustee">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Managing Trustee’s Message</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">Managing Trustee’s Message</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            
                            </div>
                            <div className="one-table">
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/BDG_Website.png" alt="" sizes="(max-width: 358px) 100vw, 358px" width="203" height="203" /></div>
                                <div className="one-table2">
                                    <p>From our modest beginnings in 2002, today Globsyn Business School is Eastern India’s only Corporate B-School, which has been ranked among the Top 50 B-Schools in India and Top 5 B-Schools in Eastern India, as per Business India’s Best B-Schools Survey 2020. Globsyn Business School’s PGDM program has been approved by AICTE, which is a major achievement for our students and alumni.</p>
                                    <p>Globsyn Business School has always been an aspirational model for those who see themselves doing well in life and is an expression of my dream to provide my students with a holistic, all-encompassing learning environment that not only motivates them to excel and perform but to be also comfortable with the technology driven world that we live in today. We have recently introduced the annual Vision Seminars series, which exposes the students to lectures on disruptive topics by iconic speakers. I am heartened that Globsyn Business School today reflects a holistic educational ecosystem that brings both educational best practices and real-life corporate exposure to give its students the right mix of management inputs for them to emerge as truly ‘Industry Ready’ and ‘Industry Relevant’ Managers.</p>
                                    <p>With our deep roots in technology, we at Globsyn have moved further by developing multiple applications that not only allow students to enrol into GBS from the comfort of their homes, but also helps them start their careers. The eGlobsyn platform merges curriculum, learning delivery, assisted and unassisted learning while bringing the social power of Web 2.0 to the students. Globsyn Business School is among the first institutions, globally, to issue its diplomas using Blockchain Technology, moving away from the age-old process of issuing paper certificates. Through our latest venture, Startup Café, we would like to foster budding entrepreneurs graduating from Globsyn Business School.</p>
                                    <p>Globsyn Business School believes in learning ‘Beyond Education’. And, it is through elements like Globsyn Management Conference, Globsyn Annual Lecture Series, Serendipity, EMBRYON, Kalyani Youth Leadership Forum and Globsyn Alumni Engage that we spread the concept of ‘Beyond Education’ among our stakeholders. While Globsyn Management Conference has been designed for academicians and Globsyn Annual Lecture Series is aimed at corporates, Globsyn Alumni Engage provide the alumni with a platform to re-connect with their alma mater. Serendipity is a learning experience for our students, EMBRYON nurtures the spirit of entrepreneurship and Kalyani Youth Leadership Forum allows students to ‘care for society’ – it is these elements that fosters a spirit of exploration, learning and self-discovery while mentoring dedicated, compassionate and understanding managers and leaders for tomorrow’s business world.</p>
                                    <p>It will need all our collective efforts to make Globsyn Business School a vibrant place to live, learn and work – a place that will someday yield the best decision makers for the corporate world.</p>
                                    <p>I thank you for showing an interest in us and we look forward to having you in our midst soon.</p>
                                </div>
                                <br/>
                            <img src="./assets/images/bdg-Sign-short.png" alt=""  width="88" height="40"></img>
                            <figcaption className="vc_figure-caption">Bikram Dasgupta</figcaption>
                            </div>                          
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
            </>
  )
}
