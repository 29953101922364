import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function WhyImplementingOutcome() {
    return (
        <><title>Why implementing Outcome Based Education is the call of the hour?</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Why implementing Outcome Based Education is the call of the hour?</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Why implementing Outcome Based Education is the call of the hour?</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/blog-image.png" alt="Why implementing Outcome Based Education is the call of the hour?" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>At present, the Outcome Based Education or the OBE system is being widely used by leading institutions across the world which has developed a curiosity to understand the reason behind the ongoing learning transformation. The OBE system has proven to be a success in helping institutions to measure their learning outcomes and at the same time enabling students to develop new skills that prepare them to stand out with their counterparts around the world.</p>
                                <p>However, the questions that arise are what is Outcome Based Education? How is it different from the traditional education system? Why are the leading institutions around the world adopting the OBE system?</p>
                                <p>The traditional education system is highly dependent on theoretical aspects of learning and hardly provides any chance for students to develop new skills which might be useful for building their careers. In a traditional system, teachers are more focused on completing the curriculum and allocate the time in doing so, whether or not the students master it.</p>
                                <p>The differences between Traditional Education and OBE system can be categorized in the following way –</p>
                                <table style={{ height: "96px" }}>
                                    <tbody>
                                        <tr>
                                            <td><span><strong>Parameter</strong></span></td>
                                            <td><span><strong>Traditional education</strong></span></td>
                                            <td><span><strong>Outcome Based Education</strong></span></td>
                                        </tr>
                                        <tr>
                                            <td>Curriculum Planning and Designing</td>
                                            <td>The same curriculum is passed from the previous generation to the new generation with minor or no modification</td>
                                            <td>The curriculum is designed and planned as per the needs of the student’s and the industry</td>
                                        </tr>
                                        <tr>
                                            <td>Teaching Process</td>
                                            <td>The faculty is focused on completing the curriculum by the end of the semester/term</td>
                                            <td>Teachers are focused on guiding students to acquire the required knowledge and develop the desired skills</td>
                                        </tr>
                                        <tr>
                                            <td>Final Outcome Assessment</td>
                                            <td>The Outcome of the students can be assessed as per their obtained grades</td>
                                            <td>The assessment is done at different levels throughout the duration of the term to track their learning, skills and attainment level of the desired outcomes</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <p>Outcome Based Education is a learning paradigm that is learner-centric as it is the responsibility of the learner to develop the required skills to reach the target/outcome. It is a step of transformation from merely teaching certain knowledge content or syllabi to ensuring certain competencies are being developed in students and the desired outcomes are being achieved.</p>
                                <p>Outcomes include knowledge, skills and attitudes. OBE system focuses on student learning by –</p>
                                <ul>
                                    <li>Recognizing and defining the learning outcomes to make explicit what the student is expected to know, understand or do and then organize their curriculum and the teaching-learning process accordingly</li>
                                    <li>Providing learning activities which will help the student to reach these outcomes</li>
                                    <li>Assessing the extent to which the student meets these outcomes through the use of explicit assessments like exams, assignments, presentations, case study analysis, projects, alumni/employer feedback, etc.</li>
                                </ul>
                                <p>To Outcome Based Education framework is beneficial to all stakeholders of an educational institution as it follows the following ground principles –</p>
                                <p><b>Clarity of Focus</b> – Clarity on what is expected from students in each program; subject or even chapter/module creates the right expectation and provides the right direction to the teaching learning process. In the Outcome – Based Approach, the faculty defines specific outcomes that give a strong sense of purpose to all the teaching and learning process. The outcomes are defined earlier and hence the students and teachers can act and strategize well to achieve the end goals or outcomes.</p>
                                <p><b>Design Down and Deliver Up</b> – When formulating the curriculum, educators start with the outcomes and work backwards; when planning instruction, teachers teach what students need to learn to demonstrate the outcomes. It means the curriculum has to be designed with a clear definition, outlining the expected outcomes which pave a way to achieve the expanded opportunities in the student’s performance.</p>
                                <p><b>Flexibility </b>– OBE allows flexibility to the faculty with respect to how they teach and what they teach. OBE does not restrict the teachers to teach using a particular methodology of teaching. OBE is a student-centered learning model and teachers are meant to guide and facilitate the student’s learning by adopting creative methods</p>
                                <p><b>Comparison</b> –Different institutions can be compared on the basis of OBE. At an individual level, the attainment of students can be used to decide what outcome they have reached.
At an institutional level, inter-university/institute comparison can be done based on learning achieved by the students. This allows employers to easily gauge what to expect from the graduates.</p>
                                <p><b>Involvement</b> – Engagement of the students in the classroom is a key part of OBE. Increased student involvement allows students to feel that they are responsible for their own learning. The OBE system allows all stakeholders to be engaged since they know the desired outcomes that need to be achieved.</p>
                                <p>OBE, therefore, designs the teaching-learning process in a way which leads to specific outcomes. It directly leads to enhancing the proficiency of a particular skill, knowledge, or behavior of the student. OBE is a necessity of the hour and should be implemented in each and every field wherever development of skills and acquiring of knowledge are concerned.</p>
                                <p><b>The GBS Angle</b></p>
                                <p>Globsyn Business School (GBS) seamlessly integrates Outcome Based Education system in its academic delivery to ensure that the students achieve the desired outcomes at the end of each course and the 2-year program. Our programs provide understanding and aim to develop functional, technical and human skills of management graduates and equip the students with in-depth exposure and skills to match with the dynamic business world. GBS constantly endeavors to update the curriculum, benchmark the academic delivery against best standards/practices and create a competitive environment for the students. The courses are intellectually demanding, academically challenging and are taught by our experienced faculty who encourage innovative thinking and active participation through experiential learning methodology. GBS ensures that the students acquire the required knowledge and develop the desired skills tomake a mark in their career and become promising managers.</p>
                                <p><b>Oindrila Roy</b><br/>
                                Knowledge Cell
                                </p>
                                <em>References</em>
                                <ul className='disc'>
                                    <li>Image Source – https://www.youtube.com/watch?v=RszkeDU3-DY</li>
                                    <li>http://www.ascd.org/publications/curriculum_handbook/413/chapters/An_Overview_of_Outcome-Based_Education.aspx</li>
                                    <li>https://pdfs.semanticscholar.org/633f/5cec1a61682483b2fd3ec78ddfb90d51f417.pdf</li>
                                    <li>https://education.stateuniversity.com/pages/2304/Outcome-Based-Education.html</li>
                                    <li>https://www.myklassroom.com/blog/4-things-to-know-about-outcome-based-education/</li>
                                </ul>  
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
