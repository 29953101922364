import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function MomentCherish() {
    return (
        <><title>A Moment to Cherish – Mango Magic 2022</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">A Moment to Cherish – Mango Magic 2022</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">A Moment to Cherish – Mango Magic 2022</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/collage-1.png" alt="A Moment to Cherish – Mango Magic 2022" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}><b>Editor’s Note</b><br /><em>Teaching and learning experiences that take place outside the confines of classroom walls have been gaining importance over time. The academic world has required students to put into practice their classroom learning in the real world, and theorize knowledge beyond education activities. This student-centric learning experience has enhanced learning and fostered personal and social development (Larsen, Walsh, Almond, & Myers, 2017)</em></p>
                                <p>The day started with a clear blue sky and bright sunshine, but since it is the Monsoon season, stray rainfall in bits and spurts are a given. However, through the rain and sun, my classmates and I with the support and guidance of some of our faculty, enthusiastically took part in the ‘Mango Magic’ – a ‘Joy of Giving’ project of Kalyani Youth Leadership Forum, a Beyond Education initiative of Globsyn Business School. Through this initiative, every year, students of GBS distribute mangoes to the underprivileged street-children, and educate them about the benefits of staying healthy.</p>
                                <iframe width="800" height="450" src="https://www.youtube.com/embed/1I_8m9iu-t8" title="Mango Magic 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                <p>On that day, we celebrated the spirit of selfless giving and not only distributed mangoes, but also experienced the joy of sharing, and educated the less privileged sections of the society on the importance of hygienic eating. Eating mangoes alone or with family is always a pleasure, but this time I experienced another level of happiness as we shared our happiness with the day workers, laborers, and their children who work for us day and night and expect nothing from us. The sight was overwhelming for us when we saw the unimaginable amount of happiness and joy those children felt, after receiving their presents.</p>
                                <p>
                                    <img className="img-fluid" src="/assets/images/img_21.png" alt="A Moment to Cherish – Mango Magic 2022" />
                                </p>
                                <p>The whole event started as we planned. First, we as a team along with two cartons of mangoes, went to Phoolbagan to meet the workers and their families. Upon reaching there and meeting them, we washed the mangoes properly, cut them into slices, and shared the fruits with them. I won’t be able to explain how blessed we felt as we sat on the pavement and ate the mangoes together, sharing slices between us, all the while maintaining hygiene and cleanliness. After Phoolbagan, we did the same activity at three other different locations in Kolkata – Rashbehari, Esplanade, and Lake Kalibari. The smile that we saw on their faces was our biggest satisfaction and greatest happiness.</p>
                                <p>
                                    <img className="img-fluid" src="/assets/images/img_3.png" alt="A Moment to Cherish – Mango Magic 2022" />
                                </p>
                                <p>We faced a few minor challenges, especially with the weather playing spoil sport, but with the help of our faculty team and thanks to the management skills that we have learned at GBS, we were able to overcome these obstacles in no time. The initiative has been a great success as children learned the true meaning of inclusiveness and caring for one another. The happy faces of the kids recapitulated the outcome of the event. I would like to thank GBS for providing us with this experience, which helped us to understand that instead of spending time in a self-centered way, we should devote some of our time for the well-being of others.</p>
                                <p>
                                    <img className="img-fluid" src="/assets/images/img_1-2.png" alt="A Moment to Cherish – Mango Magic 2022" />
                                </p>
                                <p>Proud to be a part of Globsyn Business School!</p>
                                <div className="one-table">
                                    <div className="one-table1"><img className="img-fluid" src="/assets/images/Untitled-design-1-150x150.png" alt="tt" width="150" height="150" /></div>
                                    <div className="one-table2">
                                        <p>Namrata Dutta<br />
                                            President<br />
                                            Kalyani Youth Leadership Forum<br />
                                            Batch 2021-23<br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
