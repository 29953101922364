import React from 'react'
import Sidebar from '../../Sidebar'
import { Link } from 'react-router-dom'


export default function Klyf() {
    return (
        <><title>Kalyani Youth Leadership Forum</title>
            <section className="hero-banner-img-sceren">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Kalyani Youth Leadership Forum</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Kalyani Youth Leadership Forum</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p style={{ textAlign: 'center' }}><iframe className="w-100" height="450" src="https://www.youtube.com/embed/4PZ6jGWa5OQ" title="Best Private B-School in Eastern India - Globsyn Business School | Times Brand Icons 2022" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
                                <br></br>
                                <p>Formed by the students of Globsyn Business School, Kalyani Youth Leadership Forum (KYLF) help the young minds to imbibe the practice of selfless social work as an integral part of their lives, while internalizing humane skills necessary to become complete managers. The ‘Care for Society’ initiative provides an opportunity to students to contribute towards the betterment of society as well as develop themselves as dedicated & compassionate managers and better human beings.</p>
                                <p>AICTE recognises the ‘Care for Society’ initiative of GBS as a ‘Best Practice’ among its Approved Institutions.</p>
                                <button className='btn'><a href='./assets/pdf/FINAL BEST PRACTICES IN AICTE APPROVED INSTITUTUIONS.pdf'>Best Practices in AICTE Approved Institutions</a></button>
                                <br />
                                <br />
                                <br />
                                <p>KYLF primarily works in the following verticals, namely:</p>                             
                                <div className="accordion" id="myAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Elderly Care</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Feeling lonely can be fleeting, felt for just a day, or it can be for a longer period. With this realization, KYLF student members share precious moments every quarter with the elderly residents of Tollygunge Old Age Home and St. Joseph’s Home, as well as annual visits to Subhasgram Old Age Home celebrating days of national and personal significance and religious festivals; which not only provide the elderly people a respite from their loneliness but also take them to a world of joy and immeasurable happiness.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingtwo">
                                            <button type="button" className="accordion-button collapsed " data-bs-toggle="collapse" data-bs-target="#collapsetwo">Differently-Abled Care</button>
                                        </h2>
                                        <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Differently-abled adults have the capacity to lead independent lives. However, in today’s society, they are perceived more as a burden, especially in rural India. Seeing the sheer number of such people and observing how families find it difficult to cope with disabilities, KYLF works closely with organizations like ‘Bodhayan’ in conducting different events, enjoyment trips with the purpose of showering joy and happiness to the differently-abled people, as well as their families. Apart from these events, KYLF members also organize Summer Camps during the summer and Sports Day during winter for the Bodhayan kids. They also organize numerous fun-filled picnics for these differently abled people, along with their family members. These picnics are like a whiff of fresh air in the lives of these genteel souls whose joys know no bounds when they participate in the myriad of activities that the members of KYLF plan for them.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingthree">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Channelizing Youth</button>
                                        </h2>
                                        <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>KYLF, a forum made up of young minds, believes in the power of the youth to bring about social transformation and believes that the first step lies in providing the under-privileged with the opportunity and a platform to help engage closely with society. The Forum makes an attempt to raise the level of awareness amongst the youth to work towards creating an inclusive society i.e. a society where all stakeholders will live with respect, dignity and sensitivity for each other.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <h3><strong>Care for Society Activities</strong></h3>
                                    <p>As a part of their core charter, every year KYLF organises their signature event, called ‘Kalyani Ananda Utsav – An Inter School Children’s Carnival’, where over thousands of participants including students, mothers and teachers from several schools take part in different kinds of competitions such as ‘Sit and Draw’, ‘Go as You Like’ (fancy dress) amongst others.</p>
                                <p>Throughout the year, the KYLF volunteers extend themselves towards building equality in society and bringing a new social order by working tirelessly and organizing events such as:</p>
                                <ul className='disc'>
                                    <li>Blood Donation Camp & Cancer Awareness</li>
                                    <li>Organizing an Eye Screening Camp for 400 underprivileged children in a school at Amtala</li>
                                    <li>Celebrating ‘Herbal Holi’ for the cancer-stricken children at a Child Care Centre</li>
                                    <li>Organizing numerous fun-filled picnics for the differently abled residents of Bodhayan, along with their family members</li>
                                    <li>Celebrating birthdays of senior citizens, and other calendar events like Independence Day and Christmas, at St. Joseph’s Old Age Home and Tollygunge Homes</li>
                                    <li>Organizing events like ‘Joy of Giving’ and ‘Lighting Up Smiles’ for the Globsyn Support Staff, as well as for the under privileged sections of the society</li>
                                    <li>Organizing ‘Kerala Donation Drive’ for the flood victims sending out food, clothes and other utility items for more than 1000 people</li>
                                    <li>Celebrating  the spirit of summer and spreading unadulterated joy among the street children through ‘Mango Festival’; amongst others</li>
                                </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
