import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function Customstudy() {
  return (
    <>
            <title>Custom Study Path - Globsyn</title>
            <section className="hero-banner-img-pgdm">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Custom Study Path</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">Custom Study Path</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                               
                            </div>
                            <div className="one-table">
                                <div className="one-table1"></div>
                                <div className="one-table2">
                                    <p>At Globsyn Business School’s post graduate programmes in management, we believe in providing tomorrow’s management leaders with the option to choose their area of studies and excel in their respective domains. While the first year consists of compulsory papers, second year students are provided with a plethora of choices to cross-skill themselves and be adequately armed to face the competitive business environment. For instance, while one may choose to major from the ‘School of Marketing Excellence’, while choosing to study select finance papers of one’s choice.</p>
                                    <p>The entire exercise of creating a customised study path is governed by some over arching rules. The most significant of which are:</p>
                                    <ul className='disc'>
                                        <li>To graduate from Globsyn’s post graduate programmes in management, students need to successfully complete the following choice of credits:</li>
                                        <ol style={{listStyleType:"lower-alpha"}}>
                                            <li><strong>PGDM</strong>: Complete 47 credits of Compulsory courses and opt for 12 credits from Major and 6 credits from Minor courses, across the different Schools of Excellence (Major & Minor)</li>
                                            <li><strong>MBA (Global)</strong>: Complete 41 credits of Compulsory courses and opt for 6 credits from Major in International Business along with 6 credits from Major courses of any one School of Excellence (Dual Major)</li>
                                        </ol>
                                        <li>All students must, compulsorily, choose from either of the Schools of Excellence that are on offer/operational</li>
                                        <li>Admission into a School of Excellence would be based on merit and aptitude of the student</li>
                                        <li>The choice of papers to study has to be finalised in Semester II (first year). Students can finalise their choices based on the presentations made by the faculty for each School of Excellence/paper group</li>
                                        <li>The B-School retains the right to offer a paper in a semester of its choice</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
