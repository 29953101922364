import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../Sidebar'


export default function Mba() {
    return (
        <>
            <title>AICTE Approved PGDM Course in Kolkata | Enroll at Best PGDM College</title>
            <section className="hero-banner-img-pgdm">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">MBA(Global)</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">MBA(Global)</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                                
                            </div>
                            <div className="one-table">
                                <div className="one-table2">
                                    <p>Innovation lies at the heart of a truly global company. Global managers today have to look beyond business geography as a conglomeration of national marketplaces, but as an invaluable source of knowledge, information, skills and expertise – the key requirements in the development and diffusion of innovation worldwide.</p>
                                    <p>Business corporations today need global business managers who can represent the need for an integrated worldwide strategy and more efficient standardised products. Global business managers would need to have the perspective that would allow them to look across the world and continuously explore opportunities for economies.</p>
                                    <p>Amongst the best colleges for MBA in International Business, the Master of Business Administration (Global) programme has been designed to impart students with a learning experience that would expose them to dedicated sessions covering global case studies. This unique upmarket programme of Globsyn Business School – considered to be among the top international MBA programs in Kolkata and India – offers specialization in International Business, in partnership with Malaysia University of Science and Technology (MUST), for those who harbour global ambitions and see themselves as an integral part of the global corporate environment, irrespective of the geography they will be working in.Designed for the quintessential high flyer, a significant part of this programme with international business specialization in MBA is at par with any global MBA courses. Ranked amongst the top MBA colleges in Kolkata, the MBA (Global) programme is dedicated to provide students with a truly global management education experience with classes by international faculty, giving the young managers a bird’s-eye view of the globalised economic environment.Aimed at students who see themselves making a mark as hands-on ‘Indian’ managers – a strong breed of management professionals who are increasingly leading MNC brands into nascent and emerging markets, the PGDM programme is designed to provide career options for students that are virtually limitless with avenues to work in a variety of fields ranging from Government assignments to NGO’s etc.</p>
                                    <p>Designed for the quintessential high flyer, a significant part of this programme with international business specialization in MBA is at par with any global MBA courses. Ranked amongst the top MBA colleges in Kolkata, the MBA (Global) programme is dedicated to provide students with a truly global management education experience with classes by international faculty, giving the young managers a bird’s-eye view of the globalised economic environment.</p>
                                    <p><strong>Duration:</strong> 2 years Full Time Programme</p>

                                    <strong style={{ fontSize: '20px' }}>Curriculum</strong>
                                    <h4>Semester-I</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Sl. No.</strong></td>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Link</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>1</strong></td>
                                                <td>Financial & Management Accounting</td>
                                            </tr>
                                            <tr>
                                                <td><strong>2</strong></td>
                                                <td>Managerial Economics</td>
                                            </tr>
                                            <tr>
                                                <td><strong>3</strong></td>
                                                <td>Legal Aspects of Business</td>
                                            </tr>
                                            <tr>
                                                <td><strong>4</strong></td>
                                                <td>Management Functions and Organizational Behaviour</td>
                                            </tr>
                                            <tr>
                                                <td><strong>5</strong></td>
                                                <td>Marketing Management - I</td>
                                            </tr>
                                            <tr>
                                                <td><strong>6</strong></td>
                                                <td>Business Communication</td>
                                            </tr>
                                            <tr>
                                                <td><strong>7</strong></td>
                                                <td>Professional Skills Development - I</td>
                                            </tr>
                                            <tr>
                                                <td><strong>8</strong></td>
                                                <td>Computer Applications - I</td>
                                            </tr>
                                            <tr>
                                                <td><strong>9</strong></td>
                                                <td>Industry 4.0 </td>
                                            </tr>
                                            <tr>
                                                <td><strong>10</strong></td>
                                                <td>Basic English - I</td>
                                            </tr>
                                            <tr>
                                                <td><strong>11</strong></td>
                                                <td>Basic Maths - I</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h4>Semester-II</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Sl. No.</strong></td>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Link</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>1</strong></td>
                                                <td>Financial Management and Corporate Finance</td>
                                            </tr>
                                            <tr>
                                                <td><strong>2</strong></td>
                                                <td>Managerial Economics & Global Business Environment</td>
                                            </tr>
                                            <tr>
                                                <td><strong>3</strong></td>
                                                <td>Marketing Management - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>4</strong></td>
                                                <td>Human Resource Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>5</strong></td>
                                                <td>Business Process Automation and Advanced Data Analytics</td>
                                            </tr>
                                            <tr>
                                                <td><strong>6</strong></td>
                                                <td>Business Research Methods & Applications</td>
                                            </tr>
                                            <tr>
                                                <td><strong>7</strong></td>
                                                <td>Quantitative Methods - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>8</strong></td>
                                                <td>Global Business Strategy</td>
                                            </tr>
                                            <tr>
                                                <td><strong>9</strong></td>
                                                <td>Operations Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>10</strong></td>
                                                <td>Professional Skills Development - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>11</strong></td>
                                                <td>Computer Applications - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>12</strong></td>
                                                <td>Industry 4.0</td>
                                            </tr>
                                            <tr>
                                                <td><strong>13</strong></td>
                                                <td>Basic English - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>14</strong></td>
                                                <td>Basic Maths - II</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h4>Semester-III</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Sl. No.</strong></td>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Link</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>1</strong></td>
                                                <td>Computer Applications - III</td>
                                            </tr>
                                            <tr>
                                                <td><strong>2</strong></td>
                                                <td>Professional Skills Development - III</td>
                                            </tr>
                                            <tr>
                                                <td><strong>3</strong></td>
                                                <td>Digital Analytics</td>
                                            </tr>
                                            <tr>
                                                <td><strong>4</strong></td>
                                                <td>Language classes (French/Spanish/German/Mandarin etc.)</td>
                                            </tr>
                                            <tr>
                                                <td><strong>5</strong></td>
                                                <td>Basic English - III</td>
                                            </tr>
                                            <tr>
                                                <td><strong>6</strong></td>
                                                <td>Basic Maths - III</td>
                                            </tr>
                                            <tr>
                                                <td><strong>7</strong></td>
                                                <td>Specialization I – International Business</td>
                                            </tr>
                                            <tr>
                                                <td><strong>8</strong></td>
                                                <td>Specialization II – Finance/Marketing/HR/Operations</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h4>Semester-IV</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Sl. No.</strong></td>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Link</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>1</strong></td>
                                                <td>Corporate Strategy</td>
                                            </tr>
                                            <tr>
                                                <td><strong>2</strong></td>
                                                <td>Business Law & Ethics</td>
                                            </tr>
                                            <tr>
                                                <td><strong>3</strong></td>
                                                <td>Computer Applications - IV</td>
                                            </tr>
                                            <tr>
                                                <td><strong>4</strong></td>
                                                <td>Dissertation in International Business</td>
                                            </tr>
                                            <tr>
                                                <td><strong>5</strong></td>
                                                <td>Specialization II – Finance/Marketing/HR/Operations</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <strong>Two Credits is allotted to Beyond Education Activities which is spread across all four semesters. </strong>
                                    <p>Students pursuing the programme will have a dual major – International Business and a choice between Marketing / Finance / HR / Operations.</p>
                                    <h4>Global Immersion Programme</h4>
                                    <p>An integral part of the inter-disciplinary Master of Business Administration (Global) programme, the Global Immersion Programme* (GIP) exposes students to a global environment coupled with intensive classes, case studies, projects and dissertations. The entire GIP module has been modelled to put into real-life context the entire gamut of management learning that the students have assimilated during their academic discourses.</p>
                                    <p><img className='ing-fluid' src='./assets/images/GIP-Image.jpg' alt='gipimage' /></p>
                                    <h4>About Malaysia University of Science & Technology (MUST)</h4>
                                    <p>Malaysia University of Science and Technology (MUST) was set up by the Malaysian government in 1997 in collaboration with MIT, USA and rated 5 Star for Teaching and Employability by the prestigious QS University World Rating.
                                        <br />MUST has a wide variety of programs both at undergraduate as well as postgraduate levels that emulate world className method of teaching and learning. These programmes encourage creativity, analytical thinking, problem-solving, innovation and team-building, which have proven to be successful in producing entrepreneurial leadership in technology and business. All programs are approved by the Ministry of Higher Education in Malaysia and also have the approval of the Association of Indian Universities. One of the leading universities in Malaysia, from award winning staff to unique teaching pedagogy, MUST is truly equipped to be the university of choice for students who are looking to make a brighter future.
                                        <br />The University is aggressively moving towards establishing international collaborations with partners from Nigeria, Gambia, Myanmar, Vietnam, Sri Lanka, Indonesia and India. This collaboration in Kolkata is a part of this ongoing thrust and the University hopes to bring the same passion for excellence that all its partners have come to expect.</p>
                                    <p className='d-flex justify-content-center'><img width="595" height="246" className='ing-fluid' src='./assets/images/PGPIB.png' alt='gipimage' /></p>
                                    <em className='d-flex justify-content-center'>Signing of the MoA between Globsyn Business School and Malaysia University of Science & Technology</em>
                                    <br/>
                                    <h4>Eligibility</h4>
                                    <ul className='disc'>
                                        <li>Graduate from Recognised University</li>
                                        <li>A valid CAT/MAT/XAT/JEMAT scorecard</li>
                                    </ul>

                                    <br/>
                                    <em><span style={{fontSize:"12px"}}>Classes for this programme are conducted at the Corporate Office.</span></em>
                                
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
