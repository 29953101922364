import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function COVIDPartTwo() {
  return (
    <><title>COVID-19: Trending Terminologies – Part 1</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">COVID-19: Trending Terminologies – Part 2</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog">Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="">COVID-19: Trending Terminologies – Part 2</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>
                    <div className="one-table">

                        <p>
                            <img className="img-fluid" src="/assets/images/STOP-CORONAVIRUS_800x400.jpg" alt="COVID-19: Trending Terminologies – Part 2" />
                        </p>
                        <div className="entry-header">
                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>Welcome back! Or, if you’re a new reader, you might want to read the first part of this series here! Keeping in tune with the first part, this second part will highlight several new terms that have emerged consequentially in association with the coronavirus disease. Let’s take a glance at the glossary of words that have been compiled to enrich our COVID-19 vocabulary.</p>
                    <p><b>Personal Protective Equipment (PPE)</b></p>
                    <p>Personal Protective Equipment or PPE is identified as a specialised type of clothing or equipment that is worn or used by one as an effective shield for protecting the body from the incidence of infectious diseases. PPE serves individuals by means of protecting them from exposure to diseases.</p>
                    <p><b>Asymptomatic</b></p>
                    <p>Asymptomatic refers to the lack of symptoms. With regards to COVID-19, asymptomatic individuals are such that do not reflect potential symptoms like cough, fever, sore throat, breathlessness, amongst others. On the other hand, symptomatic individuals reflect potential symptoms of a disease. In case of COVID-19, symptomatic cases showcase symptoms like fever, weakness, sore throat, sneezing, and redness of eyes amongst others.</p>
                    <p><b>Comorbidity</b></p>
                    <p>Comorbidity is identified as a situation where a body tends to bear two or more than two diseases during the same period. The diseases though simultaneously occurring within the body yet are generally observed to be diagnostically independent of each other.</p>
                    <p><b>Antibody</b></p>
                    <p>Antibody is identified as a blood protein that is produced by our immune system to identify and neutralize pathogenic objects such as viruses.Individuals who have recovered from COVID-19 develop antibodies to the virus which further could be used to help researchers develop vaccines to prevent infection.</p>
                    <p><b>Immunocompromised</b></p>
                    <p>Individuals that are found to suffer from a weak immune system owing to a lack of needed white blood cells and thereby become easily susceptible to infectious diseases are recognised to be Immunocompromised. These immunocompromised individuals owing to lack of immunity feel it difficult to fight the incidence of potential diseases.</p>
                    <p><b>Herd Immunity</b></p>
                    <p>Herd Immunity refers to the holistic immunity gained by a specific percentage of a population from the incidence of an infectious disease. Herd Immunity occurs in cases of individuals that were ill and have now recovered or such that have got duly vaccinated.</p>
                    <p><b>Black Fungus</b></p>
                    <p>Black Fungus is a term that is used commonly for naming the disease, Mucormycosis. The fungal disease, Mycosis, caused by a Mucor makes its name like that. The invasion of Mucormycosis to the tissues happens to affect the flow of blood to such which in turn makes them black in colour.</p>
                    <p><b>Yellow Fungus</b></p>
                    <p>Though the term, Yellow Fungus is coined in parlance to black and white fungus yet is identified not to bear such medical significance. The colour of pus is yellow in nature and pus is formed in areas wherever there is any type of bacterial infection. Though there maybe fungal growth also but it lacks needed significance to be worried of.</p>
                    <p><b>White Fungus</b></p>
                    <p>White Fungus is caused by the yeast called Candida. It grows in laboratories and also in humans. In the case of humans, the white fungus appears as white spots on the mucosa area of the oral cavities.</p>
                    <p>The lists of terminologies covered in two parts are prepared in a composite manner for providing readers with an effective idea about COVID-19 disease and its impacts. Are there any terms that we missed? Please share your thoughts in the comments below.</p>
                    </div>

                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
