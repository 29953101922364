import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function SipCarvyStock() {
  return (
    <><title>Internship with Karvy Stock Broking Limited</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">Internship with Karvy Stock Broking Limited</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog">Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="">Internship with Karvy Stock Broking Limited</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>
                    <div className="one-table">
                        <div className="entry-header">
                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>My name is Chandni Sharma and I’m pursuing my PGDM from Globsyn Business School (GBS). As an integral part of our curriculum, a 10-12 weeks SIP opportunity is extended to all first-year students of the school, which allows us to apply our knowledge, and improve our interpersonal skills, whilst working with various national and multinational organizations across multiple sectors in the real world.</p>
                        <p>My internship experience with Karvy Stock Broking Limited as a Finance Intern has been an eye opening opportunity. Till now I have been exposed to several different departments involving various aspects of finance allowing me to experience working on a variety of tasks. The projects I have been given have allowed me to apply my theoretical knowledge to practical experiences, as well provided me with the opportunity to learn several new skills that I was not exposed to previously.</p>
                        <p>During my SIP at Karvy, I learned countless things about how business work, about day-to-day proceedings in an office and about corporate life in general. I performed several important tasks specific to my interest in the Risk Management field, including training on products and essentials of capital market, equity markets, investment and trading Pitching to clients and learning from the experience on sales presentation. Karvy has not only given me the tools to be a successful professional, but also gave me these tools in a laid-back, fun environment in which everyone genuinely enjoys working. I have also learned how to meet with potential customers explain them the benefits, services and options available in stock market, close a sales call and gain real perspective. They have assigned me tasks which include doing an awareness programme for companies and housing society so that people can invest, earn and learn about stock market.</p>
                        <p>I would highly recommend this experience to anyone pursuing a career in the field of Finance, as at Karvy there is something for everyone. I would also like to thank Globsyn Business School for giving me such a wonderful experience.</p>
                        <br/>
                        <p><strong>Chandni Sharma</strong></p>
                        <p>PGDM Batch-08, Globsyn Business School</p>
                        <p >If you have enjoyed reading this blog, then you will like reading Globsyn Business School’s student, Shagufta Farheen’s  (PGDM Batch – 8) blog: <Link to="/blog/summer-internship-program-at-kelloggs" style={{fontSize:"10pt"}}>Summer Internship Program at Kellogg’s</Link> </p>


                    </div>

                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
