import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function AnalyzeWaySuccess() {
    return (
        <><title>Analyze Your Way to Success</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Analyze Your Way to Success</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Analyze Your Way to Success</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/analyze-your-way-to-success.jpg" alt="Analyze Your Way to Success" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>‘Business Analytics’ is the process of using statistical tools and technologies to analyze historical data and gain important insights which eventually aid in strategic decision making.</p>
                                <p>Business Analytics has been around for a long time. It was originally developed during World War II by the military to help them make strategic decisions, but now businesses around the world are using it to gain useful insights into their own business, which can be used to make better decisions and ultimately improve their performance. This is what we call ‘Business Intelligence’ or BI.</p>
                                <p><b>How does it work?</b></p>
                                <p>Business Analysts use these tools and technologies in order to identify trends among groups of people by analyzing their behavior patterns over time so that they can spot any changes that might indicate potential problems before they become serious issues for the company itself!</p>
                                <p><b>A Career in Business Analytics</b></p>
                                <p>In this era of intelligent business and data driven decisions, small businesses to large scale corporations hire business analysts to interpret large datasets to predict smallest business decisions like which product to sell, to whom to sell and to sell them at how much. For example, a small apparel business incorporated in 2021 headquartered at Kolkata is willing to hire an aspiring Business Analyst at an entry level position for a whopping 8 LPA to predict the latest trends in fashion.</p>
                                <p>According to Mordor Intelligence, the Business Analytics Market was valued at USD 67.92 billion in 2020, is expected to reach USD 103.65 billion by 2026, and work at a CAGR of 7.3% over the forecast period (2021-2026). As reported by U.S Bureau of Labour Statistics (BLS), the employment market for Business Analysts will witness a growth of upto 25% by 2030. It projects that from 2019 to 2029, the demand for Market Research Analysts will grow by 18%, much faster than the growth rate for all professions combined (4%).</p>
                                <p>The salary of a Business Analyst varies depending on specializations. IT Business Analysts earn an average annual salary of $68,364 whereas those with hands on experience in Oracle applications can earn an average of $70,164.</p>
                                <p><b>Benefits of a Business Analytics Career</b></p>
                                <p>Data is the new buzz word. Managers who have the who have the skill to analyze huge data & use it for critical decision making by designing predictive models are in huge demand. A Business Analyst should have a good understanding of different business concepts like marketing strategy and financial planning, which makes them valuable team members within an organization. Hence a career in Business Analytics is both rewarding and challenging.</p>
                                <p><b>Kick-start your career in Business Analytics with Globsyn Business School</b></p>
                                <p>Globsyn Business School (GBS) has been imparting quality education to its students since its inception. The B-School’s strong legacy and the dedication of its faculty, staff, and alumni towards imparting quality education is evident in its 20-year history of success. In this era of digitalization, GBS is equipped with modern tools that enable students to get hands-on practice on the latest analytical and visualization tools and live projects dealing with real-life datasets.</p>
                                <p>This year the B-School has launched the AICTE approved 2 years’ full-time PGDM – Business Analytics, which will be focused on building the students’ analytical skills through various simulation-based content. The PGDM in Business Analytics course will provide the students with a strong foundation in their chosen field of study, which includes Marketing Analytics and Supply Chain Analytics leveraging Machine Learning, Artificial Intelligence, as well as other business-oriented subjects like Accounting and Finance.</p>
                                <p>On the whole, the GBS curriculum focuses on providing students with practical knowledge; it also helps them develop critical thinking skills that will serve them throughout their careers.</p>
                                <p>So if you are someone who is aspiring to make it big in the world of analytics, then this B-School offers all the tools you need to succeed.</p>
                                <p><b>Doyel Mukherjee</b><br />
                                    Research Associate<br />
                                    Globsyn Business School</p>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
