import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function COVIDBewareAware() {
    return (
        <><title>COVID-19: From ‘Beware’ to ‘Be Aware’</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">COVID-19: From ‘Beware’ to ‘Be Aware’</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">COVID-19: From ‘Beware’ to ‘Be Aware’</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/Precautions-For-COVID-19_V04_blog-img-1.png" alt="COVID-19: From ‘Beware’ to ‘Be Aware’" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>We are presently living through one of the most challenging periods in the last 100 years or so. While the country is in the midst of a grave Second Wave of COVID-19 pandemic, with numbers of cases rising higher than last year, we are being compelled to remain inside for an uncertain timeframe, and confine ourselves from carrying out our favorite things. Besides, the continuous death bulletins by the media have made our lives more stressful.</p>
                                <p>Nonetheless, we should not lose our hope. Now is the time to show solidarity with the frontline workers, and stay united in our attempts to protect ourselves and our dear ones. Now is the time to take the oath to break the chain of COVID-19 transmission. Amidst this tough time, we all need to be more responsive in meeting the objectives of the awareness campaigns issued continuously by the government and notable bodies around the globe. In India, both the central and state governments have come up with COVID Appropriate Behaviour Guidelines for the citizens to forestall the spread of coronavirus.</p>
                                <p>We all know that social distancing and wearing masks are the two most effective protocols we need to maintain for curbing the spread of the deadly virus. Alongside the above aspect, we need to practice optimum personal hygiene standards and follow propensities like:</p>
                                <ul className='disc'>
                                    <li>Wear a clean mask as recommended by public advisory boards, while going out or even if we are at home feeling unwell.</li>
                                    <li>Keep a safe distance and maintain at least 1 meter distance from others to ensure safety for all.</li>
                                    <li>Practice frequent hand washing with soap and water, wash hands even if they are visibly clean and if soap and water are not available, use hand sanitizers with at least 60% alcohol.</li>
                                    <li>Cover the nose and mouth with handkerchief/tissue while sneezing and coughing and throw used tissues into closed bins immediately after use.</li>
                                    <li>Consult a doctor if feeling unwell or developing any Covid-19 infection symptoms.</li>
                                    <li>Clean and disinfect surfaces that are regularly touched like mobile phones, door handles etc.</li>
                                </ul>
                                <p>Always remember, if tested COVID-19 positive, you should never panic but follow the guidelines and observe precautions as advised by the authority. It is also recommended to intake wholesome food, and prescribed medicines to keep oneself and others safe during this crucial period. We should also abstain ourselves from carrying out the following activities like:</p>
                                <ul className='disc'>
                                    <li>Sharing personal hygiene items with others, even with family members</li>
                                    <li>Keeping travel plans to the minimum, if at all necessary</li>
                                    <li>Taking medicine without consulting a practitioner</li>
                                    <li>Having close contact with anyone, if you’re experiencing cough and fever</li>
                                    <li>Touching your eyes, nose and mouth without sanitizing your hands</li>
                                    <li>Spitting in public</li>
                                    <li>Wearing ill-fitting masks</li>
                                    <li>Taking public transport, if requires, take all the precautionary measures</li>
                                </ul>
                                <p>As we all know, that even after taking intense precautionary measures, sometimes senior family members in our household get infected with this virus. In such situations, it becomes our responsibility to look after them by optimally following the safety norms and the precautionary guidelines as mentioned below:</p>
                                <ul className='disc'>
                                    <li>Isolate the sick person, maintain distance from the other members of the family, and maintain proper ventilation of his/her room.</li>
                                    <li>Reduce contact with the sick person by separating the utensils, bedding etc. and disinfect the places/objects frequently used by the sick person.</li>
                                    <li>Wear a protection kit and triple-layer mask while coming in contact with the sick person.</li>
                                    <li>Monitor a sick person’s symptoms, especially if he/she has co-morbidity, and consult the doctor immediately if abnormalities are observed in the person.</li>
                                    <li>Ensure proper medication and rest for the sick person.</li>
                                </ul>
                                <p>The present situation might seem hopeless at times, but let us promise ourselves to stand together in this battle and navigate through this crisis, so that one day in the near future, we will can reconnect physically.</p>

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
