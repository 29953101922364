import React from 'react'
import Sidebar from '../../Sidebar'
import { Link } from 'react-router-dom'

export default function ESGtoSDG() {
    return (
        <><title>ESG to SDG – The Roadmap for a Sustainable World</title>
            <section className="hero-banner-img-media">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">ESG to SDG – The Roadmap for a Sustainable World</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">ESG to SDG – The Roadmap for a Sustainable World</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/Vision-Seminar16022022_web-1024x1024.jpg" alt="Vision-Seminar" width="678px" height="490px" />
                                </div>
                                <br /><br />
                                <p >The practice of aligning investment decision making with the societal and environmental aspects were initiated way back in the 1960s with investors excluding stocks or entire industries from their portfolios based on business activities such as tobacco production or involvement in the South African apartheid regime. The practice gained momentum and in October 2005, the United Nations Environment Program Initiative in the Freshfields Report coined the acronym ESG where E stood for Environmental (Climate Change, Natural Resources, Pollution &amp; Waste, and Environmental Opportunities), S stood for Social (Human Capital, Product Liability, Stakeholder Opposition, and Social Opportunities) and G stood for Governance (Corporate Governance and Corporate Behavior). Subsequently, it became a sustainable finance and responsible investment framework in the ECOSOC Chamber at UNHQ. The ambit gradually widened over the later years and all United Nations Member States in 2015, adopted the 2030 Agenda for Sustainable Development which provided a shared blueprint for peace and prosperity for people and the planet, not only for the present but also into the future. At the heart of the blueprint, are the 17 Sustainable Development Goals (SDGs), which are an urgent call for action by all countries in a global partnership.</p>
                                <p >The goals require contribution from all stakeholders of the planet including the world of business. Though among the collective of 17 global goals quite a few are specifically relevant to bodies corporate, the realization of SDG is largely dependent on governmental and intergovernmental processes. Alongside SDG, the idea of ESG (Environment, Society, Governance) has been gaining ground being directly relevant to business and industry. SDG and ESG of course overlap and each of the global goals under SDG can be mapped with one or more of the three components of ESG.</p>
                                <p >The entire gamut of ESG and SDG is obviously overwhelming. Spread over multiple days including a pre-seminar workshop, the deliberations of the 6<sup>th</sup> International Vision Seminar 2022 based on the theme of ‘<strong>ESG to SDG – The Roadmap for a Sustainable World</strong>’, therefore focused on certain aspects of direct relevance to business and industry through the following four dedicated sub-themes:</p>
                                <ul >
                                    <li><b></b><strong><b>Reducing Disparities and Ensuring Equity – The People Agenda</b></strong></li>
                                </ul>
                                <p >Disparities in income and discrimination on the basis of ethnicity, religion and gender are blotches on the canvas of human civilization. The 17 UNDP Sustainable Development Goals, as mutually agreed upon by 193 nations in 2015, stress on eradication of this menace by including Primary Education, Gender Equality and Reduced Inequalities as Goal Nos. 4, 5 &amp; 10 respectively. From the ESG point of view, these goals relate to the human capital, social aspect of employee diversity and Management Structure and Executive Compensation from the Governance angle. &nbsp;Of all forms of inequalities, those relating to gender are more severe because they cut across the divides of economic wellbeing and social standing. These disparities that exist in the society at large are carried over to business organizations where they translate to lack of diversity in the staff pool, poor representation of marginalized groups in top echelons and disproportionate variance in pay prospects. Rather than succumbing to such societal disparities, business leaders and leading business organizations must work towards removing or reducing them.</p>
                                <ul >
                                    <li><b></b><strong><b>Mitigating Climate Change and Maintaining Biodiversity – The Planet Agenda</b></strong></li>
                                </ul>
                                <p >It must be remembered that life on earth is dependent on the planet and not the other way round. &nbsp;Creation and consumption of energy which is essential to foster material wellbeing has been taken to a stage where it is causing such damage to the planet which the human race itself will not be able to sustain. &nbsp;Signs of adverse impact on biodiversity are starkly evident. The world-wide call for climate action and governmental and inter-governmental efforts requires due response from business organizations. The UNDP Goal No. 7 emphasizes on affordable and clean energy and the Goal No. 13 hinges on the importance of Climate Action to achieve Zero Emission. The environmental aspect of climate change as well as bio-diversity along with the social effect of impact on local communities constitute the ESG point of view towards the planet agenda. The need is thus to adopt alternate sources of energy which are clean, factoring in their affordability and impact on local communities.</p>
                                <ul >
                                    <li><b></b><strong><b>Promoting Responsible Industrialization – The Prosperity Agenda</b></strong></li>
                                </ul>
                                <p >In addition to energy related aspects, there are many other areas where irresponsible industrialization has led to unsustainable ends. These include mindless exploitation of resources and gross use and re-use of hazardous material. Business organizations have to meet challenges in this aspect by fostering innovation that create alternative paths to reach the goals of industrialization. The UNDP Goal No. 9 thus identifies Industrialization, Innovation and Infrastructure to be an essential aspect of sustainable development. The need for responsible consumption and production by industries to ensure sustainable development is authenticated by including Responsible consumption and production as UNDP Goal No. 12. The Natural resource depletion, waste and pollution, hazardous materials, coming under the ambit of environment and consumer protection as well as data protection constituting the social aspect, are the corresponding ESG angles to these goals. Large organizations must also help smaller businesses to move in the desired direction through financial and technological support. They must also integrate sustainability into production processes, sourcing avenues and consumer offerings.</p>
                                <ul >
                                    <li><b></b><strong><b>Ensuring Decent Growth and Societal Wellbeing – The Peace Agenda</b></strong></li>
                                </ul>
                                <p >Ensuring primary education to children who have been deprived of the same across the globe due to poverty, armed conflict or any other emergency, is a must for achieving social peace and harmony. The UNDP Goal No. 16 underlines this need. Business organizations have emerged as the most prominent growth engine for human civilization. As such, they are responsible not only to enable financial growth of its investors but also to ensure decent growth for all stakeholders including employees, vendors, customers and the community. This calls for efforts on multiple fronts. They should strive to create congenial employee relations and hospitable work environment, promote consumer awareness on its products and contribute to creating public awareness on sustainable development and healthy lifestyles. They must also stand guard against all forms of corruption in the interface with vendors, customers and the government. However, this herculean task can be expedited by the cooperation among the nations, which has become a lot convenient in the present digital era. The corresponding ESG concerns to these goals from the social angle are social opportunities whereas from the governance angle, bribery, corruption, political lobbying &amp; donations with vested interests along with evasion of tax liabilities are the prominent issues. This essential requirement has been mentioned as the Goal No. 17 by UNDP.</p>

                            </div>
                            <div className="accordion" id="myAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">SDG Goals addressed at the 6th  International Vision Seminar 2022</button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div className="card-body">
                                            <p>Globsyn Business School, in its 20th year, addressed nine SDG goals (4, 5, 7, 9, 10, 12, 13, 16 and 17) relevant to the Education Industry, in consonance with the ESG Concerns and SDG Goals.</p>
                                            <p>The SDG goals and ESG concerns corresponding to the sub-themes are contained in the following matrix:</p>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width="221"><strong>Session Sub-Theme</strong></td>
                                                        <td width="283"><strong>SDG Goals</strong></td>
                                                        <td width="295"><strong>ESG Concerns</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td width="221">Reducing Disparities and Ensuring Equity – <strong><b>The People Agenda</b></strong></td>
                                                        <td width="283"><strong>Goal 4:</strong>&nbsp;Quality education<p></p>
                                                            <p><strong>Goal 5:</strong>&nbsp;Gender equality</p>
                                                            <p><strong>Goal 10: </strong>Reduced inequalities</p></td>
                                                        <td width="295"><strong>Social:</strong> Primary Education, Employee diversity<p></p>
                                                            <p><strong>Governance:</strong> Management structure; executive compensation</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td width="221">Mitigating Climate Change &nbsp;and Maintaining Biodiversity – <strong><b>The Planet Agenda</b></strong></td>
                                                        <td width="283"><strong>Goal 7:</strong>&nbsp;Affordable and clean energy<p></p>
                                                            <p><strong>Goal 13:</strong>&nbsp;Climate action</p></td>
                                                        <td width="295"><strong>Environmental:</strong> Climate change; biodiversity<p></p>
                                                            <p><strong>Social:</strong> Impact on local communities</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td width="221">Promoting Responsible Industrialization – <strong><b>The Prosperity Agenda</b></strong></td>
                                                        <td width="283"><strong>Goal 9:</strong>&nbsp;Industrialization, innovation and infrastructure<p></p>
                                                            <p><strong>Goal 12:</strong>&nbsp;Responsible consumption and production</p>
                                                            <p>&nbsp;</p></td>
                                                        <td width="295"><strong>Environmental:</strong> Natural resource depletion; waste and pollution; hazardous material<p></p>
                                                            <p><strong>Social:</strong> Consumer protection; data protection</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td width="221">Ensuring Decent Growth and Societal Wellbeing – <strong><b>The Peace Agenda</b></strong></td>
                                                        <td width="283"><strong>Goal 16:</strong>&nbsp;Peace, Justice &amp; Strong Institution<p></p>
                                                            <p><strong>Goal 17:</strong>&nbsp;Partnerships for the goals</p></td>
                                                        <td width="295"><strong>Social:</strong> Social opportunities<p></p>
                                                            <p><strong>Governance:</strong> Bribery and corruption; political lobbying and donations; tax strategy</p></td>
                                                    </tr>
                                                </tbody>
                                            </table>




                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingtwo">
                                        <button type="button" className="accordion-button collapsed " data-bs-toggle="collapse" data-bs-target="#collapsetwo">Pre-seminar Workshop on ‘Responsible Business – Guidelines and Reporting’</button>
                                    </h2>
                                    <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div className="card-body">
                                            <p>The seminar was preceded by a workshop on ‘<strong>Responsible Business – Guidelines and Reporting</strong>’. It included the following:</p>
                                            <ul>
                                                <li>The 10 Principles of UN Global Compact and other international guidelines on corporate social responsibility</li>
                                                <li>Section 135 of Companies Act, 2013 on statutory provisions of CSR in India</li>
                                                <li>The 9 Principles comprising National Voluntary Guidelines on Social, Environmental and Economic Responsibilities of Business issued by the Ministry of Corporate Affairs, India.</li>
                                                <li>Business Responsibility Reporting mandated by Securities &amp; Exchange Board of India</li>
                                            </ul>
                                            <p>The reckoning of good corporate citizenship is progressively shifting from what is made of the profit to how the profit is made. ESG and SDG provide a roadmap for a sustainable world so that the planet can survive and business can thrive.The 6<sup>th</sup> International Vision Seminar 2022 and the Pre-seminar Workshop enlightened participants on the business imperatives around people, planet and prosperity.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingthree">
                                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Globsyn Management Journal</button>
                                    </h2>
                                    <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div className="card-body">
                                            <p ><strong>PRE-SEMINAR WORKSHOP<br />
                                            </strong></p>
                                            <p><strong>Responsible Business – Guidelines and Reporting</strong></p>
                                            <p ><strong>Welcome Address:</strong>&nbsp;Prof. R.C. Bhattacharya, Vice-Chairman, Globsyn Business School, India<br />
                                                <strong>Keynote Speaker &amp; Chief Guest:</strong> Dr. H. P. Kanoria, Chairman, SREI, India<br />
                                                <strong>Guest of Honour:</strong> Prof. Kristina Hunter, Faculty, University of Manitoba, Canada<br />
                                                <strong>Special Guest:</strong> Mr. Arindam Mukherjee, Senior Consultant, Development Environergy Services Ltd., New Delhi; Ex-Project Manager, UNDP, India<br />
                                                <strong>Workshop Leader:</strong> Dr. Nayan Mitra, Founder and Director, Sustainable Advancements, India<br />
                                                <strong>Conclusion:</strong>&nbsp;Prof. Kaushik Ghosh, Globsyn Business School, India<br />
                                                <strong>Vote of Thanks:</strong> Dr. Mahuya Basu, Faculty, Globsyn Business School, India</p>
                                            <p ><strong>6<sup>th</sup> INTERNATIONAL VISION SEMINAR 2022 – Day 1<br />
                                            </strong></p>
                                            <p ><strong>Welcome Address: </strong>Dr. Sabyasachi Dasgupta, Faculty &amp; Convener, 6<sup>th</sup> International Vision Seminar 2022, Globsyn Business School, India; Dr. Debaprasad Chattopadhyay, Faculty &amp; Joint Convener, 6<sup>th</sup> International Vision Seminar 2022, Globsyn Business School, India<br />
                                                <strong>Theme Address: </strong>Prof. R.C. Bhattacharya, Vice-Chairman, Globsyn Business School, India<br />
                                                <strong>Inaugural Address:</strong> Mr. Bikram Dasgupta, Founder and Executive Chairman, Globsyn Group<br />
                                                <strong>Keynote Speaker: </strong>Mr. Sanjiv Paul, Vice President, Safety, Health and Sustainability, Tata Steel Ltd.<br />
                                                <strong>Chief Guest:</strong> Dr. M.P. Poonia, Vice Chairman, AICTE<strong><strong><br />
                                                    <strong>Guest of Honour: </strong></strong></strong>Dr. Geoff Perry, Executive Vice President and Chief Officer – Asia Pacific, AACSB<br />
                                                <strong>Distinguished Guest:</strong> Dr. Soumitra Dutta, Dean Elect, Said School of Business, Oxford University; Professor of Management and Former Founding Dean, SC Johnson School of Business, Cornell University</p>
                                            <p><strong>Vote of Thanks:</strong> Dr. Mahuya Basu, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Panel Session I: Reducing Disparities and Ensuring Equity – The People Agenda</strong><strong><br />
                                            </strong><strong><i>Panelists:</i></strong></p>
                                            <ul>
                                                <li>Swami Narasimhanandaji Maharaj, Monk, Ram Krishna Mission, India, and Member, International Interdisciplinary and Inter-religious Research Group on Consciousness Studies, UNESCO, India</li>
                                                <li>Dr. Celina Shahnaz, Professor, Department of EEE, BUET, Bangladesh</li>
                                                <li>Dr. Sonjaya Gaur, Clinical Professor of Marketing, Department of Integrated Marketing, Division of programs in Business, NYU School of Professional Studies, New York University, USA</li>
                                                <li>Dr. Kallol Dutt, Additional Labour Commissioner, Government of West Bengal, India</li>
                                            </ul>
                                            <p><strong><i>Session Moderator:</i></strong>&nbsp;Prof. Manas Chakravarty, Faculty, Globsyn Business School</p>
                                            <p><strong>Panel Session II: Mitigating Climate Change and Maintaining Biodiversity – The Planet Agenda </strong></p>
                                            <p><strong><i>Panelists:</i></strong></p>
                                            <ul>
                                                <li>Dr. Runa Sarkar, Professor, Department of Economics, IIM Kolkata, India</li>
                                                <li>Mr. Tirthankar Banerjee, Director, OZTRON, Australia</li>
                                                <li>Ms. Sutapa Bhattacharya, General Manager, Corporate Communications, Tenaga Nasional, Malaysia</li>
                                            </ul>
                                            <p><strong><i>Session Moderator:</i></strong> Dr. Tanusree Chakraborty, Faculty, Globsyn Business School, India</p>
                                            <p><strong>6<sup>th</sup> INTERNATIONAL VISION SEMINAR 2022 – Day 2<br />
                                            </strong></p>
                                            <p><strong>Panel Session III: Promoting Responsible Industrialization – The Prosperity Agenda</strong><strong><br />
                                            </strong><strong><i>Panelists:</i></strong></p>
                                            <ul className='disc'>
                                                <li>Dr. Hishmi Jamil Husain, Head – Biodiversity, Corporate Sustainability at Tata Steel, India</li>
                                                <li>Dr. Bob Hopkins, Founder and President, The Philanthropy Institute, Texas, USA</li>
                                                <li>Mr. Sujoy Banerjee, Group Chief People Officer &amp; Group Head – Marketing, Gainwell Commosales Pvt. Ltd., India</li>
                                            </ul>
                                            <p><strong><i>Session Moderator:</i></strong> Dr. Rajib Bhattacharya, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Panel Session IV: Ensuring Decent Growth and Societal Wellbeing – The Peace Agenda</strong><strong><br />
                                            </strong><strong><i>Panelists:</i></strong></p>
                                            <ul className='disc'>
                                                <li>Prof. Mary C Gentile, Creator/Director, Giving Voice to Values, Richard M. Waitzer Bicentennial Professor of Ethics, University of Virginia, Darden School of Business, USA</li>
                                                <li>Prof. Bidyut Kumar Sarkar, Prof. S.K. Chakraborty Memorial Trust, India</li>
                                                <li>Ms. Avantika Mathur, Researcher, Animals &amp; Us – Voices of a New Paradigm, Canada</li>
                                            </ul>
                                            <p><strong><i>Session Moderator:</i></strong> Dr. Swapna Datta Khan, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Valedictory Session</strong></p>
                                            <p><strong>Guest Speaker: </strong>Dr. Aditi Mitra, Senior Lecturer, Sunway University, Malaysia; Member – Academic Council, Globsyn Business School, and Alumni (Batch 2009-11), Globsyn Business School, India<br />
                                                <strong>Valedictory Address:</strong> Dr. M. Athreya, Padma Bhushan, Ex-Professor, IIM Calcutta, India; Former Visiting Professor at London Business School, UK, and Strathclyde Business School, Scotland</p>
                                            <p><strong>Conclusion &amp; Vote of Thanks:</strong> Dr. Sabyasachi Dasgupta, Faculty &amp; Convener, 6<sup>th</sup> International Vision Seminar 2022, Globsyn Business School, India; Dr. Debaprasad Chattopadhyay, Faculty &amp; Joint Convener, 6<sup>th</sup> International Vision Seminar 2022, Globsyn Business School, India</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingfour">
                                        <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefour">Speakers and Tracks</button>
                                    </h2>
                                    <div id="collapsefour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div className="card-body">
                                            <p><strong>RESEARCH SEMINAR (February 24, 2023)&nbsp;</strong></p>
                                            <p><strong>Innovation and Entrepreneurship</strong></p>
                                            <p><strong>Welcome Address:</strong> Dr. Tanusree Chakraborty, Faculty, Globsyn Business School, India<br />
                                                <strong>Inaugural Address:</strong> Prof. R. C. Bhattacharya, Vice-Chairman, Globsyn Business School, India<br />
                                                <strong>Theme Address:</strong> Dr. M.P. Singh, Principal, Globsyn Business School, India<br />
                                                <strong>Chief Guest Address:</strong> Dr. Mary Conway Dato-on, Professor of Social Entrepreneurship, Rollins College, Crummer Graduate School of Business, USA<br />
                                                <strong>Special Guest Address:</strong> Dr. Sharmistha Banerjee, Professor – Department of Business Management, and Project Principal Investigator Entrepreneurship Hub, University of&nbsp;Calcutta,&nbsp;India<br />
                                                <strong>Judges:</strong> Dr. Mary Conway &amp; Dr. Sharmistha Banerjee<br />
                                                <em><strong>Research Paper Presentations</strong></em><br />
                                                <strong>Vote of Thanks:</strong> Dr. Sabyasachi Dasgupta, Faculty, Globsyn Business School, India<br />
                                                <strong>Session Moderator: </strong>Dr. Tanusree Chakraborty, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Recess</strong></p>
                                            <p><strong>SEMINAR WORKSHOP (February 24, 2023)&nbsp; </strong></p>
                                            <p><strong>Design thinking framework for sustainable Entrepreneurship and Innovation</strong></p>
                                            <p><strong>Welcome Address: </strong>Dr. M.P. Singh, Principal, Globsyn Business School, India<br />
                                                <strong>Inaugural Address</strong>: Prof. R. C. Bhattacharya, Vice-Chairman, Globsyn Business School, India<br />
                                                <strong>Keynote Speaker &amp; Chief Guest: </strong>Mr. Tirthankar Banerjee, Director, Oztron Energy, Australia<br />
                                                <strong>Workshop Chairman:</strong> Dr. R.K. Patra, Regional Chairperson (EAST), AIMS, India<br />
                                                <strong>Workshop Guest Leader: </strong>Mr. Devasis Gupta, Mentor, IIMC Innovation Park, India<br />
                                                <strong>Vote of Thanks: </strong>Dr. Sabyasachi Dasgupta, Faculty, Globsyn Business School, India<br />
                                                <strong>Session Moderator: </strong>Prof. Kaushik Ghosh, Faculty, Globsyn Business School, India</p>
                                            <p>&nbsp;</p>
                                            <p><strong>SEMINAR DAY – Panel Discussions (February 25, 2023)</strong></p>
                                            <p><strong>Inauguration </strong><br />
                                                <strong>Welcome Address: </strong>Dr. M.P. Singh, Principal, Globsyn Business School, India<br />
                                                <strong><em>Inauguration and Lamp Lighting, Globsyn Mantra</em></strong><br />
                                                <strong>Inaugural Address: </strong>Mr. Bikram Dasgupta, Founder &amp; Executive Chairman, Globsyn Group, India<br />
                                                <strong>Theme Address: </strong>Prof. R.C. Bhattacharya, Vice-Chairman, Globsyn Business School, India<br />
                                                <strong>Chief Guest: </strong>Shri Anoop Kumar Agrawal, IAS, Principal Secretary, Technical Education, Training and Skill Development, Govt. of West Bengal, India<br />
                                                <strong>Guest-of-Honour:</strong> Dr. Parag Kalkar, Dean, Faculty of Commerce and Management at Savitribai Phule Pune University &amp; Treasurer, AIMS, India<br />
                                                <strong>Special Guest: </strong>Prof. Anil Sahasrabudhe, Ex-Chairman, AICTE; Chairman, NBA; Chairman, NETF<br />
                                                <strong>Special Guest: </strong>Dr. Geoff Perry, Executive Vice President and Chief Officer, Asia Pacific, AACSB<br />
                                                <strong>Vote of Thanks:</strong>&nbsp; Dr. D.P. Chattopadhyay, Faculty, Globsyn Business School, India<br />
                                                <strong>Session Moderator:</strong> Dr. Mahuya Basu, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Emcee:</strong> Dr. D.P. Chattopadhyay, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Recess</strong></p>
                                            <p><strong>Panel Session I: The role of Government and Start-ups in fostering Innovation and Entrepreneurship: Collaborations, Partnership and Initiatives<br />
                                            </strong></p>
                                            <p ><strong>Panelists:</strong></p>
                                            <ul className='disc'>
                                                <li>Ms. Sumita Bhattacharya, Managing Director, NE Innovation Lead, Accenture, USA</li>
                                                <li>Mr. Debabrata Mitra, Joint Director, MSME-DFO, Ministry of MSME, Govt. of India, India</li>
                                                <li>Mr. R.K. Jaiswal, Honorary Consul, Maldives</li>
                                                <li>Mr. Prashant Kumar, Founder and Senior Partner, Entropia (Accenture Song), Malaysia</li>
                                                <li>Mr. Aloke Mookherjea, Former Senior Advisor, Ernst &amp; Young LLP, and Former Chairman – Flakt (India) Limited; Governing Council Member, Globsyn Business School, India</li>
                                            </ul>
                                            <p><strong>Session Chairperson: </strong>Dr. Ajitava Raychaudhuri, Emeritus Professor and HoD – Economics, Adamas University, India; Former Dean, Faculty of Arts (Economics), Jadavpur University, India; Ex-Academic Council Chairman, Globsyn Business&nbsp;School,&nbsp;India<br />
                                                <strong>Session Moderator: </strong>Dr. Rajib Bhattacharya, Faculty, Globsyn Business</p>
                                            <p><strong>Lunch<br />
                                            </strong></p>
                                            <p><strong>Panel Session II: Educators and Social Entrepreneurs: Developing knowledge ecosystems and role of social organizations in building an Entrepreneurial Environment<br />
                                            </strong></p>
                                            <p><strong>Panelists:</strong></p>
                                            <ul className='disc'>
                                                <li>Prof. Manas Chatterji, Bartle Professor, School of Management, Binghamton University, USA</li>
                                                <li>Mr. Debabrata Samaddar, Ex-Senior General Manager, Tata Steel Downstream Products Limited, and COO Wisdom Overseas, India</li>
                                                <li>Dr. Arijit Banerjee, Founder and Executive Director, Ramaesis RPL, India</li>
                                                <li>Dr. Vinitha Guptan, Vice Chancellor, SAITO University, Malaysia</li>
                                                <li>Mr. Saunak Saha, Partner, Ernst &amp; Young, India</li>
                                            </ul>
                                            <p><strong>Session Chairperson: </strong>Dr. Subhrangshu Sanyal, CEO, IIM Calcutta Innovation Park, India; Academic Council Member, Globsyn Business School,&nbsp;India<br />
                                                <strong>Session Moderator: </strong>Dr. Kavita Shastri, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Valedictory Session<br />
                                            </strong></p>
                                            <p><strong>Special Address</strong>: Dr. Upinder Dhar, Vice Chancellor, Vaishnav Vidyapeeth Vishwavidyalaya, Indore, India<br />
                                                <strong>Valedictory Address: </strong>Dr. Damodar Acharya, Ex-Chairman, AICTE, India; Governing Council Member, Globsyn Business School,&nbsp;India<br />
                                                <strong>Conclusion &amp; Vote of Thanks:</strong>&nbsp;Dr. Sabyasachi Dasgupta, Faculty, Globsyn Business School, India</p>
                                            <p><strong>Session Moderator: </strong>Dr. Mahuya Basu, Emcee and Faculty, Globsyn Business School, India</p>
                                            <p><strong>Emcee of the Ceremony:&nbsp;</strong>Dr. D.P. Chattopadhyay, Faculty, Globsyn Business School, India</p>
                                            <p>&nbsp;</p>

                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
