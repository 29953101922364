import React from 'react'
import './Common.css'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar'

export default function History() {
    return (
        <>
            <title>Management Development Program</title>
            <section className="hero-banner-img">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">History</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-yellow" to="/about-gbs">About-GBS</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">History</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                                <h1 className="vc_custom_heading">History of Globsyn
                                    Business School Kolkata</h1>
                            </div>
                            <div className="one-table">
                                <div className="one-table1"><img className="img-fluid" src="./assets/images/globsyn-business-school-history.jpg" alt="" sizes="(max-width: 358px) 100vw, 358px" width="358" height="286" /></div>
                                <div className="one-table2">
                                    <p>Founded in the last two decades by Globsyn – a pioneer in the field of technology education, Globsyn Business School (GBS) started with a small batch of 40 bright young minds who believed in Globsyn’s credo that ‘Ordinary people can do extraordinary things…‘</p>
                                    <p>Conceptualised as India’s first ‘Corporate B-School’, GBS gave itself a challenging mandate – move away from the traditional forms of management education delivery and create a breed of new age ‘Industry Relevant Managers’ – not only ready to work and contribute from day one but more importantly to seamlessly blend into the corporate world with the right equipage of knowledge, application finesse and agility to use technology.</p>
                                    <p>GBS has had a formidable parentage in Globsyn – India’s first ‘IBM Centre for Software Excellence’; the pioneer of the Knowledge Finishing School™ System in India; the only institution to seamlessly blend together ‘Infrastructure’ and ‘Institution’; the first Indian software organisation to bring dedicated business networking to India and giving to the technology training industry – Young Software Manager (YSM), the only technology training product to date that brings together ‘technology’, ‘management’ and application skills into a single, back-to-school, curriculum. The continuous performance of its alumni in the corporate domain has made Globsyn Business School – a top MBA institute in Kolkata.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
