import React from 'react'
import Sidebar from '../../Sidebar'
import { Link } from 'react-router-dom'

export default function LifelongCareer() {
    return (
        <><title>Globsyn Lifelong Career Support - Globsyn</title>
            <section className="hero-banner-img-sceren">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Globsyn Lifelong Career Support</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Globsyn Lifelong Career Support</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>Over the years, several alumni of Globsyn Business School have been able to realize their corporate dreams by getting placed in industries and securing job roles that they are passionate about, long after graduating from GBS. This has only been possible through Globsyn Lifelong Career Support, a lateral placement program for the alumni.</p>
                                <p>These are some of the success stories.</p>
                                <br />
                                <div className="accordion" id="myAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Kirti Yadav, Sales Project Manager – Greenply Industries Ltd., Batch 2017-19</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>I am Kirti Yadav, a former student of Globsyn Business School. I completed my PGDM in 2019, and was placed in a bank through campus placement. After having worked for two years, I have recently switched to a trade based company with a higher package and a better working environment. I got this opportunity through Globsyn Business School’s Lateral Placement Program, and I am eternally thankful to the B-School for this. I am quite excited about the journey ahead. Thank you GBS!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingtwo">
                                            <button type="button" className="accordion-button collapsed " data-bs-toggle="collapse" data-bs-target="#collapsetwo">Bishal Ghosh, Senior Relationship Manager – ICICI Securities, Batch 2018-20</button>
                                        </h2>
                                        <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>My gratitude to Globsyn Business School for the all-encompassing learning experience that has helped me in many occasions. For example, something I will always appreciate is the individual grooming, which was the best part of the Placement process. I read somewhere that a team never lets you down even in bad times, and I witnessed this multiple times during my two years at GBS.</p>
                                                <p>The B-School is a place where experiential learning holds more importance than mere classroom education, and hence Beyond Education elements like Kalyani Youth Leadership Forum is the perfect platform that empowers youngsters to balance the fine line between intelligence and emotions with selfless giving.</p>
                                                <p>Adding value to lives taught us what corporates actually wants from the fresh graduates. Personally, my journey from RBL Finserve to ICICI Securities was never easy. However, I accomplished career challenges with the constant guidance from GBS.</p>
                                                <p>I wish the young students of GBS the very best for their future, and hope that they, too, absorb all the new techniques of tech-enabled academic delivery and experiential learning pedagogy that Globsyn Business School marvels in.</p>
                                                <p>Once again, thank you alma mater!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingthree">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Pinaki Ghosh, Senior Relationship Manager – ICICI Securities, Batch 2018-20</button>
                                        </h2>
                                        <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Thank you Globsyn Business School and the Placement Team for the continuous help and support. I got placed as Senior Relationship Manager at ICICI Securities. During this unprecedented times, the B-School’s Lateral Placement Support Program was very helpful to help me secure this job. I’m extremely proud to be an alumnus of Globsyn Business School.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingfour">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefour">Rishi Raj Sarkar, Sales Officer Trainee – JSW Cement, Batch 2018-20</button>
                                        </h2>
                                        <div id="collapsefour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>I want to thank Globsyn Business School and entire the CRP team for the wonderful placement opportunity at JSW Cement Limited (JSW Group). I am very grateful to everyone at GBS for effectively and sincerely helping me to grab this opportunity.</p>
                                                <p>Globsyn Business School has one of the best academic infrastructure, and the guidance provided by the faculty members of the B-School is priceless. I have learnt great things here during my journey of two years in the companionship of excellent teachers and some gem friends, which would surely help me to enlighten my future ahead.</p>
                                                <p>My education at Globsyn Business School was an experience of a lifetime, and will stay with me forever. Once again, I want to thank Globsyn Business School for guiding my career into right path.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingfive">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefive">Omanjana Bhattacharya, Operations Executive – Flipkart, Batch 2019-21</button>
                                        </h2>
                                        <div id="collapsefive" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>I am thankful to Globsyn Business School for this 2 years of wonderful journey, and for providing us with several placement opportunities during the pandemic. The various training and grooming sessions with various corporates, have not only enriched my skills, but also helped me to implement myself as an ‘industry relevant manager’.</p>
                                                <p>I have always wanted to work in the e-commerce sector, but initially I got into banking instead. It was probably one of the happiest days in my life when I was given an opportunity, through the B-School’s Lateral Placement Program, to work for a renowned e-commerce company. Thank you GBS for this kind assistance during this tough times of a pandemic.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingsix">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsesix">Agni Sankar Chakraborty, Customer Success Associate – Nopaperforms, Batch 2019-21</button>
                                        </h2>
                                        <div id="collapsesix" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>I’m a tech enthusiast, who has always dreamt of working in the IT sector and building a career as a Business Analyst. Thanks to the several enlightening brainstorming sessions that we had with our esteemed faculty team and the always supportive Placement Team, I got placed in a reputed banking sector, despite being in the middle of the pandemic. One of the best part of being a Globsynite is that GBS always looks out for you, and hence even well after graduation the Lateral Placement Support Program of the B-School has always brought better opportunities for its alumni, from time to time. Thanks to such an opportunity, I could finally fulfil my life-long dream to work as a Business Analyst with a higher salary package in my preferred location. I convey my heartfelt gratitude towards my alma mater, Globsyn Business School, for fulfilling my career dreams.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
