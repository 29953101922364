import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function RoleOfField() {
    return (
        <><title>Role of Field Trips in the life of Management Students</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Role of Field Trips in the life of Management Students</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Role of Field Trips in the life of Management Students</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">

                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>When we hear about Field Trips, nostalgia hits us and makes us remember our school days when all of us were taken on trips to museums or parks. Field trips were just like another picnic for school kids. However, now when we are grown-ups and standing on the verge of starting our professional careers, field trips are no more a picnic. Instead, it has become FIELD (Field Immersion Experiences for Leadership Development) Trip, a term coined by Harvard Business School, which proposes to teach the students the difference between knowing and doing. It enables the young managers to learn how to work as a team, solve real-life business and apply theoretical knowledge into practice. For management students, field trips are not just a chance to escape the confines of the classroom, but a first-hand practical experience of the concepts that they have learned in the classroom throughout the curriculum.</p>
                                <p><Link to="/">Globsyn Business School (GBS)</Link> has always included field trips, commonly called ‘industry visits’ in their curriculum, as it helps students to step out of the boundaries of traditional pedagogic methods and put into practice experiential learning.. Recently, students of GBS have taken field trips to live sites like Hindustan National Glass; National Stock Exchange; TSPDL, Jamshedpur amongst others to get a slice of real-life action that happens in the corporate scenarios.</p>
                                <p>Let us have a look at some of the key takeaways; management students may get from field trips:</p>
                                <ul className='disc'>
                                    <li><b>Teaches Learning by Doing</b>: When the knowledge imparted in the classroom is followed up by a field trip, it helps in better understanding of the concepts taught, as it results in more effective learning. In the present day scenario, there lies a chasm between the knowledge imparted in a classroom and the requirements of the corporate world. B-Schools, today, have realised the importance of bridging this gap, and introduction of field trips in the curriculum is a positive step in this direction as this can help bridge the gap between knowing and doing. It teaches them how to be a professional and how to work professionally. Moreover, it helps them to understand the connection between the concepts taught in the classroom and how to apply them in reality.</li>
                                    <li><b>Puts Theory into Practice</b>: When the students go on a field trip, they get the opportunity to work on a live project. While putting theory into practice for the first time, students get to learn all the challenges related to application of a theory. In this context MacCormack, an MBA-graduate from Harvard Business School said, “Being there on the ground and observing yourself is different from learning something in a report from afar.” Working ‘live’ during the field trip makes the students understand the challenges poised in any decision-making process. Field trips help students reflect on the lessons they have learned in the classroom and take well-judged practical decisions based upon those lessons. It helps them to adapt, comprehend and finally react to what they have learned.</li>
                                    <li><b>Teaches the Power of Teamwork</b>: While on a field trip, students get to learn the power of teamwork. The purpose of the field trip is to provide students learning experiences outside the boundaries of classrooms and laboratories. It also teaches students, irrespective of their social, cultural and economic background, on how to implement ideas by respecting everyone’s opinions. Field trips also make them innovative.</li>
                                    <li><b>Teaches how to develop Communication Skills and be Independent</b>: Field trips provide opportunities for students to take onus of their actions and evolve in any kind of unscripted circumstances. It helps them to interact with people independently, which further improves their communication skills, helps them gain newer perspectives and contributes to the development of their overall personality. No doubt field trips are great fun as they help erase the boredom, pressure, and monotony of classroom lectures.</li>
                                </ul>
                                <p>Field trips are important components of management education. This type of pragmatic approach as a teaching method provides an opportunity for management students to learn about diverse aspects of the corporate world. This kind of experiential learning also helps them to come out of their comfort zones and develop positive social behaviour. </p>


                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
