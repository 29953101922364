import React from 'react'
import { Link } from 'react-router-dom'
import '../Common.css'
import Sidebar from '../../Sidebar'

export default function Innovation() {
  return (
    <>
<title>Innovative system of management study</title>
            <section className="hero-banner-img-inno">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Innovation</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Innovation</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                                <h1 className="vc_custom_heading">Innovative system of management study</h1>
                            </div>
                           <div className="one-table">
                              <p>Since its inception in the last two decades, Globsyn Business School has consistently developed and implemented, in real time, several innovative and creative pedagogic approaches differentiating it from others. Apart from the ‘Knowledge Connect’ & ‘Corporate Connect’ initiatives, ‘Work & Teach’ and ‘Learn & Intern’ are branded tools that Globsyn created for management education. Globsyn Business School uses the innovative Knowledge Finishing SchoolTM System pedagogy which, while amalgamating management and technology knowledge, teaches the individual the steps beyond acquisition of knowledge itself; the pedagogy stresses on the application of knowledge, its continuous management and ultimately performance. The pedagogy inculcates an extremely vital life-skill, which is a highly prized asset that every student of ours carries with them; to be practiced, in a continuous cycle, throughout their professional career.</p>  
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar/>
                        </div>
                    </div>
                </div>
            </section>
   </>     
  )
}
