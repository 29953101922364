import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../Sidebar'

export default function HumanResource() {
  return (
    <>
    <title>School of Information System Excellence | Globsyn Business School</title>
    <section className="hero-banner-img-pgdm">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">School of Information System Excellence</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="#">School of Information System Excellence</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                       
                    </div>
                    <div className="one-table">
                        <div className="one-table1"></div>
                        <div className="one-table2">
                            <p>The last three decades have witnessed some momentous changes in business methodologies. Geographic boundaries, fading away, primarily due to the rapid advancements in ICT technology has put forth IT in a pivotal role for business managers today. There are five factors, viz., internet growth and technology convergence, transformation of the business enterprise, growth of a globally connected economy, growth of knowledge and information-based economies and an emergence of the digital firm, which play an increasingly important role in the growing impact of IT for businesses at this hour. Information is power, and the Internet is bringing about a convergence of technologies, roiling markets, industries and firms, to provide key insightful information for businesses. Traditional boundaries and business relationships are breaking down, as new ones spring up. Telephone networks are merging into the Internet, and cellular phones are becoming Internet access devices. Handheld storage devices such as iPods are emerging as potential portable game and entertainment centers, whereas the Internet-connected personal computer is emerging as a home entertainment control center.</p>
                            <p>The significance of Information System in Management Studies stems from an increased need of information sharing, which holds immense potential of governing changes in the organizational structure of a business. To ensure that one is well-equipped with the technical as well as the organizational aspects of IT, the study of Information System is extremely crucial. The focus area lies on the design, development, and implementation of cutting-edge solutions such as Big Data, AI and ML, to drive the e-commerce revolution and solve important organizational problems.</p>
                            <p>One of the primary objectives of the Information System specialization programme is to give the students a 360° understanding of how IT impacts businesses, and thereafter leverages the power of Technology to help an organization achieve strategic goals in all its business operations.</p>
                            <p>The School of Information System Excellence offers the following areas of study:</p>
                            <ul className='disc'>
                                <li>Introduction of Emerging Technology in Global Business Environment</li>
                                <li>Fundamentals of E-Business/ Internet Commerce</li>
                                <li>Overview of Big Data Analytics</li>
                                <li>Artifical Intelligence & Machine Learning in Business Management</li>
                                <li>Advanced Excel-based Modelling for Finance & Marketing</li>
                                <li>Overview of Enterprise Resource Planning</li>
                                <li>Introduction of IoT in Business Applications</li>
                                <li>Dissertation for Information System and Management School of Excellence</li>
                            </ul>
                            <p><em>* Not all papers are offered in all courses</em></p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
