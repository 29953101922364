import React from 'react'
import './Common.css'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar'

export default function About() {
    return (
        <>
            <title>About Gbs</title>
            <section className="hero-banner-img-inno">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">About Gbs</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-yellow" to="#">About-GBS</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                                <h2 style={{ color: "#002147" }}>Know more About the Legacy, That is Globsyn Business School</h2>
                            </div>
                            <div className="one-table">
                                <div className="one-table2">
                                    <p>Over the last two decades, Globsyn Business School (GBS), a global brand of Globsyn Knowledge Foundation, has uniquely positioned itself as India’s only ‘Corporate B-School’, with its two-year full-time Post Graduate Diploma in Management (PGDM) being approved by AICTE</p>
                                    <p>With an edifice built on Innovation, Research and Technology, GBS is well on its path to establish a globally networked, corporate-savvy, research-driven management education system that creates ‘Industry Relevant Managers’ – an agile group of professionals equipped to operate in today’s technology-driven business environment. As a result of that over the years, more than 360 organizations have absorbed our 3500+ alumni, who have earned rich accolades for their achievements and are star performers within their organizations.</p>
                                    <p>The B-School has received many accolades from the education fraternity from time to time and has been ranked among the Top 50 B-Schools in India, as per Business India’s Best B-Schools Survey 2020. Recently, GBS has been recognized as the `Best Private Business School – West Bengal` at the ET Industry Leaders 2022, organized by The Times Group. The B-School has also been recognized as the ‘Leading B-School in Eastern India’ by The Times Group in the edition of Times Brand Icons 2021, and as the ‘Best B-School with Academic Excellence’ in Eastern India at the Asia Education Summit & Awards 2021.</p>
                                </div>
                            </div>
                            <div>
                                <h2 style={{ color: "#002147" }}>Shaping Minds for today's world...</h2>
                                <p>Studying at Globsyn Business School is a life changing experience. With emphasis on intellectual discovery and critical thinking, our students are provided an environment for both intellectual discourse and experiencing corporate life. Our faculty are discoverers and teacher-scholars not content with asking easy questions but constantly engaging the learner community to pursue academic and careers goals with the rigour that it deserves.</p>
                                <h2 style={{ color: "#002147" }}>Our Vision</h2>
                                <p>To build an education edifice, where young men and women have freedom to excel, fulfill their career aspirations through a globally networked, corporate savvy, research driven management education system.</p>
                                <h2 style={{ color: "#002147" }}>Our Mission</h2>
                                <p>To enable transformation of young minds to become responsible leaders with integrity through knowledge, innovation, research, technology and imbibe human values through care for society.</p>
                                <h2 style={{ color: "#002147" }}>Core Values</h2>
                                <ul className='list-style-1'>
                                    <li>Care for Society</li>
                                    <li>Responsible Leadership</li>
                                    <li>Innovation & Creativity</li>
                                    <li>Technology Adoption</li>
                                    <li>Lifelong Learning</li>
                                    <li>Research</li>
                                </ul>
                                <h2 style={{ color: "#002147" }}>Quality Policy</h2>
                                <p>To adopt best practices on students intake, ensure highest standards of faculty quality, academic curriculum & teaching pedagogy, and relevant placements keeping the GBS philosophy in mind.</p>
                                <h2 style={{ color: "#002147" }}>International Membership</h2>
                                <p>With an eye to infuse global standards into its pedagogy, Globsyn Business School joins AACSB International – The Association to Advance Collegiate Schools of Business (AACSB), the world’s largest business education alliance. Synonymous with the highest standards of excellence since 1916, AACSB provides quality assurance, business education intelligence, and professional development services to over 1,500 member organizations.</p>
                                <p>With this coveted membership, Globsyn Business School has now become a part of the world’s largest network of business schools and organizations focused on advancing business education.</p>
                                <div style={{ textAlign: "center" }}>
                                    <a href='https://www.aacsb.edu/about' target='_blank' rel="noreferrer">
                                        <img src="./assets/images/AACSB-logo-member-color-RGB-300x128.png" alt="sectors-cover" width="263" height="112" />
                                    </a>
                                </div>
                                <br/>
                                <h2 style={{ color: "#002147" }}>Memberships</h2>
                                <div className='d-flex justify-content-between' style={{ textAlign: "center" }}>                               
                                        <img src="./assets/images/CII.png" alt="sectors-cover" width="116" height="68" />
                                        <img src="./assets/images/AIMA.png" alt="sectors-cover" width="116" height="68" />
                                        <img src="./assets/images/AIMS.png" alt="sectors-cover" width="116" height="68" />
                                        <img src="./assets/images/BCCI.png" alt="sectors-cover" width="116" height="68" />
                                        <img src="./assets/images/CMA.png" alt="sectors-cover" width="116" height="68" />
                               </div>




                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
