import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ImposingABan() {
    return (
        <><title>Imposing a Ban on SUPs: An only solution?</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Imposing a Ban on SUPs: An only solution?</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Imposing a Ban on SUPs: An only solution?</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/SUP_BLOG.jpg" alt="SUP_BLOG" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>On the 73rd Independence Day of India, the Prime Minister of the country, Shri Narendra Modi appealed to all his countrymen to put their effort wholeheartedly towards the mission of making the country free from SUPs, i.e., Single-Use Plastics. Following his appeal, India imposed a nationwide ban on SUPs from October 2, 2019, which incidentally is also the 150th birth anniversary of Mahatma Gandhi. Although, there are some states that has already enforced the ban on plastic in the preceding years. It did not work though, because it focused only on the exclusion of carry bags as SUPs were misinterpreted as polythene carry bags or disposal plastics in India. However, the United Nations categorized ‘Single-Use Plastics’ as the products that are made of Oxo-degradable Plastic, Polystyrene or other types of plastic such as straws, cutlery, cups, cotton-bud sticks, plates, balloon sticks, plastics for packaging beverage containers, multilayered plastics (MLP) packaging for food items, chips, biscuits, chocolates, etc. These items are intended to be utilized only once before they are recycled or thrown.</p>
                                <p>According to a recent report from Britain’s Royal Statistical Society, only 9% of the plastic manufactured till the date, has been recycled. Therefore, according to this statistic, 91% of the plastic formed are still ‘unprocessed’ or ‘non-recycled’. This statistic shows that plastic waste is rising staggeringly all over the world and is incessantly worsening the global eco-system. Due to this, every year tons of plastic debris is discarded all around resulting in polluting the lands, rivers, coasts, beaches, and oceans. This pollution is adversely affecting wildlife, wildlife habitat as well as human life.</p>
                                <p>Due to the inexpensiveness and durability of plastic, global production and consumption of plastics have continued to rise for more than 50 years. Moreover, due to the chemical structure of plastic, its degradation is very difficult. These factors lead to a high prominence of plastic pollution in the environment. A Global E-waste Monitor Report, published from the United Nations University in 2017, exposed the fact that 44.7 million metric tons of e-waste was made in 2016 out of which only 20% was collected and recycled till date. Another report from Central Pollution Control Board states that 26,000 tons (TPD) of plastic waste is produced every day in our country of which only 60 per cent or 15,600 TPD was recycled. This implies that the remaining plastics have ended up as mess on roads, in streams or in landfills. This poses a huge warning to species on land and in water. Rendering to a research paper published in Nature Communications magazine, river Ganga alone took 1.15 lakh tons of plastic into the ocean in 2015, which is second to China’s Yangtze.</p>
                                <p>According to many industries, movement of imposing a ban on the usage of SUPs is a great effort to save the environment. However, it is anticipated that commanding injunction on SUPs will affect FMCG industries as they use plastics in packaging and it is directly or indirectly related to all the business units or productions. For instance, E-commerce industries, consumer appliance manufacturers such as air-conditioners, fridge, etc., hospitality and restaurant, product delivery, grocery stores and small retail shop consume single-use plastics. Therefore, recycling and waste management should also be given equivalent importance. The alternatives to SUPs can be paper, glass, and cardboard. However, they too consent a substantial impact on the atmosphere. For instance, usage of paper leads to cutting of trees. Similarly, glass leaves a major carbon footprint and devours a lot of power. Along with that, other aspects should also need to be looked into, such as safety, weight, applicability, affordability, transportability, etc.</p>
                                <p>To comprehend the work and challenge on processing approaches, robust inventory studies need to be done in cities. The suitable awareness campaign, social engineering tools and cost operative options in tier II and tier III towns and remote locations need to be acknowledged because in urban areas there is enlarged responsiveness by now. According to a Chairman of a leading Management Consulting firm, India could become a country for a multi-billion dollar investment into waste collection, separation and waste management eco-system. On the other hand, multi-layered plastic packaging is non-recyclable because it has multiple types of plastic, aluminum and paper as well. Therefore, the ban of multi-layered plastic cannot be done because it does not have a readily accessible alternative. It is the imminent need of the R&amp;D division of the industries to look for some substitutions within a concrete timeline. Similarly, to make the recycling technologies greener and sustainable, technological interventions require plotting, authorizing and promoting as well.</p>
                                <p>But the question arises, how tough will it be to implement this ban? What will be its impact on consumers and businesses? Will they find some alternatives? According to All India Plastic Manufacturers’ Association (AIPMA), plastic-processing industry has 30,000+ units in India have an annual turnover of Rs. 2.25 lakh crore where more than 4 million people are employed. Therefore, only banning will not resolve palpable problem of plastics in the current paradigm. Recycling, repairing and refurbishing of plastic needs to be implemented together as well. And in terms of plant closures and job losses, the government will have to sensibly evaluate the effect of the ban during the time of economic recession.
                                    While limiting the production and using some damaging plastics in a phased manner, it is essential and equally crucial to figure out how to handle plastic waste better. According to a report, even Mumbai, the financial capital of India, do not segregate dry and wet waste. Therefore, nothing can be said much for the smaller cities and towns throughout India. On the contrary, it is reported that there is a notable effort of waste collection in Pune. And, this initiative of the waste collection by partnering with the informal sector of Pune along with the sustainable source separation of waste in Panaji is an example of the seriousness of the Government regarding the issue.</p>
                                <p>Consequently, India has been pushed for a global determination under the aegis of the United Nations to pledge all countries phase out single-use plastic by 2030. However, good intentions must have a well-recognized path to accomplish the objective. Although, reduction strategies deliver tools to lessen single-use plastics at source, it is suggested that consistent measures ensure to be executed to alleviate plastic bag and micro beads pollution. Education campaigns will likely help further in moderating plastic pollution caused by micro beads and plastic bags at the source.</p>
                                <p>However as per the Global E-waste Monitor 2017 report by UNU, India is a major attributor to waste management on account of the e-waste legislation adopted by the nation in 2016. This is a strategic step in the direction of Extended Producer Responsibility (EPR) under the Plastic Waste Management Rules, 2016. As per this rule, importers, producers and brand owners of FMCG and Pharmacy companies are supposed to take back the plastic waste created by their products with the help of waste-management companies like Ahmedabad-based Nepra Resource Management. Nepra works with the companies like Nestle, PepsiCo and Colgate to collect their plastic waste from collection centers like the one at Malad in Mumbai. Every month, Nepra carries around 70-80 million tons of plastic, mostly MLP, from the facility to cement kilns in Dhar in Madhya Pradesh. There, it is recycled as a substitute for fossil fuels in cement kilns.</p>
                                <p>It is true that plastic is a threat. Rivers, canals, and oceans are obstructed due to the brunt of excessive use of plastic. And if the use of plastic is not controlled, then India will end up mishandling 4.17 percent of the global plastic waste by 2025. Therefore, only proper segregation of waste and recycling can pave a path towards making the country plastic-free. For this, many global FMCG giants have started taking their initiatives, such as Nestle and Unilever are planning to shift to single-polymer packaging by 2025. The Indian division of Unilever, Hindustan Unilever, used to collect and dispose 20,000+ tons of MLP waste in corporation with nonprofits and startups in more than 30 cities across India. They are also planning to expand it to more cities. By 2021, PepsiCo is planning to have 100 per cent recovery of its MLP in India. Flipkart plans to reject single-use plastics by March 2021. Its competitor, Amazon, targets to do it by June 2020. The companies will have to advance their deadlines, if India decides to ban them in October.</p>
                                <p>Aparajita Sanyal<br />
                                    Knowledge Cell</p>


                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
