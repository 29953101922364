import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function BlendedLearning() {
    return (
        <><title>Blended Learning: Grooming Students for “New Normal” corporate</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Blended Learning: Grooming Students for “New Normal” corporate</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Blended Learning: Grooming Students for “New Normal” corporate</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/blog-img_12_11_2020.png" alt="Blended Learning" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>The pandemic storm has disrupted the world like never before. It has created a new chapter in the historical pages of human civilization. Creating history means a change in the human era and the pandemic has created an era-wise bifurcation of a pre-COVID era and a post-COVID era. A pre-COVID era while had an alternative of human mobility and digital intervention, post-COVID era provides a major focus on the digital alternative as primary along with human mobility if required. The question of necessity versus another alternative has been reversed in this pandemic upheaval. This reversal has led to an indomitable impact in the working of human beings. The luxury that the digital platform provides in terms of pace, security, and comfort of work, along with a lot of money saving in terms of corporate infrastructure, has been realized like never before.</p>
                                <p>The digital potential has made corporate houses realize that they can do a lot with digital interface and stay ahead of the curve in terms of competition. So the “new normal” corporate organizations are revamping themselves to create an eco-system of digitalization in all their working processes even in post COVID period. This revamping is a significant change in the operational effectiveness of the corporate and hence they would require digitally-skilled human resource for driving the digital change in their organizations. Along with digital prowess, the corporate organizations are looking more towards those human resources who would be able to create a blending in terms of human mobility and digital interface and seamlessly have the ability to work merging the best of both the worlds. The requirements of the corporate is thus a dexterous employee with a dual talent of online and offline working.</p>
                                <p>To address this need gap of the corporate, the business schools need to groom their students to cater to the job market. Hence, the situation has become like a horse and a cart, one pulling the other. If business schools fail to create dexterous students with dual talent, there will be a time when these students will be unemployable. So business schools need to create an ecosystem of both offline and online format of education so that the students are employable for the “new normal” corporate organizations.</p>
                                <p>Globsyn Business School has been in the forefront of developing a blended ecosystem of learning to cater to the upcoming and to some degree the prevailing need gap of the market. From a majorly traditional mode of instruction, Globsyn was quick to understand the needs of the market, and foray into a completely digital mode of functioning in the working of the organization. In future, as the pandemic eases out,the blended culture of learning is an inevitable way forward to groom students to become future ready for the corporate. Collaborating digitally with top global institutes was always in the minds of Globsyn, but somewhere the pandemic acted as a catalyst in creating such collaborations and making it necessary for value creation for grooming the students to be ready for “new normal” corporate organizations. This step creates a major boost for organizations to recruit students who are ready to take on blended operational cultures of “new normal” organizations and a major impetus for students who are groomed and comfortable in such culture in their business school.</p>
                                <p>It is not only important to create a solo vertical involved in blended format, but it is more necessary to create an ecosystem of blended operational structure so that there is a blended culture in organizations working in the “new normal” environment. The students need to be groomed accordingly in business schools so that they can cope up with the new demand of the job market and be a rich sort after resource in the “new normal” business environment.</p>
                                <p>
                                    <b>Prof. (Dr.) Sabyasachi Dasgupta</b>
                                    <br />Faculty, Marketing & Research<br />
                                    Globsyn Business School
                                </p>


                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
