import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'


export default function Apprenticeship() {
    return (
        <>
            <title>Globsyn Apprenticeship Program - Globsyn</title>
            <section className="hero-banner-img-sceren">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Globsyn Apprenticeship Program</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Globsyn Apprenticeship Program</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>Global business is in a dire need of community with hands on knowledge of new technologies and skill-based work culture. The current workforce has very little exposure to any formal skills oriented training, resulting in an inept style of working among these individuals. Owing to this, many industries have started offering lucrative training-oriented apprenticeship programs that establish the concept of ‘Earn while You Learn’. In this mode post graduate students and college graduates can still be studying while getting explicit skills oriented training, along with a stipend, and companies that sponsor apprenticeship programs will be getting top-quality employees with the precise skills they need.</p>
                                <p>The term ‘Apprenticeship’ basically denotes work based training – essential to develop employable skills among millions of youth along with their higher education, and to provide a thrust in shaping the careers of these students into successful industry relevant managers. These days’ sectors like Health Care, E-commerce, Logistics, and more recently many public sector companies also take apprentices because it is mandatory by compliance. With innumerable benefits, apprenticeship programs are emerging as the key requirement for the corporate sectors who are seeking candidates with specific employability skills and technological advancements.</p>
                                <strong>Please fill up the form to enrol for the Globsyn Apprenticeship Program.</strong>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
