import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function StimulusPackage() {
  return (
    <><title>Announcement of the ‘Stimulus’ Package by the Indian Government</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">Announcement of the ‘Stimulus’ Package by the Indian Government</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog">Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="">Announcement of the ‘Stimulus’ Package by the Indian Government</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>
                    <div className="one-table">

                        <p>
                    <img className="img-fluid" src="/assets/images/website-blog-image_2.png" alt="Announcement of the ‘Stimulus’ Package by the Indian Government" />
                    </p>
                        <div className="entry-header">
                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>During the lockdown, Indian Prime Minister Shri Narendra Modi has addressed the nation to be self-reliant, for which he has declared a stimulus package of Rs. 20 Lakh Crore with the aim to nurse the economy back to health. This package includes measures of the Reserve Bank of India (RBI), as well as, the Ministry of Finance. His announcement of the package reveals that the aggregate sum of the declared package would be equal to 10% of the Gross Domestic Product (GDP). In this regard, it is fundamental to comprehend two relevant terms-‘Stimulus’ and ‘GDP’, and how these terms are co-related to each other ought to be emphasized.</p>
                        <p>The fundamental reason for this package is to increase the performance of the economy. Such exercises are practically comparable to the actions of the internal production of the GDP. Point to be noted, the mentioned activities are actually the sum expenditure made by the common people of the country owing to their consumptions during a particular period, the total invested sum of the investors, the total expenditure of the Government, and the difference between the total export sum and import expenses. At the point when all of these elements are adjoined, it shows the monetary value of all the finished goods and services related to the internal production of the country.</p>
                        <p>The economic package announced by the Prime Minister is going to be treated as a stimulus one as it can impact any of the components of GDP. Again the importance of the stimulus package in the economy is that it can create more opportunities in the field of employment in the country. Nonetheless, in reality, out of the estimation of such an economic package, only Rs 2.1 Lakh Crore worth can be considered as a stimulus because only this amount can influence the elements of GDP, and the rest of Rs. 17.9 Lakh Crore has no relation with the parameters that reveal the relationship between the stimulus package and GDP. Consequently, the scope of employment should be considered only on the value of the stimulus package i.e. Rs 2.1 Lakh Crore, but not on the declared aggregate amount by our Prime Minister during his speech on the disbursement of the stimulus package in the COVID-19 lockdown period. Therefore, it can be estimated that the actual percentage of the stimulus package is only 10% of the declared amount. The Government has tangled both the fiscal and monetary fund while declaring the aggregate value of the stimulus package. Such a declaration made by the Government may be impracticable in economic perception as referenced by numerous market analysts.</p>
                        <p>However, there are some optimistic outlooks associated with this announcement, which are scarcely identified by many of us. In the context of the COVID-19 situation when every sphere of life is struggling with the uncertainty, the stimulus package announcement may bring some ray of hope for the country. This announcement paves the path to develop the shattered economy of the country and may create ease of business processes that may guide to uplift the existing financial situation. The announcement of the stimulus package covers a large gamut of activities which is very pertinent during this COVID-19 period.</p>
                        <p>Firstly, even though the financial assistance announced by our Finance Minister is much lower than in many developed nations; however, by comparing the GDP of any developed country, we can observe that our stimulus announcement is much ahead than those countries that belong to the same GDP bracket. The Government has decided not to give their contribution regarding stimulus announcement for the country on one go. Time to time after considering the degree of the devastating nature of the Pandemic, the Government will take further decisions regarding the announcement of the future stimulus packages. The next important step taken by the Indian Government includes the distribution of free ration and direct benefit transfer by following the Jan Dhan Account Model. These services are being provided to the financially weaker section of the people, who are now undergoing a vulnerable state of life.</p>
                        <p>During the challenging times of the COVID-19 period, in many fields like small formal and informal businesses, our Government has declared to provide giveaways instead of a loan. This declaration is in stark contrast to the activity of a first-world economy like the US, which has provided loans instead of giveaway to the small business houses of its country. There are several areas where the Indian Government has provided assistance. Delayed tax payment, interest payment moratorium, loan guarantees to Small Medium Enterprises (SMEs), cutting benchmark rates, among others, are the supportive measures that must be considered as remarkable steps taken by the Indian Government.</p>
                    </div>

                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
