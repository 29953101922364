import React from 'react'
import { Link } from 'react-router-dom'
import '../Common.css'
import Sidebar from '../../Sidebar'

export default function Campus() {
    return (
        <>
            <title>History of Globsyn Business School Kolkata</title>
            <section className="hero-banner-img-inno">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Campus</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">Campus</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>

                            </div>
                            <div className="one-table">
                                <div className="one-table2">
                                    <p>Globsyn Business School, India’s first ‘Corporate B-School’, is situated at the outskirts of Kolkata, away from the hustle and bustle of the city. Spread over eight acres, the campus proposes dedicated hostel facilities for students, dedicated executive education wings, sports and recreational facilities along with conferencing and commercial facilities. Built to integrate ‘knowledge’ and ‘life’ within a single environment, the campus will have ultra modern residential blocks for teachers.</p>
                                    <p><strong>Globsyn Knowledge Campus</strong><br />
                                        Mouza Chandi, PS Bishnupur, J.L. No. 101<br />
                                        District:  24 Parganas (South)<br />
                                        Kolkata: 743 503, West Bengal, India<br />
                                        Phone: +91 82740 04556<br />
                                        Email: <a href="mailto:gbsdirect@globsyn.edu.in">gbsdirect@globsyn.edu.in</a><br />
                                        Principal Email: <a href="mailto:principal@globsyn.edu.in">principal@globsyn.edu.in</a></p>
                                    <p><iframe className='w-100' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.12874797834!2d88.26738931453156!3d22.348766985298465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02765321b3ac7b%3A0x4895a1fe6c77d19!2sGlobsyn+Business+School!5e0!3m2!1sen!2sin!4v1505382134278"  height="450"  title='map' allowFullScreen></iframe></p>
                                    <p>GBS Infrastructure [<a href="/assets/pdf/GBS-Infratructure.pdf" target="_blank" rel="noopener noreferrer">Download</a>]</p>
                                </div >
                            </div >
                        </div >
        <div className="col-lg-3 col-md-4 col-12">
            <Sidebar />
        </div>
                    </div >
                </div >
            </section >
        </>
    )
}
