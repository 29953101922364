import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function GlobsynManagementJournal() {
    return (
        <><title>Globsyn Management Journal: A Research Repository of the Management Realm</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Globsyn Management Journal: A Research Repository of the Management Realm</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Globsyn Management Journal: A Research Repository of the Management Realm</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/website-blog-images_16_07_2021.png" alt="Globsyn Management Journal" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Globsyn Management Journal (GMJ) has been a classy compendium of articles over the years. GMJ is a peer-reviewed scholarly journal that includes: research papers, perspectives, interfaces, case studies, book reviews besides a host of academic and industry related write-ups. This makes GMJ a useful reference document and study material to academicians and industry practitioners. The design, layout and overall texture of GMJ are such that it embellishes Globsyn Business School (GBS) brand. Even a cursory look at the journal contents captivates the reader’s attention, so it has found its pride of place in libraries of Universities and Academic Institutions both in India and abroad. GMJ keeps the GBS flag flying high wherever it is made available.</p>
                                <p>Since the GBS ecosystem mainly comprises students, faculty, corporate guests as speakers pitched in, to address students, GMJ permeates into intra-mural and extra-mural spaces thus ventilating the authors’ views on a plethora of topics concerning social sciences and several other disciplines thereby exploring innovative ideas and research-based findings both quantitative and qualitative in the arena of management.</p>
                                <p>With cherry-picked iconic personalities from inland and overseas in the GBS editorial board, the journal has a rigour of authenticity and originality which adds value to students’ learning and faculty being prompted to indulge in stellar teaching and seminal research. The editor spares no pains in ensuring that contents are pristine and original through a strong review process. Students get an opportunity to hone their writing skills and academicians are encouraged to delve more into newer areas with a discerning mind.</p>
                                <p>It has been my pleasure and proud privilege writing for GMJ year-after-year either individually contributing an article or book-review done as self or jointly as co-author in association with students and research scholars through case studies or on contemporarily germane topics. In the process, my networking has increased and outreach has expanded crossing both national and transnational boundaries. I look forward to subscribing in the form of write-ups to GMJ on an abiding basis in times to come.</p>
                                <br/>
                                <p><b>Prof. (Dr.) Debaprasad Chattopadhyay</b><br />
                                    HOD & Faculty – Human Resource<br />
                                    Globsyn Business School</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
