import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function EffectiveZoomGuide() {
    return (
        <><title>An Effective Zoom Guide: Features and Etiquettes you Should Know</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">An Effective Zoom Guide: Features and Etiquettes you Should Know</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">An Effective Zoom Guide: Features and Etiquettes you Should Know</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/Zoom Guide.png" alt="Effective Zoom Guide
                                    " />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Humans have the in-built ability to adapt themselves to changing natural and societal environments. The ‘New Normal’ setting in the post-pandemic educational environment reflects the amalgamation of two pedagogic systems viz. high-tech digital learning and traditional face-to-face learning. This new normal has made you comfortable in using the popular video teleconferencing apps like Google Classroom, Zoom, Microsoft Teams, Google Meet, WebEx for doing your live classes. Out of all these apps, Zoom Cloud Meeting has become quite popular among the global learners for its many rich and productive features and has rightly lived up to its promotional statement – “Modern learning for the Modern Student.”</p>
                                <p><b>Zoom Features</b></p>
                                <p>The most used and helpful Zoom features utilised by almost all students globally is as follows:</p>
                                <ul className='disc'>
                                    <li><b> Virtual Background</b> is invariably the most useful feature among global online learners. It helps to eliminate all kinds of background clutter and distractions through a static virtual background image. Users can choose from the pre-set ones, or create/upload new ones. The option is available on all Zoom versions above 5.1.1.</li>
                                    <li><b> Multiplatform Availability with High Definition video</b> on any SMART device – mobile phone, tab, laptop or desktop allows participants to attend the live classroom virtually from anywhere on this world through the internet connection.</li>
                                    <li><b> Calendar Integration</b></li>
                                    <li><b>Zoom Keyboard Shortcuts</b> like Alt + I, Alt + M, and Alt + Shift + S are used for commands like Invite, Mute and Share respectively for reducing the time spent.</li>
                                    <li><b>Scheduling Meetings Automatically</b> is an effective feature available in Zoom that helps in scheduling meetings automatically to save time.</li>
                                    <li><b>The Share Screen</b> feature allows the participants to share the contents of their devices, Slides, photos, videos, word files, PDF files, text, and organiser, effortlessly with the entire class and instructor with a voiceover.</li>
                                    <li><b>The Annotation</b> feature is there to mark, draw, put stickers or type on the shared screen content by the meeting attendees.</li>
                                    <li><b>Raise Hand</b>  is a unique feature where online meet attendees can virtually raise their hands if they have anything to ask during an online live session.</li>
                                    <li><b>Translation for ESL Students</b>is an effective feature that helps ESL students in rightly understanding the contents discussed in English language over the Zoom platform.</li>
                                </ul>
                                <p><b>Zoom Cloud Meeting Netiquette</b></p>
                                <p>While being online, it is better if you follow a certain set of rules, usually known as ‘Internet Etiquettes or netiquettes.’ These comprise of displaying proper online behaviour and also knowing and follow the socially accepted manner while collaborating online. The same is noted as follows:</p>
                                <ul className='disc'>
                                    <li>Login on time with your proper name and avoid using any fancy or quirky user names.</li>
                                    <li>Use an appropriate profile image so that even if the video is turned off, the image should represent you.</li>
                                    <li>Stay online, participate and respond – let your presence be known to the instructor and fellow attendees and mute yourself until you are requested to speak.</li>
                                    <li>Keep a check on your background, so that it does not become a distraction to others during the ongoing class. Keep away people or pets off-screen.</li>
                                    <li>Maintain proper hygiene, brush your hair and wear clean, non-wrinkled clothes while in front of the camera.</li>
                                    <li>Lastly, refrain from checking your phone, social media, chatting or roaming around while being online in the class.</li>
                                </ul>
                                <p>Online Learning at Globsyn Business School</p>
                                <p>Recognising the need of digitisation for regular courses, Globsyn Business School (GBS), being one of the forward thinking B-School in India, has integrated Zoom platform into the way of delivering classes. With Zoom Cloud Meetings, students connect and interact with their teachers and friends irrespective of their geographical locations through a high-end audio-visual system. The live classes allow them to experience the same as that of a physical classroom or the lecture hall. GBS has also used Zoom Cloud Meeting to conduct all its ‘Beyond Education’ activities into the online mode quite successfully during the stay-at-home phase.</p>
                                <p>That’s not all, if you like to know other Zoom features from an instructor’s perspective for effectively conducting your online classes, please read our blog titled ‘How to use Zoom for Effective Online Teaching?’.</p>
                                <p>You are also welcome to share your views on other features you find useful on the Zoom app in the below comment section.
                                    Till then, enjoy your learning with Zoom!</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
