import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function OnlineTeaching() {
    return (
        <><title>Online Teaching – The New Reality post COVID-19</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Online Teaching – The New Reality post COVID-19</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Online Teaching – The New Reality post COVID-19</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/web-site-blog-images_2.png" alt="online-teaching-the-new-reality-post-covid-19" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>At times when India is under lockdown due to COVID-19, the online teaching-learning process has become a new reality. It came as a boon to the students who otherwise would have lost a year of studies. All educational institutes are busy creating online learning content, pedagogy, teaching-learning platform, etc.</p>
                                <p>I have taken the opportunity to use technology as a virtual platform to educate students. I got myself adapted to technology platforms like WebEx, Google Classroom, Google Hangouts, and a few others to teach my students. Globsyn Business School guided me immensely on this aspect of online teaching as this was something that I was not adept with previously. Once I felt comfortable with the technology, I used virtual modes for session delivery, doubt-clearing, student mentoring, and various other activities to engage students on these online platforms.</p>
                                <p>With the use of technology I gradually started feeling that teaching online and offline is basically the same, as I also can share my laptop screen, lecture slides, assignments, and faculty notes very efficiently and smoothly on an online platform. Moreover, I can use virtual whiteboards and markers with the use of these said technologies. When I delivered the session on Data Analytics, I was able to share the Python codes with the students very easily through these platforms.</p>
                                <p>Initially, I was very skeptical about the attendance of the online classes. But due to the stringent student’s rule at Globsyn Business School, I saw a full house attendance after a few online sessions. Gradually, students also started becoming accustomed to online teaching and today probably both the faculty and student fraternity feel that online classes are as good and as efficient as that of offline classes.</p>
                                <p><b>Prof. Kaushik Ghosh</b><br />
                                    Faculty – Information Systems, GBS</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
