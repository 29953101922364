import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'


export default function ImportanceOfDivercity() {
    return (
        <><title>Importance of Diversity in Business Schools</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Importance of Diversity in Business Schools</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Importance of Diversity in Business Schools</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Young minds from multiple states will soon be thronging the corridors of Globsyn Business School (GBS) with dreams in their eyes in search of a corporate career. Coming from diverse backgrounds, these students will enter the gates of the School as strangers, but after two years, will unitedly walk out not just as proud GBS alumni but as friends for life. With the new academic session just around the corner, we thought of taking this platform to highlight some of the little joys that unity in diversity brings.</p>
                                <p>‘Unity in Diversity’ is the proverb we have heard a lot about. When we talk about diversity, we do not just talk about race and gender. We talk about a group of people coming together from various social stratas with different cultures, languages, experiences and educational backgrounds, involving in robust debates with differing opinions that has a propensity to spark innovative ideas in the students’ minds. Irrespective of the fact that whether the innovation is applied to management or product, if every person in the group thinks about the problem differently, it tends that they come up with a different conclusion. And that’s because each one of them come from an extremely different area and a different belief system. However, this does not happen when every person in the group is from the same background and the probability is that the conclusion will tend to be the same.</p>
                                <p>To get a better understanding on why diversity matters so much in business schools and how it benefits the students in their professional life, let us go through the following points:</p>
                                <ul className='disc'>
                                    <li><b>Diversity helps students to succeed in their future career.</b> It teaches students to develop kindliness, understandability and sensitivity to human differences. Thus, leading to successful performances in today’s diverse personnel.</li>
                                    <li><b>Diversity grooms students to work in the global society.</b> If you have been admitted into a business school, it means you are already on the threshold of a professional life. And a professional life is diverse in itself because whether it is your employers, co-workers, customers, or clients, everyone comes from different backgrounds. A business school with a diverse student community will definitely help a young manager to survive in the global corporate market.</li>
                                    <li><b>Diversity expands sophistication and worldliness while augmenting social expansion.</b> Somewhere and at some point in time, you will face segregation or antipathy from another group of people. But a business school with a diverse background can always enrich your mind and expand your social circle by giving you the opportunity to interact with diverse people.</li>
                                    <li><b>Diversity helps increase the students’ knowledge-base.</b> Interacting with those who are different from us enriches our knowledge about other people, and this results in creating a strong knowledge base about the world.</li>
                                    <li><b>Diversity promotes creative and innovative thinking.</b> Diversity helps you to think out of the box. It helps you to see the problems from multiple perspectives and let you solve it with innovative techniques.</li>
                                    <li><b>Diversity helps in self-cognizance.</b> Learning from people who belong to different backgrounds and who have a different system of beliefs, allow you to sharpen your self-perception and self-knowledge. It lets you compare your life experiences with people whose life experiences vary from your own and makes you be more self-aware. It results in making you capable of constructing informed verdicts about your academic as well as professional future.</li>
                                </ul>
                                <p>At Globsyn Business School, one of the <Link to="/">top B schools in India</Link>, we seek diversity based on gender, geography, academics, and work experience. From the valleys of Nagaland to the scorching plains of Chattisgarh, from the mountains of Sikkin to the beaches of Orissa – the Batch of 2019-21 of GBS will have some of the brightest and talented young minds from across the country, who will bring with them a cascade of energy, knowledge, skills and dreams that will help and nurture each of them to grow together. Different mother tongue, rituals and habits among the students help them to learn and adapt to the cultures around them.</p>
                                <p>A diverse classroom enables our students to adapt themselves in the fast-changing corporate world by allowing them to develop awareness, knowledge and skills to appreciate others view point and make constructive decisions in the work place. In today’s job market most of the top employers rank the candidates on the basis of their ability on how well they are able to fit within the organization’s culture and environment, and students who can handle both academic and co-curricular activities simultaneously will be able to multi task better when they enter the corporate world. Therefore, diversity in business schools is a must as it teaches the young managers how to sustain in the corporate domain.</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
