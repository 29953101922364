import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function DurgaPuja() {
  return (
    <><title>Durga Puja – A Management Case Study</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Durga Puja – A Management Case Study</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Durga Puja – A Management Case Study</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Durgapuja_Blog.jpg" alt="Durgapuja" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Durga Puja, the mother of all socio-cultural festivals, commemorates the homecoming of goddess Durga. Closest to the hearts of every Bengali household, the festival embellishes religiosity, spirituality and emotions. However, the festival not only blends the essence of religion and spiritualism in the air, but it also amalgamates huge marketing opportunities and branding strategies of various brands. With around 1-2 lacs of footfalls per day at the more popular pandals across the State, these corporate brands get immense exposure over the 5 days of festivities. Earlier, where brands’ advertisements were limited to banners and pamphlets, these days they decide the theme of the art and décor of idols & pandals, and promote it across print, electronic and digital media. Over the years these brands have blended with the celebration harmoniously, and have created a symbiotic relationship with each other.</p>
                                <p>However, the story behind this major visibility of brands is different. It takes months of preparation, ideation and planning to launch the perfect campaign that would grab the maximum eye balls. The art of successfully organizing a mega-scale event, like the Durga Puja, goes through an extensive research, time and budget management, prudent people management skills and organized hard work involving various departments, which in itself can pose to be an interesting case study for any management student.</p>
                                <p>Some notable points that the next-generation managers should take note of are as follows:</p>
                                <p><b>Research:</b> The first and foremost part of marketing is Research. A precise research or survey enlightens the company about its customers’ needs at a particular point of time. This helps in further planning and implementation of an idea, determination of the budget and management of the event.</p>
                                <p><b>Planning:</b> A proper Planning is one of the major management lessons that young managers should learn during Durga Puja. Decisions regarding budget and time needed for preparation, the numbers of man-power required for building of pandals, approach towards various corporates for sponsorships comes under planning. For a management student, learning this skill is very important as it teaches them the importance of advance planning.</p>
                                <p><b>Budget Management:</b> Festivals like Durga Puja require huge budget and proper management of finances require efficiency. There should be transparency between the committee members, maintenance of proper accountability of budget and avoiding unnecessary expenses while saving up for innovative ideas and other welfare activities. So, Budget Management is another aspect that a management student can learn from this festival.</p>
                                <p><b>Creativity & Innovation:</b> With thousands of idols gracing pandals across the state in all their stupendous glory, how can a particular pandal stand out, draw sponsors and ultimately enthrall the crowd? It is this sense of creativity and innovation, most of the times from limited resources, which can be a major takeaway for any management student.</p>
                                <p><b>People Management:</b> ‘People Management’ is a major component of management education. A five-day long festivity, which millions of people partake and where a single major pandal exhibits thousands of footfalls every day, requires superior levels of people management skill. Not just pandals, but proper management of traffic on roads and transportation services also requires proper coordination with police and civic volunteers.</p>
                                <p><b>Advertising & Brand Management:</b> Advertising & Brand Management is another major skill that every student of management education should learn during this time. Durga Puja, apart from being a ripe canvas for flaunting brands, advertising and promoting products, rides high on emotional quotient of the public. Keeping this in mind, marketing firms should ensure that the advertisements should be narrated in the form of stories upholding the emotion and nostalgia of ‘Durga Puja’, so that consumers feel a connection that will urge them to buy the product.</p>
                                <p><b>Team Building & Leadership Skills:</b> For a magnum opus, like the Durga Puja, to be successful it needs proper and swift coordination between various departments and teams, and a competent leader who knows his craft. Without a team and an able person to manage the team, it will be near impossible for the grand festivities to have a smooth run for five days.</p>
                                <p>
                                The major component of any festival, apart from the glamour, lights, advertising, awards and economy, is emotion that it generates. Durga Puja is an emotion to which every inhabitant of West Bengal is connected with. It not only brings joy to every household, but also teaches great lessons in life as well as in management. This ‘mother of all festivals’ will always be considered as one of the greatest case studies for any management student at any part of the world, at any given period of time.
                                </p>
                                

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
