import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ParadigmShift() {
  return (
    <><title>Paradigm Shift but Seamless Transition</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Paradigm Shift but Seamless Transition</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Paradigm Shift but Seamless Transition</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/website-blog-image.png" alt="Paradigm Shift but Seamless Transition" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Lockdown due to the COVID-19 pandemic has brought in several opportunities for the teachers as well as for the students, in terms of exploring more novel avenues of pedagogy delivery. In the initial phase, I was very apprehensive about my strength of ability to cope with the latest trends of technological platforms, with which we were not yet that acclimatized yet.</p>
                                <p>Within a very short time of the lockdown, Globsyn Business School (GBS), known for innovation, research, and technology, launched various online teaching programs for the benefit of its students. As it is always feasible to focus on the solution rather than the problems, GBS has not left any opportunities untapped to make a smooth transition from traditional to online methods of delivering academic pedagogy.</p>
                                <p>As there are always some initial teething problems with every new adaptations,<br/>
                                    I, too, initially faced a few hiccups as a teacher in order to deal with the new system, but in no time adjusted to it. The online teaching has become an amazingly smooth-sailing process. My students, on the other hand, adapted to the new system from day one and started enjoying the online classes. They too, just like us – their teachers, took an avid interest in getting to know the new system.</p>
                                <p>It was interesting to see that there was a healthy competition among them as to who has the most turnout for atteneding online classes effectively. The curriculum was subdivided into smaller chapters, allowing space for comprehensive discussions. Students had the opportunity to ask questions directly or to write on the chatbox. Keeping this positive attitude alive, we completed the syllabus successfully.</p>
                                <p>Truly, Globsyn Business School initiated a paradigm shift in the process of teaching pedagogy, where all the the teachers and the students has been involved in seamless transition to digital learning with effective learning experiences, during this uncertainty.</p>
                                <p><b>Prof. (Dr.) Shamindra Nath Sanyal</b><br />
                                Faculty – Marketing<br/>
                                Globsyn Business School
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
