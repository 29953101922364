import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function SpaceForMindfulness() {
    return (
        <><title>Making Space for Mindfulness</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Making Space for Mindfulness</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Making Space for Mindfulness</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Making-space-for-Mindfulness_2.jpg" alt="Making Space for Mindfulness" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>What is the meaning of the word ‘Mindfulness’? The meaning is pretty straightforward; it suggests mindfully attending to what one is doing.</p>
                                <p>On the other hand, the definition of ‘Mindfulness’ in psychology is the ability to be completely present in the moment, aware of where one is, what one is doing, and not be overly reactive or overwhelmed by what is going around. It is the quality that every human being possesses, and not something to conjure up. </p>
                                <p>The rush in everyday life, the deadlines to meet, and the responsibilities to do, every day at times may be quite burdening for a human brain. It is during those times that mindfulness works its magic. Mindfulness is the practice of purposely focusing your attention on the present moment—and accepting it without judgment. Mindfulness is now being examined scientifically, and is a key element in stress reduction and overall happiness.</p>
                                <p><b>Why is Mindfulness important in life? Is Mindfulness necessary for a successful life?</b></p>
                                <p>The cultivation of mindfulness has roots in Buddhism, but most religions include some type of prayer or meditation technique that helps shift your thoughts away from your usual preoccupations toward an appreciation of the moment and a larger perspective on life.</p>
                                <ul className='disc'>
                                    <li><b>Mindfulness improves well</b>-being.Increasing your capacity for mindfulness supports many attitudes that contribute to a satisfying life. Being mindful makes it easier to savour the pleasures in life as they occur, helps you become fully engaged in activities, and creates a greater capacity to deal with adverse events. By focusing on the here and now, many people who practice mindfulness find that they are less likely to get caught up in worries about the future or regrets over the past, are less preoccupied with concerns about success and self-esteem and are better able to form deep connections with others.</li>
                                    <li><b>Mindfulness improves physical health.</b>If greater well-being isn’t enough of an incentive, scientists have discovered that mindfulness techniques help improve physical health in several ways. Mindfulness can: help relieve stress, treat heart disease, lower blood pressure, reduce chronic pain, improve sleep, and alleviate gastrointestinal difficulties.</li>
                                    <li><b>Mindfulness improves mental health</b>.In recent years, psychotherapists have turned to mindfulness meditation as an important element in the treatment of several problems, including depression, substance abuse, eating disorders, couples’ conflicts, anxiety disorders, and obsessive-compulsive disorder.</li>
                                </ul>
                                <p><b>How do you apply Mindfulness in everyday life?</b></p>
                                <p>The practice of mindfulness is not just an activity limited to personal perusal. The utility and advantages have made it a must application in every sphere of life. Schools, colleges, and workplaces are emphasizing the application of mindfulness for a better life, an efficient workforce, and a healthy future. Some varied types of practices related to mindfulness are listed below.</p>
                                <p><b>Types of Mindfulness practice for every individual</b></p>
                                <p>While mindfulness is innate, it can be cultured through innate techniques, these practices are not only incorporated at an individual level, schools incorporate them into the young minds as well.They are as follows:</p>
                                <ul className='disc'>
                                    <li>Seated, walking, and standing meditation</li>
                                    <li>Short pauses we insert in everyday life</li>
                                    <li>Merging meditation practice with other activities</li>
                                </ul>
                                <p><b>Mindfulness at Workplace</b></p>
                                <p>Mindfulness at workplace is becoming more important as we transform from a manufacturing to a knowledge-based economy. In a manufacturing economy, the body is the primary means of production. But in a knowledge economy, it is the brain. A famous management consultant referred to employees in a knowledge economy as a “capital asset.” The truth is that nobody trains those who manage others how to manage themselves. And nobody learns how to transform the greatest tool in a knowledge economy, the mind itself! Here are some ways through which mindful practices can be preached at the workplace.</p>
                                <ul className='disc'>
                                    <li><b>Body-scan Meditation</b>.Lie on your back with your legs extended and arms at your sides, palms facing up. Focus your attention slowly and deliberately on each part of your body, in order, from toe to head or head to toe. Be aware of any sensations, emotions, or thoughts associated with each part of your body.</li>
                                    <li><b>Sitting Meditation</b>.Sit comfortably with your back straight, feet flat on the floor, and hands in your lap. Breathing through your nose, focus on your breath moving in and out of your body. If physical sensations or thoughts interrupt your meditation, note the experience and then return your focus to your breath.</li>
                                    <li><b>Walking Meditation</b>. Find a quiet place 10 to 20 feet in length, and begin to walk slowly. Focus on the experience of walking, being aware of the sensations of standing, and the subtle movements that keep your balance. When you reach the end of your path, turn and continue walking, maintaining awareness of your sensations.</li>
                                </ul>
                                <p><b>Mindfulness at Globsyn Business School</b></p>  
                                <p>Globsyn Business School (GBS) has always believed that a peaceful mind leads to positive thinking, which helps an individual to achieve new heights. With an avid interest in the development of its students’ and employees’ emotional health, GBS has been organizing Anapana Meditation Sessions that integrates the Vipassana Meditation technique towards building a mindful and holistic education and workplace ecosystem.</p>
                                <p>Thus, when questions like, “Is mindfulness necessary for a successful life? “, and “Why is mindfulness important in life?’ arise in the mind, we should reflect on the qualities of mindfulness listed in this blog. It is high time that each of us initiate our minds with the power of mindfulness for a healthy and prospective future amidst all the hustle and bustle of our lives.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
