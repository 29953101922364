import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ResearchOnlyReSearch() {
    return (
        <><title>Is Research Only Re-Search? Decoding the Myth of Valued Output</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Is Research Only Re-Search? Decoding the Myth of Valued Output</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Is Research Only Re-Search? Decoding the Myth of Valued Output</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Is-Research-only-Re-search-Decoding-the-Myth-of-Valued-Output.png" alt="Is Research Only Re-Search?" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Dr. Sabyasachi Dasgupta teaches Marketing at Globsyn Business School – one of the best business management colleges in Kolkata. Dr. Dasgupta has commanded prominent positions in several corporate and academic giants for more than 14 years. He has taught at the Florida State University, USA and has also been a visiting researcher in the University. Dr. Dasgupta, apart from presenting research papers in national and international conferences, also has a number of publications including Scopus/ABDC/UGC Care, edited books, number of cases, book chapters and a book under his name. He has also received the distinguished Research Excellence Award 2020. Dr. Dasgupta heads the Research and Publications vertical of Globsyn Research Cell. He is also a scientific committee member of TIIKM – Colombo, a member of American Marketing Association and Calcutta Management Association, and a reviewer in Journal of Brand Management, Journal of Creative Communication and journals published by American Marketing Association.
                                    </em>
                                </p>
                                <p>The word research is heard everywhere these days. Most people feel that research is important and they are doing it. The good part of this feeling is that for ages, researchers were trying to establish the fact that research is important but a sizable number of populations thought that it was a giant left to be identified, explored and validated by a collective few, possessing an invisible halo behind their heads. Now, that feeling was on one end of the spectrum and on the other end, is today’s feeling that everyone can do it and ‘we are doing it’.</p>
                                <p>The actual problem is that people are still trying to grapple with the subject called research and decipher the true essence of it. In the corporate world, there was a point of time when gut feel was the only way a brand could fight in the marketplace. Gone are the days when only gut feel enabled the brand to be sustainable in the marketplace.</p>
                                <p>Today, companies are doing research not only in-house, but they are hiring market research agencies to work for them. So, the development of research acumen among students is one of the most important knowledge building blocks to prepare them for the corporate world.</p>
                                <p>Educational institutions, like Globsyn Business School, have a separate research wing called Globsyn Research Cell, catering to the research needs of the students and mentored by the faculty to keep control of the quality of the valued research output. So, it is a myth, when people search for some information and they say they are doing research; it is a myth that everyone is appropriately using the word research; it is a myth that research is just for the academic world; it is a myth that research can only be done by a collective few.</p>
                                <p>Beyond these myths, is the myth that research is a domain of a collective few intellectuals who have the power of transforming the world. Research is a systematic, processed, and planned study requiring immense depth of knowledge at every step of research and an appropriate way needs to be undertaken for getting a credible result. Research is a valued output for academicians theorizing and generalizing a phenomenon, global/national or regional in nature. It is a valued output for companies trying to get a robust insight of consumers or strategize the next big move in the market. It is a valued output for society trying to understand social problems and thereby bringing social change for the upliftment of civilization.</p>
                                <p>Research needs to be conducted ethically, unbiasedly with a clear objective which will lead to the outcome and needs to be extremely specific. The relevance as well as the novelty of the research through its robust methodology would create differences in the way civilizations operate in the existing social framework.</p>
                                <p>While classes and conferences of research done by educational institutions like Globsyn Business School, is extremely important for the preparation of students to be corporate ready, those who feel that they would join academics or become a social changer or a social entrepreneur, would also benefit out of these endeavors of the business schools’ curriculum. Research requires training for its appropriate conduction and immaculate results for the benefit of social ecosystems. Research is not for all – it is for those who are trained in the process of research (and anyone can be) and who can add novelty in the existing knowledge framework and civilizational ecosystems.</p>
                                <p>The value of research lies in the valued output by trained professionals whose mindsets are research oriented and a researcher mindset is far more required than a testimonial degree bestowed in a convocation. Self-trained researchers too, are researchers who are finding gaps in society and either validating the alternative or researching for self-actualization. The essence of a researcher is to understand what others cannot, be a visionary to analyze the trends and forecast the path ahead, which makes their enriched minds go ahead of others in the same ecosystem and provide a blueprint of a better tomorrow.</p>

                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Sabyasachi-Dasgupta.jpg"
                                            alt="tt"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Dr. Sabyasachi Dasgupta
                                            <br />
                                            Faculty – Marketing
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
