import React from 'react'

export default function PlacementList() {
  return (
    <div className="container brand-box-modal-slider">
			<div className="row">
				<div className="col-md-12">
					<div className="top-brand-logo"><img src="https://www.globsyn.edu.in/wp-content/uploads/2022/12/gbs-re-up-logo-1.png" alt=""/></div>
					<div className="title mb-3 text-center" >
						<h3>PLACEMENT 2023</h3>
					</div>
					<div className="title mb-3 text-center" >
						<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs-placement/Placement Photograph for Website.jpg" alt=""/>
						&nbsp;
						<p className="Big-vrs" ><strong><span>Students of Batch 2021-23 after getting placed during Placement 2023</span></strong></p>

						<p className="mobile-vrs" ><strong><span>Students of Batch 2021-23 after getting placed <br/>during Placement 2023</span></strong></p>
					</div>
					<p className="text-justify">With 3500+ alumni working at 360+ corporates globally, Globsyn Business School (GBS) has always been a pioneer in providing optimum placement to its students. Considered to be one of the best placement MBA colleges in Kolkata, GBS has been lauded with the ‘Best Private B-School in Eastern India’ by The Times Group, at Times Brand Icons 2022.
					</p>
					<p className="text-justify">
						The various academic delivery practices of the B-School have significantly contributed in a steady growth of CTC over the decades, and this legacy is prevalent during this year's Placement Season as well. Placement 2023 witnessed the average growth of CTC from 5.03 LPA during 2018-20 to 7.51 LPA during 2021-23, which is an impressive 49.30% jump!
					</p>
					<div className="row gallery">
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_A1 Advisory.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/A-1-Advisory.png" alt="" className="img-responsive"/>
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<small className="single">Suvam Bose</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_ABP Network.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/ABP-Network.png" alt="" className="img-responsive"/>
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													
													<small className="single">Meghna Datta</small>
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Alright.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Alright.png" alt="" className="img-responsive"/>
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<small className="single">Bidisha Ghosh</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Asian Paints.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Asian-Paints.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item">
														<small>Aritra Naskar</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>G Jaswant Patro</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Sk Saimun Ali</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Snehasish Saha</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Bajaj Allianz_1.jpg" data-fancybox="Bajaj" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Bajaj-Allianz.png" alt="" className="img-responsive"/>
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item">
														<small>Bishal Seal</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Chitrit Dhanuka</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Pronamita Nath</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Raj Baidya</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Sanchali Chatterjee</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Saptorshi Ghosh</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Shubham Kumar Singh</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Shubhankar Ghosh</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Soumita Manna</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Souvik Roy</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Suparna De</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Suvajit Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Tapaswini Panda</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Yashdeep Sharma</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Bajaj Allianz_2.jpg" data-fancybox="Bajaj"  title=""><div></div></a>
						</div>
						
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Bandhan Bank.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Bandhan-Bank.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Diptanu Sarkar</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Diptayan Bhowmick</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Joy Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Kaustav Ray</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Sonam Agrawal</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Souvik Das</small>
													</div>
													<div className="slider3d__item">
														<small className="crd-text-mb-vrs">Supratim Banerjee</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Vishnu Das</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Berger Paints.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Berger-Paints.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<small className="single">Dattatraya Mishra</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_BuyerForeSight.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/BuyerForeSight.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">

													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Ayush Agarwal</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Kaushik Dutta</small>
													</div>
													<div className="slider3d__item">
														<small>Mohona Gupta</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Sangeeta Majumder</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Shubhamay Das</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Coffe Day Beverage.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Coffee Day Beverage Logo.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
											
													<div className="slider3d__item">
														<small>Ihtesham Afzal</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Soham Mukherjee</small>
													</div>
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Deloitte USI - Risk and Advisory.jpg" data-fancybox="Deloitte" title="">
								<div className="brands-slider" >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Deloitte.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Abhilash Gupta</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Abhishek Singh</small>
													</div>
													<div className="slider3d__item">
														<small>Rahul Mukherjee</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Rahul Ghosh</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Rounak Singh</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Rudraneel Paul</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Rudra Prasad Ghosh</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Samapti Saha</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Subham Pramanik</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Sumaiya Sultana</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Susmita Roy Chowdhury</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Shashank Jha</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Sk Nazimul Islam</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Somleena Raha</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Souradip Ganguly</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Subhasree Kundu</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Sucheta Guria</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Swagato Majumder</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Shresthy Chakraborty</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Deloitte USI-Tax_1.jpg" data-fancybox="Deloitte"  title=""><div></div></a>
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Deloitte USI-Tax_2.jpg" data-fancybox="Deloitte"  title="ta"><div></div></a>
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Deloitte-India.jpg" data-fancybox="Deloitte"  title="ta"><div></div></a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Ernst & Young_1.jpg" data-fancybox="Ernst_&_Young" title="" >
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Ernst-&-Young.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">					
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small>Anirup Guha</small>
												</div>
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small>Aman Deo</small>
												</div>
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small>Akriti Kar</small>
												</div>
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small className="crd-text-mb-vrs">Diptarup Bhattacharya</small>
												</div>
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small className="crd-text-mb-vrs">Deepshikha Ganguly</small>
												</div>
												<div className="slider3d__item">
													<small>Namrata Dutta</small>
												</div>
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small>Puja Singh</small>
												</div>
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small>Shivangi Brahma</small>
												</div>
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small>Shounak Samanta</small>
												</div>
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small className="crd-text-mb-vrs">Sankhadeep Karan</small>
												</div>
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small>Shyama Dutta</small>
												</div>
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small>Tonmoy Dey</small>
												</div>
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small>Waqar Ahmed</small>
												</div>
												<div className="slider3d__item text-uppercase font-weight-bold">
													<small>Ratul Ray Rakshit</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</a>
						<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Ernst & Young_2 final.jpg" data-fancybox="Ernst_&_Young"  title=""><div></div></a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Eureka Stock & Share Broking Service.jpg" data-fancybox="Exide" title="">
								<div className="brands-slider" >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Eureka-Stock-&-Share-Broking-Service-Logo.png" alt="" 
										className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Akash Roy</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Rohit Kumar Shaw</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
							
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Exide.jpg" data-fancybox="Exide" title="">
								<div className="brands-slider" >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Exide.png" alt="" 
										className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Iemon Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Kuntal Koley</small>
													</div>
													<div className="slider3d__item">
														<small>Suranjana Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Susmita Roy</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
							
						</div>
						

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Federal Bank.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Federal-Bank.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<small className="single">Ehtesham Zaheer</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Fi-Tek.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Fi-Tek.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">	
													<small className="single">Asmita Bhattacharya</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_GlobalData.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/GlobalData.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<small className="single">Ritam Saha</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_HDB Financial.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/HDB Financial Logo.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<small className="single">Ayindrila Chowdhury</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_HDFC-AMC.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/HDFC-AMC.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>P Roshan</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Souvik Rakshit</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_HDFC Life.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/HDFC Life Logo.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Anirban Mukherjee</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Bikash Sardar</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Gargi Chowdhury</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Pritam Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Shrestha Majumder</small>
													</div><div className="slider3d__item text-uppercase font-weight-bold">
														<small>Shubhadeep Bhattacharjee</small>
													</div><div className="slider3d__item text-uppercase font-weight-bold">
														<small>Subhrachitta Giri</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_HLS Asia.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/HLS-Asia.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<small className="single">Pralay Bera</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_ICICI Prudential.jpg" data-fancybox="ICICI-Prudential-(LI)" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/ICICI-Prudential-(LI).png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item">
														<small className="crd-text-mb-vrs">Chaitali Majumder</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Monishweta Ghorai</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Rahul Mondal</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_ICICI Securities_1.jpg" data-fancybox="ICICI_Securities" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/ICICI-Securities.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item">
														<small>Bindurekha Dash</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Deep Chakraborty</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Dipta Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Jayita Karmakar</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Oihik Mitra</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Puja Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Rounak Banerjee</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Rahul vyas</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Sourav Chakraborty</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Vishal Gupta</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Vishal Singh</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_ICICI Securities_2.jpg" data-fancybox="ICICI_Securities" title=""><div></div></a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_IDFC First Bank.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/IDFC-First-Bank.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Satyaki Bhattacharya</small>
													</div>
													<div className="slider3d__item">
														<small className="crd-text-mb-vrs">Siddhant Mukherjee</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Somdatta Ghosh</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Indiamart.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Indiamart.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
											<div className="slider3d__wrapper">
												<div className="slider3d__inner">
													<div className="slider3d__rotater">
														<small className="single crd-text-mb-vrs">Shahin Amanullah</small>
													</div>
												</div>
											</div>
										</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Jio.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Jio.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Sounick Batabyal</small>
													</div>
													<div className="slider3d__item">
														<small>Subhajyoti Patra</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Subham Bandyopadhyay</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						
						
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Kalolwala & Associates.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Kalolwala-&-Associates.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item">
														<small>Shreya Mukherjee</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Shabbrin Rizwi</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Kantar.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Kantar.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Anibrata Ghosh</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Prerona Adhikary</small>
													</div>
													<div className="slider3d__item">
														<small className="crd-text-mb-vrs">Tandrima Chatterjee</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Kimberly Clark.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Kimberly-Clark.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Annie Akhom</small>
													</div>
													<div className="slider3d__item">
														<small>Solanki Das</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Lava International.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Lava-International.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">	
													<small className="single">Sayantan Ghosh</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Magma HDI.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Magma-HDI.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Abhishek Giri</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Ayushi Roy</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Akriti Kumari Sharma</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Rohit Ray</small>
													</div>
													<div className="slider3d__item">
														<small>Udita Pal</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Marico.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Marico.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Karan Dutta</small>
													</div>
													<div className="slider3d__item">
														<small>Surbhi Agarwal</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Motilal Oswal.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Motilal-Oswal.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item">
														<small>Ankit Roy</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Soumyajit De</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_MRF.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/MRF.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<small className="single">Animesh Halder</small>	
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Nihilent1.jpg" data-fancybox="Nihilent" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Nihilent.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<small className="single">Anusree Das</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>

							

						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_OTIS.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/OTIS.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<small className="single">Aheli Sanyal</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Pantaloons.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Pantaloons.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Rahul Roy</small>
													</div>
													<div className="slider3d__item">
														<small>Raima Shome</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_PhonePe.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/PhonePe.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item">
														<small>Kajal Jain</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Prasenjit Majumder</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Prasid Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Saurav Nandy</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Sourav Kumar Sarkar</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Tiyasha Bose</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Pinelabs.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Pinelabs.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Brijesh Debnath</small>
													</div>
													<div className="slider3d__item">
														<small>Nabyendu Kuiti</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Shiwanand Soy </small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_PWC_1.jpg" data-fancybox="PWC" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/PWC.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Anisha Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Avik Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Ankit Kumar Palai</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Iftesum Anjum</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Kanika Nath</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Kritika Sharma</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Mayurakhi Bhowmik</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Nafees Hussain</small>
													</div>
													<div className="slider3d__item">
														<small>Pritam Basak</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Prakriti Singha</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Poulomi Dey</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Sanjit Dutta</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Surbhi Jain</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Ushan Bellani </small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Vatsal Bangur</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_PWC_2.jpg" data-fancybox="PWC"  title=""><div></div></a>
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_PWC-India.jpg" data-fancybox="PWC"  title=""><div></div></a>
						</div>
						
                           <div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Shadowfax.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Shadowfax.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<small className="single">Diya Dhar</small>	
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Shri Mahavir Ferro Alloys.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Shri-Mahavir-Ferro-Alloys.png" alt="" className="img-responsive" /> 
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Nikhilesh Garh</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Vishnu Kejriwal</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_TATA-AIG.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/TATA-AIG.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Arpita Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Ayan Poddar</small>
													</div>
													<div className="slider3d__item">
														<small className="crd-text-mb-vrs">Milan Chakraborty</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Mainak Chakraborty</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Rose Indra</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Sreeradha Hazra</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Suranjan Dey</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Ujjivan SFB.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/Ujjivan SFB Logo.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Aniket Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Ankita Kumari</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Hritik Kumar</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Karan Debbarma</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Mrinmoy Jana</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Rahul Kumar Mahato</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Shivam</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Sultana Khatun</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Tiasha Saha</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_UTI.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/UTI-Mutual-Fund.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Bhawna Joshi</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Mitul Ganguly</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small className="crd-text-mb-vrs">Soumyadeep Dutta</small>
													</div>
													<div className="slider3d__item">
														<small>Soumyadip Saha</small>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_VIP.jpg" data-fancybox-group="gallery" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/VIP.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Koustav Das</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Sayandrila Ghosh</small>
													</div>
													<div className="slider3d__item">
														<small>Shreyasee Saha</small>
													</div>	
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-6 mb-3 pb-3">
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Vodafore Idea_1.jpg" data-fancybox="Vodafone" title="">
								<div className="brands-slider"  >
									<div className="brand-logo text-center">
										<img src="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_logo/VodafoneIdea.png" alt="" className="img-responsive" />
									</div>
									<div className="slider3d first">
										<div className="slider3d__wrapper">
											<div className="slider3d__inner">
												<div className="slider3d__rotater">
													
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Aniruddha Sinharoy</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Karishma Kumari</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Khusboo Kumari</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Rinka Debnath</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Ankita Singha</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Arpita Singha</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Disha Ram</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Poulami Sarkar</small>
													</div>
													<div className="slider3d__item text-uppercase font-weight-bold">
														<small>Saranya Dutta</small>
													</div>
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
							<a className="fancybox" href="https://www.globsyn.edu.in/wp-content/uploads/gbs_placement_logo_student/gbs_student/Placement Light Box_Vodafore Idea_2.jpg" data-fancybox="Vodafone"  title=""><div></div></a>
						</div>
					</div>
				<div className="bottom-content text-center" >
					<h4><span>#AmbitionOfTheYoung</span></h4>
					<br/> <br/>
					<div className="rtin-btn text-uppercase"><a href="https://www.globsyn.edu.in/landing/brochure.php">DOWNLOAD BROCHURE</a></div>
				</div>
			</div>
		</div>
	</div>
  )
}
