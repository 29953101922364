import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function OttService() {
    return (
        <><title>OTT Services – A Game Changer for Young Managers</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">OTT Services – A Game Changer for Young Managers</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">OTT Services – A Game Changer for Young Managers</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p><b>Scenario 1:</b></p>
                                <p>Mr. Jignesh was extremely frustrated with the local cable TV operator because of their labyrinth of rules and regulation regarding channel selections. Whenever he wanted to watch a cricket match which was not broadcasted in any of the channels he subscribed, he was forced to contact his local cable operator for subscription. Realizing the problem his uncle was facing, his nephew Amit gifted him a large screen smartphone on his birthday. Amit also suggested him to download a particular app offered by the telecom operator whose pre-paid connection was taken along with the smartphone purchase. When Mr. Jignesh asked his nephew about the reason for downloading the app, Amit told him that he could watch his favorite game online without the headache of contacting the local cable operator every time he wanted to subscribe to a particular subscription channel.</p>
                                <p><b>Scenario 2:</b></p>
                                <p>Mrs. Roy’s daughter Puja, lives in New Jersey. Earlier, Mrs. Roy used to purchase ISD pack every month to talk to her daughter. Nowadays she speaks to her daughter through WhatsApp, using mobile data without the need to recharge any ISD package.</p>
                                <p>The two above mentioned scenarios are examples of the emergence of ‘Over the Top’ (OTT) services, which are much in demand these days.</p>
                                <p><b>But what is OTT all about?</b></p>
                                <p>Over-the-Top (OTT) services are the services that provide product/service over the Internet, bypassing the more traditional channels. Services, facilitated by OTT, are most typically related to media & communication, and their prices are normally lower than the traditional distribution channels. These services are generally availed through websites on computers, as well as via apps on mobile devices (such as smartphones and tablets), digital media players (such as video game consoles), or televisions with integrated smart TV platforms.</p>
                                <p>The different types of services offered through the various types of OTT platforms are:</p>
                                <p><b>OTT Television</b> – these platforms are normally called Online Television or Internet Television or Streaming Television<br />
                                    <b>Popular examples</b> – Netflix, Hotstar, ZEE5 etc.
                                </p>
                                <p>
                                    <b>OTT Messaging</b> – these platforms are instant messaging services or online chat provided by third parties, as an alternative to text messaging services provided by a telecom service provider
                                    <br /><b>Popular examples</b> – WhatsApp, Telegram, Google Allo etc.
                                </p>
                                <p><b>OTT Voice Calling</b> – these platforms are generally called VOIP (Voice-Over Internet Protocol) services, where open internet communication protocols replace and sometimes enhance existing operator controlled services provided by mobile service operators
                                    <br />
                                    <b>Popular examples</b> – WeChat, Skype, Viber etc.
                                </p>
                                <p>In the recent past, we have seen a significant increase in the popularity of OTT platforms, which resulted in the emergence of a number of ‘players’ in the market as mentioned above. Different companies have been using different strategies to monetize their services in the OTT market, such as, Advertising Video on Demand (AVOD), Transactional Video on Demand (TVOD) and Subscription Video on Demand (SVOD).</p>
                                <p>In AVOD model, OTT services are monetized through advertisements, one of the most common strategies among OTT players. YouTube has adopted this strategy quite successfully. On the other hand, TVOD model is based on a pay-per-view, a la carte OTT content, like Google play movies/ YouTube rental video or movie.</p>
                                <p>To retain viewers and insist on more transactions, TVOD model rely on selective and captivating viewing experiences. It is something like a fine-dining experience; you will only get whatever you order. SVOD services on the other hand, are antithetical to TVOD services in the sense that they are easy to sign up for and easy to opt out from. The providers of SVOD are continually challenged with retaining consumers, by providing exclusive new content, aggressive pricing schemes – and probably both.</p>
                                <p>The OTT video content market of India, though competitive, is still in its nascent stage. A recent survey conducted by Counterpoint Research reveals that though subscription-based market (SVOD) continues to grow considerably in the country, the Indian market still remains highly concentrated on the Ad-based model (AVOD), where advertisements are the drivers of revenue generation. The five metro cities account for 55 percent of the overall OTT video platform users, while Tier-I cities account for another 36 percent of the users. The research also revealed that Hotstar leads the Indian OTT video content market, followed by Amazon Prime Video, Sony LIV, Netflix, Voot, Zee5, ALTBalaji and ErosNow in terms of the percentage of respondents subscribed to each platform.</p>
                                <p>(Source: www.thehindubusinessline.com, June 19, 2019)</p>
                                <p>There is a huge potential in Indian online video market. Apart from the traditional Over-the-Top (OTT) providers, major broadcasters in the country have also entered into this market segment and launched their own OTT platforms. As per PwC Report, OTT video revenue in India has been pegged at Rs 2,019 crore in 2017, and is anticipated to reach Rs 5,595 crore by 2022.</p>
                                <p>(Source: timesofindia.indiatimes.com, June 26, 2019)</p>
                                <p>Apart from rapid increase of internet penetration, some of the major factors that fuel the growth of Indian OTT video content market are high-value content at low cost, original theme and compatibility with multiple devices.</p>
                                <p>Most OTT services are associated with ‘cord cutting’ — the practice of shifting from TV/traditional voice call subscriptions to web-based applications. While ‘cord cutting’ has definitely increased OTT consumer base, a major part of rural India still thrives on traditional channels as their source of entertainment. To combat this shortage is a major challenge to the OTT market in India.</p>
                                <p>Having said that, OTT services in the Indian sub-continent have enough space to evolve and grow in the country, as compared to traditional platforms. A complete migration from the traditional channels to the OTT service providers will still take some more time, but it can safely be said OTT services are gradually reshaping the future of media and communication with the help of technology. Young managers should keep a close tab on this emerging revolution, as it will help them understand how the marketing dynamics change with the shift in the entertainment demography.</p>
                                <br/>
                                <p>Prof. Debmalya Banerjee<br/>
                                Knowledge Cell</p>

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
