import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function CampusFacilities() {
    return (
        <><title>Campus Facilities - Globsyn</title>
            <section className="hero-banner-img-sceren">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Campus Facilities</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Campus Facilities</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <img src="./assets/images/Campus-Facilities.jpg" alt="campus" />
                                <br></br>
                                <p>Globsyn Business School (GBS) has a sprawling state of the art eight-acre campus located amidst a lush green landscape in the outskirts of Kolkata – an amiable environment provides exclusive facilities to students to learn, conceive and implement their ideas. The Campus is well equipped with state-of-the-art modern facilities like Globsyn Student Home, Hybrid Learning Classrooms, Study Lounge, Outdoor Sporting Facilities, Well-equipped Library, Open Air Cafeteria, and various other services.</p>
                                <p>Studying at GBS is a life changing experience – with emphasis on intellectual discovery and critical thinking, the students are provided an amiable environment for both intellectual discourse and experiencing corporate life.</p>
                                <br />
                                <div className="accordion" id="myAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Globsyn Student Home</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Living in the Globsyn Student Home or hostel is an excellent way to kick start your 2-year journey at Globsyn Business School. Situated inside the B-School’s campus, the hostel gives a secure environment for the students, and boosts camaraderie between the students. It also plays a significant role in building tomorrow’s managers and entrepreneurs.</p>
                                                <p><a href="https://www.globsyn.edu.in/globsyn-student-home/"><em>More Information…</em></a></p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingtwo">
                                            <button type="button" className="accordion-button collapsed " data-bs-toggle="collapse" data-bs-target="#collapsetwo">Hybrid Learning Classrooms</button>
                                        </h2>
                                        <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Globsyn Business School has taken a step to become a trend-setter in designing Hybrid Learning Classrooms for its young managers. The power of this learning format lies in the fact that the students, regardless of their chosen post-graduate management programmes, can continue their courses remotely and/or re-live the physical classroom experience, as and when they prefer.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingthree">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Study Lounge</button>
                                        </h2>
                                        <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Students at GBS can choose to have constructive discussions at the Nest Lounge strewn with couches and bean bags, which replaces the solemn atmosphere of the class room with a more relaxed air filled with laughter, table tennis, pool or board games. An ideal place for students to immerse themselves into fervent conversations and brain-storming sessions, which would ultimately shape them into becoming ‘industry relevant managers’.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingfour">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefour">Outdoor Sporting Facilities</button>
                                        </h2>
                                        <div id="collapsefour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>An active involvement in all kind of sports is encouraged at the Campus. Students can take part in a variety of outdoor sporting facilities, including Badminton and Volleyball. A four-day-long sports extravaganza ‘Titanium’ is organized at the GBS Campus, with the idea of encouraging ‘Serious Fun’ among students.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingfive">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefive">Well-equipped Library</button>
                                        </h2>
                                        <div id="collapsefive" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>The well-stocked Bird Library, with more than five thousand books and journals, is a prime asset of GBS. Students can also check the e-catalogue comprising of more than one lakh e-books and do some reference work to help with their coursework. The B-School also has an eLibrary – a digital repository of more than 500 books and 100+ videos added every week – making sure that students always have access to books and videos to further their quest in management education.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingsix">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsesix">Jonathan’s Cove</button>
                                        </h2>
                                        <div id="collapsesix" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Jonathan’s Cove – the open-air cafeteria – apart from providing healthy delicious refreshments throughout the day is a prime location where students can be found, in between classes, working on projects, completing assignments, debating management case studies, having discussions with faculty, or engaging in casual banter with their cohort.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingseven">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseseven">Other Facilities</button>
                                        </h2>
                                        <div id="collapseseven" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <strong>Study Hubs</strong>
                                                <p >Abundant designated areas where students can discuss with their peers and even faculty members about various aspects of the curriculum, and get a better understanding on the subjects.</p>
                                                <strong>Medical Room</strong>
                                                <p >A room, equipped with first aid facilities inside the GBS Campus, which caters to the immediate medical requirements of the students.</p>
                                                <strong>Bus Service</strong>
                                                <p >Complimentary bus service that allows students to get picked up from major locations across the city to the eco-friendly GBS Campus, and back.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
