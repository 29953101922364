import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../Sidebar'



export default function PgdmBusiness() {
  return (
    <>
            <title>PGDM in Business Analytics Course with Placement in Kolkata, India</title>
            <section className="hero-banner-img-pgdm">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">PGDM – Business Analytics</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">PGDM – Business Analytics</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                                
                            </div>
                            <div className="one-table">
                                <div className="one-table2">
                                    <p>One of the most sought after Business Analytics PG courses in India, Globsyn Business School’s AICTE approved Post Graduate Diploma in Management – Business Analytics (PGDM – Business Analytics) curriculum provides in-depth knowledge of analytical concepts, techniques, and their applications in improving business decisions. The objective of the programme is to facilitate in-depth exposure to the students regarding methodologies and practices of Business Analytics and to prepare them for the emerging needs of industry in the various functional domains.</p>
                                    <p>In today’s corporate world data driven decision making is the most sought-after industry skill. The PGDM – Business Analytics programme will focus on honing your understanding of key concepts, your managerial judgment, and your ability to apply course concepts to real business problems. Based on Globsyn’s triad of Innovation, Research and Technology, this programme is intended to help students gain skills to excel in the fast paced business world by learning how to leverage data and analytics to make better business decisions.</p>
                                    <p>Geared to help students keep pace with changing market dynamics and make better strategic business decisions informed by real metrics and insights, this programme is amplified with the finest business analyst training, ready access to up-to-date, high-quality data and analysis, which will shape them into ‘industry relevant managers’ of the global knowledge economy.</p>
                                    <p><strong>Duration:</strong> 2 years Full Time Programme</p>
                                    <h4>Program Educational Objectives</h4>
                                    <ul className='disc'>
                                        <li>To provide graduates with a comprehensive knowledge of business analytics and its application in the diverse contexts of business</li>
                                        <li>To produce management graduates who can solve business problems in real-world situations by applying analytical skills to make effective decisions</li>
                                        <li>To produce responsible managers who have high regard for personal and organizational integrity in pursuit of sustainable development</li>
                                        <li>To enable graduates to demonstrate critical thinking, creativity, and innovation to emerge as business leaders and entrepreneurs in the analytical domain</li>
                                        <li>To equip students with effective communication skills, team spirit, and social values to meet the diversified needs of the industry</li>
                                    </ul>
                                    <strong style={{ fontSize: '20px' }}>Structure</strong>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td><strong>Foundation Programme</strong></td>
                                                <td>Coverage: Academic Sessions, Non-Academic Sessions, Corporate 
                                                    <br />Connects, Employability Enhancement Sessions, Basic English, Basic Maths
                                                    <br/>Mode: Blended Mode – Online and Face to Face
                                                    </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Core Courses</strong></td>
                                                <td>Coverage: Python, R, Data Management, Cloud Computing, Artificial Intelligence, Natural Language Processing, Machine Learning, Cyber Security, Economics, Quantitative Techniques, Operations, Business Research Methods, Finance, Marketing, HR, etc.
                                                    <br />Mode: Mixed Mode – Lectures supplemented by Online Learning, Live on-site projects, Experiential Learning and Case Studies</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Schools of Excellence</strong></td>
                                                <td>Specialization I: Business Analytics
                                                    <br />Specialization II: Finance/Marketing/HR/Operations Management Mode: Mixed Mode – Lectures supplemented by Online Learning and Case Studies</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <strong style={{ fontSize: '20px' }}>Curriculum</strong>
                                    <h4>Semester-I</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Sl. No.</strong></td>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Link</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>1</strong></td>
                                                <td>Financial & Management Accounting</td>
                                            </tr>
                                            <tr>
                                                <td><strong>2</strong></td>
                                                <td>Managerial Economics</td>
                                            </tr>
                                            <tr>
                                                <td><strong>3</strong></td>
                                                <td>Quantitative Methods - I (Applied Statistics)</td>
                                            </tr>
                                            <tr>
                                                <td><strong>4</strong></td>
                                                <td>Organisation Behaviour and Human Resource Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>5</strong></td>
                                                <td>Marketing Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>6</strong></td>
                                                <td>Business Communication</td>
                                            </tr>
                                            <tr>
                                                <td><strong>7</strong></td>
                                                <td>Excel for Business</td>
                                            </tr>
                                            <tr>
                                                <td><strong>8</strong></td>
                                                <td>Programming with R</td>
                                            </tr>
                                            <tr>
                                                <td><strong>9</strong></td>
                                                <td>Programming with Python</td>
                                            </tr>
                                            <tr>
                                                <td><strong>10</strong></td>
                                                <td>RDBMS and Datawarehouse</td>
                                            </tr>
                                            <tr>
                                                <td><strong>11</strong></td>
                                                <td>Professional Skills Development - I</td>
                                            </tr>
                                            <tr>
                                                <td><strong>12</strong></td>
                                                <td>Basic English - I</td>
                                            </tr>
                                            <tr>
                                                <td><strong>13</strong></td>
                                                <td>Basic Maths - I</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h4>Semester-II</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Sl. No.</strong></td>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Link</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>1</strong></td>
                                                <td>Financial Management and Corporate Finance</td>
                                            </tr>
                                            <tr>
                                                <td><strong>2</strong></td>
                                                <td>Quantitative Methods - II (Operations Research)</td>
                                            </tr>
                                            <tr>
                                                <td><strong>3</strong></td>
                                                <td>Global Business Strategy</td>
                                            </tr>
                                            <tr>
                                                <td><strong>4</strong></td>
                                                <td>Operations Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>5</strong></td>
                                                <td>Spreadsheet Modelling with Excel</td>
                                            </tr>
                                            <tr>
                                                <td><strong>6</strong></td>
                                                <td>Machine Learning - I</td>
                                            </tr>
                                            <tr>
                                                <td><strong>7</strong></td>
                                                <td>Big Data Management</td>
                                            </tr>
                                            <tr>
                                                <td><strong>8</strong></td>
                                                <td>Market Research</td>
                                            </tr>
                                            <tr>
                                                <td><strong>9</strong></td>
                                                <td>MIS and ERP</td>
                                            </tr>
                                            <tr>
                                                <td><strong>10</strong></td>
                                                <td>Data Visualisation</td>
                                            </tr>
                                            <tr>
                                                <td><strong>11</strong></td>
                                                <td>Cloud Technology</td>
                                            </tr>
                                            <tr>
                                                <td><strong>12</strong></td>
                                                <td>Digital Transformation and Strategy</td>
                                            </tr>
                                            <tr>
                                                <td><strong>13</strong></td>
                                                <td>Professional Skills Development - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>14</strong></td>
                                                <td>Basic English - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>15</strong></td>
                                                <td>Basic Maths - II</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h4>Semester-III</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Sl. No.</strong></td>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Link</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>1</strong></td>
                                                <td>Artificial Intelligence and Deep Learning </td>
                                            </tr>
                                            <tr>
                                                <td><strong>2</strong></td>
                                                <td>Natural Language Processing (NLP)</td>
                                            </tr>
                                            <tr>
                                                <td><strong>3</strong></td>
                                                <td>Machine Learning - II</td>
                                            </tr>
                                            <tr>
                                                <td><strong>4</strong></td>
                                                <td>Data Engineering</td>
                                            </tr>
                                            <tr>
                                                <td><strong>5</strong></td>
                                                <td>Cybersecurity and Cyberlaw</td>
                                            </tr>
                                            <tr>
                                                <td><strong>6</strong></td>
                                                <td>Professional Skills Development - III</td>
                                            </tr>
                                            <tr>
                                                <td><strong>7</strong></td>
                                                <td>Basic English - III</td>
                                            </tr>
                                            <tr>
                                                <td><strong>8</strong></td>
                                                <td>Basic Maths - III</td>
                                            </tr>
                                            <tr>
                                                <td><strong>9</strong></td>
                                                <td>Electives from School of Business Analytics (Major)</td>
                                            </tr>
                                            <tr>
                                                <td><strong>10</strong></td>
                                                <td>Electives from School of Management (Minor)</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h4>Semester-IV</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Sl. No.</strong></td>
                                                <td style={{ backgroundColor: "#d9edf7" }}><strong>Link</strong></td>
                                            </tr>
                                            <tr>
                                                <td><strong>1</strong></td>
                                                <td>Business Ethics & Corporate Governance</td>
                                            </tr>
                                            <tr>
                                                <td><strong>2</strong></td>
                                                <td>Data Governance and Data Ethics</td>
                                            </tr>
                                            <tr>
                                                <td><strong>3</strong></td>
                                                <td>Blockchain and Industry 4.0</td>
                                            </tr>
                                            <tr>
                                                <td><strong>4</strong></td>
                                                <td>Agile Project Management for Analytics</td>
                                            </tr>
                                            <tr>
                                                <td><strong>5</strong></td>
                                                <td>Electives from School of Business Analytics (Major)</td>
                                            </tr>
                                            <tr>
                                                <td><strong>6</strong></td>
                                                <td>Electives from School of Management (Minor)</td>
                                            </tr>
                                            <tr>
                                                <td><strong>7</strong></td>
                                                <td>Dissertation</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <strong>Two Credits is allotted to Beyond Education Activities which is spread across all four semesters. </strong>
                                    <br/>
                                    <br/>
                                    <strong style={{ fontSize: '20px' }}>Schools of Excellence</strong>
                                    <ul className='disc'>
                                        <li>School of Business Analytics Excellence</li>
                                        <li>Schools of Management Excellence (Finance/Marketing/HR/Operations Management)</li>
                                    </ul>
                                    <em><span >Please be aware of AICTE’s eligibility criteria, admission policy and refund policy from the AICTE website, before applying for the </span></em>
                                    <em><span >PGDM – Business Analytics programme of Globsyn Business School.</span></em>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
