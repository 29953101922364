import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function InternshipPantaloons() {
    return (
        <><title>My Summer Internship Experience at Pantaloons</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">My Summer Internship Experience at Pantaloons</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">My Summer Internship Experience at Pantaloons</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Summer-Internship-Experience-at-Pantaloons.png" alt="My Summer Internship Experience at Pantaloons" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Shourya Chatterjee, PGDM, Batch 2022-24, Globsyn Business School, writes about his Summer Internship Experience at Aditya Birla Fashion & Retail Limited (Pantaloons) which not only enhanced his knowledge and confidence of working in the corporate world but also provided him the necessary experience, skills and tools needed to face the competitive world.
                                    </em>
                                </p>
                                <p>My name is Shourya Chatterjee and I’m pursuing my PGDM from Globsyn Business School. Currently I am doing my Summer Internship from Aditya Birla Fashion and Retail Ltd. (Brand – Pantaloons) – a subsidiary of Aditya Birla Group, one of the largest conglomerates in India. I was chosen as Finance Intern and I have now completed around 6 weeks of SIP with this organization.</p>
                                <p>Internships play a crucial role in a student’s transition from the academic environment to the professional world. They offer valuable opportunities to gain practical experience, develop new skills, and understand the dynamics of the corporate world. Entering the corporate world as an intern can be both exciting and nerve-wracking. It marks the beginning of a new chapter in your professional journey and allows you to apply the theoretical knowledge you have acquired in a real-world setting. There might be a sense of anticipation and eagerness to prove yourself while also feeling a bit overwhelmed by the unknown. During the internship, it is crucial to maintain a positive attitude, be proactive, and demonstrate a willingness to learn. Take the initiative to engage with your colleagues, ask questions, and seek feedback on your work.</p>
                                <h3>First Touch of the Corporate World</h3>
                                <p>Now, on my first day, I was both anxious and excited. My company mentor reminded me on the first day that internships are learning opportunities and that making errors is acceptable as long as you are willing to learn from them. He said something that energised me. Through orientations, team-building exercises, and interactive sessions, I was introduced to the company’s principles and culture. I instantly fit in with the rest of the team and felt valued by them.</p>
                                <p style={{ textAlign: "center" }}>
                                    <img className="img-fluid" src="/assets/images/At-the-Cash-Till-Management-section-of-Pantaloons.png" alt="At-the-Cash-Till-Management-section-of-Pantaloons" />
                                    <br />
                                    <em>At the Cash Till Management section of Pantaloons, Camac Street</em>
                                </p>
                                <h3>Experience at Workplace</h3>
                                <p>I was given the responsibility of performing audits at several Pantaloons stores, where I was able to put my skills to use and grow, giving me exposure to the corporate world. To carry out the work of the commercial audit, we have been separated into different groups. Before sending us for the audit, we received a thorough briefing. For the purpose of auditing, I was given access to three distinct stores: Camac Street, Baruipur, and fortunately South City, which is the largest Pantaloons store in the eastern zone from the standpoint of operational business. Working with experienced managers like the store manager, head cashier, and departmental managers is a true delight. Their expertise aids in the development of my practical understanding, and their corporation makes it simple and straightforward to carry out the audit.</p>
                                <p style={{ textAlign: "center" }}>
                                    <img className="img-fluid" src="/assets/images/At-the-Customer-Dealing-and-Billing-section-of-Pantaloons.png" alt="At-the-Customer-Dealing-and-Billing-section-of-Pantaloons" />
                                    <br />
                                    <em>At the Customer Dealing and Billing section of Pantaloons, Camac Street</em>
                                </p>
                                <p>They help me comprehend how businesses have operated and how Pantaloons conducts its operations. As a finance intern, it is necessary to comprehend how an organisation does business and records commercial transactions. With the assistance of my company mentor and other seniors, this is a task happened quite easily. I found this to be inspiring because it aided in my goal of being a key member of the team. I’ve always tried to emphasise and have professional interests. So far, this internship has pointed me in the right path in terms of what I want to accomplish with my life.</p>
                                <p style={{ textAlign: "center" }}>
                                    <img className="img-fluid" src="/assets/images/During-Board-Meeting-Audit-Report-Submission-at-Zonal-Office-of-Aditya-Birla-Fashion-Retail-Limited.png" alt="During-Board-Meeting-Audit-Report-Submission-at-Zonal-Office-of-Aditya-Birla-Fashion-Retail-Limited" />
                                    <br />
                                    <em>During Board Meeting & Audit Report Submission at Zonal Office of Pantaloons</em>
                                </p>
                                <h3>Benefits of an Internship</h3>
                                <p>My understanding and confidence have increased as a result of this internship, which is essential for interacting in corporate world. After my internship, I can say that I have gained knowledge and increased confidence in my ability to reach my objectives. With no obligations in the real world, this internship is a fantastic way for me to evaluate my abilities. It has provided me with experiences, skills, and the tools I need to acquire a full-time in a career in my field later on.</p>
                                <p style={{ textAlign: "center" }}>
                                    <img className="img-fluid" src="/assets/images/With-Commercial-Head-and-Assistant-Cashier.png" alt="During-Board-Meeting-Audit-Report-Submission-at-Zonal-Office-of-Aditya-Birla-Fashion-Retail-Limited" />
                                    <br />
                                    <em>With (From Left) Mr Shams Tabrez, Commercial Head, and Mr Krishanu Routh, Assistant Cashier, Pantaloons, Camac Street</em>
                                </p>
                                <h3>Conclusion</h3>
                                <p>
                                    Gathering information is one of the most significant things I have learned during my internship. This involves honing the skills I already possess and learning new ones that are relevant to my ideal of the career path I want to pursue.
                                </p>
                                <p>I am really thankful to Globsyn Business School, CRP Team specially Banti Biswas, CRP Head and Aditya Birla Fashion and Retail Ltd. (Brand – Pantaloons) for providing me with this opportunity to work with them, as well as for assisting me in learning so many new things and for inspiring me to enter this new and unknown world. I am also grateful to my mentor, Prof. Manas Chakraborty, as well as my Company Mentor, Mr Vishal Jaiswal and my office supervisor Mr Shams Tabrez, for their continuous support and assistance.</p>
                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Shourya-Chatterjee.png"
                                            alt="Shourya-Chatterjee"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Shourya Chatterjee
                                            <br />
                                            PGPM-IB
                                            <br />
                                            Batch 2022-24
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
