import React from 'react'
import CountUp from 'react-countup';
import { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";

export default function Numcounter() {

    const [counterOn,setcounterOn]= useState(false);

  return (
    <ScrollTrigger onEnter={()=> setcounterOn(true)} onExit={()=>setcounterOn(false)}>
    <div className="counter-area bg-primary-deep">
          <div className="container">
            <div className="row">  
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 counter1-box wow " >
                <h2 className="about-counter title-bar-counter" style={{fontWeight:"normal"}}>{counterOn && <CountUp start={0} end={360} duration={.40} delay={0} />}</h2>
                <p>Corporate Relations</p>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 counter1-box wow " >
                <h2 className="about-counter title-bar-counter" style={{fontWeight:"normal"}} >{counterOn && <CountUp start={0} end={3507} duration={.40} delay={0} />}</h2>
                <p>Alumni of the B-School</p>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 counter1-box wow " >
                <h2 className="about-counter title-bar-counter" style={{fontWeight:"normal"}} >{counterOn && <CountUp start={0} end={5448} duration={.40} delay={0} />}</h2>
                <p>Books in Library</p>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 counter1-box wow " >
                <h2 className="about-counter title-bar-counter" style={{fontWeight:"normal"}} >{counterOn && <CountUp start={0} end={105} duration={.40} delay={0} />}</h2>
                <p>Study Papers on Offer</p>
              </div>
            </div>
          </div>
        </div>
        </ScrollTrigger>
  )
}
