import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function GreenInitiatives() {
    return (
        <><title>Green Initiatives – A Way to Brand Building</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Green Initiatives – A Way to Brand Building</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Green Initiatives – A Way to Brand Building</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Green-Initiatives-–-A-Way-to-Brand-Building.png" alt="Green Initiatives" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Dr. Darshana Bhagowati, teaches Marketing at Globsyn Business School – one of the top MBA colleges in Kolkata. Dr. Bhagowati did her MBA from Dibrugarh University, Assam before completing her Ph.D from Burdwan University. She has around a decade of years of experience in both industry and academics. She was earlier associated with various leading management institutions in the country, and has also published several conference papers and journal publications.
                                    </em>
                                </p>
                                <p>The world of marketing had seen a notable transformation with the Green Initiatives taken by the companies of the present day. The term Green Initiatives implies here the effort taken by the company to leverage their brand through environment friendly ways which in turn leads to sustainability. Green marketing is the buzzword of the day and the successful companies are promoting their brands through the Green Initiatives.</p>
                                <p>Marketing is meeting the needs profitability of the customers and the customers of the present day are well informed of the product before they make a purchase. Modern marketing philosophy is customer centric and the customers are health conscious. They prefer products which are environment friendly and marketers strive to find all possible ways to cater to the needs of the customers in a most eco-friendly way.</p>
                                <p>Here are some of the Green Initiatives taken by the companies to build and promote their brands – McDonalds delivers its food in the paper bags which are environment friendly. Westside and Pantaloons deliver its product to the customers in the paper bags which are ways to Green Marketing.</p>
                                <p>There are multiple examples of the big brands, which adopt these green initiatives that make the company ahead of the rest. Using environment friendly paper and inks for printing is a way to Green Initiative. Using recycled materials for packaging a product is another way that companies adopt these days as their marketing practices. The small street vendors are also adopting the Green Initiatives in their own way by using the recycled paper bags.</p>
                                <p>We, as humans, should be responsible enough to save our mother earth in every possible way. Marketing should be meeting the needs of the customers profitably as well as consciously so that every individual can afford the products made of organic elements. Green initiative is indeed a tool to brand building and sustainability.</p>

                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Darshana-Bhagawati.jpg"
                                            alt="tt"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Dr. Darshana Bhagowati
                                            <br />
                                            Assistant Professor – Marketing
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
