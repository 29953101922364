import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../Sidebar'

export default function Finance() {
  return (
    <>
            <title>School of Finance Excellence | Globsyn Business School</title>
            <section className="hero-banner-img-pgdm">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">School of Finance Excellence</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">School of Finance Excellence</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                               
                            </div>
                            <div className="one-table">
                                <div className="one-table1"></div>
                                <div className="one-table2">
                                    <p>The School of Finance Excellence brings to management aspirants a perfect blend of theory and practices over Financial Management, Business Finance, and Accounts as the principal areas of coverage. Today there is an increasing emphasis on globalisation and multinational financial management which have been reflected in the curriculum. While many of the courses are analytical in approach, the finance specialization emphasises on adequate conceptual inputs for a strong foundation, applicability of the concepts, techniques and strategies through project and industry vertical based case studies built around real life problems. One of the primary objectives of the elective is to give the students a feel of ‘how to go about’ in the field of finance and foster an understanding of how financial decisions themselves can create can value for the firm.</p>
                                    <p>The School of Finance Excellence offers the following areas of study:</p>
                                    <ul className='disc'>
                                        <li>Advanced Accounting & Taxation</li>
                                        <li>Banking & Insurance Management</li>
                                        <li>Investment Banking & Financial Services</li>
                                        <li>Risk Management</li>
                                        <li>Global Finance</li>
                                        <li>Pricing</li>
                                        <li>Security Analysis & Portfolio Management</li>
                                        <li>Financial Statement Analysis</li>
                                        <li>Mergers & Acquisitions</li>
                                        <li>Rural Banking & Micro Finance</li>
                                        <li>Behavioural Finance</li>
                                        <li>Treasury Management</li>
                                        <li>Valuation</li>
                                        <li>Dissertation for Finance School of Excellence</li>
                                    </ul>
                                    <p><em>* Not all papers are offered in all courses</em></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
