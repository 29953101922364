import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ManegementDevelopment() {
    return (
        <><title>Governing Council</title>
            <section className="hero-banner-img-pgdm">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Management Development Program</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/technology">Management Development Program</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>

                            </div>
                            <div className="one-table">
                                <p>To cope with an ever-evolving and increasingly competitive world, Globsyn Business School (GBS) believes that the need for updating one’s cognitive and professional skills is equally important for both business managers and management faculty members. Sustaining this belief to fruition, GBS is committed to bring excellence in management practices and promote intellectual capital in all spheres of management sciences through its uniquely designed Management Development Program (MDP) – a self-paced interactive learning program focusing on management and leadership development.</p>
                                <p>Keeping in mind the evolution of the dynamic leadership across the globe, the B-School will be organizing an MDP on <strong>‘Good to Great Managers: A Roadmap for Learning, Leading, and Winning’.</strong> Designed for all levels of managers, corporate leaders, and all those who would like to elevate from being a good to a great manager, this MDP will equip the participants with dynamic leadership techniques to become great leaders in the ever changing VUCA world, as this would help them to survive and thrive in the cut-throat competitive world.</p>
                                <p>Certificates of participation will be provided at the end of the MDP to all the participants.</p>
                                <p><strong>Date:</strong> Saturday, December 10, 2022<br />
                                    <strong>Mode:</strong> Online</p>
                                    <div className="accordion" id="myAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Methodology</button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>The Management Development Program provides:</p>
                                                    <p><strong>Comprehensive Practical Emphasis:</strong> The MDP modules emphasize best practices/principles and the application of knowledge to decision making in everyday practice, so that participants will be able to apply what they learn to their own work situations.</p>
                                                    <p><strong>Hands-on Experience to Participants:</strong> Experience with a variety of formats provides valuable insights into best practices in corporates.</p>
                                                    <p><strong>Peer Networking: </strong>  You will have opportunities to learn with other corporate colleagues addressing some of the same challenges.</p>
                                                    <p><strong>Evaluation and Feedback:</strong> The knowledge and skills that participants develop by going through this program are evaluated through live projects, reports, and online activities.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingtwo">
                                                <button type="button" className="accordion-button collapsed " data-bs-toggle="collapse" data-bs-target="#collapsetwo">Educational Approach</button>
                                            </h2>
                                            <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>With an approach of ‘learn and lead by doing’, this MDP will enable the participants to develop a positive mind-set, appreciate the managers’ role in the learning process, and realise their own potential to become great managers. Engaging in deep reflection on the MDP’s theme, they will also be expected to take the role of facilitators in order to gain insights into the facilitation process. The pedagogy involved in this MDP constitutes of informative lectures and experiential learning sessions like case studies, role plays, and simulation activities, where participants will be encouraged to engage interactively and contribute to the learning process.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingthree">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Structure and Flow</button>
                                            </h2>
                                            <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Participants will have opportunities to develop skills related to self-management, management of others and business management. Given below are the details of the sessions:</p>
                                                    <h4>Structure</h4>
                                                    <table className="table table-striped table-hover">
                                                        <tbody>
                                                            <tr style={{ backgroundColor: "#bcbcbc8c" }}>
                                                                <th>Session</th>
                                                                <th>Topic</th>
                                                                <th>Expert</th>
                                                                <th>Time</th>
                                                            </tr>
                                                            <tr>
                                                                <td>I</td>
                                                                <td>The Journey from Learning to Leading</td>
                                                                <td>Dr. Chandrima Banerjee Director
                                                                    Ungrow Solutions</td>
                                                                <td>10 am – 11.30 am</td>
                                                            </tr>
                                                            <tr>
                                                                <td>II</td>
                                                                <td>Transformational Leadership</td>
                                                                <td>Dr. Vidyanand Jha
                                                                    Faculty – HR & OB
                                                                    IIM Calcutta</td>
                                                                <td>11.30 am – 1 pm</td>
                                                            </tr>
                                                            <tr style={{ backgroundColor: "#bcbcbc8c", textAlign: "center" }}>
                                                                <td colSpan={"3"}>Lunch Break</td>
                                                                <td>1 pm – 2 pm</td>
                                                            </tr>
                                                            <tr>
                                                                <td>III</td>
                                                                <td>Good to Great Management Practices</td>
                                                                <td>Mr. Pawan Marda
                                                                    Company Secretary
                                                                    Linde India</td>
                                                                <td>2 pm – 3.30 pm</td>
                                                            </tr>
                                                            <tr>
                                                                <td>IV</td>
                                                                <td>Presentation on Effective Managerial Skills</td>
                                                                <td>Dr. M.P. Singh
                                                                    Principal
                                                                    Globsyn Business School</td>
                                                                <td>3.30 pm – 4.30 pm</td>
                                                            </tr>
                                                            <tr style={{ backgroundColor: "#bcbcbc8c", textAlign: "center" }}>
                                                                <td colSpan={"3"}>Q & A Session</td>
                                                                <td>4.30 pm – 4.45 pm</td>
                                                            </tr>
                                                            <tr style={{ backgroundColor: "#bcbcbc8c", textAlign: "center" }}>
                                                                <td colSpan={"3"}>Post-MDP Reflections</td>
                                                                <td>4.45 pm – 5 pm </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingfour">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefour">Registration and Payment Details</button>
                                            </h2>
                                            <div id="collapsefour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p><strong>Registration Fees: Rs. 1500 only (inclusive of GST)</strong><br />
                                                        The Registration Form needs to be filled by all participants
                                                        <br />
                                                        Payment to be made Online/ IMPS / NEFT / Phone Pe / Google Pay / any other online mode</p>
                                                    <p><strong>Payment Details</strong>
                                                        <br />Name of Account Holder: Globsyn Knowledge Foundation
                                                        <br />Bank Name: HDFC Bank Ltd.
                                                        <br />IFSC Code: HDFC0000516
                                                        <br />A/C No: 50200033157465</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingfive">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefive">Registration Form</button>
                                            </h2>
                                            <div id="collapsefive" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingsix">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsesix">Correspondence Details</button>
                                            </h2>
                                            <div id="collapsesix" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p><strong>Dr. M. P. Singh</strong>
                                                        <br/>Principal
                                                        <br/>Globsyn Business School
                                                        <br/> Mobile: +91 99509 28476
                                                        <br/>Email: principal@globsyn.edu.in</p>
                                                <p><strong>Note:</strong> All communications regarding the MDP must be addressed only to Dr. M. P. Singh.</p>
                                                </div>
                                            </div>
                                        </div>
                        
                                    </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
