import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function CostOfCommuting() {
  return (
    <><title>The Cost of Commuting to Work</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">The Cost of Commuting to Work</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog"> Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to=""> The Cost of Commuting to Work</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>

                    <div className="one-table">
                        <p>
                            <img className="img-fluid" src="/assets/images/Commuting_Blog.jpg" alt="Commuting_Blog" />
                        </p>
                        <div className="entry-header">
                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>In today’s high paced world, the aspect of commuting plays a very important role in job searches and career selections. The time taken and the money involved in commuting to work, plays a significant role in deciding whether a job is desirable or not. Many people while looking to buy a house prefers to live within five kilometers of their workplace, as they do not want to spend more money or time on commuting than is absolutely necessary.</p>
                        <p>According to a survey, majority of the work force commute to work, by car, bus, metro or the local train. So how do they really determine the cost of their commute? Some of the things that are to be taken into consideration while determining the cost of commute are as follows:</p>
                        <p><b>1. The Cost of Driving a Vehicle</b></p>
                        <p>Many factors have to be taken into consideration while determining the cost of driving a vehicle to work, such as:
                        <ul className='disc'>
                            <li>Depreciation</li>
                            <li>Insurance</li>
                            <li>Registration</li>
                            <li>Cost of Petrol</li>
                            <li>Maintenance and Repairs</li>
                            <li>The monthly EMI payments, if someone has one</li>
                        </ul>
                        </p>
                        <p>How do we derive the cost of driving a vehicle from the above factors? This is done by adding up the annual costs in all the above categories and then divided by the number of kilometers driven in a particular year. This will give an estimated cost per kilometer. The cost of commuting becomes even more expensive when one has to pay to park his vehicle at work. In this case, the annual cost of parking is added up and then divided by the number of kilometers driven to work. This resultant extra expense is then added to the estimated cost per kilometer.</p>
                        <p><b>2) Time Saved is Money Saved.</b></p>
                        <p>Apart from the rising expenditures that it takes to operate a vehicle, another factor to remember is that time is money. Every minute that one spends driving to and from work, is the time he could have spent doing a number of other constructive and productive things in life. Another frustrating factor while driving to work is that a distance which takes a certain amount of time to cover, takes almost its double time during rush hour traffic.</p>
                        <p><b>3) Riding a Bi-cycle to work can actually make a person a Millionaire!</b></p>
                        <p>These days, professionals living closer to their workplaces prefer using alternative means of transport, like walking or cycling, in an effort to stay fit and save up on fuel expenses.</p>
                        <p>Using the ‘Time Value of Money Calculator’ it is seen that the money saved in commuting to work, if invested for 35 years at an interest of 7 percent per annum, can grow to over a crore of rupees, even if adjusted for inflation.</p>
                        <p>
                        So it can be safely assumed that walking and cycling can not only be a fun way of staying fit, but also a highly cost effective method of daily commuting to one’s workplace. It is thus advantageous to drive less and enjoy the walk to office and use fun activities like listening to music or news during the journey.
                        <br/>
                        Happy Journey!!!
                        </p>
                        <p>Ayan Ghosh
                            <br/>
                            Knowledge Cell
                        </p>
                        
                    </div>

                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
