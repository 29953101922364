import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function StatisticsTheGrammar() {
    return (
        <><title>Statistics: The Grammar of Science</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Statistics: The Grammar of Science</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Statistics: The Grammar of Science</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Statistics-The-Grammar-of-Science.jpg" alt="Statistics: The Grammar of Science" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Dr. Swapna Datta Khan, teaches Marketing at Globsyn Business School – one of the best B-Schools in Kolkata. Dr. Khan has been passionate about teaching and grooming young management professionals for more than 16 years. She has taught various courses related to Decision Sciences and Marketing Management at various leading institutions across the country. She has had a research experience of over 14 years, with publications in international journals and presentations at international conferences, besides having edited a research journal, been resource person at conferences and reviewer of research papers. She has qualified the UGC NET Lectureship in Mathematics. She has also served in the Indian Army as a part of the 1st course of non-medical lady officers.
                                    </em>
                                </p>
                                <h4>Below is a light-hearted attempt at stimulating an imaginary conversation between Harry ‘Poddar’ and Hermoine ‘Ganguli’ about the nuances of Statistics as the grammar of Science.</h4>
                                <p>Harry Poddar wanted to know if he had to acquaint himself with statistics. Knowing that he was not a math enthusiast, he decided to take some advice from his dear friend, Hermione Ganguli. The conversation went as follows:</p>
                                <h4>Harry: What, really, is Statistics?</h4>
                                <p>Hermione: Statistics is, basically, the study of data. It is to decision-making what pathology is to medicine; it provides the body of content that may drive a workable choice. The statistician assimilates, organizes, and analyses data to offer interpretations that could affect a crucial pronouncement.</p>
                                <p>Descriptive Statistics enable the summarization of data, with descriptors such as mean and standard deviation to pronounce central tendency and dispersion and frequency to describe categorical data.</p>
                                <p>So, if we need to use data in a study to throw insights, we would take a sample that adequately represents the population and use the sample statistic to recognize numerical characteristics of the data (Estimation), discover associations between data sets (Correlation), check if assumptions are justified (Testing of Hypothesis) or decipher which singularity gets affected by which happening (Regression).</p>
                                <h4>Harry: How recent is Statistics, as a science?</h4>
                                <p>Hermione: Al-Khalil (717 – 786) wrote a text that contained combinatorial computations to list words with and without vowels. The concept of the use of adequate sample size in inferential statistics was initiated in the late 12th century. Further, in the 17th century, Blaise Pascal and Pierre de Fermat made a considerable contribution to probability theory. Sir Ronald Fischer is one of the founders of modern statistics. Now statistics are integrated into a myriad of fields such as agriculture, medicine, management of business organizations, political science, and medicine.</p>
                                <h4>Harry: Is statistics in any way connected to the daily life of the common man?</h4>
                                <p>Hermione: The analysis of molecular marker loci predicts breeding values that enable genome selection in agriculture and animal husbandry. When we use our smartphones, often the text is prompted by the phone, using an inbuilt system of statistical analysis of the text we voluntarily input and Markov analysis to predict the text that we may need to insert. The news is full of statistics depicting the devastation caused by the spread of the SARS Covid-19 virus and the aware man does his best to protect himself and his community. In sports, statistics are often used to rank athletes and create a hierarchy. The Life Insurer computes the rates of return on his insurance products based on Actuarial Statistics.</p>
                                <h4>Harry: My friend is the Marketing Manager of a well-known organization. He says that he uses statistics to power his decision-making. Could you explain?</h4>
                                <p>Hermione: Marketing is the art of making the customer realize that he does, indeed, need your product/ service. How you would do this is to understand how he thinks, articulates and purchases. There are closed-circuit cameras in every retail format, mostly installed to enhance security. The output of such cameras is vast and variable and such data is known as Big Data, due to its mammoth size. However, specialized data management tools (such as the Apache Hadoop) could churn the data to sediment workable inputs that drive analytics on customer behavior and buying patterns.</p>
                                <p>Companies are known to invest in a variety of channels (especially, the digital kind) to communicate to their customers and prospective customers. Statistical analysis would throw light on the Return-of-Investment in such kinds of marketing communications and could justifiably improve the manager’s choice of channels. When you use social media, data-driven Attribution Modelling computes the contribution of each search-and-click in the clickstream.</p>
                                <p>A business manager introducing a new product or striving for the entry of a part of his product-mix in a new market would do so only after assessing the market potential using insights gained from Market Research. Likewise, he would also use Marketing Mix Modelling to strategize his marketing processes effectively.</p>
                                <p>Customer data is used to create customer segments (meaningful cohorts with similar needs) using Cluster Analysis to target offerings. In a demographically diverse populace, this enables them to effectively position products and services. Product appraisals with statistical data appeal more to the aware customer than rhetoric.</p>
                                <p>Customer feedback is used to determine satisfaction levels and thus estimate the quality of service/ product and improve customer engagement. Often, a company leader invests in robotics to find out how a solution gives profits, a growing clientele, and productivity.</p>
                                <p>Suppose you would like to impress upon the public (including your customers) that you prioritize sustainability and environmental wellbeing, statistics would speak louder than mere statements.</p>
                                <h4>Harry: Suppose I have to manage the Human Resources Department in my company, will I need any application of statistics?</h4>
                                <p>Hermione: Effective analytics could predict attrition and help understand employee turnover better. Absenteeism records can throw light on employee engagement levels; statistics would enable appropriate benchmarking, performance assessment, and link employee compensation to overall business performance. Besides, companies assess recruitment and retention data to identify and groom talent.</p>
                                <h4>Harry: Accountancy is my brother’s forte. Why would he require the knowledge of statistics?</h4>
                                <p>Hermione: The use of statistics as a discipline is widely prevalent in the Finance Sector. The analysis of risk associated with the stock market (the prediction of the performance of a stock is based on a statistical analysis of its historical data) and the understanding of savings instruments such as Mutual Funds needs in-depth knowledge of statistics. The measure of economic growth of a nation and at the organizational level, the benchmarking of earnings in business are both based on statistical analysis. In a nutshell, when it comes to Finance, statistics are everywhere!</p>
                                <h4>Harry: So, would I need a primer on statistics to be an efficient manager?</h4>
                                <p>Hermione: The knowledge of statistics is a mandatory part of management education. At Globsyn Business School, the subject is gently introduced and its fundamentals explained so that they can serve as adequate guidance for all budding managers and their subsequent evolution into business professionals. After all, Karl Pearson said that “statistics is the grammar of science.” and science is certainly not magic!</p>



                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Prof-Dr-Swapna-Dutta-Khan.png"
                                            alt="tt"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Dr. Swapna Datta Khan
                                            <br />
                                            Faculty – Human Resource Management
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
