import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'


export default function FinancialManagement() {
    return (

        <><title>Financial & Management Accounting: The Road Ahead</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Financial & Management Accounting: The Road Ahead</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Financial & Management Accounting: The Road Ahead</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/blog-image_17_11_2020.png" alt="Financial & Management " />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>In Globsyn Business School (GBS), ‘Financial & Management Accounting’ is a very important foundation course taught in Semester I in the 2 years MBA programme. It is usually followed by a course on ‘Corporate Finance’ in Semester II which provides a framework to acquire working knowledge in the world of Finance. Further, students who wish to Major in Finance, have the option to choose from a host of state of the art electives in Semester III and IV which ranges from Investments, Working Capital Management, Risk Management, Mergers & Acquisitions, Valuations, Banking & Insurance, Financial Statement Analysis, etc. that exposes students to become an expert in the world of Finance. Students are also groomed to make a career in the world of Corporate Finance, Audit & Taxation with one of the Big 4 Accounting firms, as a Business Analyst with a Mutual Fund or as an Investment Banker in the years to come through real life simulations using financial modelling in MS Excel and case studies in the various courses they undergo.</p>
                                <p>In modern terms, Accounting is defined as the process of financial information in a systematic manner based on certain established principles known as GAAP (Generally Accepted Accounting Principles) with the objective of communicating the financial health of the organization to relevant stakeholders periodically. Over the years, Accounting as a subject of knowledge has undergone a wide scale of changes and today it is not looked upon just as a course on understanding cash inflows and outflows in isolation, but as a major interface in management decision making that holistically enables a manager to integrate different functional areas of management which include HR, Marketing, Operations & Systems, and not only limited to the General Management process.</p>
                                <p>The course starts with focusing on the basic Accounting equation ‘Capital + Liabilities = Assets’ followed by a detailed understanding of US GAAP (Generally Accepted Accounting Principles) and also enables students with a basic knowledge of the ‘Accounting Standards’ (AS) as prescribed by the ‘Institute of Chartered Accountants of India’ (ICAI). The course enables students to prepare and interpret financial statements of companies from different sectors also leading to some advanced concepts in Management Accounting like marginal costing, absorption costing, cost sheet, etc. As an instructor of the course, I am confident that students who successfully undergo this course will have a fair understanding of the Balance Sheet of a company and its interpretation. For the current batch of 2020-22, the course is considered as one of the 3 valuable courses offered by Harvard Business School Online CORe Programme as a part of the structured academic delivery which brings a world-class business academic delivery in GBS.</p>

                                <p>
                                    <b>Prof. (Dr.) Subir Sen</b>
                                    <br />Faculty, Finance<br />
                                    Globsyn Business School
                                </p>


                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
