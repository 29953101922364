import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function TipsTricksSailing() {
  return (
    <><title>Tips and Tricks for Sailing Through Online Interviews</title>
      <section className="hero-banner-img-blogs">
        <div className="container">
          <div className="entry-banner-content">
            <h1 className="entry-title">Tips and Tricks for Sailing Through Online Interviews</h1>
            <div className="d-flex">
              <div><Link className="text-yellow" to="/">Globsyn</Link></div>
              <div><span className="text-light">-</span></div>
              <div><Link className="text-light" to="/blog">Blogs</Link></div>
              <div><span className="text-light">-</span></div>
              <div><Link className="text-light" to="">Tips and Tricks for Sailing Through Online Interviews</Link></div>
            </div>
          </div>
        </div>
      </section>
      <section className='mt-8'>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-9 col-12">
              <div>
              </div>
              <div className="one-table">

                <p>
                  <img className="img-fluid" src="/assets/images/img_11.png" alt="Tips and Tricks for Sailing Through Online Interviews" />
                </p>
                <div className="entry-header">
                  <div className="entry-meta bar1 clearfix">
                    <ul>
                      <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                      <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                      <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                      <li>
                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <p>Amid the Covid-19 pandemic and lockdown, the industrial machinery has been brought to a standstill in its entirety – and the recruitment industry is no exception. Consistent with the current situation, most of the companies have frozen the hiring process altogether. Still a silver lining can be seen in these dark clouds, when many companies out there are recruiting new talent in a bid to prevent an economic slowdown.</p>
                <p>Needless to say, the option of conducting face-to-face interviews is off the table. To counter this problem, the companies have resorted to conducting online job interviews for interested candidates. By utilizing technologies like video conferencing that have long existed, but less frequently used in the hiring process, the recruiters are able to hire talent whilst saving time and money – both for the employer and the candidate.</p>
                <p>Like the globe, the internet is also warming up; contemporary online behaviour is more communicative, the ever evolving cyber space does not imitate real world, but rather creates a rapid, new immediate multi layered world.</p>
                <p>As for the online interview, there are 2 main types –</p>
                <ol style={{ listStyle: "decimel" }}>
                  <li>Synchronous online interviewing</li>
                  <li>Asynchronous online interviewing</li>
                </ol>
                <p>Synchronous Online interviews resemble mostly the traditional interview as they are conducted in real time. In this type, the interview takes place in internet chat room or conferencing software where all participants, the interviewers and interviewee, are online at the same time, the questions and answers are asked and heard in real time.</p>
                <p>Asynchronous interviews, on the other hand, do not take place in real time. These interviews use emails; discussion boards or an automatic chat bot. It usually involves the interviewer sending the questions to the respondents through any of the medium, and the respondents answer at their own convenience, or within the stipulated time given in the software for the same. Here, neither party needs to be online at the same time.</p>
                <p>Online Interview is almost always a video Interview, in a synchronous interview, both one-to-one or panel interview can take place over the internet.</p>
                <p>In One-to-one interview, there is only one interviewer, and the process starts after the candidate’s greeting. This almost replicates a traditional interview process, where interviewer assesses the body language, clothing and attitude of the candidate through the video conferencing media. It is better to be prepared and present a good impression at the first time only. So, be professional from the beginning. There will be some general questions and few specific questions related to the job-role one is applying for. It is possible that a candidate may be asked to sit for a number of interviews before the final selection, so it is important to live up to the worth.</p>
                <p>Online Panel Interview will have two or more interviewers at the online meeting. Each of the panellists has his/her own stake and take turns in asking questions. Be sure, to address the specific person and direct your answer to the relevant interviewer. A panel interview is guaranteed to raise a candidate’s stress level, that’s why it is chosen. Here, the company would like to see how a particular candidate can work under stress and react to it. Panel interviews are not intended to foster comfortable and friendly relationships, so be prepared to face the challenge.</p>
                <p>If you are informed about the panel interview at the time of scheduling, be sure to know and ask for the names of the interviewers.</p>
                <p>Do the groundwork properly if you need to give any online presentation; you have to appear relaxed and comfortable, and never be stressed and nervous while appearing for the online interview.</p>
                <p>Online interviews are just important like any other interviews. May be at this hour, you need to impress the interviewer a little more than someone they can see in person. Be sure to do the required homework, so that you are ready and appear confident.</p>
                <p>Tips to keep in mind while facing the camera for the online interview –</p>
                <ul className='disc'>
                  <li>Online interviews take place when you sit in front of the web cam while the interviewer conducts the meeting.</li>
                  <li>Make sure the environment showing behind and around you look professional, business like, clean, free of pets, kids and unnecessary sounds. It is to be treated as any other office interview.</li>
                  <li>Set the stage where you must look relaxed, yet professional. Being on the couch or bean bag won’t help.</li>
                  <li>Have a glass/bottle of water beside you, and make sure your desk looks clean.</li>
                  <li>Check in advance the image of yourself you are going to project, if anything looks wrong, fix it.</li>
                  <li>Don’t have a back-lit video, where your face looks dark; arrange for some light on your face at a proper angle.</li>
                  <li>Dress for an interview, look clean and enthusiastic.</li>
                  <li>Test your internet connection, web cam, sound system, so that you don’t miss any words or questions while answering.</li>
                  <li>Look straight, towards your screen.</li>
                  <li>Turn off any sound, alarm, computer speaker and cell phones.</li>
                </ul>
                <p>Remember to practise well, many people get nervous front of camera, and they either mumble or speak too quickly. It is important to face the camera properly and speak clearly so that the person on the other side can hear and understand you clearly.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
