import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function SocialImpactCyber() {
  return (
    <><title>The Business and Social Impact of Malpractices in Cyber Universe</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">The Business and Social Impact of Malpractices in Cyber Universe</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">The Business and Social Impact of Malpractices in Cyber Universe</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Calcutta Management Association (CMA) introduced a new programme entitled “Knowledge Exchange Series” (KES) in the form of evening lecture sessions, with the basic objective to provide a platform where invited guest speakers will be sharing their learnings, ideas and experiences on a particular theme. However, instead of traditional and often discussed subject matters, the objective of the Series is to bring into focus a few contemporary and “disruptive” issues which are of concern to the present day economic and social life of the country.</p>
                                <p>As a Knowledge Partner to the recently held seminar on ‘The Business and Social Impact of Malpractices in Cyber Universe’ , organised by the CMA as a part of its Knowledge Exchange Series, Globsyn Knowledge Foundation had the wonderful experience of listening to Mr. Sandeep Sengupta, CISA, Certified Ethical Hacker, MD, ISOAH Data Securities Pvt. Ltd. He broke the entire topic in 2 sections – Cyber and its Malpractices.</p>
                                <p>Mr. Sengupta explained the term Cyber which is all about the internet. He discussed the history of internet that started with Advanced Research Projects Agency (ARPA) and the network is known as ARPANET. In 1983, ARPA adopted Transmission Control Protocol (TCP) which is used to access the internet. He also specified the first browser named as MOSAIC that is used as an interface to access the internet and described the various technical details of World Wide Web, and how fibre optic cables laid under the sea is used as a connecting medium of multiple computers.</p>
                                <p>Mr. Sengupta is in the job of developing new protocols that set standards while exchanging information through internet. He explained the term ‘standards’ that include data security while transferring information. Current protocols still have some loopholes which leads the security breach, and Mr. Sengupta and his team are in the process of creating much secured protocols that will stop data breaching.</p>
                                <p>While mentioning the cyber malpractices, he mentioned 3 Cs – Communication, Culture and Creativity. The more these Cs are taken care of while data exchanging the more secured the data will be. He gave an example of ‘A’ in India exchanging message with ‘B’ in India.  When ‘A’ send the message, no wonder the message may travel through various servers located in various countries before it reached ‘B’. Now in this scenario, there must be stringent legal provisions in the said countries that ensures that data is reached from sender to receiver, without being adulterated.</p>
                                <p>In conclusion, Mr. Sengupta urged the management students to think about standardizing the protocols legally so that there are minimum chances of data breaching and work towards it.</p>
                                <br/>
                                <p><strong>Prof. Kaushik Ghosh</strong></p>
                                <p style={{fontSize:"10pt"}}><Link to="/globsyn-information-system-it-specialization">IS School of Intelligence</Link> </p>


                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
