import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function TipsToChase() {
  return (
    <><title>Tips to chase-away those Pre-CAT Blues</title>
    <section className="hero-banner-img-blogs">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">Tips to chase-away those Pre-CAT Blues</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="/blog"> Blogs</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to=""> Tips to chase-away those Pre-CAT Blues</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                    </div>

                    <div className="one-table">
                        <p>
                            <img className="img-fluid" src="/assets/images/Blog-Banner_800x400-1.jpg" alt="" />
                        </p>
                        <div className="entry-header">
                            <div className="entry-meta bar1 clearfix">
                                <ul>
                                    <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                    <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                    <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                    <li>
                                        <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p>CAT (Common Admission Test) is one of the toughest competitive exams in India, which is going to be conducted this year on November 24. This exam not just tests your intellect but your ability to manage your time, speed and accuracy, along with concentration and presence of mind during the exam. Clearing this exam successfully will ensure a smooth pathway towards the gates of the top B-Schools in India and with exam D-Day being just 4 days away, every aspiring MBA student needs to gear up their preparation.</p>
                        <p>Here are a few last minute preparation tips to keep yourself exam-ready:</p>
                        <p>Let us see some notable key aspects of Simulation-based Learning:
                        <ul className='disc'>
                            <li>Keep your Admit Card and ID Card ready beforehand so that you do not face any problem on the last day.</li>
                            <li>Take a look over the entire Exam Pattern and CAT Syllabus to make sure that you have not missed out any topic.</li>
                            <li>Incase you have left out any topic, do not start upon it as by now you only have a few days left and starting on an unknown content will just hamper your time and self-confidence.</li>
                            <li>Dedicate major portions of your preparation time to work on the topics that you feel need more revision.</li>
                            <li>Start revising the syllabus and take timed mock tests consisting of different levels of difficulty. Keep a record of how much time you take while completing the whole test, as well as each section of the mock test.</li>
                            <li>Improve your efficiency and timing by calculating how much time you will be spending on each section during the exam.</li>
                            <li>Revise the formulas for Quantitative Aptitude before solving such questions.</li>
                            <li>If you are not able to solve any question, do not dwell on it for long. Come back to it later, if you have extra time.</li>
                            <li>Make sure you have eaten adequately and have taken a proper 8 hours of sleep before the D-Day so that you can appear for it with a relaxed mind and calm nerves.</li>                           
                        </ul>
                        </p>
                        <p>Now as you gear up with all these last-minute preparation tips, be ready to chase-away those pre-CAT blues and crack the exam with flying colours.</p>
                        <p>Globsyn Business School wishes you All the Best!</p>
                       
                    </div>

                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
