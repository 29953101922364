import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'


export default function FifteenthAugust() {
    return (
        <><title>15th August – “How’s the Josh?”</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">15th August – “How’s the Josh?”</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">15th August – “How’s the Josh?”</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/15-Augest_Blog.jpg" alt="Bridgethegap" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>“One individual may die for an idea; but that idea will, after his death, incarnate itself in a thousand lives. That is how the wheel of evolution moves on and the ideas and dreams of one nation are bequeathed to the next.”</p>
                                <p>– Netaji Subhash Chandra Bose</p>
                                <p>Netaji Subhash Chandra Bose believed that the love his generation had for the country and sacrifices of the freedom fighters for their motherland, will evolve in the minds of the generations to come. However, as per the thoughts of the older generation, does the new generation meet their expectations? Does the new generation, while bringing change in the society, remember the sacrifices of the freedom fighters? Do they think of the country’s welfare, or they only think of their own welfare? Is the passion of patriotism stirred only when our ‘Men in Blue’ plays Pakistan on the 22-yard pitch? These are countless questions, which seek answers.</p>
                                <ul className='disc'>
                                    <li>Inaugural Address</li>
                                    <li>Address on Social Entrepreneurship and TSEC</li>
                                    <li>TSEC Contest Launch</li>
                                    <li>Address by Chief Guest</li>
                                    <li>Panel Discussion on Social Entrepreneurship</li>
                                </ul>
                                <p>At midnight on August 15, 1947, India made her ‘Tryst with Destiny’. We, the people of India, won independence from 200+ years of British regime. Millions of people fought for the country and sacrificed their lives in order to make their motherland and countrymen free from the slavery of the Britishers. The youth of that generation were determined to wake up to an independent India, and lived to realise this dream. Their love for the country had no boundaries, and they wanted the future generations to imbibe this feeling of patriotism. </p>
                                <p>However, today in the minds of the ‘hashtag’ generation, is Independence Day only associated with the hoisting of the Tricolor, re-runs of sappy Bollywood patriotic blockbusters and their forefathers’ stories of the struggle for independence. Unfortunately, for this generation, which has only heard or read tales about the freedom struggles of 1947, Independence Day often passes as just another holiday when they can sleep late or catch up with the latest series on Netflix.</p>
                                <p>Having said that, it is the voice of young India that has started to awaken to the atrocities against women and children, in support of the LGBTQ community, in protest against various types of discriminations, corruptions and extremisms – political, social, religious or economic. They might seem distant or nonchalant, but the new generation are carving their own path for the country’s welfare. They have understood that patriotism not just mean fighting for their motherland, but also to contribute to her welfare and growth. </p>
                                <p>Today, India, with a youth voice that constitutes 50 per cent of India’s billion plus population, has indeed a lot of scope to see young people excel as nation builders, as corporate stalwarts or  entrepreneur geniuses. With leading management and technology institutions, acting as cells for innovation and incubators of young managers, the young minds of India in no matter of time will surpass the boundaries of economic challenges, bureaucratic red-taperies, poverty bottlenecks, fanatical extremisms, political & social setbacks, and other such deterrents. </p>
                                <p>These are the Indians of the 21st Century, who after 72 years of Independence, will still have the zeal to make India proud. Yes, the youth of India, has a lot of learning to do, a lot of growing up to do, a lot more wars to fight for – wars that are not as glorious as the one their grandparents fought some 73 years back. Yet, they are important wars all the same; and today’s youth is fighting them with pens, microphones and social media. </p>
                                <p>And this war is fueled with a dream, a vision for a better India and there is a maddening hunger to see it come true. The ‘josh’ among young Indians will always be high!</p>
                                <p>Jai Hind!</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
