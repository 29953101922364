import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function GipPartThree() {
  return (
    <><title>A GBS Experience through Global Immersion Program (Part 3)</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">A GBS Experience through Global Immersion Program (Part 3)</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog"> Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> A GBS Experience through Global Immersion Program (Part 3)</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Copy-of-Untitled-Design-2.png" alt="" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p><em>This blog is the third and final part of the ‘A GBS Experience through Global Immersion Program’ series, where a group of GBS students, pursuing Global MBA </em> – International Business, visited Malaysia as a part of their Global Immersion Program. In our last blog from this series, we informed the readers about our industrial visit, classroom sessions and local sightseeing. In today’s blog, we will discuss our other industrial visits and remaining classroom sessions along with the lessons we have learned from this trip.</p>
                                <p>On the last day of the week, we assembled at the University at sharp 9 am. With our souls filled with energy, we attended our last lecture with Mr. Premkumar Rajagopal on Ethical Leadership. In the session, Mr. Rajagopal taught us about ethics, lacking which no organization can sustain longer. The lesson also included various kinds of ethics, which different leaders adopted in divergent situations. Further, we were given a leadership assessment to which we were told to provide responses for the given situations. The assessment helped us gain insight into our own leadership qualities, how ready we are to be a good leader. Then he talked about Level 5 Leadership and mentioned 6 keys to build it. In reference to Level 5 Leadership, Mr. Rajagopal recommended us to read two books namely, ‘Good to Great’ by Jim Collins and ‘Level 5 Leadership’ by John Maxwell. He also mentioned that knowledge received through books, alone, is not enough but what actually leads us in life is the knowledge gained through various experiences. And with this note, he discussed some real-life ethical decisions from his personal experiences. Followed by these lessons, we were told to solve a case study based on leadership.</p>
                                <p>Post lunch, we assembled in our classroom at 2 pm. All the groups were ready with their presentations, which started at 3 pm with different groups giving their insights on the case study. When students finished their presentations, Mr. Rajagopal shared his insight on the case study. After the presentation, the class ended with a small Q&A session. That evening as we came across different cultures of Malaysia, we visited the Time Square and enjoyed the views of the beautiful roads covered with bright lights.</p>
                                <p>The second week of our educational tour with Dr. David Bobker, who asked us to write our names on a paper and told us to place them on the desk so that he could interact with us individually. Further, he gave us a task to present in groups. The task was to prepare a case study consisting of five good and bad qualities of leaders along with real-life examples. With the explanation consisting of the differences between leaders and managers, he further introduced to us the Blake-Mouton Managerial Grid, which is a two-dimensional grid that focuses on people and performances. Post lunch break, he explained the various components of the grid, i.e., Impoverished Management, Middle of the Road Management, Country Club Management, Team Management and Produce or Perish Management. Following the lesson, he gave us the assignment for the next class – to prepare a case study on the decision-making process of managers.</p>
                                <p>It was the day of our second industrial visit to Shah Alam – the state capital of Selangor in Malaysia. Filled with a lot of excitement, we went to Brand’s Suntory for our next industrial visit. We left for the place at 12 noon from MUST campus along with Ms. Dona Aini and our professors, Dr. Subir Sen and Dr. Debraj Datta. On reaching there, we were given a presentation which included the history of Brand’s, the various products under its umbrella, the benefits of the products, their places in the market, the revenues, profits, operations and other nitty-gritty details of Brand’s. After the presentation and Q&A sessions, we were given a tour to the factory by Ms. Jennifer, who is the head of e-commerce marketing of Brand’s. Before leaving the premises of Brand’s Suntory, we were given free samples of Brand’s signature products ‘Essence of Chicken’ and ‘InnerShine Berry Essence’. With this visit, our day ended with lots of learning and experiences.</p>
                                <p>The next day started with the presentation of another case study on leadership. Dr. David Bobker asked us to get together with our previous teams and prepare a presentation. He asked us to draw a shield on the chart papers provided to each group, on which we had to draw a product of our choice and mention the motto of the group underneath the shield. Post presentation and lunch, we were addressed by Prof. A.D. Ram, who gave us his insights into the Supply Chain System of Intel, where he worked as a senior manager. He explained to us the needs of the corporate world from us as young managers. On this note, our day ended and we learned a lot from this experience.</p>
                                <p>With a heavy heart, we started our last day at MUST with a session by Prof. Premkumar. He taught us about the different styles of leadership. During the session, he asked us to prepare a short personal evaluation of our individual leadership styles. After a short break, we were asked to assemble for our final group presentation on leadership, which was evaluated by Dr. Premkumar Rajagopal, Prof. Harwindar Singh, Dr. Subir Sen and Dr. Debraj Datta. After the presentations, Dr. Rajagopal gave us his feedback and showed us our areas of improvements. We were then given a long break and was asked to assemble at 5:30 pm, for the dinner ceremony.</p>
                                <p>At the ceremony, we were addressed by Ms. Dona Aini, who introduced us to our speakers of the night. After the speeches by our eminent speakers, we were awarded our Certificate of Participation. This was followed by a sumptuous dinner spread of Indian culinary delicacies which signed off our Malaysia trip on a grand note.</p>
                                <p>Needless to say, MUST was an amazing host to all of us. In the two weeks of our tour, we were enriched beyond our expectations, which in turn have made us more confident, more determined and more career-focused, thanks to the experiences that we have gained there. We are thankful to Globsyn Business School for giving us this international exposure that made us corporate ready.</p>
                                

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
