import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function GipPartTwo() {
    return (
        <><title>A GBS Experience through Global Immersion Program (Part 2)</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">A GBS Experience through Global Immersion Program (Part 2)</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog"> Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> A GBS Experience through Global Immersion Program (Part 2)</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Copy-of-Untitled-Design-1.png" alt="" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p><em>This blog is the second part of the ‘A GBS Experience through Global Immersion Program’ series, where a group of GBS students, pursuing Global MBA – International Business, visited Malaysia as a part of their Global Immersion Program. In our last blog from this series, we informed the readers about our next day’s plan – an industrial visit to Bank Negara Malaysia Museum.</em></p>
                                <p>Our day started with our attendance at the MUST premises at 8:30 am sharp. After the attendance, all the students along with Ms. Dona Aini and Ms. Fatimah from MUST boarded the assigned bus and left for Bank Negara Malaysia Museum for our first industrial visit. After reaching the Museum at 10:00 am, we all went through a thorough security check and were later provided with lockers to keep our handbags and backpacks. Soon after these proceedings, we were briefed about the history and the present scenario of Malaysia, along with the Do’s and Don’ts regarding the museum tour. The first gallery we visited was Pusaka, a contemporary Art Gallery, meaning ‘embracing our heritage for the future’. The name of this Art Gallery was given by Warisan Kemudi Wawasan. Pusaka features the works of 12 artists who realised heritage in a contemporary form. Some of these arts are ‘Mountains of Light’ by Haris Abadi, ‘This Is Certainly Not Like We Thought It Was’ by Ahmad Fuad Osman, ‘Biri Puteh’ by Jamil Zakaria, ‘Itu Homemade Malaysia’ by Bibi Chew, ‘A Message from the Objects’ by Umibaizurah Mahir @ Ismail, etc.</p>
                                <p>After visiting the Art Gallery, we were guided to the 2nd floor, where the Coin Gallery situated. We saw the evolution of money over the ages, along with their designers. We even saw the age-old tool used to manufacture gold coins. The floor was also home to the 10 billion dollars’ worth of gold bars and cash, secured within a glass room, which was visible only on entering the password. There was also a set of racks containing the evolution of the Malaysian currency, Ringgit, in the form of notes.</p>
                                <p>We were then guided to the 1st floor gallery that depicted economic situations of different countries in a unique and creative way. It showed the GDP rate of different countries until the year 2015 with the help of colourful neon lights. Also, there was a space which showed the inflation, deflation and unemployment rate of the countries. There was a space dedicated to the old newspapers containing the history of Malaysia. We were given the assignment of comparing and forming an analysis between Bank Negara Malaysia Museum and any of our national banks.</p>
                                <p>After our visit to the museum, we were taken to the Lulu Hypermarket, where we formed groups and explored the marketplace. There were a few international brands but the majority of the brands were the local ones ranging from skincare, apparels, and accessories to different eateries as well. Then, we visited the KL Forest situated in Petaling Jaya, which was just a few minutes walking distances from the Lulu Mall. There was a steep hike inside the forest to the top of the hill where there was a hanging bridge connecting two hill-tops which was unfortunately under construction. The entire forest was covered with lush greens, herbal gardens and tall trees and was home to a lot of flora and fauna.</p>
                                <p>The humid weather along with the rush hour city traffic were forgotten as we gazed in awe at the magnificent structures in the city starting from the astounding Petronas Tower to countless number of skyscrapers littering the city’s skyline. As each day passed by, we discovered that Malaysia is truly a flourishing cross-cultural country offering new experiences every day to those visiting it.</p>
                                <p>On this note, we started our next day. We reached the MUST campus at 9 am to attend our second class with Prof. Premkumar Rajagopal, where he explained to us the different powers of a leader. With the help of some live examples, he discussed charismatic leader and Black hat of charisma. Then, he gave us three situational questions, which we discussed among our group members and came up with the answers. He also gave us a case study, which we had to present in groups of 4-5 in front of the whole class. After the group discussion, Prof. Rajagopal discussed with us two real-life situations, one of the Johnson & Johnson and the other of Intel, and gave us an insight into the thought process of the Board of Directors’ taking the decisions. He also gave us the task of going through the other two case studies as homework for the next day.</p>
                                <p>At the end of the class, we realized that education happens not only through books and presentations but also by exploring the world, going ‘Beyond Education’ as we say at GBS. We learnt that as we explore the world, we gain knowledge and insight into various markets, customers, product information and using this knowledge to reach new trade upfront and open new business borders.</p>
                                <p>Our next stop for the day was, Bukit Bintang. Visiting this place was the finest way possible to learn advertisement because this was the place where big brands, such as OMEGA, Victoria’s Secret and many more, were displayed on big electronic screens. The place was also a heaven for street-food, and the food items we explored on the streets bore testimony to the fact that various cultural evolutions are happening in this country and it is flourishing with business. The more we explored this colourful nation, the more we learned about their culture and marketing, which resembled diversification in every field.</p>
                                <p>As another day came to an end and our journey on this land progressed, we penned down another experience in this travelogue. In our next part of the series, we hope to return with exciting chapters that are nothing short of learning chapters. Keep reading!</p>

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
