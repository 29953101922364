import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function SipAtAsian() {
    return (
        <><title>My SIP Experience at Asian Paints</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">My SIP Experience at Asian Paints</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">My SIP Experience at Asian Paints</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                            <img className="img-fluid" src="/assets/images/My-SIP-Experience-at-Asian-Paints.png" alt="" />
                            </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Hello, my name is Budhaditya Mondal and I am from Kolkata, ‘The City of Joy’. I would like to take this opportunity to share my Summer Internship Program (SIP) from one of India’s largest paint company – Asian Paints. For me it is a dream come true to have my first brush with corporate experience from such a market leader.</p>
                                <p>I started my summer internship at Asian Paints as a Research Analyst, where I was tasked with visiting different Dealers, Contractors and also Customers from all over Kolkata to understand the problems that they are facing in the Asian Paints Premium Range Products. Now, there are three types of product range in Paint Industry – Economic Range, Luxury Range and Premium Range.</p>
                                <p>Asian Paints has no problem with Economic and Luxury Ranges, as they are the market leaders of these two sects. However, in case of Premium Range, it faces a lot of challenges from immediate competitors like Berger, Nerolac, ICI, Indigo and Shalimar Paints. In such a scenario, I was given the responsibility to understand the overall interior Premium Range Market in Kolkata and also to execute a plan for Asian Paints to out-grow its competitors and help it to become the Market Leaders in Interior Premium Range section.</p>
                                <p>My experience of SIP at Asian Paints has been a great learning curve for me as I got to work alongside and learn from so many veterans of the company, especially the Territory Sales Officers and Regional Sales Officer. Till now I had visited 47 dealers and also organized two ‘Contractors Meet’ in Baguiati (Hotel Shivagan) and Garia (Jatra Suru Sangha) for launching new lucrative schemes for contractors.</p>
                                <p>As someone who is taking initial steps into the corporate world, I felt really welcomed when my seniors at Asian Paints, with its friendly environment, gave me all the support I needed while treating me as an employee instead of just an intern.  Being a Globsynite, I want to thank <Link to="/">Globsyn Business School</Link>, our CRP team and my faculty members for their continuous contribution in my SIP journey.</p>
                                <br/>
                                <p><strong>Budhaditya Mondal</strong></p>
                                <p style={{fontSize:"8pt"}}><Link to="/pgdm">PGDM</Link> Batch – 08, Globsyn Business School</p>


                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
