import React from 'react'
import { Link } from 'react-router-dom'
import '../Common.css'
import Sidebar from '../../Sidebar'

export default function AcademicCouncil() {
    return (
        <><title>Academic Council</title>
            <section className="hero-banner-img-trustee">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Academic Council</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/technology">Academic Council</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>The Academic Council of Globsyn Business School, is one of the four pillars of the academic ecosystem of the B-School.</p>
                                <p>Globsyn Business School is fortunate to have renowned academicians and industry veterans as its Academic Council members, whose able guidance and mentoring ensures that the B-School’s curriculum architecture and overall academic delivery are periodically updated, industry-aligned and in-line with the global trends.</p>
                                
                                    <div className="accordion" id="myAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Mrs. Suchitra Guha – Former Head, HR/IR Commercial, Tata Steel Limited</button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Suchitra Guha is a Human Resource subject matter expert with over four decades of experience in the full spectrum of HR and IR functions of an enterprise. She is especially recognized in the industry for her deep knowledge of: Industrial Relations, trade unions, collective bargaining, wage agreements and tribunal cases. She also has substantial experience in workforce planning, competency frameworks, performance management, learning and training, outsourcing HR services and right-sizing organizations, undergoing mergers, acquisitions or re-structuring. She also has deep experience in managing internal equity and designing reward systems.</p>
                                                    <p>Suchitra’s experience and subject matter expertise combines both Human Resource management and Industrial Relations. Suchitra’s area of influence at Tata Steel spanned all the HR functions and she was viewed by the organizations leadership as a strategic advisor and key influencer. She provided HR partnership to a business unit which had a headcount of over 5000 which included professionals, unionized staff and a contingent workforce across multiple locations.</p>
                                                    <p>Suchitra played a critical role in Tata Steel’s internal transformation journey and its subsequent globalization. Post retirement from Tata Steel, Suchitra provides independent advisory and consultancy services to both for profit and not for profit organizations. She is currently an external HR advisor to the C-suite of Tata NYK, Singapore. Suchitra is also the external independent member of the Sexual harassment and internal complaint committee of Tata Steel. Tata metallic’s, British Oxygen.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingtwo">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsetwo">Mr. Saibal Chatterjee – Managing Director, Lokenath Chatterjee & Sons (Precision Tools) Pvt. Ltd.</button>
                                            </h2>
                                            <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Mr. Saibal Chatterjee is the Chairman and Managing Director of his own business, Lokenath Chatterjee & Sons (Precision Tools) Pvt. Ltd., the company founded by his father in 1964. Mr. Chatterjee literally grew up with the company, working with his father during spare time right from his early school and college days. This helped him develop a keen interest in the technical as well as all other aspects of the business. Today, Mr. Chatterjee’s company has a global presence, with customers and agents all over Europe, USA, Canada, Australia, New Zealand and South Africa. His company exports 90% of its production, and concentrates on research and development in order to keep pace with the rapid changes in modern technology. He has a licensing agreement with a reputed company in Switzerland. In the USA, his company does private branding for two leading Machine Tool manufacturers based in Idaho and Chicago.</p>
                                                    <p>Apart from his work, Mr. Chatterjee enjoys a serious hobby of teaching, especially in the evening and after office hours. What started as a hobby about five years ago is almost a second profession for him today. He is now visiting professor for International Business at IISWBM, Kolkata and also teaches Global Marketing for the MBA program at the Bengal Engineering College & Science University, Shibpur. Internationally, he has lectured at the University of Lowell in Boston, USA in 2004 and at the International Business School, University of Arnem in Holland in 2007. He loves teaching and sharing his work and travel experiences with his students. What makes Mr. Chatterjee an interesting person is that he feels that both his profession and his hobby provide him with that missing link between the academic and corporate styles of Management.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingthree">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Mr. P. K. Chatterjee – Former President HR, Birla Corporation</button>
                                            </h2>
                                            <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Mr. Chatterjee is an HR practitioner by choice for over four decades. He started his career as Management Trainee in Andrew Yule & Co., then moved to ACC-Vicker’s Babcock Ltd. to Indian Oil Corporation to Hindustan Unilever Ltd. where he worked for over 25 years and was responsible for HR function of all the businesses in the eastern region including Nepal. He then served as Group-Head-HR and Sr. Vice-President in Duncans Industries Ltd. He then took up business role as Managing Director of IAG Limited (formerly Indo-Asahi Glass Limited). He also handled several consulting assignment on coaching on behalf of Aon Hewitt. He is an Hons. Graduate and Post Graduate with specialization in Sociology and MBA. He has been trained in various facets of HR, both in India and abroad. Apart from his professional engagements, he was member of various Central and State Government bodies. He is Past Chairman of HR, CSR & Skill Development Sub-Committee of BCCI, Past Chairman of NIPM, Past Vice-Chairman of ISTD, Life Member of NHRD and National Council Member of Employer’s Federation of India. He has represented India as Employer’s representative in ILO, Italy, South Korea etc. He is currently working as President (HR) of Birla Corporation Limited. He is also associated with several NGOs and spends considerable time for social cause.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingten">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseten">Mr. Sudhir Chand – Managing Director, Inflexion Management Services</button>
                                            </h2>
                                            <div id="collapseten" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Sudhir Chand is an Electrical Engineer from BITS, Pilani, an alumni of Jamnalal Bajaj Institute Mumbai, and an Executive Education alumnus of IIM, Ahmadabad and Cranfield School of Management, UK.
                                                        His 36 years in industry stint commenced at Crompton Greaves Ltd. In which he served for 7 years and after a brief job hop in between followed by Exide Industries Ltd for 28 years. Starting in Marketing Services, he held various positions in Exide as General Manager Marketing, Vice President international, and Executive Vice President Marketing before being promoted to the main Board of Exide in 1996, where he served as an Executive Director for 10 years. First, he served there as Director Automotive, handling the company’s largest Division as profit centre head and then as Director Corporate Affairs for the Company- including HR ,Training and Corporate Social Responsibility. He retired from Exide in 2006. He was inter alia responsible for the strategic merger of its biggest competitor, the brand repositioning of Exide, the introduction of BAT MOBILE and the launch of DYNEX and SF SONIC brands. He is Past President of CMA (Calcutta Management Association) and was a member of the Eastern Regional Council, CII, and served on various Committees of CII, Bengal Chamber of Commerce and Industry, and Govt. of India. He was also the Secretary General of IBMA (Indian Battery Manufacturers’ Association). He has continuously been associated with academic work and teaches Marketing at various Institutes and has been a guest faculty for some MDP programmes at IIM, Calcutta and Calcutta Management Association.</p>
                                                    <p>Chand is on the Advisory Board of ICFAI, and academic councils of Globsyn Business School, Future Institute of Engineering & Management,Kolkata and Board of Studies (Management Studies) of the Visva Bharati University, Santiniketan. He recently started his own consulting company – Inflexion Management Services Pvt Ltd. consulting in areas of Marketing ,Market Research and HR. He is an associate in MART (the country’s premier organization in rural marketing). He is also a Senior Associate of Right Grow Talent Company Ltd. and works in areas of Talent Appreciation, Development Centers and Leadership Development for some of the premier companies in India.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingfour">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefour">Dr. Prabir De – Department of Mechanical Engineering, Jadavpur University</button>
                                            </h2>
                                            <div id="collapsefour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Dr. Prabir De is a Professor in the department of Mechanical Engineering, Jadavpur University. He has done his bachelor’s and master’s from adavpur University and completed his Ph.D from Indian Institute of Science. He has been engaged in teaching & research for undergraduate & postgraduate programmers. He has total 25 numbers of research publications and his current research activities are modelling and analysis of transient phenomena, in particular to simple and cross-country pumping mains. His main clients are NTPC, Govt. of Qatar, Govt. of Oman, Consulting Engineering Services, PHE-Govt. of Mizoram, Mackintosh Bum Ltd., Techno Electric and Engineering Co. Ltd., Larsen and Toubro Ltd., Tata Consulting Engineers, etc.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingfive">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefive">Dr. Subhrangshu Sanyal – CEO, IIM Calcutta Innovation Park</button>
                                            </h2>
                                            <div id="collapsefive" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Subhrangshu is a startup evangelist and an experienced management professional with a focus on entrepreneurship & innovation. As the Chief Executive Officer of llM Calcutta Innovation Park, he is actively engaged with the key stakeholders, such as Government, Academia, Corporate, Investors, in the entrepreneurship domain. He is also involved in creating a strong enabling ecosystem for “the startups with a special focus on East and North East India”. He is also responsible for the incubation activities like sourcing, mentoring, capacity building and funding of the startups. Subhrangshu has rich experience in innovation management, business process re-engineering and program management. He worked with leading companies in multiple geographies and was involved in end to end implementation of large strategic projects for global organizations. He also had a stint with a technology startup. Subhrangshu has a strong consulting background with a Doctor of Philosophy (Ph.D.) from National Law University, PGDM from llM Calcutta and B.E. in Mechanical Engineering from Jadavpur University.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingsix">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsesix">Dr. Prabina Rajib – Dean, Vinod Gupta School of Management, IIT Kharagpur</button>
                                            </h2>
                                            <div id="collapsesix" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Prabina rajib is a professor of Finance & Accounts in VGSOM, IIT, Kharagpur, He was visiting faculty of AIT Bangkok, former teaching assistant in Curtin Business School, Perth and Fullbright Fellow at Purdue University. She has published over 40 referred journals and 18 conference papers. She has authored two books including ‘Commodity Derivatives & Risk Management, 2014 and Stock Exchanges & Investments- Straight Answers to 250 Nagging Question, 2007 (coauthored with Prof. V. Raghunathan)’.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingseven">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseseven">Mr. T. V. Srinivas Shenoy – Chief Commercial Officer (Long Products), Tata Steel Limited</button>
                                            </h2>
                                            <div id="collapseseven" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>T. V. S. Shenoy, based out of Kolkata, is presently the Chief Commercial Officer of the Long Products Division, Tata Steel.</p>
                                                    <p>As an MBA and engineer, he joined Tata Steel from NIT Rourkela campus in 1992, and has worked in various SBUs of Tata Steel – The Mines Division, The Tubes SBU, Agrico and Retail Initiatives (Steel Junction), the Ferro Alloys and Minerals Division, and the New Materials Business. Prior to this, he was the Chief of the New Materials Business at Tata Steel.</p>
                                                    <p>With nearly three decades of experience in various functions in Tata Steel – Operations, Channel Sales, B2B Sales, B2C Sales, Domestic and International Sales, he has worked pan India in cities like Jamshedpur, Mumbai, Ahmedabad, Delhi, and now in Kolkata. He has also headed the marketing functions, and handled the brands of Tata Pipes, Tata Structura, Tata Agrico, Steel Junction, and Tata Silcomag.</p>
                                                    <p>Mr. Shenoy is also the President of the CMA (Calcutta Management Association), and enjoys travelling and writing with a keen eye on photography.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingeight">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseeight">Dr. Aditi Mitra – Senior Lecturer, Department of Economics and Finance - Sunway University Business School, Malaysia and GBS alumnus</button>
                                            </h2>
                                            <div id="collapseeight" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Aditi Mitra is currently working as an Assistant Professor at Sunway University Business School, Malaysia where she teaches in the Accounting and Finance degree programmes which are validated by the UK’s leading university – Lancaster University. She has been awarded a Doctoral Degree from the University of Putra Malaysia – Business School (AACSB accredited). Her doctoral research focuses in the domain of social accounting and strategic business decisions. Her passion for teaching has inspired her to also complete the Post Graduate Certificate in International Academic Practice (PGCAP) offered by The University of Lancaster, UK. She also has industry experience of over five years in Consulting and Research and Risk Advisory roles among global firms as Deloitte and Nielsen.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingnine">
                                                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsenine">Prof. R.C. Bhattacharya – Vice Chairman, Globsyn Business School</button>
                                            </h2>
                                            <div id="collapsenine" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div className="card-body">
                                                    <p>Prof. Bhattacharya is a Metallurgist by profession and an MBA from Cranfield Business School, UK. He has also completed a programme on ‘Strategic Thinking and Management’ from Wharton Business School, U.S. Prof. Bhattacharya joined Indian Oxygen Ltd. (now BOC India Ltd.) initially as General Manager-Operations and finally rose to the level of Chief Executive, Eastern Region. He was also the Senior Vice President-Marketing, with one of the Aditya Birla Group Companies before switching over to Academics and Consultancy. He carries more than 30 years of industrial management experience in Operations, Quality Assurance, Marketing, Strategy and General Management. Prof. Bhattacharya has been teaching Marketing, Industrial Marketing, Marketing of Services, Strategic Marketing, Strategic Management and other topics as visiting Professor in many renowned business schools. He has been a visiting professor at IIM-Calcutta for about 20 years. He has conducted more than 30 Management Development Programmes and has published and presented a number of papers in India and abroad. He has first-hand experience in Strategic Alliances, Brand Building in Industrial Markets, Corporate Marketing, Relationship Marketing, Loyalty Building etc. He has been a Consultant in the area of Strategic Management with both national and international assignments.</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
