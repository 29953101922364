import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function MyJourneyInCovid() {
    return (
        <><title>My Journey as a Management Student in the Time of COVID-19 Pandemic</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">My Journey as a Management Student in the Time of COVID-19 Pandemic</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">My Journey as a Management Student in the Time of COVID-19 Pandemic</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/Website-Blog-img-2.png" alt="My Journey as a Management Student in the Time of COVID-19 Pandemic" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>COVID-19 has given rise to a global catastrophic situation that has affected every sphere of human life. Amidst this pandemic, the only thing we can do is to stay positive and carry on with our lives. This hope towards a better and brighter future has encouraged me to enrol in the Business Management Program and continue with my higher education.</p>
                                <p>I started my MBA journey with Globsyn Business School in the middle of the COVID-19 outbreak in India. I was always excited to visit my new college, spend time and interact with new friends and professors. But I could not experience all these as all the educational institutions shifted completely to an online mode on short notice during the worldwide lockdown.</p>
                                <p>An MBA degree prepares a student for the professional world, so the pedagogy gives ample opportunity for social interaction, in the form of meeting new people, making new friends while expanding my network. These are all the reasons I joined this programme, but COVID-19 has significantly decreased my opportunity to interact with my fellow mates and lecturers in person. Yet, it is commendable how Globsyn Business School integrated the entire MBA program into the online mode and provided us with quality education even in these trying times.</p>
                                <p>At present, I can interact with my fellow students and lecturers through Online Video conferencing applications and forums, the live classes allow me to experience the same as that of a physical classroom or the lecture hall. Initially, although I faced some technical glitches while using the online tools, now in this stay-at-home period, these digital tools have helped me to stay connected with my friends and faculties. I have become confident and comfortable in using Zoom, Google Meet, Webex Meetings for our class meetings, discussing our projects as well as making presentations in a very professional way. We have also discovered many innovative strategies to display our managerial skills. Our faculties always motivate us to stay focused on our career goals and guide us to adapt to this changing world.</p>
                                <p>I have always been intrigued with the International Business Programme because it allows us to discover the world of global business opportunities which with proper research, planning, presenting and reporting will make us fit for international management or consulting roles. However, there are only a few business schools to offer management degree in International Business, and Globsyn Business School (GBS), listed among the Top 50 B-Schools in India, is one of them. Taking admission into the Post Graduate Programme in Management – International Business + Master of Business Administration (PGPM-IB + MBA) from GBS was surely one of the best decisions I have made so far.
                                    Designed with strong global biases, Globsyn’s PGPM-IB+MBA programme is dedicated to providing us with integrated learning experiences with sessions on global case studies. Amidst this challenging situation, GBS, in collaboration with Malaysia University of Science and Technology (MUST) is all set to groom students to make them ‘industry-relevant and I feel fortunate to be a part of GBS.</p>
                                <p>I must thank all the faculties of the B-School and teachers of MUST for continuing their lectures seamlessly through various digital platforms even during this tough time. When most of the B-Schools are grappling with the nuances of an offline-to-online transition, Globsyn Business School provides us with an opportunity to choose our learning path. Along with its synchronous mode of learning, GBS offers e-Globsyn for us, the asynchronous platform through which we can access our learning according to our space, time and pace.</p>
                                <p>Online education certainly has its benefits, but in my opinion, it depends on various factors: the individual’s preferences, the requirements of the industry that the individual works in and also the general perception of online education in the community.</p>
                                <p>The environment of GBS is positive and supportive. Pursuing an MBA from India’s leading Business School is something that I wanted to achieve, and being a Globsynite, I like to thank my B-School from my heart for providing us with the best curriculum as well as making us relevant and agile.</p>
                                <p>Globsyn Business School focuses on the holistic development of individuals, along with managerial skills. The various ‘Beyond Education’ activities are so designed to imbibe the essential human qualities – empathy, creativity, passion, amongst others to make us successful in the future career.</p>
                                <p>To conclude, this pandemic may appear terrible, but this could also be a perfect time to re-acquaint ourselves with our inner strength, peace and wisdom which will drive us towards our target and help us to achieve our goals in life.</p>
                                <br />
                                <p><b>Rajasree Chaudhuri</b><br />
                                    Batch 2020-22<br />
                                    Globsyn Business School</p>


                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
