import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ItForManagers() {
    return (
        <><title>IT for Managers – Corporate Connect</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">IT for Managers – Corporate Connect</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">IT for Managers – Corporate Connect</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>As India’s first ‘Corporate B-School’, Globsyn Business School (GBS) designed ‘Corporate Connect’ as one of the cornerstones of its academic delivery system, which exposes the students to the nuances of the corporate world and makes them industry-relevant managers. With this vision to build a globally networked, corporate savvy, research driven management education system, GBS over the years has been inviting industry stalwarts to share their knowledge and experiences with the young managers, which will help them in their future corporate journey.</p>
                                <p>Following is an account on the experience of one our students, who have been a part of a Corporate Connect session.</p>
                                <p>“Uber doesn’t have a single taxi of their own and Flipkart doesn’t have their own inventory. Starting a business doesn’t need a huge investment.”</p>
                                <p>The Corporate Connect session on the subject ‘IT for Managers’, conducted by Mr. Debkumar Majumder, Director & Communication Sector Leader, IBM India Pvt. Ltd. started on this note. These days, businesses run completely through innovative ideas and cutting-edge technologies, which give birth to another version of Industry – Industry 4.0</p>
                                <p>During the session, Mr. Majumder mentioned about the way Artificial Intelligence is influencing present day global business scenarios, as the entire business process today is implemented through automation – starting from marketing to production to logistic – the entire process is executed through programmed computers. He also mentioned the various roles of IoT in business processes, like improving asset utilization by monitoring equipment through sensors and taking preventive maintenance for uninterrupted services. He also specified that IoT applications can connect to video cameras and sensors to monitor the equipment to avoid physical threats. By enhancing the business module, assets utilization, equipment monitoring and employee training services, IoT applications also saves your overall business cost.</p>
                                <p>Following the above discourse, Mr. Majumder mentioned about role of ‘Natural Language Processing’ (NLP) in any business, and how it helps in inter-staff communication, leadership, presentations, improving personal effectiveness and sales-negotiation-persuasion.</p>
                                <p>In conclusion, Mr. Majumder dealt with the importance of data in running a business. Almost every modern business platform or tool can deliver rows upon rows of data for use. According to him, in the present day scenario, gathering data helps a business to have a better understanding of its customers and markets. He gave example of ‘sentiment analysis’ and ‘churn analysis’ in telecom industry to support this. While concluding this segment, he laid emphasis on the fact that data helps a business to take decisions, to solve problems, to enhance performance, to improve business processes, to understand consumers and markets etc.</p>
                                <p>This Corporate Connect session is extremely helpful for us, as we are poised to set foot in the corporate world. We are thankful to Globsyn Business School for conducting such sessions and exposing us to a global corporate culture.</p>
                                <p>– Student Blog</p>


                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
