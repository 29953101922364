import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function BloodDonationCamp() {
  return (
    <><title>Blood Donation Camp at Globsyn Business School</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Blood Donation Camp at Globsyn Business School</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Blood Donation Camp at Globsyn Business School</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Blood Donation Camp at Globsyn Business School.jpg" alt="Blood Donation Camp at Globsyn Business School" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}><b>Editor’s Note</b><br /><em>Rohini Majumdar, Director – Content Writing, Kalyani Youth Leadership Forum (KYLF) 2022-23, writes about her experience of organizing a Blood Donation Camp in collaboration with Saroj Gupta Cancer Centre and Research Institute. Read on to know how Rohini, along with her batchmates, went about organizing the camp with seamless coordination and execution, ensuring that over 150 people donated blood at the camp.</em></p>
                                <p>With the ethos of ‘Care for Society’ firmly embedded in its philosophy, student volunteers of Kalyani Youth Leadership Forum (KYLF), a Beyond Education initiative of Globsyn Business School (GBS), organized a Blood Donation Camp in collaboration with Saroj Gupta Cancer Centre and Research Institute.</p>
                                <p>
                                    <img className="img-fluid" src="/assets/images/camp1.jpg" alt="Camp" />
                                </p>
                                <p>Nearly 150 students and employees of GBS came together in this noble mission to touch thousands of human lives through caregiving and raise awareness about the imperative need for voluntary blood donation in our society. The camp was organized by the KYLF student volunteers with help from the Student Council, First Year students and GBS faculty. A systematic process was set up by the students, which made the entire process of blood donation seem smooth and hassle free for the donors. The donors also motivated and encouraged the students who were hesitant of donating blood and helped them overcome their fear of blood donation. Mrs. Ranjana Dasgupta, Managing Trustee, Kalyani – A Bikram Dasgupta Foundation, and Trustee, Globsyn Knowledge Foundation, visited the blood donation camp and motivated and encouraged everyone with her kind words.</p>    
                                <iframe width="796" height="446" src="https://www.youtube.com/embed/vgVxhuv9L7o" title="GBS Blood Donation Camp 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                <p>The 1st year students took a great initiative in organizing the camp and served as a backbone for the 2nd year students by supporting them with seamless coordination and execution. Many of the donors were donating for the first time. Hence all effort was made to keep them in their comfort zones. Some of the students felt a sense of discomfort after donating blood, but the doctors from Saroj Gupta Cancer Centre made sure that every student was at their best of health. We had arranged food supplements for the donors. As soon as the donors were relieved by the doctors after a final check-up, the volunteers immediately gave them food supplements as well as food packets. At the end, the students were filled with a sense of satisfaction as they realised that they made such a difference, and had made an impact on the society.</p>
                                <p>
                                    <img className="img-fluid" src="/assets/images/camp2.jpg" alt="GBS Blood Donation Camp 2022" />
                                </p>
                                <p>Throughout the entire event, the Student Council supported the KYLF Board members, ensuring that the event ended successfully with each donor feeling proud of their noble act. Initially, we were nervous about how much the students would support the event, but the whole team of KYLF was overwhelmed by the response. At the end of the day, the KYLF team thanked each individual for taking out time and attending the event with such positivity.</p>
                                <div className="one-table">
                                    <div className="one-table1"><img className="img-fluid" src="/assets/images/camp4_profile-1-150x150.jpg" alt="tt" width="150" height="150" /></div>
                                    <div className="one-table2">
                                        <p><b>Rohini Majumdar</b><br />
                                        Director – Content Writing<br />
                                        Kalyani Youth Leadership Forum<br />
                                        Globsyn Business School<br />
                                        Batch 2021-2023
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
