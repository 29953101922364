import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function TeachingThroughTechnology() {
  return (
    <><title>Teaching Through Technology – A New Way of Life @ GBS</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Teaching Through Technology – A New Way of Life @ GBS</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Teaching Through Technology – A New Way of Life @ GBS</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/BLOG-IMAGES-1.png" alt="Teaching Through Technology – A New Way of Life @ GBS" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Technology, Research and Innovation have always been a way of life for every faculty in Globsyn Business School (GBS) since the last two decades. We have always thrived on doing things in a different way, be it Flip Classroom with e-globsyn as our LMS at the back end or Experiential Learning through case studies and group presentations or having exposed our students to the applications of theory in the market place through ‘Industry Connect’ sessions. We have done it all. However, in all these experiences the faculty – student relationship at GBS were driven by touch and feel which every faculty values at GBS. This is more important as we believe in shaping beyond ‘industry relevant managers’ to making ‘responsible corporate citizens’. To facilitate this transformation across young minds, we at GBS, initiated “Beyond Education’; societal activities through which every student at GBS help discover themselves.</p>
                                <p>However, with the COVID-19 lockdown declared since March 23, 2020, our Campus stands closed till date, diligently following social distancing norms declared by the appropriate authorities. However, learning at GBS has not stopped. Having that adaptive DNA embedded in the genes of every Globsynite, we seamlessly shifted to online delivery in no time. Online classes commenced within a fortnight. However, even though students and parents were initially sceptical, the confidence displayed by the faculty in blending technology and learning reposed the confidence in drawing students in hoards into our virtual classrooms. Google Hangout query sessions were arranged to clear all doubts and ambiguities, ‘Industry Connect’ sessions were also held using Facebook and Instagram Live, and even our popular ‘Globsyn Management Conference’ (GMC) went through the Webinar mode. Also, but not the least, our end semester exams also went online using the ‘Open Book’ method. The lockdown which also coincides with our ‘Summer Internship’; faculty members have seamlessly blended themselves to online mentoring using Whatsapp or Google Duo to video call with their mentees.</p>
                                <p>This adaptation has not been restricted to our academic delivery team only; our Admission Team and Corporate Relationship Program Team along with all day to day operations at the B-School have also all gone digital end-to-end working from the comfort of their homes. In the years to come this effective and diligent blending of online with offline methods of academic delivery is going to be a new way of life at GBS.</p>
                                <p><b>Prof. (Dr.) Subir Sen</b><br />
                                Faculty – Finance<br/>
                                Globsyn Business School
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
