import React from 'react'
import { Link } from 'react-router-dom'
import '../Common.css'
import Sidebar from '../../Sidebar'

export default function AddmissionOffice() {
    return (
        <>
            <title>Addmission Office|Globsyn</title>
            <section className="hero-banner-img-inno">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Addmission Office</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">Addmission Office</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>

                            </div>
                            <div className="one-table">
                                <div className="one-table2">
                                    <p>Globsyn Business School’s, admissions office is situated at the heart of knowledge economy hub of eastern India – the Salt Lake Electronics Complex, Kolkata.</p>
                                    <p><strong>Globsyn Group – Corporate Office</strong><br />
                                        Globsyn Crystals, 1st Floor<br />
                                        XI – 11 12, Block – EP, Sector V<br />
                                        Salt Lake Electronics Complex<br />
                                        Kolkata – 700091<br />
                                        Phone: +91 82740 04556<br />
                                        E-mail: <a href="mailto:gbsdirect@globsyn.edu.in">gbsdirect@globsyn.edu.in</a></p>
                                    <p><iframe className='w-100' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d921.0484211077517!2d88.4325039!3d22.5718582!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0275ae8dc57fcb%3A0x7163d68d7584faf!2sGlobsyn+Group+-+Corporate+Office!5e0!3m2!1sen!2sin!4v1507103992557" height="450"  title='office-map' allowFullScreen></iframe></p>
                                </div >
                            </div >
                        </div >
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div >
                </div >
            </section >
        </>
    )
}
