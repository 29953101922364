import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../Sidebar'


export default function OperationManegment() {
  return (
    <>
    <title>School of Operations Management Excellence | Globsyn Business School</title>
    <section className="hero-banner-img-pgdm">
        <div className="container">
            <div className="entry-banner-content">
                <h1 className="entry-title">School of Operations Management Excellence</h1>
                <div className="d-flex">
                    <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                    <div><span className="text-light">-</span></div>
                    <div><Link className="text-light" to="#">School of Operations Management Excellence</Link></div>
                </div>
            </div>
        </div>
    </section>
    <section className='mt-8'>
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div>
                       
                    </div>
                    <div className="one-table">
                        <div className="one-table1"></div>
                        <div className="one-table2">
                            <p>The study of Operations Management is pivotal for a comprehensive understanding of ‘production – from inception to implementation’ in the commercial sector. The School of Operations Management Excellence at Globsyn Business School thrives to viably educate aspiring operation managers about the fundamental nitty-gritty involved in the management procedure – from learning the concepts of design, planning, arranging, staffing, driving and controlling, to settling on a strategy and running an implementation cohesively. The layered structure of the Operations Management specialization programme also covers a variety of industrial experiences, equal parts, academic disciplines. One of the primary objectives of this elective is to provide aspirants with high-quality teaching and research covering multifarious levels of managerial decision-making.</p>
                            <p>The School of Marketing Excellence offers the following areas of study:</p>
                            <ul className='disc'>
                                <li>Operations Strategy</li>
                                <li>World Class Manufacturing</li>
                                <li>Service Operations</li>
                                <li>Total Quality Management</li>
                                <li>Logistics Management</li>
                                <li>Resource Management</li>
                                <li>Technology Management</li>
                                <li>Dissertation for Operations Management School of Excellence</li>
                            </ul>
                            <p><em>* Not all papers are offered in all courses</em></p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12">
                    <Sidebar />
                </div>
            </div>
        </div>
    </section>
</>
  )
}
