import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function GamificationEducation() {
  return (
    <><title>Gamification in Education</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Gamification in Education</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Gamification in Education</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Gamification-in-Education.png" alt="Gamification in Education" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>The present generation is digital natives; they grow up tech-savvy using digital technologies.</p>
                                <p>The education sector presently has been witnessing a massive change, the outlook on education is diversifying, and they are advocating for an alternative mechanism to propagate quality knowledge. In this context, mention must be made of Gamification in Education. When it comes to learning, being focused, and immersed, gamification offers a way to make education more enjoyable and fun, alongside productive.</p>
                                <p><b>What is Gamification?</b></p>
                                <p>According to Kapp, Gamification is “using game-based mechanics, aesthetics and game thinking to engage people, motivate action, promote learning, and solve problems.” (Kapp, 2012)</p>
                                <p>Gamification can be defined as the process that implements game-like elements into non-gaming activities to enhance motivation and engagement. Gamification involves using game-based elements such as point scoring, peer competition, team work, score tables to drive engagement, which help students assimilate new information and test their knowledge.</p>
                                <p><b>How is Gamification used in Education?</b></p>
                                <p>Gamification in education is a strategy for increasing engagement and enhancing the learning experience of students by incorporating game elements into an educational environment.</p>
                                <p>Gamification can be incorporated to conduct pre-assessments, formative assessments, as a part of the course delivery etc. to understand, review and evaluate the student’s learning. It can be incorporated at different stages of a course depending on the course and the discretion of the faculty.Gamification works because it triggers real, powerful human emotions such as happiness, intrigue, excitement and accomplishment.</p>
                           <p>The following can be considered as ways to gamify your classroom:</p>
                           <ol>
                            <li><b>Determination of learners’ characteristics</b>: When teachers implement new approaches in the learning process it is essential to define students’ characteristics (profiles) in order to determine whether the new tools and techniques would be suitable. The key and decisive factors are the predisposition of the students to interact with the learning content and to participate in learning events with competitive nature.</li>
                            <li><b>Definition of learning objectives</b>: The learning objectives have to be specific and clearly defined. The purpose of education is to achieve the learning objectives because otherwise, all activities (including gamification activities) will seem pointless. The objectives determine what educational content and activities to be included in the learning process and the selection of appropriate game mechanics and techniques to achieve them.</li>
                            <li><b>Creation of educational content and activities for gamification</b>: The educational content should be interactive, engaging, and rich in multimedia elements. The training activities should be developed tailored to the learning objectives (Simões, J., R. Díaz Redondo, A. Fernández Vilas, 2013).</li>
                            <li><b>Adding game elements and mechanisms</b>: The key element of gamification is the inclusion of tasks that learners have to perform. The performance of tasks leads to accumulation of points, transition to higher levels, and winning awards. All these actions are aimed at achieving predetermined learning objectives. Which elements will be included in training depends on the defined objectives (what knowledge and skills should be acquired as a result of the task). Activities that require independent work by students bring individual awards (such as badges). Activities requiring interaction with other learners are the social element of training, they make students a part of a big learning community and their results are public and visible (such as leader boards) (W. Hsin-Yuan Huang, D. Soman, 2013).</li>
                           </ol>
                           <p><b>Impact of Gamification on Students’ Learning</b></p>
                           <p>The most important indicator of a student’s knowledge attainment is engagement; the level of attention and personal involvement a person has with an activity. The more engaged students are with the learning process, the more they will internalize the learned skills. Studies on gamification show that the use of gamification in the classroom increases student engagement. The advantages of gamification in the classroom are as follows:</p>
                           <ul className='disc'>
                            <li>Gamification increases competition which can lead to engagement.</li>
                            <li>Gamification involves creativity and student choice, which increases engagement.</li>
                            <li>Gamification gives students immediate feedback (through peer feedback, progress bars, badges, teacher response, etc.) and allows them to easily track their progress towards academic goals.</li>
                           </ul>
                           <p><b>Gamification in Education at Globsyn Business School</b></p>
                           <p>Globsyn Business School has integrated Gamification as a part of its continuous evaluation in the form of surprise and scheduled quizzes. These platforms can also be used to deliver lectures in class by incorporating quizzes as well as PPTs. The B-School has explored the different gamification platforms, especially Quizizz and Kahoot, as they include maximum features and proved to be more engaging and attractive as compared to some others.</p>
                           <p>Activities, exercises, task-based analysis, role-plays, and now gamification, play an integral part of the GBS curriculum that ensures development of the students’ cognitive, inter-personal, and intra-personal skills, so that they emerge as industry relevant managers.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
