import React from 'react'
import Sidebar from '../Sidebar'
import './Common.css'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'


export default function Ranking() {
    return (
        <><title>Rankings & Recognitions</title>
            <section className="hero-banner-img-inno">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Rankings & Recognitions</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Rankings & Recognitions</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>

                            </div>
                            <div className="one-table">
                                <p>Over the past two decades, Globsyn Business School – considered to be amongst the top 5 B-Schools in Eastern India, as per a recent Business India’s Best B-Schools Survey – has received several laurels from the industry-academia fraternity. Here are some of the rankings and recognitions conferred to the B-School in the last 5 years.</p>
                                <p style={{ textAlign: 'center' }}>
                                <ReactPlayer className="w-100" style={{height:"450px"}} url='https://www.youtube.com/embed/VJDajOxei-w' />
                                </p>
                                <br></br>
                                <h4>RECOGNITIONS</h4>
                                <ul className='disc'>
                                    <li>Globsyn Business School was recognised as the ‘Institute of the Year – East‘ by ASSOCHAM at 16th International Education Leadership & Skill Development Summit 2023.</li>
                                    <li>Globsyn Business School was recognised as the ‘Best Private B-School in Eastern India’ by The Times Group at Times Brand Icons 2022.</li>
                                    <li>Globsyn Business School was recognized as the `Best Private Business School – West Bengal` at the ET Industry Leaders 2022.</li>
                                    <li>Globsyn Business School has been recognized as the ‘Leading B-School in Eastern India’ by The Times Group in the edition of Times Brand Icons 2021.</li>
                                    <li>Globsyn Business School’s Embryon – The Entrepreneurship Cell has been recognized by AACSB’s 2021 Innovations That Inspire initiative, which showcases business schools creating positive societal impact.</li>
                                    <li>Globsyn Business School has been recognized as the ‘Best B-School with Academic Excellence’ in Eastern India at the Asia Education Summit & Awards 2021.</li>
                                    <li>Globsyn Business School has been recognized as an ‘ET Leading B-School’ by ET Industry Leaders EAST 2020</li>
                                    <li>Globsyn Business School has been recognised by The Voices with the prestigious Dr. Radhakrishnan Institutional Excellence Award 2020, in the category of Top Lead Business School.</li>
                                    <li>Globsyn Business School has been recognised by The Times Group as a Times Stellar Brands East 2020 in the category of Leading B-School in West Bengal.</li>
                                    <li>Globsyn Business School has been recognized by ET Edge, an initiative by Economic Times, as one of the best education brands 2020.</li>
                                    <li>Globsyn Business School has been recognized as the ‘Academic Partner of the Year, 2019’, by an University endorsed by the Government of Malaysia, amongst other partners from Vietnam, Sri Lanka, China, Nigeria and Ghana at Kuala Lampur, Malaysia</li>
                                    <li>Globsyn Business School has been conferred with the title of ‘Most Preferred B-School of the Year – East’ at the esteemed 12th EduShine Excellence Awards 2019, organized by the Associated Chambers of Commerce & Industry of India (ASSOCHAM).</li>
                                    <li>Globsyn has been conferred with the West Bengal Best Employer Brand Awards 2018, organized by Employer Branding Institute and World HRD Congress.</li>
                                    <li>Globsyn Business School has been conferred with the esteemed Education Excellence Awards 2018, organized by the Associated Chamber of Commerce and Industries of India (ASSOCHAM), for being the ‘Best B-School in Placements’ for 2018.</li>
                                    <li>Globsyn Business School has won at the National Innovation Award organized by Associated Chamber of Commerce and Industries of India (ASSOCHAM) for being an Outstanding Institution adopting ‘Innovation in Pedagogy 2018’.</li>
                                    <li>Globsyn Business School has been awarded as Best Business School of the Year (East) at the 8th Indian Education Awards 2018 organized by Franchise India.</li>
                                    <li>Globsyn Business School has been conferred with the prestigious Gurukul Award for being the Best MBA College (Non IIM) during 2015-16.</li>
                                </ul>
                                <br/>
                                <h4>RANKINGS</h4>
                                <ul className='disc'>
                                    <li>Globsyn Business School has been ranked No. 1 Private B-School in Kolkata as per MBA Universe 2021.</li>
                                    <li>Globsyn Business School has been ranked among the Top 50 B-Schools in India, and Top 5 B-Schools in Eastern India along with IIM – Calcutta, XLRI – Jamshedpur, IIT-Kharagpur (VGSM), and XIM – Bhubaneshwar, as per Business India’s Best B-Schools Survey 2020.</li>
                                    <li>Globsyn Business School has been ranked among the top 60 Business School in India (amongst 3000 registered B-Schools) and Top 6 in the East by MBA Universe Ranking 2020, along with IIM – Calcutta, XLRI – Jamshedpur, IIM – Sambalpur, KIIT – Bhubaneshwar and IMI – Bhubaneshwar.</li>
                                    <li>Globsyn Business School has been ranked among the Top 5 B-Schools in Eastern India as per Business India’s Best B-Schools Survey 2019 with IIM – Calcutta, XLRI – Jamshedpur, Vinod Gupta School of Management – IIT-Kharagpur and XIM – Bhubaneshwar.</li>
                                    <li>Globsyn Business School has been featured as ‘College of the Year, 2019’ for PGDM (Marketing) by Siliconindia magazine.</li>
                                 <li>Globsyn Business School has been ranked among the Top 5 B-Schools in Eastern India as per Business India’s Best B-Schools Survey 2017.</li>
                                 <li>Globsyn Business School has been ranked among the Top 5 B-Schools in Eastern India as per Business India’s Best B-Schools Survey 2016 with IIM – Calcutta, XLRI – Jamshedpur, XIM – Bhubaneshwar, and Vinod Gupta School of Management – IIT-Kharagpur.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
