import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function TipsToAce() {
    return (
        <><title>7 Tips to ace Campus Placement</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">7 Tips to ace Campus Placement</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">7 Tips to ace Campus Placement</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/campus_placement_Blog.jpg" alt="campus_placement" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Campus Placements are the corridors through which students walk into the corporate world. Securing the first job is every student’s dream, and campus placements are the platforms which make that dream come true. It opens up a lot of opportunities for the students as well as it paves the road for a rewarding career. However approaching this crucial juncture in a systematic manner is very important.</p>
                                <p>Let us take a look at some of the factors required to land up with a dream job:</p>
                                <p><b>Ace up your Résumé</b></p>
                                <p>The first and foremost thing you need to do while preparing for your placement drive is to make your résumé look job-friendly. Remember, the first impression you will create upon your future employers will be through your résumé, so do your research well before you start building up the content. Understand the difference between a ‘Curriculum Vitae’ and ‘Résumé’, before you caption your ‘bio’. While making sure that your résumé looks impressive by highlighting your strengths, extracurricular activities, projects, internships and trainings, remember to properly format your résumé by using an uncluttered layout, avoiding fancy fonts &amp; colours, and maximising impression by strategic use of words.</p>
                                <p><b>Strengthen your Knowledge Foundation</b></p>
                                <p>While you are preparing for your interview, the most important thing you should not miss is the subject knowledge of courses you have studied in your master’s and graduation degree courses. Most candidates tend to ignore this, but if you knowledge foundation is not strong enough then all your efforts of impressing your interviewers might go in vain.</p>
                                <p><b>Play up your USP</b></p>
                                <p>Building one’s skills is the best option to stand out of the competition where thousands of students are preparing for the same job. So, the best possible way is to have something that others don’t have and add it to your resume. For instance, students with IT background can develop an app or any software and non-IT students can present research papers or can build some other models.</p>
                                <p>Prompt your Aptitude Skill: The Aptitude Test is the first round of elimination, which most students fear. However, with some practice you will slowly become more confident of cracking the aptitude questions. The thumb-rule for approaching these types of tests is to complete the easier questions and then coming back to the tougher ones. This way not only do you not waste time, but your self-confidence also does not take a beating.</p>
                                <p><b>Enhance your Communication Skills</b></p>
                                <p>
                                Due to the colonial hangover, there is still a great demand in the Indian job market scenario for candidates who can proficiently communicate in English. A lot of eligible candidates fail to make a mark because they don’t pay attention to enhancing their communication skills, even-though their other skills are at par. Communicating with your peers in English, reading English newspapers, watching English movies are some of the ways you can brush up your communication skills in this language.
                                </p>
                                <p><b>Prepare for Technical Interview</b></p>
                                <p>Getting good command over your technical knowledge or subject knowledge will help you to crack your technical interview. So, practice your coding skills or your other technical skills, and impress the interview panel with your technical skills.</p>
                                <p>In AVOD model, OTT services are monetized through advertisements, one of the most common strategies among OTT players. YouTube has adopted this strategy quite successfully. On the other hand, TVOD model is based on a pay-per-view, a la carte OTT content, like Google play movies/ YouTube rental video or movie.</p>
                                <p>To retain viewers and insist on more transactions, TVOD model rely on selective and captivating viewing experiences. It is something like a fine-dining experience; you will only get whatever you order. SVOD services on the other hand, are antithetical to TVOD services in the sense that they are easy to sign up for and easy to opt out from. The providers of SVOD are continually challenged with retaining consumers, by providing exclusive new content, aggressive pricing schemes – and probably both.</p>
                                <p><b>Do not underestimate the HR Round</b></p>
                                <p>Most people think that if they have qualified in the aptitude and technical rounds, then clearing the HR interview will be a cakewalk. Most candidates, by this point, is filled with the over confidence of securing the job, as they have cleared the ‘tougher’ rounds. And this is where they err, make a mistake and get rejected.</p>
                                <p>With more than 80 corporates visiting the Campus every year to hire the pupils, Globsyn Business School (GBS) have built more than 360 successful corporate relations over the years. With the objective to usher its students into a successful career, GBS have designed the Employability Enhancement and Placement Mentoring (EEPM) sessions, which train the young managers to be employable and placement-friendly by equipping them with the confidence to crack job interviews, meet challenges posed by recruiters in various elimination rounds and exhibit skills as expected by the recruiters.</p>
                                <p>So, now when you have the checklist to get that first job out of campus, prepare yourself for it and remember that to realise your dreams, you need courage and bit of strategic preparations.</p>

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
