import React, { useState } from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'
import { getUsers, getLength } from '../BlogsApi/Users'
import DataTable from '../BlogsComponents/DataTable'
import Pnext from '../BlogsComponents/Pnext'

export default function Blog() {
    const [page, setPage] = useState(1)
    const [limit] = useState(10)

    let totalPage = Math.ceil(getLength() / limit)
    function handelPageChange(value) {
        if (value === "&laquo;" || value === "...") {
            setPage(1);
        }
        else if (value === "&lsaquo;") {
            if (page !== 1) {
                setPage(page - 1);
            }
        }
        else if (value === "&rsaquo;") {
            if (page !== totalPage) {
                setPage(page + 1);
            }
        }
        else if (value === "&raquo;" || value === "...") {
            setPage(totalPage);
        }
        else {
            setPage(value);
        }
    }
    return (
        <><title>GBS Blog</title>
            <section className="hero-banner-img-media">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Blogs</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Blogs</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <DataTable users={getUsers(page, limit)} />
                           
                            <Pnext totalPage={totalPage} page={page} limit={limit} siblings={1} onPageChange={handelPageChange} />
                            
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
