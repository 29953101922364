import React from 'react'
import { Link } from 'react-router-dom'

export default function DataTable(props) {
    return (
        <>
            {props.users.map(user=> (
                <div className='row' style={{padding:"10px"}} key={user.id}>
                <div className="one-table">
                    <div className="one-table1">
                    <Link to={user.url}>  <img
                            className="img-fluid"
                            src={user.thumb}
                            alt="Blog"
                            style={{width:"150px",height:"100px"}}
                        /></Link>
                    </div>
                    <div className="one-table2" >
                        <span className='h6'><Link to={user.url}>{user.btitle}</Link></span>            
                        <br/>
                        <span style={{margin:"6px 0"}}>{user.pdate}</span>
                        <br/>
                        <p className='p-0'> {user.des}</p> 
                    </div>
                </div>
                </div>
            ))}
        </>
    )
}