import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function Conferance() {
    return (
        <><title>Globsyn Management Conference - Globsyn</title>
            <section className="hero-banner-img-seminer">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Globsyn Management Conference</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Globsyn Management Conference</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p style={{ textAlign: 'center' }}><iframe className="w-100" height="450" src="https://www.youtube.com/embed/CadexMHgOEs" title="Globsyn Management Conference" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></p>
                                <br></br>
                                <p>Globsyn Management Conference – one of the six Beyond Education verticals of Globsyn Business School – is aimed at promoting academic discourse and camaraderie among the faculty of post graduate management education, through its Vision Seminar, Research Seminar and EBSCO enlisted Globsyn Management Journal.</p>
                                <p>This platform is conceptualized for the academic community and witnesses the participation of the best minds of management education and corporates from across the country. This is achieved by presenting conceptually sound and methodologically rigorous seminars, which provide insights and advance knowledge on managerial, business and organisation issues.</p>
                                <br />
                                <br />
                                <div className="accordion" id="myAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">Vision Seminars</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Globsyn Business School has envisaged the Vision Seminar as an annual event to bring together diverse minds touching upon subjects that explore the issues and challenges faced by managers in the new era, create awareness on the implementation of the best practices across organizations, and encourage innovation induced by environmental challenges. These Seminars are organized with an objective to influence and develop young managers by helping them think clearly, giving them a reason to be and making their work more beautiful.</p>
                                                <ul className='disc'>
                                                    <li><strong>Innovation and Entrepreneurship in Management Education</strong></li>
                                                </ul>
                                                <p >Innovation and entrepreneurship have been defined variously which include suggestions of broad overlap as well contentions of nuanced differences. There is however no denying that innovative entrepreneurship and entrepreneurial innovation have been the vanguard of economic development and social progress from time immemorial. The 7th International Vision Seminar 2023, dedicated to the theme ‘Innovation and Entrepreneurship in Management Education’, focused on some aspects of this vast subject, which are of immediate relevance to Academicians, Corporate, Research Scholars, Government Officials, NGOs, Students, and Public in general so that appropriate deliberations may lead to creation of human resource with an entrepreneurial mind-set.</p>
                                                <p><Link to="/ivs2023"><em>More Information…</em></Link></p>
                                                <ul className='disc'>
                                                    <li><strong>ESG to SDG – The Roadmap for a Sustainable World</strong></li>
                                                </ul>
                                                <p >In October 2005, the United Nations Environment Program Initiative in the Freshfields Report coined the acronym ESG – Environmental, Social, and Governance, respectively. In 2015, all United Nations Member States adopted the 2030 Agenda for Sustainable Development that provided a shared blueprint – at the heart of which are the 17 Sustainable Development Goals (SDGs), considered to be an urgent call for action by all countries in a global partnership. The entire gamut of ESG and SDG is obviously overwhelming. The deliberations of the 6th International Vision Seminar 2022, based on the theme ‘ESG to SDG – The Roadmap for a Sustainable World’, will therefore be focused on certain aspects of direct relevance to business and industry.</p>
                                                <p><Link to="/vs2022"><em>More Information…</em></Link></p>
                                                <ul className='disc'>
                                                    <li><strong>Sustainability in the Age of Disruption</strong></li>
                                                </ul>
                                                <p >Although the ongoing worldwide COVID-19 pandemic has caused economic havoc across all business sectors; it has also seen the ability of human mind and intellect to make innovations, and ushering in digital and other technological transformation in every area of human activity. This is precisely the case in point that the International Vision Seminar 2021 based on the theme ‘Sustainability in the Age of Disruption’ attempts to address – identifying critical areas and resolving problems to ensure Sustainability.</p>
                                                <p><a href="https://www.globsyn.edu.in/vs2021/"><em>More Information…</em></a></p>
                                                <ul className='disc'>
                                                    <li><strong>Technology in Management in the Age of Industry 4.0</strong></li>
                                                </ul>
                                                <p>The ‘International Vision Seminar 2020’ on the theme ‘Technology in Management in the Age of Industry 4.0’, was on various emerging technologies that improve productivity and quality not only in manufacturing but across all business processes, in terms of opportunities and vulnerabilities.</p>
                                                <p><a href="https://www.globsyn.edu.in/technology-in-management-in-the-age-of-industry-4-0/"><em>More Information…</em></a></p>
                                                <ul className='disc'>
                                                    <li><strong>Corporate Ethics and Responsible Leadership</strong></li>
                                                </ul>
                                                <p>We are at a time when the grey areas between right and wrong are expanding and individual decisions are ever more subjective. Businesses are fighting to survive and grow against fierce competition, and success in terms of money and fame is harder to come by. Under such circumstances it is may be easy to overlook the essentials of ethics and responsible leadership.</p>
                                                <p><em><a href="https://www.globsyn.edu.in/corporate-ethics-and-responsible-leadership/">More Information…</a></em></p>
                                                <ul className='disc'>
                                                    <li><strong>Women’s Empowerment</strong></li>
                                                </ul>
                                                <p>Women’s Empowerment implies the creation of an environment for women where they can initiate decisions of their own for their respective personal benefits as well as for the society. It results in increased and improved social, economic, political and legal strength of women to ensure their rights in society.</p>
                                                <p><em><a href="https://www.globsyn.edu.in/womens-empowerment/">More Information…</a></em></p>
                                                <ul className='disc'>
                                                    <li><strong>Spirituality in Management Education</strong></li>
                                                </ul>
                                                <p>Happiness Index is a major talking point in today’s world. Globsyn Business School elaborated&nbsp;on the issue of how Spirituality and Happiness Index can bring a positive transition in management education for the youth by hosting the 1<sup>st</sup>&nbsp;Vision Seminar, ‘Spirituality in Management Education’.</p>
                                                <p><em><a href="https://www.globsyn.edu.in/spirituality-in-management-education/">More Information</a>…</em></p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingtwo">
                                            <button type="button" className="accordion-button collapsed " data-bs-toggle="collapse" data-bs-target="#collapsetwo">Research Seminars</button>
                                        </h2>
                                        <div id="collapsetwo" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Over the years, Globsyn Business School has been organising Research Seminars with an objective to expose the students to the corporate world, promote research, provide a forum for exchanging ideas on cutting edge breakthroughs in management practices and motivate the academically inclined young managers of the B-School to pursue research in the future.</p>
                                                <ul className='disc'>
                                                    <li><strong> Emerging Trends across sectors in a Post-pandemic Globalized World</strong></li>
                                                </ul>
                                                <p>The theme for International Research Seminar 2021 was ‘Emerging Trends across sectors in a Post-pandemic Globalized World’. The Seminar had been conceptualized to create an inclusive platform with corporate veterans, academicians, entrepreneurs and students, who deliberated and provided research-oriented findings in terms of the role of integrated management system for business sustainability in the post COVID-19 world. This International Seminar focused on the emerging trends across sectors of economy and deliberated on the importance of strategic resilience and integrated management for business sustainability.</p>
                                                <p><em><a href="https://www.globsyn.edu.in/emerging-trends-across-sectors-in-a-post-pandemic-globalized-world">More Information…</a></em></p>
                                                <ul className='disc'>
                                                    <li><strong>Business Resilience in the post COVID-19 World: Role of Integrated Management</strong></li>
                                                </ul>
                                                <p>The theme for ‘International Research Seminar 2020’ is ‘Business Resilience in the post COVID-19 World: Role of Integrated Management’ has been conceptualized to create an inclusive platform with corporate veterans, academicians, entrepreneurs and students, who will deliberate and provide research-oriented findings in terms of the role of integrated management system for business sustainability in the post COVID-19 world. An Integrated Management System (IMS) integrates all the systems and procedures of an organization into one full structure, allowing an organization to function as a single unit with consolidated goals. Post COVID era would require such an integrated approach to become agile in their businesses.</p>
                                                <p><a href="https://www.globsyn.edu.in/international-conference-on-business-resilience-in-the-post-covid-19-world-role-of-integrated-management/"><em>More Information…</em></a></p>
                                                <ul className='disc'>
                                                    <li><strong>Integrated Management for Business Sustainability in a VUCA World</strong></li>
                                                </ul>
                                                <p>An Integrated Management System (IMS) integrates all the systems and procedures of an organization into one full structure, allowing an organization to function as a single unit with consolidated goals. In an environment, which is overwhelmingly influenced by VUCA (Volatility, Uncertainty, Complexity &amp; Ambiguity), it becomes essential for organizations not only to navigate, but also to manage their strategies to survive first and then grow. Organizations that view and exploit the opportunities and possibilities for change through IMS in the VUCA market conditions will not merely endure, but also successfully compete and grow in the face of the range of emerging adverse and hyper-competitive business conditions.</p>
                                                <p><a href="https://www.globsyn.edu.in/integrated-management-for-business-sustainability-in-a-vuca-world/"><em>More Information…</em></a></p>
                                                <ul className='disc'>
                                                    <li><strong>Business and Sustainable Development</strong></li>
                                                </ul>
                                                <p>Today, on one side, the industry is faced with enormous opportunities poised by the rise in the aspirations and disposable incomes of around 4.5 billion middle-class population spanning BRICS nations; while on the other side we are facing businesses that do not have the potential to become resilient to change.</p>
                                                <p><em><a href="https://www.globsyn.edu.in/business-and-sustainable-development/">More Information…</a></em></p>
                                                <ul className='disc'>
                                                    <li><strong>Changing Business Scenario &amp; Evolving Management Practices</strong></li>
                                                </ul>
                                                <p>As we stand at the juncture of this new millennium, business and industry stands on the sharp edge of double-sided sword. Change is engulfing us like never before; older paradigms are fast outfacing newer ones. Survival of the fittest has long become extinct, and survival of the most innovative is slowly giving way to an emerging paradigm ‘survival of the most adaptable’. At the business forefront, challenges are abound: boundaries between industries are falling leading to convergence, product life-cycles are increasingly becoming shorter, technologies are proliferating in a flat world, innovations disrupting existing industries and a lot more.</p>
                                                <p><a href="https://www.globsyn.edu.in/changing-business-scenario-evolving-management-practices/"><em>More Information…</em></a></p>
                                                <ul className='disc'>
                                                    <li><strong>e-Applicability of Research in Management Decision-Making</strong></li>
                                                </ul>
                                                <p>Businesses today are becoming more complex, faster and less predictable. Environmental challenges have been a conspicuous and persistent driver of innovation in business organizations. The changing environmental challenges have forced many corporate behemoths to realign their business strategies, so that they suit the internal as well as the external environments. Innovation induced by environmental issues not only yields advantages from an ecological standpoint, but also from an economic and strategic point of view. The conference focuses on the changing nature of work which is increasingly knowledge based, cognitively complex, time-pressurized and demands high technological competence. Today’s workplace focuses on team-based collaborative work which has to be undertaken in a far more competitive environment than ever before.</p>
                                                <p><em><a href="https://www.globsyn.edu.in/e-applicability-of-research-in-management-decision-making/">More Information…</a></em></p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingthree">
                                            <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsethree">Globsyn Management Journal</button>
                                        </h2>
                                        <div id="collapsethree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div className="card-body">
                                                <p>Globsyn Management Journal (GMJ) is an EBSCO enlisted annual publication of Globsyn Business School, Kolkata, India. GMJ with ISSN: 0973-9181 is also enlisted in ICI, J-GATE, Pro Quest database, WorldCat and Google Scholar. Its objective is to contribute to a better understanding of organizations and their functioning by presenting conceptually sound and methodologically rigorous papers, which provide insights and advance knowledge on managerial, business and organizational issues.</p>
                                                <p><em><a href="https://www.globsyn.edu.in/globsyn-management-journal/">More Information…</a></em></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
