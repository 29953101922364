import React from 'react'
import Sidebar from '../../Sidebar'
import { Link } from 'react-router-dom'

export default function Serendipity() {
  return (
    <><title>Serendipity - Globsyn</title>
            <section className="hero-banner-img-sceren">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Serendipity</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">Serendipity</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>Conceptualized as a ‘live project’ for students, Serendipity replicates a real organization with dedicated teams for Finance, Marketing, HR, Operations, etc., who are given the task of running a successful business venture of designing and organizing a multi-day large format event. The two-day event is designed with the first day being dedicated to inter-school competitions, whereas on the second day students put together a cultural show followed by dinner, hosted for their parents, faculty and other guests.</p>
                                <p>Over the years ‘Serendipity’ has been a platform where entrepreneurs and intrapreneurs were developed and groomed. With the experience of handling and managing such a multi-dimensional large format event successfully, the students get an edge to be identified as ‘performers’ in the competitive corporate environment.</p>
                                <p style={{ textAlign: 'center' }}><iframe className="w-100" height="450" src="https://www.youtube.com/embed/n2Vag-WPdMk" title="Best Private B-School in Eastern India - Globsyn Business School | Times Brand Icons 2022" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
                                
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
