import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function EvolutionEducation() {
    return (
        <><title>The Evolution of Education Framework from Classroom 1.0 to Classroom 4.0</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">The Evolution of Education Framework from Classroom 1.0 to Classroom 4.0</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">The Evolution of Education Framework from Classroom 1.0 to Classroom 4.0</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">

                                <p>
                                    <img className="img-fluid" src="/assets/images/The_Evolution_of_Education.png" alt="The Evolution of Education Framework" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>In order to understand the aspect of education framework, one needs to first understand the meaning of the word, ‘Framework’. The concept of Framework highlighted by the Canadian Oxford Dictionary happens to act as a significant supporting structure. It is also identified as a basic system. From the standpoint of education, the framework is considered to be the basic supporting structure that acts as the foundation based on which the curriculum and pedagogy are designed and developed. The study of the Evolution of Education Framework needs to be carried out along four basic stages ranging from Classroom 1.0 to Classroom 4.0. Education evolved along these four stages to not only meet the needs of industry but to also generate the right quality of student experience. </p>
                                <h3>What are Frameworks in Education?</h3>
                                <p>Frameworks used in the field of education contribute to providing a cohesive structure constituted by several components that are adaptable to work with varied teaching patterns, content areas, and to changing student needs. Educational frameworks are mainly of three main categories viz. Curriculum Framework, Lesson Planning Framework, and Program Framework. The same are illustrated as follows:</p>
                                <h4>Curriculum Framework </h4>
                                <p>The Curriculum Framework is a set of different standards that helps in specifying the content that is ought to be learned. It thus tends to define the curriculum or what needs to be taught. </p>
                                <h4>Lesson Planning Framework</h4>
                                <p>The Lesson Planning Framework is the framework that is used by teaching communities to organize, plan, and reflect their ideas. Although this framework tends to explore diverse contents, it fails to highlight evidence-based practices for executing the ideas. </p>
                                <h4>Program Framework </h4>
                                <p>The Program Framework not only focuses on what needs to be taught but also on the manner of teaching the same to the desired students. </p>
                                <h3>Higher Education: A journey from 1.0 to 4.0</h3>
                                <h4>What is Education 1.0</h4>
                                <p>Education 1.0 or Classroom 1.0 reflects an authoritarian teaching setting where the student is only a passive recipient of the concepts or ideas taught by the respective teacher within a physical classroom setting. It forbids the use of any type of technological application. The teacher acts as a knowledge leader and determines the most significant topics for the student to learn without having any regard in understanding the learning interests and needs of students. </p>
                                <h4>What is Education 2.0</h4>
                                <p>The Education 2.0 or Classroom 2.0 framework reflects the growth of communication and teacher-student interactivity within the physical classroom setting. Here, the involvement of technology for sharing learning resources can be effectively observed in terms of using broadcasting mediums like television and radios for designing and communicating learning programs. Likewise, the use of a projector in class is also observed for the rendering of learning materials via slides. </p>
                                <h4>What is Education 3.0</h4>
                                <p>The Education 3.0 or Classroom 3.0 framework is essentially student-centric where the teacher acts as a facilitator and advisor. The Education 3.0 setting involves a blend of both classroom and online-based teaching practices. This type of teaching framework is driven by factors like self-directed learning, problem-solving activities, needed creativity, and potential interactivity between the teachers and students. </p>
                                <p>Information Technology (IT) is extensively used in the teaching process for the development of approaches like blended learning, flipped classrooms, and also the development of e-learning platforms. Learning resources that can be accessed by the students based on their convenience are shared by the teachers online. Though both blended and flipped learning approaches reflect a combination of both physical and online learning and instructional modes yet the latter involves greater interactivity between the student and teacher. </p>
                                <h4>What is Education 4.0</h4>
                                <p>Education 4.0 or Classroom 4.0 accounts is regarded as the most innovative platform that is noted in terms of the Evolution of the Education Framework. The framework of Education 4.0 is still in its developmental stage that focuses on enabling students to carry out learning irrespective of time and place. Education 4.0 framework encourages the carrying out of project-based and practical learning approaches wherein the students are evaluated rather than being examined as done in the traditional frameworks. </p>
                                <p>The use of social networking platforms like YouTube for the development of learning videos; use of Zoom platforms for conducting webinars involving teachers, students, and experts coming from diverse industries; use of Artificial Intelligence (AI) and Chatbots for generating instantaneous responses to students are some effective examples of the Education 4.0 in process.</p>
                                <h3>Current Day Applications of Education 3.0 and 4.0</h3>
                                <p>The current-day applications of Education 3.0 and 4.0 can be best understood based on some case studies.</p>
                                <h4>Case of UEM Jaipur</h4>
                                <p>University of Engineering and Management (UEM) Jaipur has rightly incorporated the use of ICT in its learning framework. The institute has enabled the access of 10,000+ online courses to the students based on their convenience for meeting their learning needs. Further, YouTube is also used by the institution for uploading day-to-day lectures rendered by respective teachers for meeting the objectives of the flipped classroom. </p>
                                <h4>Case of Globsyn Business School</h4>
                                <p>Globsyn Business School (GBS) located in Kolkata is one such B-School that renders focuses on involving technological applications in its learning framework. Over the last two decades, GBS has uniquely positioned itself as India’s only ‘Corporate B-School’. </p>
                                <p>GBS has effectively integrated the Zoom platform for delivering learning resources to the students. With the use of Zoom Cloud Meetings, students and teachers can effectively interact irrespective of the parameters of time and place. Further, the practices of blended learning and flipped learning are also pursued by the B-School based on the integration of both physical classroom and online learning. The above approaches have not only enhanced the learning experience of students but also contributed to increasing the level of interactivity between the key stakeholders. </p>
                                <p>The Evolution of Education Framework thus has come a long way from Classroom 1.0 to Classroom 4.0. The digitalization of the learning environment has made it increasingly responsive, interactive, and adaptive in meeting the emergent needs of students across the globe. </p>

                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
