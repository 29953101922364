import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function PracticeMantra() {
    return (
        <><title>Best Practice Mantra</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Best Practice Mantra</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Best Practice Mantra</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Best-Practice-Mantra.png" alt="Best-Practice-Mantra" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Prof. Manas Chakravarty, teaches Human Resource Management at Globsyn Business School – one of the best Business Schools in Kolkata. Prof. Chakravarty moved to academics for over 17 years now following a distinguished career of 25 years in the industry culminating in leadership roles. Prior to joining the faculty team at Globsyn Business School, he was the Associate Dean at ICFAI, Hyderabad and Dean at United World School of Business, Ahmedabad. In addition to administrative and professional responsibilities, Prof. Chakravarty has delivered executive learning programs at leading corporate houses across the country and has been the program director of national and international conferences. Apart from academic writing, he also has a blog page in the Economic Times. Persuasive and articulate, Prof. Chakravarty enjoys working with individuals and groups engaged in intellectual pursuit.
                                    </em>
                                </p>
                                <p>Much of the progress in human civilization over the centuries has come about due to the quest of how to do things better and what better things to do. Individuals and institutions have been incessantly looking around to discover what is better and identifying the best that is worth emulating. This age-old endeavor has in current times acquired a label: Best Practices.</p>
                                <p>In the context of policy analysis, Eugene Bardach of Goldman School of Public Policy, UC Berkely provides an eightfold framework. The Bardach prescription fits the process that needs to be followed for identifying and adopting a best practice in any domain, not only public policy. The eight steps are: (1) define the problem, (2) assemble evidence, (3) construct alternatives, (4) select criteria, (5) project outcomes, (6) confront trade-offs, (7) decide, and (8) tell your story. All these eight steps must be diligently followed to identify and adopt a best practice towards making a meaningful difference.</p>
                                <p>Large organizations with multiple units may have a sufficiently vast arena to be able to go through all the eight steps suggested above. However, a small operational unit would find it difficult to singularly undertake the entire exercise, particularly the steps relating to assembling evidence and constructing alternatives. Therefore, those bodies that have bonafide access to information from several organizations engaged in similar work and also have the capacity to analyze and evaluate them are majorly involved with identifying and promoting best practices. For example, the NITI Ayog in partnership with UNDP has published a document containing 75 “best practices in the social sector” to commemorate 75 years of the country’s independence.</p>
                                <p style={{ textAlign: "center" }}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/58mUeHbagkA" title="AICTE Recognises"></iframe>
                                </p>
                                <p>Another example is AICTE recognizing the ‘Care for Society’ initiative undertaken by students of Globsyn Business School (GBS) as a best practice. The ‘Care for Society’ initiative provides an opportunity to students to contribute towards the betterment of society as well as develop themselves as dedicated and compassionate managers and better human beings. Throughout the year, students of the B-School extend themselves towards building equity in society and bringing a new social order.</p>
                                <p style={{ textAlign: "center" }}>
                                    <img className="img-fluid" src="/assets/images/Globsyn-Business-School-recognized-as-an-AACSB-International-2021-Innovation-That-Inspires-768x402.png" alt="AACSB-International-2021-Innovation-That-Inspires" />
                                    <br/>
                                    <em>Globsyn Business School recognized as an AACSB International 2021 Innovation That Inspires</em>
                                </p>
                                <p>‘Embryon’ – the entrepreneurship cell of GBS, another best practice of the B-School, is aimed at encouraging entrepreneurship among students and creating a platform to foster tomorrow’s entrepreneurs and intrapreneurs for bigger challenges that lie ahead. Recently ‘Embryon’ has been recognized among 24 business schools, globally, as an AACSB International 2021 Innovation That Inspires. This annual program recognizes institutions from around the world that serve as champions of change in the business education landscape.</p>
                                <p>Bardach whose eight steps for identifying a best practice has been mentioned above also cautions that such rigorous evaluation is rarely done and what is proclaimed as a best practice may only be a good thing to do in a given circumstance. Michael Quinn Patton, former president of the American Evaluation Association, goes a step further preferring the use of terms such as “better”, “effective” or “promising” when describing practices worth emulation by others.</p>
                                <p>Creating or identifying and adopting a best practice cannot be a one-time activity; it is eternal work in progress. While searching for newer practices that may have emerged as superior, committed effort must also be put to improve upon the same. The words of St. Jerome provides the mantra: Good, better, best. Never let it rest.</p>
                                <p>
                                    Good, better, best. Never let it rest.<br />
                                    ‘Till your good is better, and your better is best.
                                </p>
                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Manas_jpg124648.jpg"
                                            alt="Manas"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Prof. Manas Chakravarty
                                            <br />
                                            Faculty – Human Resource Management
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
