import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function LiberalArt() {
    return (
        <><title>Management as Liberal Art</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Management as Liberal Art</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog">Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="">Management as Liberal Art</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/Management-as-Liberal-Art.png" alt="Management-as-Liberal-Art" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p style={{ border: "1px solid #ffc600", padding: "10px" }}>
                                    <b>Editor’s Note</b>
                                    <br />
                                    <em>
                                        Prof. Manas Chakravarty, teaches Human Resource Management at Globsyn Business School – one of the best B Schools in Kolkata. Prof. Chakravarty moved to academics following a distinguished career in the industry culminating in leadership roles. Prior to joining the faculty team at Globsyn Business School, he was the Associate Dean at ICFAI, Hyderabad and Dean at Unitedworld School of Business, Karnavati University, Gandhinagar. In addition to administrative and professorial responsibilities, Prof. Chakravarty has delivered executive learning programs at leading corporate houses across the country and has been the program director of national and international conferences. Apart from academic writing, he also has a blog page in the Economic Times.
                                    </em>
                                </p>
                                <p>Liberal arts have traditionally been the fountainhead of all education.  Over the ages though, due to a combination of socio-economic factors, liberal arts suffered neglect.  It is therefore heartening that in recent times there is again a growing emphasis all over the world on the pivotal role of liberal arts in the academic curricula.</p>
                                <div className="one-table3"><img className="img-fluid" src="/assets/images/Liberal-Art-241x300.jpg" alt="" sizes="(max-width: 358px) 100vw, 358px" width="200" height="250" />
                                </div>
                                <div className="one-table2">
                                    <p>In the expression “liberal arts” the word “arts” connotes skills or branches of knowledge that can be learnt and put to practice rather than just the “fine arts” like painting or sculptor.  The prefix “liberal” signifies that the arts are pursued freely for the sake of knowledge and wisdom, without becoming subservient to the particular needs of a specific profession or vocation. In occidental classical antiquity, septem artes liberales (seven liberal arts) comprised the quadrivium of astronomy, arithmetic, geometry and music and the trivium of rhetoric, grammar and logic. In modern times the liberal arts may be considered to encompass all fields in the areas of humanities, social sciences, natural and formal sciences.</p>
                                    <p>Historically, liberal arts education was considered essential for success in any walk of life.  One of the most successful rulers of all times, Alexander the Great of Macedonia had been tutored in the liberal arts by Aristotle who in turn had been taught by Plato and Plato by Socrates. A pictorial representation of liberal arts education shown in the 12th Century work Hortus Deliciarum (Garden of Delight) by Herrad of Landsberg depicts Plato and Socrates seated at the feet of Philosophy which occupies the throne. Philosophy has been shown as the reigning figure because in Greek the word philosophia means love of wisdom.  Three heads seen emerging from the crown of Philosophy are: Ethics, Logic and Physics. From the heart of Philosophy emerge seven springs: Grammar, Rhetoric, Dialectic, Music, Arithmetic, Geometry and Astronomy.  Also surrounding Philosophy are angelic representations of the seven liberal arts.</p>
                                </div>
                                <p>The ancient Indian tradition in education also emphasized liberal arts.  Kadambari composed in the 7th century by Banabhatta, considers education to comprise 64 kalaa.  The National Education Policy framed in 2020 cites the tradition of multidisciplinary learning that were in vogue at Takshashila and Nalanda and calls for bringing back the liberal arts into Indian education because that is “exactly the kind of education that will be required for the 21st century.”</p>
                                <p>From the age of Socrates to current times, the structure of formal education has undergone drastic changes though the high status of philosophy continues to be recognized albeit symbolically in as much as the highest academic qualification granted by universities remains “doctor of philosophy” regardless of academic disciplines.  After losing ground to vocational and professional programmes over a long period of time, the importance of liberal arts education has again found traction.  Presently, the trend in many great universities is to allow students build an inter-disciplinary bouquet of courses so that they gain liberal academic exposure of their choice.  Some leading institutions have also positioned themselves as a liberal arts college or have inter-disciplinary programmes on offer.  The BASc programme of the University College of London is a case in point.  The programme involves core courses that enable students acquire skills to work across multiple disciplines, specialization courses via one major and one minor pathway, and a foreign language course.  The programme has four specialization pathways:  Arts (Anthropology, Creative Writing, Creative Studies, Design and Visualisation, Drawing Methodology, Film and Documentary Film Making, History, History of Art, Languages, Literature, Philosophy); Social Sciences (Archaeology, Economics, Geography, History, International Relations, Law, Management, Philosophy, Political Economy, Politics, Sociology);  Health and Environment (Anthropology, Biochemistry, Biological Sciences, Biomedicine, Environmental Sciences, Geography, Geology, Neuroscience, Pharmacology, Psychology); Sciences and Engineering (Chemistry, Computer Science, Earth Sciences, Economics, Engineering, Mathematics, Physics).</p>

                                <p>Ideally, all students pursuing tertiary education should acquire a strong foundation in liberal arts during the undergraduate years before progressing to post-graduate level or venturing into vocational or professional studies.  Of course, even at the Master’s or Doctoral levels, there could be programmes designated specifically as liberal arts.   For example, the Georgetown University offers a programme leading to Doctor of Liberal Studies which has four foundation courses designed for mastery of interdisciplinary approach to research, argumentation, values reflection, and oral and written communication.  An example closer home is the programme for MA in Liberal Arts at IIT Guwahati where core courses include the areas of political theory, culture, literary studies, linguistics, historical studies, debates in philosophy, digital humanities, and international relations.</p>
                                <p>
                                    Liberal arts education has special significance for business managers and management students.  The book New Realities by Peter Ducker published way back in 1989 has a chapter titled “Management as Social Function and Liberal Art” where he says:
                                </p>
                                <p style={{ paddingLeft: "40px" }}><em>Management is thus what tradition used to call a liberal art – “liberal” because it deals with the fundamentals of knowledge, self-knowledge, wisdom and leadership; “art” because it is practice and application.  Managers draw on all the knowledges and insights of the humanities and the social sciences – on psychology and philosophy, on economics and history, on the physical sciences and ethics.  But they have to focus this knowledge on effectiveness and results – on healing a sick patient, teaching a student, building a bridge, designing and selling a “user-friendly” software program. </em></p>
                                <p>Though the idea of management as a liberal art was propounded by Drucker, coining of the term MLA is credited to Joseph Maciariello following his (co-authored) book Drucker’s Lost Art of Management. William Cohen, another authority on Drucker, while discussing his new (co-authored) book Peter Drucker’s Most Important New Reality, talks about the beginning of a major management revolution with the idea of MLA.</p>
                                <p>Starting 2024, Globsyn Business School has introduced MLA as a course.  The objectives of the course are to sensitize students on the relevance of liberal arts and to provide actionable cues so that interest in those lines may be cultivated beyond the academia.  As learning outcomes, students on successful completion of the course would be able to relate apparently disparate forms of knowledge when dealing with a given issue and demonstrate the ability to engage with topics beyond the immediate realm of business management.  By rolling out this course, Globsyn Business School makes its own contribution to the MLA revolution.</p>

                                <div className="one-table">
                                    <div className="one-table1">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/Manas_jpg124648.jpg"
                                            alt="Manas"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="one-table2">
                                        <p>
                                            Prof. Manas Chakravarty
                                            <br />
                                            Faculty – Human Resource Management
                                            <br />
                                            Globsyn Business School
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
