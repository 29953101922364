import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function FinancialAid() {
    return (
        <>
            <title>Financial Aid - Globsyn</title>
            <section className="hero-banner-img-teaching">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Financial Aid</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation"> Financial Aid</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>The fundamental aim of Education is to enable students develop a perspective of their basic capacities, boost their confidence and enhance their knowledge bank. It hoists their creativity, productivity and advances them technologically in securing social progress and economic distribution.</p>
                                <p>Acknowledging the importance of education for all, Globsyn Business School is committed to not only recognize the excellence achieved by its students, but also provide support and opportunities in their pursuit of various post graduate programmes.</p>
                                <p>As a part of this initiative – to provide seamless education facilities for meritorious enthusiasts and help them excel in their pursuit of education – students of GBS, over the years, have availed financial aid from these intuitions: State Bank of India, ICICI Bank, Axis Bank, Central Bank of India, Punjab National Bank, Bank of India, HDFC Bank, Union Bank of India, UCO Bank, Indian Bank, Canara Bank, West Bengal Student Credit Card Scheme, HDFC Credila, amongst others.</p>
                                <div style={{textAlign:"center"}}>
                                <img src="./assets/images/Logo-Panel.png" alt="logo-panel" />
                                </div>
                                <br/>
                                <i>For details, please contact:</i>
                                <br/>
                                <br/>
                                <p><strong>Education Loan Department</strong><br />
                                    Contact: (033) 4000 3600, +91 82740 04556<br />
                                    Email: <a href="mailto:gbsloan@globsyn.edu.in"><u>gbsloan@globsyn.edu.in</u></a></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
