import React from 'react'
import './Common.css'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar'

export default function MediaCoverage() {
    return (
        <>
            <title>Media Coverage|Globsyn</title>
            <section className="hero-banner-img-media">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Media Coverage</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="#">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="#">Media Coverage</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>

                            </div>
                            <div className="one-table">
                                <div className="one-table2">
                                    <ul className='disc'>
                                        <li><a href="https://www.telegraphindia.com/india/watch-rahul-dasgupta-on-keeping-up-with-family-legacy-and-spearheading-globsyn-business-school/cid/1934287" target="_blank" rel="noreferrer">Rahul Dasgupta on keeping up with family legacy and spearheading Globsyn Business School – (The Telegraph, May 4, 2023)</a></li>
                                        <li><a href="https://www.telegraphindia.com/edugraph/campus/globsyn-business-school-holds-19th-annual-convocation-for-the-students-of-batch-2020-22-photogallery/cid/1934039?slide=1" target="_blank" rel="noreferrer">Globsyn Business School holds 19th Annual Convocation for the students of Batch 2020-22 – (The Telegraph, May 3, 2023)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/09/Best-Private-B-School-in-Eastern-India.jpg" target="_blank" rel="noreferrer">A B-school that stays a step ahead – (The Times of India, December 24, 2022)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/09/ET-Post-for-Media-26-2022.png" target="_blank" rel="noreferrer">A glorious legacy translated into the finest management institute in the country – (The Economic Times, September 26, 2022)</a></li>
                                        <li><a href="https://www.telegraphindia.com/edugraph/campus/going-beyond-education-a-chat-with-globsyn-business-school-founder-bikram-dasgupta/cid/1887909" target="_blank" rel="noreferrer">Going ‘Beyond Education’: A chat with Globsyn Business School Founder Bikram Dasgupta – (The Telegraph, September 21, 2022)</a></li>
                                        <li><a href="https://www.anandabazar.com/brand-studio/bikram-dasgupta-in-conversation-with-anandabazar-and-edugraph-dgtl/cid/1371358" target="_blank" rel="noreferrer">The Sky is the Aim  An exclusive interview with Bikram Dasgupta – (Anandabazar Patrika, September 21, 2022)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/09/Young-Entrepreneur-in-the-field-of-Education.png" target="_blank" rel="noreferrer">Rahul Dasgupta, Director and Trustee, Globsyn Business School was honored with the ‘Young Entrepreneur in the field of Education’ award at the ET Industry Leaders 2022, organized by The Times Group – (The Times of India, September 13, 2022)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/09/Best-Globsyn-Business-School.png" target="_blank" rel="noreferrer">Globsyn Business School was recognized as ‘Best Private Business School – West Bengal’ at the ET Industry Leaders 2022, organized by The Times Group – (The Times of India, September 13, 2022)</a></li>
                                        <li><a href="https://www.telegraphindia.com/india/gbs-records-highest-enrolment-for-its-2022-24-inducted-batch/cid/1883992" target="_blank" rel="noreferrer">GBS records Highest Enrolment for its 2022-24 Inducted Batch – (The Telegraph, September 2, 2022)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/08/Image_Revised_01010101.png" target="_blank" rel="noopener noreferrer">Manpower Development – (The Economic Times, August 25, 2022)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/06/TOI-Article_June-6-2022-611x1024.jpg" target="_blank" rel="noopener noreferrer">Biz leader uplifts students with global education – (The Times of India, June 06, 2022)</a></li>
                                        <li><a href="https://www.telegraphindia.com/edugraph/news/globsyn-business-school-hosts-18th-convocation-ceremony/cid/1866829" target="_blank" rel="noopener noreferrer">Globsyn Business School hosts 18th convocation ceremony – (The Telegraph, May 25, 2022)</a></li>
                                        <li><a href="https://www.anandabazar.com/india/globsyn-business-schools-18-th-convocation-ceremony-dgtl/cid/1346471" target="_blank" rel="noopener noreferrer">The 18th Annual Convocation of Globsyn Business School was held amidst much grandeur – (Anandabazar Patrika, May 25, 2022)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/05/bengali-media.jpeg" target="_blank" rel="noopener noreferrer">Globsyn Business School completes 20 years in Postgraduate Management Education – (May 25, 2022, The Statesman)</a></li>
                                        <li><a href="https://www.indiablooms.com/life-details/E/6529/globsyn-business-school-aims-to-make-the-hybrid-learning-model-more-immersive-for-students.html" target="_blank" rel="noopener noreferrer">Globsyn Business School aims to make the hybrid learning model more immersive for students (May 23, 2022, India Blooms)</a></li>
                                        <li><a href="https://www.facebook.com/AnandabazarSocial/posts/388168986683803" target="_blank" rel="noopener noreferrer">Globsyn Business School celebrates 18th Convocation Ceremony – (May 23, 2022, Anandabazar Patrika, Facebook Page)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2022/05/index.jpg" target="_blank" rel="noopener noreferrer">Students should write the story of their own lives – (May23, 2022, Prabhat Khabar)</a></li>
                                        <li><a href="https://timesofindia.indiatimes.com/life-style/spotlight/globsyn-business-school-a-legacy-continuum-of-accomplishments/articleshow/90440692.cms" target="_blank" rel="noopener noreferrer">Globsyn Business School: A Legacy continuum of accomplishments – (The Times of India, March 26, 2022)</a></li>
                                        <li><a href="https://m.facebook.com/CalcuttaTimesOfficial/posts/glimpses-of-a-memorable-evening-as-globsyn-business-school-celebrated-20-years-o/5019554041493289/" target="_blank" rel="noopener noreferrer">Glimpses of a memorable evening – (March 16, 2022, Calcutta Times, Facebook Page)</a></li>
                                        <li><a href="https://www.timesnownews.com/business-economy/companies/article/times-brand-icons-2021-east-recognizing-the-contribution-of-entrepreneurs-and-artists-to-the-society/846932" target="_blank" rel="noopener noreferrer">Times Brand Icons 2021 (East): Recognizing the contribution of entrepreneurs and artists to the society – (TimesNow News, Jan 06, 2022)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2021/12/GBS-TBI-FB.png" target="_blank" rel="noopener noreferrer">A B-School that strives hard to remain globally relevant and sustainable – (The Times of India, Dec 18, 2021)</a></li>
                                        <li><a href="https://www.google.com/amp/s/m.economictimes.com/industry/services/education/globsyn-business-school-plans-to-use-augmented-reality-to-enhance-its-hybrid-delivery-model/amp_articleshow/88068603.cms" target="_blank" rel="noopener noreferrer">Globsyn Business School plans to use Augmented Reality to enhance its hybrid delivery model – (The Economics Times, Dec 3, 2021)<br />
                                        </a></li>
                                        <li><a href="https://timesofindia.indiatimes.com/spotlight/the-future-of-learning-globsyn-business-school/articleshow/82744130.cms" target="_blank" rel="noopener noreferrer">The future of learning: Globsyn Business School – (The Times of India, May 20, 2021)<br />
                                        </a></li>
                                        <li><a href="https://economictimes.indiatimes.com/industry/services/education/in-conversation-with-generational-entrepreneurs-of-globsyn-the-dasguptas-who-followed-a-structured-passion/articleshow/78566247.cms" target="_blank" rel="noopener noreferrer">In conversation with ‘Generational’ Entrepreneurs of Globsyn: The Dasguptas who followed a ‘Structured Passion’ – (The Economic Times, Oct 9, 2020)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2020/08/news-papper_19_08_2020-.png" target="_blank" rel="noopener noreferrer">Blended learning for students to provide better career paths  (The Times of India, Aug 19, 2020)</a></li>
                                        <li><a href="https://www.telegraphindia.com/education/click-for-internship-during-the-lockdown-a-virtual-internship-whether-paid-or-unpaid-will-add-value-to-your-resume/cid/1786218?fbclid=IwAR2RU38dVr_Ol4Jw99piZgbQxs4hjfCf77f5x4I3HYmkasf_I435Ms4gMpE" target="_blank" rel="noopener noreferrer">Click for internship  (The Telegraph, July 13, 2020)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2020/07/news-papper-post-img.png" target="_blank" rel="noopener noreferrer">The Long Wait  (The Telegraph, June 2, 2020)</a></li>
                                        <li><a href="https://digitallearning.eletsonline.com/2020/05/educators-artificial-intelligence-can-be-substitute-not-replacement/" target="_blank" rel="noopener noreferrer">Elets Exclusive | Educators: Artificial Intelligence can be substitute not replacement – (Eletsonline.com, May, 2020)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2020/02/f5bee495-2602-4994-b335-cc609b76ddc5.jpg" target="_blank" rel="noopener noreferrer">Placement Galore  (The Statesman, Feb 2020)</a></li>
                                        <li><a href="https://www.thehighereducationreview.com/news/national-scholarship-program-announced-by-globsyn-business-school-worth-inr-5000000-in-multiple-categories-nid-1198.html" target="_blank" rel="noopener noreferrer">National Scholarship Program announced by Globsyn Business School worth INR 5,000,000 in multiple categories – (Higher Education Review, Dec 2019)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2019/12/Article-on-Anand-Utsav1.png" target="_blank" rel="noopener noreferrer">Carnival for Underprivileged Kids  (The Times of India, Nov 2019)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2019/11/5096ee3b-78d1-49ba-a1e2-278c32e5c43a-1.jpg" target="_blank" rel="noopener noreferrer">Encouraging Your Minds  (The Statesman, Nov 2019)</a></li>
                                        <li><a href="https://www.business-standard.com/article/news-ani/globsyn-business-school-successfully-hosts-kalyani-ananda-utsav-2019-119111101147_1.html" target="_blank" rel="noopener noreferrer">Globsyn Business School Successfully Hosts Kalyani Ananda Utsav 2019  (Business Standard, Nov 2019)</a></li>
                                        <li><a href="http://www.uniindia.com/aicte-recognises-care-for-society-initiative-of-globsyn-business-school-as-a-best-practices-nationally-by-approved-institutions-in-india/newsvoir/news/1699741.html" target="_blank" rel="noopener noreferrer">AICTE Recognises ‘Care for Society’ Initiative of Globsyn Business School as a ‘Best Practices’ Nationally by Approved Institutions in India  (United News of India, Aug 2019)</a></li>
                                        <li><a href="https://www.globsyn.com/wp-content/uploads/2019/03/TOI-education-supplement.jpeg" target="_blank" rel="noopener noreferrer">GBS, MUST tie up for biz course  (Times of India, Mar 2019)</a></li>
                                        <li><a href="http://bartamanpatrika.com/detailNews.php?cID=19nID=154184" target="_blank" rel="nofollow noopener noreferrer">Globsyn Business School organizes Vision seminar on Corporate Ethics Responsible Leadership  (Bartaman Patrika, Mar 2019)</a></li>
                                        <li><a href="https://www.thestatesman.com/books-education/globsyn-business-school-conducted-its-15th-annual-convocation-at-the-park-kolkata-1502733230.html" target="_blank" rel="noopener noreferrer">Globsyn Business School conducted its 15th Annual Convocation at The Park, Kolkata  (The Statesman, Feb 2019)</a></li>
                                        <li><a href="https://www.business-standard.com/article/news-ani/gbs-becomes-first-b-school-in-india-to-adopt-blockchain-for-certification-119021101061_1.html" target="_blank" rel="noopener noreferrer">GBS becomes first B-School in India to adopt blockchain for certification  (Business Standard, Feb 2019)</a></li>
                                        <li><a href="https://www.business-standard.com/article/news-ani/globsyn-business-school-places-100-students-within-forty-five-days-118121700843_1.html" target="_blank" rel="noopener noreferrer">Globsyn Business School places 100 students within forty five days  (Business Standard, Dec 2018)</a></li>
                                        <li><a href="https://www.globsyn.com/wp-content/uploads/2018/11/Dainik-Jagran_9.11.2018.jpg" target="_blank" rel="noopener noreferrer">Work stress among IT Employees  (Dainik Jagaran, Nov 2018)</a></li>
                                        <li><a href="https://www.globsyn.edu.in/wp-content/uploads/2020/07/Brainfeed-Higher-Education_Sept-2018.pdf" target="_blank" rel="noopener noreferrer">What are Recruiters looking for in the MBA Graduates these days?  (Brainfeed Higher Education, Sept 2018)</a></li>
                                        <li><a href="https://www.globsyn.com/wp-content/uploads/2018/11/Article-in-Knowledge-Review-Magazine.jpg" target="_blank" rel="noopener noreferrer">Why the entry of Blockchain in the education sector is a breakthrough and a necessity for the future considering its many benefits?  (Knowledge Review Magazine, Aug 2018)</a></li>
                                        <li><a href="https://www.stoodnt.com/blog/management-education/" target="_blank" rel="noopener noreferrer">Interview with Rahul Dasgupta, Trustee, Globsyn Knowledge Foundation on Management Education  (Stoodnt, Aug 2018)</a></li>
                                        <li><a href="https://www.stoodnt.com/blog/things-to-be-considered-before-choosing-mba-study/" target="_blank" rel="noopener noreferrer">Things To Be Considered Before Choosing MBA Study  (Stoodnt, May 2018)</a></li>
                                        <li><a href="https://www.globsyn.com/wp-content/uploads/2018/11/fund_groom.jpeg" target="_blank" rel="noopener noreferrer">Workshop for the underprivileged  (The Statesman, July 2017)</a></li>
                                        <li><a href="https://www.globsyn.com/wp-content/uploads/2018/11/kids_get_life.jpeg" target="_blank" rel="noopener noreferrer">Kids get life and work skills  (The Times of India, July 2017)</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/06/gbs-news-vishwamitra-010617.jpeg" target="_blank" rel="noopener noreferrer">100% Placement in Globsyn (Dainik Vishwamitra)  Jun 2017</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/03/convocation-2017.jpeg" target="_blank" rel="noopener noreferrer">Globsyn Business School Convocation (Prayag)  Feb 2017</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/04/Dainik-Jagran-12-Sep-16.jpeg" target="_blank" rel="noopener noreferrer">Education should be relevant (Dainik Jagaran)  Sep 2016</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/04/ht-gbs-alumni-brand-summit.jpeg" target="_blank" rel="noopener noreferrer">Globsyn Business School organizes brand summit (The Hidustan Times)  Aug 2016</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/04/Dainik-Jagaran-digitization.jpeg" target="_blank" rel="noopener noreferrer">Montek Singh Ahluwalia innaugurates Globsyn Business School Alumni Brand Summit (Dainik Jagaran)  Aug 2016</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/04/TOI-Edu-248.jpeg" target="_blank" rel="noopener noreferrer">Montek Singh Ahluwalia attends Retrace (The Times of India)  Aug 2016</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/BDG-Interview-001.jpeg" target="_blank" rel="noopener noreferrer">Eduskills is a Phase of Life (The Times of India)  Mar 2016</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/gbs-convocation-16-TT.jpeg" target="_blank" rel="noopener noreferrer">Partha rap on select private varsities (The Telegraph)  Feb 2016</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/04/Statesman-9-Feb-2016.jpeg" target="_blank" rel="noopener noreferrer">Annual Convocation (The Statesman)  Feb 2016</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/04/GBS-Convocation16-TOI.jpeg" target="_blank" rel="noopener noreferrer">Biz School holds annual convocation (The Times of India)  Feb 2016</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/04/Prabhat-Khabar-9Feb2016.jpeg" target="_blank" rel="noopener noreferrer">Committee to be set-up to monitor private institutes (Prabhat Khabar)  Feb 2016</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/04/Sanmarg-82.jpeg" target="_blank" rel="noopener noreferrer">Globsyn Business School Convocation (Sanmarg)  Feb 2016</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/04/Dainik-Jagaran-82.jpeg" target="_blank" rel="noopener noreferrer">Education Minister Dr. Partha Chatterjee at Globsyn Business School Convocation (Dainik Jagaran)  Feb 2016</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/GBS-Convocation16-TOI-edu.jpg" target="_blank" rel="noopener noreferrer">Biz school holds annual convocation (The Times of India)  Feb 2016</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/04/statesman-27Nov15.jpeg" target="_blank" rel="noopener noreferrer">No End to Innovation (The Statesman)  Nov 2015</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2017/04/Jansatta-nov15.jpeg" target="_blank" rel="noopener noreferrer">Globsyn organizes competition (Jansatta)  Nov 2015</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/ht-17Nov15-1.jpg" target="_blank" rel="noopener noreferrer">Globsyn B-school awards (Hindustan Times)  Nov 2015</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/TOI-29Oct15.jpg" target="_blank" rel="noopener noreferrer">Biz school awards students (Times of India)  Oct 2015</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/Rahul-Interview-Education-Insider.pdf" target="_blank" rel="noopener noreferrer">Management education needs to be revamped (Education Insider)  Jun 2015</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/globsyn-online-admission-ht.jpg" target="_blank" rel="noopener noreferrer">Globsyn B-school all set to launch online admission (Hindustan Times)  May 2015</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/globsyn-online-admission-ht.jpg" target="_blank" rel="noopener noreferrer">Globsyns Management Course (Times of India)  May 2015</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/toi_report_160614.pdf" target="_blank" rel="noopener noreferrer">Management Magic (Times of India)  Jun 2014</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/Romit_HT_230713.jpg" target="_blank" rel="noopener noreferrer">Informed decision will help you choose the right business school (Hindustan Times)  Jul 2013</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/Telegraph_Careergraph_180413.jpg" target="_blank" rel="noopener noreferrer">High on Tech (The Telegraph)  Apr 2013</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/TOI_NIE_PG8_080113.jpg" target="_blank" rel="noopener noreferrer">Biz School Hosts CII ASEAN-India Student Exchange Program (Times of India)  Apr 2013</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/Globsyn-B-School-_HT-pg-2_Dec-25-2012.jpg" target="_blank" rel="noopener noreferrer">GBS Holds Students Exchange Programme (Hindustan Times)  Dec 2012</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/HT-091012.jpg" target="_blank" rel="noopener noreferrer">A Shift to Learner-centered Edifice is a Must (Hindustan Times)  Oct 2012</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/Hindu-Business-Line_pg4_August-6.jpg" target="_blank" rel="noopener noreferrer">Macroeconomic Situation Volatile: Murthy (Hindustan Times)  Aug 2012</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/NarayanaMurthyGlobsyn_10081-1.jpg" target="_blank" rel="noopener noreferrer">N.R. Narayana Murthy Stresses the Importance of Intellect and Values (The Telegraph)  Aug 2012</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/Timesof-India-NIE_UOL.jpg" target="_blank" rel="noopener noreferrer">Globsyn partners University of London (Times of India)  Jun 2012</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/ETKM_2011_12_21_21.jpg" target="_blank" rel="noopener noreferrer">All for Robust Markets (The Economic Times)  Nov 2011</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/toi_270411.jpg" target="_blank" rel="noopener noreferrer">Globsyn to provide online classroom soon (Times of India)  Apr 2011</a></li>
                                        <li><a href="http://www.globsyn.com/wp-content/uploads/2015/12/learning.jpg" target="_blank" rel="noopener noreferrer">Globsyn Business School where learning is fun (The Statesman)  Feb 2011</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
