import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function ImportanceofDataPrivacy() {
  return (
    <><title>Importance of Data Privacy</title>
            <section className="hero-banner-img-blogs">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">Importance of Data Privacy</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/blog"> Blogs</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to=""> Importance of Data Privacy</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>

                            <div className="one-table">
                                <p>
                                    <img className="img-fluid" src="/assets/images/2020-03-17-1.png" alt="Importance of Data Privacy" />
                                </p>
                                <div className="entry-header">
                                    <div className="entry-meta bar1 clearfix">
                                        <ul>
                                            <li><i className="fa fa-user me-2" aria-hidden="true"></i><span>By</span>Jonathan</li>
                                            <li><i className="fa fa-tags me-2" aria-hidden="true"></i>Blog</li>
                                            <li><i className="fa fa-comments-o me-2" aria-hidden="true"></i><span>(0)</span> Comment</li>
                                            <li>
                                                <p><i className="fa fa-clock-o"></i> 3  min read</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Amit and his wife recently went to a restaurant for dinner to celebrate their marriage anniversary. At the time of billing, a feedback form was provided. Amit filled up the form happily and handed it over to the waiter. In the feedback form, along with other information, anniversary date was also asked. Few months later, Amit got a mail from a renowned travel website regarding a tour package deal with clearly mentioning of his anniversary date. The deal was something like “Spend your next anniversary in…at a discount of…” He was quite astonished because neither he shared his anniversary date nor any contact details with the website. So he rang up his wife to know whether she contacted the travel website or not, but got a negative response from her.</p>
                                <p>One of the possible reasons of the above mentioned scenario is that either the restaurant shared their customer details’ with third party without the consent of customers, or the database they use to maintain the details of their customer is compromised.</p>
                                <p>This is just an example of how our personal details are shared or used without our consent. In other words, it can also be said that we cannot control how the data that we share is going to process. Everyday millions of bytes of data are being generated all over the world through number of sources and Internet is one of the major sources. Whenever a user browses net he/she leaves some digital footprints knowingly or unknowingly in cyberspace, and this raises question of data privacy. But what is Data Privacy all about? Why do we need to concern about it?</p>
                                <p>Data Privacy refers to how data/information should be processed based on its relative importance. For example, a person would not hesitate to share his/her name, name of the organization where he/she is working etc. during interaction with a stranger. But the person would definitely not share his/her financial details. Data Privacy/Information Privacy can also be defined as the ability of an organization or individual to determine what data in a computer system can be shared with third parties.</p>
                                <p>(Source: https://searchcio.techtarget.com/definition/data-privacy-information-privacy)</p>
                                <p>In this age of globalization, the concept of data privacy deals with important personal information, also known as Personally Identifiable Information (PII – It refers to those information that can be used alone or with other relevant data, can identify an individual) and Personal Health Information (PHI). This can include biometric information, health and medical records, and financial data (for e.g. bank account details, debit/credit card number etc.) and even general, but still critical information such as home address, DOB etc.</p>
                                <p>With the rapid advancement of digital technologies, companies are utilizing latest techniques to collect data from every possible digital platform to gain insight of their business. The only reason is that data is the most valuable asset for every company in this era of industry 4.0. As long as data is collected as well as processed & stored in legitimate way, there is no issue. But if data is collected and/or processed without the consent of concerned person then the issue of data privacy arises.</p>
                                <p>In the recent past, we saw a number of data breach incidents which ultimately resulted in compromise of data privacy. Let us have a look on few of those incidents:</p>
                                <ul className='disc'>
                                    <li>India’s largest lender, State Bank of India left one of its servers unprotected which exposed the data of its 422 million customers. The server contained partial bank account numbers, bank balances and phones of individual using the bank’s SBI Quick service.</li>
                                <li>More than 1.3 million credit and debit card details from Indian banks were spotted for sale in October, 2019, reported by a Singapore-based cybersecurity company.</li>
                                <li>Records of 6.8 million users’ of an Indian healthcare website were stolen by hackers in February’19 reported by a US based cyber security firm-FireEye.</li>
                                </ul>
                                <p>(Source: https://economictimes.indiatimes.com/industry/tech/8-biggest-data-leaks-of-2019-that-hit-indian-users-hard/dating-apps-reveal-location-threatening-individual-safety/slideshow/72837169.cms)</p>
                                <p>While discussing about data privacy related incidents, it’s incomplete without mentioning of Cambridge Analytica case that shook the whole world. It is reported that political data firm Cambridge Analytica obtained the data of 50 million Facebook users, built 30 million personality profiles, and sold the data to US politicians who wanted to influence voters, without the users’ consent.  (Source: https://dig.watch/trends/cambridge-analytica)</p>
                                <p>In the case of Cambridge Analytica, the company was able to gather personally identifiable information (PII) through a personality quiz app called ‘thisisyourdigitiallife’. To participate in the quiz, the app needs to be added to the users’ Facebook profile. As soon as the app is granted permission, users’ activity detail gets transferred to the app developer. This data includes all of the posts that users and their friends have liked on Facebook. This case led to havoc controversy over data privacy policy followed by Facebook.</p>
                                <p>From the above discussion, it is clear that data privacy is as important as data security. Without proper protection of privacy of data, huge loss could be incurred. In this regard, the difference between data privacy & data security need to be understood. Data protection is about safeguarding data against unauthorized access whereas data privacy is about authorized access with specification of who has it and who defines it.</p>
                                <p>Realizing the importance of Data Privacy, a number of rules & regulations are enacted in different countries to protect personal data like General Data Protection regulation in European Union, California Consumer Privacy Act in USA, Federal Law on Personal Data in Russia, APPI (Act on Protection of Personal Information) Amendment 2017 in Japan etc. In India, Personal Data Protection Bill, 2019 is tabled in the parliament and discussion is going on.</p>
                                <p>All the rules and regulations created & drafted yet so far on data privacy/data protection around the globe are to protect individuals’ privacy rights on data pertaining to themselves. But it leads to another debate called ‘Digital Protectionism‘. The debate raises mainly because of the cross border data flows in today’s digital economy. When a multinational company or e-commerce giant store data of customers/users in their server, the rules & regulations on data protection pertaining to the customers/users govern by the law enforcement agencies belonging from the country where those customers/user reside not by laws enacted by the country where the company registered/server situated. As a result, free flow of data across geographical boundaries hamper due to multiple rules and regulations.</p>
                                <p>There is a difference in opinion on how much restriction would be imposed to protect data privacy. Some believe that strict rules would prevail where some in favor of balanced approach i.e. privacy of data is not compromised while promoting flow of data across borders.</p>
                                <p>It is true that digital business can’t sustain without flow of data. Today data is the key driver to fuel the growth of digital economy. Reliance Industries’ chairman Mukesh Ambani rightly mentioned that data is the new oil. Too much restriction would ultimately create isolated ‘Data Island’ which is a bottleneck in global supply chain management process. On the hand, the importance of data privacy could not be ignored. That is why business organizations, policy makers, law enforcement agencies, industry bodies & all other stakeholders’ should come to unanimity on what data to collect, how to process and where to store without affecting the interest of consumers, as well as business entities.</p>
                                <p><b>Debmalya Banerjee</b><br/>
                                <br/>
                                Knowledge Cell</p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
  )
}
