import React from 'react'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'

export default function NationalScholarship() {
    return (
        <>
            <title>National Scholarship Program - Globsyn</title>
            <section className="hero-banner-img-sceren">
                <div className="container">
                    <div className="entry-banner-content">
                        <h1 className="entry-title">National Scholarship Program</h1>
                        <div className="d-flex">
                            <div><Link className="text-yellow" to="/">Globsyn</Link></div>
                            <div><span className="text-light">-</span></div>
                            <div><Link className="text-light" to="/innovation">National Scholarship Program</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mt-8'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-12">
                            <div>
                            </div>
                            <div className="one-table">
                                <p>At Globsyn Business School (GBS) we believe in acknowledging excellence achieved by meritorious students in academic and extra-curricular pursuits and recognising talent from a holistic point of view. As a part of this ongoing initiative, GBS offers various scholarships to the students of its Post Graduate Management Programmes. The aim of the National Scholarship Program (NSP) for graduate students is also to recognise merit from a broader perspective to include those who have excelled in non-academic areas.</p>
                                <strong>Objective</strong>
                                <br />
                                <br />
                                <p >The aim of the Scholarship Schemes is to provide financial relief to some meritorious students coming from economically disadvantaged families so that they may pursue their PG education at GBS without the burden of fee expense.</p>
                                <strong>Allocation of Scholarships</strong>
                                <br />
                                <br />
                                <p>Scholarships come under, broadly two categories: Economically Disadvantaged and Socially Challenged.</p>
                                <p><strong><b>&nbsp; &nbsp; &nbsp; 1) Kalyani Scholarships</b></strong> (for students from economically disadvantaged background)</p>
                                <ol type='none'>
                                    <li>
                                        <ol type='a'>
                                            <li>&nbsp;No. of students: 7 (seven)</li>
                                            <li>&nbsp;Quantum of Scholarship: 100% fee waiver (except Admission Fee)</li>
                                            <li>Eligibility for National Scholarship Program :
                                                <ul className='disc'>
                                                    <li>Students with an exemplary academic track-record, whose parents’ or guardian’s income falls below the criteria as specified under ‘Parental Income Ceiling’, will be eligible for the Kalyani Scholarship.</li>
                                                    <li>The minimum academic criteria required for students to avail this and the other scholarships mentioned below:</li>
                                                    <p style={{ paddingLeft: "40px" }}>Class 10/equivalent: = 75%<br />
                                                        Class 12/equivalent: = 75%<br />
                                                        Graduation: = 60%<br />
                                                        Qualifying Aptitude Exam (CAT/MAT etc.) = 80 percentile<br />
                                                        Other outstanding achievements (documentary proof required)</p>
                                                    <li>Furthermore, the students awarded scholarships must maintain an academic CGPA = 6.0 in all Semesters at GBS and actively participate in the institute’s Beyond Education activities.</li>
                                                    <li>The students’ performance would be reviewed after every Semester for consideration to continue the fee waiver.</li>
                                                </ul>
                                            </li>
                                            <li>Administration of Fee Waiver: All installments of fee (except initial Admission Fee) will be waived. If any installments have already been paid, these will be refunded after the due date of the last installment that would have normally been due.</li>
                                        </ol>
                                    </li>
                                </ol>
                                <strong>2) Outstanding Achievers Scholarships </strong>(for students achieving recognition or excellence in sports or culture/arts areas).
                                <br />
                                <br />
                                <ol type='none'>
                                    <li>
                                        <ol type='a'>
                                            <li>No. of scholarships: 6 (three each in Sports and Arts)</li>
                                            <li>Quantum of Scholarship: Rs. 1,00,000 each</li>
                                            <li>Eligibility for National Scholarship Program :
                                                <ul className='disc'>
                                                    <li>For Sportspersons, the student must have been recognised by a prominent sports body/association at the State or National level in any outdoor or indoor sporting event.</li>
                                                    <li>In the Arts area, students achieving recognition or excellence from prominent bodies/associations at the Regional or National level in any areas such as Debate, Quiz, Dance, Drama etc. will be eligible.</li>
                                                    <li>In addition, the students’ parents’ or guardian’s income should fall below the criteria as specified under ‘Parental Income Ceiling’.</li>
                                                    <li>Furthermore, the students awarded scholarships must maintain an academic CGPA &gt;= 5.0 in all Semesters at GBS and actively participate in the institute’s Beyond Education activities.</li>
                                                    <li>The students’ performance would be reviewed after every Semester for consideration to be eligible for partial fee waiver.</li>
                                                    <li>Administration of Fee waiver: The fee waiver will be applied in two parts. The first part 50% (i.e. Rs. 50,000/-) will be reduced from the penultimate installment and remaining 50% reduced in the last installment.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <strong>Parental Income Ceiling</strong>
                                <br />
                                <br />
                                <p>The parental income should be Rs. 8 lakh per annum for both categories of Scholarships. Students will have to furnish income certificate or self-attested copy of last income tax return for their parent/guardian along with copy of Aadhar Card (or equivalent identification allowed by the Government) for both student and parent.</p>
                                <strong>Opening of NSP and Selection Procedure</strong>
                                <br />
                                <br />
                                <ol type='a'>
                                    <li>The application for Scholarships may be submitted by filling out the form provided below.</li>
                                    <li>Shortlisted applicants will be interviewed by a Scholarships Committee chaired by Dean, along with at least two other senior Faculty members who may include the Principal, within one month of the closure of all applications.</li>
                                    <li>At the interview, applicants will have to present all documentary proof for verification, including academic credentials, income documents, identity proof etc. as required for the Scholarship.</li>
                                    <li>After the interview, the recommendations of the Scholarships Committee based on a comprehensive evaluation on several criteria, will be sent to the Director for final approval. Director may choose to carry out a second level of interview.</li>
                                    <li>Final list of Scholarships awarded will be announced.</li>
                                </ol>
                                <strong>General</strong>
                                <ol type='a'>
                                    <li>All Scholarship based fee waivers will apply to Programme/Course Fee only. There will be no waiver of any other charges such as re-examination fee, any student activity charges, library charges, hostel charges, any late payment charges etc. that may be applicable.</li>
                                    <li>Scholarships will not be given to students who are already availing the benefit of any other scholarship from anywhere else. &nbsp;A declaration to that effect will be taken. &nbsp;Scholarship may be cancelled, and other penal action taken if declaration is found to be false.</li>
                                    <li>Students are expected to maintain the dignity and prestige of GBS at all times. &nbsp;If any disciplinary action, on any front is taken against the awarded students during their course of study, the scholarship will be forfeited.</li>
                                </ol>
                                <br/>
                                <br/>
                               <div className='text-center'><strong >Apply for Scholarship</strong></div> 
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
